import React, { Fragment, Component } from 'react';
import DataGrid, {
    Column,
    Export, Grouping,
    GroupPanel,
    HeaderFilter, Pager,
    Paging,
    SearchPanel,
    SortByGroupSummaryInfo
} from 'devextreme-react/data-grid'; //npm i devextreme-react

import { getRequestParams,numberGridFormat } from '../../helpers/globalfunctions.js';
import { exportFormats } from '../../helpers/fixcodes.js';
import { jsPDF } from 'jspdf';
import { createBrowserHistory } from 'history';
import { exportDataGrid } from 'devextreme/pdf_exporter';
import { currencySymbolFormats, precisionFormats } from '../../helpers/fixcodes.js';
const history = createBrowserHistory();

export class BillPaymentListComponent extends Component {
    static displayName = BillPaymentListComponent.name;

    constructor(props) {
        super(props);
        this.state = {
            billpaymentSummary:null,
            billpaymentDetails: null,
        }
    }

    async componentDidMount() {
        await this.getBillPaymentMaster();
    }

    getBillPaymentMaster = async () => {
        try {
            var request = {};
            const requestParams = getRequestParams("GET");
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'billPayment/1/0', requestParams);
            const data = await response.json();            
            this.setState({
                billpaymentSummary: data.payload.billPayment
            });
        } catch (err) {
            console.log("Error in " + this.displayName + ".getBillPaymentMaster function", err);
        }
    }

    onExporting = (e) => {
        const doc = new jsPDF();
        if (e.format === "pdf") {
            exportDataGrid({
                jsPDFDocument: doc,
                component: e.component,
                columnWidths: 50,
                // indent: 10,
            }).then(() => {
                doc.save(e.fileName);
            });
        }
    }

    handleCategoryModal = async (e) => {
        this.setState({
            categoryModal: true,
            singelData: null
        });
    }

    editBillPayment(e, data) {
        e.preventDefault();
        history.push({
            pathname: '/billpayment/new',
            state: {
                accountId: data.accountId,
                accountName: data.accountName,
                sourceUrl: "/billpayment/list",
                sourceState: this.state
            }
        });
        window.location.reload(false);
    }

    render() {
        const renderGridCell = (data) => {
            return <div>
                <a href="/#" title="Edit" onClick={(e) => { this.editBillPayment(e, data.data) }}  >{data.data.accountName}</a>&nbsp;&nbsp;
            </div>;
        }
        const renderGridTotalAmountCell = (data) => {
            return numberGridFormat(data.value)
            // return <div><small><i className={currencySymbolFormats.currencyFormat}></i></small>&nbsp;&nbsp;{parseFloat(data.value).toFixed(precisionFormats[2])}</div>;
        }
        return (
            <Fragment>
                <div className="section-body">
                    {/* <div className='card bg-filter'>
                        <div className='card-body'>
                            <div className="section-header">
                                <h6 className="card-title text-blue">Bill Payment</h6>
                            </div>
                        </div>
                    </div> */}
                    <div className='card'>
                    <div className='card-body'>
                                <DataGrid
                                    dataSource={this.state.billpaymentSummary}
                                    keyExpr="accountId"
                                    id='invoicepaymentList'
                                    selection={{ mode: 'single' }}
                                    columnsAutoWidth={false}
                                    showColumnLines={true}
                                    showRowLines={true}
                                    showBorders={true}
                                    rowAlternationEnabled={false}
                                    allowColumnResizing={true}
                                    onExporting={this.onExporting}
                                    columnResizingMode='widget'
                                    onToolbarPreparing={this.onToolbarPreparing}>

                                    <Column dataField="accountName" caption="Customer" allowSearch={true} allowFiltering={false} allowSorting={true} width={250} cellRender={renderGridCell} />
                                    <Column dataField="creditAmount" caption="Credit" allowSearch={true} allowFiltering={false} allowSorting={true} width={200} cellRender={renderGridTotalAmountCell} />
                                    <Column dataField="debitAmount" caption="Debit" allowSearch={true} allowFiltering={false} allowSorting={true} width={300} cellRender={renderGridTotalAmountCell} />
                                    <Column dataField="dueBillTotalAmount" caption="Due Amount" allowSearch={true} allowFiltering={false} allowSorting={true} width={300} cellRender={renderGridTotalAmountCell} />
                                    <Column dataField="overDueAmount" caption="Over Due Amount" allowSearch={true} allowFiltering={false} allowSorting={true} width={180} cellRender={renderGridTotalAmountCell} />

                                    <SortByGroupSummaryInfo summaryItem="count" />
                                    <GroupPanel visible={false} />
                                    {/* <Template name="toolbarTemplate" /> */}
                                    <HeaderFilter visible={true} />
                                    <SearchPanel visible={true} highlightCaseSensitive={true} width={240} placeholder="Search..." />
                                    <Grouping autoExpandAll={true} />
                                    <Paging defaultPageSize={10} />
                                    <Pager showPageSizeSelector={true} allowedPageSizes={[10, 20, 50]} showInfo={true} visible={true} />
                                    {/* <Export enabled={true} formats={exportFormats} allowExportSelectedData={false} fileName="BillPayment" /> */}
                                </DataGrid>
                            </div>
                    </div>
                </div>
            </Fragment>
        )
    };
}
