import React, { Fragment, Component } from 'react';
import DataGrid, {
    Column,
    Export, Grouping,
    GroupPanel,
    HeaderFilter, Pager,
    Paging,
    SearchPanel,
    SortByGroupSummaryInfo,
    Summary, TotalItem
} from 'devextreme-react/data-grid'; //npm i devextreme-react

import Modal from 'react-modal'; //npm install --save react-modal;
import Swal from 'sweetalert2'; //npm i sweetalert2 --save
import { toast } from 'react-toastify'; //npm i react-toastify --save
import { exportFormats } from '../../helpers/fixcodes.js';
import { getRequestParams, formatDate,numberGridFormat } from '../../helpers/globalfunctions.js';
import { jsPDF } from 'jspdf';
import { exportDataGrid } from 'devextreme/pdf_exporter';
import { SelectBox } from 'devextreme-react/select-box';
import NumberBox from 'devextreme-react/number-box';
import DateBox from 'devextreme-react/date-box';
import moment from 'moment';
import { precisionFormats, currencySymbolFormats } from '../../helpers/fixcodes.js';

const customStyles = {
    content: {
        width: '40%',
    }
}

export class PaymentComponent extends Component {
    static displayName = PaymentComponent.name;

    constructor(props) {
        super(props);
        this.state = {
            paymentMode: null,
            accountList: null,
            paymentModeList: null,
            paymentmodel: false,
            transactionNo: "",
            customerId: 0,
            filtercustomerId: 0,
            fromDate:  moment(new Date()).subtract(30, 'day').format("MM/DD/YYYY"),
            toDate: new Date(),
            paymentModeId: 0,
            filterpaymentModeId: 0,
            amount: 0.00,
            remarks: "",
            paymentDate: new Date(),
            sessionUser: JSON.parse(sessionStorage.getItem("sessionUser")),
            errors: {
                customerId: "",
                paymentModeId: "",
                amount: "",
                transactionNo: "",
                remarks: "",
                paymentDate: ""
            },
        }
    }

    async componentDidMount() {
        await this.getPaymentMaster();
        await this.getAccounts();
        await this.getPaymentMode();
    }

    getPaymentMaster = async () => {

        try {
            var request = {};

            request.CustomerId = this.state.filtercustomerId;
            request.paymentModeId = this.state.filterpaymentModeId;
            request.FromDate = formatDate(this.state.fromDate, "MM/dd/yyyy") + ' ' + '00:00:00';
            request.ToDate = formatDate(this.state.toDate, "MM/dd/yyyy") + ' ' + "23:59:59";
            const requestParams = getRequestParams("POST", request);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'payment/Payment', requestParams);
            const data = await response.json();
            this.setState({
                paymentModeList: data.payload
            });
        } catch (err) {
            console.log("Error in " + this.displayName + ".getPaymentMaster function", err);
        }
    }

    handleSearchItem = async () => {
        await this.getPaymentMaster();
    }

    handleClear = () => {
        this.setState({
            filtercustomerId: 0,
            filterpaymentModeId: 0,
            fromDate:moment(new Date()).subtract(30, 'day').format("MM/DD/YYYY"),
            toDate: new Date(),
        });
        setTimeout(() => this.getPaymentMaster(), 100);
    }

    handleFormtDateChanged = (date) => {
        this.setState({ fromDate: date.value });
    }
    handleToDateChanged = (date) => {
        this.setState({ toDate: date.value });
    }



    getAccounts = async () => {
        try {
            var request = {};
            const requestParams = getRequestParams("GET", request);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'account/dropDownAccountPayment', requestParams);
            const data = await response.json();
            this.setState({
                accountList: data.payload
            });
        } catch (err) {
            console.log("Error in " + this.displayName + ".getAccounts function", err);
        }
    }

    getPaymentMode = async () => {
        try {
            var request = {};
            const requestParams = getRequestParams("GET", request);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'paymentMode/Get', requestParams);
            const data = await response.json();
            this.setState({
                paymentMode: data.payload
            });
        } catch (err) {
            console.log("Error in " + this.displayName + ".getPaymentMode function", err);
        }
    }

    editCategoryModelModal = (e, data) => {
        e.preventDefault();
        if (data != null) {
            this.setState({
                paymentmodel: true,
                singelData: data,
                id: data.id,
                categoryName: data.name,
                hsnCode: data.hsnCode,
                hsnId: data.hsnId,
                categoryDescription: data.description,
            });
        }
    }

    onExporting = (e) => {
        const doc = new jsPDF();
        if (e.format === "pdf") {
            exportDataGrid({
                jsPDFDocument: doc,
                component: e.component,
                columnWidths: 50,
                // indent: 10,
            }).then(() => {
                doc.save(e.fileName);
            });
        }
    }

    handleCustomerChange = (selectedOption) => {
        this.setState({
            customerId: (selectedOption.selectedItem !== null ? selectedOption.selectedItem.id : 0),
        });
        if (selectedOption.selectedItem !== null && selectedOption.selectedItem.id > 0) {
            this.state.errors["customerId"] = "";
        }
    }

    handleFilterCustomerChange = (selectedOption) => {
        this.setState({
            filtercustomerId: (selectedOption.selectedItem !== null ? selectedOption.selectedItem.id : 0),
        });

    }

    handleFilterPaymentModeChange = (selectedOption) => {
        this.setState({
            filterpaymentModeId: (selectedOption.selectedItem !== null ? selectedOption.selectedItem.id : 0),
        });

    }

    handlePaymentModeChange = (selectedOption) => {
        this.setState({
            paymentModeId: (selectedOption.selectedItem !== null ? selectedOption.selectedItem.id : 0),
        });
        if (selectedOption.selectedItem !== null && selectedOption.selectedItem.id > 0) {
            this.state.errors["paymentModeId"] = "";
        }
    }

    handleAmountChange = (event) => {
        const newValue = event.value;
        this.setState({ amount: newValue });

    }

    handlePaymentDateChanged = (date) => {
        this.setState({ paymentDate: date.value });
    }

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({ [name]: value });
        this.state.errors[name] = "";
    }



    handleUserChange = (selectedOption) => {
        this.setState({
            filterUserId: (selectedOption.selectedItem !== null ? selectedOption.selectedItem.id : 0)
        });
    }


    handlePaymentModal = async (e) => {
        this.setState({
            paymentmodel: true,
            singelData: null
        });
    }

    handleValidation = () => {
        let formIsValid = true;
        try {
            let errors = this.state.errors;
            errors.customerId = "";
            errors.paymentModeId = "";
            errors.paymentDate = "";
            errors.amount = "";
            errors.transactionNo = "";
            errors.remarks = ""

            if (this.state.customerId === 0) {
                formIsValid = false;
                errors.customerId = "Please select account.";
            }
            if (this.state.paymentModeId === 0) {
                formIsValid = false;
                errors.paymentModeId = "Please select payment mode.";
            }
            if (this.state.paymentDate === "") {
                formIsValid = false;
                errors.paymentDate = "Please select payment date.";
            }
            if (this.state.amount === 0) {
                formIsValid = false;
                errors.amount = "Please enter amount.";
            }

            if (this.state.transactionNo.trim() === "") {
                formIsValid = false;
                errors.transactionNo = "Please enter transaction number.";
            }

            if (this.state.remarks.trim() === "") {
                formIsValid = false;
                errors.remarks = "Please enter remarks.";
            }
            this.setState({ errors: errors });
        }
        catch (err) {
            console.log("Error in " + this.displayName + ".handleValidation function", err);
            this.setState({ isSubmited: false })
        }
        return formIsValid;
    }

    handlePaymentSubmit = async (e) => {
        e.preventDefault();
        try {
            if (this.handleValidation()) {
                this.setState({ isSubmited: true });
                var newObject = {};
                newObject.customerId = this.state.customerId;
                newObject.paymentModeId = this.state.paymentModeId;
                newObject.paymentDate = this.state.paymentDate;
                newObject.amount = this.state.amount;
                newObject.transactionNo = this.state.transactionNo;
                newObject.remarks = this.state.remarks;
                newObject.CreatedBy = this.state.sessionUser.id;
                newObject.CreatedOn = formatDate(new Date(), "MM/dd/yyyy hh:mm");
                const requestParams = getRequestParams('POST', newObject);
                const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'payment/insert', requestParams);
                const data = await response.json();
                if (data.isSuccess) {
                    Swal.fire({
                        icon: 'success',
                        title: 'Success',
                        text: data.message,
                    }).then((result) => {
                        if (result.isConfirmed) {
                            this.closePaymentModal();
                            setTimeout(() => this.getPaymentMaster(), 500)
                        }
                        this.setState({ isSubmited: false })
                    })
                }
                else {
                    toast.error(data.message, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        style: { width: "310px" },
                        hideProgressBar: false,
                        newestOnTop: false,
                        closeButton: false
                    });
                    this.setState({ isSubmited: false })
                }
            }
        }
        catch (err) {
            console.log("Error in " + this.displayName + ".handlePaymentSubmit function", err);
            this.setState({ isSubmited: false })
        }
    }

    closePaymentModal = () => {
        this.setState({
            isSubmited: false,
            loadPanelVisible: false,
            paymentmodel: false,
            singelData: null,
            transactionNo: "",
            customerId: 0,
            paymentModeId: 0,
            amount: 0.00,
            remarks: "",
            paymentDate: new Date(),
            sessionUser: JSON.parse(sessionStorage.getItem("sessionUser")),
            errors: {
                customerId: "",
                paymentModeId: "",
                amount: "",
                transactionNo: "",
                remarks: "",
                paymentDate: ""
            }

        });
    }

    render() {
        const customizeAmountText = (data) => {
            var value = 'Total - '  +  numberGridFormat(data.value);
            return value
            // return `Total - ${data.value.toFixed(precisionFormats[2])}`;
        }

        const renderGridNumberCell = (data) => {
            return numberGridFormat(data.value)
            // return <div><small><i class={currencySymbolFormats.currencyFormat}></i></small>&nbsp;&nbsp;{parseFloat(data.value !== undefined ? data.value : 0.00).toFixed(precisionFormats[2])}</div>;
        }
        return (
            <Fragment>
                <div className="section-body">
                    <div className='card bg-filter'>
                        <div className='card-body'>
                            <div className="section-header">
                                <div className="col-lg-4">
                                    <div className="form-group">
                                        <label htmlFor="">Account</label>
                                        <SelectBox
                                            dataSource={this.state.accountList}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select account"
                                            name="id"
                                            defaultValue={0}
                                            value={this.state.filtercustomerId}
                                            displayExpr='name'
                                            valueExpr='id'
                                            onSelectionChanged={this.handleFilterCustomerChange}
                                            showClearButton={true}
                                            disabled={false}
                                            searchEnabled={true}
                                        />
                                    </div>
                                </div>

                                <div className="col-lg-2">
                                    <div className="form-group">
                                        <label>Payment Mode </label>
                                        <SelectBox
                                            dataSource={this.state.paymentMode}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select payment mode"
                                            name="id"
                                            defaultValue={0}
                                            value={this.state.filterpaymentModeId}
                                            displayExpr='name'
                                            valueExpr='id'
                                            onSelectionChanged={this.handleFilterPaymentModeChange}
                                            showClearButton={true}
                                            disabled={false}
                                            searchEnabled={true}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-2">
                                    <div className="form-group">
                                        <label>From Date </label>
                                        <DateBox type="date"
                                            className="replay-date-time"
                                            name="fromDate"
                                            placeholder="MM/dd/yyyy"
                                            displayFormat="MM/dd/yyyy"
                                            showAnalogClock={true}
                                            useMaskBehavior={true}
                                            defaultValue="MM/dd/yyyy"
                                            value={this.state.fromDate}
                                            onValueChanged={this.handleFormtDateChanged}
                                            width="100%"
                                            disabled={false}
                                            acceptCustomValue={false}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-2">
                                    <div className="form-group">
                                        <label>To Date</label>
                                        <DateBox type="date"
                                            className="replay-date-time"
                                            name="toDate"
                                            placeholder="MM/dd/yyyy"
                                            displayFormat="MM/dd/yyyy"
                                            showAnalogClock={true}
                                            useMaskBehavior={true}
                                            defaultValue="MM/dd/yyyy"
                                            value={this.state.toDate}
                                            onValueChanged={this.handleToDateChanged}
                                            width="100%"
                                            disabled={false}
                                            acceptCustomValue={false}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-2">
                                <div className='form-group'>
                                        <label htmlFor="">&nbsp;</label><br />
                                        <button type="submit" className="btn btn-success" onClick={this.handleSearchItem}>
                                            Search
                                        </button>&nbsp;&nbsp;
                                        <button type="submit" className="btn btn-outline-secondary" onClick={this.handleClear}>
                                            <span title="Clear">Clear</span>
                                        </button>&nbsp;&nbsp;
                                        <button type="submit" className="btn btn-success" onClick={this.handlePaymentModal}>
                                            New
                                        </button>
                                    </div>
                                </div>                                
                            </div>
                           
                        </div>
                    </div>
                    <div className='card'>
                    <div className='card-body'>
                                <DataGrid
                                    dataSource={this.state.paymentModeList}
                                    keyExpr="id"
                                    id='paymentModeId'
                                    selection={{ mode: 'single' }}
                                    columnsAutoWidth={false}
                                    showColumnLines={true}
                                    showRowLines={true}
                                    showBorders={true}
                                    rowAlternationEnabled={false}
                                    allowColumnResizing={true}
                                    onExporting={this.onExporting}
                                    columnResizingMode='widget'
                                    onToolbarPreparing={this.onToolbarPreparing}>
                                    <Column dataField="customer" caption="Account" allowSearch={true} allowFiltering={false} allowSorting={true} width={400} />
                                    <Column dataField="paymentMode" caption="Payment Mode" allowSearch={true} allowFiltering={false} allowSorting={true} width={130} />
                                    <Column dataField="paymentDate" caption="Payment Date" allowSearch={true} allowFiltering={false} allowSorting={true} width={110} dataType="datetime" format="dd/MM/yyyy" />
                                    <Column dataField="amount" caption="Amount" allowSearch={true} allowFiltering={false} allowSorting={true} width={150} cellRender={renderGridNumberCell} />
                                    <Column dataField="transactionNo" caption="Transaction#" allowSearch={true} allowFiltering={false} allowSorting={true} width={150} />
                                    <Column dataField="remarks" caption="Remarks" allowSearch={true} allowFiltering={false} allowSorting={true} width={180} />
                                    <Summary>
                                        <TotalItem column="amount" summaryType="sum" customizeText={customizeAmountText} />
                                    </Summary>
                                    <SortByGroupSummaryInfo summaryItem="count" />
                                    <GroupPanel visible={false} />
                                    {/* <Template name="toolbarTemplate" /> */}
                                    <HeaderFilter visible={true} />
                                    <SearchPanel visible={true} higlightCaseSensitive={true} width={240} placeholder="Search..." />
                                    <Grouping autoExpandAll={true} />
                                    <Paging defaultPageSize={20} />
                                    <Pager showPageSizeSelector={true} allowedPageSizes={[10, 20, 50]} showInfo={true} visible={true} />
                                    <Export enabled={true}  allowExportSelectedData={false} fileName="Payment" />
                                </DataGrid>
                            </div>
                    </div>
                </div>
                <Modal ariaHideApp={false}
                    isOpen={this.state.paymentmodel}
                    style={customStyles}
                    className={"react-modal"}
                    //onAfterOpen={this.handleAssignDataToState}
                    onRequestClose={this.closeModel}
                    shouldCloseOnOverlayClick={false}
                    contentLabel="Example Modal">
                    <form method="post" style={this.state.isSubmited ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                        <div className="modal-header">
                            <h6 className="modal-title">Payment</h6>
                        </div>
                        <div className="modal-body">
                            <div className='row '>
                                <div className="col-lg-8">
                                    <div className="form-group">
                                        <label htmlFor="">Account <span className="text-danger">*</span></label>
                                        <SelectBox
                                            dataSource={this.state.accountList}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select account"
                                            name="id"
                                            defaultValue={0}
                                            value={this.state.customerId}
                                            displayExpr='name'
                                            valueExpr='id'
                                            onSelectionChanged={this.handleCustomerChange}
                                            showClearButton={true}
                                            disabled={this.state.id > 0}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["customerId"].length > 0 && <span className="error invalid-feedback">{this.state.errors["customerId"]}</span>}
                                    </div>
                                </div>

                                <div className="col-lg-4">
                                    <div className="form-group">
                                        <label>Payment Mode <span className="text-danger">*</span></label>
                                        <SelectBox
                                            dataSource={this.state.paymentMode  }
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select payment mode"
                                            name="id"
                                            defaultValue={0}
                                            value={this.state.paymentModeId}
                                            displayExpr='name'
                                            valueExpr='id'
                                            onSelectionChanged={this.handlePaymentModeChange}
                                            showClearButton={true}
                                            disabled={false}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["paymentModeId"].length > 0 && <span className="error invalid-feedback">{this.state.errors["paymentModeId"]}</span>}
                                    </div>
                                </div>

                                <div className="col-lg-4">
                                    <div className="form-group">
                                        <label htmlFor="">Payment Date</label>
                                        <DateBox type="date"
                                            className="replay-date-time"
                                            name="paymentDate"
                                            placeholder="dd/mm/yyyy"
                                            displayFormat="dd/MM/yyyy"
                                            showAnalogClock={true}
                                            useMaskBehavior={true}
                                            defaultValue={this.state.paymentDate}
                                            value={this.state.paymentDate}
                                            onValueChanged={this.handlePaymentDateChanged}
                                            width="100%"
                                            disabled={this.state.id > 0}
                                            acceptCustomValue={false}
                                        />
                                        {this.state.errors["paymentDate"].length > 0 && <span className="error invalid-feedback">{this.state.errors["paymentDate"]}</span>}

                                    </div>
                                </div>

                                <div className="col-lg-4">
                                    <div className="form-group">
                                        <label htmlFor="">Paid Amount <span className="text-danger">*</span></label>
                                        <NumberBox type="number" className="form-control form-control-sm" name="amount" placeholder="Enter Amount"
                                            disabled={this.state.id > 0} format="#0.00"
                                            defaultValue={0.00} title="amount" value={this.state.amount} onValueChanged={this.handleAmountChange} min={0.00} max={999999.99} />
                                        {this.state.errors["amount"].length > 0 && <span className="error invalid-feedback">{this.state.errors["amount"]}</span>}
                                    </div>
                                </div>

                                <div className="col-lg-4">
                                    <div className="form-group">
                                        <label>Transaction# <span className="text-danger">*</span></label>
                                        <input type="text" className="form-control form-control-sm" name="transactionNo" autoComplete="transactionNo" placeholder="Enter transaction number" value={this.state.transectionNo}
                                            title="Transaction#" maxLength="50" onChange={this.handleInputChange} />
                                        {this.state.errors["transactionNo"].length > 0 && <span className="error invalid-feedback">{this.state.errors["transactionNo"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="form-group">
                                        <label>Remarks <span className="text-danger">*</span></label>
                                        <input type="text" className="form-control form-control-sm" name="remarks" autoComplete="remarks" placeholder="Enter remarks" value={this.state.remarks}
                                            title="Remarks" maxLength="250" onChange={this.handleInputChange} />
                                        {this.state.errors["remarks"].length > 0 && <span className="error invalid-feedback">{this.state.errors["remarks"]}</span>}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                                <button type="submit" className="btn btn-success" onClick={this.handlePaymentSubmit} >
                                    {this.state.isSubmited && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                    {!this.state.isSubmited && <span title="Submit">Submit</span>}
                                </button>
                                <button type="submit" className="btn btn-outline-secondary" onClick={this.closePaymentModal}>
                                    <span title="Cancel"><i class="fas fa-times"></i> Cancel</span>
                                </button>
                            </div>
                    </form>
                </Modal>
            </Fragment>
        )
    };
}
