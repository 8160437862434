import React, { Fragment, Component } from 'react';
import DataGrid, {
    Column,
    Export, Grouping,
    GroupPanel,
    HeaderFilter, Pager,
    Paging,
    SearchPanel,
    SortByGroupSummaryInfo,
    Selection,
    Editing,
    PatternRule,
    RequiredRule,
} from 'devextreme-react/data-grid'; //npm i devextreme-react
import Modal from 'react-modal'; //npm install --save react-modal;
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import { formatDate, getRequestParams } from '../../helpers/globalfunctions.js';
import { DateFormat, exportFormats,currencySymbolFormats } from '../../helpers/fixcodes.js';
import { floatValidation } from '../../helpers/globalvalidations.js';
import { LoadPanel } from 'devextreme-react/load-panel';
import { SelectBox } from 'devextreme-react/select-box';
import DateBox from 'devextreme-react/date-box';
import Swal from 'sweetalert2'; //npm i sweetalert2 --save
import { toast } from 'react-toastify'; //npm i react-toastify --save
import { jsPDF } from 'jspdf';
import { exportDataGrid } from 'devextreme/pdf_exporter';
const position = { of: '#historydiv' };

const customStyles = {
    content: {
        width: '50%',
    }
}
var billingType = [
    { id: 1, name: "Advance" }
    ,{id: 2, name: "Post" }
]
export class ContractConfigurationComponent extends Component {
    static displayName = ContractConfigurationComponent.name;
    constructor(props) {
        super(props);
        this.state = {
            isSubmited: false,
            loadPanelVisible: false,
            contractList: null,
            statusList: null,
            typeList: null,
            paymentTermTypeList:null,
            customerList: null,
            addContractModelIsOpen: false,
            customerId: 0,
            contractStatusId: 0,
            constractTypeId: 0,
            paymentTermTypeId: 0,
            comments: "",
            startDate: new Date(),
            endDate: new Date(),
            filterCustomerId: 0,
            filterContractStatusId: 0,
            filterConstractTypeId: 0,
            filterPaymentTermTypeId: 0,
            filterStartDate: "",            
            filterEndDate: "",
            selectedItemKeys: [],
            selectedRowsData: [],
            customerContractId: 0,
            min:new Date(),
            allMode:[],
            checkBoxesMode: "always",
            billingTypeList :billingType,
            billingTypeName :"",
            errors: {
                customerId: 0,
                contractStatusId: 0,
                constractTypeId: 0,
                paymentTermTypeId: 0,
                comments: "",
                grdContractService:"",
                billingTypeName :""
            }
        }
    }

    async componentDidMount() {
        this.setState({
            loadPanelVisible: true
        });
        await this.getContractList();
        await this.getContractStatus();
        await this.getContractType();
        await this.getPaymentTermType();
        await this.getCustomer();
        await this.getCustomerContractService();

        this.setState({
            loadPanelVisible: false
        });
    }
    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift({
            location: 'after',
            widget: 'dxButton',
            options: {
                icon: 'refresh',
                onClick: this.getContractList.bind(this)
            }
        });
    }
    getContractList = async () => {
        try {
            var request = {};
            request.CustomerId = this.state.filterCustomerId;
            request.ContractTypeId = this.state.filterConstractTypeId;
            request.ContractStatusId = this.state.filterContractStatusId;
            request.PaymentTermsId = this.state.filterPaymentTermTypeId;
            request.ContractStart = this.state.filterStartDate === "" ? "" : formatDate(this.state.filterStartDate, "yyyy-MM-dd");
            request.ContractEnd = this.state.filterEndDate === "" ? "" : formatDate(this.state.filterEndDate, "yyyy-MM-dd")
            const requestParams = getRequestParams("POST", request);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'contractConfiguration/contractConfiguration', requestParams);
            const data = await response.json();            
            this.setState({
                contractList: data.payload
            });
            // this.setState({
            //     contractList: data.payload != null ? data.payload : []
            //     ,contractTypeId: data.payload !== null ? data.payload[0].contractTypeId : 0
            //     ,contractStatusId: data.payload !== null ? data.payload[0].contractStatusId : 0
            //     ,paymentTermTypeId: data.payload !== null ? data.payload[0].paymentTermsId : 0
            //     ,startDate: data.payload !== null ? formatDate(data.payload[0].contractStart, "MM/dd/yyyy hh:mm") : ""
            //     ,endDate: data.payload !== null ? formatDate(data.payload[0].contractEnd, "MM/dd/yyyy hh:mm") : ""
            // });
        } catch (err) {
            console.log("Error in " + this.displayName + ".getContractList function", err);
        }
    }
    getCustomer = async () => {
        try {
            var request = {};
            const requestParams = getRequestParams("GET", request);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'account/dropdownAccount/2', requestParams);
            const data = await response.json();
            this.setState({
                customerList: data.payload
            });
        } catch (err) {
            console.log("Error in " + this.displayName + ".getContractList function", err);
        }
    }
    getContractType = async () => {
        try {
            var request = {};
            const requestParams = getRequestParams("GET", request);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'contractConfiguration/contractType/0', requestParams);
            const data = await response.json();
            this.setState({
                typeList: data.payload
            });
        } catch (err) {
            console.log("Error in " + this.displayName + ".getContractList function", err);
        }
    }
    getContractStatus = async () => {
        try {
            var request = {};
            const requestParams = getRequestParams("GET", request);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'contractConfiguration/contractStatus/0', requestParams);
            const data = await response.json();
            this.setState({
                statusList: data.payload
            });
        } catch (err) {
            console.log("Error in " + this.displayName + ".getContractList function", err);
        }
    }
    getPaymentTermType = async () => {
        try {
            var request = {};
            const requestParams = getRequestParams("GET", request);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'paymentTermType/dropdownType', requestParams);
            const data = await response.json();

            this.setState({
                paymentTermTypeList: data.payload
            });
        } catch (err) {
            console.log("Error in " + this.displayName + ".getPaymentTermType function", err);
        }
    }
    getServiceMaster = async () => {
        try {
            var request = {};
            const requestParams = getRequestParams("GET", request);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'master/services/0', requestParams);
            const data = await response.json();
            this.setState({
                servicesource: data.payload,
                loadPanelVisible: false
            });
        } catch (err) {
            console.log("Error in " + this.displayName + ".getServiceMaster function", err);
            this.setState({
                loadPanelVisible: false
            });
        }
    }

    openContractModel = () => {
        this.setState({
            addContractModelIsOpen: true,
        })
    }

    openCustomerContractModel = (e, customerId) => {
        this.setState({
            addContractModelIsOpen: true,
            customerId: customerId
        })
    }
    handleStartDateChanged = (date) => {
        this.setState({ startDate: date.value });
    }

    handleEndDateChanged = (date) => {
        this.setState({ endDate: date.value });
    }

    handleCustomerChange = (selectedOption) => {
        this.setState({
            customerId: (selectedOption.selectedItem !== null ? selectedOption.selectedItem.id : 0),
        });
        let errors = this.state.errors;
        if (selectedOption.selectedItem !== null && selectedOption.selectedItem.id > 0) {
            //this.state.errors["customerId"] = "";
            errors.customerId = "";
            this.setState({
                errors:errors
            });
        }
    }
    handleBillingTypeChange = (selectedOption) => {
        this.setState({
            billingTypeName: (selectedOption.selectedItem !== null ? selectedOption.selectedItem.name : ""),
        });
        // let errors = this.state.errors;
        // if (selectedOption.selectedItem !== null && selectedOption.selectedItem.id > 0) {
        //     //this.state.errors["customerId"] = "";
        //     errors.customerId = "";
        //     this.setState({
        //         errors:errors
        //     });
        // }
    }
    handleContractStatusChange = (selectedOption) => {
        this.setState({
            contractStatusId: (selectedOption.selectedItem !== null ? selectedOption.selectedItem.id : 0),
        });
        let errors = this.state.errors;
        if (selectedOption.selectedItem !== null && selectedOption.selectedItem.id > 0) {
            //this.state.errors["contractStatusId"] = "";
            errors.contractStatusId = "";
            this.setState({
                errors:errors
            });
        }
    }
    handleContractTypeChange = (selectedOption) => {
        this.setState({
            constractTypeId: (selectedOption.selectedItem !== null ? selectedOption.selectedItem.id : 0),
        });
        let errors = this.state.errors;
        if (selectedOption.selectedItem !== null && selectedOption.selectedItem.id > 0) {
            //this.state.errors["constractTypeId"] = "";
            errors.constractTypeId = "";
            this.setState({
                errors: errors
            });
        }
    }
    handlePaymentTermTypeChange = (selectedOption) => {
        this.setState({
            paymentTermTypeId: (selectedOption.selectedItem !== null ? selectedOption.selectedItem.id : 0),
        });
        let errors = this.state.errors;

        if (selectedOption.selectedItem !== null && selectedOption.selectedItem.id > 0) {
            //this.state.errors["paymentTermTypeId"] = "";
            errors.paymentTermTypeId ="";
            this.setState({
                errors:errors
            });
        }
    }
    handleFilterStartDateChanged = (date) => {
        this.setState({
            filterStartDate: date.value,
        });
    }
    handleFilterEndDateChanged = (date) => {
        this.setState({ filterEndDate: date.value });
    }
    handleFilterCustomerChange = (selectedOption) => {
        this.setState({
            filterCustomerId: (selectedOption.selectedItem !== null ? selectedOption.selectedItem.id : 0),
        });
    }
    handleFilterContractStatusChange = (selectedOption) => {
        this.setState({
            filterContractStatusId: (selectedOption.selectedItem !== null ? selectedOption.selectedItem.id : 0),
        });
    }
    handleFilterContractTypeChange = (selectedOption) => {
        this.setState({
            filterConstractTypeId: (selectedOption.selectedItem !== null ? selectedOption.selectedItem.id : 0),
        });
    }
    handleFilterPaymentTermTypeChange = (selectedOption) => {
        this.setState({
            filterPaymentTermTypeId: (selectedOption.selectedItem !== null ? selectedOption.selectedItem.id : 0),
        });
    }

    handleValidation = () => {
        let formIsValid = true;
        try {
            let errors = this.state.errors;
            errors.customerId = 0;
            errors.contractStatusId = 0;
            errors.constractTypeId = 0;
            errors.paymentTermTypeId = 0;
            errors.comments = "";
            errors.billingTypeName ="";

            if (this.state.customerId === 0) {
                formIsValid = false;
                errors.customerId = "Please select customer.";
            }
            if (this.state.contractStatusId === 0) {
                formIsValid = false;
                errors.contractStatusId = "Please select status.";
            }
            if (this.state.constractTypeId === 0) {
                formIsValid = false;
                errors.constractTypeId = "Please select type.";
            }
            if (this.state.paymentTermTypeId === 0) {
                formIsValid = false;
                errors.paymentTermTypeId = "Please select payment term.";
            }
            if (this.state.comments.trim() === "") {
                formIsValid = false;
                errors.comments = "Please enter comment.";
            }
            if (this.state.billingTypeName.trim() === "") {
                formIsValid = false;
                errors.billingTypeName = "Please enter billing type.";
            }
            if (this.state.selectedItemKeys.length === 0){
                formIsValid = false;
                errors.grdContractService = "Please select service item.";
            }
            this.setState({ errors: errors });
        }
        catch (err) {
            console.log("Error in " + this.displayName + ".handleValidation function", err);
            this.setState({ isSubmited: false })
        }
        return formIsValid;
    }
    handleCustomerContractSubmit = async (e) => {
        e.preventDefault();
        try {
            if (this.handleValidation()) {
                let selectedRowKeys = this.state.selectedItemKeys;
                let list = this.state.servicesource.filter((tax) => selectedRowKeys.filter((id) => id == tax.rowIndex).length > 0);
                this.setState({ isSubmited: true });
                var newObject = {};
                newObject.CustomerId = this.state.customerId;
                newObject.ContractTypeId = this.state.constractTypeId;
                newObject.ContractStart = formatDate(this.state.startDate, "yyyy-MM-dd") + " " + "00:00:00";
                newObject.ContractEnd = formatDate(this.state.endDate, "yyyy-MM-dd") + " " + "23:59:59"
                newObject.ContractStatusId = this.state.contractStatusId;
                newObject.Comments = this.state.comments;
                newObject.PaymentTermsId = this.state.paymentTermTypeId;
                newObject.BillingType = this.state.billingTypeName;
                var contractServicesItemList = [];
                list.forEach((item, index) => {
                    var contractServicesItem = {};
                    contractServicesItem.Id = parseInt(item.id);
                    contractServicesItem.ItemName = item.name;
                    contractServicesItem.ServiceItemId = parseInt(item.serviceItemId);
                    contractServicesItem.BasedOnUnitId = parseInt(item.basedOnUnitId);
                    contractServicesItem.BasedOnUnit = item.basedOnUnit;
                    contractServicesItem.TermTypeId = parseInt(item.termTypeId);
                    contractServicesItem.TermType = item.termType;
                    contractServicesItem.UnitPrice = parseFloat(item.unitPrice);
                    contractServicesItem.SalePrice = parseFloat(item.salePrice);
                    contractServicesItem.Limit = item.limit;
                    contractServicesItem.Description = "";
                    contractServicesItem.WefDate = formatDate(new Date(), "MM/dd/yyyy hh:mm");
                    contractServicesItemList.push(contractServicesItem);
                });
                newObject.CustomerContractService = {};
                newObject.CustomerContractService = contractServicesItemList;
                const requestParams = getRequestParams('POST', newObject);
                const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'contractConfiguration/'+ this.state.customerContractId + '/contractConfiguration', requestParams);
                const data = await response.json();
                if (data.isSuccess) {
                    Swal.fire({
                        icon: 'success',
                        title: 'Success',
                        text: data.message,
                    }).then((result) => {
                        if (result.isConfirmed) {
                            this.getContractList();
                            this.closeItemModal();
                        }
                        this.setState({ isSubmited: false })
                    });
                }
                else {
                    toast.error(data.message, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        style: { width: "310px" },
                        hideProgressBar: false,
                        newestOnTop: false,
                        closeButton: false
                    });
                    this.setState({ isSubmited: false })
                }
            }
        } catch (err) {
            console.log("Error in " + this.displayName + ".handleCategorySubmit function", err);
            this.setState({ isSubmited: false })
        }
    }
    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({ [name]: value });
        this.state.errors[name] = "";
    }
    
    closeItemModal = () => {
        this.setState({
            addContractModelIsOpen: false,
            customerId: 0,
            contractStatusId: 0,
            constractTypeId: 0,
            paymentTermTypeId: 0,
            comments: "",
            startDate: new Date(),
            endDate: new Date(),
            customerContractId: 0,
            selectedItemKeys: [],
            selectedRowsData: [],
            billingTypeName :"",
            errors: {
                customerId: 0,
                contractStatusId: 0,
                constractTypeId: 0,
                paymentTermTypeId: 0,
                comments: "",
                grdContractService:"",
                billingTypeName : ""
            }
        })
    }
    handleClose = () => {
        this.setState({
            filterCustomerId: 0,
            filterContractStatusId: 0,
            filterConstractTypeId: 0,
            filterPaymentTermTypeId: 0,
            filterStartDate: "",
            filterEndDate: "",
            minDate:"",
        });
        setTimeout(() => this.getContractList(), 100); 
    }
    onExporting = (e) => {
        const doc = new jsPDF();
        if (e.format === "pdf") {
            exportDataGrid({
                jsPDFDocument: doc,
                component: e.component,
                columnWidths: 50
                // indent: 10,
            }).then(() => {
                doc.save('Contract Configuration.pdf');
            });
        }
    }
    handleServiceChanged = (data) => {
        
        this.setState({
            selectedItemKeys: data.selectedRowKeys,
            selectedRowsData: data.selectedRowsData,
        });
    }
    handleContractConfiguration = async (e, data) => {
        e.preventDefault();
        this.setState({
            addContractModelIsOpen: true,
            customerContractId: data.id,
            contractSoNo: data.soNo,
            min : data.contractStart
        });
        setTimeout(() => this.getContractDetails(), 500);
        setTimeout(() => this.getCustomerContractService(), 500);
    }

    getContractDetails = async () => {
        try {
            var request = {};
            request.CustomerId = 0;
            request.CustomerContractId = this.state.customerContractId;
            const requestParams = getRequestParams("POST", request);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'contractConfiguration/contractConfiguration', requestParams);            
            const data = await response.json();  
                      
            this.setState({
                customerId: data.payload !== null ? data.payload[0].customerId : 0
                ,constractTypeId: data.payload !== null ? data.payload[0].contractTypeId : 0
                ,contractStatusId: data.payload !== null ? data.payload[0].contractStatusId : 0
                ,paymentTermTypeId: data.payload !== null ? data.payload[0].paymentTermsId : 0
                ,startDate: data.payload !== null ? formatDate(data.payload[0].contractStart, "MM/dd/yyyy hh:mm") : ""
                ,endDate: data.payload !== null ? formatDate(data.payload[0].contractEnd, "MM/dd/yyyy hh:mm") : ""
                ,billingTypeName: data.payload !== null ? data.payload[0].billingType :""
            });
        } catch (err) {
            console.log("Error in " + this.displayName + ".getContractDetails function", err);
        }
    }

    getCustomerContractService = async () => {
        try {
            var request = {};
            request.Id = this.state.customerContractId;
            const requestParams = getRequestParams("POST", request);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'contractConfiguration/customerContractService', requestParams);
            const data = await response.json();            
            this.setState({
                servicesource: data.payload !== null ? data.payload : []
            });
            setTimeout(() => this.setContractDetails(), 500);
        } catch (err) {
            console.log("Error in " + this.displayName + ".getCustomerContractService function", err);
        }
    }

    setContractDetails = (e) => {
       
        let selectedRowKeys = [];
        let selectedRows = this.state.servicesource.filter((m) => { return m.id > 0; });
        for (let index = 0; index < selectedRows.length; index++) {
            selectedRowKeys.push(selectedRows[index].rowIndex);
        }

        this.setState({
            // contractTypeId: this.state.servicesource !== null ? this.state.servicesource[0].contractTypeId : 0
            // ,contractStatusId: this.state.servicesource !== null ? this.state.servicesource[0].contractStatusId : 0
            // ,paymentTermTypeId: this.state.servicesource !== null ? this.state.servicesource[0].paymentTermsId : 0
            // ,startDate: this.state.servicesource !== null ? formatDate(this.state.servicesource[0].contractStart, "MM/dd/yyyy hh:mm") : ""
            // ,endDate: this.state.servicesource !== null ? formatDate(this.state.servicesource[0].contractEnd, "MM/dd/yyyy hh:mm") : ""
            selectedItemKeys: selectedRowKeys
        });
    }
    onAllModeChanged = ({ value })=> {
        this.setState({ allMode: value });
      }
    handleRowPrepared = (e) => {
       
        if (e.rowType === 'data') {
            var months; var month;
            var d = new Date(e.data.contractEnd);
            var d1 = new Date();
            months = (d.getMonth() + 1);
            month = (d1.getMonth() + 1);
            var diffMonth = parseInt(months) - parseInt(month);
            if (diffMonth == 1 || diffMonth == -1) {
                e.rowElement.style.backgroundColor = '#ffd7d7';                                
            }

        }
    }
    render() {
        const renderGridCell = (data) => {
            return <div className='text-center'>
                <a href="/#" className="fas fa-pen" title="Edit Contract" onClick={(e) => { this.handleContractConfiguration(e, data.data) }} ></a>
            </div>;
        }

        const renderGridNumberCell = (data) => {
            return <div><small><i className={currencySymbolFormats.currencyFormat}></i></small>&nbsp;&nbsp;{parseFloat(data.value !== undefined ? data.value : 0).toFixed(2)}</div>;
        }

        return (
            <Fragment>
                <div className="section-body">
                    <div className='card bg-filter'>
                        <div className='card-body'>
                            <div className="section-header">
                                <div className='row'>
                                    <div className="col-lg-4">
                                        <div className="form-group">
                                            <label>Customer</label>
                                            <SelectBox
                                                dataSource={this.state.customerList}
                                                className="basic-multi-select"
                                                classNamePrefix="select"
                                                placeholder="Select customer"
                                                name="id"
                                                defaultValue={0}
                                                value={this.state.filterCustomerId}
                                                displayExpr='name'
                                                valueExpr='id'
                                                onSelectionChanged={this.handleFilterCustomerChange}
                                                showClearButton={true}
                                                disabled={false}
                                                searchEnabled={true}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-2">
                                        <div className="form-group">
                                            <label>Contract Type</label>
                                            <SelectBox
                                                dataSource={this.state.typeList}
                                                className="basic-multi-select"
                                                classNamePrefix="select"
                                                placeholder="Select contract type"
                                                name="id"
                                                defaultValue={0}
                                                value={this.state.filterConstractTypeId}
                                                displayExpr='name'
                                                valueExpr='id'
                                                onSelectionChanged={this.handleFilterContractTypeChange}
                                                showClearButton={true}
                                                disabled={false}
                                                searchEnabled={true}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-2">
                                        <div className="form-group">
                                            <label>Contract Status</label>
                                            <SelectBox
                                                dataSource={this.state.statusList}
                                                className="basic-multi-select"
                                                classNamePrefix="select"
                                                placeholder="Select contract status"
                                                name="id"
                                                defaultValue={0}
                                                value={this.state.filterContractStatusId}
                                                displayExpr='name'
                                                valueExpr='id'
                                                onSelectionChanged={this.handleFilterContractStatusChange}
                                                showClearButton={true}
                                                disabled={false}
                                                searchEnabled={true}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-2">
                                        <div className="form-group">
                                            <label>Contract Start</label>
                                            <DateBox type="date"
                                                className="replay-date-time"
                                                name="filterStartDate"
                                                placeholder="dd/mm/yyyy"
                                                displayFormat="dd/MM/yyyy"
                                                showAnalogClock={true}
                                                useMaskBehavior={true}
                                                defaultValue="dd/MM/yyyy"
                                                value={this.state.filterStartDate}
                                                onValueChanged={this.handleFilterStartDateChanged}
                                                width="100%"
                                                disabled={false}
                                                acceptCustomValue={false}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-2">
                                        <div className="form-group">
                                            <label>Contract End</label>
                                            <DateBox type="date"
                                                className="replay-date-time"
                                                name="filterEndDate"
                                                placeholder="dd/mm/yyyy"
                                                displayFormat="dd/MM/yyyy"
                                                showAnalogClock={true}
                                                useMaskBehavior={true}
                                                defaultValue="dd/MM/yyyy"
                                                value={this.state.filterEndDate}
                                                onValueChanged={this.handleFilterEndDateChanged}
                                                width="100%"                                                
                                                disabled={false}
                                                acceptCustomValue={false}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-2">
                                        <div className="form-group">
                                            <label>Payment Term</label>
                                            <SelectBox
                                                dataSource={this.state.paymentTermTypeList}
                                                className="basic-multi-select"
                                                classNamePrefix="select"
                                                placeholder="Select payment term"
                                                name="id"
                                                defaultValue={0}
                                                value={this.state.filterPaymentTermTypeId}
                                                displayExpr='name'
                                                valueExpr='id'
                                                onSelectionChanged={this.handleFilterPaymentTermTypeChange}
                                                showClearButton={true}
                                                disabled={false}
                                                searchEnabled={true}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-lg-4' style={{marginTop : '15px'}}>
                                        <button type="New" className="btn btn-success" onClick={this.getContractList}>
                                            <span title="Search">Search</span>
                                        </button>&nbsp;&nbsp;
                                        <button type="New" className="btn btn-outline-secondary" onClick={this.handleClose}>
                                            <span title="Clear">Clear</span>
                                        </button>&nbsp;&nbsp;
                                        <button type="New" className="btn btn-success" onClick={this.openContractModel}>
                                            New
                                        </button>
                                    </div>

                                </div>
                            </div>
                          
                        </div>
                    </div>
                    <div className='card'>
                    <div className='card-body'>
                                <DataGrid
                                    dataSource={this.state.contractList}
                                    keyExpr="id"
                                    selection={{ mode: 'single' }}
                                    columnsAutoWidth={false}
                                    showColumnLines={true}
                                    showRowLines={true}
                                    showBorders={true}
                                    rowAlternationEnabled={false}
                                    allowColumnResizing={true}
                                    columnResizingMode='widget'
                                    onExporting={this.onExporting}
                                    onRowPrepared={this.handleRowPrepared}
                                    onToolbarPreparing={this.onToolbarPreparing}>
                                    <Column dataField="id" width={30} caption="" allowFiltering={false} allowSorting={false} cellRender={renderGridCell} />
                                    <Column dataField="customerName" caption="Customer Name" allowSearch={true} allowFiltering={false} allowSorting={true} width={400} />
                                    <Column dataField="contractTypeName" caption="Contract Type" allowSearch={true} allowFiltering={false} allowSorting={true} width={130} />
                                    <Column dataField="contractStart" caption="Contract Start" allowSearch={true} allowFiltering={false} allowSorting={true} width={130} format={DateFormat.DateNorthFormat} dataType="datetime" />
                                    <Column dataField="contractEnd" caption="Contract End" allowSearch={true} allowFiltering={false} allowSorting={true} width={130} format={DateFormat.DateNorthFormat} dataType="datetime" />
                                    <Column dataField="contractStatusName" caption="Status" allowSearch={true} allowFiltering={false} allowSorting={true} width={110} />
                                    <Column dataField="paymentTermsName" caption="Payment Term" allowSearch={true} allowFiltering={false} allowSorting={true} width={150} />
                                    <Column dataField="comments" caption="Comment" allowSearch={true} allowFiltering={false} allowSorting={true}  />
                                    
                                    <SortByGroupSummaryInfo summaryItem="count" />
                                    <GroupPanel visible={false} />
                                    <HeaderFilter visible={true} />
                                    <SearchPanel visible={true} highlightCaseSensitive={true} width={240} placeholder="Search..." />
                                    <Grouping autoExpandAll={true} />
                                    <Paging defaultPageSize={15} />
                                    <Pager showPageSizeSelector={true} allowedPageSizes={[10, 20, 50]} showInfo={true} visible={true} />
                                    <Export enabled={true} allowExportSelectedData={false} fileName="Contract Configuration" />
                                </DataGrid>
                            </div>
                    </div>
                </div>
                <Modal ariaHideApp={false}
                    isOpen={this.state.addContractModelIsOpen}
                    style={customStyles}
                    className={"react-modal"}
                    onRequestClose={this.closeModel}
                    shouldCloseOnOverlayClick={false}
                    contentLabel="Example Modal">
                    <form method="post" style={this.state.isSubmited ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                        <div className="modal-header">
                            <h6 className="modal-title">{this.state.customerId == 0 ? 'New Contract' : 'Update Contract' }</h6>
                        </div>
                        <div className="modal-body">
                            <div className='row'>
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <label>Customer <span className="text-danger">*</span></label>
                                        <SelectBox
                                            dataSource={this.state.customerList}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select customer"
                                            name="id"
                                            defaultValue={0}
                                            value={this.state.customerId}
                                            displayExpr='name'
                                            valueExpr='id'
                                            onSelectionChanged={this.handleCustomerChange}
                                            showClearButton={true}
                                            disabled={false}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["customerId"].length > 0 && <span className="error invalid-feedback">{this.state.errors["customerId"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div className="form-group">
                                        <label>Contract Type <span className="text-danger">*</span></label>
                                        <SelectBox
                                            dataSource={this.state.typeList}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select contract type"
                                            name="id"
                                            defaultValue={0}
                                            value={this.state.constractTypeId}
                                            displayExpr='name'
                                            valueExpr='id'
                                            onSelectionChanged={this.handleContractTypeChange}
                                            showClearButton={true}
                                            disabled={false}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["constractTypeId"].length > 0 && <span className="error invalid-feedback">{this.state.errors["constractTypeId"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div className="form-group">
                                        <label>Contract Status <span className="text-danger">*</span></label>
                                        <SelectBox
                                            dataSource={this.state.statusList}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select contract status"
                                            name="id"
                                            defaultValue={0}
                                            value={this.state.contractStatusId}
                                            displayExpr='name'
                                            valueExpr='id'
                                            onSelectionChanged={this.handleContractStatusChange}
                                            showClearButton={true}
                                            disabled={false}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["contractStatusId"].length > 0 && <span className="error invalid-feedback">{this.state.errors["contractStatusId"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div className="form-group">
                                        <label>Billing Type <span className="text-danger">*</span></label>
                                        <SelectBox
                                            dataSource={this.state.billingTypeList}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select billing type"
                                            name="name"
                                            defaultValue={0}
                                            value={this.state.billingTypeName}
                                            displayExpr='name'
                                            valueExpr='name'
                                            onSelectionChanged={this.handleBillingTypeChange}
                                            showClearButton={true}
                                            disabled={false}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["billingTypeName"].length > 0 && <span className="error invalid-feedback">{this.state.errors["billingTypeName"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div className="form-group">
                                        <label>Payment Term <span className="text-danger">*</span></label>
                                        <SelectBox
                                            dataSource={this.state.paymentTermTypeList}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select payment term"
                                            name="id"
                                            defaultValue={0}
                                            value={this.state.paymentTermTypeId}
                                            displayExpr='name'
                                            valueExpr='id'
                                            onSelectionChanged={this.handlePaymentTermTypeChange}
                                            showClearButton={true}
                                            disabled={false}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["paymentTermTypeId"].length > 0 && <span className="error invalid-feedback">{this.state.errors["paymentTermTypeId"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div className="form-group">
                                        <label>Contract Start <span className="text-danger">*</span></label>
                                        <DateBox type="date"
                                            className="replay-date-time"
                                            name="startDate"
                                            placeholder="dd/mm/yyyy"
                                            displayFormat="dd/MM/yyyy"
                                            showAnalogClock={true}
                                            useMaskBehavior={true}
                                            defaultValue={this.state.startDate}
                                            value={this.state.startDate}
                                            onValueChanged={this.handleStartDateChanged}
                                            width="100%"
                                            disabled={false}
                                            acceptCustomValue={false}
                                            min= {this.state.min}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div className="form-group">
                                        <label>Contract End <span className="text-danger">*</span></label>
                                        <DateBox type="date"
                                            className="replay-date-time"
                                            name="endDate"
                                            placeholder="dd/mm/yyyy"
                                            displayFormat="dd/MM/yyyy"
                                            showAnalogClock={true}
                                            useMaskBehavior={true}
                                            defaultValue={this.state.endDate}
                                            value={this.state.endDate}
                                            onValueChanged={this.handleEndDateChanged}
                                            width="100%"
                                            disabled={false}
                                            acceptCustomValue={false}
                                            min={this.state.startDate}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="form-group">
                                        <label htmlFor="">Comment <span className="text-danger">*</span></label>
                                        <textarea type="text" className="form-control form-control-sm" name="comments" autoComplete="comments" placeholder="Enter comment"
                                            maxLength="150" title="Comments" value={this.state.comments} onChange={this.handleInputChange} />
                                        {this.state.errors["comments"].length > 0 && <span className="error invalid-feedback">{this.state.errors["comments"]}</span>}
                                    </div>
                                </div>                                
                            </div>                            
                            <div className="row">
                                <DataGrid
                                    ref="grdContractServiceRef"
                                    dataSource={this.state.servicesource}
                                    keyExpr="rowIndex"
                                    allowColumnReordering={true}
                                    showBorders={true}
                                    selectedRowKeys={this.state.selectedItemKeys}
                                    onSelectionChanged={this.handleServiceChanged}>
                                    <Selection 
                                    mode="multiple"
                                    showCheckBoxesMode={this.state.checkBoxesMode}
                                    />
                                    <Paging enabled={false} />
                                    <Editing mode="cell"
                                        allowUpdating={true}
                                        allowDeleting={false}
                                        allowAdding={false}
                                        editorOptions={true} />
                                    <Column dataField="name" caption="Service" allowSearch={true} allowEditing={false} allowFiltering={false} allowSorting={true} width={180} />
                                    <Column dataField="basedOnUnit" caption="Based On" allowSearch={true} allowEditing={false} allowFiltering={false} allowSorting={true} width={150} />
                                    <Column dataField="termType" caption="Term Type" allowSearch={true} allowEditing={false} allowFiltering={false} allowSorting={true} width={150} />
                                    <Column dataField="unitPrice" caption="Standard Unit Price" allowSearch={true} allowEditing={false} allowFiltering={false} allowSorting={true} width={150}
                                        dataType="number" cellRender={renderGridNumberCell} />
                                    <Column dataField="salePrice" caption="Sale Price" dataType="decimal" format={"#,##0.##"} alignment="right"
                                        cellRender={renderGridNumberCell}>
                                        <RequiredRule />
                                        <PatternRule message={'Sale Price should be decimal'} pattern={floatValidation} />
                                    </Column>
                                </DataGrid>
                                {this.state.errors["grdContractService"].length > 0 && <span className="error invalid-feedback">{this.state.errors["grdContractService"]}</span>}
                            </div>
                        </div>

                        <div className="modal-footer">
                            <button type="submit" className="btn btn-success" onClick={this.handleCustomerContractSubmit} >
                                {this.state.isSubmited && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                {!this.state.isSubmited && <span title="Submit">Submit</span>}
                            </button>
                            <button type="button" className="btn btn-outline-secondary" onClick={this.closeItemModal} title="Cancel">Cancel</button>
                        </div>
                    </form>
                </Modal>

                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={position}
                    visible={this.state.loadPanelVisible}
                    showIndicator={true}
                    shading={true}
                    showPane={true}
                    hideOnOutsideClick={false}
                />
            </Fragment>
        )
    };
}