import React, { Component, Fragment, Suspense } from "react";

//import { Route } from 'react-router';
import { BrowserRouter as Router, Route } from "react-router-dom"; //npm i --save react-router@latest
import { Wrapper } from "@googlemaps/react-wrapper";
import { Layout } from "./components/Layout";
import { AuthorisedLayout } from "./components/AuthorisedLayout";
import { LoginComponent } from "./components/LoginComponent";
import { ChangePasswordComponent } from "./components/shared/ChangePasswordComponent";
import { SalesOrderComponent } from "./components/sales/SalesOrderComponent";
import { EditCustomerVendorComponent } from "./components/accounts/EditCustomerVendorComponent";
import { PurchseOrderListComponent } from "./components/purchase/PurchseOrderListComponent";
import { PurchseOrderComponent } from "./components/purchase/PurchseOrderComponent";
import { SalesReturnListComponent } from "./components/sales/SalesReturnListComponent";
import { SalesReturnComponent } from "./components/sales/SalesReturnComponent";
import { PurchseReturnListComponent } from "./components/purchase/PurchseReturnListComponent";
import { PurchseReturnComponent } from "./components/purchase/PurchseReturnComponent";
import { CreditNotesComponent } from "./components/sales/CreditNotesComponent";
import { DebitNotesComponent } from "./components/purchase/DebitNotesComponent";
import { PaymentComponent } from "./components/payment/PaymentComponent";
import { PaymentReceivableComponent } from "./components/billing/PaymentReceivableComponent";
import { PaymentPayblesComponent } from "./components/billing/PaymentPayblesComponent";
import { CategoryComponent } from "./components/product/CategoryComponent";
import { DashboardComponent } from "./components/dashboard/DashboardComponent";
import { ItemComponent } from "./components/product/ItemComponent";
import { HsnCodeComponent } from "./components/settings/HsnCodeComponent";
import { ItemAttributeComponent } from "./components/product/ItemAttributeComponent";
import { GenerateInvoiceComponent } from "./components/billing/GenerateInvoiceComponent";
import { InvoiceListComponent } from "./components/billing/InvoiceListComponent";
import { InvoicesComponent } from "./components/billing/InvoicesComponent";

import { UsersComponent } from "./components/users/UsersComponent";
import { CustomerComponent } from "./components/accounts/CustomerComponent";
import { AddCustomerVendorComponent } from "./components/accounts/AddCustomerVendorComponent";
import { ContractConfigurationComponent } from "./components/configuration/ContractConfigurationComponent";
import { AttributeComponent } from "./components/master/AttributeComponent";
import { AttributeTypeComponent } from "./components/master/AttributeTypeComponent";
import { TaxConfigurationComponent } from "./components/configuration/TaxConfigurationComponent";
import { VendorComponent } from "./components/accounts/VendorComponent";
import { SalesOrderListComponent } from "./components/sales/SalesOrderListComponent";
import { StockComponent } from "./components/stock/StockComponent";
import { QCComponent } from "./components/stock/QCComponent";
import { ServiceComponent } from "./components/settings/ServiceComponent";
import { CourierComponent } from "./components/accounts/CourierComponent";
import { TaxComponent } from "./components/settings/TaxComponent";
import { EventLogComponent } from "./components/EventLog/EventLogComponent";

import { BillsListComponent } from "./components/billing/BillsListComponent";
import { BillsComponent } from "./components/billing/BillsComponent";
import { InvoicePaymentList } from "./components/billing/InvoicePaymentList";
import { InvoicePayment } from "./components/billing/InvoicePayment";
import { WarehouseComponent } from "./components/master/WarehouseComponent";

import { BillPaymentListComponent } from "./components/payment/BillPaymentListComponent";
import { BillPaymentComponent } from "./components/payment/BillPaymentComponent";
import { CreditNotesListComponent } from "./components/sales/CreditNotesListComponent";
import { DebitNotesListComponent } from "./components/purchase/DebitNotesListComponent";
import { HSNTaxComponent } from "./components/configuration/HSNTaxComponent";
import { ItemCurrentStatusComponent } from "./components/stock/ItemCurrentStatusComponent";
import { InvoicePdfComponent } from "./components/billing/InvoicePdfComponent";
import { InvoicePdfDetailsComponent } from "./components/billing/InvoicePdfDetailsComponent";
import { ActiveDevicesComponent } from "./components/stock/ActiveDevicesComponent";
import { ContractItemComponent } from "./components/configuration/ContractItemComponent";
import { ContractItemDetailComponent } from "./components/configuration/ContractItemDetailComponent";
import { RequestRequisitionComponent } from "./components/Operations/RequestRequisitionComponent";
import { CustomerMappingComponent } from "./components/master/CustomerMappingComponent";

// import { Layout } from './components/Layout';
// import { AuthorisedLayout } from './components/AuthorisedLayout';
//const Layout = lazy(() => import('./components/Layout').then(module => ({ default: module.Layout })));
//const AuthorisedLayout = lazy(() => import('./components/AuthorisedLayout').then(module => ({ default: module.AuthorisedLayout })));
//const LoginComponent = lazy(() => import('./components/LoginComponent').then(module => ({ default: module.LoginComponent })));
//const DashboardComponent = lazy(() => import('./components/dashboard/DashboardComponent').then(module => ({ default: module.DashboardComponent })));
//const ChangePasswordComponent = lazy(() => import('./components/shared/ChangePasswordComponent').then(module => ({ default: module.ChangePasswordComponent })));
//const ItemComponent = lazy(() => import('./components/product/ItemComponent').then(module => ({ default: module.ItemComponent })));
//const HsnCodeComponent = lazy(() => import('./components/settings/HsnCodeComponent').then(module => ({ default: module.HsnCodeComponent })));
//const CategoryComponent = lazy(() => import('./components/product/CategoryComponent').then(module => ({ default: module.CategoryComponent })));
//const ItemAttributeComponent = lazy(() => import('./ffcomponents/product/ItemAttributeComponent').then(module => ({ default: module.ItemAttributeComponent })));
// const SalesOrderComponent = lazy(() => import('./components/sales/SalesOrderComponent').then(module => ({ default: module.SalesOrderComponent })));

// const UsersComponent = lazy(() => import('./components/users/UsersComponent').then(module => ({ default: module.UsersComponent })));
// const CustomerComponent = lazy(() => import('./components/accounts/CustomerComponent').then(module => ({ default: module.CustomerComponent })));
// const AddCustomerVendorComponent = lazy(() => import('./components/accounts/AddCustomerVendorComponent').then(module => ({ default: module.AddCustomerVendorComponent })));
// const ContractConfigurationComponent = lazy(() => import('./components/configuration/ContractConfigurationComponent').then(module => ({ default: module.ContractConfigurationComponent })));
// const AttributeComponent = lazy(() => import('./components/master/AttributeComponent').then(module => ({ default: module.AttributeComponent })));
// const AttributeTypeComponent = lazy(() => import('./components/master/AttributeTypeComponent').then(module => ({ default: module.AttributeTypeComponent })));
// const TaxConfigurationComponent = lazy(() => import('./components/configuration/TaxConfigurationComponent').then(module => ({ default: module.TaxConfigurationComponent })));
// const VendorComponent = lazy(() => import('./components/accounts/VendorComponent').then(module => ({ default: module.VendorComponent })));
// const SalesOrderListComponent = lazy(() => import('./components/sales/SalesOrderListComponent').then(module => ({ default: module.SalesOrderListComponent })));
// const StockComponent = lazy(() => import('./components/stock/StockComponent').then(module => ({ default: module.StockComponent })));
// const QCComponent = lazy(() => import('./components/stock/QCComponent').then(module => ({ default: module.QCComponent })));
// const ServiceComponent = lazy(() => import('./components/settings/ServiceComponent').then(module => ({ default: module.ServiceComponent })));
// const CourierComponent = lazy(() => import('./components/accounts/CourierComponent').then(module => ({ default: module.CourierComponent })));
// const TaxComponent = lazy(() => import('./components/settings/TaxComponent').then(module => ({ default: module.TaxComponent })));
// const EventLogComponent= lazy(() => import('./components/eventlog/EventLogComponent').then(module => ({default: module.EventLogComponent })));

//const CreditNotesComponent = lazy(() => import('./components/sales/CreditNotesComponent').then(module => ({ default: module.CreditNotesComponent })));

export default class App extends Component {
  static displayName = App.name;

  constructor(props) {
    super(props);
  }

  render() {
    const isAuthorized =
      sessionStorage.getItem("sessionUserLoggedin") === "false" ||
      sessionStorage.getItem("sessionUserLoggedin") === null
        ? false
        : true;

    return (
      <Fragment>
        <Router>
          {isAuthorized === true ? (
            <AuthorisedLayout>
              <Route path="/dashboard" component={DashboardComponent} />
              <Route
                path="/changepassword"
                component={ChangePasswordComponent}
              />
              <Route path="/item" component={ItemComponent} />
              <Route path="/hsncode" component={HsnCodeComponent} />
              <Route path="/category" component={CategoryComponent} />
              <Route path="/itemattribute" component={ItemAttributeComponent} />
              <Route path="/salesorder/new" component={SalesOrderComponent} />
              <Route path="/users" component={UsersComponent} />
              <Route
                path="/addaccount"
                component={AddCustomerVendorComponent}
              />
              <Route path="/customer" component={CustomerComponent} />
              <Route path="/attribute" component={AttributeComponent} />
              <Route
                path="/contract"
                component={ContractConfigurationComponent}
              />
              <Route
                path="/taxconfiguration"
                component={TaxConfigurationComponent}
              />
              <Route path="/attributeType" component={AttributeTypeComponent} />
              <Route path="/vendor" component={VendorComponent} />
              <Route
                path="/salesorder/list"
                component={SalesOrderListComponent}
              />
              <Route
                path="/editaccount"
                component={EditCustomerVendorComponent}
              />
              <Route
                path="/purchaseorder/list"
                component={PurchseOrderListComponent}
              />
              <Route
                path="/purchaseorder/new"
                component={PurchseOrderComponent}
              />
              <Route
                path="/salereturn/list"
                component={SalesReturnListComponent}
              />
              <Route path="/salereturn/new" component={SalesReturnComponent} />
              <Route
                path="/purchasereturn/list"
                component={PurchseReturnListComponent}
              />
              <Route
                path="/purchasereturn/new"
                component={PurchseReturnComponent}
              />
              <Route path="/stock" component={StockComponent} />
              <Route path="/qualitycheck" component={QCComponent} />
              <Route path="/serviceitem" component={ServiceComponent} />
              <Route path="/courier" component={CourierComponent} />
              <Route path="/tax" component={TaxComponent} />
              <Route
                path="/generateinvoice"
                component={GenerateInvoiceComponent}
              />
              <Route path="/invoice/list" component={InvoiceListComponent} />
              <Route path="/invoice/new" component={InvoicesComponent} />
              <Route path="/eventlog" component={EventLogComponent} />
              <Route
                path="/debitnote/list"
                component={DebitNotesListComponent}
              />
              <Route path="/debitnote/new" component={DebitNotesComponent} />
              <Route path="/payment" component={PaymentComponent} />
              <Route path="/invoicepayment/new" component={InvoicePayment} />
              <Route
                path="/paymentReceivable"
                component={PaymentReceivableComponent}
              />
              <Route
                path="/paymentpayables"
                component={PaymentPayblesComponent}
              />
              <Route path="/bill/list" component={BillsListComponent} />
              <Route path="/bill/new" component={BillsComponent} />
              {/* <Route path='/billpayment' component={BillPaymentComponent} /> */}
              <Route
                path="/invoicepayment/list"
                component={InvoicePaymentList}
              />
              <Route path="/warehouse" component={WarehouseComponent} />
              <Route
                path="/billpayment/list"
                component={BillPaymentListComponent}
              />
              <Route path="/billpayment/new" component={BillPaymentComponent} />
              <Route
                path="/creditnote/list"
                component={CreditNotesListComponent}
              />
              <Route path="/creditnote/new" component={CreditNotesComponent} />
              <Route path="/hsntax" component={HSNTaxComponent} />
              <Route
                path="/itemcurrentstatus"
                component={ItemCurrentStatusComponent}
              />
              <Route path="/invoicepdf" component={InvoicePdfComponent} />
              <Route
                path="/invoicepdfdetail"
                component={InvoicePdfDetailsComponent}
              />
              <Route path="/activedevices" component={ActiveDevicesComponent} />
              <Route path="/contractitem" component={ContractItemComponent} />
              <Route
                path="/contractitemdetails"
                component={ContractItemDetailComponent}
              />
              <Route
                path="/requestrequisition"
                component={RequestRequisitionComponent}
              />
              <Route
                path="/customermapping"
                component={CustomerMappingComponent}
              />
            </AuthorisedLayout>
          ) : (
            <Layout>
              <Suspense fallback={<div>Loading ...</div>}>
                <Route exact path="/" component={LoginComponent} />
                <Route path="/login" component={LoginComponent} />
              </Suspense>
            </Layout>
          )}
        </Router>
      </Fragment>
    );
  }
}
