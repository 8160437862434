import React, { Fragment, Component } from 'react';
import DataGrid, {
    Column,
    Export, Grouping,
    GroupPanel,
    HeaderFilter, Pager,
    Paging,
    SearchPanel,
    SortByGroupSummaryInfo
} from 'devextreme-react/data-grid'; //npm i devextreme-react

import { getRequestParams } from '../../helpers/globalfunctions.js';
import { exportFormats } from '../../helpers/fixcodes.js';
import { jsPDF } from 'jspdf';
import { exportDataGrid } from 'devextreme/pdf_exporter';


export class PaymentPayblesComponent extends Component {
    static displayName = PaymentPayblesComponent.name;

    constructor(props) {
        super(props);
        this.state = {
            paymentpayableList: [],
        }
    }
    
    async componentDidMount() {
        await this.getPaymentPayableMaster();
    }

    getPaymentPayableMaster = async () => {
        try {
            var request = {};
            //when passing GET method 
            const requestParams = getRequestParams("GET");
            //when you use post method with request 
            //const requestParams = getRequestParams("GET", request);
            
            //pass the id value 
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'paymentpayable/0', requestParams);
            //when you use post method with request 
            //const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'invoicepayment/id', requestParams);

            const data = await response.json();
            this.setState({
                paymentpayableList: data.payload
            });
        } catch (err) {
            console.log("Error in " + this.displayName + ".getPaymentPayableMaster function", err);
        }
    }

    onExporting = (e) => {
        const doc = new jsPDF();
        if (e.format === "pdf") {
            exportDataGrid({
                jsPDFDocument: doc,
                component: e.component,
                columnWidths: 50,
               // indent: 10,
            }).then(() => {
                doc.save(e.fileName);
            });            
        }
    }

    handleCategoryModal = async (e) => {
        this.setState({
            categoryModal: true,
            singelData: null
        });        
    }

    render() {
        return (
            <Fragment>
                <div className="section-body">
                    <div className='card'>
                        <div className='card-body'>
                            <div className="section-header">
                                <label>Payment Receivable</label>
                                <div className='mr-3'></div>
                                <div>
                                    &nbsp;
                                </div> 
                                <div className="section-header-buttons">
                                    <button type="submit" className="btn btn-success" onClick={this.handleCategoryModal}>
                                        New
                                    </button>&nbsp;&nbsp;
                                </div>
                            </div>
                        </div>

                        <DataGrid
                            dataSource={this.state.paymentpayableList}
                            keyExpr="id"
                            id='paymentrecivableList'
                            selection={{ mode: 'single' }}
                            columnsAutoWidth={false}
                            showColumnLines={true}
                            showRowLines={true}
                            showBorders={true}
                            rowAlternationEnabled={false}
                            allowColumnResizing={true}
                            onExporting={this.onExporting}
                            columnResizingMode='widget'
                            onToolbarPreparing={this.onToolbarPreparing}>

                            <Column dataField="invoiceNo" caption="Invoice#" allowSearch={true} allowFiltering={false} allowSorting={true} width={180} />
                            <Column dataField="vendor" caption="Vendor" allowSearch={true} allowFiltering={false} allowSorting={true} width={180} />
                            <Column dataField="orderNo" caption="Order#" allowSearch={true} allowFiltering={false} allowSorting={true} width={200} />
                            <Column dataField="paymentTerm" caption="Payment Term" allowSearch={true} allowFiltering={false} allowSorting={true} width={250} />
                            <Column dataField="taxTotal" caption="Tax Total" allowSearch={true} allowFiltering={false} allowSorting={true} width={180} />
                            <Column dataField="grossTotal" caption="Gross Total" allowSearch={true} allowFiltering={false} allowSorting={true} width={180} />
                            <Column dataField="dueAmount" caption="Due Amount" allowSearch={true} allowFiltering={false} allowSorting={true} width={180} />

                            <SortByGroupSummaryInfo summaryItem="count" />
                            <GroupPanel visible={false} />
                            {/* <Template name="toolbarTemplate" /> */}
                            <HeaderFilter visible={true} />
                            <SearchPanel visible={true} highlightCaseSensitive={true} width={240} placeholder="Search..." />
                            <Grouping autoExpandAll={true} />
                            <Paging defaultPageSize={20} />
                            <Pager showPageSizeSelector={true} allowedPageSizes={[10, 20, 50]} showInfo={true} visible={true} />
                            <Export enabled={true} formats={exportFormats} allowExportSelectedData={false} fileName="PaymentPayable" />
                        </DataGrid>
                        
                    </div>
                </div>
            </Fragment>
        )
    };
}
