import React, { Fragment, Component } from 'react';
import DataGrid, {
    Column,
    Paging,
    SortByGroupSummaryInfo,
    Editing, Lookup,
    RequiredRule,
    PatternRule,
    StringLengthRule,
    Summary, TotalItem,
} from 'devextreme-react/data-grid'; //npm i devextreme-react
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import { getRequestParams, formatDate } from '../../helpers/globalfunctions.js';
import { alphanumericValidation, floatValidation, numberValidation } from '../../helpers/globalvalidations.js';
import { precisionFormats, currencySymbolFormats, orderType,documentType } from '../../helpers/fixcodes.js';
import DateBox from 'devextreme-react/date-box';
import { LoadPanel } from 'devextreme-react/load-panel';
import { SelectBox } from 'devextreme-react/select-box';
import Swal from 'sweetalert2'; //npm i sweetalert2 --save
import Modal from 'react-modal'; //npm install --save react-modal
import axios from 'axios'; //npm install--save axios

const position = { of: '#historydiv' };
const customStyles = {
    content: {
        width: '50%',
    }
}
export class CreditNotesComponent extends Component {
    static displayName = CreditNotesComponent.name;
    constructor(props) {
        super(props);
        this.grdCreditNoteAttributesRef = React.createRef();
        this.state = {
            isSubmited: false,
            loadPanelVisible: false,
            documentModelIsOpen: false,
            accountList: null,
            itemList: [],
            itemDetails: [],
            itemDetailsGrid :null,
            serviceItemList: null,
            hsnsacList: null,
            invoiceList: null,
            id: 0,
            invoiceId: 0,
            accountId: this.props.location.state === undefined ? 0 : this.props.location.state.customerId,
            referenceNo: this.props.location.state === undefined ? "" : this.props.location.state.creditNoteNo,
            creditDate: this.props.location.state === undefined ? new Date() : this.props.location.state.creditNoteDate,
            invoiceNo: this.props.location.state === undefined ? "" : this.props.location.state.invoiceNo,
            validTill: this.props.location.state === undefined ? new Date() : this.props.location.state.validTill,
            discount: 0.00,
            grossAmount: this.props.location.state === undefined ? 0.00 : this.props.location.state.grossAmount,
            remarks: this.props.location.state === undefined ? "" : this.props.location.state.remarks,
            accountTaxList: [],
            sessionUser: JSON.parse(sessionStorage.getItem("sessionUser")),
            creditNoteId: this.props.location.state === undefined ? 0 : this.props.location.state.creditNoteId,
            imageList: [],
            pdfList: [],
            excelList: [],
            otherList: [],
            minimiumDate: new Date(),
            documentSelectedFile: [],
            documentCreditNoteId: 0,
            resultMeassage: "",
            targetFile: [],
            inputValue: "",
            invoiceTypeId :0,
            isNewItem : false,
            errors: {
                accountId: 0,
                referenceNo: "",
                invoiceNo: "",
                itemDetailsGrid: "",
                remarks: ""
            }
        }

        this.assginData = this.assginData.bind(this);
        this.handleCreditNoteSubmit = this.handleCreditNoteSubmit.bind(this);
    }

    async componentDidMount() {
        this.setState({
            loadPanelVisible: true
        });
        await this.getAccounts();
        await this.getItemMaster();
        await this.getHsnCodeMaster();
        await this.getInvoice();
        // await this.getAccountTax();

        if (this.state.creditNoteId > 0) {
            await this.getCreditNoteMaster();
        }

        this.setState({
            loadPanelVisible: false
        });
    }

    getCreditNoteMaster = async () => {
        this.setState({
            loadPanelVisible: true
        });
        try {
            var request = {};
            request.FromDate = '';
            request.ToDate = formatDate(this.state.toDate, "yyyy-MM-dd") + ' ' + '23:59:59';
            request.Id = parseInt(this.state.creditNoteId);
            // request.CustomerId = parseInt(this.state.accountId);
            request.InvoiceNo = "";
            // request.OrderNo = this.state.orderNo;
            const requestParams = getRequestParams("POST", request);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'creditnote/creditNoteDetails', requestParams);
            const data = await response.json();

            this.setState({
                salesordersource: data.payload,
                loadPanelVisible: false
            });
            this.assginData(data.payload);
        } catch (err) {
            console.log("Error in " + this.displayName + ".getCreditNoteMaster function", err);
            this.setState({
                loadPanelVisible: false
            });

            if (this.state.creditNoteId > 0) {
                // let myVar = setTimeout(() => this.assginData(), 500);
            }
        }
    }

    assginData = (salesOrder) => {

        if (salesOrder !== null && salesOrder !== undefined) {
            var creditNotes = salesOrder.creditNotes;
            var creditNoteDetails = salesOrder.creditNoteDetails;

            this.getInvoice();
            this.getAccountTax(creditNotes.customerId);
            setTimeout(() => this.getItemDetailsList(creditNotes.invoiceId,creditNotes.invoiceTypeId), 100);
            this.setState({
                id: creditNotes.id,
                accountId: creditNotes.customerId,
                // orderNo: orderSummary.soNo,
                // orderDate: formatDate(orderSummary.orderDate, "MM/dd/yyyy hh:mm"),
                // shippingDate: formatDate(orderSummary.shippingDate, "MM/dd/yyyy hh:mm"),
                // paymentTermId: orderSummary.paymentTermsId,
                // shippingCharges: parseFloat(orderSummary.shippingCharges).toFixed(precisionFormats[2]),
                // discount: parseFloat(orderSummary.discount).toFixed(precisionFormats[2]),
                // otherCharges: parseFloat(orderSummary.otherCharges).toFixed(precisionFormats[2]),
                grossAmount: parseFloat(creditNotes.grossAmount).toFixed(precisionFormats[2]),
                remarks: creditNotes.remarks,
                // termsAndConditions: orderSummary.termsCondition,
                itemDetailsGrid: creditNoteDetails,
                // orderStatusId: orderSummary.orderStatusId,
                // orderTypeId: orderSummary.orderTypeId,
                // contractTypeId: orderSummary.contractTypeId,
                // contractStatusId: orderSummary.contractStatusId,
                // paymentTermTypeId: orderSummary.contractPaymentTermsId,
                // startDate: orderSummary.contractStart,
                // endDate: orderSummary.contractEnd,
            });
           
        }
    }

    getInvoice = async () => {
        try {
            var request = {};
            const requestParams = getRequestParams("GET", request);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'invoice/getInvoiceForCreditNote', requestParams);
            const data = await response.json();
            this.setState({
                invoiceList: data.payload
            });
        } catch (err) {
            console.log("Error in " + this.displayName + ".getInvoice function", err);
        }
    }

    getAccounts = async () => {
        try {
            var request = {};
            const requestParams = getRequestParams("GET", request);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'account/dropdownAccount/2', requestParams);
            const data = await response.json();
            this.setState({
                accountList: data.payload
            });
        } catch (err) {
            console.log("Error in " + this.displayName + ".getAccounts function", err);
        }
    }


    getItemMaster = async () => {
        try {
            var request = {};
            request.CategoryId = 0;
            request.HSNId = 0;
            request.ItemId = 0;
            const requestParams = getRequestParams("POST", request);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'items/item/', requestParams);
            const data = await response.json();
            this.setState({
                itemList: data.payload != null ? data.payload : []
            });
        } catch (err) {
            console.log("Error in " + this.displayName + ".getItemMaster function", err);
        }
    }
    getHsnCodeMaster = async () => {
        try {
            var request = {};
            const requestParams = getRequestParams("GET", request);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'hsnsacCode/hscacccodes/0', requestParams);
            const data = await response.json();
            this.setState({
                hsnsacList: data.payload,
            });
        } catch (err) {
            console.log("Error in " + this.displayName + ".getHsnCodeMaster function", err);
        }
    }

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        let errors = this.state.errors;

        switch (name) {
            // case 'OrderNo':
            //     if (alphanumericValidation.test(event.target.value))
            //         this.setState({ orderNo: event.target.value });
            //     break;
            case 'Remarks':
                if (alphanumericValidation.test(event.target.value))
                    this.setState({ remarks: event.target.value });
                break;
            case 'ReferenceNo':
                if (alphanumericValidation.test(event.target.value))
                    this.setState({ referenceNo: event.target.value });
                break;
            default:
                this.setState({ [name]: value });
                break;
        }

        errors[name] = "";
        this.setState({
            errors: errors
        });

        //this.state.errors[name] = "";
    }

    getAccountTax = async (id) => {
        try {

            var request = {};
            const requestParams = getRequestParams("GET", request);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'taxConfiguration/accountTax/' + id, requestParams);
            const data = await response.json();
            this.setState({
                accountTaxList: data.payload != null ? data.payload : [],
            });

        } catch (err) {
            console.log("Error in " + this.displayName + ".getContractList function", err);
        }
    }

    onRowInserting = async (e) => {
        this.setState({ isNewItem: true });
    }

    handleCreditNoteDateChanged = (date) => {
        this.setState({ creditDate: date.value });
    }
    handleValidTillDateChanged = (date) => {
        this.setState({ validTill: date.value });
    }
    handleShippingChargesChange = (event) => {
        const newValue = event.value;
        this.setState({ "shippingCharges": newValue });
    }

    handleDiscountChange = (event) => {
        const newValue = event.value;
        this.setState({ "discount": newValue });
    }

    handleOtherChargesChange = (event) => {
        const newValue = event.value;
        this.setState({ "otherCharges": newValue });
    }

    handleGrossAmountChange = (event) => {
        const newValue = event.value;
        this.setState({ "grossAmount": newValue });
    }

    handleAccountChange = (selectedOption) => {
        this.setState({
            accountId: (selectedOption.selectedItem !== null ? selectedOption.selectedItem.id : 0),
        });
        let errors = this.state.errors;

        if (selectedOption.selectedItem !== null && selectedOption.selectedItem.id > 0) {
            //this.state.errors["accountId"] = "";
            errors.accountId = "";
            this.setState({
                errors: errors
            });
            setTimeout(() => this.getAccountTax(selectedOption.selectedItem.id), 500);
        }
    }
    handleInvoiceChange = (selectedOption) => {
        this.setState({
            invoiceNo: (selectedOption.selectedItem !== null ? selectedOption.selectedItem.invoiceNo : ""),
            invoiceTypeId :(selectedOption.selectedItem !== null ? selectedOption.selectedItem.invoiceTypeId : 0)
        });
        if (selectedOption.selectedItem !== null && selectedOption.selectedItem.id > 0) {
            this.getItemDetailsList(selectedOption.selectedItem.id,selectedOption.selectedItem.invoiceTypeId);
        }
    
        let errors = this.state.errors;

        if (selectedOption.selectedItem !== null && selectedOption.selectedItem.invoiceNo != "") {
            //this.state.errors["accountId"] = "";
            errors.accountId = "";
            this.setState({
                errors: errors
            });

        }

    }
    getItemDetailsList = async (id,typeid) => {
        this.setState({
            loadPanelVisible: true
        });
        try {
            
            var request = {};
            const requestParams = getRequestParams("GET", request);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'invoice/getDetailsForCreditNote/' + id + '/' + typeid, requestParams);
            const data = await response.json();
            
            this.setState({
                itemDetails: data.payload,
                accountId :data.payload[0].customerId,
                loadPanelVisible: false
            });
            //this.assginDataItem(data.payload);
        } catch (err) {
            console.log("Error in " + this.displayName + ".getInvoiceList function", err);
            this.setState({
                loadPanelVisible: false
            });


        }
    } 

    // assginDataItem = (salesOrder) => {
    //    if (salesOrder !== null && salesOrder !== undefined) {
    //         var invoiceDetails = salesOrder.invoiceDetails;
    //         this.setState({
    //             itemDetails: invoiceDetails,
    //             accountId :invoiceDetails[0].customerId,
    //             loadPanelVisible: false
    //         });
    //     }
    // }


    calculateQtyChange = (newData, value, currentRowData) => {
        newData.qty = parseInt(value);
        var totalItemPrice = 0.00;
        totalItemPrice = currentRowData.price !== undefined ? parseFloat(currentRowData.price, 3) * value : 0;
        var totalTax = 0.00;
        if (totalItemPrice > 0) {
            if (this.state.accountTaxList.length > 0) {
                this.state.accountTaxList.map((taxitem, i) => {
                    var singleTax = parseFloat(((totalItemPrice * taxitem.percentage) / 100), 2);
                    totalTax = totalTax + singleTax;
                });
            }
        }
        newData.taxAmount = parseFloat(totalTax, 2).toFixed(precisionFormats[2]);
        newData.totalAmount = (parseFloat(totalTax, 2) + parseFloat(totalItemPrice, 3)).toFixed(precisionFormats[2]);
    }


    calculatePriceChange = (newData, value, currentRowData) => {
        newData.price = parseFloat(value).toFixed(precisionFormats[2]);
        var totalItemPrice = 0.00;
        currentRowData.qty = (parseInt(this.state.orderTypeId) === parseInt(orderType.Serviceopex)) ? 1 : currentRowData.qty;
        totalItemPrice = currentRowData.qty !== undefined ? parseFloat(currentRowData.qty, 3) * value : 0;
        var totalTax = 0.00;
        if (totalItemPrice > 0) {
            if (this.state.accountTaxList.length > 0) {
                this.state.accountTaxList.map((taxitem, i) => {
                    var singleTax = parseFloat(((totalItemPrice * taxitem.percentage) / 100), 2);
                    totalTax = totalTax + singleTax;
                });
            }
        }
        newData.taxAmount = parseFloat(totalTax, 2).toFixed(precisionFormats[2]);
        newData.totalAmount = (parseFloat(totalTax, 2) + parseFloat(totalItemPrice, 3)).toFixed(precisionFormats[2]);
    }

    calculateDiscountChange = (newData, value, currentRowData) => {
        newData.discount = parseFloat(value).toFixed(precisionFormats[2]);
        newData.totalAmount = (parseFloat(currentRowData.totalAmount) - parseFloat(value)).toFixed(precisionFormats[2]);
    }


    handleValidation = () => {
        let formIsValid = true;
        try {
            let errors = this.state.errors;
            errors.accountId = 0;
            errors.referenceNo = "";
            errors.invoiceNo = "";
            errors.remarks = "";
            errors.itemDetailsGrid = ""
            if (this.state.accountId === 0) {
                formIsValid = false;
                errors.accountId = "Please select customer.";
            }
            if (this.state.remarks.trim() === "") {
                formIsValid = false;
                errors.remarks = "Please enter remarks.";
            }
            if (this.state.itemDetailsGrid.length == 0) {
                formIsValid = false;
                errors.itemDetailsGrid = "Please enter item(s).";
            }
            if (this.state.invoiceNo.trim() === "") {
                formIsValid = false;
                errors.invoiceNo = "Please select invoice.";
            }
            if (this.state.referenceNo.trim() === "") {
                formIsValid = false;
                errors.referenceNo = "Please enter reference number.";
            }

            this.setState({ errors: errors });
        }
        catch (err) {
            console.log("Error in " + this.displayName + ".handleValidation function", err);
            this.setState({ isSubmited: false })
        }
        return formIsValid;
    }

    isGuid = (stringToTest) => {
        if (stringToTest[0] === "{") {
            stringToTest = stringToTest.substring(1, stringToTest.length - 1);
        }
        var regexGuid = /^(\{){0,1}[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}(\}){0,1}$/gi;
        return regexGuid.test(stringToTest);
    }
    handleCreditNoteSubmit = async (e) => {
        e.preventDefault();
        try {
            if (this.handleValidation()) {
                this.setState({
                    isSubmited: true
                });
                const dataSource = this.refs.grdCreditNoteAttributesRef.instance.getDataSource();
                var attributeArray = dataSource._items;

                var request = new Object();
                request.ReferenceNo = this.state.referenceNo;
                request.CreditNoteDate = formatDate(this.state.creditDate, "MM/dd/yyyy hh:mm");
                request.ValidTill = formatDate(this.state.validTill, "MM/dd/yyyy hh:mm");
                request.CustomerId = parseInt(this.state.accountId);
                request.InvoiceNo = (this.state.invoiceNo);
                var orderItemsList = [];
                var totalAmount = 0, taxAmount = 0;

                attributeArray.forEach((element, index) => {
                    var orderItemRequest = {};
                    orderItemRequest.Id = this.state.isNewItem === false ? 0 : element.id;
                    orderItemRequest.ItemId = element.itemId;
                    orderItemRequest.ItemName = element.itemName;
                    orderItemRequest.Description = element.description;
                    orderItemRequest.HSNSACCode = element.hsnsacCode;
                    orderItemRequest.Qty = (parseInt(this.state.orderTypeId) === parseInt(orderType.Serviceopex)) ? parseInt(1) : parseInt(element.qty);
                    orderItemRequest.Price = parseFloat(element.price);
                    //  orderItemRequest.Discount = parseFloat(element.discount);
                    orderItemRequest.TotalAmount = parseFloat(element.totalAmount);
                    orderItemRequest.TaxAmount = parseFloat(element.taxAmount);
                    orderItemRequest.Remarks = "";
                    orderItemRequest.WarrentyTerms = "";
                    totalAmount = totalAmount + parseFloat(element.totalAmount);
                    taxAmount = taxAmount + parseFloat(element.taxAmount);
                    orderItemsList.push(orderItemRequest);
                });
                request.creditNoteDetails = {};
                request.creditNoteDetails = orderItemsList;

                request.TotalAmount = parseFloat(totalAmount);
                //  request.Discount = parseFloat(this.state.discount);
                request.TaxAmount = taxAmount;
                request.GrossAmount = parseFloat(totalAmount)// - parseFloat(this.state.discount) + parseFloat(this.state.otherCharges) + parseFloat(this.state.shippingCharges);
                request.Remarks = this.state.remarks;
                request.UserId = this.state.sessionUser.id;
                const requestParams = getRequestParams('POST', request);
                const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'creditnote/' + this.state.creditNoteId, requestParams);

                const data = await response.json();
                if (data.isSuccess) {
                    this.setState({ documentCreditNoteId: data.payload, resultMeassage: data.message })
                    if (this.state.documentSelectedFile.size !== undefined || this.state.documentSelectedFile.length > 0) {
                        if (this.state.id == 0) {
                            this.documentUpload();
                        }
                    }
                    else {
                        Swal.fire({
                            icon: 'success',
                            title: 'Success',
                            text: data.message,
                        }).then((result) => {
                            if (result.isConfirmed) {
                                if (data.isSuccess) {
                                    window.location.href = "/creditnote/list";
                                }
                            }
                            this.setState({ isSubmited: false })
                        });
                    }

                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: data.message,
                    }).then((result) => {
                        if (result.isConfirmed) {
                        }
                        this.setState({ isSubmited: false })
                    });
                    this.setState({ isSubmited: false })
                }

                this.setState({
                    isSubmited: true
                })
            }
        } catch (err) {
            console.log("Error in " + this.displayName + ".handleCreditNoteSubmit function", err);
            this.setState({ isSubmited: false })
        }
    }

    handleClearControls = () => {
        this.setState({
            isSubmited: false,
            loadPanelVisible: false,
            accountList: [],
            itemList: [],
            itemDetails: [],
            hsnsacList: [],
            id: 0,
            accountId: 0,
            orderNo: "",
            orderDate: new Date(),
            shippingDate: new Date(),
            paymentTermId: 0,
            shippingCharges: 0.00,
            discount: 0.00,
            otherCharges: 0.00,
            grossAmount: 0.00,
            remarks: "",
            termsAndConditions: "",
            accountTaxList: [],
            sessionUser: JSON.parse(sessionStorage.getItem("sessionUser")),
            creditNoteId: this.props.location.state === undefined ? "" : this.props.location.state.creditNoteId,
            isNewItem: false,
            orderStatusId: 0,
            errors: {
                accountId: 0,
                orderNo: "",
                orderDate: new Date(),
                shippingDate: new Date(),
                paymentTermId: 0,
                shippingCharges: 0.00,
                discount: 0.00,
                otherCharges: 0.00,
                grossAmount: 0.00,
                remarks: "",
                termsAndConditions: "",
            }
        });
    }
    handleCancel = () => {
        window.location.href = "/creditnote/list";
    }
    openDocumentModel = () => {
        this.setState({
            documentModelIsOpen: true
        });
        this.getDocuments();
    }
    closeDocumentModel = () => {
        this.setState({
            documentModelIsOpen: false
        });
    }
    getDocuments = async () => {
        try {
            this.setState({
                loadPanelVisible: true
            });
            const requestParams = getRequestParams('GET', {});
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'file/' + parseInt(documentType.CreditNote) + '/' + this.state.creditNoteId + '/documents', requestParams);
            const data = await response.json();
            var imageListObj = [];
            var pdfListObj = [];
            var excelListObj = [];
            var otherListObj = [];
            if (data.documents != null && data.documents.length > 0) {
                for (var i = 0; i < data.documents.length; i++) {
                    if (data.documents[i].fileGuid != '00000000-0000-0000-0000-000000000000') {
                        const requestParamsImage = getRequestParams('GET', '');
                        const responseImage = await fetch(process.env.REACT_APP_API_BASE_URL + 'file/download/' + data.documents[i].fileGuid, requestParamsImage);
                        const dataImage = await responseImage.json();

                        if (dataImage.fileExtension.toUpperCase() == '.PDF') {
                            var img = new Object();
                            img.src = 'data:' + dataImage.mimeType + ';base64,' + dataImage.fileContent;
                            img.fileGuid = data.documents[i].fileGuid;
                            img.fileName = dataImage.fileName;
                            pdfListObj.push(img);
                        } else if (dataImage.fileExtension.toUpperCase() == '.XLXS' || dataImage.fileExtension.toUpperCase() == '.CSV' || dataImage.fileExtension.toUpperCase() == '.XLS') {
                            var img = new Object();
                            img.src = 'data:' + dataImage.mimeType + ';base64,' + dataImage.fileContent;
                            img.fileGuid = data.documents[i].fileGuid;
                            img.fileName = dataImage.fileName;
                            excelListObj.push(img);
                        } else if (dataImage.fileExtension.toUpperCase() == '.JPG' || dataImage.fileExtension.toUpperCase() == '.PNG' || dataImage.fileExtension.toUpperCase() == '.JPEG') {
                            var img = new Object();
                            img.src = 'data:' + dataImage.mimeType + ';base64,' + dataImage.fileContent;
                            img.fileGuid = data.documents[i].fileGuid;
                            imageListObj.push(img);
                        } else {
                            var img = new Object();
                            img.src = 'data:' + dataImage.mimeType + ';base64,' + dataImage.fileContent;
                            img.fileGuid = data.documents[i].fileGuid;
                            img.fileName = dataImage.fileName;
                            otherListObj.push(img);
                        }

                    }
                }
            }

            this.setState({
                loadPanelVisible: false,
                imageList: imageListObj,
                pdfList: pdfListObj,
                excelList: excelListObj,
                otherList: otherListObj
            });

        } catch (e) {
            console.log(e);
        }
    }

    onEditorPreparing = (e) => {
        if (e.parentType === "dataRow" && e.dataField === "itemId") {
            e.editorOptions.onValueChanged = (ev) => {
                let selectedItem = ev.component.option("selectedItem");
                e.setValue(selectedItem);
            };
        }
    }

    setItemCellValue = (rowData, value) => {
        rowData.itemId = value.id;
        rowData.hsnsacCode = value.hsnCode;
        rowData.itemName = value.itemName;
    }

    setServiceItemCellValue = (rowData, value) => {
        rowData.itemId = value.id;
        rowData.hsnsacCode = value.hsnSacCode;
    }

    handleFileInputChange = async (e) => {

        let images = e.target.files;
        var i;
        for (i = 0; i < e.target.files.length; i++) {
            this.state.targetFile.push(images[i]);
        }
        this.setState({
            documentSelectedFile: this.state.targetFile
        })
        //console.log(this.state.documentSelectedFile);
    }
    removeAll = () => {
        document.getElementById("file").value = null;
    }
    resetFileInput = (e) => {
        var data = this.state.documentSelectedFile.filter(item => item.name === e.target.value)
        var _data = this.state.documentSelectedFile.filter(item => item.name !== data[0].name)
        var data1 = this.state.documentSelectedFile.filter(item => item.name === data[0].name)
        this.setState({
            documentSelectedFile: _data,
            targetFile: _data
        }, () => 500)

        const arrLength = _data.length;
        //console.log(arrLength - 1);
        if (_data.length == 0) {
            this.removeAll();
        }
        else {
            document.getElementById("file").value = null
        }
    };
    documentUpload = async () => {
        for (let i = 0; i < this.state.documentSelectedFile.length; i++) {
            //if (this.state.documentSelectedFile.size > 0)
            {
                const url = process.env.REACT_APP_API_BASE_URL + 'file/upload';
                const formData = new FormData();
                //console.log(this.state.documentSelectedFile);
                formData.append('body', this.state.documentSelectedFile[i]);
                const config = {
                    headers: {
                        'content-type': 'multipart/form-data',
                        'UserId': this.state.sessionUser.id,
                        'Authorization': 'Bearer ' + sessionStorage.getItem('session_token'),
                    },
                };
                this.setState({
                    loadPanelVisible: true
                });
                axios.post(url, formData, config).then((response) => {
                    var resp = response.data;
                    if (resp != null) {
                        this.setState({
                            fileGuid: resp.fileGuid
                        })
                        this.saveDocument(resp)
                    }
                });
                this.setState({
                    loadPanelVisible: false
                });
            }
        }
    }
    saveDocument = async (data) => {
        if (data !== null) {
            this.setState({
                fileGuid: data.fileGuid
            });
            try {
                var isSuccess = false;
                var resultMessage = '';
                //for (var i = 0; i < this.state.uniqueId.length; i++)
                {
                    var request = new Object();
                    request.FileGuid = data.fileGuid;
                    request.FileRecordType = parseInt(9);
                    request.UserId = Number(this.state.sessionUser.id);
                    request.DocumentType = documentType.CreditNote;
                    request.Id = this.state.documentCreditNoteId;
                    const requestVehicleParams = getRequestParams('POST', request);
                    const responseVehicle = await fetch(process.env.REACT_APP_API_BASE_URL + 'file/save-documents', requestVehicleParams);
                    const dataVehicle = await responseVehicle.json();
                    isSuccess = dataVehicle.isSuccess;
                    resultMessage = dataVehicle.message;
                }
                //  this.setState({ isSubmited: false })
                if (isSuccess) {
                    Swal.fire({
                        icon: isSuccess ? 'success' : 'error',
                        title: isSuccess ? 'success' : 'Oops...',
                        text: this.state.resultMeassage,
                    }).then((result) => {
                        if (isSuccess) {
                            window.location.href = "/creditnote/list"
                            //  this.closeModel();
                            //this.getDocuments();
                        }
                    });
                } else {
                    this.onfailure('Failed to upload image.', resultMessage);
                }
                this.setState({ isSubmited: false })
            } catch (e) {
                //
            }

        }
    }

    render() {
        const customizeQtyText = (data) => {
            return `Total - ${data.value}`;
        }

        const customizePriceText = (data) => {
            return `Total - ${data.value.toFixed(precisionFormats[2])}`;
        }

        const customizeTaxText = (data) => {
            return `Total - ${data.value.toFixed(precisionFormats[2])}`;
        }

        const customizeDiscountText = (data) => {
            return `Total - ${data.value.toFixed(precisionFormats[2])}`;
        }

        const customizeTotalAmountText = (data) => {
            return `Total - ${data.value.toFixed(precisionFormats[2])}`;
        }
        // const handleItemGridChanges = (data) => {
        //   
        //     return <div>Hello</div>
        // }

        const renderGridNumberCell = (data) => {
            return <div><small><i className={currencySymbolFormats.currencyFormat}></i></small>&nbsp;&nbsp;{parseFloat(data.value !== undefined ? data.value : 0.00).toFixed(precisionFormats[2])}</div>;
        }

        return (
            <Fragment>
                <div className="section-body">
                    <div className='card bg-filter'>
                        <div className='card-body'>
                            <div className="section-header">
                                <h6 className='m-0'>{this.state.creditNoteId > 0 ? "Credit Note#" + this.state.referenceNo : "New Credit Note"} </h6>
                                <div className="section-header-buttons" >
                                    {/* <button type="submit" className="btn btn-success" onClick={this.handleCreditNoteSubmit} style={{disabled : this.state.id }} >
                                        {this.state.isSubmited && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                        {!this.state.isSubmited && <span title="Submit">Submit</span>}
                                    </button>&nbsp;&nbsp; */}
                                    <button type="submit" className="btn btn-outline-secondary" onClick={this.handleCancel}>
                                        <span title="Cancel">Cancel</span>
                                    </button>
                                </div>
                            </div>
                           
                        </div>
                    </div>
                    <div className='card'>
                    <div className='card-body'>
                             {/* <div className='sub-title-color font-weight-bold'>Personal Info</div> */}
                             <div className='row'>
                            <div className="col-lg-2 col-md-3 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <label htmlFor="">Invoice# <span className="text-danger">*</span></label>
                                        <SelectBox
                                            dataSource={this.state.invoiceList}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select invoice"
                                            name="invoiceNo"
                                            defaultValue={0}
                                            value={this.state.invoiceNo}
                                            displayExpr='invoiceNo'
                                            valueExpr='invoiceNo'
                                            onSelectionChanged={this.handleInvoiceChange}
                                            showClearButton={true}
                                            disabled={this.state.id > 0}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["invoiceNo"].length > 0 && <span className="error invalid-feedback">{this.state.errors["invoiceNo"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <label htmlFor="">Customer</label>
                                        <SelectBox
                                            dataSource={this.state.accountList}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select customer"
                                            name="id"
                                            defaultValue={0}
                                            value={this.state.accountId}
                                            displayExpr='name'
                                            valueExpr='id'
                                            onSelectionChanged={this.handleAccountChange}
                                            showClearButton={true}
                                            disabled={true}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["accountId"].length > 0 && <span className="error invalid-feedback">{this.state.errors["accountId"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-3 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <label htmlFor="">Reference# <span className="text-danger">*</span></label>
                                        <input type="text" className="form-control form-control-sm" name="referenceNo" placeholder="Enter reference number" value={this.state.referenceNo}
                                            disabled={this.state.id > 0} title="Reference#" maxLength="10" onChange={this.handleInputChange} />
                                        {this.state.errors["referenceNo"].length > 0 && <span className="error invalid-feedback">{this.state.errors["referenceNo"]}</span>}
                                    </div>
                                </div>

                                <div className="col-lg-2 col-md-3 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <label htmlFor="">Credit Note Date</label>
                                        <DateBox type="date"
                                            className="replay-date-time"
                                            name="creditDate"
                                            placeholder="yyyy"
                                            displayFormat="yyyy"
                                            showAnalogClock={true}
                                            useMaskBehavior={true}
                                            defaultValue={this.state.creditDate}
                                            value={this.state.creditDate}
                                            onValueChanged={this.handleCreditNoteDateChanged}
                                            width="100%"
                                             disabled={this.state.id > 0}
                                            acceptCustomValue={false}
                                        />
                                    </div>
                                </div>

                                <div className="col-lg-2 col-md-3 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <label htmlFor="">Valid Till</label>
                                        <DateBox type="date"
                                            className="replay-date-time"
                                            name="validTill"
                                            placeholder="dd/mm/yyyy"
                                            displayFormat="dd/MM/yyyy"
                                            showAnalogClock={true}
                                            useMaskBehavior={true}
                                            defaultValue={this.state.validTill}
                                            value={this.state.validTill}
                                            onValueChanged={this.handleValidTillDateChanged}
                                            width="100%"
                                             disabled={this.state.id > 0}
                                            acceptCustomValue={false}
                                        />
                                    </div>
                                </div>
                            <div className="col-lg-2 col-md-3 col-sm-12 col-xs-12" style={{ display: this.state.creditNoteId > 0 ? 'block' : 'none' }}>
                                    <br></br>
                                    <button className="btn btn-ouline-secondary" title="View Document(s)" onClick={() => { this.openDocumentModel() }} ><span className='fas fa-folder'></span>&nbsp;View Document(s)</button>
                                </div>
                            </div>
                            <div className='row mt-2'>
                                <div className='col-12'>
                                    {/* <div className='sub-title-color font-weight-bold'>Item Info</div> */}
                                    <DataGrid
                                        ref="grdCreditNoteAttributesRef"
                                        dataSource={this.state.itemDetailsGrid}
                                        keyExpr="id"
                                        allowColumnReordering={true}
                                        showBorders={true}
                                        onRowUpdating={this.onRowUpdating}
                                        onRowUpdated={this.onRowUpdated}
                                        onRowInserting={this.onRowInserting}
                                        orRowInserted={this.onRowInserted}
                                        onRowRemoved={this.onRowRemoved}
                                        onEditorPreparing={this.onEditorPreparing}>
                                        <Paging enabled={false} />
                                        <Editing mode="row"
                                            allowUpdating={this.state.id == 0 ? true : false}
                                            allowDeleting={this.state.id ==0 ? true : false}
                                            allowAdding={this.state.id == 0 ? true : false}
                                            editorOptions={this.state.id == 0 ? true : false} />


                                        <Column dataField="itemId" caption="Item" setCellValue={this.setItemCellValue}>
                                            <Lookup dataSource={this.state.itemDetails} displayExpr="itemName" valueExpr="itemId" />
                                            <RequiredRule />
                                        </Column>


                                        <Column dataField="description" caption="Description">
                                            <RequiredRule maxLength={50} />
                                            <PatternRule message={'Quantity should be in number'} pattern={alphanumericValidation} />
                                            <StringLengthRule message="Description can not be more than 50 characters" max={50} />
                                        </Column>
                                        {this.state.invoiceTypeId !== parseInt(orderType.Serviceopex)  ?
                                        <Column dataField="hsnsacCode" caption="HSN Code" allowEditing={false}>
                                            {/* <Lookup dataSource={this.state.hsnsacList} displayExpr="name" valueExpr="code" allowEditing={false} /> */}
                                            <RequiredRule />
                                        </Column>:
                                        <Column dataField="hsnsacCode" caption="SAC Code" allowEditing={false}>
                                        {/* <Lookup dataSource={this.state.hsnsacList} displayExpr="name" valueExpr="code" allowEditing={false} /> */}
                                        <RequiredRule />
                                    </Column>
                                    }
                                        <Column dataField="qty" caption="Qty" dataType="number" setCellValue={this.calculateQtyChange} alignment="right">
                                            <RequiredRule />
                                            <PatternRule message={'Quantity should be in number'} pattern={numberValidation} />
                                        </Column>
                                        <Column dataField="price" caption="Unit Price" dataType="decimal" setCellValue={this.calculatePriceChange} format={"#,##0.##"} alignment="right"
                                            cellRender={renderGridNumberCell}>
                                            <RequiredRule />
                                            <PatternRule message={'Item Price should be decimal'} pattern={floatValidation} />
                                        </Column>
                                        <Column dataField="taxAmount" caption="Tax Amount" dataType="decimal" allowEditing={false} format={"#,##0.##"} alignment="right"
                                            cellRender={renderGridNumberCell}>
                                            <RequiredRule />
                                            <PatternRule message={'Tax Amount should be decimal'} pattern={floatValidation} />
                                        </Column>

                                        <Column dataField="totalAmount" caption="Total Amount" dataType="decimal" allowEditing={false} format={"#,##0.##"} alignment="right"
                                            cellRender={renderGridNumberCell}></Column>
                                        <Summary>
                                            <TotalItem column="qty" summaryType="sum" customizeText={customizeQtyText} />
                                            <TotalItem column="price" summaryType="sum" customizeText={customizePriceText} />
                                            <TotalItem column="taxAmount" summaryType="sum" customizeText={customizeTaxText} />
                                            <TotalItem column="discount" summaryType="sum" customizeText={customizeDiscountText} />
                                            <TotalItem column="totalAmount" summaryType="sum" customizeText={customizeTotalAmountText} />
                                        </Summary>
                                        <SortByGroupSummaryInfo summaryItem="count" />
                                        {this.state.errors["itemDetailsGrid"].length > 0 && <span className="error invalid-feedback">{this.state.errors["itemDetailsGrid"]}</span>}
                                    </DataGrid>
                                </div>
                            </div>
                            <div className='row mt-3'>
                                <div className="col-6">
                                    <div className="form-group">
                                        <label htmlFor="">Remarks <span className="text-danger">*</span></label>
                                        {/* <textarea className="form-control" placeholder="Enter remarks here"></textarea> */}
                                        <textarea type="text" className="form-control form-control-sm" name="remarks" autoComplete="remarks" placeholder="Enter remarks"
                                            maxLength="200" title="Remarks" value={this.state.remarks} onChange={this.handleInputChange} />
                                        {this.state.errors["remarks"].length > 0 && <span className="error invalid-feedback">{this.state.errors["remarks"]}</span>}
                                    </div>
                                </div>
                                <div className="col-6" style={{ display: this.state.creditNoteId === 0 ? 'block' : 'none' }}>
                                    <div className="form-group">
                                        <label htmlFor="">Attach File(s) to Credit Note</label>
                                        <input className="form-control" type="file" id="file" onClick={() => { this.removeAll() }} onChange={(e) => { this.handleFileInputChange(e, false) }} />
                                        {/* <label htmlFor="" className='small text-muted'>You can upload a maximum of 5 files, 5MB each</label> */}
                                        {this.state.documentSelectedFile.map((item, index) => {
                                            return (
                                                <div key={index}>
                                                    <label >{item.name} <button value={item.name} onClick={this.resetFileInput} className="btn btn-outline-secondary fas fa-trash text-danger"></button></label>
                                                </div>
                                            );
                                        })}
                                        {/* <label htmlFor="" className='small text-muted'>You can upload a maximum of 5 files, 5MB each</label> */}
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
                <Modal ariaHideApp={false}
                    isOpen={this.state.documentModelIsOpen}
                    style={customStyles}
                    className={"react-modal"}
                    onRequestClose={this.closeDocumentModel}
                    shouldCloseOnOverlayClick={false}
                    contentLabel="Example Modal">
                    <form method="post" style={this.state.isSubmited ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                        <div className="modal-header">
                            <h6 className="modal-title">Document</h6>
                        </div>
                        <div className="modal-body">
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <div className="box-body no-padding" style={{ maxHeight: 400 + 'px', overflowY: "scroll" }}>
                                    <div>
                                        <ul className="users-list clearfix">
                                            {this.state.imageList.length > 0 && this.state.imageList.map((item, j) =>
                                                <li style={{ position: "relative" }} key={j}>
                                                    <a href={item.src} download={item.fileName}><div className="framehover"><img src={item.src} width="150" alt="Image" /></div></a>
                                                </li>
                                            )}
                                            {this.state.pdfList.length > 0 && this.state.pdfList.map((item, j) =>
                                                <li style={{ position: "relative" }} key={j}>
                                                    <a href={item.src} download={item.fileName}><div className="framehover"><img src="../../assets/img/pdf_icon.png" width="150" alt="Blank Image" /></div></a>
                                                </li>
                                            )}
                                            {this.state.excelList.length > 0 && this.state.excelList.map((item, j) =>
                                                <li style={{ position: "relative" }} key={j}>
                                                    <a href={item.src} download={item.fileName}><div className="framehover"><img src="../../assets/img/excel_icon.png" width="100" alt="Blank Image" /></div></a>
                                                </li>
                                            )}
                                            {this.state.otherList.length > 0 && this.state.otherList.map((item, j) =>
                                                <li style={{ position: "relative" }} key={j}>
                                                    <a href={item.src} download={item.fileName}><div className="framehover"><img src="../../assets/img/other_icon.png" width="100" alt="Blank Image" /></div></a>
                                                </li>
                                            )}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-outline-secondary" onClick={this.closeDocumentModel} title="Cancel">Cancel</button>
                        </div>
                    </form>
                </Modal>
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={position}
                    visible={this.state.loadPanelVisible}
                    showIndicator={true}
                    shading={true}
                    showPane={true}
                    hideOnOutsideClick={false}
                />
            </Fragment>
        )
    };
}