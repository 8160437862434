import React, { Fragment, Component } from "react";
import DataGrid, {
  Column,
  Export,
  Grouping,
  GroupPanel,
  HeaderFilter,
  Pager,
  Paging,
  Selection,
  SearchPanel,
  SortByGroupSummaryInfo,
} from "devextreme-react/data-grid"; //npm i devextreme-react
import Modal from "react-modal"; //npm install --save react-modal;
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import { formatDate, getRequestParams } from "../../helpers/globalfunctions.js";
import { alphanumericValidation } from "../../helpers/globalvalidations.js";
import { exportFormats } from "../../helpers/fixcodes.js";
import TextArea from "devextreme-react/text-area";
import { LoadPanel } from "devextreme-react/load-panel";
import Swal from "sweetalert2"; //npm i sweetalert2 --save
import { toast } from "react-toastify"; //npm i react-toastify --save
import { SelectBox } from "devextreme-react/select-box";
import DateBox from "devextreme-react/date-box";
import { jsPDF } from "jspdf";
import { exportDataGrid } from "devextreme/pdf_exporter";

const position = { of: "#historydiv" };
const customStyles = {
  content: {
    width: "40%",
  },
};

export class ItemCurrentStatusComponent extends Component {
  static displayName = ItemCurrentStatusComponent.name;

  constructor(props) {
    super(props);
    this.state = {
      isSubmited: false,
      loadPanelVisible: false,
      itemModelIsOpen: false,
      syncModelIsOpen: false,
      sessionUser: JSON.parse(sessionStorage.getItem("sessionUser")),
      id: 0,
      itemCurrentStatus: null,
      itemStatusList: null,
      Status: 0,
      customerList: null,
      customerId: 0,
      itemId: 0,
      stockItemId: 0,

      itemList: [],
      unmappedDeviceList: [],
      selectedRowKey: [],
      modelItemId: 0,
      modelCustomerId: 0,
      modelVehicleNo: "",
      modelStatus: 0,
      modelSerialNo: "",
      modelStockItemId: 0,
      sessionUser: JSON.parse(sessionStorage.getItem("sessionUser")),
      wefDate: new Date(),
      errors: {
        //customerId: 0,
        itemId: 0,
        serialNo: "",
        vehicleNo: "",
        //statusId:0,
        Status: 0,
      },
    };
  }

  async componentDidMount() {
    await this.getItemCurrent();
    await this.getItemStatus();
    //await this.getItemMaster();
    await this.getCustomerList();
    await this.getUnmappedItem();
  }

  getItemCurrent = async () => {
    debugger;
    this.setState({
      loadPanelVisible: true,
    });
    try {
      const requestParams = getRequestParams("GET", null);
      const response = await fetch(
        process.env.REACT_APP_API_BASE_URL +
          "itemCurrentStatus/" +
          this.state.customerId +
          "/" +
          this.state.Status,
        requestParams
      );
      const data = await response.json();

      this.setState({
        itemCurrentStatus: data.payload,
        loadPanelVisible: false,
      });
    } catch (err) {
      console.log("Error in " + this.displayName + ".getQc function", err);
      this.setState({
        loadPanelVisible: false,
      });
    }
  };
  getItemStatus = async () => {
    this.setState({
      loadPanelVisible: true,
    });
    try {
      const requestParams = getRequestParams("GET", null);
      const response = await fetch(
        process.env.REACT_APP_API_BASE_URL +
          "itemCurrentStatus/dropdownItemStatus",
        requestParams
      );
      const data = await response.json();

      this.setState({
        itemStatusList: data.payload,
        loadPanelVisible: false,
      });
    } catch (err) {
      console.log("Error in " + this.displayName + ".getQc function", err);
      this.setState({
        loadPanelVisible: false,
      });
    }
  };
  handleFilterStatusChange = (selectedOption) => {
    this.setState({
      Status:
        selectedOption.selectedItem !== null
          ? selectedOption.selectedItem.id
          : 0,
    });
  };

  getCustomerList = async () => {
    this.setState({
      loadPanelVisible: true,
    });
    try {
      const requestParams = getRequestParams("GET", null);
      const response = await fetch(
        process.env.REACT_APP_API_BASE_URL + "account/dropdownAccount/2",
        requestParams
      );
      const data = await response.json();

      this.setState({
        customerList: data.payload,
        loadPanelVisible: false,
      });
    } catch (err) {
      console.log("Error in " + this.displayName + ".getQc function", err);
      this.setState({
        loadPanelVisible: false,
      });
    }
  };

  // getItemMaster = async () => {
  //     try {
  //         var request = {};
  //         request.CategoryId = 0;
  //         request.modelItemId = 0;
  //         const requestParams = getRequestParams("POST", request);
  //         const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'items/item/', requestParams);
  //         const data = await response.json();
  //         this.setState({
  //             itemList: data.payload != null ? data.payload : []
  //         });
  //     } catch (err) {
  //         console.log("Error in " + this.displayName + ".getItemMaster function", err);
  //     }
  // }

  getUnmappedItem = async () => {
    try {
      var request = {};
      const requestParams = getRequestParams("GET", request);
      const response = await fetch(
        process.env.REACT_APP_API_BASE_URL +
          "itemCurrentStatus/getUnmappedInventoryItem",
        requestParams
      );
      const data = await response.json();
      this.setState({
        unmappedDeviceList: data.payload,
      });
    } catch (err) {
      console.log(
        "Error in " + this.displayName + ".getUnmappedItem function",
        err
      );
    }
  };

  handleValidation = () => {
    let formIsValid = true;
    try {
      let errors = this.state.errors;
      //errors.customerId = 0;
      errors.serialNo = "";
      errors.vehicleNo = "";
      errors.Status = 0;
      //   errors.mappedOn =  0;

      // if (this.state.modelCustomerId === 0) {
      //     formIsValid = false;
      //     errors.customerId = "Please Select Customer.";
      // }
      if (this.state.modelSerialNo === "") {
        formIsValid = false;
        errors.serialNo = "Please Enter SerialNo.";
      }
      if (this.state.modelVehicleNo === "") {
        formIsValid = false;
        errors.vehicleNo = "Please Enter VehicleNo.";
      }
      if (this.state.modelStatus === 0) {
        formIsValid = false;
        errors.Status = "Please Select Status.";
      }
      // if (this.state.mappedOn === 0) {
      //     formIsValid = false;
      //     errors.mappedOn = "Please Enter MappedOn.";
      // }
      this.setState({ errors: errors });
    } catch (err) {
      console.log(
        "Error in " +
          this.displayName +
          ".isRequestRequisitionValidation Function",
        err
      );
    }
    return formIsValid;
  };

  handleItemCurrentSubmit = async (e) => {
    e.preventDefault();
    try {
      if (this.handleValidation()) {
        this.setState({ isSubmited: true });
        var newObject = {};
        newObject.stockItemId = parseInt(this.state.modelStockItemId);
        newObject.CustomerId = parseInt(this.state.modelCustomerId);
        //newObject.ItemId = parseInt(this.state.modelItemId);
        newObject.SerialNo = this.state.modelSerialNo;
        newObject.VehicleNo = this.state.modelVehicleNo;
        newObject.Status = parseInt(this.state.modelStatus);
        newObject.MappedOn =
          this.state.modelStatus === 3
            ? formatDate(new Date(), "MM/dd/yyyy hh:mm")
            : null;
        newObject.WefDate = formatDate(this.state.wefDate, "MM/dd/yyyy hh:mm");
        newObject.CreatedBy = this.state.sessionUser.id;
        newObject.CreatedOn = new Date();
        newObject.UpdatedBy = this.state.sessionUser.id;
        newObject.UpdatedOn = new Date();
        const requestParams = getRequestParams("POST", newObject);
        const response = await fetch(
          process.env.REACT_APP_API_BASE_URL +
            "itemCurrentStatus/InsertUpdateItemCurrentStatus/" +
            this.state.id,
          requestParams
        );
        const data = await response.json();

        if (data.isSuccess) {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: data.message,
          }).then((result) => {
            if (result.isConfirmed) {
              this.closeModal();
              this.getItemCurrent();
            }
            this.setState({ isSubmited: false });
          });
        } else {
          toast.error(data.message, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 2000,
            style: { width: "310px" },
            hideProgressBar: false,
            newestOnTop: false,
            closeButton: false,
          });
          this.setState({ isSubmited: false });
        }
      }
    } catch (err) {
      console.log(
        "Error in " + this.displayName + ".handleItemCurrentSubmit function",
        err
      );
      this.setState({ isSubmited: false });
    }
  };

  handleFilterCustomerChange = (selectedOption) => {
    this.setState({
      customerId:
        selectedOption.selectedItem !== null
          ? selectedOption.selectedItem.id
          : 0,
    });
  };
  onExporting = (e) => {
    const doc = new jsPDF();
    if (e.format === "pdf") {
      exportDataGrid({
        jsPDFDocument: doc,
        component: e.component,
        columnWidths: 50,
        // indent: 10,
      }).then(() => {
        doc.save(e.fileName);
      });
    }
  };
  handleClearSearch = () => {
    this.setState({
      Status: 0,
      customerId: 0,
    });
    setTimeout(() => {
      this.getItemCurrent();
    }, 500);
  };

  // newItemCurrentStatusModal = () => {
  //     this.setState({
  //         itemModelIsOpen: true,
  //         id:0,
  //         modelCustomerId:0,
  //         modelItemId:0,
  //         modelStatus:0,
  //     })
  // }

  editModel = (e, data) => {
    e.preventDefault();

    if (data != null) {
      this.setState({
        itemModelIsOpen: true,
        modelCustomerId: data.customerId,
        modelVehicleNo: data.vehicleNo,
        modelSerialNo: data.serialNo,
        modelStockItemId: data.stockItemId,
        //modelItemId : data.itemId,
        modelStatus: data.status,
      });
    }
  };
  handleModelCustomerChange = (selectedOption) => {
    this.setState({
      modelCustomerId:
        selectedOption.selectedItem !== null
          ? selectedOption.selectedItem.id
          : 0,
    });
  };
  handleModelStatusChange = (selectedOption) => {
    this.setState({
      modelStatus:
        selectedOption.selectedItem !== null
          ? selectedOption.selectedItem.id
          : 0,
    });
  };
  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({ [name]: value });
  };
  handleMultipleUnmappedSelectionChanged = (e) => {
    this.setState({ selectedRowKey: e.selectedRowKeys });
  };
  handleUpdateContractItem = async (e) => {
    e.preventDefault();
    try {
      //    if (this.state.selectedRowKey != null && this.state.selectedRowKey.length === 0) {
      //     Swal.fire({
      //         icon: 'error',
      //         text: "Please select at least one row.",
      //     }).then((result) => {
      //         this.setState({ isSwapTrip: false })
      //     });
      //     return;
      // }
      let itemList = [];
      let selectedRowKeys = this.state.selectedRowKey;
      let devicelIst = this.state.unmappedDeviceList;
      let list = this.state.unmappedDeviceList.filter(
        (v) => selectedRowKeys.filter((id) => id === v.id).length > 0
      );
      list.map((gridData, i) => {
        let newgrid = new Object();
        //newgrid.Id = gridData.id;
        newgrid.StockItemId = gridData.stockItemId;
        itemList.push(newgrid);
      });
      var newObject = {};
      newObject.contractItem = itemList;
      this.setState({ loadPanelVisible: true });
      const requestParams = getRequestParams("POST", newObject);
      const response = await fetch(
        process.env.REACT_APP_API_BASE_URL +
          "itemCurrentStatus/unmappedContractItem",
        requestParams
      );
      const data = await response.json();
      this.setState({ loadPanelVisible: false });
      if (data.isSuccess) {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: data.message,
        }).then((result) => {
          if (result.isConfirmed) {
            //this.unmappedDeviceList();
            //this.closeVehicleDeviceModel();
          }
          this.setState({ isSubmited: false });
        });
      } else {
        Swal.fire({
          icon: "oops",
          title: "oops",
          text: data.message,
        }).then((result) => {
          if (result.isConfirmed) {
            //this.unmappedDeviceList();
            //this.closeVehicleDeviceModel();
          }
          this.setState({ isSubmited: false });
        });
      }
      this.setState({ isSubmited: false });
    } catch (err) {
      console.log(
        "Error in " + this.displayName + ".handleItemSubmit function",
        err
      );
      this.setState({ isSubmited: false });
    }
  };

  closeModal = () => {
    this.setState({
      isSubmited: false,
      loadPanelVisible: false,
      itemModelIsOpen: false,
      customerId: 0,
      Status: 0,
      stockItemId: 0,
      modelStockItemId: 0,
      modelCustomerId: 0,
      modelVehicleNo: "",
      modelStatus: 0,
      modelItemId: 0,
      modelSerialNo: "",
      id: 0,
      errors: {
        customerId: 0,
        itemId: 0,
        serialNo: 0,
        vehicleNo: 0,
        //statusId:0,
        mappedOn: 0,
        Status: 0,
      },
    });
  };
  openInventorySyncModel = () => {
    this.setState({ syncModelIsOpen: true });
    setTimeout(() => {
      this.getUnmappedItem();
    }, 100);
  };
  closeInventorySyncModel = () => {
    this.setState({ syncModelIsOpen: false });
  };
  render() {
    const renderGridCell = (data) => {
      return (
        <div>
          <a
            href="#"
            className="fas fa-pen"
            title="Edit"
            onClick={(e) => {
              this.editModel(e, data.data);
            }}
          ></a>
          &nbsp;&nbsp;
        </div>
      );
    };
    return (
      <Fragment>
        <div className="section-body">
          <div className="card bg-filter">
            <div className="card-body">
              <div className="section-header">
                <div className="mr-3">
                  <div className="row">
                    <div className="col-lg-4 col-md-4 col-sm-12">
                      <div className="form-group">
                        <label htmlFor="">Customer</label>
                        <SelectBox
                          dataSource={this.state.customerList}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          placeholder="Select customer"
                          name="id"
                          defaultValue={0}
                          value={this.state.customerId}
                          displayExpr="name"
                          valueExpr="id"
                          onSelectionChanged={this.handleFilterCustomerChange}
                          showClearButton={true}
                          searchEnabled={true}
                        />
                      </div>
                    </div>
                    <div className="col-lg-2 col-md-2 col-sm-12">
                      <div className="form-group">
                        <label htmlFor="">Item Status</label>
                        <SelectBox
                          dataSource={this.state.itemStatusList}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          placeholder="Select item status"
                          name="id"
                          defaultValue={0}
                          value={this.state.Status}
                          displayExpr="name"
                          valueExpr="id"
                          onSelectionChanged={this.handleFilterStatusChange}
                          showClearButton={true}
                          searchEnabled={true}
                        />
                      </div>
                    </div>
                    <div className="col-lg-2 col-md-2 col-sm-12 col-xs-12 pl-0">
                      <div className="form-group">
                        <label htmlFor="">&nbsp;</label>
                        <br />
                        <button
                          type="submit"
                          className="btn btn-success"
                          onClick={this.getItemCurrent}
                        >
                          Search
                        </button>
                        &nbsp;&nbsp;
                        <button
                          type="submit"
                          className="btn btn-outline-secondary"
                          onClick={this.handleClearSearch}
                        >
                          <span title="Clear">Clear</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="section-header-buttons">
                  <label htmlFor="">&nbsp;</label>
                  <br />
                  <button
                    type="button"
                    className="btn btn-success"
                    onClick={this.openInventorySyncModel}
                    title="Sync"
                  >
                    Sync
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-body">
              <DataGrid
                dataSource={this.state.itemCurrentStatus}
                keyExpr="id"
                id="gridHsnCodeMasterList"
                selection={{ mode: "single" }}
                columnsAutoWidth={false}
                showColumnLines={true}
                showRowLines={true}
                showBorders={true}
                rowAlternationEnabled={false}
                allowColumnResizing={true}
                columnResizingMode="widget"
                width="100%"
                onToolbarPreparing={this.onToolbarPreparing}
                onExporting={this.onExporting}
              >
                {/* <Column dataField="id" width={35} caption="" allowFiltering={false} allowSorting={false}/> */}
                <Column
                  dataField="itemName"
                  caption="Item"
                  allowSearch={true}
                  allowFiltering={false}
                  allowSorting={true}
                  width={100}
                />
                <Column
                  dataField="serialNo"
                  caption="Serial#"
                  allowSearch={true}
                  allowFiltering={false}
                  allowSorting={true}
                  width={150}
                />
                <Column
                  dataField="vehicleNo"
                  caption="Vehicle#"
                  allowSearch={true}
                  allowFiltering={false}
                  allowSorting={true}
                  width={120}
                />
                <Column
                  dataField="simNo"
                  caption="SimNo#"
                  allowSearch={true}
                  allowFiltering={false}
                  allowSorting={true}
                  width={120}
                />
                <Column
                  dataField="mappedOn"
                  caption="Mapped On"
                  allowSearch={true}
                  allowFiltering={false}
                  allowSorting={true}
                  width={180}
                  dataType="datetime"
                  format="dd/MM/yyyy HH:mm:ss"
                />
                <Column
                  dataField="customerName"
                  caption="Customer"
                  allowSearch={true}
                  allowFiltering={false}
                  allowSorting={true}
                  width={400}
                />
                <Column
                  dataField="statusName"
                  caption="Item Status"
                  allowSearch={true}
                  allowFiltering={false}
                  allowSorting={true}
                  width={120}
                />
                <Column
                  dataField="wefDate"
                  caption="Wef Date"
                  allowSearch={true}
                  allowFiltering={false}
                  allowSorting={true}
                  width={150}
                  dataType="datetime"
                  format="dd/MM/yyyy"
                />

                <SortByGroupSummaryInfo summaryItem="count" />
                <GroupPanel visible={false} />
                {/* <Template name="toolbarTemplate" /> */}
                <HeaderFilter visible={true} />
                <SearchPanel
                  visible={true}
                  highlightCaseSensitive={true}
                  width={240}
                  placeholder="Search..."
                />
                <Grouping autoExpandAll={true} />
                <Paging defaultPageSize={20} />
                <Pager
                  showPageSizeSelector={true}
                  allowedPageSizes={[10, 20, 50]}
                  showInfo={true}
                  visible={true}
                />
                <Export
                  enabled={true}
                  allowExportSelectedData={false}
                  fileName="Item current status"
                />
              </DataGrid>
            </div>
          </div>
        </div>
        <Modal
          ariaHideApp={false}
          isOpen={this.state.itemModelIsOpen}
          style={customStyles}
          className={"react-modal"}
          onRequestClose={this.closeModal}
          shouldCloseOnOverlayClick={false}
          contentLabel="Example Modal"
        >
          <form
            method="post"
            onSubmit={this.handleItemCurrentSubmit}
            style={
              this.state.isSubmited
                ? { pointerEvents: "none", opacity: "0.4" }
                : {}
            }
          >
            <div className="modal-header">
              <h6 className="modal-title">{"Update Item Current Status"}</h6>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-lg-6">
                  <div className="form-group">
                    <label>
                      Customer <span className="text-danger">*</span>
                    </label>
                    <SelectBox
                      dataSource={this.state.customerList}
                      className="basic-multi-select"
                      classNamePrefix="select bill"
                      placeholder="Select account"
                      name="name"
                      defaultValue={0}
                      value={this.state.modelCustomerId}
                      displayExpr="name"
                      valueExpr="id"
                      onSelectionChanged={this.handleModelCustomerChange}
                      //disabled={this.state.id > 0}
                      showClearButton={true}
                      searchEnabled={true}
                    />
                    {/* {this.state.errors["customerId"].length > 0 && <span className="error invalid-feedback">{this.state.errors["customerId"]}</span>}  */}
                  </div>
                </div>
                {/* <div className="col-lg-6">
                                    <div className="form-group">
                                    <label>Item <span className="text-danger">*</span></label>
                                        <SelectBox
                                            dataSource={this.state.itemList}
                                            className="basic-multi-select"
                                            classNamePrefix="select "
                                            placeholder="Select Item"
                                            name="name"
                                            defaultValue={0}
                                            value={this.state.modelItemId}
                                            displayExpr='name'
                                            valueExpr='id'
                                            onSelectionChanged={this.handleModelItemChange}
                                            //disabled={this.state.id > 0}
                                            showClearButton={true}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["itemId"].length > 0 && <span className="error invalid-feedback">{this.state.errors["itemId"]}</span>} 
                                    </div>
                                </div> */}
                <div className="col-lg-6">
                  <div className="form-group">
                    <label>
                      Status <span className="text-danger">*</span>
                    </label>
                    <SelectBox
                      dataSource={this.state.itemStatusList}
                      className="basic-multi-select"
                      classNamePrefix="select status"
                      placeholder="Select status"
                      name="name"
                      defaultValue={0}
                      value={this.state.modelStatus}
                      displayExpr="name"
                      valueExpr="id"
                      onSelectionChanged={this.handleModelStatusChange}
                      //disabled={this.state.id > 0}
                      showClearButton={true}
                      searchEnabled={true}
                    />
                    {this.state.errors["Status"].length > 0 && (
                      <span className="error invalid-feedback">
                        {this.state.errors["Status"]}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="form-group">
                    <label>
                      Vehicle# <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      name="modelVehicleNo"
                      placeholder="Enter vehicle number"
                      value={this.state.modelVehicleNo}
                      title="Vehicle#"
                      maxLength="20"
                      onChange={this.handleInputChange}
                    />
                    {this.state.errors["vehicleNo"].length > 0 && (
                      <span className="error invalid-feedback">
                        {this.state.errors["vehicleNo"]}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="form-group">
                    <label>
                      Serial# <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      name="modelSerialNo"
                      placeholder="Enter serial number"
                      value={this.state.modelSerialNo}
                      title="Serial#"
                      maxLength="20"
                      onChange={this.handleInputChange}
                    />
                    {this.state.errors["serialNo"].length > 0 && (
                      <span className="error invalid-feedback">
                        {this.state.errors["serialNo"]}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="submit"
                className="btn btn-success"
                onClick={this.handleItemCurrentSubmit}
              >
                {this.state.isSubmited && (
                  <span title="In-process">
                    <i className="fas fa-spinner fa-spin"></i> In-process
                  </span>
                )}
                {!this.state.isSubmited && <span title="Submit">Submit</span>}
              </button>
              <button
                type="button"
                className="btn btn-outline-secondary"
                onClick={this.closeModal}
                title="Cancel"
              >
                Cancel
              </button>
            </div>
          </form>
        </Modal>

        <Modal
          ariaHideApp={false}
          isOpen={this.state.syncModelIsOpen}
          style={customStyles}
          className={"react-modal"}
          //onAfterOpen={this.handleAssignDataToState}
          onRequestClose={this.closeInventorySyncModel}
          shouldCloseOnOverlayClick={false}
          contentLabel="Example Modal"
        >
          <form
            method="post"
            onSubmit={this.handleUpdateContractItem}
            style={
              this.state.isSubmited
                ? { pointerEvents: "none", opacity: "0.4" }
                : {}
            }
          >
            <div className="modal-header">
              <h6 className="modal-title">Sync Inventory</h6>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-lg-12">
                  <DataGrid
                    dataSource={this.state.unmappedDeviceList}
                    keyExpr="id"
                    //  selection={{ mode: 'single' }}
                    columnsAutoWidth={false}
                    showColumnLines={true}
                    showRowLines={true}
                    showBorders={true}
                    rowAlternationEnabled={false}
                    allowColumnResizing={true}
                    columnResizingMode="widget"
                    onSelectionChanged={
                      this.handleMultipleUnmappedSelectionChanged
                    }
                    //  selectedRowKeys={this.state.selectedRowKey}
                    //  onToolbarPreparing={this.onToolbarPreparing}
                  >
                    <Selection
                      mode="multiple"
                      selectAllMode={"allPages"}
                      showCheckBoxesMode={"always"}
                    />

                    {/* <Column dataField="soNo" caption="SO#" allowSearch={true} allowFiltering={false} allowSorting={true} width={150} />
                                        <Column dataField="referenceSONo" caption="Reference No" allowSearch={true} allowFiltering={false} allowSorting={true} width={150} />*/}
                    <Column
                      dataField="serialNo"
                      caption="SerialNo"
                      allowSearch={true}
                      allowFiltering={false}
                      allowSorting={true}
                      width={150}
                    />
                    <Column
                      dataField="vehicleNo"
                      caption="Vehicle#"
                      allowSearch={true}
                      allowFiltering={false}
                      allowSorting={true}
                      width={150}
                    />
                    <Column
                      dataField="statusName"
                      caption="Status"
                      allowSearch={true}
                      allowFiltering={false}
                      allowSorting={true}
                      width={150}
                    />

                    <SortByGroupSummaryInfo summaryItem="count" />
                    <GroupPanel visible={false} />
                    {/* <Template name="toolbarTemplate" /> */}
                    <HeaderFilter visible={true} />
                    <SearchPanel
                      visible={true}
                      highlightCaseSensitive={true}
                      width={240}
                      placeholder="Search..."
                    />
                    <Grouping autoExpandAll={true} />
                    <Paging defaultPageSize={10} />
                    <Pager
                      showPageSizeSelector={true}
                      allowedPageSizes={[10, 20, 50]}
                      showInfo={true}
                      visible={true}
                    />
                    <Export
                      enabled={true}
                      allowExportSelectedData={false}
                      fileName="VehicleDevice"
                    />
                  </DataGrid>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="submit"
                className="btn btn-success"
                onClick={this.handleUpdateContractItem}
              >
                {this.state.isSubmited && (
                  <span title="In-process">
                    <i className="fas fa-spinner fa-spin"></i> In-process
                  </span>
                )}
                {!this.state.isSubmited && <span title="Submit">Submit</span>}
              </button>
              <button
                type="button"
                className="btn btn-outline-secondary"
                onClick={this.closeInventorySyncModel}
                title="Cancel"
              >
                Cancel
              </button>
            </div>
          </form>
        </Modal>
        <LoadPanel
          shadingColor="rgba(0,0,0,0.4)"
          position={position}
          visible={this.state.loadPanelVisible}
          showIndicator={true}
          shading={true}
          showPane={true}
          hideOnOutsideClick={false}
        />
      </Fragment>
    );
  }
}
