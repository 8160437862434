import React, { Fragment, Component } from 'react';
import DataGrid, {
    Column,
    Export, Grouping,
    GroupPanel,
    HeaderFilter, Pager,
    Paging,
    SearchPanel,
    SortByGroupSummaryInfo,
    Summary, TotalItem,MasterDetail
} from 'devextreme-react/data-grid'; //npm i devextreme-react
import {
    Connector, PieChart,
    Size
} from 'devextreme-react/pie-chart';
import {
Label, Legend, Series,
 } from 'devextreme-react/chart';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import { getRequestParams,numberFormat,numberGridFormat } from '../../helpers/globalfunctions.js';
import { precisionFormats, exportFormats,currencySymbolFormats,invoiceStatus } from '../../helpers/fixcodes.js';
import { jsPDF } from 'jspdf';
import NumberBox from 'devextreme-react/number-box';
import { SelectBox } from 'devextreme-react/select-box';

import { exportDataGrid } from 'devextreme/pdf_exporter';
let currentYear = (new Date()).getFullYear();
let maxYear = 10;
let minYear = 0
let financialYearList = [];
for (let i = maxYear; i >= minYear; i--) {
    let year = new Object();
    year.id = (currentYear - i);
    year.name = (currentYear - i);
    financialYearList.push(year);
}
export class DashboardComponent extends Component {
    static displayName = DashboardComponent.name;

    constructor(props) {
        super(props);

        this.state = {
            isSubmited: false,
            loadPanelVisible: false,
            dashboardDetails: [],
            dashboardStockDetails: [],
            dashboardInvoiceDetails: [],
            dashboardSalesSummary: [],
            dashboardPurchaseSummary: null,
            dashboardPieInvoice : null,
            invoicepaymentSummary : null,
            dashboardPaymentReceived : null,
            invoicePaymentDetails :null,
            financialYearList : financialYearList,
            financialYear : (new Date()).getFullYear(),
            totalSaleOrder :0,
            hidePurchaseSummary: false,
            hideSaleSummary: false,
            hideShowPayment : false,
            paidInvoiceQty :0,
            gridId :0,
        }
    }

    async componentDidMount() {
        this.setState({
            loadPanelVisible: true
        });
        await this.getDashboardDetails();
        await this.getInvoicePaymentMaster();
        this.setState({
            loadPanelVisible: false
        });
    }

    getDashboardDetails = async () => {
        try {
            var request = {};
            var financialYear = this.state.financialYear;
            const requestParams = getRequestParams("GET", request);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'dashboard/' + financialYear, requestParams);
            const data = await response.json();
             this.setState({
                dashboardDetails: data.dashboardDetails,
                dashboardStockDetails: data.dashboardStockDetails,
                dashboardInvoiceDetails: data.dashboardInvoiceDetails,
                dashboardSalesSummary: data.dashboardSalesSummary,
                dashboardPieInvoice : data.dashboardPieInvoice,
                dashboardPurchaseSummary:data.dashboardPurchaseSummary,
                dashboardPaymentReceived : data.dashboardPaymentReceived,
                paidInvoiceQty : data.invoiceQty
            });
            
            var number= Number(data.dashboardDetails.totalSales).toLocaleString();
            console.log(number);
        } catch (err) {
            console.log("Error in " + this.displayName + ".getDashboardDetails function", err);
        }
    }
    getInvoicePaymentMaster = async () => {
        try {
            var request = {};
            var financialYear = this.state.financialYear;
            const requestParams = getRequestParams("GET");
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'dashboard/GetInvoiceRelatedPayment/' + financialYear, requestParams);
            const data = await response.json();
           
            this.setState({
                invoicepaymentSummary: data.payload
            });
        } catch (err) {
            console.log("Error in " + this.displayName + ".getInvoicePaymentMaster function", err);
        }
    }

    getInvoicePaymentDetailsMaster = async (gridId) => {
        try {
            var request = {};
           this.setState({gridId : gridId})
            var financialYear = this.state.financialYear;
            const requestParams = getRequestParams("GET");
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'dashboard/GetInvoicePaymentDetails/' + financialYear + '/' + gridId , requestParams);
            const data = await response.json();
           
            this.setState({
                invoicePaymentDetails: data.payload
            });
        } catch (err) {
            console.log("Error in " + this.displayName + ".getInvoicePaymentMaster function", err);
        }
    }
    onStockExporting = (e) => {
        const doc = new jsPDF();
        if (e.format === "pdf") {
            exportDataGrid({
                jsPDFDocument: doc,
                component: e.component,
                columnWidths: 50
                // indent: 10,
            }).then(() => {
                doc.save(e.fileName + '.pdf');
            });
        }
    }

    onInvoiceExporting = (e) => {
        const doc = new jsPDF();
        if (e.format === "pdf") {
            exportDataGrid({
                jsPDFDocument: doc,
                component: e.component,
                columnWidths: 50
                // indent: 10,
            }).then(() => {
                doc.save(e.fileName + '.pdf');
            });
        }
    }

    onSalesSummaryExporting = (e) => {
        const doc = new jsPDF();
        if (e.format === "pdf") {
            exportDataGrid({
                jsPDFDocument: doc,
                component: e.component,
                columnWidths: 50
                // indent: 10,
            }).then(() => {
                doc.save(e.fileName + '.pdf');
            });
        }
    }
    handleOnFYDropdownChange = async (data) => {
        try {
            let financialYearDropdownData = null;
            if (data !== null && data.selectedItem !== null) {
                this.setState({financialYear : (data.selectedItem !== null ? data.selectedItem.id : 0)});     
            }
            setTimeout(() =>  this.getDashboardDetails(), 100);
            setTimeout(() =>  this.getInvoicePaymentMaster(), 100);
           
          } catch (ex) {
            console.log(ex);
        }
    }
     customizePoint =(point)=> {
        const color = point.series.getPointsByArg(point.argument)[0].getColor();
        
        let fillId;
        
        switch (point.argument) {
          case 'Draft':
            color = "#212529";
            break;
          case "Appproved":
            color = "#BF0000";
            break;
          case 'Sent to Customer':
            color = "#00599E";
            break;
        //    case 'Sent To Tally':
        //     color = ;
        //         break;
        //   case 'Paid':
        //     color =;
        //     break;
          default:
            break;
        }
      
        return { color: { color } };
      }
//     getLinearGradient = (color)=> { 
//         return getGradient('linear', color, hexToRgb(color, 0.5)); }
//   getGradient=(type, color1, color2) =>{
//             return registerGradient(type, {
//               colors: [{
//                 offset: '20%',
//                 color: color1,
//               }, {
//                 offset: '90%',
//                 color: color2,
//               }],
//             });
//           }
showHidePurchaseGrid = ()=>{
    this.setState({hidePurchaseSummary : true, hideShowPayment : false,hideSaleSummary : false})
}
showHideSaleGrid = ()=>{
   this.setState({hidePurchaseSummary : false,hideShowPayment : false,hideSaleSummary : true})
}
showHidePaymentGrid = (e,gridId)=>{
    
  this.setState({hidePurchaseSummary : false,hideShowPayment : true,hideSaleSummary : false})
    setTimeout(() => { this.getInvoicePaymentDetailsMaster(gridId) },100);
}

 numberFormat = (value) =>
  new Intl.NumberFormat('en-IN', {
    style: 'currency',
    currency: 'INR'
  }).format(value);     

    render() {
        const renderGridNumberCell = (data) => {
            //return <div><small><i className={currencySymbolFormats.currencyFormat}></i></small>&nbsp;&nbsp;{parseFloat(data.value).toFixed(precisionFormats[2])}</div>;
            return numberGridFormat(data.value)
        }

        const customizeText = (data) => {
            return "Total: " + numberGridFormat(data.value)
           
            //return `Total - ${data.value.toFixed(precisionFormats[2])}`;
        }

        const customizeCountText = (data) => {
            return "Total: " + numberGridFormat(data.value)
        }
        return (
            <Fragment>
                <div className='dashboard'>
                    <div className='row mb-3'>
                        <div className='col-lg-2'>
                            {/* <DateRangePicker
        initialSettings={{ startDate: '1/1/2014', endDate: '3/1/2014' }}
      >
        <input type="text" className="form-control" />
      </DateRangePicker> */}
                            {/* <DateRangePicker block /> */}
                            <SelectBox
                                dataSource={this.state.financialYearList}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                placeholder="Select year"
                                name="ddfinancialyear"
                                defaultValue={0}
                                value={this.state.financialYear}
                                displayExpr='name'
                                valueExpr='id'
                                onSelectionChanged={this.handleOnFYDropdownChange}
                                showClearButton={true}
                                disabled={false}
                                searchEnabled={true}
                            />
                        </div>
                        
                    </div>
                    <div className='d-flex flex-row justify-content-between align-self-center mr-2 ml-2 mb-3'>
                        <div className='w-100 mr-3'>
                            <div className="info-box bg-info" onClick={this.showHideSaleGrid}>
                                <div className='inner'>
                                    <div className="info-box-content">
                                        <div className="info-box-text">Sales</div>
                                        <div className="info-box-number">
                                            {/* ₹ {parseFloat(this.state.dashboardDetails.totalSales).toFixed(precisionFormats[2])} */}
                                            {/* ₹ {Number(this.state.dashboardDetails.totalSales).toLocaleString()} */}
                                            { numberFormat(this.state.dashboardDetails.totalSales)}
                                        </div>
                                    </div>
                                    {/* <span className="info-box-icon"><i className="fas fa-store"></i></span> */}
                                </div>
                                <hr></hr>
                                <div className="small-box-footer">
                                    <div>Invoice# <span>{this.state.dashboardDetails.soCount}</span></div>
                                    <div>Item qty <span>{this.state.dashboardDetails.salesQty}</span></div>
                                </div>
                            </div>
                        </div>
                        <div className='w-100 mr-3'>
                            <div className="info-box bg-warning">
                                <div className='inner' onClick={this.showHidePurchaseGrid}>
                                    <div className="info-box-content">
                                        <div className="info-box-text">Purchase</div>
                                        <div className="info-box-number" >
                                             {/* {parseFloat(this.state.dashboardDetails.totalPurchase).toFixed(precisionFormats[2])} */}
                                             {/* ₹ {Number(this.state.dashboardDetails.totalPurchase).toLocaleString()} */}
                                            { numberFormat(this.state.dashboardDetails.totalPurchase)}
                                        </div>
                                    </div>
                                    {/* <div className="info-box-icon"><i className="fas fa-box"></i></div> */}
                                </div>
                                <hr></hr>
                                <div className="small-box-footer">
                                    <div>PO# <span>{this.state.dashboardDetails.poCount}</span></div>
                                    <div>Item qty <span>{this.state.dashboardDetails.purchaseQty}</span></div>
                                </div>
                            </div>
                        </div>
                        <div className='w-100 mr-3'>
                            <div className="info-box bg-success" >
                                <div className='inner'>
                                    <div className="info-box-content" onClick={(e) => this.showHidePaymentGrid(e,1)}>
                                        <div className="info-box-text">Payment Received</div>
                                        <div className="info-box-number">
                                            {/* ₹ {parseFloat(this.state.dashboardDetails.paymentReceived).toFixed(precisionFormats[2])} */}
                                            {/* ₹ {Number(this.state.dashboardDetails.paymentReceived).toLocaleString()} */}
                                           { numberFormat(this.state.dashboardDetails.paymentReceived)}
                                        </div>
                                    </div>
                                    {/* <span className="info-box-icon"><i className="fas fa-rupee-sign"></i></span> */}
                                </div>
                                <hr></hr>
                                <div className="small-box-footer">
                                    <div>Invoice# <span>{this.state.paidInvoiceQty}</span></div>
                                    {/* <div>ordered <span>{this.state.dashboardDetails.prOrdered}</span></div> */}
                                </div>
                            </div>
                        </div>
                        <div className='w-100 mr-3'>
                            <div className="info-box bg-danger">
                                <div className='inner'>
                                    <div className="info-box-content" onClick={(e) => this.showHidePaymentGrid(e,2)}>
                                        <div className="info-box-text">Payment Due</div>
                                        <div className="info-box-number">
                                            {/* ₹ {parseFloat(this.state.dashboardDetails.paymentDue).toFixed(precisionFormats[2])} */}
                                            {/* ₹ {(this.state.dashboardDetails.paymentDue)} */}
                                            {/* <NumericFormat value={this.state.dashboardDetails.paymentDue.toFixed(precisionFormats[2])} displayType={'text'} thousandSeparator={true} prefix={'$'} /> */}
                                          {numberFormat(this.state.dashboardDetails.paymentDue)}
                                        </div>
                                    </div>
                                    {/* <span className="info-box-icon"><i className="fas fa-rupee-sign"></i></span> */}
                                </div>
                                <hr></hr>
                                <div className="small-box-footer">
                                <div>Invoice# <span>{this.state.dashboardDetails.invoiceQty}</span></div>
                                    {/* <div>qty <span>{this.state.dashboardDetails.pdQty}</span></div>
                                    <div>ordered <span>{this.state.dashboardDetails.pdOrdered}</span></div> */}
                                </div>
                            </div>
                        </div>
                        <div className='w-100 mr-3'>
                            <div className="info-box bg-grey">
                                <div className='inner'>
                                    <div className="info-box-content" onClick={(e) => this.showHidePaymentGrid(e,3)}>
                                        <div className="info-box-text">Payment Over Due</div>
                                        <div className="info-box-number">
                                          {numberFormat(this.state.dashboardDetails.invoiceOverDue)}
                                        </div>
                                    </div>
                                </div>
                                <hr></hr>
                                <div className="small-box-footer">
                                <div>Invoice# <span>{this.state.dashboardDetails.invoiceOverDueQty}</span></div>
                                    {/* <div>qty <span>{this.state.dashboardDetails.pdQty}</span></div>
                                    <div>ordered <span>{this.state.dashboardDetails.pdOrdered}</span></div> */}
                                </div>
                            </div>
                        </div>
                        {/* <div className='w-100'>
                            <div className="info-box bg-dark low-stock">
                                <div className="info-box-content">
                                    <div className="info-box-text">Low Stock</div>
                                    <hr className='custom-hr mt-1 mb-1'></hr>
                                    <div className='stock-item'>
                                        <span>FMB920 <span className='badge badge-pill badge-danger'>5</span></span>
                                        <span>FMB920 <span className='badge badge-pill badge-danger'>5</span></span>
                                        <span>FMB920 <span className='badge badge-pill badge-danger'>5</span></span>
                                        <span>FMB920 <span className='badge badge-pill badge-danger'>5</span></span>
                                        <span>FMB920 <span className='badge badge-pill badge-danger'>5</span></span>
                                        <span className='text-right'>
                                            <button type="submit" className="btn btn-link"><span title="View more">View more</span></button>
                                        </span>

                                    </div>
                                </div>

                            </div>
                        </div> */}
                    </div>

                    <div className="row mb-3">
                        <div className="col-4">
                            <div className="card shadow-sm rounded-vlg">
                                <div className="card-header border-0">
                                    <div style={{ width: '100%' }}>
                                        <h4 className="card-title">Stock Overview</h4>
                                        <div className="card-body">
                                            <PieChart
                                                id="pie"
                                                type="doughnut"
                                                palette="Bright"
                                                dataSource={this.state.dashboardPieInvoice}
                                                // customizePoint={this.customizePoint}
                                            >
                                                <Series argumentField="name"  valueField="id"  backgroundColor = "red">
                                                    {/*<SmallValuesGrouping mode="topN" topCount={"3"} />*/}
                                                    {/* <Label
                                                        visible={true}
                                                        customizeText={this.customizeLabel}
                                                        format={"fixedPoint"}
                                                    > */}
                                                        {/* <Connector visible={true} width={1} color={"red"} /> */}
                                                    {/* </Label> */}
                                                </Series>
                                                <Size width={230} />
                                                {/*<Export enabled={true} />*/}
                                                <Legend
                                                    margin={0}
                                                    horizontalAlignment="right"
                                                    verticalAlignment="top"
                                                />
                                            </PieChart>
                                        </div>
                                        {/* <div style={{ width: '100%' }}><img src='https://www.ft.com/__origami/service/image/v2/images/raw/ftcms%3A347ece48-0f69-11e9-a3aa-118c761d2745?source=ig' className="img-fluid" /></div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-8">
                            <div className="card shadow-sm rounded-vlg">
                                <div className="card-header border-0">
                                    <div className="d-flex justify-content-between" style={{ width: '100%' }}>
                                        <h4 className="card-title">Warehouse Stock</h4>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <DataGrid
                                        dataSource={this.state.dashboardStockDetails}
                                        selection={{ mode: 'single' }}
                                        columnsAutoWidth={false}
                                        showColumnLines={true}
                                        showRowLines={true}
                                        showBorders={true}
                                        rowAlternationEnabled={false}
                                        allowColumnResizing={true}
                                        columnResizingMode='widget'
                                        onExporting={this.onStockExporting}
                                        onToolbarPreparing={this.onToolbarPreparing}>
                                        {/* <Column dataField="id" width={47} caption="" allowFiltering={false} allowSorting={false} cellRender={renderEditGridCell} /> */}
                                        <Column dataField="item" caption="Item Name" allowSearch={true} allowFiltering={false} allowSorting={true} width={250} />
                                        <Column dataField="warehouse" caption="Warehouse" allowSearch={true} allowFiltering={false} allowSorting={true} width={250} />
                                        <Column dataField="totalItem" caption="QTY" allowSearch={true} allowFiltering={false} allowSorting={true} width={250} />
                                        {/* <Column dataField="inWarehouse" caption="In Warehouse" allowSearch={true} allowFiltering={false} allowSorting={true} width={200} /> */}
                                        {/* <Column dataField="inField" caption="In Field" allowSearch={true} allowFiltering={false} allowSorting={true} width={150} /> */}
                                        
                                        <SortByGroupSummaryInfo summaryItem="count" />
                                        <GroupPanel visible={false} />
                                        <HeaderFilter visible={true} />
                                        <SearchPanel visible={false} highlightCaseSensitive={true} width={240} placeholder="Search..." />
                                        <Grouping autoExpandAll={true} />
                                        <Paging defaultPageSize={10} />
                                        <Pager showPageSizeSelector={true} allowedPageSizes={[10, 20, 50]} showInfo={true} visible={true} />
                                        {/* <Export enabled={false} formats={exportFormats} allowExportSelectedData={false} fileName="StockOverview" /> */}
                                    </DataGrid>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row mb-3'>
                        <div className="col-12">
                            <div className="card shadow-sm rounded-vlg">
                                <div className="card-header border-0">
                                    <h4 className="card-title">Invoices</h4>
                                </div>
                                <div className="card-body">
                                    <DataGrid
                                        dataSource={this.state.invoicepaymentSummary}
                                        selection={{ mode: 'single' }}
                                        columnsAutoWidth={false}
                                        showColumnLines={true}
                                        showRowLines={true}
                                        showBorders={true}
                                        rowAlternationEnabled={false}
                                        allowColumnResizing={true}
                                        columnResizingMode='widget'
                                        onExporting={this.onInvoiceExporting}
                                        onToolbarPreparing={this.onToolbarPreparing}>
                                        {/* <Column dataField="id" width={47} caption="" allowFiltering={false} allowSorting={false} cellRender={renderEditGridCell} /> */}
                                        <Column dataField="customerName" caption="Customer" allowSearch={true} allowFiltering={false} allowSorting={false} width={450} />
                                        <Column dataField="totalInvoiceAmount" caption="Total Invoice Amount" allowSearch={true} allowFiltering={false} allowSorting={true} width={200}
                                            format={"#,##0.###"}
                                            cellRender={renderGridNumberCell}
                                        />
                                        <Column dataField="creditAmount" caption=" Total Amount Paid" allowSearch={true} allowFiltering={false} allowSorting={true} width={200}
                                            format={"#,##0.###"}
                                            cellRender={renderGridNumberCell}
                                        />
                                        <Column dataField="dueInvoiceTotalAmount" caption="Total Amount Due" allowSearch={true} allowFiltering={false} allowSorting={true} width={200}
                                            format={"#,##0.###"}
                                            cellRender={renderGridNumberCell}
                                        />
                                             <MasterDetail
                                        enabled={true}
                                        component={DetailTemplate}
                                   
                                    />
                                        <Summary>
                                            <TotalItem column="totalAmount" summaryType="sum" customizeText={customizeText} />
                                            <TotalItem column="amountPaid" summaryType="sum" customizeText={customizeText} />
                                            <TotalItem column="amountDue" summaryType="sum" customizeText={customizeText} />
                                        </Summary>
                                        <SortByGroupSummaryInfo summaryItem="count" />
                                        <GroupPanel visible={false} />
                                        <HeaderFilter visible={true} />
                                        <SearchPanel visible={false} highlightCaseSensitive={true} width={240} placeholder="Search..." />
                                        <Grouping autoExpandAll={true} />
                                        <Paging defaultPageSize={10} />
                                        <Pager showPageSizeSelector={true} allowedPageSizes={[10, 20, 50]} showInfo={true} visible={true} />
                                        {/* <Export enabled={false} formats={exportFormats} allowExportSelectedData={false} fileName="InvoiceDetails" /> */}
                                    </DataGrid>
                                  
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row mb-3' style={{display : this.state.hidePurchaseSummary === false && this.state.hideShowPayment === false && this.state.hideSaleSummary === true ? "block": "none"}}>
                        <div className="col-12">
                            <div className="card shadow-sm rounded-vlg">
                                <div className="card-header border-0">
                                    <h4 className="card-title">Total Sales Summary</h4>
                                </div>
                                <div className="card-body">
                                    <DataGrid
                                        dataSource={this.state.dashboardSalesSummary}
                                        selection={{ mode: 'single' }}
                                        columnsAutoWidth={false}
                                        showColumnLines={true}
                                        showRowLines={true}
                                        showBorders={true}
                                        rowAlternationEnabled={false}
                                        allowColumnResizing={true}
                                        columnResizingMode='widget'
                                        onToolbarPreparing={this.onToolbarPreparing}>
                                        {/* <Column dataField="id" width={47} caption="" allowFiltering={false} allowSorting={false} cellRender={renderEditGridCell} /> */}
                                        <Column dataField="customer" caption="Customer" allowSearch={true} allowFiltering={false} allowSorting={true} width={450} />
                                        <Column dataField="totalInvoice" caption="Total Invoice#" allowSearch={true} allowFiltering={false} allowSorting={true} width={130} />
                                        <Column dataField="itemQuantity" caption="Total Quantity" allowSearch={true} allowFiltering={false} allowSorting={true} width={130} />
                                        <Column dataField="totalCompleted" caption="Total Completed" allowSearch={true} allowFiltering={false} allowSorting={true} width={130} />
                                        <Column dataField="grossAmount" caption="Gross Amount" allowSearch={true} allowFiltering={false} allowSorting={true} width={200} 
                                            format={"#,##0.###"}
                                            cellRender={renderGridNumberCell}
                                        />
                                        {/* <Column dataField="amountReceived" caption="Amount Paid" allowSearch={true} allowFiltering={false} allowSorting={true} width={250} 
                                            format={"#,##0.###"}
                                            cellRender={renderGridNumberCell}
                                        />
                                        <Column dataField="amountDue" caption="Amount Due" allowSearch={true} allowFiltering={false} allowSorting={true} width={250} 
                                            format={"#,##0.###"}
                                            cellRender={renderGridNumberCell}
                                        /> */}
                                        {/* <Summary>
                                            <TotalItem column="totalInvoice" summaryType="sum" customizeText={customizeCountText} />    
                                            <TotalItem column="itemQuantity" summaryType="sum" customizeText={customizeCountText} />
                                            <TotalItem column="grossAmount" summaryType="sum" customizeText={customizeText} />
                                            <TotalItem column="amountReceived" summaryType="sum" customizeText={customizeText} />
                                            <TotalItem column="amountDue" summaryType="sum" customizeText={customizeText} />
                                        </Summary> */}
                                        <SortByGroupSummaryInfo summaryItem="count" />
                                        <GroupPanel visible={false} />
                                        <HeaderFilter visible={true} />
                                        <SearchPanel visible={false} highlightCaseSensitive={true} width={240} placeholder="Search..." />
                                        <Grouping autoExpandAll={true} />
                                        <Paging defaultPageSize={10} />
                                        <Pager showPageSizeSelector={true} allowedPageSizes={[10, 20, 50]} showInfo={true} visible={true} />
                                        {/* <Export enabled={false} formats={exportFormats} allowExportSelectedData={false} fileName="TotalSalesSummary" /> */}
                                    </DataGrid>
                                 </div>
                            </div>
                        </div>
                    </div>
                    <div className='row mb-3' style={{display : this.state.hidePurchaseSummary === true && this.state.hideShowPayment === false && this.state.hideSaleSummary === false ? "block": "none"}}>
                        <div className="col-12">
                            <div className="card shadow-sm rounded-vlg">
                                <div className="card-header border-0">
                                    <h4 className="card-title">Total Purchase Summary</h4>
                                </div>
                                <div className="card-body">
                                    <DataGrid
                                        dataSource={this.state.dashboardPurchaseSummary}
                                        selection={{ mode: 'single' }}
                                        columnsAutoWidth={false}
                                        showColumnLines={true}
                                        showRowLines={true}
                                        showBorders={true}
                                        rowAlternationEnabled={false}
                                        allowColumnResizing={true}
                                        columnResizingMode='widget'
                                        onExporting={this.onSalesSummaryExporting}
                                        onToolbarPreparing={this.onToolbarPreparing}>
                                        {/* <Column dataField="id" width={47} caption="" allowFiltering={false} allowSorting={false} cellRender={renderEditGridCell} /> */}
                                        <Column dataField="vendor" caption="Vendor" allowSearch={true} allowFiltering={false} allowSorting={true} width={500} />
                                        <Column dataField="totalBill" caption="Total Bill#" allowSearch={true} allowFiltering={false} allowSorting={true} width={100} />
                                        <Column dataField="itemQuantity" caption="Total Quantity" allowSearch={true} allowFiltering={false} allowSorting={true} width={180} />
                                        <Column dataField="totalCompleted" caption="Total Completed" allowSearch={true} allowFiltering={false} allowSorting={true} width={180} />
                                        <Column dataField="grossAmount" caption="Gross Amount" allowSearch={true} allowFiltering={false} allowSorting={true} width={200} 
                                            format={"#,##0.###"}
                                            cellRender={renderGridNumberCell}
                                        />
                                        {/* <Column dataField="amountReceived" caption="Amount Paid" allowSearch={true} allowFiltering={false} allowSorting={true} width={250} 
                                            format={"#,##0.###"}
                                            cellRender={renderGridNumberCell}
                                        />
                                        <Column dataField="amountDue" caption="Amount Due" allowSearch={true} allowFiltering={false} allowSorting={true} width={250} 
                                            format={"#,##0.###"}
                                            cellRender={renderGridNumberCell}
                                        /> */}
                                        <Summary>
                                            <TotalItem column="totalInvoice" summaryType="sum" customizeText={customizeCountText} />    
                                            <TotalItem column="itemQuantity" summaryType="sum" customizeText={customizeCountText} />
                                            <TotalItem column="grossAmount" summaryType="sum" customizeText={customizeText} />
                                            <TotalItem column="amountReceived" summaryType="sum" customizeText={customizeText} />
                                            <TotalItem column="amountDue" summaryType="sum" customizeText={customizeText} />
                                        </Summary>
                                        <SortByGroupSummaryInfo summaryItem="count" />
                                        <GroupPanel visible={false} />
                                        <HeaderFilter visible={true} />
                                        <SearchPanel visible={false} highlightCaseSensitive={true} width={240} placeholder="Search..." />
                                        <Grouping autoExpandAll={true} />
                                        <Paging defaultPageSize={10} />
                                        <Pager showPageSizeSelector={true} allowedPageSizes={[10, 20, 50]} showInfo={true} visible={true} />
                                        {/* <Export enabled={false} formats={exportFormats} allowExportSelectedData={false} fileName="TotalSalesSummary" /> */}
                                    </DataGrid>
                                    
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className='row mb-3' style={{display : this.state.hidePurchaseSummary === false && this.state.hideShowPayment === true && this.state.hideSaleSummary === false ? "block": "none"}}>
                        <div className="col-12">
                            <div className="card shadow-sm rounded-vlg">
                                <div className="card-header border-0">
                                    <h4 className="card-title">Invoice Payment</h4>
                                </div>
                                <div className="card-body">
                                    <DataGrid
                                        dataSource={this.state.invoicePaymentDetails}
                                        selection={{ mode: 'single' }}
                                        columnsAutoWidth={false}
                                        showColumnLines={true}
                                        showRowLines={true}
                                        showBorders={true}
                                        rowAlternationEnabled={false}
                                        allowColumnResizing={true}
                                        columnResizingMode='widget'
                                        onToolbarPreparing={this.onToolbarPreparing}>
                                        {/* <Column dataField="id" width={47} caption="" allowFiltering={false} allowSorting={false} cellRender={renderEditGridCell} /> */}
                                        <Column dataField="customerName" caption="Customer" allowSearch={true} allowFiltering={false} allowSorting={true} width={420} />
                                        <Column dataField="invoiceNo" caption="Invoice#" allowSearch={true} allowFiltering={false} allowSorting={true} width={200} />
                                        <Column dataField="invoiceDate" caption="InvoiceDate" allowSearch={true} allowFiltering={false} allowSorting={true} width={180}  dataType="datetime" format="dd/MM/yyyy" />
                                        <Column dataField="grossAmount" caption="Invoice Amount" allowSearch={true} allowFiltering={false} allowSorting={true} width={200} 
                                            format={"#,##0.###"}
                                            cellRender={renderGridNumberCell}
                                        />
                                       <Column dataField="invoiceStatusName" caption="Invoice Status" allowSearch={true} allowFiltering={false} allowSorting={true} width={180} />
                                       {this.state.gridId === 1?
                                      <Column dataField="amountPaid" caption="Amount" allowSearch={true} allowFiltering={false} allowSorting={true} width={180}
                                      format={"#,##0.###"}
                                      cellRender={renderGridNumberCell} /> :
                                      this.state.gridId === 2?
                                      <Column dataField="dueAmount" caption="Due Amount" allowSearch={true} allowFiltering={false} allowSorting={true} width={180}
                                      format={"#,##0.###"}
                                      cellRender={renderGridNumberCell} /> :
                                      this.state.gridId === 3?
                                      <Column dataField="paymentOverDue" caption="Overdue Amount" allowSearch={true} allowFiltering={false} allowSorting={true} width={180} 
                                      format={"#,##0.###"}
                                      cellRender={renderGridNumberCell}/> :
                                      ""
                                    }
                                        {/* <Summary>
                                            <TotalItem column="amountPaid" summaryType="sum" customizeText={customizeCountText} />    
                                            <TotalItem column="dueAmount" summaryType="sum" customizeText={customizeCountText} />
                                            <TotalItem column="overdueAmount" summaryType="sum" customizeText={customizeCountText} />
                                           
                                        </Summary> */}
                                        <SortByGroupSummaryInfo summaryItem="count" />
                                        <GroupPanel visible={false} />
                                        <HeaderFilter visible={true} />
                                        <SearchPanel visible={false} highlightCaseSensitive={true} width={240} placeholder="Search..." />
                                        <Grouping autoExpandAll={true} />
                                        <Paging defaultPageSize={10} />
                                        <Pager showPageSizeSelector={true} allowedPageSizes={[10, 20, 50]} showInfo={true} visible={true} />
                                        {/* <Export enabled={false} formats={exportFormats} allowExportSelectedData={false} fileName="TotalSalesSummary" /> */}
                                    </DataGrid>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                
                </div>
            </Fragment>
        );
    }
}

class DetailTemplate extends React.Component {
    constructor(props) {
        super(props);
       
        this.state = {
            customerId: props.data.data.customerId,
            paymentId: 0,
            invoicepaymentDetails: [],
            financialYear:props.data.data.financialYear
        };
    }
    async componentDidMount() {
        await this.getCustomerInvoices();
    }

    getCustomerInvoices = async () => {
        try {
            const requestParams = getRequestParams("GET");
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'dashboard/InvoicePaymentDetailsByCustomer/' + this.state.customerId , requestParams);
            const data = await response.json();
        
            this.setState({
                invoicepaymentDetails: data.payload.filter( p => p.invoiceStatus !== 'Completed'),
             });
        } catch (err) {
            console.log("Error in " + this.displayName + ".getInvoicePaymentMaster function", err);
        }
    }
    render() {
        const rendersettleDate = (data)=>{
           
            if(data.data.settledDate == "0001-01-01T00:00:00"){
                return "";

            }
            else{
               return <div>{data.data.settledDate}</div>
            }
            
        }
        const renderGridTotalAmountCell = (data) => {
            return <div><small><i className={currencySymbolFormats.currencyFormat}></i></small>&nbsp;&nbsp;{parseFloat(data.value).toFixed(precisionFormats[2])}</div>;
        }
        const renderStatusGridCell = (data) => {
            
            if (data.data.invoiceStatusId == parseInt(invoiceStatus.Draft)) {
                return <div className='text-left'><span className='badge inv-status-draft border-0'>{data.data.invoiceStatus}</span></div>;
            }
            else if (data.data.invoiceStatusId == parseInt(invoiceStatus.Approved)) {
                return <div className='text-left'> <button className='badge inv-status-Approve border-0'>{data.data.invoiceStatus}</button></div>;
            }
            else if (data.data.invoiceStatusId == parseInt(invoiceStatus.SentToTally)) {
                return <div className='text-left'> <button className='badge inv-status-sendToTally border-0'>{data.data.invoiceStatus}</button></div>;
            }
            else if (data.data.invoiceStatusId == parseInt(invoiceStatus.SentToCustomer)) {
                return <div className='text-left'> <button className='badge inv-status-sendToCustomer border-0'>{data.data.invoiceStatus}</button></div>;
            }
            else if (data.data.invoiceStatusId == parseInt(invoiceStatus.Paid)) {
                return <div className='text-left'> <button className='badge inv-status-paid border-0'>{data.data.invoiceStatus}</button></div>;
            }
            else if (data.data.invoiceStatusId == parseInt(invoiceStatus.PartiallyPaid)) {
                return <div className='text-left'> <button className='badge inv-status-partiallyPaid border-0'>{data.data.invoiceStatus}</button></div>;
            }
            else if (data.data.invoiceStatusId == parseInt(invoiceStatus.Overdue)) {
                return <div className='text-left'> <button className='badge inv-status-Approve border-0'>{data.data.invoiceStatus}</button></div>;
            }
            else {
                return <div className='text-left'> <button className='badge inv-status-Approve'>{data.data.invoiceStatus}</button></div>;
            }
        }

        return (
            <Fragment>
                <div>
                    <DataGrid
                        dataSource={this.state.invoicepaymentDetails}
                        keyExpr="id"
                        id='invoicepaymentList'
                        selection={{ mode: 'single' }}
                        columnsAutoWidth={false}
                        showColumnLines={true}
                        showRowLines={true}
                        showBorders={true}
                        rowAlternationEnabled={false}
                        allowColumnResizing={true}
                        onExporting={this.onExporting}
                        columnResizingMode='widget'
                        onToolbarPreparing={this.onToolbarPreparing}>
                        <Column dataField="invoiceNo" caption="Invoice#" allowSearch={true} allowFiltering={false} allowSorting={true} width={180} />
                        <Column dataField="invoiceTypeName" caption="Invoice Type" allowSearch={true} allowFiltering={false} allowSorting={true} width={120} />
                        <Column dataField="invoiceDate" caption="Invoice Date" allowSearch={true} allowFiltering={false} allowSorting={true} width={120} dataType="datetime" format="dd/MM/yyyy" />
                        <Column dataField="invoiceDueDate" caption="Due Date" allowSearch={true} allowFiltering={false} allowSorting={true} width={140} dataType="datetime" format="dd/MM/yyyy" />
                        <Column dataField="totalAmount" caption="Invoice Amount" allowSearch={true} allowFiltering={false} allowSorting={true} width={140} cellRender={renderGridTotalAmountCell} />
                        <Column dataField="settledDate" caption="Settle Date" allowSearch={true} allowFiltering={false} allowSorting={true} width={140} cellRender={rendersettleDate}  />
                        <Column dataField="settledAmount" caption="Settle Amount" allowSearch={true} allowFiltering={false} allowSorting={true} width={140} cellRender={renderGridTotalAmountCell} />
                        <Column dataField="dueAmount" caption="Due Amount" allowSearch={true} allowFiltering={false} allowSorting={true} width={140} cellRender={renderGridTotalAmountCell} />
                        <Column dataField="invoiceStatus" caption="Status" allowSearch={true} allowFiltering={false} allowSorting={true} width={150} cellRender={renderStatusGridCell} />
                        <SortByGroupSummaryInfo summaryItem="count" />
                        <GroupPanel visible={false} />
                        {/* <Template name="toolbarTemplate" /> */}
                        <HeaderFilter visible={true} />
                        <SearchPanel visible={false} highlightCaseSensitive={true} width={240} placeholder="Search..." />
                        <Grouping autoExpandAll={true} />
                        {/* <Paging defaultPageSize={20} /> */}
                        <Pager showPageSizeSelector={false} allowedPageSizes={[10, 20, 50]} showInfo={false} visible={false} />
                        <Export enabled={false} allowExportSelectedData={false} fileName="InvoicePayment" />
                    </DataGrid>
                </div>
            </Fragment>
        );
    }
}

export default DetailTemplate;