import React, { Fragment, Component } from 'react';
import DataGrid, {
    Column,
    Export, Grouping,
    GroupPanel,
    HeaderFilter, Pager,
    Paging,
    SearchPanel,
    SortByGroupSummaryInfo,
    Summary, TotalItem,
} from 'devextreme-react/data-grid'; //npm i devextreme-react
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import { getRequestParams, formatDate,numberGridFormat } from '../../helpers/globalfunctions.js';
import { LoadPanel } from 'devextreme-react/load-panel';
import { alphanumericValidation } from '../../helpers/globalvalidations.js';
import { currencySymbolFormats, precisionFormats,documentType } from '../../helpers/fixcodes.js';
import { SelectBox } from 'devextreme-react/select-box';
import { createBrowserHistory } from 'history';
import DateBox from 'devextreme-react/date-box';
import { DocumentsComponent } from '../shared/DocumentsComponent.jsx';
import { jsPDF } from 'jspdf';
import { exportDataGrid } from 'devextreme/pdf_exporter';
const exportFormats = ['pdf', 'xlsx'];
const position = { of: '#historydiv' };
const history = createBrowserHistory();

export class PurchseOrderListComponent extends Component {
    static displayName = PurchseOrderListComponent.name;
    constructor(props) {
        super(props);

        this.state = {
            isSubmited: false,
            loadPanelVisible: false,
            purchaseOrderSource: null,
            accountList:null,
            accountId: 0,
            orderDate: "",
            shippingDate: "",
            invoiceNo: "",
            orderNo: ""
        }

        this.getPurchaseOrderMaster = this.getPurchaseOrderMaster.bind(this);
    }

    async componentDidMount() {
        await this.getPurchaseOrderMaster();
        await this.getAccounts();
    }

    getPurchaseOrderMaster = async () => {
        this.setState({
            loadPanelVisible: true
        });
        try {
            var request = {};
            request.DeliveryDate = this.state.shippingDate === "" ? "" : formatDate(this.state.shippingDate, "yyyy-MM-dd");
            request.PODate = this.state.orderDate === "" ? "" : formatDate(this.state.orderDate, "yyyy-MM-dd");
            request.CustomerId = parseInt(this.state.accountId);
            request.InvoiceNo = this.state.invoiceNo;
            request.OrderNo = this.state.orderNo;
            request.PurchaseOrderId = parseInt(0);
            const requestParams = getRequestParams("POST", request);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'purchase/purchaseOrder', requestParams);
            const data = await response.json();
            this.setState({
                purchaseOrderSource: data.payload === null ? [] : data.payload,
                loadPanelVisible: false
            });
        } catch (err) {
            console.log("Error in " + this.displayName + ".getPurchaseOrderMaster function", err);
            this.setState({
                loadPanelVisible: false
            });
        }
    }
    getAccounts = async () => {
        try {
            var request = {};
            const requestParams = getRequestParams("GET", request);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'account/dropdownAccount/3', requestParams);
            const data = await response.json();
            this.setState({
                accountList: data.payload === null ? [] : data.payload,
            });
        } catch (err) {
            console.log("Error in " + this.displayName + ".getAccounts function", err);
        }
    }

    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift({
            location: 'after',
            widget: 'dxButton',
            options: {
                icon: 'refresh',
                onClick: this.getPurchaseOrderMaster.bind(this)
            }
        });
    }

    editPurchaseOrder(e, data) {
        e.preventDefault();
        history.push({
            pathname: '/purchaseorder/new',
            state: {
                purchaseorderId: data.id,
                orderNo: data.poNo,
                orderDate: data.orderDate,
                shippingDate: data.shippingDate,
                invoiceNo: data.invoiceNo,
                customerId: data.customerId,
                sourceUrl: "/purchaseorder/list",
                sourceState: this.state
            }
        });
        window.location.reload(false);
    }

    handlePurchaseOrder = (e) => {
        sessionStorage.setItem("session_PageName", "Purchase Order");
        window.location.href = "/purchaseorder/new";
    }
    handleOnViewUploadDocument = async (e, data) => {
        e.preventDefault();
        try {
            var documentArray = []
            let row = {};
            row.id = data.id === null ? 0 : Number(data.id);
            row.poNo = data.poNo === null ? '' : data.poNo.trim();
            documentArray.push(row);
            this.refs.documentsComponent.showModal('Purchase Order', documentArray, data.poNo);
        } catch (ex) {
            console.log(ex);
        }
    }

    handleOrderDateChanged = (date) => {
        this.setState({ orderDate: date.value });
    }

    handleShippingDateChanged = (date) => {
        this.setState({ shippingDate: date.value });
    }
    handleAccountChange = (selectedOption) => {
        this.setState({
            accountId: (selectedOption.selectedItem !== null ? selectedOption.selectedItem.id : 0),
        });
    }
    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        switch (name) {
            case 'orderNo':
                if (alphanumericValidation.test(event.target.value))
                    this.setState({ orderNo: event.target.value });
                break;
            case 'invoiceNo':
                if (alphanumericValidation.test(event.target.value))
                    this.setState({ invoiceNo: event.target.value });
                break;
            default:
                this.setState({ [name]: value });
                break;
        }
    }
    onExporting = (e) => {
        const doc = new jsPDF();
        if (e.format === "pdf") {
            exportDataGrid({
                jsPDFDocument: doc,
                component: e.component,
                columnWidths: 50
                // indent: 10,
            }).then(() => {
                doc.save('PurchaseOrder.pdf');
            });
        }
    }
    handleClear = () => {
        this.setState({
            accountId: 0,
            orderDate: "",
            shippingDate: "",
            invoiceNo: "",
            orderNo: ""
        });
        setTimeout(() => this.getPurchaseOrderMaster(), 100);
        //this.getPurchaseOrderMaster();
    }
    render() {
        const renderGridCell = (data) => {
            return <div>
                <a href="/#" className="fas fa-pen" title="Edit" onClick={(e) => { this.editPurchaseOrder(e, data.data) }}>&nbsp;</a>
                <a href="/#" className="fas fa-file-alt" title="Document(s)" onClick={(e) => { this.handleOnViewUploadDocument(e, data.data) }}>&nbsp;</a>
            </div>;
        }

        const renderGridNumberCell = (data) => {
            return numberGridFormat(data.value)
            //return <div><small><i class={currencySymbolFormats.currencyFormat}></i></small>&nbsp;&nbsp;{parseFloat(data.value).toFixed(precisionFormats[2])}</div>;
        }

        // const customizeText = (data) => {
        //     var value = 'Total: ' + numberGridFormat(data.value);
        //     return value
        //     // return `Total - ${data.value.toFixed(precisionFormats[2])}`;
        // }

        return (
            <Fragment>
                <div className="section-body">
                    <div className='card bg-filter'>
                        <div className='card-body'>
                            <div className="section-header">
                                <div className='row'>
                                    <div className='col-lg-4 col-md-2 col-sm-12'>
                                        <div className="form-group">
                                            <label htmlFor="">Vendor</label>
                                            <SelectBox
                                                dataSource={this.state.accountList}
                                                className="basic-multi-select"
                                                classNamePrefix="select"
                                                placeholder="Select vendor"
                                                name="id"
                                                defaultValue={0}
                                                value={this.state.accountId}
                                                displayExpr='name'
                                                valueExpr='id'
                                                onSelectionChanged={this.handleAccountChange}
                                                showClearButton={true}
                                                searchEnabled={true}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-2 col-md-3 col-sm-12 col-xs-12">
                                        <div className="form-group">
                                            <label htmlFor="">Order#</label>
                                            <input type="text" className="form-control form-control-sm" name="orderNo" placeholder="Enter order number" value={this.state.orderNo}
                                                title="Order No" maxLength="20" onChange={this.handleInputChange} />
                                        </div>
                                    </div>
                                    <div className='col-lg-2 col-md-2 col-sm-12'>
                                        <div className="form-group">
                                            <label htmlFor="">Order Date</label>
                                            <DateBox type="date"
                                                className="replay-date-time"
                                                name="orderDate"
                                                placeholder="dd/mm/yyyy"
                                                displayFormat="dd/MM/yyyy"
                                                showAnalogClock={true}
                                                useMaskBehavior={true}
                                                defaultValue={this.state.orderDate}
                                                value={this.state.orderDate}
                                                onValueChanged={this.handleOrderDateChanged}
                                                width="100%"
                                                acceptCustomValue={false}
                                            />
                                        </div>
                                    </div>
                                    {/* <div className='col-lg-2 col-md-2 col-sm-12'>
                                            <div className="form-group">
                                                <label htmlFor="">Shipping Date</label>
                                                <DateBox type="date"
                                                    className="replay-date-time"
                                                    name="shippingDate"
                                                    placeholder="dd/mm/yyyy"
                                                    displayFormat="dd/MM/yyyy"
                                                    showAnalogClock={true}
                                                    useMaskBehavior={true}
                                                    defaultValue={this.state.shippingDate}
                                                    value={this.state.shippingDate}
                                                    onValueChanged={this.handleShippingDateChanged}
                                                    width="100%"
                                                    disabled={false}
                                                    acceptCustomValue={false}
                                                />
                                            </div>
                                        </div> */}

                                    <div className="col-lg-2 col-md-3 col-sm-12 col-xs-12">
                                        <div className="form-group">
                                            <label htmlFor="">Bill#</label>
                                            <input type="text" className="form-control form-control-sm" name="invoiceNo" placeholder="Enter bill number" value={this.state.invoiceNo}
                                                title="Invoice No" maxLength="20" onChange={this.handleInputChange} />
                                        </div>
                                    </div>
                                    <div className="col-lg-2 col-md-3 col-sm-12 col-xs-12">
                                        <div className="form-group">
                                            <label htmlFor="">&nbsp;</label><br />
                                            <button type="submit" className="btn btn-success" onClick={this.getPurchaseOrderMaster}>
                                                Search
                                            </button>&nbsp;&nbsp;
                                            <button type="submit" className="btn btn-outline-secondary" onClick={this.handleClear}>
                                                Clear
                                            </button>&nbsp;&nbsp;
                                            <button type="submit" className="btn btn-success" onClick={this.handlePurchaseOrder}>
                                                New
                                            </button>                                           
                                        </div>
                                    </div>
                                </div>
                            </div>                           
                        </div>
                    </div>
                    <div className='card'>
                    <div className='card-body'>
                                <DataGrid
                                    dataSource={this.state.purchaseOrderSource}
                                    keyExpr="id"
                                    id='gridSalesOrderList'
                                    selection={{ mode: 'single' }}
                                    columnsAutoWidth={false}
                                    showColumnLines={true}
                                    showRowLines={true}
                                    showBorders={true}
                                    rowAlternationEnabled={false}
                                    allowColumnResizing={true}
                                    columnResizingMode='widget'
                                    onExporting={this.onExporting}
                                    onToolbarPreparing={this.onToolbarPreparing}>
                                    <Column dataField="id" width={50} caption="" allowFiltering={false} allowSorting={false} cellRender={renderGridCell} />
                                    <Column dataField="customerName" caption="Vendor" allowSearch={true} allowFiltering={false} allowSorting={true} width={150} />
                                    <Column dataField="poNo" caption="Order#" allowSearch={true} allowFiltering={false} allowSorting={true} width={150} />
                                    <Column dataField="orderDate" caption="Order Date" allowSearch={true} allowFiltering={false} allowSorting={true} width={180} dataType="datetime" format="dd/MM/yyyy HH:mm" />
                                    <Column dataField="grossAmount" caption="Gross Amt" allowSearch={true} allowFiltering={false} allowSorting={true} width={250}
                                        format={"#,##0.###"}
                                        cellRender={renderGridNumberCell}
                                    />
                                    <Column dataField="billNo" caption="Bill#" allowSearch={true} allowFiltering={false} allowSorting={true} width={200} />
                                    {/* <Column dataField="type" caption="Payment Due" allowSearch={true} allowFiltering={false} allowSorting={true} width={180} /> */}
                                    {/* <Column dataField="shippingDate" caption="Shipped Date" allowSearch={true} allowFiltering={false} allowSorting={true} width={180} dataType="datetime" format="dd/MM/yyyy HH:mm" /> */}
                                    <Column dataField="orderStatus" caption="Status" allowSearch={true} allowFiltering={false} allowSorting={true} width={100} />
                                    {/* <Summary>
                                        <TotalItem column="grossAmount" summaryType="sum"  />
                                    </Summary> */}
                                    <SortByGroupSummaryInfo summaryItem="count" />
                                    <GroupPanel visible={false} />
                                    {/* <Template name="toolbarTemplate" /> */}
                                    <HeaderFilter visible={true} />
                                    <SearchPanel visible={true} highlightCaseSensitive={true} width={240} placeholder="Search..." />
                                    <Grouping autoExpandAll={true} />
                                    <Paging defaultPageSize={20} />
                                    <Pager showPageSizeSelector={true} allowedPageSizes={[10, 20, 50]} showInfo={true} visible={true} />
                                    <Export enabled={true}  allowExportSelectedData={false} fileName="PurchaseOrder" />
                                </DataGrid>
                            </div>
                    </div>
                </div>
                <DocumentsComponent ref="documentsComponent" />
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={position}
                    visible={this.state.loadPanelVisible}
                    showIndicator={true}
                    shading={true}
                    showPane={true}
                    hideOnOutsideClick={false}
                />
            </Fragment>
        )
    };
}