
import DataGrid, {
    Column,
    Export, Grouping,
    GroupPanel,
    HeaderFilter, Pager,
    Paging,
    SearchPanel,
    SortByGroupSummaryInfo
} from 'devextreme-react/data-grid'; //npm i devextreme-react
import React, { Fragment, Component } from "react";
import { getRequestParams } from '../../helpers/globalfunctions.js';
import { SelectBox } from 'devextreme-react/select-box';
import { formatDate } from '../../helpers/globalfunctions.js';
import DateBox from 'devextreme-react/date-box';
import { exportDataGrid } from 'devextreme/pdf_exporter';
import { jsPDF } from 'jspdf';
const exportFormats = ['pdf', 'xlsx'];


export class EventLogComponent extends Component {
    static displayName = EventLogComponent.name;

    constructor(props) {
        super(props);
        this.state = {
            eventlogsource: [],
            userid: 0,
            moduleTypeId: 0,
            filterUserId: 0,
            fromDate: new Date(),
            toDate: new Date(),
            sessionUser: JSON.parse(sessionStorage.getItem("sessionUser")),
            username: "",
            modulename: "",
            source: "",
            description: "",
            logdatetime: "",
            moduleType: [],
            userlist: [],
        }
    }

    //lifecycle page load
    async componentDidMount() {
        await this.getEventLogMaster();
        await this.getModuleType();
        await this.getUser();
    }

    //finding value in gridviewlist
    getEventLogMaster = async () => {
        try {
            var request = {};
            request.UserId = this.state.filterUserId;
            request.moduleTypeId = this.state.moduleTypeId;
            request.FromDate = formatDate(this.state.fromDate, "MM/dd/yyyy") + ' ' + '00:00:00';
            request.ToDate = formatDate(this.state.toDate, "MM/dd/yyyy") + ' ' + "23:59:59";
            const requestParams = getRequestParams("POST", request);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'eventlog', requestParams);
            const data = await response.json();
            this.setState({
                eventlogsource: data.payload
            })
        }
        catch (err) {
            console.log("Error in " + this.displayName + ".getEventLogMaster function", err);
        }
    }

    //for fetching value in module dropdown  
    getModuleType = async () => {
        try {
            var request = {};
            const requestParams = getRequestParams("GET", request);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'eventlog/dropdown/moduletype')
            const data = await response.json();
            this.setState({
                moduleType: data.payload
            });
        } catch (err) {
            console.log("Error in " + this.displayName + ".getModuleType function", err);
        }
    }

    //for fetching value in module dropdown  
    getUser = async () => {
        try {
            var request = {};
            const requestParams = getRequestParams("GET", request);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'users/dropdown/user')
            const data = await response.json();
            this.setState({
                userlist: data.payload
            });
        } catch (err) {
            console.log("Error in " + this.displayName + ".getUser function", err);
        }
    }

    //dropdown value change 
    handleModuleTypeChange = (selectedOption) => {
        this.setState({
            moduleTypeId: (selectedOption.selectedItem !== null ? selectedOption.selectedItem.id : 0)
        });
    }

    handleUserChange = (selectedOption) => {
        this.setState({
            filterUserId: (selectedOption.selectedItem !== null ? selectedOption.selectedItem.id : 0)
        });
    }

    handleFormtDateChanged = (date) => {
        this.setState({ fromDate: date.value });
    }
    handleToDateChanged = (date) => {
        this.setState({ toDate: date.value });
    }


    handleSubmit = async () => {
        await this.getEventLogMaster();
    }

    handleCancel = () => {
        this.setState({
            moduleTypeId: 0,
            filterUserId: 0,
            fromDate: new Date(),
            toDate: new Date(),
        });
        setTimeout(() => this.getEventLogMaster(), 100);
    }

    //icon refresh
    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift({
            location: 'after',
            widget: 'dxButton',
            options: {
                icon: 'refresh',
                onClick: this.getEventLogMaster.bind(this)
            }
        });
    }

    onExporting = (e) => {
        const doc = new jsPDF();
        if (e.format === "pdf") {
            exportDataGrid({
                jsPDFDocument: doc,
                component: e.component,
                columnWidths: 50
                // indent: 10,
            }).then(() => {
                doc.save('EventLog.pdf');
            });
        }
    }
    render() {
        return (
            <Fragment>
                <div div className="section-body">
                    <div className='card bg-filter'>
                        <div className='card-body'>
                            <div className="section-header">
                                <div className="row">
                                    <div className="col-lg-2">
                                        <div className="form-group">
                                            <label htmlFor="">Module Type</label>
                                            <SelectBox
                                                dataSource={this.state.moduleType}
                                                className="basic-multi-select"
                                                classNamePrefix="select"
                                                placeholder="Select module type"
                                                name="id"
                                                defaultValue={0}
                                                value={this.state.moduleTypeId}
                                                displayExpr='name'
                                                valueExpr='id'
                                                onSelectionChanged={this.handleModuleTypeChange}
                                                showClearButton={true}
                                                disabled={this.state.id > 0}
                                                searchEnabled={true}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-2">
                                        <div className="form-group">
                                            <label htmlFor="">User</label>
                                            <SelectBox
                                                dataSource={this.state.userlist}
                                                className="basic-multi-select"
                                                classNamePrefix="select"
                                                placeholder="Select user"
                                                name="id"
                                                defaultValue={0}
                                                value={this.state.filterUserId}
                                                displayExpr='name'
                                                valueExpr='id'
                                                onSelectionChanged={this.handleUserChange}
                                                showClearButton={true}
                                                disabled={this.state.id > 0}
                                                searchEnabled={true}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-2">
                                        <div className="form-group">
                                            <label>From Date </label>
                                            <DateBox type="date"
                                                className="replay-date-time"
                                                name="fromDate"
                                                placeholder="MM/dd/yyyy"
                                                displayFormat="MM/dd/yyyy"
                                                showAnalogClock={true}
                                                useMaskBehavior={true}
                                                defaultValue="MM/dd/yyyy"
                                                value={this.state.fromDate}
                                                onValueChanged={this.handleFormtDateChanged}
                                                width="100%"
                                                disabled={false}
                                                acceptCustomValue={false}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-2">
                                        <div className="form-group">
                                            <label>To Date</label>
                                            <DateBox type="date"
                                                className="replay-date-time"
                                                name="toDate"
                                                placeholder="MM/dd/yyyy"
                                                displayFormat="MM/dd/yyyy"
                                                showAnalogClock={true}
                                                useMaskBehavior={true}
                                                defaultValue="MM/dd/yyyy"
                                                value={this.state.toDate}
                                                onValueChanged={this.handleToDateChanged}
                                                width="100%"
                                                disabled={false}
                                                acceptCustomValue={false}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-lg-2'>
                                        <div className='form-group'>
                                        <label>&nbsp;</label><br></br>
                                        <button type="submit" className="btn btn-success" onClick={this.handleSubmit}>
                                            Search
                                        </button>&nbsp;&nbsp;
                                        <button type="submit" className="btn btn-outline-secondary" onClick={this.handleCancel}>
                                            <span title="Clear">Clear</span>
                                        </button>
                                        </div>
                                    </div>
                                </div>
                            </div>                            
                        </div >
                    </div>
                    <div className='card'>
                    <div className='card-body'>
                                <DataGrid
                                    dataSource={this.state.eventlogsource}
                                    keyExpr="id"
                                    selection={{ mode: 'single' }}
                                    columnsAutoWidth={false}
                                    showColumnLines={true}
                                    showRowLines={true}
                                    showBorders={true}
                                    rowAlternationEnabled={false}
                                    allowColumnResizing={true}
                                    columnResizingMode='widget'
                                    onExporting={this.onExporting}
                                    onToolbarPreparing={this.onToolbarPreparing}>

                                    <Column dataField="moduleTypeName" caption="Module Type" allowSearch={true} allowFiltering={false} allowSorting={true} width={150} />
                                    <Column dataField="source" caption="Source" allowSearch={true} allowFiltering={false} allowSorting={true} width={80} />
                                    <Column dataField="description" caption="Description" allowSearch={true} allowFiltering={false} allowSorting={true} width={600} />
                                    <Column dataField="userName" caption="User" allowSearch={true} allowFiltering={false} allowSorting={true} width={80} />
                                    <Column dataField="logDateTime" caption="Log Date time" allowSearch={true} allowFiltering={false} allowSorting={true} width={100} />

                                    <SortByGroupSummaryInfo summaryItem="count" />
                                    <GroupPanel visible={false} />
                                    {/* <Template name="toolbarTemplate" /> */}
                                    <HeaderFilter visible={true} />
                                    <SearchPanel visible={true} highlightCaseSensitive={true} width={240} placeholder="Search..." />
                                    <Grouping autoExpandAll={true} />
                                    <Paging defaultPageSize={20} />
                                    <Pager showPageSizeSelector={true} allowedPageSizes={[10, 20, 50]} showInfo={true} visible={true} />
                                    <Export enabled={true}  allowExportSelectedData={false} fileName="EventLog" />
                                </DataGrid>
                            </div>
                    </div>
                </div>
            </Fragment>
        )
    };
}

