import React, { Fragment, Component } from 'react';
import DataGrid, {
    Column,
    Paging,
    Selection,
    Editing,
    RequiredRule,
    PatternRule
} from 'devextreme-react/data-grid'; //npm i devextreme-react
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import {
    alphanumericValidation, floatValidation, vMinLength, vMinUpperCaseLetter, vMinLowerCaseLetter, vMinNumber,
    vMinSpecialCharacter, vIsPasswordComplexity
} from '../../helpers/globalvalidations.js';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import Swal from 'sweetalert2'; //npm i sweetalert2 --save
import { toast } from 'react-toastify'; //npm i react-toastify --save
import { getRequestParams, formatDate } from '../../helpers/globalfunctions.js';
import { currencySymbolFormats } from '../../helpers/fixcodes.js';
import { PatternFormat } from 'react-number-format';
import axios from 'axios'; //npm install--save axios
import { CheckBox } from 'devextreme-react/check-box';
import Modal from 'react-modal'; //npm install --save react-modal;
import { SelectBox } from 'devextreme-react/select-box';
import DateBox from 'devextreme-react/date-box';

const customStyles = {
    content: {
        width: '55%',
    }
}

export class AddCustomerVendorComponent extends Component {
    static displayName = AddCustomerVendorComponent.name;
    constructor(props) {
        super(props);
        this.state = {
            isSubmited: false,
            loadPanelVisible: false,
            contractConfigurationModal: false,
            customername: "",
            phone: "",
            email: "",
            website: "",
            GSTNo: "",
            panNo: "",
            contactPerson: "",
            billingAddressLine1: "",
            billingAddressLine2: "",
            billingCity: "",
            billingState: "",
            billingZipCode: "",
            shippingAddressLine1: "",
            shippingAddressLine2: "",
            shippingCity: "",
            shippingState: "",
            shippingZipCode: "",
            firstName: "",
            lastName: "",
            userName: "",
            password: "",
            accountTypeName: this.props.location.state === undefined ? "" : this.props.location.state.AccountTypeName,
            file: null,
            fileGuid: "00000000-0000-0000-0000-000000000000",
            accountId: 0,
            moduleTypeName: this.props.location.state === undefined ? "" : this.props.location.state.ModuleTypeName,
            sourceUrl: this.props.location.state.sourceUrl,
            documentSelectedFile: [],
            documentUniqueId: 0,
            documentTypeId: this.props.location.state === undefined ? 0 : this.props.location.state.DocumentTypeId,
            resultMeassage: "",
            sessionUser: JSON.parse(sessionStorage.getItem("sessionUser")),
            targetFile: [],
            contractConfigurationChecked: false,
            contractType: [],
            contractStatus: [],
            paymentTermType: [],
            startDate: new Date(),
            endDate: new Date(),
            contractTypeId: 0,
            contractStatusId: 1,
            paymentTermTypeId: 0,
            salePrice: 0,
            selectedItemKeys: [],
            selectedRowsData: [],
            customerContractRequest: {},
            contractServicesItemRequest: {},
            id: 0,
            errors: {
                customername: "",
                phone: "",
                email: "",
                website: "",
                GSTNo: "",
                panNo: "",
                contactPerson: "",
                billingAddressLine1: "",
                billingCity: "",
                billingState: "",
                billingZipCode: "",
                shippingAddressLine1: "",
                shippingCity: "",
                shippingState: "",
                shippingZipCode: "",
                firstName: "",
                lastName: "",
                userName: "",
                password: "",
                contractTypeId: 0,
                contractStatusId: 1,
                paymentTermTypeId: 0,
                servicesource: [],
            }
        }

        this.handleContractConfiguration = this.handleContractConfiguration.bind(this);
        this.handleCustomerContractSubmit = this.handleCustomerContractSubmit.bind(this);
    }

    async componentDidMount() {
        this.setState({
            loadPanelVisible: true
        });
        await this.getContractType();
        await this.getContractStatus();
        await this.getPaymentTermType();
        await this.getServiceMaster();
        this.setState({
            loadPanelVisible: false
        });
    }

    getContractType = async () => {
        try {
            var request = {};
            const requestParams = getRequestParams("GET", request);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'contractConfiguration/contractType/0', requestParams);
            const data = await response.json();
            this.setState({
                contractType: data.payload
            });
        } catch (err) {
            console.log("Error in " + this.displayName + ".getContractType function", err);
        }
    }

    getContractStatus = async () => {
        try {
            var request = {};
            const requestParams = getRequestParams("GET", request);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'contractConfiguration/contractStatus/0', requestParams);
            const data = await response.json();
            this.setState({
                contractStatus: data.payload
            });
        } catch (err) {
            console.log("Error in " + this.displayName + ".getContractStatus function", err);
        }
    }

    getPaymentTermType = async () => {
        try {
            var request = {};
            const requestParams = getRequestParams("GET", request);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'paymentTermType/dropdownType', requestParams);
            const data = await response.json();

            this.setState({
                paymentTermType: data.payload
            });
        } catch (err) {
            console.log("Error in " + this.displayName + ".getPaymentTermType function", err);
        }
    }

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        let errors = this.state.errors;
        const numeric = /^[]?\d*(?:[]\d*)?$/;
        switch (name) {
            case 'customername':
                if (alphanumericValidation.test(event.target.value))
                    this.setState({ customername: event.target.value });
                break;
            case 'phone':
                if (numeric.test(event.target.value))
                    this.setState({ phone: event.target.value });
                break;
            case 'email':
                if (alphanumericValidation.test(event.target.value))
                    this.setState({ email: event.target.value });
                break;
            case 'website':
                if (alphanumericValidation.test(event.target.value))
                    this.setState({ website: event.target.value });
                break;
            case 'GSTNo':
                if (alphanumericValidation.test(event.target.value))
                    this.setState({ GSTNo: event.target.value });
                break;
            case 'panNo':
                if (alphanumericValidation.test(event.target.value))
                    this.setState({ panNo: event.target.value });
                break;
            case 'contactPerson':
                if (alphanumericValidation.test(event.target.value))
                    this.setState({ contactPerson: event.target.value });
                break;
            case 'billingAddressLine1':
                if (alphanumericValidation.test(event.target.value))
                    this.setState({ billingAddressLine1: event.target.value });
                break;
            case 'billingAddressLine2':
                if (alphanumericValidation.test(event.target.value))
                    this.setState({ billingAddressLine2: event.target.value });
                break;
            case 'billingCity':
                if (alphanumericValidation.test(event.target.value))
                    this.setState({ billingCity: event.target.value });
                break;
            case 'billingState':
                if (alphanumericValidation.test(event.target.value))
                    this.setState({ billingState: event.target.value });
                break;
            case 'billingZipCode':
                if (numeric.test(event.target.value))
                    this.setState({ billingZipCode: event.target.value });
                break;
            case 'shippingAddressLine1':
                if (alphanumericValidation.test(event.target.value))
                    this.setState({ shippingAddressLine1: event.target.value });
                break;
            case 'shippingAddressLine2':
                if (alphanumericValidation.test(event.target.value))
                    this.setState({ shippingAddressLine2: event.target.value });
                break;
            case 'shippingCity':
                if (alphanumericValidation.test(event.target.value))
                    this.setState({ shippingCity: event.target.value });
                break;
            case 'shippingState':
                if (alphanumericValidation.test(event.target.value))
                    this.setState({ shippingState: event.target.value });
                break;
            case 'shippingZipCode':
                if (numeric.test(event.target.value))
                    this.setState({ shippingZipCode: event.target.value });
                break;
            case 'firstName':
                if (alphanumericValidation.test(event.target.value))
                    this.setState({ firstName: event.target.value });
                break;
            case 'lastName':
                if (alphanumericValidation.test(event.target.value))
                    this.setState({ lastName: event.target.value });
                break;
            case 'userName':
                if (alphanumericValidation.test(event.target.value))
                    this.setState({ userName: event.target.value });
                break;
            case 'password':
                if (alphanumericValidation.test(event.target.value))
                    this.setState({ password: event.target.value });
                break;
            default:
                this.setState({ [name]: value });
                break;
        }

        errors[name] = "";
        this.setState({
            [name]: value,
            errors: errors
        });

        //this.state.errors[name] = "";
        // this.state = {
        //     errors: {
        //         [name] : ""
        //     }
        // }
    }

    handleValidation = () => {
        let formIsValid = true;
        try {
            let errors = this.state.errors;
            errors.customername = "";
            errors.phone = "";
            errors.email = "";
            errors.website = "";
            errors.GSTNo = "";
            errors.panNo = "";
            errors.contactPerson = "";
            errors.billingAddressLine1 = "";
            errors.billingCity = "";
            errors.billingState = "";
            errors.billingZipCode = "";
            errors.shippingAddressLine1 = "";
            errors.shippingCity = "";
            errors.shippingState = "";
            errors.shippingZipCode = "";
            errors.firstName = "";
            errors.lastName = "";
            errors.userName = "";
            errors.password = "";
            if (this.state.customername === "") {
                formIsValid = false;
                errors.customername = "Please enter name.";
            }
            // if (this.state.email === null || this.state.email.trim() === '') {
            //     formIsValid = false;
            //     errors.email = "Please enter email.";
            // } else if (this.state.email !== '') {
            //     let regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            //     if (!regEmail.test(this.state.email)) {
            //         formIsValid = false;
            //         errors.email = "Please enter valid email.";
            //     }
            // }
            // if (this.state.phone === null || this.state.phone.trim() === "") {
            //     formIsValid = false;
            //     errors.phone = "Please enter phone number.";
            // } else if (this.state.phone.trim().replace("(", "").replace(")", "").replace("-", "").replaceAll(" ", "").length !== 10) {
            //     formIsValid = false;
            //     errors.phone = "Invalid mobile number.";
            // }
            // if (this.state.website.trim() === "") {
            //     formIsValid = false;
            //     errors.website = "Please enter website.";
            // }
            // if (this.state.GSTNo.trim() === "") {
            //     formIsValid = false;
            //     errors.GSTNo = "Please enter GST number.";
            // }
            // if (this.state.panNo.trim() === "") {
            //     formIsValid = false;
            //     errors.panNo = "Please enter PAN number.";
            // }
            // if (this.state.contactPerson.trim() === "") {
            //     formIsValid = false;
            //     errors.contactPerson = "Please enter contact person.";
            // }
            // if (this.state.billingAddressLine1.trim() === "") {
            //     formIsValid = false;
            //     errors.billingAddressLine1 = "Please enter billing address 1.";
            // }
            // if (this.state.billingCity.trim() === "") {
            //     formIsValid = false;
            //     errors.billingCity = "Please enter city.";
            // }
            // if (this.state.billingState.trim() === "") {
            //     formIsValid = false;
            //     errors.billingState = "Please enter state.";
            // }
            // if (this.state.billingZipCode.trim() === "") {
            //     formIsValid = false;
            //     errors.billingZipCode = "Please enter postal code.";
            // }
            // if (this.state.shippingAddressLine1.trim() === "") {
            //     formIsValid = false;
            //     errors.shippingAddressLine1 = "Please enter shipping address 1.";
            // }
            // if (this.state.shippingCity.trim() === "") {
            //     formIsValid = false;
            //     errors.shippingCity = "Please enter city.";
            // }
            // if (this.state.shippingState.trim() === "") {
            //     formIsValid = false;
            //     errors.shippingState = "Please enter state.";
            // }
            // if (this.state.shippingZipCode.trim() === "") {
            //     formIsValid = false;
            //     errors.shippingZipCode = "Please enter postal code.";
            // }
            // if (this.state.userName.trim() === "") {
            //     formIsValid = false;
            //     errors.userName = "Please enter username.";
            // }
            // if (this.state.firstName.trim() === "") {
            //     formIsValid = false;
            //     errors.firstName = "Please enter first name.";
            // }
            // if (this.state.lastName.trim() === "") {
            //     formIsValid = false;
            //     errors.lastName = "Please enter last name.";
            // }
            // if ((this.state.password.trim() === "" && vIsPasswordComplexity === true) || this.state.password.trim() !== "") {
            //     const renumbersymbolexp = /[^0-9]/g;
            //     const lowercaseexp = /[^a-z]/g;
            //     const uppercaseexp = /[^A-Z]/g;
            //     const specialcharacters = /[^!@#$%^&*]/g;
            //     var IsPwdnumbersymbol = this.state.password.replace(renumbersymbolexp, "").length;
            //     var IsLowercase = this.state.password.replace(lowercaseexp, "").length;
            //     var IsUppercase = this.state.password.replace(uppercaseexp, "").length;
            //     var IsSpecialCharacters = this.state.password.replace(specialcharacters, "").length;
            //     if (this.state.password.trim() === "") {
            //         formIsValid = false;
            //         errors.password = "Please enter password.";
            //     }
            //     else if (this.state.password.length < vMinLength) {
            //         formIsValid = false;
            //         errors.password = "Password must be " + vMinLength + " characters long";
            //     }
            //     else if (IsPwdnumbersymbol < vMinNumber) {
            //         formIsValid = false;
            //         errors.password = "Password must contains atleast " + vMinNumber + " number";
            //     }
            //     else if (IsLowercase < vMinLowerCaseLetter) {
            //         formIsValid = false;
            //         errors.password = "Password must contains atleast " + vMinLowerCaseLetter + " lowercase alphabet";
            //     }
            //     else if (IsUppercase < vMinUpperCaseLetter) {
            //         formIsValid = false;
            //         errors.password = "Password must contains atleast " + vMinUpperCaseLetter + " uppercase alphabet";
            //     }
            //     else if (IsSpecialCharacters < vMinSpecialCharacter) {
            //         formIsValid = false;
            //         errors.password = "Password must contains atleast " + vMinSpecialCharacter + " special character";
            //     }
            // }
            this.setState({ errors: errors });
        }
        catch (err) {
            console.log("Error in " + this.displayName + ".handleValidation function", err);
            this.setState({ isSubmited: false })
        }
        return formIsValid;
    }

    handleAccountSubmit = async (e) => {
        e.preventDefault();
        try {
            if (this.handleValidation()){
                this.setState({ isSubmited: true });
                var newObject = {};
                newObject.Name = this.state.customername.trim();
                newObject.Email = this.state.email.trim();
                newObject.Phone1 = this.state.phone;
                newObject.Website = this.state.website;
                newObject.GSTNo = this.state.GSTNo;
                newObject.PANNo = this.state.panNo;
                newObject.ContactPerson = this.state.contactPerson;
                newObject.ShippingAddress1 = this.state.shippingAddressLine1;
                newObject.ShippingAddress2 = this.state.shippingAddressLine2;
                newObject.ShippingCity = this.state.shippingCity;
                newObject.ShippingState = this.state.shippingState;
                newObject.ShippingPostalCode = this.state.shippingZipCode;
                newObject.BillingAddress1 = this.state.billingAddressLine1;
                newObject.BillingAddress2 = this.state.billingAddressLine2;
                newObject.BillingCity = this.state.billingCity;
                newObject.BillingState = this.state.billingState;
                newObject.BillingPostalCode = this.state.billingZipCode;
                newObject.UserName = this.state.userName;
                newObject.Password = this.state.password;
                newObject.FirstName = this.state.firstName;
                newObject.LastName = this.state.lastName;
                newObject.AccountTypeName = this.state.accountTypeName;
                newObject.ModuleTypeName = this.state.moduleTypeName;
                newObject.ModuleTypeName = this.state.moduleTypeName;
                newObject.CreatedBy = this.state.sessionUser.id;
                newObject.CreatedOn = new Date();
                console.log(this.state.selectedRowsData.length)
            if(this.state.selectedRowsData.length !== 0){
               var contractConfigurationRequest = {};
                contractConfigurationRequest = {};
                contractConfigurationRequest.Id = parseInt(0);
                contractConfigurationRequest.CustomerId = parseInt(0);
                contractConfigurationRequest.ContractTypeId = parseInt(this.state.contractTypeId);
                contractConfigurationRequest.ContractStart = formatDate(this.state.startDate, "MM/dd/yyyy hh:mm");
                contractConfigurationRequest.ContractEnd = formatDate(this.state.endDate, "MM/dd/yyyy hh:mm");
                contractConfigurationRequest.ContractStatusId = parseInt(this.state.contractStatusId);
                contractConfigurationRequest.Comments = "";
                contractConfigurationRequest.PaymentTermsId = parseInt(this.state.paymentTermTypeId);
                newObject.ContractConfigurationRequest = {};
                newObject.ContractConfigurationRequest = contractConfigurationRequest;

                var contractServicesItemList = [];
                this.state.selectedRowsData.forEach((item, index) => {
                    var contractServicesItem = {};
                    contractServicesItem.Id = 0;
                    contractServicesItem.ItemName = item.name;
                    contractServicesItem.ServiceItemId = parseInt(item.id);
                    contractServicesItem.BasedOnUnitId = parseInt(item.basedOnUnitId);
                    contractServicesItem.BasedOnUnit = item.basedOnUnit;
                    contractServicesItem.TermTypeId = parseInt(item.termTypeId);
                    contractServicesItem.TermType = item.termType;
                    contractServicesItem.UnitPrice = parseFloat(item.unitPrice);
                    contractServicesItem.SalePrice = item.salePrice == undefined ? parseFloat(0.0) : parseFloat(item.salePrice);
                    contractServicesItem.Limit = item.limit;
                    contractServicesItem.Description = "";
                    contractServicesItem.WefDate = formatDate(new Date(), "MM/dd/yyyy hh:mm");
                    contractServicesItemList.push(contractServicesItem);
                });
            }
                newObject.CustomerContractService = {};
                newObject.CustomerContractService = contractServicesItemList;

                const requestParams = getRequestParams('POST', newObject);
                //console.log(JSON.stringify(requestParams));

                const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'account/Register', requestParams);
                const data = await response.json();
                // this.setState({ accountId: data.payload });
                if (data.isSuccess) {
                    this.setState({ documentUniqueId: data.payload, resultMeassage: data.message })
                    if (this.state.documentSelectedFile.size !== undefined || this.state.documentSelectedFile.length > 0) {
                        {
                            this.setState({ isSubmited: true });
                            this.documentUpload();
                        }
                    }
                    else {
                        Swal.fire({
                            icon: 'success',
                            title: 'Success',
                            text: data.message,
                        }).then((result) => {
                            if (result.isConfirmed) {
                                window.location.href = this.state.sourceUrl;
                                this.closeModal();
                            }
                            this.setState({ isSubmited: false })
                        })
                    }
                }
                else {
                    toast.error(data.message, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        style: { width: "310px" },
                        hideProgressBar: false,
                        newestOnTop: false,
                        closeButton: false
                    });
                    this.setState({
                        isSubmited: false
                    });
                }
            }
        } catch (err) {
            console.log("Error in " + this.displayName + ".handleAccountSubmit function", err);
            this.setState({
                isSubmited: false
            });
        }
    }

    handleCustomerContractSubmit = async (e) => {
        e.preventDefault();
        if (this.handleContractValidation()) {
            this.setState({
                contractConfigurationModal: false,
            });
        }
    }

    handleContractValidation = () => {
        let formIsValid = true;
        try {
            let errors = this.state.errors;
            errors.contractTypeId = 0;
            //errors.contractStatusId = 0;
            errors.paymentTermTypeId = 0;
            if (this.state.contractTypeId === 0) {
                formIsValid = false;
                errors.contractTypeId = "Please select contract type.";
            }
            // if (this.state.contractStatusId === 0) {
            //     formIsValid = false;
            //     errors.contractStatusId = "Please select contract status.";
            // }
            if (this.state.paymentTermTypeId === 0) {
                formIsValid = false;
                errors.paymentTermTypeId = "Please select payment term.";
            }
            this.setState({ errors: errors });
        }
        catch (err) {
            console.log("Error in " + this.displayName + ".handleContractValidation function", err);
            this.setState({ isSubmited: false })
        }
        return formIsValid;
    }

    closeModal = () => {
        this.setState({
            isSubmited: false,
            loadPanelVisible: false,
            customername: "",
            phone: "",
            email: "",
            website: "",
            GSTNo: "",
            panNo: "",
            contactPerson: "",
            billingAddressLine1: "",
            billingAddressLine2: "",
            billingCity: "",
            billingState: "",
            billingZipCode: "",
            shippingAddressLine1: "",
            shippingAddressLine2: "",
            shippingCity: "",
            shippingState: "",
            shippingZipCode: "",
            firstName: "",
            lastName: "",
            userName: "",
            password: "",
            accountTypeName: "",
            file: null,
            fileGuid: "00000000-0000-0000-0000-000000000000",
            accountId: 0,
            documentTypeId: 0,
            contractConfigurationChecked: false,
            errors: {
                customername: "",
                phone: "",
                email: "",
                website: "",
                GSTNo: "",
                panNo: "",
                contactPerson: "",
                billingAddressLine1: "",
                billingCity: "",
                billingState: "",
                billingZipCode: "",
                shippingAddressLine1: "",
                shippingCity: "",
                shippingState: "",
                shippingZipCode: "",
                firstName: "",
                lastName: "",
                userName: "",
                password: "",
            }
        });
        window.location.href = this.state.sourceUrl;
    }

    copyBillingAddress = () => {
        this.setState({
            shippingAddressLine1: this.state.billingAddressLine1,
            shippingAddressLine2: this.state.billingAddressLine2,
            shippingCity: this.state.billingCity,
            shippingState: this.state.billingState,
            shippingZipCode: this.state.billingZipCode
        });
    }

    handleCancel = () => {
        window.location.href = this.state.sourceUrl;
    }

    handleFileInputChange = async (e) => {
        let images = e.target.files;
        var i;
        for (i = 0; i < e.target.files.length; i++) {
            this.state.targetFile.push(images[i]);
        }
        this.setState({
            documentSelectedFile: this.state.targetFile
        })
    }

    resetFileInput = (e) => {
        var data = this.state.documentSelectedFile.filter(item => item.name === e.target.value);
        var _data = this.state.documentSelectedFile.filter(item => item.name !== data[0].name);
        //var data1 = this.state.documentSelectedFile.filter(item => item.name === data[0].name)
        this.setState({
            documentSelectedFile: _data,
            targetFile: _data
        }, () => 500)
        const arrLength = _data.length;
        console.log(arrLength - 1);
        if (_data.length === 0) {
            this.removeAll();
        }
        else {
            document.getElementById("file").value = null
        }
    }

    removeAll = () => {
        document.getElementById("file").value = null;
    }

    documentUpload = async () => {
        for (let i = 0; i < this.state.documentSelectedFile.length; i++) {
            //if (this.state.documentSelectedFile.size > 0) 
            {

                const url = process.env.REACT_APP_API_BASE_URL + 'file/upload';
                const formData = new FormData();
                console.log(this.state.documentSelectedFile);
                formData.append('body', this.state.documentSelectedFile[i]);
                const config = {
                    headers: {
                        'content-type': 'multipart/form-data',
                        'UserId': this.state.sessionUser.id,
                        'Authorization': 'Bearer ' + sessionStorage.getItem('session_token'),
                    },
                };
                this.setState({
                    loadPanelVisible: true
                });
                axios.post(url, formData, config).then((response) => {
                    var resp = response.data;
                    if (resp != null) {
                        this.setState({
                            fileGuid: resp.fileGuid
                        })
                        this.saveDocument(resp)
                    }
                });
                this.setState({
                    loadPanelVisible: false
                });
            }
        }
    }

    saveDocument = async (data) => {

        this.setState({ isSubmited: true });
        if (data !== null) {
            this.setState({
                fileGuid: data.fileGuid
            });
            try {
                var isSuccess = false;
                var resultMessage = '';
                //for (var i = 0; i < this.state.uniqueId.length; i++)
                {
                    var request = {};
                    request.FileGuid = data.fileGuid;
                    request.UserId = Number(this.state.sessionUser.id);
                    request.DocumentType = this.state.documentTypeId;
                    request.Id = this.state.documentUniqueId;
                    const requestVehicleParams = getRequestParams('POST', request);
                    const responseVehicle = await fetch(process.env.REACT_APP_API_BASE_URL + 'file/save-documents', requestVehicleParams);
                    const dataDocument = await responseVehicle.json();
                    isSuccess = dataDocument.isSuccess;
                    resultMessage = dataDocument.message;
                }
                this.setState({
                    loadPanelVisible: false
                });
                this.setState({ isSubmited: false });
                if (isSuccess) {
                    Swal.fire({
                        icon: isSuccess ? 'success' : 'error',
                        title: isSuccess ? 'success' : 'Oops...',
                        text: this.state.resultMeassage,
                    }).then((result) => {
                        if (isSuccess) {
                            window.location.href = this.state.sourceUrl
                            //  this.closeModel();
                            //this.getDocuments();
                        }
                    });
                } else {
                    this.onfailure('Failed to upload image.', resultMessage);
                }
            } catch (e) {
                //
            }
        }
    }

    closeContractConfigurationModal = (e) => {
        this.setState({
            contractConfigurationChecked: false,
            contractConfigurationModal: false,
        }, () => {
            console.log(this.state.contractConfigurationChecked, 'dealersOverallTotal1');
        });

    }

    getServiceMaster = async () => {
        try {
            var request = {};
            const requestParams = getRequestParams("GET", request);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'master/services/0', requestParams);
            const data = await response.json();
            //  
            this.setState({
                servicesource: data.payload,
                loadPanelVisible: false
            });
        } catch (err) {
            console.log("Error in " + this.displayName + ".getServiceMaster function", err);
            this.setState({
                loadPanelVisible: false
            });
        }
    }

    handleContractConfiguration = (e) => {
        
        if (e.value === true) {
            this.setState({
                contractConfigurationChecked: e.value,
                contractConfigurationModal: true
            });
        }
    }

    handleStartDateChanged = (date) => {
        this.setState({
            startDate: date.value
            , endDate: date.value
        });
    }
    handleEndDateChanged = (date) => {
        this.setState({ endDate: date.value });
    }
    handleContractStatusChange = (selectedOption) => {
        this.setState({
            contractStatusId: (selectedOption.selectedItem !== null ? selectedOption.selectedItem.id : 0),
        });
        let errors = this.state.errors;
        if (selectedOption.selectedItem !== null && selectedOption.selectedItem.id > 0) {
            //this.state.errors["contractStatusId"] = "";
            errors.contractStatusId = "";
            this.setState({
                errors: errors
            });
        }
    }
    handleContractTypeChange = (selectedOption) => {
        this.setState({
            contractTypeId: (selectedOption.selectedItem !== null ? selectedOption.selectedItem.id : 0),
        });
        let errors = this.state.errors;
        if (selectedOption.selectedItem !== null && selectedOption.selectedItem.id > 0) {
            //this.state.errors["contractTypeId"] = "";
            errors.contractTypeId = "";
            this.setState({
                errors: errors
            });
        }
    }
    handlePaymentTermTypeChange = (selectedOption) => {
        this.setState({
            paymentTermTypeId: (selectedOption.selectedItem !== null ? selectedOption.selectedItem.id : 0),
        });
        let errors = this.state.errors;
        if (selectedOption.selectedItem !== null && selectedOption.selectedItem.id > 0) {
            //this.state.errors["paymentTermTypeId"] = "";
            errors.paymentTermTypeId = "";
            this.setState({
                errors: errors
            });
        }
    }
    handleServiceChanged = (data) => {
        this.setState({
            selectedItemKeys: data.selectedRowKeys,
            selectedRowsData: data.selectedRowsData,
        });
    }

    render() {
        const renderGridNumberCell = (data) => {
            return <div><small><i className={currencySymbolFormats.currencyFormat}></i></small>&nbsp;&nbsp;{parseFloat(data.value !== undefined ? data.value : 0).toFixed(2)}</div>;
        }

        return (
            <Fragment>
                <div className="section-body">
                    <div className='card bg-filter'>
                        <div className='card-body'>
                            <div className="section-header">
                                <h6 className='m-0'>New {this.state.accountTypeName}</h6>
                                <div className="section-header-buttons">
                                    <button type="submit" className="btn btn-success" onClick={this.handleAccountSubmit} >
                                        {this.state.isSubmited && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                        {!this.state.isSubmited && <span title="Submit">Submit</span>}
                                    </button>&nbsp;&nbsp;
                                    <button type="submit" className="btn btn-outline-secondary" onClick={this.closeModal}>
                                        <span title="Cancel">Cancel</span>
                                    </button>
                                </div>
                            </div>
                           
                        </div>
                    </div>
                    <div className='card'>
                    <div className='card-body'>
                             {/* <div className='sub-title-color font-weight-bold'>Personal Info</div> */}
                             <div className='row'>
                                <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <label htmlFor="">Name <span className="text-danger">*</span></label>
                                        <input type="text" className="form-control form-control-sm" name="customername" value={this.state.customername} placeholder="e.g. John Dave" maxLength="200" title="Name" onChange={this.handleInputChange} />
                                        {this.state.errors["customername"].length > 0 && <span className="error invalid-feedback">{this.state.errors["customername"]}</span>}
                                    </div>
                                </div>

                                <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <label>Phone <span className="text-danger">*</span></label>
                                        <PatternFormat className="form-control form-control-sm" format="### #### ###" name="phone" placeholder="Enter phone" value={this.state.phone}
                                            title="Contact No" maxLength="50" onChange={this.handleInputChange} />
                                        {/* {this.state.errors["phone"].length > 0 && <span className="error invalid-feedback">{this.state.errors["phone"]}</span>} */}
                                    </div>
                                </div>

                                <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <label htmlFor="">Email <span className="text-danger">*</span></label>
                                        <input type="text" className="form-control form-control-sm" name="email" value={this.state.email} placeholder="e.g. user@domain.com" maxLength="50" title="Email" onChange={this.handleInputChange} />
                                        {/* {this.state.errors["email"].length > 0 && <span className="error invalid-feedback">{this.state.errors["email"]}</span>} */}
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <label htmlFor="">Website <span className="text-danger">*</span></label>
                                        <input type="text" className="form-control form-control-sm" name="website" value={this.state.website} placeholder="e.g. www.example.com" maxLength="50" title="Website" onChange={this.handleInputChange} />
                                        {/* {this.state.errors["website"].length > 0 && <span className="error invalid-feedback">{this.state.errors["website"]}</span>} */}
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <label htmlFor="">GST Number <span className="text-danger">*</span></label>
                                        <input type="text" className="form-control form-control-sm" name="GSTNo" value={this.state.GSTNo} placeholder="e.g. 01GSTGS1234F0A1" maxLength="20" title="GST No." onChange={this.handleInputChange} />
                                        {/* {this.state.errors["GSTNo"].length > 0 && <span className="error invalid-feedback">{this.state.errors["GSTNo"]}</span>} */}
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <label htmlFor="">PAN Number <span className="text-danger">*</span></label>
                                        <input type="text" className="form-control form-control-sm" name="panNo" value={this.state.panNo} placeholder="e.g. AAAAA0000A" maxLength="10" title="PAN No." onChange={this.handleInputChange} />
                                        {/* {this.state.errors["panNo"].length > 0 && <span className="error invalid-feedback">{this.state.errors["panNo"]}</span>} */}
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <label htmlFor="">Contact Person <span className="text-danger">*</span></label>
                                        <input type="text" className="form-control form-control-sm" name="contactPerson" value={this.state.contactPerson} placeholder="Enter contact person name" maxLength="50" title="Contact Person" onChange={this.handleInputChange} />
                                        {/* {this.state.errors["contactPerson"].length > 0 && <span className="error invalid-feedback">{this.state.errors["contactPerson"]}</span>} */}
                                    </div>
                                </div>

                            </div>
                            {/* <div className='sub-title-color font-weight-bold mt-3'>User Info</div> */}
                            <div className='row mt-3'>
                                <div className="col-lg-2 col-md-2 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <label htmlFor="">First Name <span className="text-danger">*</span></label>
                                        <input type="text" className="form-control form-control-sm" name="firstName" value={this.state.firstName} placeholder="e.g. John" maxLength="50" title="First Name" onChange={this.handleInputChange} />
                                        {/* {this.state.errors["firstName"].length > 0 && <span className="error invalid-feedback">{this.state.errors["firstName"]}</span>} */}
                                    </div>
                                </div>

                                <div className="col-lg-2 col-md-2 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <label>Last Name <span className="text-danger">*</span></label>
                                        <input type="text" className="form-control form-control-sm" name="lastName" value={this.state.lastName} placeholder="e.g. Dave" maxLength="50" title="Last Name" onChange={this.handleInputChange} />
                                        {/* {this.state.errors["lastName"].length > 0 && <span className="error invalid-feedback">{this.state.errors["lastName"]}</span>} */}
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-2 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <label htmlFor="">Username <span className="text-danger">*</span></label>
                                        <input type="text" className="form-control form-control-sm" name="userName" value={this.state.userName} placeholder="e.g. johndave12 or johndave" maxLength="50" title="Username" onChange={this.handleInputChange} />
                                        {/* {this.state.errors["userName"].length > 0 && <span className="error invalid-feedback">{this.state.errors["userName"]}</span>} */}
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-2 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <label htmlFor="">Password <span className="text-danger">*</span></label>
                                        <input type="password" className="form-control form-control-sm" name="password" value={this.state.password} placeholder="Enter password" maxLength="150" title="Password" autocomplete="new-password" onChange={this.handleInputChange} />
                                        {/* {this.state.errors["password"].length > 0 && <span className="error invalid-feedback">{this.state.errors["password"]}</span>} */}
                                    </div>
                                </div>
                                {/* <div className="col-lg-2 col-md-2 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <label htmlFor="">Re-type Password <span className="text-danger">*</span></label>
                                        <input type="password" className="form-control form-control-sm" name="password" value={this.state.password} placeholder="Re-type password" maxLength="150" title="Re-type Password" onChange={this.handleInputChange} />
                                        {this.state.errors["password"].length > 0 && <span className="error invalid-feedback">{this.state.errors["password"]}</span>}
                                    </div>
                                </div> */}
                            </div>
                            {/* <div className='sub-title-color font-weight-bold mt-3'>Addresses</div> */}
                            <div className='row mt-3'>
                                <div className='col-6'>
                                    <div className='bg-body-tertiary p-3 rounded'>
                                        <div className='font-weight-bold mb-2'>Billing Address</div>
                                        <div className='row'>
                                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                <div className="form-group">
                                                    <label htmlFor="">Address Line 1 <span className="text-danger">*</span></label>
                                                    <input type="text" className="form-control form-control-sm" name="billingAddressLine1" value={this.state.billingAddressLine1} placeholder="Enter address line 1" maxLength="100" title="Address Line 1" onChange={this.handleInputChange} />
                                                    {/* {this.state.errors["billingAddressLine1"].length > 0 && <span className="error invalid-feedback">{this.state.errors["billingAddressLine1"]}</span>} */}
                                                </div>
                                            </div>
                                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                <div className="form-group">
                                                    <label htmlFor="">Address Line 2</label>
                                                    <input type="text" className="form-control form-control-sm" name="billingAddressLine2" value={this.state.billingAddressLine2} placeholder="Enter address line 2" maxLength="100" title="Address Line 2" onChange={this.handleInputChange} />
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                                                <div className="form-group">
                                                    <label htmlFor="">City <span className="text-danger">*</span></label>
                                                    <input type="text" className="form-control form-control-sm" name="billingCity" value={this.state.billingCity} placeholder="Enter city" maxLength="50" title="City" onChange={this.handleInputChange} />
                                                    {/* {this.state.errors["billingCity"].length > 0 && <span className="error invalid-feedback">{this.state.errors["billingCity"]}</span>} */}
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                                                <div className="form-group">
                                                    <label htmlFor="">State <span className="text-danger">*</span></label>
                                                    <input type="text" className="form-control form-control-sm" name="billingState" value={this.state.billingState} placeholder="Enter state" maxLength="50" title="State" onChange={this.handleInputChange} />
                                                    {/* {this.state.errors["billingState"].length > 0 && <span className="error invalid-feedback">{this.state.errors["billingState"]}</span>} */}
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                                                <div className="form-group">
                                                    <label htmlFor="">Postal Code <span className="text-danger">*</span></label>
                                                    <PatternFormat className="form-control form-control-sm" format="######" name="billingZipCode" placeholder="Enter postal code" value={this.state.billingZipCode} title="Postal Code" maxLength="7" onChange={this.handleInputChange} />
                                                    {/* <input type="text" className="form-control form-control-sm" name="billingZipCode" value={this.state.billingZipCode} placeholder="Enter postal code" maxLength="7" title="Postal Code" onChange={this.handleInputChange} /> */}
                                                    {/* {this.state.errors["billingZipCode"].length > 0 && <span className="error invalid-feedback">{this.state.errors["billingZipCode"]}</span>} */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-6'>
                                    <div className='bg-body-tertiary p-3 rounded'>
                                        <div className="d-flex flex-row justify-content-between">
                                            <div className='font-weight-bold mb-2'>Shipping Address</div>
                                            <div><button type="button" className="btn btn-link" onClick={this.copyBillingAddress} title='Copy billing address'><i className="fas fa-copy"></i> Copy billing address</button></div>
                                        </div>
                                        <div className='row'>
                                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                <div className="form-group">
                                                    <label htmlFor="">Address Line 1 <span className="text-danger">*</span></label>
                                                    <input type="text" className="form-control form-control-sm" name="shippingAddressLine1" value={this.state.shippingAddressLine1} placeholder="Enter address line 1" maxLength="100" title="Address Line 1" onChange={this.handleInputChange} />
                                                    {/* {this.state.errors["shippingAddressLine1"].length > 0 && <span className="error invalid-feedback">{this.state.errors["shippingAddressLine1"]}</span>} */}
                                                </div>
                                            </div>
                                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                <div className="form-group">
                                                    <label htmlFor="">Address Line 2</label>
                                                    <input type="text" className="form-control form-control-sm" name="shippingAddressLine2" value={this.state.shippingAddressLine2} placeholder="Enter address line 2" maxLength="100" title="Address Line 2" onChange={this.handleInputChange} />
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                                                <div className="form-group">
                                                    <label htmlFor="">City <span className="text-danger">*</span></label>
                                                    <input type="text" className="form-control form-control-sm" name="shippingCity" value={this.state.shippingCity} placeholder="Enter city" maxLength="50" title="City" onChange={this.handleInputChange} />
                                                    {/* {this.state.errors["shippingCity"].length > 0 && <span className="error invalid-feedback">{this.state.errors["shippingCity"]}</span>} */}
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                                                <div className="form-group">
                                                    <label htmlFor="">State <span className="text-danger">*</span></label>
                                                    <input type="text" className="form-control form-control-sm" name="shippingState" value={this.state.shippingState} placeholder="Enter state" maxLength="50" title="State" onChange={this.handleInputChange} />
                                                    {/* {this.state.errors["shippingState"].length > 0 && <span className="error invalid-feedback">{this.state.errors["shippingState"]}</span>} */}
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                                                <div className="form-group">
                                                    <label htmlFor="">Postal Code <span className="text-danger">*</span></label>
                                                    <PatternFormat className="form-control form-control-sm" format="######" name="shippingZipCode" placeholder="Enter postal code" value={this.state.shippingZipCode} title="Postal Code" maxLength="7" onChange={this.handleInputChange} />
                                                    {/* <input type="text" className="form-control form-control-sm" name="shippingZipCode" value={this.state.shippingZipCode} placeholder="Enter postal code" maxLength="7" title="Postal Code" onChange={this.handleInputChange} /> */}
                                                    {/* {this.state.errors["shippingZipCode"].length > 0 && <span className="error invalid-feedback">{this.state.errors["shippingZipCode"]}</span>} */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row mt-2'>
                                <div className="col-6">
                                    <div className="form-group">
                                        <label htmlFor="">Attach File(s)</label>
                                        <input className="form-control" type="file" id="file" onClick={() => { this.removeAll() }} onChange={(e) => { this.handleFileInputChange(e, false) }} />
                                        {/* <label htmlFor="" className='small text-muted'>You can upload a maximum of 5 files, 5MB each</label> */}
                                        {this.state.documentSelectedFile.map((item, index) => {
                                            return (
                                                <div key={index}>
                                                    <label >{item.name} <button value={item.name} onClick={this.resetFileInput} className="btn btn-outline-secondary fas fa-trash text-danger"></button></label>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>
                            <div className='row mt-2' style={{ display: this.state.accountTypeName == 'Customer' ? 'block' : 'none' }}>
                                <div className="col-12">
                                    <div className="form-group">
                                        <CheckBox text="Set Contract Configuration" defaultValue={this.state.contractConfigurationChecked} onValueChanged={this.handleContractConfiguration} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal ariaHideApp={false}
                    isOpen={this.state.contractConfigurationModal}
                    style={customStyles}
                    className={"react-modal"}
                    onRequestClose={this.closeContractConfigurationModal}
                    shouldCloseOnOverlayClick={false}
                    contentLabel="Example Modal">
                    <form method="post" onSubmit={this.handleCustomerContractSubmit} style={this.state.isSubmited ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                        <div className="modal-header">
                            <h6 className="modal-title">Set Contract Configuration {this.state.customername === "" ? "" : "- " + this.state.customername}</h6>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-lg-3">
                                    <div className="form-group">
                                        <label htmlFor="">Contract Type</label>
                                        <SelectBox
                                            dataSource={this.state.contractType}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select contract type"
                                            name="id"
                                            defaultValue={0}
                                            value={this.state.contractTypeId}
                                            displayExpr='name'
                                            valueExpr='id'
                                            onSelectionChanged={this.handleContractTypeChange}
                                            showClearButton={true}
                                            disabled={false}
                                            //disabled={this.state.id > 0}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["contractTypeId"].length > 0 && <span className="error invalid-feedback">{this.state.errors["contractTypeId"]}</span>}
                                    </div>
                                </div>
                                {
                                    this.state.id > 0 ?
                                        <div className="col-lg-3">
                                            <div className="form-group">
                                                <label htmlFor="">Contract Status</label>
                                                <SelectBox
                                                    dataSource={this.state.contractStatus}
                                                    className="basic-multi-select"
                                                    classNamePrefix="select"
                                                    placeholder="Select contract status"
                                                    name="id"
                                                    defaultValue={0}
                                                    value={this.state.contractStatusId}
                                                    displayExpr='name'
                                                    valueExpr='id'
                                                    onSelectionChanged={this.handleContractStatusChange}
                                                    showClearButton={true}
                                                    disabled={false}
                                                    //disabled={this.state.id > 0}
                                                    searchEnabled={true}
                                                />
                                                {/* {this.state.errors["contractStatusId"].length > 0 && <span className="error invalid-feedback">{this.state.errors["contractStatusId"]}</span>} */}
                                            </div>
                                        </div> : null
                                }
                                <div className="col-lg-2">
                                    <div className="form-group">
                                        <label htmlFor="">Payment Term</label>
                                        <SelectBox
                                            dataSource={this.state.paymentTermType}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select payment term"
                                            name="id"
                                            defaultValue={0}
                                            value={this.state.paymentTermTypeId}
                                            displayExpr='name'
                                            valueExpr='id'
                                            onSelectionChanged={this.handlePaymentTermTypeChange}
                                            showClearButton={true}
                                            disabled={false}
                                            //disabled={this.state.id > 0}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["paymentTermTypeId"].length > 0 && <span className="error invalid-feedback">{this.state.errors["paymentTermTypeId"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-2">
                                    <div className="form-group">
                                        <label>Start Date </label>
                                        <DateBox type="date"
                                            className="start-date-time"
                                            name="startDate"
                                            placeholder="dd/mm/yyyy"
                                            displayFormat="dd/MM/yyyy"
                                            showAnalogClock={true}
                                            useMaskBehavior={true}
                                            defaultValue="dd/MM/yyyy"
                                            value={this.state.startDate}
                                            onValueChanged={this.handleStartDateChanged}
                                            width="100%"
                                            disabled={false}
                                            acceptCustomValue={false}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-2">
                                    <div className="form-group">
                                        <label>End Date </label>
                                        <DateBox type="date"
                                            className="end-date-time"
                                            name="endDate"
                                            placeholder="dd/mm/yyyy"
                                            displayFormat="dd/MM/yyyy"
                                            showAnalogClock={true}
                                            useMaskBehavior={true}
                                            defaultValue={this.state.endDate}
                                            value={this.state.endDate}
                                            min={this.state.endDate}
                                            onValueChanged={this.handleEndDateChanged}
                                            width="100%"
                                            disabled={false}
                                            acceptCustomValue={false}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <DataGrid
                                    ref="grdContractServiceRef"
                                    dataSource={this.state.servicesource}
                                    keyExpr="id"
                                    allowColumnReordering={true}
                                    showBorders={true}
                                    onRowUpdating={this.onRowUpdating}
                                    onRowUpdated={this.onRowUpdated}
                                    onRowInserting={this.onRowInserting}
                                    onRowRemoved={this.onRowRemoved}
                                    onEditorPreparing={this.onEditorPreparing}
                                    selectedRowKeys={this.state.selectedItemKeys}
                                    onSelectionChanged={this.handleServiceChanged}>
                                    <Selection mode="multiple" />
                                    <Paging enabled={false} />
                                    <Editing mode="cell"
                                        allowUpdating={true}
                                        allowDeleting={false}
                                        allowAdding={false}
                                        editorOptions={true} />
                                    <Column dataField="name" caption="Service" allowSearch={true} allowEditing={false} allowFiltering={false} allowSorting={true} width={200} />
                                    <Column dataField="basedOnUnit" caption="Based On" allowSearch={true} allowEditing={false} allowFiltering={false} allowSorting={true} width={120} />
                                    <Column dataField="termType" caption="Term Type" allowSearch={true} allowEditing={false} allowFiltering={false} allowSorting={true} width={120} />
                                    <Column dataField="unitPrice" caption="Standard Unit Price" allowSearch={true} allowEditing={false} allowFiltering={false} allowSorting={true} width={180}
                                        dataType="number" cellRender={renderGridNumberCell} />
                                    <Column dataField="salePrice" caption="Sale Price" dataType="decimal" format={"#,##0.##"} alignment="right"
                                        cellRender={renderGridNumberCell}>
                                        <RequiredRule />
                                        <PatternRule message={'Sale Price should be decimal'} pattern={floatValidation} />
                                    </Column>
                                </DataGrid>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="submit" className="btn btn-success">
                                {this.state.isSubmited && <span title="In-process" onClick={this.handleCustomerContractSubmit}><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                {!this.state.isSubmited && <span title="Submit">Submit</span>}
                            </button>
                            <button type="button" className="btn btn-outline-secondary" onClick={this.closeContractConfigurationModal} title="Cancel">Cancel</button>
                        </div>
                    </form>
                </Modal>
            </Fragment>
        )
    };
}