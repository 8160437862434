import React, { Fragment, Component } from 'react';
import DataGrid, {
    Column,
    Export, Grouping,
    GroupPanel,
    HeaderFilter, Pager,
    Paging,
    SearchPanel,
    SortByGroupSummaryInfo
} from 'devextreme-react/data-grid'; //npm i devextreme-react
import Modal from 'react-modal'; //npm install --save react-modal;
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import { getRequestParams } from '../../helpers/globalfunctions.js';
import { alphanumericValidation, alphanumericSpecialcaseValidation } from '../../helpers/globalvalidations.js';
import { LoadPanel } from 'devextreme-react/load-panel';
import Swal from 'sweetalert2'; //npm i sweetalert2 --save
import { toast } from 'react-toastify'; //npm i react-toastify --save
import { SelectBox } from 'devextreme-react/select-box';
import { jsPDF } from 'jspdf';
import { exportDataGrid } from 'devextreme/pdf_exporter';
const exportFormats = ['pdf', 'xlsx'];
const position = { of: '#historydiv' };

const customStyles = {
    content: {
        width: '25%',
    }
}

export class CategoryComponent extends Component {
    static displayName = CategoryComponent.name;

    constructor(props) {
        super(props);
        //  this.inputRef = React.createRef();
        this.state = {

            isSubmited: false,
            loadPanelVisible: false,
            categoryModal: false,
            categorySource: null,
            singelData: null,
            hsnList: null,
            id: 0,
            categoryName: "",
            hsnCode: "",
            hsnName: "",
            hsnId: 0,
            categoryDescription: "",
            sessionUser: JSON.parse(sessionStorage.getItem("sessionUser")),
            filterHsnId: 0,
            errors: {
                categoryName: "",
                hsnCode: "",
                hsnName: "",
                categoryDescription: "",
                hsnId: 0,
            }
        }
        this.handleCategorySubmit = this.handleCategorySubmit.bind(this);
    }

    async componentDidMount() {
        await this.getCategoryMaster();
        await this.getHsnList();
    }
    componentWillUnmount() {
        // fix Warning: Can't perform a React state update on an unmounted component
        this.setState = (state, callback) => {
            return;
        };
    }


    getHsnList = async () => {
        try {
            var request = {};
            const requestParams = getRequestParams("GET", request);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'hsnsacCode/hscacccodes/0', requestParams);
            let data = await response.json();
            if (data.payload != null) {
                data = JSON.parse(JSON.stringify(data.payload));
                let hsnList = data.filter(item => item.type == 'HSN');
                this.setState({
                    hsnList: hsnList
                });
            }
            else {
                this.setState({
                    hsnList: null
                });
            }


        } catch (err) {
            console.log("Error in " + this.displayName + ".getHsnList function", err);
        }
    }

    getCategoryMaster = async () => {
        this.setState({
            loadPanelVisible: true
        });
        try {
            var request = {};
            const requestParams = getRequestParams("GET", request);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'itemCategory/category/0/' + this.state.filterHsnId, requestParams);
            const data = await response.json();
            this.setState({
                categorySource: data.payload == null ? null : data.payload,
                loadPanelVisible: false
            });
        } catch (err) {
            console.log("Error in " + this.displayName + ".getCategoryMaster function", err);
            this.setState({
                loadPanelVisible: false
            });
        }
    }

    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift({
            location: 'after',
            widget: 'dxButton',
            options: {
                icon: 'refresh',
                onClick: this.getCategoryMaster.bind(this)
            }
        });
    }

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        let errors = this.state.errors;

        //const alphanumericValidation = /^[A-Za-z0-9]*$/;
        switch (name) {
            case 'categoryName':
                if (alphanumericValidation.test(event.target.value))
                    this.setState({
                        categoryName: event.target.value
                    });
                break;
            case 'hsnCode':
                if (alphanumericValidation.test(event.target.value))
                    this.setState({ hsnCode: event.target.value });
                break;
            case 'categoryDescription':
                if (alphanumericSpecialcaseValidation.test(event.target.value))
                    this.setState({ categoryDescription: event.target.value });
                break;
            default:
                this.setState({ [name]: value });
                break;
        }

        errors[name] = "";
        this.setState({
            errors: errors
        });

        //this.state.errors[name] = "";
        // this.setState({
        //     errors: {
        //         [name]: "",
        //     }
        // })
    }

    handleHsnChange = (selectedOption) => {
        this.setState({
            hsnId: (selectedOption.selectedItem !== null ? selectedOption.selectedItem.id : 0),
            hsnCode: (selectedOption.selectedItem !== null ? selectedOption.selectedItem.code : "")
        });
    }
    handleFilterHsnChange = (selectedOption) => {
        this.setState({
            filterHsnId: (selectedOption.selectedItem !== null ? selectedOption.selectedItem.id : 0),
        });
    }

    handleCategoryModal = async (e) => {
        this.setState({
            categoryModal: true,
            singelData: null
        });
    }

    closeCategoryModal = () => {
        this.setState({
            isSubmited: false,
            loadPanelVisible: false,
            categoryModal: false,
            singelData: null,
            id: 0,
            categoryName: "",
            hsnCode: "",
            hsnId: 0,
            categoryDescription: "",
            errors: {
                categoryName: "",
                hsnCode: "",
                categoryDescription: "",
                hsnId: 0,
                hsnType: "",
            }
        });
    }

    handleValidation = () => {
        let formIsValid = true;
        try {
            let errors = this.state.errors;
            errors.categoryName = "";
            errors.hsnCode = "";
            errors.hsnId = "";
            errors.hsnName = "";
            errors.hsnType = "";

            if (this.state.categoryName.trim() === "") {
                formIsValid = false;
                errors.categoryName = "Please enter category name.";
            }
            if (this.state.hsnId === 0) {
                formIsValid = false;
                errors.hsnId = "Please select hsn code.";
            }
            // if (this.state.hsnCode.trim() === "") {
            //     formIsValid = false;
            //     errors.hsnCode = "Please enter hsn code.";
            // }
            if (this.state.categoryDescription.trim() === "") {
                formIsValid = false;
                errors.categoryDescription = "Please enter description.";
            }
            this.setState({ errors: errors });
        }
        catch (err) {
            console.log("Error in " + this.displayName + ".handleValidation function", err);
            this.setState({ isSubmited: false })
        }
        return formIsValid;
    }

    handleSearchhsncode = async () => {
        await this.getCategoryMaster();
    }

    handleClearSearch = () => {
        this.setState({
            hsnId: 0,
            filterHsnId: 0
        });
        setTimeout(() => this.getCategoryMaster(), 100);
    }

    handleCategorySubmit = async (e) => {
        e.preventDefault();
        try {
            if (this.handleValidation()) {
                this.setState({ isSubmited: true });
                var newObject = {};
                newObject.id = this.state.id;
                newObject.name = this.state.categoryName.trim();
                newObject.hSNId = this.state.hsnId;
                newObject.hsnCode = this.state.hsnCode.trim();
                newObject.description = this.state.categoryDescription.trim();
                newObject.CreatedBy = this.state.sessionUser.id;
                newObject.CreatedOn = new Date();
                newObject.UpdatedBy = this.state.sessionUser.id;
                newObject.UpdatedOn = new Date();

                const requestParams = getRequestParams('POST', newObject);
                const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'itemCategory/' + this.state.id + '/category', requestParams);
                const data = await response.json();
                if (data.isSuccess) {
                    Swal.fire({
                        icon: 'success',
                        title: 'Success',
                        text: data.message,
                    }).then((result) => {
                        if (result.isConfirmed) {
                            this.getCategoryMaster();
                            this.closeCategoryModal();
                        }
                        this.setState({ isSubmited: false })
                    })
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: data.message,
                    }).then((result) => {
                        if (result.isConfirmed) {
                        }
                        this.setState({ isSubmited: false })
                    });
                    this.setState({ isSubmited: false })
                }
            }
        }
        catch (err) {
            console.log("Error in " + this.displayName + ".handleCategorySubmit function", err);
            this.setState({ isSubmited: false })
        }
    }

    editCategoryModelModal = (e, data) => {
        e.preventDefault();
        if (data != null) {
            this.setState({
                categoryModal: true,
                singelData: data,
                id: data.id,
                categoryName: data.name,
                hsnCode: data.hsnCode,
                hsnId: data.hsnId,
                categoryDescription: data.description,
            });
        }
    }

    onExporting = (e) => {
        const doc = new jsPDF();
        if (e.format === "pdf") {
            exportDataGrid({
                jsPDFDocument: doc,
                component: e.component,
                columnWidths: 50
                // indent: 10,
            }).then(() => {
                doc.save('ItemCategory.pdf');
            });
        }
    }

    render() {
        const renderGridCell = (data) => {
            return <div>
                <a href="/#" className="fas fa-pen" title="Edit" onClick={(e) => { this.editCategoryModelModal(e, data.data) }} ></a>&nbsp;&nbsp;
            </div>;
        }

        return (
            <Fragment>
                <div className="section-body">
                    <div className='card bg-filter'>
                        <div className='card-body'>
                            <div className="section-header">
                                <div className='row'>
                                    <div className="col-lg-2">
                                        <div className="form-group">
                                            <label>HSN Code</label>
                                            <SelectBox
                                                dataSource={this.state.hsnList}
                                                className="basic-multi-select"
                                                classNamePrefix="select"
                                                placeholder="Select hsn code"
                                                name="id"
                                                defaultValue={0}
                                                value={this.state.filterHsnId}
                                                displayExpr='code'
                                                valueExpr='id'
                                                onSelectionChanged={this.handleFilterHsnChange}
                                                showClearButton={true}
                                                disabled={false}
                                                searchEnabled={true}
                                            />
                                        </div>


                                    </div>
                                    <div className='col-lg-6 pl-0'>
                                        <br />
                                        <button type="submit" className="btn btn-success" onClick={this.handleSearchhsncode}>
                                            Search
                                        </button>&nbsp;&nbsp;
                                        <button type="submit" className="btn btn-outline-secondary" onClick={this.handleClearSearch}>
                                            Clear
                                        </button>
                                    </div>
                                </div>
                                <div className="section-header-buttons">
                                    <label htmlFor="">&nbsp;</label><br />
                                    <button type="submit" className="btn btn-success" onClick={this.handleCategoryModal}>
                                        New
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='card'>
                        <div className='card-body'>
                            <DataGrid
                                dataSource={this.state.categorySource}
                                keyExpr="id"
                                id='gridHsnCodeMasterList'
                                selection={{ mode: 'single' }}
                                columnsAutoWidth={false}
                                showColumnLines={true}
                                showRowLines={true}
                                showBorders={true}
                                rowAlternationEnabled={false}
                                allowColumnResizing={true}
                                onExporting={this.onExporting}
                                columnResizingMode='widget'
                                onToolbarPreparing={this.onToolbarPreparing}>
                                <Column dataField="id" width={35} caption="" allowFiltering={false} allowSorting={false} cellRender={renderGridCell} />
                                <Column dataField="name" caption="Name" allowSearch={true} allowFiltering={false} allowSorting={true} width={180} />
                                <Column dataField="hsnCode" caption="HSN Code" allowSearch={true} allowFiltering={false} allowSorting={true} width={100} />
                                <Column dataField="description" caption="Description" allowSearch={true} allowFiltering={false} allowSorting={true} width={300} />
                                <SortByGroupSummaryInfo summaryItem="count" />
                                <GroupPanel visible={false} />
                                {/* <Template name="toolbarTemplate" /> */}
                                <HeaderFilter visible={true} />
                                <SearchPanel visible={true} highlightCaseSensitive={true} width={240} placeholder="Search..." />
                                <Grouping autoExpandAll={true} />
                                <Paging defaultPageSize={20} />
                                <Pager showPageSizeSelector={true} allowedPageSizes={[10, 20, 50]} showInfo={true} visible={true} />
                                <Export enabled={true} allowExportSelectedData={false} fileName="Category" />
                            </DataGrid>
                        </div>
                    </div>
                </div>

                <Modal ariaHideApp={false}
                    isOpen={this.state.categoryModal}
                    style={customStyles}
                    className={"react-modal"}
                    //onAfterOpen={this.handleAssignDataToState}
                    onRequestClose={this.closeCategoryModal}
                    shouldCloseOnOverlayClick={false}
                    contentLabel="Example Modal">
                    <form method="post" onSubmit={this.handleCategorySubmit} style={this.state.isSubmited ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                        <div className="modal-header">
                            <h6 className="modal-title">{this.state.id > 0 ? "Update Category" : "New Category"}</h6>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="form-group">
                                        <label>Name <span className="text-danger">*</span></label>
                                        <input type="text" className="form-control form-control-sm" name="categoryName" placeholder="Enter category name" value={this.state.categoryName}
                                            disabled={this.state.id > 0} title="Category Name" maxLength="50" onChange={this.handleInputChange} />
                                        {this.state.errors["categoryName"].length > 0 && <span className="error invalid-feedback">{this.state.errors["categoryName"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="form-group">
                                        <label>HSN Code <span className="text-danger">*</span></label>
                                        <SelectBox
                                            dataSource={this.state.hsnList}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select hsn code"
                                            name="id"
                                            defaultValue={0}
                                            value={this.state.hsnId}
                                            displayExpr='code'
                                            valueExpr='id'
                                            onSelectionChanged={this.handleHsnChange}
                                            showClearButton={true}
                                            disabled={false}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["hsnId"].length > 0 && <span className="error invalid-feedback">{this.state.errors["hsnId"]}</span>}
                                    </div>
                                </div>
                                {/* <div className="col-lg-12">
                                    <div className="form-group">
                                        <label htmlFor="">HSN Code <span className="text-danger">*</span></label>
                                        <input type="text" className="form-control form-control-sm" name="hsnCode" placeholder="HSN Code" maxLength="20" title="HSN Code" value={this.state.hsnCode} onChange={this.handleInputChange} disabled={true} />
                                        {this.state.errors["hsnCode"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["hsnCode"]}</span>}
                                    </div>
                                </div> */}
                                <div className="col-lg-12">
                                    <div className="form-group">
                                        <label htmlFor="">Description <span className="text-danger">*</span></label>
                                        <textarea type="text" className="form-control form-control-sm" name="categoryDescription" autoComplete="categoryDescription" placeholder="Enter description" maxLength="100" title="Description" value={this.state.categoryDescription} onChange={this.handleInputChange} />
                                        {this.state.errors["categoryDescription"].length > 0 && <span className="error invalid-feedback">{this.state.errors["categoryDescription"]}</span>}
                                    </div>
                                </div>
                            </div></div>
                        <div className="modal-footer">
                            <button type="submit" className="btn btn-success" onClick={this.handleCategorySubmit} >
                                {this.state.isSubmited && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                {!this.state.isSubmited && <span title="Submit">Submit</span>}
                            </button>
                            <button type="button" className="btn btn-outline-secondary" onClick={this.closeCategoryModal} title="Cancel">Cancel</button>
                        </div>
                    </form>
                </Modal>

                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={position}
                    visible={this.state.loadPanelVisible}
                    showIndicator={true}
                    shading={true}
                    showPane={true}
                    hideOnOutsideClick={false}
                />
            </Fragment>
        )
    };
}