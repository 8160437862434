import React, { Fragment, Component } from "react";
import DataGrid, {
  Column,
  Export,
  Grouping,
  GroupPanel,
  HeaderFilter,
  Pager,
  Paging,
  SearchPanel,
  SortByGroupSummaryInfo,
} from "devextreme-react/data-grid"; //npm i devextreme-react
import Modal from "react-modal"; //npm install --save react-modal;
import { getRequestParams } from "../../helpers/globalfunctions.js";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";

const customStyles = {
  content: {
    width: "50%",
  },
};

export class CustomerMappingComponent extends Component {
  static displayName = CustomerMappingComponent.name;

  constructor(props) {
    super(props);
    this.state = {};
  }

  async componentDidMount() {
    await this.getCustomerMapping();
  }

  getCustomerMapping = async () => {
    debugger;
    this.setState({
      loadPanelVisible: true,
    });
    try {
      var request = {};
      const requestParams = getRequestParams("GET", request);
      const response = await fetch(
        process.env.REACT_APP_API_BASE_URL + "sourceMap/GetSourceMapping",
        requestParams
      );
      const data = await response.json();
      this.setState({
        CustomerMapping: data.payload,
        loadPanelVisible: false,
      });
    } catch (err) {
      console.log(
        "Error in " + this.displayName + ".getcustomerMapping function",
        err
      );
      this.setState({
        loadPanelVisible: false,
      });
    }
  };

  render() {
    return (
      <Fragment>
        <div className="section-body">
          <div className="card bg-filter">
            <div className="card-body">
              <div className="section-header">
                <h6 class="m-0">Customer Mapping</h6>
                <div className="section-header-buttons">
                  {/* <button
                    type="submit"
                    className="btn btn-success"
                    //onClick={this.openWarehouseModel}
                  >
                    New
                  </button> */}
                </div>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-body">
              <DataGrid
                dataSource={this.state.CustomerMapping}
                //keyExpr="id"
                selection={{ mode: "single" }}
                columnsAutoWidth={false}
                showColumnLines={true}
                showRowLines={true}
                showBorders={true}
                rowAlternationEnabled={false}
                allowColumnResizing={true}
                columnResizingMode="widget"
                onToolbarPreparing={this.onToolbarPreparing}
              >
                {/* <Column
                  dataField="id"
                  width={35}
                  caption=""
                  allowFiltering={false}
                  allowSorting={false}
                  //cellRender={renderGridCell}
                /> */}
                {/* <Column dataField="account" caption="Account" allowSearch={true} allowFiltering={false} allowSorting={true} width={300} />                                     */}
                {/* <Column
                  dataField="accountCode"
                  caption="Account Code"
                  allowSearch={true}
                  allowFiltering={false}
                  allowSorting={true}
                  width={100}
                /> */}
                <Column
                  dataField="dataSourceName"
                  caption="Source Name"
                  allowSearch={true}
                  allowFiltering={false}
                  allowSorting={true}
                  width={150}
                />
                <Column
                  dataField="databaseName"
                  caption="Source Db"
                  allowSearch={true}
                  allowFiltering={false}
                  allowSorting={true}
                  width={150}
                />
                <Column
                  dataField="refernceBillingId"
                  caption="Billing Id"
                  allowSearch={true}
                  allowFiltering={false}
                  allowSorting={true}
                  width={100}
                />
                <Column
                  dataField="refernceBillingName"
                  caption="Billing Name"
                  allowSearch={true}
                  allowFiltering={false}
                  allowSorting={true}
                  width={350}
                />
                <Column
                  dataField="contractDetails"
                  caption="Contract Details"
                  allowSearch={true}
                  allowFiltering={false}
                  allowSorting={true}
                  width={250}
                />
                <Column
                  dataField="accountId"
                  caption="Source Account Id"
                  allowSearch={true}
                  allowFiltering={false}
                  allowSorting={true}
                  width={150}
                />
                <Column
                  dataField="accountName"
                  caption="Source Account Name"
                  allowSearch={true}
                  allowFiltering={false}
                  allowSorting={true}
                  width={200}
                />
                {/* <Column
                  dataField="approvedBy"
                  caption="Account By"
                  allowSearch={true}
                  allowFiltering={false}
                  allowSorting={true}
                  width={100}
                /> */}
                {/* <Column
                  dataField="approvedDateTime"
                  caption="Approved Date Time"
                  allowSearch={true}
                  allowFiltering={false}
                  allowSorting={true}
                  width={100}
                /> */}
                {/* <Column
                  dataField="contractServing"
                  caption="Contract Serving"
                  allowSearch={true}
                  allowFiltering={false}
                  allowSorting={true}
                  width={100}
                /> */}
                {/* <Column
                  dataField="contract_Device_Count"
                  caption="Contract Device Count"
                  allowSearch={true}
                  allowFiltering={false}
                  allowSorting={true}
                  width={150}
                /> */}
                {/* <Column
                  dataField="contract_Used_Count"
                  caption="Contract Used Count"
                  allowSearch={true}
                  allowFiltering={false}
                  allowSorting={true}
                  width={150}
                /> */}
                {/* <Column
                  dataField="createdBy"
                  caption="Created By"
                  allowSearch={true}
                  allowFiltering={false}
                  allowSorting={true}
                  width={150}
                /> */}
                {/* <Column
                  dataField="createdDatetime"
                  caption="Contract Date Time"
                  allowSearch={true}
                  allowFiltering={false}
                  allowSorting={true}
                  width={150}
                /> */}
                <SortByGroupSummaryInfo summaryItem="count" />
                <GroupPanel visible={false} />
                {/* <Template name="toolbarTemplate" /> */}
                <HeaderFilter visible={true} />
                <SearchPanel
                  visible={true}
                  highlightCaseSensitive={true}
                  width={240}
                  placeholder="Search..."
                />
                <Grouping autoExpandAll={true} />
                <Paging defaultPageSize={20} />
                <Pager
                  showPageSizeSelector={true}
                  allowedPageSizes={[10, 20, 50]}
                  showInfo={true}
                  visible={true}
                />
                <Export
                  enabled={true}
                  allowExportSelectedData={false}
                  fileName="WareHouse"
                />
              </DataGrid>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
