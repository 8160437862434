import React, { Fragment, Component } from 'react';
import DataGrid, {
    Column,
    Export, Grouping,
    GroupPanel,
    HeaderFilter, Pager,
    Paging,
    SearchPanel,
    SortByGroupSummaryInfo
} from 'devextreme-react/data-grid'; //npm i devextreme-react
import Modal from 'react-modal'; //npm install --save react-modal;
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import { formatDate, getRequestParams } from '../../helpers/globalfunctions.js';
import { alphanumericValidation } from '../../helpers/globalvalidations.js';
import { exportFormats } from '../../helpers/fixcodes.js';
import TextArea from 'devextreme-react/text-area';
import { LoadPanel } from 'devextreme-react/load-panel';
import Swal from 'sweetalert2'; //npm i sweetalert2 --save
import { toast } from 'react-toastify'; //npm i react-toastify --save
import { SelectBox } from 'devextreme-react/select-box';
import DateBox from 'devextreme-react/date-box';
import { jsPDF } from 'jspdf';
import { exportDataGrid } from 'devextreme/pdf_exporter';

const position = { of: '#historydiv' };
const customStyles = {
    content: {
        width: '40%',
    }
}

export class QCComponent extends Component {
    static displayName = QCComponent.name;

    constructor(props) {
        super(props);
        this.state = {
            isSubmited: false,
            loadPanelVisible: false,
            sessionUser: JSON.parse(sessionStorage.getItem("sessionUser")),
            id: 0,
            qcSource: [],
            itemList: [],
            qcTypeList: [],
            qcStatusList: [],
            id: 0,
            itemId: 0,
            serialNumber: "",
            qcTypeId: 0,
            qcDate: new Date(),
            problemFound: "",
            receivedDate: new Date(),
            newSerialNumber: "",
            qcStatusId: 0,
            filterItemId:0,
            filterSerialNo:"",
            filterNewSerialNo:"",
            filterQcTypeId:0,
            filterQcStatusId: 0,
            filterQCDate:"",
            maxReceivedDate: new Date(),
            serialNoList :[],
            stockItemId :this.props.location.state === undefined ? 0 : this.props.location.state.stockId,
            errors: {
                itemId: 0,
                serialNumber: "",
                qcTypeId: 0,
                qcDate: "",
                problemFound: "",
                receivedDate: new Date(),
                newSerialNumber: "",
                qcStatusId: 0,
            }
        }
    }

    async componentDidMount() {
        await this.getQc();
        await this.getStockItemMaster();
        await this.getQCTypeMaster();
        await this.getQCStatusMaster();
    }

    getQc = async () => {
        this.setState({
            loadPanelVisible: true
        });
        try {
            debugger;
            var request = {};    
            request.ItemId = this.state.filterItemId;
            request.NewSerialNo = this.state.filterNewSerialNo;
            request.SerialNo = this.state.filterSerialNo;
            request.QCTypeId = this.state.filterQcTypeId;            
            request.QCStatusId = this.state.filterQcStatusId;
            request.QCDate = this.state.filterQCDate !== "" ? formatDate(this.state.filterQCDate, "MM/dd/yyyy hh:mm") : "";
            request.StockItemId = this.state.stockItemId;
            const requestParams = getRequestParams("POST", request);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'qc/qc/' + parseInt(this.state.id) + '/' + this.state.filterItemId, requestParams);
            const data = await response.json();
            this.setState({
                qcSource: data.payload,
                loadPanelVisible: false
            });
        } catch (err) {
            console.log("Error in " + this.displayName + ".getQc function", err);
            this.setState({
                loadPanelVisible: false
            });
        }
    }

    onExporting = (e) => {
        const doc = new jsPDF();
        if (e.format === "pdf") {
            exportDataGrid({
                jsPDFDocument: doc,
                component: e.component,
                columnWidths: 50,
               // indent: 10,
            }).then(() => {
                doc.save(e.fileName);
            });            
        }
    }

    handleSearchQC = async () => {
        await this.getQc();
    }

    handleClearSearch = () => {
        this.setState({
            id: 0,
            id: 0,
            itemId: 0,
            serialNumber: "",
            qcTypeId: 0,
            qcDate: new Date(),
            problemFound: "",
            receivedDate: new Date(),
            newSerialNumber: "",
            qcStatusId: 0,
            filterItemId:0,
            filterSerialNo:"",
            filterNewSerialNo:"",
            filterQcTypeId:0,
            filterQCDate:"",
            filterQcStatusId:0,
            stockItemId:0,
            errors: {
                itemId: 0,
                serialNumber: "",
                qcTypeId: 0,
                qcDate: "",
                problemFound: "",
                receivedDate: new Date(),
                newSerialNumber: "",
                qcStatusId: 0,
            }
        });
        setTimeout(() => this.getQc(), 500);
    }

    getStockItemMaster = async () => {
        try {
            var request = {};
            const requestParams = getRequestParams("GET", request);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'itemstock/getStockItem', requestParams);
            const data = await response.json();
            
            this.setState({
                itemList: data.payload != null ? data.payload : []
            });
        } catch (err) {
            console.log("Error in " + this.displayName + ".getItemMaster function", err);
        }
    }

    getQCTypeMaster = async () => {
        try {
            var request = {};
            const requestParams = getRequestParams("GET", request);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'qcType/Get', requestParams);
            const data = await response.json();
            this.setState({
                qcTypeList: data.payload != null ? data.payload : []
            });
        } catch (err) {
            console.log("Error in " + this.displayName + ".getQCTypeMaster function", err);
        }
    }

    getQCStatusMaster = async () => {
        try {
            var request = {};
            const requestParams = getRequestParams("GET", request);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'qcStatus/Get', requestParams);
            const data = await response.json();
            this.setState({
                qcStatusList: data.payload != null ? data.payload : []
            });
        } catch (err) {
            console.log("Error in " + this.displayName + ".getQCStatusMaster function", err);
        }
    }
    getSerialNoList = async (id) => {
        try {
            const requestParams = getRequestParams("GET", null);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'itemstock/getSerialNumberByItemId/' +id , requestParams);
            const data = await response.json();
            this.setState({
                serialNoList: data.payload != null ? data.payload : []
            });
        } catch (err) {
            console.log("Error in " + this.displayName + ".getSerialNoList function", err);
        }
    }
    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        switch (name) {
            case 'serialNumber':
                if (alphanumericValidation.test(event.target.value))
                    this.setState({ serialNumber: event.target.value });
                break;
            case 'filterSerialNo':
                if (alphanumericValidation.test(event.target.value))
                    this.setState({ filterSerialNo: event.target.value });
                break;
            case 'filterNewSerialNo':
                if (alphanumericValidation.test(event.target.value))
                    this.setState({ filterNewSerialNo: event.target.value });
                break;
            default:
                this.setState({ [name]: value });
                break;
        }
    }

    handleTextAreaValueChanged = (event) => {
       
        this.setState({
            problemFound: event.value,
        });
    }

    handlequalityCheckModal = async (e) => {
        this.setState({
            qualityCheckModal: true,
            singelData: null
        });
    }

    handleItemChange = (selectedOption) => {
        
      if(selectedOption.selectedItem !== null){
        this.setState({
            itemId: (selectedOption.selectedItem !== null ? selectedOption.selectedItem.id : 0),
         });
        this.getSerialNoList(selectedOption.selectedItem.id);
      }
      else{
        this.setState({itemId :0})
      }
       
        
    }
    handleSerialNoChange = (selectedOption) => {
     this.setState({
        serialNumber: (selectedOption.selectedItem !== null ? selectedOption.selectedItem.name : ""),
        });
       
    }
    handleFilterItemChange = (selectedOption) => {
        this.setState({
            filterItemId: (selectedOption.selectedItem !== null ? selectedOption.selectedItem.id : 0)
        });
    }

    handleQCTypeChange = (selectedOption) => {
        this.setState({
            qcTypeId: (selectedOption.selectedItem !== null ? selectedOption.selectedItem.id : 0)
        });
    }

    handleFilerQCTypeChange = (selectedOption) => {
        this.setState({
            filterQcTypeId: (selectedOption.selectedItem !== null ? selectedOption.selectedItem.id : 0)
        });
    }

    handleQCStatusChange = (selectedOption) => {
        this.setState({
            qcStatusId: (selectedOption.selectedItem !== null ? selectedOption.selectedItem.id : 0)
        });
    }

    handleFilterQCStatusChange = (selectedOption) => {
        this.setState({
            filterQcStatusId: (selectedOption.selectedItem !== null ? selectedOption.selectedItem.id : 0)
        });
    }

    handleqcDateDateChanged = (date) => {
        this.setState({ qcDate: date.value });
    }

    handleReceivedDateDateChanged = (date) => {
        this.setState({ receivedDate: date.value });
    }

    handleFilterQCDateChanged = (date) => {
        this.setState({ filterQCDate: date.value });
    }

    handleValidation = () => {
         let formIsValid = true;
        try {
            let errors = this.state.errors;
            errors.itemId = 0;
            errors.serialNumber = "";
            errors.qcTypeId = 0;
            errors.qcDate = "";;

            if (this.state.itemId === 0) {
                formIsValid = false;
                errors.itemId = "Please select item.";
            }
            if (this.state.serialNumber.trim() === '') {
                formIsValid = false;
                errors.serialNumber = "Please enter serial number.";
            }
            if (this.state.qcTypeId === 0) {
                formIsValid = false;
                errors.qcTypeId = "Please select quality type.";
            }
            if (this.state.qcDate === null) {
                formIsValid = false;
                errors.qcDate = "Please enter QC date.";
            }
            if(this.state.id > 0){
                if (this.state.qcStatusId === 0) {
                    formIsValid = false;
                    errors.qcStatusId = "Please select quality status.";
                } 
                if(this.state.qcStatusId == 2){
                    if (this.state.newSerialNumber === "") {
                        formIsValid = false;
                        errors.newSerialNumber = "Please enter new serial number.";
                    }
                }
              
                if (this.state.receivedDate === null) {
                    formIsValid = false;
                    errors.receivedDate = "Please enter received date.";
                }
            }     
            this.setState({ errors: errors });
        } catch (err) {
            console.log("Error in " + this.displayName + ".isFormhandleValidationValid function", err);
            formIsValid = false;
        }
        return formIsValid;
    }

    handleQualityCheckSubmit = async (e) => {
        e.preventDefault();
        debugger;
        try {
            if (this.handleValidation()) {
                this.setState({ isSubmited: true });            
                var newObject = {};
                newObject.InvoiceNo = "";
                newObject.ItemId = parseInt(this.state.itemId);
                newObject.SerialNo = this.state.serialNumber;
                newObject.QCDate = formatDate(this.state.qcDate, "MM/dd/yyyy hh:mm");
                newObject.QCTypeId = parseInt(this.state.qcTypeId);
                newObject.ProblemFound = this.state.problemFound.trim();
                newObject.QCStatusId = parseInt(this.state.qcStatusId);
                newObject.NewSerialNo = this.state.newSerialNumber;
                newObject.ReceivedDate = formatDate(this.state.receivedDate, "MM/dd/yyyy hh:mm");
                newObject.Source = "Web";
                newObject.CreatedBy = parseInt(this.state.sessionUser.id);
                newObject.CreatedOn = formatDate(new Date(), "MM/dd/yyyy hh:mm");
                newObject.UpdatedBy = parseInt(this.state.sessionUser.id);
                newObject.UpdatedOn = formatDate(new Date(), "MM/dd/yyyy hh:mm");
debugger;
                const requestParams = getRequestParams('POST', newObject);
                const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'qc/' + this.state.id + '/qc', requestParams);
                const data = await response.json();                

                if (data.isSuccess) {
                    Swal.fire({
                        icon: 'success',
                        title: 'Success',
                        text: data.message,
                        //text: 'QC updated successfully',
                    }).then((result) => {
                        if (result.isConfirmed) {
                            this.closeQualityCheckModal();
                            this.getQc();
                        }
                        this.setState({ isSubmited: false })
                    })
                }
                else {
                    toast.error(data.message, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        style: { width: "310px" },
                        hideProgressBar: false,
                        newestOnTop: false,
                        closeButton: false
                    });
                    this.setState({ isSubmited: false })
                }
            }
        } catch (err) {
            console.log("Error in " + this.displayName + ".handleQualityCheckSubmit function", err);
            this.setState({ isSubmited: false })
        }
    }

    closeQualityCheckModal = () => {
        this.setState({
            isSubmited: false,
            loadPanelVisible: false,
            qualityCheckModal: false,
            id: 0,
            itemId: 0,
            serialNumber: "",
            qcTypeId: 0,
            qcDate: new Date(),
            problemFound: "",
            receivedDate: new Date(),
            newSerialNumber: "",
            qcStatusId: 0,
            errors: {
                itemId: 0,
                serialNumber: "",
                qcTypeId: 0,
                qcDate: "",
                problemFound: "",
                receivedDate: new Date(),
                newSerialNumber: "",
                qcStatusId: 0,
            }
        });
    }

    editqualityCheckModal = (e, data) => {
        e.preventDefault();
        
        setTimeout(() => {this.getStockItemMaster() }, 100);
        if (data != null) {
            this.setState({
                qualityCheckModal: true,
                singelData: data,
                id: data.id,
                itemId: data.itemId,
                serialNumber: data.serialNo,
                qcTypeId: data.qcTypeId,
                qcDate: data.qcDate,
                problemFound: data.problemFound,
                newSerialNumber: data.newSerialNo,
                receivedDate: data.receivedDate,
                qcStatusId: data.qcStatusId,
            });
        
        setTimeout(() => {this.getSerialNoList(data.itemId); }, 100);
        }
    }

    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift({
            location: 'after',
            widget: 'dxButton',
            options: {
                icon: 'refresh',
                onClick: this.getQc.bind(this)
            }
        });
        e.toolbarOptions.items.unshift({
            location: 'before',
            template: ''
        },
        )
    }

    render() {
        const renderGridCell = (data) => {
            return <div>
                <a href="#" className="fas fa-pen" title="Edit" onClick={(e) => { this.editqualityCheckModal(e, data.data) }} ></a>&nbsp;&nbsp;
            </div>;
        }

        return (
            <Fragment>
                <div className="section-body">
                    <div className='card bg-filter'>
                        <div className='card-body'>
                            <div className="section-header">
                            <div className='mr-3'>
                                    <div className='row'>
                                        <div className='col-lg-2 col-md-2 col-sm-12'>
                                            <div className="form-group">
                                                <label htmlFor="">Item</label>
                                                <SelectBox
                                                    dataSource={this.state.itemList}
                                                    className="basic-multi-select"
                                                    classNamePrefix="select"
                                                    placeholder="Select item"
                                                    name="id"
                                                    defaultValue={0}
                                                    value={this.state.filterItemId}
                                                    displayExpr='name'
                                                    valueExpr='id'
                                                    onSelectionChanged={this.handleFilterItemChange}
                                                    showClearButton={true}
                                                    searchEnabled={true}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-2 col-md-3 col-sm-12 col-xs-12">
                                            <div className="form-group">
                                                <label htmlFor="">Serial#</label>
                                                <input type="text" className="form-control form-control-sm" name="filterSerialNo" placeholder="Enter serial number" value={this.state.filterSerialNo}
                                                    title="Serial No" maxLength="20" onChange={this.handleInputChange} />
                                            </div>
                                        </div>
                                        <div className="col-lg-2 col-md-3 col-sm-12 col-xs-12">
                                            <div className="form-group">
                                                <label htmlFor="">New Serial</label>
                                                <input type="text" className="form-control form-control-sm" name="filterNewSerialNo" placeholder="Enter new serial number" value={this.state.filterNewSerialNo}
                                                    title="New Serial No" maxLength="20" onChange={this.handleInputChange} />
                                            </div>
                                        </div>
                                        <div className='col-lg-2 col-md-2 col-sm-12'>
                                            <div className="form-group">
                                                <label htmlFor="">QC Type</label>
                                                <SelectBox
                                                    dataSource={this.state.qcTypeList}
                                                    className="basic-multi-select"
                                                    classNamePrefix="select"
                                                    placeholder="Select qc type"
                                                    name="id"
                                                    defaultValue={0}
                                                    value={this.state.filterQcTypeId}
                                                    displayExpr='name'
                                                    valueExpr='id'
                                                    onSelectionChanged={this.handleFilerQCTypeChange}
                                                    showClearButton={true}
                                                    disabled={this.state.id > 0}
                                                    searchEnabled={true}
                                                />
                                            </div>
                                        </div>
                                        <div className='col-lg-2 col-md-2 col-sm-12'>
                                            <div className="form-group">
                                                <label htmlFor="">QC Status</label>
                                                <SelectBox
                                                    dataSource={this.state.qcStatusList}
                                                    className="basic-multi-select"
                                                    classNamePrefix="select"
                                                    placeholder="Select qc status"
                                                    name="id"
                                                    defaultValue={0}
                                                    value={this.state.filterQcStatusId}
                                                    displayExpr='name'
                                                    valueExpr='id'
                                                    onSelectionChanged={this.handleFilterQCStatusChange}
                                                    showClearButton={true}
                                                    disabled={false}
                                                    searchEnabled={true}
                                                />
                                            </div>
                                        </div>                                        
                                        <div className='col-lg-2 col-md-2 col-sm-12'>
                                            <div className="form-group">
                                                <label htmlFor="">QC Date</label>
                                                <DateBox type="date"
                                                    className="replay-date-time"
                                                    name="filterValidDate"
                                                    placeholder="dd/mm/yyyy"
                                                    displayFormat="dd/MM/yyyy"
                                                    showAnalogClock={true}
                                                    useMaskBehavior={true}
                                                    defaultValue="dd/MM/yyyy"
                                                    value={this.state.filterQCDate}
                                                    onValueChanged={this.handleFilterQCDateChanged}
                                                    width="100%"
                                                    acceptCustomValue={false} />
                                            </div>
                                        </div>
                                        <div className="col-lg-2 col-md-2 col-sm-12 col-xs-12">
                                            <div className="form-group">
                                                <label htmlFor="">&nbsp;</label><br />
                                                <button type="submit" className="btn btn-success" onClick={this.handleSearchQC}>
                                                    Search
                                                </button>&nbsp;&nbsp;
                                                <button type="submit" className="btn btn-outline-secondary" onClick={this.handleClearSearch}>
                                                    Clear
                                                </button>&nbsp;&nbsp;
                                                <button type="submit" className="btn btn-success" onClick={this.handlequalityCheckModal}>
                                                    New
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <h6 className='m-0'>Quality Check</h6> */}
                                {/* <div className="section-header-buttons">
                                    <button type="submit" className="btn btn-primary" onClick={this.handlequalityCheckModal}>
                                        <span title="Submit"><i className="fas fa-plus"></i> New</span>
                                    </button>&nbsp;&nbsp;
                                    <a href="#" className="btn btn-outline-secondary" title="Filter"><i className="fas fa-filter"></i></a>
                                    <div className="btn-group">
                                        <button type="button" title='More' className="btn btn-outline-secondary dropdown-toggle dropdown-icon" data-toggle="dropdown" aria-expanded="false">
                                            <i className="fas fa-ellipsis-h"></i>
                                        </button>
                                        <div className="dropdown-menu" role="menu">
                                            <a className="dropdown-item" href="#"><i className="fas fa-file-import"></i> Import</a>
                                            <a className="dropdown-item" href="#"><i className="fas fa-file-import"></i> Export</a>
                                            <a className="dropdown-item" href="#"><i className="fas fa-print"></i> Print</a>
                                            <a className="dropdown-item" href="#"><i className="fas fa-envelope"></i> Email</a>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                            
                        </div>
                    </div>
                    <div className='card'>
                    <div className='card-body'>
                                <DataGrid
                                    dataSource={this.state.qcSource}
                                    keyExpr="id"
                                    id='gridHsnCodeMasterList'
                                    selection={{ mode: 'single' }}
                                    columnsAutoWidth={false}
                                    showColumnLines={true}
                                    showRowLines={true}
                                    showBorders={true}
                                    rowAlternationEnabled={false}
                                    allowColumnResizing={true}
                                    columnResizingMode='widget'
                                    width="100%"
                                    onToolbarPreparing={this.onToolbarPreparing}
                                    onExporting={this.onExporting}>
                                    <Column dataField="id" width={35} caption="" allowFiltering={false} allowSorting={false} cellRender={renderGridCell} />
                                    <Column dataField="itemName" caption="Item" allowSearch={true} allowFiltering={false} allowSorting={true} width={200} />
                                    <Column dataField="serialNo" caption="Serial#" allowSearch={true} allowFiltering={false} allowSorting={true} width={150} />
                                    <Column dataField="qcDate" caption="QC Date" allowSearch={true} allowFiltering={false} allowSorting={true} width={150} dataType="datetime" format="dd/MM/yyyy" />
                                    <Column dataField="qcType" caption="QC Type" allowSearch={true} allowFiltering={false} allowSorting={true} width={120}  />
                                    <Column dataField="problemFound" caption="Problem Found" allowSearch={true} allowFiltering={false} allowSorting={true} width={180} />
                                    <Column dataField="qcStatus" caption="QC Status" allowSearch={true} allowFiltering={false} allowSorting={true} width={100} />
                                    <Column dataField="newSerialNo" caption="New Serial" allowSearch={true} allowFiltering={false} allowSorting={true} width={120} />
                                    <SortByGroupSummaryInfo summaryItem="count" />
                                    <GroupPanel visible={false} />
                                    {/* <Template name="toolbarTemplate" /> */}
                                    <HeaderFilter visible={true} />
                                    <SearchPanel visible={true} highlightCaseSensitive={true} width={240} placeholder="Search..." />
                                    <Grouping autoExpandAll={true} />
                                    <Paging defaultPageSize={20} />
                                    <Pager showPageSizeSelector={true} allowedPageSizes={[10, 20, 50]} showInfo={true} visible={true} />
                                    <Export enabled={true}  allowExportSelectedData={false} fileName="Quality Check" />
                                </DataGrid>
                            </div>
                    </div>
                </div>

                <Modal ariaHideApp={false}
                    isOpen={this.state.qualityCheckModal}
                    style={customStyles}
                    className={"react-modal"}
                    onRequestClose={this.closeQualityCheckModal}
                    shouldCloseOnOverlayClick={false}
                    contentLabel="Example Modal">
                    <form method="post" onSubmit={this.handleQualityCheckSubmit} style={this.state.isSubmited ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                        <div className="modal-header">
                            <h6 className="modal-title">{this.state.id > 0 ? "Update Quality Check" : "New Quality Check"}</h6>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-lg-4">
                                    <div className="form-group">
                                        <label>Item <span className="text-danger">*</span></label>
                                        <SelectBox
                                            dataSource={this.state.itemList}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select Item"
                                            name="id"
                                            defaultValue={0}
                                            value={this.state.itemId}
                                            displayExpr='name'
                                            valueExpr='id'
                                            onSelectionChanged={this.handleItemChange}
                                            showClearButton={true}
                                            disabled={this.state.id > 0}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["itemId"].length > 0 && <span className="error invalid-feedback">{this.state.errors["itemId"]}</span>}
                                    </div>
                                </div>
                               
                                <div className="col-lg-4">
                                    <div className="form-group">
                                        <label>Serial#  <span className="text-danger">*</span></label>
                                        <SelectBox
                                            dataSource={this.state.serialNoList}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select serial number"
                                            name="name"
                                            defaultValue={0}
                                            value={this.state.serialNumber}
                                            displayExpr='name'
                                            valueExpr='name'
                                            onSelectionChanged={this.handleSerialNoChange}
                                            showClearButton={true}
                                            disabled={this.state.id > 0}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["serialNumber"].length > 0 && <span className="error invalid-feedback">{this.state.errors["serialNumber"]}</span>}
                                    </div>
                                </div>
                               
                                {/* <div className="col-lg-4">
                                    <div className="form-group">
                                        <label>Serial# <span className="text-danger">*</span></label>
                                        <input type="text" className="form-control form-control-sm" name="serialNumber" placeholder="Enter serial number" value={this.state.serialNumber}
                                            title="Serial #" maxLength="20" onChange={this.handleInputChange} disabled={this.state.id > 0} />
                                        {this.state.errors["serialNumber"].length > 0 && <span className="error invalid-feedback">{this.state.errors["serialNumber"]}</span>}
                                    </div>
                                </div> */}
                                <div className="col-lg-4">
                                    <div className="form-group">
                                        <label>QC Type <span className="text-danger">*</span></label>
                                        <SelectBox
                                            dataSource={this.state.qcTypeList}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select QC type"
                                            name="id"
                                            defaultValue={0}
                                            value={this.state.qcTypeId}
                                            displayExpr='name'
                                            valueExpr='id'
                                            onSelectionChanged={this.handleQCTypeChange}
                                            showClearButton={true}
                                            //disabled={this.state.id > 0}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["qcTypeId"].length > 0 && <span className="error invalid-feedback">{this.state.errors["qcTypeId"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="form-group">
                                        <label htmlFor="">QC Date</label>
                                        <DateBox type="date"
                                            className="replay-date-time"
                                            name="qcDate"
                                            placeholder="dd/mm/yyyy"
                                            displayFormat="dd/MM/yyyy"
                                            showAnalogClock={true}
                                            useMaskBehavior={true}
                                            defaultValue={this.state.qcDate}
                                            value={this.state.qcDate}
                                            onValueChanged={this.handleqcDateDateChanged}
                                            width="100%"
                                            disabled={false}
                                            acceptCustomValue={false}
                                        />
                                        {this.state.errors["qcDate"].length > 0 && <span className="error invalid-feedback">{this.state.errors["qcDate"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-8">
                                    <div className="form-group">
                                        <label>Problem Found <span className="text-danger"></span></label>
                                        <TextArea
                                            height={35}
                                            className="form-control form-control-sm"
                                            name="problemFound" placeholder="Enter problem found"
                                            value={this.state.problemFound}
                                            onValueChanged={this.handleTextAreaValueChanged}
                                        />
                                        {this.state.errors["problemFound"].length > 0 && <span className="error invalid-feedback">{this.state.errors["problemFound"]}</span>}
                                    </div>
                                </div>
                                
                            </div>
                            {
                                    this.state.id > 0 ?
                                        <div className='row'>
                                            <div className="col-lg-4">
                                                <div className="form-group">
                                                    <label>QC Status <span className="text-danger">*</span></label>
                                                    <SelectBox
                                                        dataSource={this.state.qcStatusList}
                                                        className="basic-multi-select"
                                                        classNamePrefix="select"
                                                        placeholder="Select status"
                                                        name="id"
                                                        defaultValue={0}
                                                        value={this.state.qcStatusId}
                                                        displayExpr='name'
                                                        valueExpr='id'
                                                        onSelectionChanged={this.handleQCStatusChange}
                                                        showClearButton={true}
                                                        disabled={false}
                                                        searchEnabled={true}
                                                    />
                                                    {this.state.errors["qcStatusId"].length > 0 && <span className="error invalid-feedback">{this.state.errors["qcStatusId"]}</span>}
                                                </div>
                                            </div>
                                            <div className="col-lg-4" style={{display: this.state.qcStatusId == 2 ? "block" : "none"}}>
                                                <div className="form-group">
                                                    <label>New Serial <span className="text-danger">*</span></label>
                                                    <input type="text" className="form-control form-control-sm" name="newSerialNumber" placeholder="Enter new serial number" value={this.state.newSerialNumber}
                                                        title="New Serial" maxLength="20" onChange={this.handleInputChange} disabled={false} />
                                                    {this.state.errors["newSerialNumber"].length > 0 && <span className="error invalid-feedback">{this.state.errors["newSerialNumber"]}</span>}
                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <div className="form-group">
                                                    <label htmlFor="">Received Date<span className="text-danger">*</span></label>
                                                    <DateBox type="date"
                                                        className="replay-date-time"
                                                        name="receivedDate"
                                                        placeholder="dd/mm/yyyy"
                                                        displayFormat="dd/MM/yyyy"
                                                        showAnalogClock={true}
                                                        useMaskBehavior={true}
                                                        defaultValue={this.state.receivedDate}
                                                        value={this.state.receivedDate}
                                                        onValueChanged={this.handleReceivedDateDateChanged}
                                                        max={this.state.maxReceivedDate}
                                                        width="100%"
                                                        disabled={false}
                                                        acceptCustomValue={false}
                                                    />
                                                    {this.state.errors["receivedDate"].length > 0 && <span className="error invalid-feedback">{this.state.errors["receivedDate"]}</span>}
                                                </div>
                                            </div>
                                        </div>
                                        : null}
                        </div>
                        <div className="modal-footer">
                            <button type="submit" className="btn btn-success" onClick={this.handleQualityCheckSubmit} >
                                {this.state.isSubmited && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                {!this.state.isSubmited && <span title="Submit">Submit</span>}
                            </button>
                            <button type="button" className="btn btn-outline-secondary" onClick={this.closeQualityCheckModal} title="Cancel">Cancel</button>
                        </div>
                    </form>
                </Modal>
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={position}
                    visible={this.state.loadPanelVisible}
                    showIndicator={true}
                    shading={true}
                    showPane={true}
                    hideOnOutsideClick={false}
                />
            </Fragment>
        )
    };
}