import React, { Fragment, Component } from 'react';
import DataGrid, {
    Column,
    Export, Grouping,
    GroupPanel,
    HeaderFilter, Pager,
    Paging,
    SearchPanel,
    SortByGroupSummaryInfo
} from 'devextreme-react/data-grid'; //npm i devextreme-react
import Modal from 'react-modal'; //npm install --save react-modal;
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import { getRequestParams } from '../../helpers/globalfunctions.js';
import { alphanumericValidation } from '../../helpers/globalvalidations.js';
import { LoadPanel } from 'devextreme-react/load-panel';
import Swal from 'sweetalert2'; //npm i sweetalert2 --save
import { toast } from 'react-toastify'; //npm i react-toastify --save
import { jsPDF } from 'jspdf';
import { exportDataGrid } from 'devextreme/pdf_exporter';
const exportFormats = ['pdf', 'xlsx'];
const position = { of: '#historydiv' };

const customStyles = {
    content: {
        width: '20%',
    }
}

export class AttributeTypeComponent extends Component {
    static displayName = AttributeTypeComponent.name;

    constructor(props) {
        super(props);

        this.state = {
            isSubmited: false,
            loadPanelVisible: false,
            attributeTypeModelIsOpen: false,
            attributetypesource: null,
            singelData: null,
            id: 0,
            name: "",
            errors: {
                name: "",
            }
        }
        this.handleAttributeTypeSubmit = this.handleAttributeTypeSubmit.bind(this);
    }

    async componentDidMount() {
        await this.getAttributeType();
    }

    getAttributeType = async () => {
        this.setState({
            loadPanelVisible: true
        });
        try {
            var request = "";
            const requestParams = getRequestParams("GET", request);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'attributes/attributetype/0', requestParams);
            const data = await response.json();
            this.setState({
                attributetypesource: data.payload,
                loadPanelVisible: false
            });

        } catch (err) {
            console.log("Error in " + this.displayName + ".getAttributeType function", err);
            this.setState({
                loadPanelVisible: false
            });
        }
    }

    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift({
            location: 'after',
            widget: 'dxButton',
            options: {
                icon: 'refresh',
                onClick: this.getAttributeType.bind(this)
            }
        });
    }

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        let errors = this.state.errors;

        //const alphanumericValidation = /^[A-Za-z0-9]*$/;
        switch (name) {
            case 'name':
                if (alphanumericValidation.test(event.target.value))
                    this.setState({ name: event.target.value });
                break;
            default:
                this.setState({ 
                    [name]: value,
                    // errors:{
                    //     [name]: ""
                    // }
                 });
                break;
        }
        this.setState({
            errors: errors
        });
        //this.state.errors[name] = "";
        // this.setState({
        //     errors:{
        //         [name]: ""
        //     }
        // });
    }

    handleAttributeTypeModal = (e) => {
        this.setState({
            attributeTypeModelIsOpen: true,
            singelData: null
        });
    }

    closeAttributeTypeModal = () => {
        this.setState({
            isSubmited: false,
            loadPanelVisible: false,
            attributeTypeModelIsOpen: false,
            singelData: null,
            id: 0,
            name: "",
            errors: {
                name: "",
            }
        });
    }

    handleValidation = () => {
        let formIsValid = true;
        try {
            let errors = this.state.errors;
            errors.name = "";
            errors.description = "";
            errors.attributeTypeId = 0;

            if (this.state.name.trim() === "") {
                formIsValid = false;
                errors.name = "Please enter attribute type.";
            }
            this.setState({ errors: errors });
        }
        catch (err) {
            console.log("Error in " + this.displayName + ".handleValidation function", err);
            this.setState({ isSubmited: false })
        }
        return formIsValid;
    }

    handleAttributeTypeSubmit = async (e) => {
        e.preventDefault();
        try {
            if (this.handleValidation()) {
                this.setState({ isSubmited: true });
                var newObject = {};
                newObject.Name = this.state.name.trim();
                const requestParams = getRequestParams('POST', newObject);
                const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'attributes/' + this.state.id + '/attributetype', requestParams);
                const data = await response.json();
                if (data.isSuccess) {
                    Swal.fire({
                        icon: 'success',
                        title: 'Success',
                        text: data.message,
                    }).then((result) => {
                        if (result.isConfirmed) {
                            this.getAttributeType();
                            this.closeAttributeTypeModal();
                        }
                        this.setState({ isSubmited: false })
                    })
                }
                else {
                    toast.error(data.message, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        style: { width: "310px" },
                        hideProgressBar: false,
                        newestOnTop: false,
                        closeButton: false
                    });
                    this.setState({ isSubmited: false })
                }
            }
        }
        catch (err) {
            console.log("Error in " + this.displayName + ".handleAttributeTypeSubmit function", err);
            this.setState({ isSubmited: false })
        }
    }

    editAttributeModelModal = (e, data) => {
        e.preventDefault();
        if (data != null) {
            this.setState({
                attributeTypeModelIsOpen: true,
                singelData: data,
                id: data.id,
                name: data.name,
            });
        }
    }
    onExporting = (e) => {
        const doc = new jsPDF();
        if (e.format === "pdf") {
            exportDataGrid({
                jsPDFDocument: doc,
                component: e.component,
                columnWidths: 50
                // indent: 10,
            }).then(() => {
                doc.save('AttributeType.pdf');
            });
        }
    }

    render() {
        const renderGridCell = (data) => {
            return <div>
                <a href="/#" className="fas fa-pen" title="Edit" onClick={(e) => { this.editAttributeModelModal(e, data.data) }} >&nbsp;</a>&nbsp;&nbsp;
            </div>;
        }

        return (
            <Fragment>
                <div className="section-body">
                    <div className='card bg-filter'>
                    <div className='card-body'>
                            <div className="section-header">
                                <h6 class="m-0">Attribute Type</h6>                                
                                <div className="section-header-buttons">
                                    <button type="submit" className="btn btn-success" onClick={this.handleAttributeTypeModal}>
                                        New
                                    </button>&nbsp;&nbsp;
                                    {/* <a href="#" className="btn btn-primary" onClick={this.handleAttributeTypeModal}><i className="fas fa-plus"></i> New</a>&nbsp; */}
                                    {/* <a href="/#" className="btn btn-outline-secondary" title="Filter"><i className="fas fa-filter"></i></a> */}
                                    {/* <a href="#" className="btn btn-outline-secondary"><i className="fas fa-ellipsis-h"></i></a> */}
                                    {/* <div className="btn-group">
                                        <button type="button" className="btn btn-outline-secondary">Action</button>
                                        <button type="button" title="More" className="btn btn-outline-secondary dropdown-toggle dropdown-icon" data-toggle="dropdown" aria-expanded="false">
                                            <i className="fas fa-ellipsis-h"></i>
                                        </button>
                                        <div className="dropdown-menu" role="menu">
                                            <a className="dropdown-item" href="/#"><i className="fas fa-file-import"></i> Import</a>
                                            <a className="dropdown-item" href="/#"><i className="fas fa-file-import"></i> Export</a>
                                            <a className="dropdown-item" href="/#"><i className="fas fa-print"></i> Print</a>
                                            <a className="dropdown-item" href="/#"><i className="fas fa-envelope"></i> Email</a>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                           
                        </div>
                    </div>
                    <div className='card'>
                    <div className='card-body'>
                                <DataGrid
                                    dataSource={this.state.attributetypesource}
                                    keyExpr="id"
                                    id='gridAttributeMasterList'
                                    selection={{ mode: 'single' }}
                                    columnsAutoWidth={false}
                                    showColumnLines={true}
                                    showRowLines={true}
                                    showBorders={true}
                                    rowAlternationEnabled={false}
                                    allowColumnResizing={true}
                                    columnResizingMode='widget'
                                    onExporting={this.onExporting}
                                    onToolbarPreparing={this.onToolbarPreparing}>
                                    <Column dataField="id" width={30} caption="" allowFiltering={false} allowSorting={false} cellRender={renderGridCell} />
                                    <Column dataField="name" caption="Name" allowSearch={true} allowFiltering={false} allowSorting={true} width={300} />
                                    <SortByGroupSummaryInfo summaryItem="count" />
                                    <GroupPanel visible={false} />
                                    {/* <Template name="toolbarTemplate" /> */}
                                    <HeaderFilter visible={true} />
                                    <SearchPanel visible={true} highlightCaseSensitive={true} width={240} placeholder="Search..." />
                                    <Grouping autoExpandAll={true} />
                                    <Paging defaultPageSize={20} />
                                    <Pager showPageSizeSelector={true} allowedPageSizes={[10, 20, 50]} showInfo={true} visible={true} />
                                    <Export enabled={true}  allowExportSelectedData={false} fileName="AttributeTypeMaster" />
                                </DataGrid>
                            </div>
                    </div>
                </div>

                <Modal ariaHideApp={false}
                    isOpen={this.state.attributeTypeModelIsOpen}
                    style={customStyles}
                    className={"react-modal"}
                    //onAfterOpen={this.handleAssignDataToState}
                    onRequestClose={this.closeAttributeTypeModal}
                    shouldCloseOnOverlayClick={false}
                    contentLabel="Example Modal">
                    <form method="post" onSubmit={this.handleAttributeTypeSubmit} style={this.state.isSubmited ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                        <div className="modal-header">
                            <h6 className="modal-title">{this.state.id > 0 ? "Update Attribute Type" : "New Attribute Type"}</h6>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="form-group">
                                        <label>Attribute Type <span className="text-danger">*</span></label>
                                        <input type="text" className="form-control form-control-sm" name="name" placeholder="Enter attribute type" value={this.state.name}
                                            title="Attribute Type" maxLength="50" onChange={this.handleInputChange} />
                                        {this.state.errors["name"].length > 0 && <span className="error invalid-feedback">{this.state.errors["name"]}</span>}
                                    </div>
                                </div>
                            </div></div>
                        <div className="modal-footer">
                            <button type="submit" className="btn btn-success" onClick={this.handleAttributeTypeSubmit} >
                                {this.state.isSubmited && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                {!this.state.isSubmited && <span title="Submit">Submit</span>}
                            </button>
                            <button type="button" className="btn btn-outline-secondary" onClick={this.closeAttributeTypeModal} title="Cancel">Cancel</button>
                        </div>
                    </form>
                </Modal>
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={position}
                    visible={this.state.loadPanelVisible}
                    showIndicator={true}
                    shading={true}
                    showPane={true}
                    hideOnOutsideClick={false}
                />
            </Fragment>
        )
    };
}