import React, { Fragment, Component } from 'react';
import DataGrid, {
    Column,
    Export, Grouping,
    GroupPanel,
    HeaderFilter, Pager,
    Paging,
    SearchPanel,
    SortByGroupSummaryInfo,
    Summary, TotalItem  
} from 'devextreme-react/data-grid'; //npm i devextreme-react
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import { getRequestParams, formatDate,numberGridFormat } from '../../helpers/globalfunctions.js';
import { LoadPanel } from 'devextreme-react/load-panel';
import { Template } from 'devextreme-react/core/template';
import { currencySymbolFormats, precisionFormats } from '../../helpers/fixcodes.js';
import { createBrowserHistory } from 'history';
import DateBox from 'devextreme-react/date-box';
import { jsPDF } from 'jspdf';
import { exportDataGrid } from 'devextreme/pdf_exporter';
import moment from 'moment';
const exportFormats = ['pdf', 'xlsx'];
const position = { of: '#historydiv' };
const history = createBrowserHistory();

export class BillsListComponent extends Component {
    static displayName = BillsListComponent.name;
    constructor(props) {
        super(props);
        this.state = {
            isSubmited: false,
            loadPanelVisible: false,
            billDataSource: null,
            approveBills: null,
            termTypeList: [],
            termTypeId: 0,
            billingStartDate: moment(new Date()).subtract(30, 'day').format("MM/DD/YYYY"),
            billingEndDate: new Date(),
            selectionRowKey: [],
            sessionUser: JSON.parse(sessionStorage.getItem("sessionUser")),
        }
    }

    async componentDidMount() {
        await this.GetBills();
    }

    GetBills = async () => {
        this.setState({
            loadPanelVisible: true
        });
        try {
            var request = {};
            request.FromDate = formatDate(this.state.billingStartDate, "yyyy-MM-dd");
            request.ToDate = formatDate(this.state.billingEndDate, "yyyy-MM-dd");
            request.InvoiceId = 0
            const requestParams = getRequestParams("POST", request);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'billing', requestParams);
            let data = await response.json();
            this.setState({
                billDataSource: data.payload,
                loadPanelVisible: false
            });

        } catch (err) {
            console.log("Error in " + this.displayName + ".GetBills function", err);
            this.setState({
                loadPanelVisible: false
            });
        }
    }
    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift({
            location: 'after',
            widget: 'dxButton',
            options: {
                icon: 'refresh',
                onClick: this.GetBills.bind(this)
            }
        });
    }

    editInvoice(e, data) {
        e.preventDefault();
        history.push({
            pathname: '/bill/new',
            state: {
                billId: data.id,
                billDate: data.billDate,
                customerId: data.customerId,
                orderNo: data.orderNumber,
                billNo: data.billNo,
                dueDate: data.billDueDate,
            }
        });
        window.location.reload(false);
    }

    handleAddInvoice = (e) => {
        sessionStorage.setItem("session_PageName", "Bills");
        window.location.href = "/bill/new";
    }

    handleStartDateChanged = (date) => {
        this.setState({ billingStartDate: date.value });
    }
    handleEndDateChanged = (date) => {
        this.setState({ billingEndDate: date.value });
    }
    handlePaymentTermTypeChange = (selectedOption) => {
        this.setState({
            termTypeId: (selectedOption.selectedItem !== null ? selectedOption.selectedItem.id : 0),
        });
    }
    handleMultipleSelectionChanged = (e) => {
        this.setState({
            selectionRowKey: e.selectedRowKeys
        });
    }
    onExporting = (e) => {
        const doc = new jsPDF();
        if (e.format === "pdf") {
            exportDataGrid({
                jsPDFDocument: doc,
                component: e.component,
                columnWidths: 50
                // indent: 10,
            }).then(() => {
                doc.save('Bill.pdf');
            });
        }
    }
    handleClear = () => {
        this.setState({
            accountId: 0,
            orderDate: "",
            shippingDate: "",
            invoiceNo: "",
            orderNo: "",
            termTypeId: 0,
            billingStartDate: moment(new Date()).subtract(30, 'day').format("MM/DD/YYYY"),
            billingEndDate: new Date(),
        });
        setTimeout(() => this.GetBills(), 100);
    }

    render() {
        const renderGridNumberCell = (data) => {
            return numberGridFormat(data.value)
            //return <div><small><i className={currencySymbolFormats.currencyFormat}></i></small>&nbsp;&nbsp;{parseFloat(data.value).toFixed(precisionFormats[2])}</div>;
        }
        const renderTotalGridNumberCell = (data) => {
            return numberGridFormat(data.value)
            // return <div><small><i className={currencySymbolFormats.currencyFormat}></i></small>&nbsp;&nbsp;{parseFloat(data.value).toFixed(precisionFormats[2])}</div>;
        }
        const customizeText = (data) => {
            var value = 'Total: ' + numberGridFormat(data.value);
            return value
        }
        const customizeGrossText = (data) => {
            var value = 'Total: ' + numberGridFormat(data.value);
            return value
        }
        const renderGridCell = (data) => {
            return <div className='text-center'>
                <a href="/#" className="fas fa-pen" title="Edit" onClick={(e) => { this.editInvoice(e, data.data) }} >&nbsp;</a>
            </div>;
        }
        const renderGridAmountCell = (data) => {
            return <div>{parseFloat(data.value).toFixed(precisionFormats[2])}</div>;
        }
        return (
            <Fragment>
                <div className="section-body">
                    <div className='card bg-filter'>
                        <div className='card-body'>
                            <div className="section-header">
                                <div className='row'>
                                    <div className='col-lg-2 col-md-2 col-sm-12'>
                                        <div className="form-group">
                                            <label htmlFor="">Billing Start Date</label>
                                            <DateBox type="date"
                                                className="replay-date-time"
                                                name="billingStartDate"
                                                placeholder="dd/mm/yyyy"
                                                displayFormat="dd/MM/yyyy"
                                                showAnalogClock={true}
                                                useMaskBehavior={true}
                                                defaultValue="dd/MM/yyyy"
                                                value={this.state.billingStartDate}
                                                onValueChanged={this.handleStartDateChanged}
                                                width="100%"
                                                acceptCustomValue={false}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-lg-2 col-md-2 col-sm-12'>
                                        <div className="form-group">
                                            <label htmlFor="">Billing End Date</label>
                                            <DateBox type="date"
                                                className="replay-date-time"
                                                name="billingEndDate"
                                                placeholder="dd/mm/yyyy"
                                                displayFormat="dd/MM/yyyy"
                                                showAnalogClock={true}
                                                useMaskBehavior={true}
                                                defaultValue="dd/MM/yyyy"
                                                value={this.state.billingEndDate}
                                                onValueChanged={this.handleEndDateChanged}
                                                width="100%"
                                                acceptCustomValue={false}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-3 col-sm-12 col-xs-12 pl-0">
                                        <div className="form-group">
                                            <label htmlFor="">&nbsp;</label><br />
                                            <button type="submit" className="btn btn-success" onClick={this.GetBills} title="Search">
                                                Search
                                            </button>&nbsp;&nbsp;
                                            <button type="submit" className="btn btn-outline-secondary" title="Clear" onClick={this.handleClear}>
                                                Clear
                                            </button>
                                            
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div>
                                    <button type="submit" className="btn btn-success" onClick={this.handleAddInvoice}>
                                                New
                                            </button>
                                    </div>
                                </div>
                            </div>

                           
                        </div>
                    </div>
                    <div className="card" >
                                    <div className="card-body" >
                                        <DataGrid
                                            dataSource={this.state.billDataSource}
                                            keyExpr="id"
                                            //id='gridSalesOrderList'
                                            columnsAutoWidth={false}
                                            showColumnLines={true}
                                            showRowLines={true}
                                            showBorders={true}
                                            rowAlternationEnabled={false}
                                            allowColumnResizing={true}
                                            columnResizingMode='widget'
                                            onExporting={this.onExporting}
                                            onToolbarPreparing={this.onToolbarPreparing}>

                                            <Column dataField="id" width={40} caption="" allowFiltering={false} allowSorting={false} cellRender={renderGridCell} />
                                            <Column dataField="customerName" caption="Customer" allowSearch={true} allowFiltering={false} allowSorting={true} width={250} />
                                            <Column dataField="billNo" caption="Bill#" allowSearch={true} allowFiltering={false} allowSorting={true} width={150} />
                                            <Column dataField="billDate" caption="Bill Date" allowSearch={true} allowFiltering={false} allowSorting={true} width={150} dataType="datetime" format="dd/MM/yyyy" />
                                            <Column dataField="billDueDate" caption="Due Date" allowSearch={true} allowFiltering={false} allowSorting={true} width={100} dataType="datetime" format="dd/MM/yyyy" />
                                            <Column dataField="qty" caption="Item Qty" allowSearch={true} allowFiltering={false} allowSorting={true} width={100} />
                                            <Column dataField="taxAmount" caption="Total Tax" allowSearch={true} allowFiltering={false} allowSorting={true} width={150} cellRender={renderTotalGridNumberCell} />
                                            <Column dataField="grossAmount" caption="Gross total" allowSearch={true} allowFiltering={false} allowSorting={true} width={200}
                                                format={"#,##0.###"}
                                                cellRender={renderGridNumberCell}
                                            />
                                            {/* <Column dataField="amountDue" caption="Amount Due" allowSearch={true} allowFiltering={false} allowSorting={true} width={150} cellRender={renderGridAmountCell} /> */}
                                            {/* <Column dataField="shippingDate" caption="Shipped Date" allowSearch={true} allowFiltering={false} allowSorting={true} width={150} dataType="datetime" format="dd/MM/yyyy HH:mm" /> */}
                                          
                                            <Summary>
                                                <TotalItem column="grossAmount" summaryType="sum" customizeText={customizeGrossText} />
                                                <TotalItem column="taxAmount" summaryType="sum" customizeText={customizeText} />
                                            </Summary>
                                            <SortByGroupSummaryInfo summaryItem="count" />
                                            <GroupPanel visible={false} />
                                            <Template name="toolbarTemplate" render={this.toolbarItemRender} />
                                            <HeaderFilter visible={true} />
                                            <SearchPanel visible={true} highlightCaseSensitive={true} width={240} placeholder="Search..." />
                                            <Grouping autoExpandAll={true} />
                                            <Paging defaultPageSize={20} />
                                            <Pager showPageSizeSelector={true} allowedPageSizes={[10, 20, 50]} showInfo={true} visible={true} />
                                            <Export enabled={true}  allowExportSelectedData={false} fileName="Bills" />
                                        </DataGrid>
                                    </div>

                                </div>
                </div>
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={position}
                    visible={this.state.loadPanelVisible}
                    showIndicator={true}
                    shading={true}
                    showPane={true}
                    hideOnOutsideClick={false}
                />
            </Fragment>
        )
    };
}