import React, { Component, Fragment } from 'react';
import { SidebarComponent } from './shared/SidebarComponent';
import { TopbarComponent } from './shared/TopbarComponent';

export class AuthorisedLayout extends Component {
    static displayName = AuthorisedLayout.name;

    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render() {
        return (
            <Fragment>   
                <div className="main-wrapper">
                <SidebarComponent />
                <TopbarComponent />
                <div name="mainContent" className="main-content">
                        <section className="section">
                            {this.props.children}
                        </section>
                    </div>
                </div>
            </Fragment>
        )
    }
}