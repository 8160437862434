import React, { Fragment, Component } from 'react';
import DataGrid, {
    Column,
    Export, Grouping,
    GroupPanel,
    HeaderFilter, Pager,
    Paging,
    SearchPanel,
    SortByGroupSummaryInfo
} from 'devextreme-react/data-grid'; //npm i devextreme-react

import { getRequestParams,numberGridFormat } from '../../helpers/globalfunctions.js';
import { exportFormats } from '../../helpers/fixcodes.js';
import { createBrowserHistory } from 'history';
import { jsPDF } from 'jspdf';
import { exportDataGrid } from 'devextreme/pdf_exporter';
import { SelectBox } from 'devextreme-react/select-box';
import DateBox from 'devextreme-react/date-box';
import { formatDate } from '../../helpers/globalfunctions.js';
const history = createBrowserHistory();



export class DebitNotesListComponent extends Component {
    static displayName = DebitNotesListComponent.name;

    constructor(props) {
        super(props);
        this.state = {
            debitNotesList:null,
            accountList:null,
            billList:null,
            fromDate: new Date(),
            toDate: new Date(),
            accountId: 0,
            filterbillNo:"",

        }
    }

    async componentDidMount() {
        await this.getDebitNotesMaster();
        await this.getAccounts();
        await this.getBill();
    }

    getDebitNotesMaster = async () => {
        try {
            
            var request = {};
            request.FromDate = formatDate(this.state.fromDate, "MM/dd/yyyy") + ' ' + '00:00:00';
            request.ToDate = formatDate(this.state.toDate, "MM/dd/yyyy") + ' ' + "23:59:59";
            request.vendorId = this.state.accountId;
            request.BillNo = this.state.filterbillNo;
            const requestParams = getRequestParams("Post", request);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'debitnote/debitNote', requestParams);
            const data = await response.json();
            
            this.setState({
                debitNotesList: data.payload
            });
        } catch (err) {
            console.log("Error in " + this.displayName + ".getDebitNotesMaster function", err);
        }
    }

    getAccounts = async () => {
        
        try {
            var request = {};
            const requestParams = getRequestParams("GET", request);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'account/dropdownAccount/3', requestParams);
            const data = await response.json();
            
            this.setState({
                accountList: data.payload === null ? [] : data.payload,
            });
        } catch (err) {
            console.log("Error in " + this.displayName + ".getAccounts function", err);
        }
    }

    handleAccountChange = (selectedOption) => {
        this.setState({
            accountId: (selectedOption.selectedItem !== null ? selectedOption.selectedItem.id : 0),
        });
    }

    getBill = async () => {
        
        try {
            var request = {};
            const requestParams = getRequestParams("GET", request);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'billing/billingDropDown',requestParams);
            const data = await response.json();
            this.setState({
                billList: data.payload 
            });
        } catch (err) {
            console.log("Error in " + this.displayName + ".getBill function", err);
        }
    }

    handleBillChange = (selectedOption) => {
        this.setState({
            filterbillNo: (selectedOption.selectedItem !== null ? selectedOption.selectedItem.name: ""),
        });
    }
    

    handleFromDateChanged = (date) => {
        this.setState({ fromDate: date.value });
    }

    handleToDateChanged = (date) => {
        this.setState({ toDate: date.value });
    }

    handleSubmit = async () => {
        
        await this.getDebitNotesMaster();
    }

    handleCancel = () => {
        this.setState({
            accountId: 0,
            filterbillNo: "",
            fromDate: new Date(),
            toDate: new Date(),
        });
        setTimeout(() => this.getDebitNotesMaster(), 100);
    }

    handleAddDebitNote = (e) => {
        sessionStorage.setItem("session_PageName", "Debit Note");
        window.location.href = "/debitnote/new";
    }

    onExporting = (e) => {
        const doc = new jsPDF();
        if (e.format === "pdf") {
            exportDataGrid({
                jsPDFDocument: doc,
                component: e.component,
                columnWidths: 50,
                // indent: 10,
            }).then(() => {
                doc.save(e.fileName);
            });
        }
    }

    editDebitNote(e, data) {
        e.preventDefault();
        
        history.push({
            pathname: '/debitnote/new',
            state: {
                debitNoteId: data.id,
                debitNoteNo: data.debitNoteNo,
                debitNoteDate: data.debitNoteDate,
                validTill: data.validTill,
                billNo: data.billNo,
                vendorId: data.vendorId,
                remarks:data.remarks,
                grossAmount :data.grossAmount,
                sourceUrl: "/debitnote/list",
                sourceState: this.state
            }
        });
        window.location.reload(false);
    }


    render() {
            const renderGridCell = (data) => {
                return <div>
                    <a href="/#" className="fas fa-eye" title="Edit" onClick={(e) => { this.editDebitNote(e, data.data) }}>&nbsp;</a>
                </div>;
            }
            const renderGridNumberCell = (data) => {
                return numberGridFormat(data.value)
                //  return <div><small><i class={currencySymbolFormats.currencyFormat}></i></small>&nbsp;&nbsp;{parseFloat(data.value).toFixed(precisionFormats[2])}</div>;
            }
        return (
            <Fragment>
                <div className="section-body">
                    <div className='card bg-filter'>
                        <div className='card-body'>
                            <div className="section-header">
                                <div className='row'>
                                    <div className='col-lg-4 col-md-4 col-sm-12'>
                                        <div className="form-group">
                                            <label htmlFor="">Vendor <span className="text-danger">*</span></label>
                                            <SelectBox
                                                dataSource={this.state.accountList}
                                                className="basic-multi-select"
                                                classNamePrefix="select"
                                                placeholder="Select vendor"
                                                name="id"
                                                defaultValue={0}
                                                value={this.state.accountId}
                                                displayExpr='name'
                                                valueExpr='id'
                                                onSelectionChanged={this.handleAccountChange}
                                                showClearButton={true}
                                                searchEnabled={true}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-lg-2 col-md-2 col-sm-12'>
                                        <div className="form-group">
                                            <label htmlFor="">Bill# <span className="text-danger">*</span></label>
                                            <SelectBox
                                                dataSource={this.state.billList}
                                                className="basic-multi-select"
                                                classNamePrefix="select"
                                                placeholder="Select bill number"
                                                name="name"
                                                defaultValue={0}
                                                value={this.state.filterbillNo}
                                                displayExpr='name'
                                                valueExpr='name'
                                                onSelectionChanged={this.handleBillChange}
                                                showClearButton={true}
                                                searchEnabled={true}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-2">
                                        <div className="form-group">
                                            <label>From Date </label>
                                            <DateBox type="date"
                                                className="replay-date-time"
                                                name="fromDate"
                                                placeholder="MM/dd/yyyy"
                                                displayFormat="MM/dd/yyyy"
                                                showAnalogClock={true}
                                                useMaskBehavior={true}
                                                defaultValue="MM/dd/yyyy"
                                                value={this.state.fromDate}
                                                onValueChanged={this.handleFromDateChanged}
                                                width="100%"
                                                disabled={false}
                                                acceptCustomValue={false}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-2">
                                        <div className="form-group">
                                            <label>To Date</label>
                                            <DateBox type="date"
                                                className="replay-date-time"
                                                name="toDate"
                                                placeholder="MM/dd/yyyy"
                                                displayFormat="MM/dd/yyyy"
                                                showAnalogClock={true}
                                                useMaskBehavior={true}
                                                defaultValue="MM/dd/yyyy"
                                                value={this.state.toDate}
                                                onValueChanged={this.handleToDateChanged}
                                                width="100%"
                                                disabled={false}
                                                acceptCustomValue={false}
                                            />
                                        </div>
                                    </div>


                                    <div className="col-lg-2 col-md-3 col-sm-12 col-xs-12">
                                        <div className="form-group">
                                            <label htmlFor="">&nbsp;</label><br />
                                            <button type="submit" className="btn btn-success" onClick={this.handleSubmit}>
                                                Search
                                            </button>&nbsp;&nbsp;
                                            <button type="submit" className="btn btn-outline-secondary" onClick={this.handleCancel}>
                                                Clear
                                            </button>&nbsp;&nbsp;
                                            {/* <button type="submit" className="btn btn-success" onClick={this.handleAddDebitNote}>
                                                New
                                            </button> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='card'>
                        <div className='card-body'>
                            <DataGrid
                                dataSource={this.state.debitNotesList}
                                keyExpr="id"
                                id='griddebitNotesList'
                                selection={{ mode: 'single' }}
                                columnsAutoWidth={false}
                                showColumnLines={true}
                                showRowLines={true}
                                showBorders={true}
                                rowAlternationEnabled={false}
                                allowColumnResizing={true}
                                onExporting={this.onExporting}
                                columnResizingMode='widget'
                                onToolbarPreparing={this.onToolbarPreparing}>
                                <Column dataField="" caption="" allowSearch={true} allowFiltering={false} allowSorting={true} width={50} cellRender={renderGridCell} />
                                <Column dataField="debitNoteNo" caption="Debit Note#" allowSearch={true} allowFiltering={false} allowSorting={true} width={180} />
                                <Column dataField="debitNoteDate" caption="Debit Note Date" allowSearch={true} allowFiltering={false} allowSorting={true} width={200} />
                                <Column dataField="vendor" caption="Vendor" allowSearch={true} allowFiltering={false} allowSorting={true} width={180} />
                                <Column dataField="billNo" caption="Bill No" allowSearch={true} allowFiltering={false} allowSorting={true} width={180} />
                                <Column dataField="grossAmount" caption="Gross Amount" allowSearch={true} allowFiltering={false} allowSorting={true} width={180} cellRender={renderGridNumberCell} />
                                {/* <Column dataField="status" caption="Status" allowSearch={true} allowFiltering={false} allowSorting={true} width={180} /> */}
                                <Column dataField="remarks" caption="Remarks" allowSearch={true} allowFiltering={false} allowSorting={true} width={180} />


                                <SortByGroupSummaryInfo summaryItem="count" />
                                <GroupPanel visible={false} />
                                {/* <Template name="toolbarTemplate" /> */}
                                <HeaderFilter visible={true} />
                                <SearchPanel visible={true} highlightCaseSensitive={true} width={240} placeholder="Search..." />
                                <Grouping autoExpandAll={true} />
                                <Paging defaultPageSize={20} />
                                <Pager showPageSizeSelector={true} allowedPageSizes={[10, 20, 50]} showInfo={true} visible={true} />
                                <Export enabled={true}  allowExportSelectedData={false} fileName="DebitNotes" />
                            </DataGrid>
                        </div>
                    </div>
                </div>

            </Fragment>
        )
    };
}
