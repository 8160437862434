import React, { Fragment, Component } from 'react';
import ReactToPrint from "react-to-print";
import Modal from 'react-modal'; //npm install --save react-modal;
import { jsPDF } from 'jspdf';
import { LoadPanel } from 'devextreme-react/load-panel';
import { getRequestParams, formatDate } from '../../helpers/globalfunctions.js';
const customStyles = {
    content: {
        width: '60%',
    }
}
const position = { of: '#historydiv' };
export class InvoicePdfDetailsComponent extends Component {
    static displayName = InvoicePdfDetailsComponent.name;
    constructor(props) {

        super(props);
        this.state = {
            invoiceId: 0,
            invoiceTypeId: 0,
            soNo: '',
            customerId: 0,
            invoiceAccountDetails: null,
            adminAccount: null,
            invoiceDetails: null,
            grossAmount: 0,
            totalAmount: 0,
            taxList: null,
            taxamount: null,
            vehicleDeviceList: null,
            wordTotalAmount: "",
            proRataContractInfo :null,
            pdfModelIsOpen: false,
            loadPanelVisible: false
        }
    }
    // componentDidMount = () => {
    //     this.getInvoiceList();
    // }

    showModal = (data) => {
        this.setState({ pdfModelIsOpen: true, invoiceId: data.id, invoiceTypeId: data.invoiceTypeId, soNo: data.orderNumber, customerId: data.customerId })
        setTimeout(() => {
            this.getInvoiceList();
        }, 500);
    }
    getInvoiceList = async () => {
        this.setState({
            loadPanelVisible: true
        });
        try {
            var request = {}
            request.InvoiceId = this.state.invoiceId;
            request.InvoiceTypeId = this.state.invoiceTypeId;
            request.CustomerId = this.state.customerId;
            request.SONo = this.state.soNo;
            const requestParams = getRequestParams("POST", request);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'invoice/getInvoicePDfDetails', requestParams);
            let data = await response.json();
            debugger;
            this.setState({
                invoiceAccountDetails: data.invoiceAccountDetails[0],
                adminAccount: data.account[0],
                invoiceDetails: data.invoiceDetails,
                grossAmount: data.grossAmount,
                totalAmount: data.totalAmount,
                taxList: data.taxConfiguration,
                taxamount: data.taxamount,
                wordTotalAmount: data.wordTotalAmount,
                loadPanelVisible: false,
                vehicleDeviceList: data.customerVehicleList,
                proRataContractInfo :data.proRataContractInfo.length  !== 0?data.proRataContractInfo[0]:null
              
            })
        } catch (err) {
            console.log("Error in " + this.displayName + ".getInvoiceList function", err);
            this.setState({
                loadPanelVisible: false
            });


        }
    }
    printDocument = () => {
        
        console.log(this.state.invoiceAccountDetails.name);
        var name = this.state.invoiceAccountDetails.name + ' ' + "Invoice";
        const input = document.getElementById("divToPrint2");
        const pdf = new jsPDF('p', 'mm', 'a4'); //unit: "px", format: "letter", userUnit: "px"
        pdf.html(input, { html2canvas: { scale: 0.19 } }).then(() => {
            pdf.save(name + ".pdf");
        });
        // scale: 0.10

    }
    setComponentRef = (ref) => {
        this.componentRef = ref;
    };

    reactToPrintContent = () => {
        return this.componentRef;
    };
    reactToPrintTrigger = () => {
        return <button className='btn btn-success'>Print Invoice</button>;
    };
    closePdfModel = () => {
        this.setState({ pdfModelIsOpen: false })
    }


    render() {
        return (
            <Fragment>
                <Modal ariaHideApp={false}
                    isOpen={this.state.pdfModelIsOpen}
                    style={customStyles}
                    className={"react-modal"}
                    //onAfterOpen={this.handleAssignDataToState}
                    onRequestClose={this.closeHsnModal}
                    shouldCloseOnOverlayClick={false}
                    contentLabel="Example Modal">

                    <div className="modal-header">
                        <h6 className="modal-title">Invoice</h6>
                    </div>
                    <div className="modal-body" style={{ height: "500px", overflow: "auto" }}>
                        <div id="divToPrint2">

                            {this.state.invoiceAccountDetails !== null ?
                                <table cellPadding={20} cellSpacing={0} border="0" className='inv-table' align='center'>
                                    <tbody>
                                        <tr>
                                            <td style={{ padding: "0px" }}>
                                                <table cellPadding={10} cellSpacing={0} width={"100%"} border="0" className="logo-company">
                                                    <tbody>
                                                        <tr>
                                                            <td align="left">
                                                                <img src="../../assets/img/report-header-plexitech-logo.png" width="200" className='ml-4' />
                                                            </td>
                                                            <td align="right"><img src="../../assets/img/report-header-sstracking-logo-bg.jpg" width="220" /></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td bgcolor={"orange"} className='pl-2 pb-1 pt-1 text-left'><span>{this.state.adminAccount.phone1}</span> • <span className='inv-email text-left email-font'>{this.state.adminAccount.email}</span> • <span className='inv-email text-left email-font'>{this.state.adminAccount.website}</span></td>
                                        </tr>
                                        <tr>
                                            <td>    
                                                <table cellPadding={0} cellSpacing={0} width={"100%"} border="0">
                                                    <tr>
                                                        <td className='align-top' width={"60%"}>
                                                            <div className='inv-cust-details'>
                                                                <div className='inv-lbl font-weight-bold text-orange'>INVOICE TO</div>
                                                                <div className='inv-cust-name font-weight-bold'>{this.state.invoiceAccountDetails.name}</div>
                                                                <div className='inv-address'>{this.state.invoiceAccountDetails.billingAddress1} <span className='inv-city'>{this.state.invoiceAccountDetails.billingState}-{this.state.invoiceAccountDetails.billingPostalCode}</span></div>
                                                                <table cellPadding={0} cellSpacing={0} width={"100%"} border="0">
                                                                    <tr>
                                                                        <td align="left">Contact</td>
                                                                        <td align="left" className="font-weight-bold"><span className='inv-contact-per'>{this.state.invoiceAccountDetails.contactPerson}.</span></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td align="left">Phone</td>
                                                                        <td align="left" className="font-weight-bold"><span className='inv-contact'>{this.state.invoiceAccountDetails.phone1}</span></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td align="left">Email</td>
                                                                        <td align="left" className="font-weight-bold"><span className='inv-email'>{this.state.invoiceAccountDetails.email}</span></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td align="left">GST No.</td>
                                                                        <td align="left" className="font-weight-bold"><span className='inv-email'>{this.state.invoiceAccountDetails.gstNo}</span></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td align="left">PAN No.</td>
                                                                        <td align="left" className="font-weight-bold"><span className='inv-email'>{this.state.invoiceAccountDetails.panNo}</span></td>
                                                                    </tr>
                                                                </table>
                                                            </div>
                                                        </td>
                                                        <td className='align-top'>
                                                            <div className='inv-inv-details'>
                                                                <table cellPadding={0} cellSpacing={0} width={"100%"} border="0">
                                                                    <tr>
                                                                        <td colSpan={2} align="left">
                                                                            <div className='inv-lbl font-weight-bold text-orange'>TAX INVOICE DETAIL</div>
                                                                        </td>   </tr>
                                                                    <tr>
                                                                        <td align="left" width={"160px"}>Invoice No.</td>
                                                                        <td align="left" className="font-weight-bold">{this.state.invoiceAccountDetails.invoiceNo}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td align="left">Invoice Date</td>
                                                                        <td align="left" className="font-weight-bold">{formatDate(this.state.invoiceAccountDetails.invoiceDate, 'dd/MM/yyyy')}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td align="left">Purchase Order No.</td>
                                                                        <td align="left" className="font-weight-bold">{this.state.invoiceAccountDetails.referenceSONo === "" ? this.state.invoiceAccountDetails.orderNumber : this.state.invoiceAccountDetails.referenceSONo}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td align="left">Purchase Order Date</td>
                                                                        {this.state.invoiceAccountDetails.orderDate !== null ?
                                                                            <td align="left" className="font-weight-bold"> {formatDate(this.state.invoiceAccountDetails.orderDate, 'dd/MM/yyyy')}</td> :
                                                                            <td align="left" className="font-weight-bold"></td>}
                                                                    </tr>
                                                                    <tr>
                                                                        <td align="left">Representative</td>
                                                                        <td align="left" className="font-weight-bold">{this.state.invoiceAccountDetails.fullName}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td align="left">Bill Period</td>
                                                                        <td align="left" className="font-weight-bold">{formatDate(this.state.invoiceAccountDetails.contractStart, 'dd/MM/yyyy')} to {formatDate(this.state.invoiceAccountDetails.contractEnd, 'dd/MM/yyyy')}</td>
                                                                    </tr> </table>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td height={"670px"} valign='top'>
                                                <table className='inv-item-table mt-1' cellPadding={0} cellSpacing={0} width={"100%"} border="0">
                                                    <thead>
                                                        <tr>
                                                            <th>Item Name</th>
                                                            <th>Item Description</th>
                                                            <th>HSN/SAC Code</th>
                                                            <th width="80px">Quantity</th>
                                                            <th className='text-right'>Rate/Unit</th>
                                                            <th className='text-right'>Amount</th>
                                                            <th className='text-right'>Billig Period</th>
                                                        </tr>
                                                    </thead>

                                                    <tbody>
                                                        { this.state.invoiceDetails.length == 1?
                                                             this.state.invoiceDetails.map((item, i) => {
                                                                return (
                                                                    <tr>
                                                                        <td>{item.itemName}</td>
                                                                        <td>{item.description}</td>
                                                                        <td>{item.hsnsacCode}</td>
                                                                         <td>{item.qty}</td>
                                                                        <td className='text-right'>{item.price}</td>
                                                                        <td className='text-right'>{item.totalAmount}.00</td>
                                                                        <td className='text-right'>{formatDate(this.state.invoiceAccountDetails.contractStart, 'dd/MM/yyyy')} to {formatDate(this.state.invoiceAccountDetails.contractEnd, 'dd/MM/yyyy')}</td>
                                                                    </tr>
                                                                );
                                                            }) :
                                                            this.state.invoiceDetails.map((item, i) => {
                                                                return (
                                                                    <tr>
                                                                      <td>{item.itemName}</td>
                                                                        <td>{item.description}</td>
                                                                        <td>{item.hsnsacCode}</td>
                                                                         <td>{item.qty}</td>
                                                                        <td className='text-right'>{item.price}</td>
                                                                        <td className='text-right'>{item.totalAmount}.00</td>
                                                                        {/* <td className='text-right'>{this.state.proRataContractInfo !== null ? i=== 0 ? formatDate(this.state.invoiceAccountDetails.contractStart, 'dd/MM/yyyy') + " " + "to" + " " + formatDate(this.state.invoiceAccountDetails.contractEnd, 'dd/MM/yyyy'):formatDate(this.state.proRataContractInfo.billingStartDate, 'dd/MM/yyyy') + " " + "to" + " " + formatDate(this.state.proRataContractInfo.billingEndDate, 'dd/MM/yyyy') : ""}</td> */}
                                                                    </tr>
                                                                    
                                                                );
                                                            }) 
                                                        }
                                                   
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>
                                                <table cellPadding={0} cellSpacing={0} width={"100%"} border="0" className='inv-amt-table'>
                                                    <tr>
                                                        <td valign='top'>
                                                            <div className='p-1' style={{ backgroundColor: "#f0f0f0" }}>
                                                                In Words: <span className='font-weight-bold'>{this.state.wordTotalAmount} Only</span>
                                                            </div>
                                                            <div class="inv-lbl font-weight-bold text-orange mt-2">PAYMENT INFO</div>
                                                            <table cellSpacing={0} cellPadding={0} border={0} width={"100%"} className='inv-bank-table small'>
                                                                <tr>
                                                                    <td width={"140px"}>Favour of</td>
                                                                    <td><span className='font-weight-bold'>PLEXITECH SOLUTIONS LLP</span></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Bank A/C No.</td>
                                                                    <td><span className='font-weight-bold'>50200018713085</span></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Bank Name & Addres</td>
                                                                    <td><span className='font-weight-bold'>HDFC Bank Ltd. Near CST VT, Mumbai - 400001, Maharashtra, India</span></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>RTGS/NEFT/IFSC</td>
                                                                    <td><span className='font-weight-bold'>HDFC0000355</span></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>PAN No.</td>
                                                                    <td><span className='font-weight-bold'>{this.state.adminAccount.panNo}</span></td>
                                                                </tr>
                                                            </table>
                                                        </td>
                                                        <td valign='top' width="200px">
                                                            <table cellSpacing={0} cellPadding={0} border={0} width={"100%"} className='inv-total-table'>
                                                                <tr>
                                                                    <td className='text-right'>Sub Total</td>
                                                                    <td className='text-right font-weight-bold'>{this.state.grossAmount}.00</td>
                                                                </tr>

                                                                {this.state.taxList.map((item, i) => {
                                                                    return (
                                                                        <tr>
                                                                            <td className='text-right'>{item.taxName} @{item.percentage}%</td>
                                                                            <td className='text-right font-weight-bold'>{this.state.taxamount[0]}</td>
                                                                        </tr>
                                                                    )
                                                                })}

                                                                <tr>
                                                                    <td className='text-right'>R/OFF</td>
                                                                    <td className='text-right font-weight-bold'>-</td>
                                                                </tr>
                                                                <tr>
                                                                    <td className='text-right font-weight-bold text-white' style={{ backgroundColor: "#4f5356", color: "#ffffff" }}>Grand Total</td>
                                                                    <td className='text-right font-weight-bold text-orange' style={{ backgroundColor: "#4f5356", color: "#ffffff" }}>{this.state.totalAmount}.00</td>
                                                                </tr>
                                                            </table>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className='pt-4 pb-4'>
                                                <table cellPadding={0} cellSpacing={0} width={"100%"} border="0">
                                                    <tr>
                                                        <td width={"60%"}>
                                                            <div className='inv-declaration'>
                                                                <div className='font-weight-bold'>PLEXITECH SOLUTIONS LLP</div>
                                                                <div>GST No. - <span className='font-weight-bold'>{this.state.adminAccount.gstNo}</span> | PAN No. - <span className='font-weight-bold'>{this.state.adminAccount.panNo}</span></div>

                                                                {/* <div className='font-weight-bold mt-3'>Declaration</div> */}
                                                                <hr></hr>
                                                                <p className='small text-justify mb-0 pb-0'>"I/We hereby certify that my/our registration certificate under the Maharashtra Goods & Service Tax is in force on the date on which the sale of the goods specified in this Tax Invoice is made by me/us and that the transaction of sale covered by the Tax Invoice has been effected by me/us and it shall be accounted for in the turnover of sales while filing of return and the due tax' or shall be paid."" if any' payable on the sales has been paid."</p>
                                                            </div>
                                                        </td>
                                                        <td className='text-right'>
                                                            <div className='font-weight-bold'>For PlexiTech Solutions LLP</div>
                                                            <div><img src="../../assets/img/logo-stamp-psllp.jpg" width="80" /></div>
                                                            <div>Authorised Signatory</div>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className='text-center p-0'>
                                                <div>Subject to Mumbai Jurisdiction</div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td bgcolor="#f0f0f0">
                                                <table>
                                                    <tr>
                                                        <td width={"33%"}>
                                                            <span className='font-weight-bold'>Sales Office</span><br></br>Gala No.B 29, Jai Mata Di Compound, Kalher, Bhiwandi. Taluka - Thane, District - Thane. Maharashtra. India.
                                                        </td>
                                                        <td width={"33%"} className='text-center'><img src="../../assets/img/UKAS-Management-Systems.jpg" width="50" /></td>
                                                        <td width={"33%"}>
                                                            <div><span className='font-weight-bold'>Registered Office</span><br></br>{this.state.adminAccount.billingAddress1}</div>

                                                        </td>
                                                    </tr>
                                                </table>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className='text-center p-0'>
                                                <div className='small'>This is a Computer Generated Invoice</div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>



                                : ""}
                                 {this.state.vehicleDeviceList !== null ?
                            <table cellPadding={20} cellSpacing={0} border="0" width={"100%"} className='inv-table mt-5' align='center' >
                                <tbody>
                                    <tr>
                                        <td style={{ padding: "0px", borderBottom: '13px solid orange' }}>
                                            <table cellPadding={10} cellSpacing={0} width={"100%"} border="0" className='logo-company'>
                                                <tbody>
                                                    <tr>
                                                        <td align="left">
                                                            <img src="../../assets/img/report-header-plexitech-logo.png" width="200" className='ml-4' />
                                                        </td>
                                                        <td align="right"><img src="../../assets/img/report-header-sstracking-logo-bg.jpg" width="220" /></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>

                                    {this.state.vehicleDeviceList != null ?
                                        <tr>
                                            <td valign='top'>
                                                <table className='inv-item-table mt-1' cellPadding={0} cellSpacing={0} width={"100%"} border="0">
                                                    <thead>
                                                        <tr>
                                                            <th width="80px">Sr. No.</th>
                                                            <th width="170px">Device ID</th>
                                                            <th>Vehicle Number</th>
                                                        </tr>
                                                    </thead>

                                                    <tbody>
                                                        {this.state.vehicleDeviceList.map((item, i) => {
                                                            return (
                                                                <tr>
                                                                    <td>{i+1}</td>
                                                                    <td>{item.serialNo}</td>
                                                                    <td>{item.vehicleNo}</td>
                                                                </tr>
                                                            );
                                                        })}

                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr> : null}
                                </tbody>
                            </table> : ""}
                        </div>
                        <div id="divToPrint" style={{ display: "none" }}>
                            <DetailTemplate ref={this.setComponentRef} dataSource={this.state} />
                        </div>

                    </div>

                    <div className="modal-footer">
                        <button id='button-pdf' className='btn btn-success' onClick={this.printDocument}>Export to PDF</button>

                        <ReactToPrint
                            content={this.reactToPrintContent}
                            documentTitle="Invoice"
                            removeAfterPrint
                            trigger={this.reactToPrintTrigger}
                        />
                        <button id='button-pdf' className='btn btn-outline-secondary' title='Close' onClick={this.closePdfModel}>Close</button>

                    </div>
                </Modal>
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={position}
                    visible={this.state.loadPanelVisible}
                    showIndicator={true}
                    shading={true}
                    showPane={true}
                    hideOnOutsideClick={false}
                />
            </Fragment>
        )
    }
}
class DetailTemplate extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            invoiceId: props.dataSource.invoiceId === undefined ? 0 : props.dataSource.invoiceId,
            invoiceTypeId: props.dataSource.invoiceTypeId === undefined ? 0 : props.dataSource.invoiceTypeId,
            soNo: props.dataSource.soNo === undefined ? '' : props.dataSource.soNo,
            customerId: props.dataSource.customerId === undefined ? 0 : props.dataSource.customerId,
            invoiceAccountDetails: null,
            adminAccount: null,
            invoiceDetails: null,
            grossAmount: 0,
            totalAmount: 0,
            taxList: null,
            taxamount: null,
            wordTotalAmount: "",
            vehicleDeviceList: null,
            proRataContractInfo :null
        }
    }
    async componentDidMount() {
        await this.getInvoiceList();
    }
    getInvoiceList = async () => {
        this.setState({
            loadPanelVisible: true
        });
        try {
            var request = {}
            request.InvoiceId = this.state.invoiceId;
            request.InvoiceTypeId = this.state.invoiceTypeId;
            request.CustomerId = this.state.customerId;
            request.SONo = this.state.soNo;
            
            const requestParams = getRequestParams("POST", request);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'invoice/getInvoicePDfDetails', requestParams);
            let data = await response.json();
            
            this.setState({
                invoiceAccountDetails: data.invoiceAccountDetails[0],
                adminAccount: data.account[0],
                invoiceDetails: data.invoiceDetails,
                grossAmount: data.grossAmount,
                totalAmount: data.totalAmount,
                taxList: data.taxConfiguration,
                taxamount: data.taxamount,
                wordTotalAmount: data.wordTotalAmount,
                vehicleDeviceList: data.customerVehicleList,
                proRataContractInfo :data.proRataContractInfo[0]
            })
        } catch (err) {
            console.log("Error in " + this.displayName + ".getInvoiceList function", err);
            this.setState({
                loadPanelVisible: false
            });


        }
    }



    render() {
        return (
            <Fragment>
                <div id="divToPrint2">

                    {this.state.invoiceAccountDetails !== null ?
                        <table cellPadding={20} cellSpacing={0} width={"100%"} border="0" className='inv-table' align='center'>
                            <tbody>
                                <tr>
                                    <td style={{ padding: "0px" }}>
                                        <table cellPadding={10} cellSpacing={0} width={"100%"} border="0" className='logo-company'>
                                            <tbody>
                                                <tr>
                                                    <td align="left">
                                                        <img src="../../assets/img/report-header-plexitech-logo.png" width="200" className='ml-4' />
                                                    </td>
                                                    <td align="right"><img src="../../assets/img/report-header-sstracking-logo-bg.jpg" width="220" /></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>

                                <tr>
                                    <td bgcolor={"orange"} className='pl-2 pb-1 pt-1'><div>{this.state.adminAccount.phone1} •{this.state.adminAccount.email} •{this.state.adminAccount.website}</div></td>
                                </tr>
                                <tr>
                                    <td>
                                        <table cellPadding={0} cellSpacing={0} width={"100%"} border="0">
                                            <tr>
                                                <td className='align-top' width={"60%"}>
                                                    <div className='inv-cust-details'>
                                                        <div className='inv-lbl font-weight-bold text-orange'>INVOICE TO</div>
                                                        <div className='inv-cust-name font-weight-bold'>{this.state.invoiceAccountDetails.name}</div>
                                                        <div className='inv-address'>{this.state.invoiceAccountDetails.billingAddress1}<span className='inv-city'>{this.state.invoiceAccountDetails.billingState}-{this.state.invoiceAccountDetails.billingPostalCode}</span></div>
                                                        <table cellPadding={0} cellSpacing={0} width={"100%"} border="0">
                                                            <tr>
                                                                <td align="left">Contact</td>
                                                                <td align="left" className="font-weight-bold"><span className='inv-contact-per'>{this.state.invoiceAccountDetails.contactPerson}.</span></td>
                                                            </tr>
                                                            <tr>
                                                                <td align="left">Phone</td>
                                                                <td align="left" className="font-weight-bold"><span className='inv-contact'>{this.state.invoiceAccountDetails.phone1}</span></td>
                                                            </tr>
                                                            <tr>
                                                                <td align="left">Email</td>
                                                                <td align="left" className="font-weight-bold"><span className='inv-email'>{this.state.invoiceAccountDetails.email}</span></td>
                                                            </tr>
                                                            <tr>
                                                                <td align="left">GST No.</td>
                                                                <td align="left" className="font-weight-bold"><span className='inv-email'>{this.state.invoiceAccountDetails.gstNo}</span></td>
                                                            </tr>
                                                            <tr>
                                                                <td align="left">PAN No.</td>
                                                                <td align="left" className="font-weight-bold"><span className='inv-email'>{this.state.invoiceAccountDetails.panNo}</span></td>
                                                            </tr>

                                                        </table>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className='inv-inv-details'>
                                                        <table cellPadding={0} cellSpacing={0} width={"100%"} border="0">
                                                            <tr>
                                                                <td colSpan={2} align="left">
                                                                    <div className='inv-lbl font-weight-bold text-orange'>TAX INVOICE DETAIL</div>
                                                                </td>   </tr>
                                                            <tr>
                                                                <td align="left" width={"160px"}>Invoice No.</td>
                                                                <td align="left" className="font-weight-bold">{this.state.invoiceAccountDetails.invoiceNo}</td>
                                                            </tr>
                                                            <tr>
                                                                <td align="left">Invoice Date</td>
                                                                <td align="left" className="font-weight-bold">{formatDate(this.state.invoiceAccountDetails.invoiceDate, 'dd/MM/yyyy')}</td>
                                                            </tr>
                                                            <tr>
                                                                <td align="left">Purchase Order No.</td>
                                                                <td align="left" className="font-weight-bold">{this.state.invoiceAccountDetails.referenceSONo === "" ? this.state.invoiceAccountDetails.orderNumber : this.state.invoiceAccountDetails.referenceSONo}</td>
                                                            </tr>
                                                            <tr>
                                                                <td align="left">Purchase Order Date</td>
                                                                {this.state.invoiceAccountDetails.orderDate !== null ?
                                                                    <td align="left" className="font-weight-bold"> {formatDate(this.state.invoiceAccountDetails.orderDate, 'dd/MM/yyyy')}</td> :
                                                                    <td align="left" className="font-weight-bold"></td>}
                                                            </tr>
                                                            <tr>
                                                                <td align="left">Representative</td>
                                                                <td align="left" className="font-weight-bold">{this.state.invoiceAccountDetails.fullName}</td>
                                                            </tr>
                                                            <tr>
                                                                <td align="left">Bill Period</td>
                                                                <td align="left" className="font-weight-bold">{formatDate(this.state.invoiceAccountDetails.contractStart, 'dd/MM/yyyy')} to {formatDate(this.state.invoiceAccountDetails.contractEnd, 'dd/MM/yyyy')}</td>
                                                            </tr> </table>
                                                    </div>
                                                </td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>
                                <tr>
                                    <td height={"420px"} valign='top'>
                                        <table className='inv-item-table mt-1' cellPadding={0} cellSpacing={0} width={"100%"} border="0">
                                            <thead>
                                                <tr>
                                                   
                                                    <th>Item Name</th>
                                                    <th>Item Description</th>
                                                    <th>HSN/SAC Code</th>
                                                    <th width="80px">Quantity</th>
                                                    <th align="right">Rate/Unit</th>
                                                    <th align="right">Amount</th>
                                                <th className='text-right'>Billig Period</th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                            { this.state.invoiceDetails.length == 1?
                                                             this.state.invoiceDetails.map((item, i) => {
                                                                return (
                                                                    <tr>
                                                                        <td>{item.itemName}</td>
                                                                        <td>{item.description}</td>
                                                                        <td>{item.hsnsacCode}</td>
                                                                         <td>{item.qty}</td>
                                                                        <td className='text-right'>{item.price}</td>
                                                                        <td className='text-right'>{item.totalAmount}.00</td>
                                                                        <td className='text-right'>{formatDate(this.state.invoiceAccountDetails.contractStart, 'dd/MM/yyyy')} to {formatDate(this.state.invoiceAccountDetails.contractEnd, 'dd/MM/yyyy')}</td>
                                                                    </tr>
                                                                );
                                                            }) :
                                                            this.state.proRataContractInfo == null ? 
                                                            this.state.invoiceDetails.map((item, i) => {
                                                                return (
                                                                    <tr>
                                                                      <td>{item.itemName}</td>
                                                                        <td>{item.description}</td>
                                                                        <td>{item.hsnsacCode}</td>
                                                                         <td>{item.qty}</td>
                                                                        <td className='text-right'>{item.price}</td>
                                                                        <td className='text-right'>{item.totalAmount}.00</td>
                                                                     {/* <td className='text-right'>{this.state.proRataContractInfo !== null ? i=== 0 ? formatDate(this.state.invoiceAccountDetails.contractStart, 'dd/MM/yyyy') + " " + "to" + " " + formatDate(this.state.invoiceAccountDetails.contractEnd, 'dd/MM/yyyy'):formatDate(this.state.proRataContractInfo.billingStartDate, 'dd/MM/yyyy') + " " + "to" + " " + formatDate(this.state.proRataContractInfo.billingEndDate, 'dd/MM/yyyy') : ""}</td>  */}
                                                                    </tr>
                                                                    
                                                                );
                                                            }) :""
                                                        }
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                                <tr>

                                    <td>
                                        <table cellPadding={0} cellSpacing={0} width={"100%"} border="0" className='inv-amt-table'>
                                            <tr>
                                                <td valign='top'>
                                                    <div className='p-1' style={{ backgroundColor: "#f0f0f0" }}>
                                                        In Words: <span className='font-weight-bold'>{this.state.wordTotalAmount} Only</span>
                                                    </div>
                                                    <div class="inv-lbl font-weight-bold text-orange mt-2">PAYMENT INFO</div>
                                                    <table cellSpacing={0} cellPadding={0} border={0} width={"100%"} className='inv-bank-table small'>
                                                        <tr>
                                                            <td width={"140px"}>Favor of</td>
                                                            <td><span className='font-weight-bold'>PLEXITECH SOLUTIONS LLP</span></td>
                                                        </tr>
                                                        <tr>
                                                            <td>Bank A/C No.</td>
                                                            <td><span className='font-weight-bold'>50200018713085</span></td>
                                                        </tr>
                                                        <tr>
                                                            <td>Bank Name & Addres</td>
                                                            <td><span className='font-weight-bold'>HDFC Bank Ltd. Near CST VT, Mumbai - 400001, Maharashtra, India</span></td>
                                                        </tr>
                                                        <tr>
                                                            <td>RTGS/NEFT/IFSC</td>
                                                            <td><span className='font-weight-bold'>HDFC0000355</span></td>
                                                        </tr>
                                                        <tr>
                                                            <td>PAN No.</td>
                                                            <td><span className='font-weight-bold'>ACOFS3339N</span></td>
                                                        </tr>
                                                    </table>
                                                </td>
                                                <td valign='top' width="250px">
                                                    <table cellSpacing={0} cellPadding={0} border={0} width={"100%"} className='inv-total-table'>
                                                        <tr>
                                                            <td className='text-right'>Sub Total</td>
                                                            <td className='text-right font-weight-bold'>{this.state.grossAmount}.00</td>
                                                        </tr>

                                                        {this.state.taxList.map((item, i) => {
                                                            return (
                                                                <tr>
                                                                    <td className='text-right'>{item.taxName} @{item.percentage}%</td>
                                                                    <td className='text-right font-weight-bold'>{this.state.taxamount[0]}</td>
                                                                </tr>
                                                            )
                                                        })}

                                                        <tr>
                                                            <td className='text-right'>R/OFF</td>
                                                            <td className='text-right font-weight-bold'>-</td>
                                                        </tr>
                                                        <tr>
                                                            <td className='text-right font-weight-bold text-white' style={{ backgroundColor: "#4f5356", color: "#ffffff" }}>Grand Total</td>
                                                            <td className='text-right font-weight-bold text-orange' style={{ backgroundColor: "#4f5356", color: "#ffffff" }}>{this.state.totalAmount}.00</td>
                                                        </tr>
                                                    </table>
                                                </td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='pt-4 pb-4'>
                                        <table cellPadding={0} cellSpacing={0} width={"100%"} border="0">
                                            <tr>
                                                <td width={"60%"}>
                                                    <div className='inv-declaration'>
                                                        <div className='font-weight-bold'>PLEXITECH SOLUTIONS LLP</div>
                                                        <div>GST No. - <span className='font-weight-bold'>{this.state.adminAccount.gstNo}</span> | PAN No. - <span className='font-weight-bold'>{this.state.adminAccount.panNo}</span></div>

                                                        {/* <div className='font-weight-bold mt-3'>Declaration</div> */}
                                                        <hr></hr>
                                                        <p className='small text-justify mb-0 pb-0'>"I/We hereby certify that my/our registration certificate under the Maharashtra Goods & Service Tax is in force on the date on which the sale of the goods specified in this Tax Invoice is made by me/us and that the transaction of sale covered by the Tax Invoice has been effected by me/us and it shall be accounted for in the turnover of sales while filing of return and the due tax' or shall be paid."" if any' payable on the sales has been paid."</p>
                                                    </div>
                                                </td>
                                                <td className='text-right'>
                                                    <div className='font-weight-bold'>For Plexitech Solutions LLP</div>
                                                    <div><img src="../../assets/img/logo-stamp-psllp.jpg" width="80" /></div>
                                                    <div>Authorised Signatory</div>
                                                </td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='text-center p-0'>
                                        <div>Subject to Mumbai Jurisdiction</div>
                                    </td>
                                </tr>
                                <tr>
                                    <td bgcolor="#f0f0f0">
                                        <table>
                                            <tr>
                                                <td width={"33%"}>
                                                    <span className='font-weight-bold'>Sales Office</span><br></br>Gala No.B 29, Jai Mata Di Compound, Kalher, Bhiwandi. Taluka - Thane, District - Thane. Maharashtra. India.
                                                </td>
                                                <td width={"33%"} className='text-center'><img src="../../assets/img/UKAS-Management-Systems.jpg" width="50" /></td>
                                                <td width={"33%"}>
                                                    <div><span className='font-weight-bold'>Registered Office</span><br></br>{this.state.adminAccount.billingAddress1}</div>

                                                </td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='text-center p-0'>
                                        <div className='small'>This is a Computer Generated Invoice</div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        : ""}
                    {this.state.vehicleDeviceList !== null ?

                        <table className='inv-item-table mt-4' cellPadding={0} cellSpacing={0} width={"100%"} border="0">
                            <thead>
                                <tr>
                                    <th width="80px">Sr. No.</th>
                                    <th width="200px">Device ID</th>
                                    <th>Vehicle Number</th>
                                </tr>
                            </thead>

                            <tbody>
                                {this.state.vehicleDeviceList.map((item, i) => {
                                    return (
                                        <tr>
                                            <td>{i + 1}</td>
                                            <td>{item.serialNo}</td>
                                            <td>{item.vehicleNo}</td>

                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                        : null}

                </div>
            </Fragment>
        );
    }
}

export default DetailTemplate;