import React, { Fragment, Component } from 'react';
import DataGrid, {
    Column,
    Export, Grouping,
    GroupPanel,
    HeaderFilter, Pager,
    Paging,
    SearchPanel,
    SortByGroupSummaryInfo
} from 'devextreme-react/data-grid'; //npm i devextreme-react
import Modal from 'react-modal'; //npm install --save react-modal;
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import { getRequestParams,formatDate } from '../../helpers/globalfunctions.js';
import { alphanumericValidation } from '../../helpers/globalvalidations.js';
import { exportFormats,currencySymbolFormats } from '../../helpers/fixcodes.js';
import { LoadPanel } from 'devextreme-react/load-panel';
import Swal from 'sweetalert2'; //npm i sweetalert2 --save
import { toast } from 'react-toastify'; //npm i react-toastify --save
import { SelectBox } from 'devextreme-react/select-box';
import TextArea from 'devextreme-react/text-area';
import DateBox from 'devextreme-react/date-box';
import NumberBox from 'devextreme-react/number-box';
import { jsPDF } from 'jspdf';
import { exportDataGrid } from 'devextreme/pdf_exporter';

const position = { of: '#historydiv' };

var basedOnListObject = [
    { id: 1, name: "Device" }
    ,{id: 2, name: "Visit" }
]

const customStyles = {
    content: {
        width: '30%',
    }
}

export class ServiceComponent extends Component {
    static displayName = ServiceComponent.name;

    constructor(props) {
        super(props);

        this.state = {
            isSubmited: false,
            loadPanelVisible: false,
            serviceModelIsOpen: false,
            servicesource: [],
            basedOnList: basedOnListObject,
            paymentTypeList: [],
            singelData: null,
            id: 0,
            name: "",
            description: "",
            basedOnUnitId: 0,
            basedOnUnitName: "",
            termTypeId:0,
            termType:"",
            unitPrice:0,
            limit:"",
            wefDate: new Date(),            
            createdOn: new Date(),
            updatedOn: new Date(),
            sessionUser: JSON.parse(sessionStorage.getItem("sessionUser")),
            sacCodelist :[],
            sacCode :"",
            errors: {
                name: "",
                description: "",
                basedOnUnitId: 0,
                termTypeId: 0,
                unitPrice: 0,
                limit:"",
                wefDate: new Date(),
                sacCode :"",
            }
        }
        this.handleServiceSubmit = this.handleServiceSubmit.bind(this);
    }

    async componentDidMount() {        
        await this.getPaymentTypeMaster();
        await this.getServiceMaster();
        await this.getHsnCodeMaster();
    }

    getHsnCodeMaster = async () => {
        try {
            const requestParams = getRequestParams("GET", null);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'hsnsacCode/hscacccodes/0' , requestParams);
            let data = await response.json();
            
            if (data.payload != null) {
                data = JSON.parse(JSON.stringify(data.payload));
                let hsnList = data.filter(item => item.type == 'SAC');
                this.setState({
                    sacCodelist: hsnList
                });
            }
            else{
                this.setState({
                    sacCodelist: null
                });
            }
            
        } catch (err) {
            console.log("Error in " + this.displayName + ".getHsnCodeMaster function", err);
            this.setState({
                loadPanelVisible: false
            });
        }
    }

    getServiceMaster = async () => {
        this.setState({
            loadPanelVisible: true
        });
        try {
            var request = {};
            const requestParams = getRequestParams("GET", request);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'master/services/' + this.state.id, requestParams);
            const data = await response.json();
            this.setState({
                servicesource: data.payload,
                loadPanelVisible: false
            });
        } catch (err) {
            console.log("Error in " + this.displayName + ".getServiceMaster function", err);
            this.setState({
                loadPanelVisible: false
            });
        }
    }

    getPaymentTypeMaster = async () => {
        try {
            var request = {};
            const requestParams = getRequestParams("GET", request);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'paymentTermType/dropdownType', requestParams);
            const data = await response.json();
            this.setState({
                paymentTypeList: data.payload != null ? data.payload : []
            });
        } catch (err) {
            console.log("Error in " + this.displayName + ".getPaymentTypeMaster function", err);
        }
    }

    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift({
            location: 'after',
            widget: 'dxButton',
            options: {
                icon: 'refresh',
                onClick: this.getServiceMaster.bind(this)
            }
        });
    }

    onExporting = (e) => {
        const doc = new jsPDF();
        if (e.format === "pdf") {
            exportDataGrid({
                jsPDFDocument: doc,
                component: e.component,
                columnWidths: 50,
               // indent: 10,
            }).then(() => {
                doc.save(e.fileName);
            });            
        }
    }

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        let errors = this.state.errors;

        //const alphanumericValidation = /^[A-Za-z0-9]*$/;
        switch (name) {
            case 'Name':
                if (alphanumericValidation.test(event.target.value))
                    this.setState({ name: event.target.value });
                break;
            case 'Description':
                if (alphanumericValidation.test(event.target.value))
                    this.setState({ description: event.target.value });
                break;
            case 'Limit':
                if (alphanumericValidation.test(event.target.value))
                    this.setState({ limit: event.target.value });
                break;
            default:
                this.setState({ [name]: value });
                break;
        }

        errors[name] = "";
        this.setState({
            errors: errors
        });
    }

    handleShippingDateChanged = (date) => {
        this.setState({ wefDate: date.value });
    }

    handleAttributeModal = (e) => {
        this.setState({
            serviceModelIsOpen: true,
            singelData: null
        });
    }

    closeServiceModal = () => {
        this.setState({
            isSubmited: false,
            loadPanelVisible: false,
            serviceModelIsOpen: false,
            singelData: null,
            id: 0,
            name: "",
            description: "",
            basedOnUnitId: 0,
            basedOnUnitName: "",
            termTypeId:0,
            termType:"",
            unitPrice:0,
            limit:"",
            wefDate: new Date(),            
            createdOn: new Date(),
            updatedOn: new Date(),
            sessionUser: JSON.parse(sessionStorage.getItem("sessionUser")),
            sacCode : "",
            errors: {
                name: "",
                description: "",
                basedOnUnitId: 0,
                termTypeId: 0,
                unitPrice: 0,
                limit:"",
                wefDate: new Date(),
                sacCode : "",
            }
        });
    }

    handleValidation = () => {
        let formIsValid = true;
        try {
            let errors = this.state.errors;
            errors.name = "";
            errors.basedOnUnitId = "";
            errors.termTypeId = "";
            errors.limit = "";
            errors.unitPrice = "";

            if (this.state.name.trim() === "") {
                formIsValid = false;
                errors.name = "Please enter service name.";
            }
            if (this.state.basedOnUnitId === 0) {
                formIsValid = false;
                errors.basedOnUnitId = "Please select service based on.";
            }
            if (this.state.termTypeId === 0) {
                formIsValid = false;
                errors.termTypeId = "Please select term type.";
            }
            if (this.state.limit.trim() === "") {
                formIsValid = false;
                errors.limit = "Please enter limit.";
            }
            if (this.state.unitPrice === 0) {
                formIsValid = false;
                errors.unitPrice = "Please enter unit price.";
            }
            if (this.state.sacCode.trim() === "") {
                formIsValid = false;
                errors.sacCode = "Please select sac code.";
            }
            this.setState({ errors: errors });
        }
        catch (err) {
            console.log("Error in " + this.displayName + ".handleValidation function", err);
            this.setState({ isSubmited: false })
        }
        return formIsValid;
    }

    handleBasedOnChange = (selectedOption) => {
      this.setState({
            basedOnUnitId: (selectedOption.selectedItem !== null ? selectedOption.selectedItem.id : 0)
            ,basedOnUnitName: (selectedOption.selectedItem !== null ? selectedOption.selectedItem.name : "")
        });
        let errors = this.state.errors;
        if(selectedOption.selectedItem !== null){
            errors.basedOnUnitId = "";
            // this.setState({
            //     basedOnUnitId: ""
            // });
        }
    }

    handleSacCodeOnChange = (selectedOption) => {
        this.setState({
              sacCode: (selectedOption.selectedItem !== null ? selectedOption.selectedItem.code : "")
              
          });
          let errors = this.state.errors;
          if(selectedOption.selectedItem !== null){
              errors.sacCode = "";
           }
      }
    handleTermTypeChange = (selectedOption) => {
        this.setState({
            termTypeId: (selectedOption.selectedItem !== null ? selectedOption.selectedItem.id : 0)
            ,termType: (selectedOption.selectedItem !== null ? selectedOption.selectedItem.name : 0)
        });
    }

    handleUnitPriceChange = (event) => {
        const newValue = event.value;
        this.setState({ "unitPrice": newValue });
    }

    handleTextAreaValueChanged = (event) => {
        this.setState({
            description: event.value,
        });
    }

    handleServiceSubmit = async (e) => {
        e.preventDefault();
        try {
            if (this.handleValidation()) {
                this.setState({ isSubmited: true });

                var newObject = {};
                newObject.Name = this.state.name.trim();
                newObject.HsnSacCode =  this.state.sacCode;
                newObject.BasedOnUnitId = parseInt(this.state.basedOnUnitId);
                newObject.BasedOnUnit = this.state.basedOnUnitName;
                newObject.TermTypeId = parseInt(this.state.termTypeId);
                newObject.TermType = this.state.termType;
                newObject.UnitPrice = parseFloat(this.state.unitPrice);
                newObject.Limit = this.state.limit;
                newObject.WefDate = formatDate(this.state.wefDate,"MM/dd/yyyy hh:mm");
                newObject.Description = this.state.description;
                newObject.CreatedBy = parseInt(this.state.sessionUser.id);
                newObject.CreatedOn = formatDate(this.state.createdOn,"MM/dd/yyyy hh:mm");
                newObject.UpdatedBy = parseInt(this.state.sessionUser.id);
                newObject.updatedOn = formatDate(this.state.updatedOn,"MM/dd/yyyy hh:mm");
                const requestParams = getRequestParams('POST', newObject);
                
                const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'master/' + this.state.id + '/service', requestParams);
                const data = await response.json();
                if (data.isSuccess) {
                    Swal.fire({
                        icon: 'success',
                        title: 'Success',
                        text: data.message,
                    }).then((result) => {
                        if (result.isConfirmed) {
                            this.getServiceMaster();
                            this.closeServiceModal();
                        }
                        this.setState({ isSubmited: false })
                    })
                }
                else {
                    toast.error(data.message, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        style: { width: "310px" },
                        hideProgressBar: false,
                        newestOnTop: false,
                        closeButton: false
                    });
                    this.setState({ isSubmited: false })
                }
            }
        }
        catch (err) {
            console.log("Error in " + this.displayName + ".handleServiceSubmit function", err);
            this.setState({ isSubmited: false })
        }
    }

    editServiceItemModal = (e, data) => {
        e.preventDefault();
        if (data != null) {
            this.setState({
                serviceModelIsOpen: true,
                singelData: data,
                id: data.id,
                name: data.name,
                description: data.description,
                basedOnUnitId: data.basedOnUnitId,
                termTypeId: data.termTypeId,
                unitPrice: data.unitPrice,
                limit: data.limit,
                wefDate: data.wefDate,
                sacCode : data.hsnSacCode
            });
        }
    }

    render() {
        const renderGridCell = (data) => {
            return <div>
                <a href="#" className="fas fa-pen" title="Edit" onClick={(e) => { this.editServiceItemModal(e, data.data) }} ></a>&nbsp;&nbsp;
            </div>;
        }

        const renderGridNumberCell = (data) => {
            return <div><small><i class={currencySymbolFormats.currencyFormat}></i></small>&nbsp;&nbsp;{parseFloat(data.value).toFixed(2)}</div>;
        }

        return (
            <Fragment>
                <div className="section-body">
                    <div className='card bg-filter'>
                        <div className='card-body'>
                            <div className="section-header">
                                <h6 class="m-0">Service Item</h6>
                                <div className="section-header-buttons">
                                    <button type="submit" className="btn btn-success" onClick={this.handleAttributeModal}>
                                        New
                                    </button>
                                </div>
                            </div>
                           
                        </div>
                    </div>
                    <div className='card'>
                    <div className='card-body'>
                                <DataGrid
                                    dataSource={this.state.servicesource}
                                    keyExpr="id"
                                    id='gridServiceMasterList'
                                    selection={{ mode: 'single' }}
                                    columnsAutoWidth={false}
                                    showColumnLines={true}
                                    showRowLines={true}
                                    showBorders={true}
                                    rowAlternationEnabled={false}
                                    allowColumnResizing={true}
                                    columnResizingMode='widget'
                                    onExporting={this.onExporting}
                                    onToolbarPreparing={this.onToolbarPreparing}>
                                    <Column dataField="id" width={30} caption="" allowFiltering={false} allowSorting={false} cellRender={renderGridCell} />
                                    <Column dataField="name" caption="Name" allowSearch={true} allowFiltering={false} allowSorting={true} width={200} />
                                    <Column dataField="basedOnUnit" caption="Based On" allowSearch={true} allowFiltering={false} allowSorting={true} width={100} />
                                    <Column dataField="termType" caption="Term Type" allowSearch={true} allowFiltering={false} allowSorting={true} width={90} />
                                    <Column dataField="unitPrice" caption="Unit Price" allowSearch={true} allowFiltering={false} allowSorting={true} width={100} dataType="number" cellRender={renderGridNumberCell} />
                                    <Column dataField="limit" caption="Limit" allowSearch={true} allowFiltering={false} allowSorting={true} width={100} />
                                    <Column dataField="wefDate" caption="Wef Date" allowSearch={true} allowFiltering={false} allowSorting={true} width={100} dataType="datetime" format="dd/MM/yyyy" />
                                    <SortByGroupSummaryInfo summaryItem="count" />
                                    <GroupPanel visible={false} />
                                    {/* <Template name="toolbarTemplate" /> */}
                                    <HeaderFilter visible={true} />
                                    <SearchPanel visible={true} highlightCaseSensitive={true} width={240} placeholder="Search..." />
                                    <Grouping autoExpandAll={true} />
                                    <Paging defaultPageSize={20} />
                                    <Pager showPageSizeSelector={true} allowedPageSizes={[10, 20, 50]} showInfo={true} visible={true} />
                                    <Export enabled={true}  allowExportSelectedData={false} fileName="ServiceItem" />
                                </DataGrid>
                            </div>
                    </div>
                </div>

                <Modal ariaHideApp={false}
                    isOpen={this.state.serviceModelIsOpen}
                    style={customStyles}
                    className={"react-modal"}
                    //onAfterOpen={this.handleAssignDataToState}
                    onRequestClose={this.closeServiceModal}
                    shouldCloseOnOverlayClick={false}
                    contentLabel="Example Modal">
                    <form method="post" onSubmit={this.handleServiceSubmit} style={this.state.isSubmited ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                        <div className="modal-header">
                            <h6 className="modal-title">{this.state.id > 0 ? "Update Service Item" : "New Service Item"}</h6>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <label>Name <span className="text-danger">*</span></label>
                                        <input type="text" className="form-control form-control-sm" name="Name" placeholder="Enter service name" value={this.state.name} disabled={this.state.id > 0} title="Attribute Name" maxLength="50" onChange={this.handleInputChange} />
                                        {this.state.errors["name"].length > 0 && <span className="error invalid-feedback">{this.state.errors["name"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <label>Based On <span className="text-danger">*</span></label>
                                        <SelectBox
                                            dataSource={this.state.basedOnList}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select based on"
                                            name="id"
                                            defaultValue={0}
                                            value={this.state.basedOnUnitId}
                                            displayExpr='name'
                                            valueExpr='id'
                                            onSelectionChanged={this.handleBasedOnChange}
                                            showClearButton={true}
                                            disabled={false}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["basedOnUnitId"].length > 0 && <span className="error invalid-feedback">{this.state.errors["basedOnUnitId"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <label>Term Type <span className="text-danger">*</span></label>
                                        <SelectBox
                                            dataSource={this.state.paymentTypeList}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select term type"
                                            name="id"
                                            defaultValue={0}
                                            value={this.state.termTypeId}
                                            displayExpr='name'
                                            valueExpr='id'
                                            onSelectionChanged={this.handleTermTypeChange}
                                            showClearButton={true}
                                            disabled={false}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["termTypeId"].length > 0 && <span className="error invalid-feedback">{this.state.errors["termTypeId"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <label>SAC Code <span className="text-danger">*</span></label>
                                        <SelectBox
                                            dataSource={this.state.sacCodelist}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select code"
                                            name="code"
                                            defaultValue={0}
                                            value={this.state.sacCode}
                                            displayExpr='hsnsacNameCode'
                                            valueExpr='code'
                                            onSelectionChanged={this.handleSacCodeOnChange}
                                            showClearButton={true}
                                            disabled={false}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["sacCode"].length > 0 && <span className="error invalid-feedback">{this.state.errors["sacCode"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <label htmlFor="">Unit Price <span className="text-danger">*</span></label>
                                        <NumberBox type="number" className="form-control form-control-sm" name="UnitPrice" placeholder="Enter Unit Price"
                                            disabled={this.state.id > 0} format="#0.00"
                                            defaultValue={0.00} title="Unit Price" value={this.state.unitPrice} onValueChanged={this.handleUnitPriceChange} min={0.00} max={9999.99} />                                        
                                        {this.state.errors["unitPrice"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["unitPrice"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <label htmlFor="">Limit <span className="text-danger">*</span></label>
                                        <input type="text" className="form-control form-control-sm" name="Limit" placeholder="Enter limit" maxLength="20" title="Limit" value={this.state.limit} 
                                        onChange={this.handleInputChange} />
                                        {this.state.errors["limit"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["limit"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <label htmlFor="">WEF Date <span className="text-danger">*</span></label>
                                        <DateBox type="date"
                                            className="replay-date-time"
                                            name="wefDate"
                                            placeholder="dd/mm/yyyy"
                                            displayFormat="dd/MM/yyyy"
                                            showAnalogClock={true}
                                            useMaskBehavior={true}
                                            defaultValue={this.state.wefDate}
                                            value={this.state.wefDate}
                                            onValueChanged={this.handleWefDateChanged}
                                            width="100%"
                                            disabled={this.state.id > 0}
                                            acceptCustomValue={false}
                                        />
                                        {this.state.errors["wefDate"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["wefDate"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="form-group">
                                    <label htmlFor="">Description</label>
                                        <TextArea
                                            height={60}
                                            maxLength="200"
                                            className="form-control form-control-sm"
                                            name="Description" placeholder="Enter description"
                                            value={this.state.description}
                                            onValueChanged={this.handleTextAreaValueChanged}
                                        />
                                    </div>
                                </div>
                            </div></div>
                        <div className="modal-footer">
                            <button type="submit" className="btn btn-success" onClick={this.handleServiceSubmit} >
                                {this.state.isSubmited && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                {!this.state.isSubmited && <span title="Submit">Submit</span>}
                            </button>
                            <button type="button" className="btn btn-outline-secondary" onClick={this.closeServiceModal} title="Cancel">Cancel</button>
                        </div>
                    </form>
                </Modal>
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={position}
                    visible={this.state.loadPanelVisible}
                    showIndicator={true}
                    shading={true}
                    showPane={true}
                    hideOnOutsideClick={false}
                />
            </Fragment>
        )
    };
}