import React, { Fragment, Component } from 'react'; import DataGrid, {
    Column,
    Export, Grouping,
    GroupPanel,
    HeaderFilter, Pager,
    Paging,
    SearchPanel,
    SortByGroupSummaryInfo, MasterDetail,
} from 'devextreme-react/data-grid'; //npm i devextreme-react
import { getRequestParams ,numberGridFormat} from '../../helpers/globalfunctions.js';
import { alphanumericValidation } from '../../helpers/globalvalidations.js';
import { SelectBox } from 'devextreme-react/select-box';
import Modal from 'react-modal'; //npm install --save react-modal;
import NumberBox from 'devextreme-react/number-box';
import { currencySymbolFormats, precisionFormats } from '../../helpers/fixcodes.js';
import Swal from 'sweetalert2';

const customStyles = {
    content: {
        width: '50%',
    }
}
export class InvoicePayment extends Component {
    static displayName = InvoicePayment.name;
    constructor(props) {
        super(props);
        this.state = {
            invoicepaymentSummary: [],
            invoicepaymentDetails: [],
            customerInvoice: [],
            invoicepaymentModelIsOpen: false,
            customerId: this.props.location.state === undefined ? 0 : this.props.location.state.customerId,
            customerName: this.props.location.state === undefined ? "" : this.props.location.state.customerName,
            creditAmount: 0.0,
            debitAmount: 0.0,
            dueAmount: 0.0,
            overDueAmount: 0.0,
            invoiceId: 0,
            invoiceNo: "",
            amount: 0.0,
            balanceAmount: 0.0,
            settleAmount: 0.0,
            invoiceDueAmount :0.0,
            totalInvoiceAmount :0.0,
            dueInvoiceTotalAmount :0.0,
            remarks: "",
            paymentId: 0,
            sessionUser: JSON.parse(sessionStorage.getItem("sessionUser")),
            seconds: 30,
            errors: {
                settleAmount: 0,
                invoiceNo: "",
            }

        }
    }

    async componentDidMount() {
        await this.getInvoicePaymentMaster();
        //  this.dispatchInterval = setInterval(() => {
        //         const { seconds } = this.state
        //         if (seconds > 0) {
        //             this.setState(({ seconds }) => ({
        //                 seconds: seconds - 1
        //             }))
        //         }
        //         else{
        //             this.getInvoicePaymentMaster();
        //         }

        //     }, 1000);

    }

    getInvoicePaymentMaster = async () => {
        try {
            
            const requestParams = getRequestParams("GET");
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'invoicepayment/' + this.state.customerId + '/0', requestParams);
            const data = await response.json();
            
            this.setState({
                invoicepaymentSummary: data.payload.invoicePayment,
                invoicepaymentDetails: data.payload.invoicePaymentSummary,
                creditAmount: data.payload.invoicePayment[0].creditAmount,
                debitAmount: data.payload.invoicePayment[0].debitAmount,
                dueAmount: data.payload.invoicePayment[0].dueAmount,
                overDueAmount: data.payload.invoicePayment[0].overDueAmount,
                invoiceDueAmount : data.payload.invoicePayment[0].dueInvoiceAmount,
                totalInvoiceAmount :data.payload.invoicePayment[0].totalInvoiceAmount,
                dueInvoiceTotalAmount : data.payload.invoicePayment[0].dueInvoiceTotalAmount
            });
        } catch (err) {
            console.log("Error in " + this.displayName + ".getInvoicePaymentMaster function", err);
        }
    }
    handleClear = () => {
        window.location.href = "/invoicepayment/list";
    }
    openInvoicePaymentModel = (e, gridData) => {
        // e.preventDefault();
        this.setState({
            invoicepaymentModelIsOpen: true,
            paymentId: gridData.paymentId
        });
        this.getCustomerInvoices();
    }
    handleAmountChange = (event) => {
        const newValue = event.value;
        this.setState({ "amount": newValue });
    }
    handleBalanceAmountChange = (event) => {
        const newValue = event.value;
        this.setState({ "balanceAmount": newValue });
    }
    handleSettleAmountChange = (event) => {
        const newValue = event.value;
        this.setState({ "settleAmount": newValue });
    }
    handleInvoiceChange = (selectedOption) => {
        this.setState({
            invoiceNo: (selectedOption.selectedItem !== null ? selectedOption.selectedItem.invoiceNo : ""),
            amount: (selectedOption.selectedItem !== null ? selectedOption.selectedItem.grossAmount : ""),
            balanceAmount: (selectedOption.selectedItem !== null ? selectedOption.selectedItem.dueAmount : "")
        });
        let errors = this.state.errors;
        if (selectedOption.selectedItem !== null && selectedOption.selectedItem.invoiceNo !== null) {
            //this.state.errors["invoiceNo"] = "";
            errors.invoiceNo = "";
            this.setState({
                errors: errors
            });
        }

    }
    ClearModel = () => {
        this.setState({
            invoiceId: 0,
            invoiceNo: "",
            amount: 0.0,
            balanceAmount: 0.0,
            settleAmount: 0.0,
            remarks: "",
            invoicepaymentModelIsOpen: false,
            errors: {
                settleAmount: 0,
                invoiceNo: "",
            }
        });
    }

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        //const alphanumericValidation = /^[A-Za-z0-9]*$/;
        switch (name) {
            case 'remarks':
                if (alphanumericValidation.test(event.target.value))
                    this.setState({ name: event.target.value });
                break;

            default:
                this.setState({ [name]: value });
                break;
        }
        this.setState({ [name]: value });

    }
    getCustomerInvoices = async () => {
        try {
            var request = {};
            request.CustomerId = this.state.customerId;
            request.InvoiceNo = "";
            request.InvoiceStatusId = 0;
            request.InvoicePaymentStatus = 0;
            const requestParams = getRequestParams("POST", request);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'invoicepayment/CustomerInvoices', requestParams);
            const data = await response.json();
            this.setState({
                customerInvoice: data.payload.filter(e => e.invoiceStatus !== "Paid"),

            });
        } catch (err) {
            console.log("Error in " + this.displayName + ".getInvoicePaymentMaster function", err);
        }
    }

    handleInvoicePaymentSubmit = async (e) => {
        e.preventDefault();
        try {
            if (this.handleValidation()) {
                this.setState({ isSubmited: true });
                var request = {};
                request.UserId = this.state.sessionUser.id;
                request.PaymentId = this.state.paymentId;
                request.CustomerId = this.state.customerId;
                request.InvoiceNo = this.state.invoiceNo;
                request.AmountPaid = this.state.settleAmount;
                request.DueAmount = this.state.balanceAmount;
                const requestParams = getRequestParams("POST", request);
                const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'invoicepayment', requestParams);
                const data = await response.json();
                if (data.isSuccess) {
                    Swal.fire({
                        icon: 'success',
                        title: 'Success',
                        text: data.message,
                    }).then((result) => {
                        if (result.isConfirmed) {
                            this.getInvoicePaymentMaster();
                            this.ClearModel();
                        }
                        this.setState({ isSubmited: false })
                    });
                }
                this.setState({ isSubmited: false });
            }
        } catch (err) {
            console.log("Error in " + this.displayName + ".handleInvoicePaymentSubmit function", err);
        }
    }

    handleValidation = () => {
        let formIsValid = true;
        try {
            let errors = this.state.errors;
            errors.settleAmount = "";
            errors.invoiceNo = "";


            if (this.state.settleAmount === 0) {
                formIsValid = false;
                errors.settleAmount = "Please enter settle Amount.";
            }
            if (this.state.invoiceNo === "") {
                formIsValid = false;
                errors.invoiceNo = "Please select invoice.";
            }
            this.setState({ errors: errors });
        }
        catch (err) {
            console.log("Error in " + this.displayName + ".handleValidation function", err);
            this.setState({ isSubmited: false })
        }
        return formIsValid;
    }
    // renderDetail(props) {
    //  
    //     return (<div><DetailTemplate
    //         useRef="dipatchDetailRef"
    //          isDispatchRowIndex={props.data.customerId}
    //          dispatchRideId={props.data.paymentId}
    //         // singleRideSource={props.data}
    //          refreshDataSource={this.getInvoicePaymentMaster}
    //          stopAutoRefresh={this.dispatchInterval}
    //         // stopAutoRefreshHandle={this.dispatchIntervalHandle}
    //         // isStopAutoRefresh={this.isStopAutoRefresh}
    //         // handleRefresh={this.handleRefresh}
    //         // //rowChanged={this.rowChanged}
    //         // editViewRide={this.editViewRide}
    //     /></div>);
    // }
    handleRowPrepared = (e) => {
        if (e.rowType === 'data') {
          e.rowElement.style.backgroundColor = '#E8F5E9';
        }
    }
    render() {
        const renderGridCell = (data) => {
            return <div>
                <button type="button" className="btn btn-sm btn-success" onClick={(e) => this.openInvoicePaymentModel(e, data.data)}>
                    <span title="New"><small><i className="fas fa-rupee-sign"></i></small> Pay</span>
                </button>&nbsp;&nbsp;
            </div>;
        }
        const renderGridTotalAmountCell = (data) => {
            return numberGridFormat(data.value)
          //  return <div><small><i className={currencySymbolFormats.currencyFormat}></i></small>&nbsp;&nbsp;{parseFloat(data.value).toFixed(precisionFormats[2])}</div>;
        }
        return (
            <Fragment>
                <div className="section-body">
                    <div className='card bg-filter'>
                        <div className='card-body'>
                            <div className="section-header">
                                <h5 className="card-title text-blue mb-0">{this.state.customerName}</h5>

                                <div className="section-header-buttons">
                                    <button type="submit" className="btn btn-success" onClick={this.handleClear}>
                                        <span title="Cancel"><i className="fas fa-arrow-left"></i> Back</span>
                                    </button>
                                </div>
                            </div>                            
                        </div>
                    </div>
                    <div className='card mb-2'>
                        <div className='card-body'>
                        <div className='invoice-pymt-details'>
                                <div>
                                    <label className='mb-0'>Balance Amount</label>
                                    <div className='text-blue'><h5 className='mb-0'>{parseFloat(this.state.dueAmount).toFixed(precisionFormats[2])}</h5></div>
                                    {/* <div><b>{parseFloat(this.state.creditAmount).toFixed(precisionFormats[2])}</b></div> */}
                                </div>
                                <div>
                                    <label className='mb-0'>Total Invoice Amount</label>
                                    <div className='text-blue'><h5 className='mb-0'>{parseFloat(this.state.totalInvoiceAmount).toFixed(precisionFormats[2])}</h5></div>
                                    {/* <div><b>{parseFloat(this.state.creditAmount).toFixed(precisionFormats[2])}</b></div> */}
                                </div>

                                <div>
                                    <label className='mb-0'> Total Paid Amount</label>
                                    <div><h5 className='mb-0'>{parseFloat(this.state.debitAmount).toFixed(precisionFormats[2])}</h5></div>
                                </div>
                                <div>
                                    <label className='mb-0'>Total Due Amount</label>
                                    <div><h5 className='mb-0'>{parseFloat(this.state.dueInvoiceTotalAmount).toFixed(precisionFormats[2])}</h5></div>
                                </div>
                                {/* <div>
                                    <label className='mb-0'> Total Invoice Amount</label>
                                    <div className='text-blue'><b>{parseFloat(this.state.totalInvoiceAmount).toFixed(precisionFormats[2])}</b></div>
                                </div> */}
                                <div>
                                    <label className='mb-0'>Invoice Over Due Amount</label>
                                    <div><h5 className='mb-0'>{parseFloat(this.state.overDueAmount).toFixed(precisionFormats[2])}</h5></div>
                                </div>
                            </div>

                           
                        </div>
                    </div>
                    <div className='card'>
                        <div className='card-body'>
                        <div className='mt-3'>
                                <DataGrid
                                    dataSource={this.state.invoicepaymentDetails}
                                    keyExpr="paymentId"
                                    id='invoicepaymentList'
                                    selection={{ mode: 'single' }}
                                    columnsAutoWidth={false}
                                    showColumnLines={true}
                                    showRowLines={true}
                                    showBorders={true}
                                    rowAlternationEnabled={false}
                                    allowColumnResizing={true}
                                    // onExporting={this.onExporting}
                                    onRowPrepared={this.handleRowPrepared}
                                    columnResizingMode='widget'
                                    onToolbarPreparing={this.onToolbarPreparing}>
                                    <Column dataField="customerName" caption="Customer" allowSearch={true} allowFiltering={false} allowSorting={true} width={420} />
                                    <Column dataField="paymentDate" caption="Payment Date" allowSearch={true} allowFiltering={false} allowSorting={true} width={250} dataType="datetime" format="dd/MM/yyyy" />
                                    <Column dataField="paymentMode" caption="Payment Mode" allowSearch={true} allowFiltering={false} allowSorting={true} width={250} />
                                    <Column dataField="creditAmount" caption="Payment Amount" allowSearch={true} allowFiltering={false} allowSorting={true} width={200} cellRender={renderGridTotalAmountCell} />
                                    <Column dataField="transactionNo" caption="Transaction No" allowSearch={true} allowFiltering={false} allowSorting={true} width={250} />
                                    <Column dataField="paymentId" caption="" allowSearch={true} allowFiltering={false} allowSorting={true} width={100} cellRender={renderGridCell} />
                                    <MasterDetail
                                        enabled={true}
                                        component={DetailTemplate}
                                    //stopAutoRefresh={this.dispatchInterval}
                                    />
                                    <SortByGroupSummaryInfo summaryItem="count" />
                                    <GroupPanel visible={false} />
                                    {/* <Template name="toolbarTemplate" /> */}
                                    <HeaderFilter visible={true} />
                                    <SearchPanel visible={true} highlightCaseSensitive={true} width={240} placeholder="Search..." />
                                    <Grouping autoExpandAll={true} />
                                    <Paging defaultPageSize={20} />
                                    <Pager showPageSizeSelector={true} allowedPageSizes={[10, 20, 50]} showInfo={true} visible={true} />
                                    {/* <Export enabled={true} allowExportSelectedData={false} fileName="InvoicePayment" /> */}
                                </DataGrid>
                            </div>

                            <Modal ariaHideApp={false}
                                isOpen={this.state.invoicepaymentModelIsOpen}
                                style={customStyles}
                                className={"react-modal"}
                                //onAfterOpen={this.handleAssignDataToState}
                                onRequestClose={this.closeHsnModal}
                                shouldCloseOnOverlayClick={false}
                                contentLabel="Example Modal">
                                <form method="POST" style={this.state.isSubmited ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                                    <div className="modal-header">
                                        <h6 className="modal-title"> New Invoice Payment</h6>
                                    </div>
                                    <div className="modal-body">
                                        <div className="row">
                                            <div className="col-lg-6">
                                                <div className="form-group">
                                                    <label htmlFor="">Invoice <span className="text-danger">*</span></label>
                                                    <SelectBox
                                                        dataSource={this.state.customerInvoice}
                                                        className="basic-multi-select"
                                                        classNamePrefix="select"
                                                        placeholder="Select invoice"
                                                        name="invoiceNo"
                                                        defaultValue={0}
                                                        value={this.state.invoiceNo}
                                                        displayExpr='invoiceNo'
                                                        valueExpr='invoiceNo'
                                                        onSelectionChanged={this.handleInvoiceChange}
                                                        showClearButton={true}
                                                        // disabled={this.state.id > 0}
                                                        searchEnabled={true}
                                                    />
                                                    {this.state.errors["invoiceNo"].length > 0 && <span className="error invalid-feedback">{this.state.errors["invoiceNo"]}</span>}
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="form-group">
                                                    <label htmlFor="">Amount</label>
                                                    <NumberBox type="number" className="form-control form-control-sm" name="amount" placeholder="Enter Amount"
                                                        format="#0.00" disabled={true}
                                                        defaultValue={0.00} title="amount" value={this.state.amount} onValueChanged={this.handleAmountChange} min={0.00} max={999999.99} />
                                                    {/* {this.state.errors["amountPaid"].length > 0 && <span className="error invalid-feedback">{this.state.errors["amountPaid"]}</span>} */}
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="form-group">
                                                    <label htmlFor=""> Balance Amount</label>
                                                    <NumberBox type="number" className="form-control form-control-sm" name="balanceAmount" placeholder="Enter balance Amount"
                                                        format="#0.00" disabled={true}
                                                        defaultValue={0.00} title="balanceAmount" value={this.state.balanceAmount} onValueChanged={this.handleBalanceAmountChange} min={0.00} max={999999.99} />
                                                    {/* {this.state.errors["amountPaid"].length > 0 && <span className="error invalid-feedback">{this.state.errors["amountPaid"]}</span>} */}
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="form-group">
                                                    <label htmlFor="">Settle Amount</label>
                                                    <NumberBox type="number" className="form-control form-control-sm" name="amount" placeholder="Enter settle Amount"
                                                        format="#0.00"
                                                        defaultValue={0.00} title="settleAmount" value={this.state.settleAmount} onValueChanged={this.handleSettleAmountChange} min={0.00} max={999999.99} />
                                                    {this.state.errors["settleAmount"].length > 0 && <span className="error invalid-feedback">{this.state.errors["settleAmount"]}</span>}
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="form-group">
                                                    <label>Remarks </label>
                                                    <input type="text" className="form-control form-control-sm" name="remarks" autoComplete="remarks" placeholder="Enter Remarks" value={this.state.remarks}
                                                        title="remarks" maxLength="250" onChange={this.handleInputChange} />
                                                    {/* {this.state.errors["remarks"].length > 0 && <span className="error invalid-feedback">{this.state.errors["remarks"]}</span>} */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="submit" className="btn btn-success" onClick={this.handleInvoicePaymentSubmit} >
                                            {this.state.isSubmited && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                            {!this.state.isSubmited && <span title="Submit">Submit</span>}
                                        </button>
                                        <button type="button" className="btn btn-outline-secondary" onClick={this.ClearModel} title="Cancel">Cancel</button>
                                    </div>
                                </form>
                            </Modal>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}
class DetailTemplate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            customerId: props.data.data.customerId,
            paymentId: props.data.data.paymentId,
            invoicepaymentDetails: [],
        };
    }
    async componentDidMount() {
        await this.getCustomerInvoices();
    }

    getCustomerInvoices = async () => {
        try {
            const requestParams = getRequestParams("GET");
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'invoicepayment/' + this.state.customerId + '/' + this.state.paymentId, requestParams);
            const data = await response.json();
         
            this.setState({
                invoicepaymentDetails: data.payload.invoicePaymentDetails.filter( p => p.invoiceStatus !== 'Completed'),
             });
        } catch (err) {
            console.log("Error in " + this.displayName + ".getInvoicePaymentMaster function", err);
        }
    }
    handleRowPrepared = (e) => {
        if (e.rowType === 'data') {
           let s = this.state.invoicepaymentDetails;
          s.map((x, index, array) => {
            if(e.dataIndex == array.length -1)
            {
                e.rowElement.style.backgroundColor = '#FFF9C4'
            }
           
        });
    }
}
    render() {
        const renderGridTotalAmountCell = (data) => {
            return <div><small><i className={currencySymbolFormats.currencyFormat}></i></small>&nbsp;&nbsp;{parseFloat(data.value).toFixed(precisionFormats[2])}</div>;
        }

        return (
            <Fragment>
                <div>
                    <DataGrid
                        dataSource={this.state.invoicepaymentDetails}
                        keyExpr="id"
                        id='invoicepaymentList'
                        selection={{ mode: 'single' }}
                        columnsAutoWidth={false}
                        showColumnLines={true}
                        showRowLines={true}
                        showBorders={true}
                        rowAlternationEnabled={false}
                        allowColumnResizing={true}
                        onExporting={this.onExporting}
                        onRowPrepared={this.handleRowPrepared}
                        columnResizingMode='widget'
                        onToolbarPreparing={this.onToolbarPreparing}>
                        <Column dataField="soNumber" caption="SO#" allowSearch={true} allowFiltering={false} allowSorting={true} width={100} />
                        <Column dataField="invoiceNo" caption="Invoice#" allowSearch={true} allowFiltering={false} allowSorting={true} width={180} />
                        <Column dataField="invoiceDate" caption="Invoice Date" allowSearch={true} allowFiltering={false} allowSorting={true} width={200} dataType="datetime" format="dd/MM/yyyy" />
                        <Column dataField="grossAmount" caption="Invoice Amount" allowSearch={true} allowFiltering={false} allowSorting={true} width={150} cellRender={renderGridTotalAmountCell} />
                        <Column dataField="settledDate" caption="Settle Date" allowSearch={true} allowFiltering={false} allowSorting={true} width={150} dataType="datetime" format="dd/MM/yyyy" />
                        <Column dataField="settledAmount" caption="Settle Amount" allowSearch={true} allowFiltering={false} allowSorting={true} width={150} cellRender={renderGridTotalAmountCell} />
                        <Column dataField="dueAmount" caption="Due Amount" allowSearch={true} allowFiltering={false} allowSorting={true} width={150} cellRender={renderGridTotalAmountCell} />
                        {/* <Column dataField="invoiceStatus" caption="Status" allowSearch={true} allowFiltering={false} allowSorting={true} width={150} /> */}
                        <SortByGroupSummaryInfo summaryItem="count" />
                        <GroupPanel visible={false} />
                        {/* <Template name="toolbarTemplate" /> */}
                        <HeaderFilter visible={true} />
                        <SearchPanel visible={false} highlightCaseSensitive={true} width={240} placeholder="Search..." />
                        <Grouping autoExpandAll={true} />
                        {/* <Paging defaultPageSize={20} /> */}
                        <Pager showPageSizeSelector={false} allowedPageSizes={[10, 20, 50]} showInfo={false} visible={false} />
                        <Export enabled={false} allowExportSelectedData={false} fileName="InvoicePayment" />
                    </DataGrid>
                </div>
            </Fragment>
        );
    }
}

export default DetailTemplate;