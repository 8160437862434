import React, { Fragment, Component } from 'react';
import DataGrid, {
    Column,
    Paging,
    SortByGroupSummaryInfo,
    Editing, Lookup,
    RequiredRule,
    PatternRule,
    StringLengthRule,
    Summary, TotalItem,
} from 'devextreme-react/data-grid'; //npm i devextreme-react
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import { getRequestParams, formatDate, numberGridFormat } from '../../helpers/globalfunctions.js';
import { alphanumericValidation, floatValidation, numberValidation, alphanumericSpecialcaseValidation } from '../../helpers/globalvalidations.js';
import { floatExpression } from '../../helpers/globalvalidations.js';
import { precisionFormats, currencySymbolFormats, orderType, documentType } from '../../helpers/fixcodes.js';
import DateBox from 'devextreme-react/date-box';
import { LoadPanel } from 'devextreme-react/load-panel';
import { SelectBox } from 'devextreme-react/select-box';
import NumberBox from 'devextreme-react/number-box';
import Swal from 'sweetalert2'; //npm i sweetalert2 --save
import Modal from 'react-modal'; //npm install --save react-modal
import { OrderStatus } from '../../helpers/fixcodes.js';
import axios from 'axios'; //npm install--save axios

const position = { of: '#historydiv' };
const customStyles = {
    content: {
        width: '50%',
    }
}
var billingType = [
    { id: 1, name: "Advance" }
    , { id: 2, name: "Post" }
]
export class SalesOrderComponent extends Component {
    static displayName = SalesOrderComponent.name;
    constructor(props) {
        super(props);
        this.grdSalesOrderAttributesRef = React.createRef();
        this.state = {
            isSubmited: false,
            loadPanelVisible: false,
            documentModelIsOpen: false,
            accountList: null,
            paymentTermList: null,
            itemList: [],
            itemDetails: [],
            serviceItemList: null,
            hsnsacList: null,
            orderStatusList: null,
            orderTypeList: null,
            id: 0,
            accountId: this.props.location.state === undefined ? 0 : this.props.location.state.customerId,
            orderNo: this.props.location.state === undefined ? "" : this.props.location.state.orderNo,
            orderDate: this.props.location.state === undefined ? new Date() : this.props.location.state.orderDate,
            shippingDate: this.props.location.state === undefined ? new Date() : this.props.location.state.shippingDate,
            invoiceNo: this.props.location.state === undefined ? "" : this.props.location.state.invoiceNo,
            paymentTermId: 0,
            shippingCharges: 0.00,
            discount: 0.00,
            otherCharges: 0.00,
            grossAmount: 0.00,
            remarks: "",
            termsAndConditions: "",
            sessionUser: JSON.parse(sessionStorage.getItem("sessionUser")),
            salesorderId: this.props.location.state === undefined ? 0 : this.props.location.state.salesorderId,
            isNewItem: false,
            orderStatusId: 0,
            imageList: [],
            pdfList: [],
            excelList: [],
            otherList: [],
            minimiumDate: new Date(),
            documentSelectedFile: [],
            saleDocumentOrderId: 0,
            resultMeassage: "",
            targetFile: [],
            orderTypeId: 0,
            contractType: null,
            contractStatus: null,
            paymentTermType: null,
            startDate: new Date(),
            endDate: new Date(),
            contractTypeId: 0,
            contractStatusId: 1,
            paymentTermTypeId: 0,
            paymentTermTypeName: 0,
            accountTaxList : [],
            customerContractList:[],
            billingTypeList :billingType,
            billingTypeName :"",
            referenceSoNo:"",
            taxshippingValue :0,
            taxOtherCharges:0,
            taxDiscount:0,
            taxTotalAmount :0,
            taxSubTotal :0,
            showTaxAmount :0,
            errors: {
                accountId: 0,
                orderNo: "",
                orderDate: new Date(),
                shippingDate: new Date(),
                paymentTermId: 0,
                shippingCharges: 0.00,
                discount: 0.00,
                otherCharges: 0.00,
                grossAmount: 0.00,
                remarks: "",
                termsAndConditions: "",
                itemDetailsGrid: "",
                orderTypeId: 0,
                contractTypeId: 0,
                contractStatusId: 0,
                paymentTermTypeId: 0,
            }
        }

        this.assginData = this.assginData.bind(this);
        this.handleSalesOrderSubmit = this.handleSalesOrderSubmit.bind(this);
    }

    async componentDidMount() {
        this.setState({
            loadPanelVisible: true
        });
        await this.getAccounts();
        await this.getOrderType();
        await this.getPaymentTerms();
        await this.getItemMaster();
        // await this.getServiceItemMaster();
        await this.getHsnCodeMaster();
        await this.getContractType();
        await this.getContractStatus();
        await this.getPaymentTermType();

        if (this.state.salesorderId > 0) {
            await this.getSalesOrderMaster();
        }

        this.setState({
            loadPanelVisible: false
        });
    }

    getSalesOrderMaster = async () => {
        this.setState({
            loadPanelVisible: true
        });
        try {
            var request = {};
            request.DeliveryDate = this.state.shippingDate === "" ? "" : formatDate(this.state.shippingDate, "yyyy-MM-dd");
            request.SODate = this.state.orderDate === "" ? "" : formatDate(this.state.orderDate, "yyyy-MM-dd");
            request.SalesOrderId = parseInt(this.state.salesorderId);
            request.CustomerId = parseInt(this.state.accountId);
            request.InvoiceNo = this.state.invoiceNo;
            request.OrderNo = this.state.orderNo;
            request.FinancialFromDate = "";

            const requestParams = getRequestParams("POST", request);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'salesorder/salesOrderDetails', requestParams);
            const data = await response.json();

            this.setState({
                salesordersource: data.payload,
                loadPanelVisible: false
            });
            this.assginData(data.payload);
        } catch (err) {
            console.log("Error in " + this.displayName + ".getSalesOrderMaster function", err);
            this.setState({
                loadPanelVisible: false
            });

            if (this.state.salesorderId > 0) {
                // let myVar = setTimeout(() => this.assginData(), 500);
            }
        }
    }

    getContractType = async () => {
        try {
            var request = {};
            const requestParams = getRequestParams("GET", request);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'contractConfiguration/contractType/0', requestParams);
            const data = await response.json();
            this.setState({
                contractType: data.payload
            });
        } catch (err) {
            console.log("Error in " + this.displayName + ".getContractType function", err);
        }
    }

    getContractStatus = async () => {
        try {
            var request = {};
            const requestParams = getRequestParams("GET", request);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'contractConfiguration/contractStatus/0', requestParams);
            const data = await response.json();
            this.setState({
                contractStatus: data.payload
            });
        } catch (err) {
            console.log("Error in " + this.displayName + ".getContractStatus function", err);
        }
    }

    getPaymentTermType = async () => {
        try {
            var request = {};
            const requestParams = getRequestParams("GET", request);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'paymentTermType/dropdownType', requestParams);
            const data = await response.json();

            this.setState({
                paymentTermType: data.payload
            });
        } catch (err) {
            console.log("Error in " + this.displayName + ".getPaymentTermType function", err);
        }
    }


    assginData = (salesOrder) => {
        if (salesOrder !== null && salesOrder !== undefined) {
            var orderSummary = salesOrder.orderSummary;
            var orderDetails = salesOrder.orderDetails;
            setTimeout(() => this.getOrderStatus(), 500);
            setTimeout(() => this.getAccountTax(orderSummary.customerId), 500);
            this.setState({
                id: orderSummary.id,
                accountId: orderSummary.customerId,
                orderNo: orderSummary.soNo,
                referenceSoNo: orderSummary.referenceSONo,
                orderDate: formatDate(orderSummary.orderDate, "MM/dd/yyyy hh:mm"),
                shippingDate: formatDate(orderSummary.shippingDate, "MM/dd/yyyy hh:mm"),
                paymentTermId: orderSummary.paymentTermsId,
                shippingCharges: parseFloat(orderSummary.shippingCharges).toFixed(precisionFormats[2]),
                discount: parseFloat(orderSummary.discount).toFixed(precisionFormats[2]),
                otherCharges: parseFloat(orderSummary.otherCharges).toFixed(precisionFormats[2]),
                grossAmount: parseFloat(orderSummary.grossAmount).toFixed(precisionFormats[2]),
                remarks: orderSummary.remarks,
                taxSubTotal :orderSummary.taxAmount,
                termsAndConditions: orderSummary.termsCondition,
                itemDetails: orderDetails,
                orderStatusId: orderSummary.orderStatusId,
                orderTypeId: orderSummary.orderTypeId,
                contractTypeId: orderSummary.contractTypeId,
                contractStatusId: orderSummary.contractStatusId,
                paymentTermTypeId: orderSummary.contractPaymentTermsId,
                startDate: orderSummary.contractStart,
                endDate: orderSummary.contractEnd,
                billingTypeName: orderSummary.billingType
            });
            setTimeout(() => this.getServiceItemMaster(), 500);
        }
    }

    getOrderStatus = async () => {
        try {
            var request = {};
            const requestParams = getRequestParams("GET", request);
            var orderStatusId = parseInt(0);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'master/orderStatus/' + orderStatusId, requestParams);
            const data = await response.json();
            this.setState({
                orderStatusList: data.payload
            });
        } catch (err) {
            console.log("Error in " + this.displayName + ".getOrderStatus function", err);
        }
    }

    getAccounts = async () => {
        try {
            var request = {};
            const requestParams = getRequestParams("GET", request);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'account/dropdownAccount/2', requestParams);
            const data = await response.json();
            this.setState({
                accountList: data.payload
            });
        } catch (err) {
            console.log("Error in " + this.displayName + ".getAccounts function", err);
        }
    }

    getOrderType = async () => {
        try {
            var request = {};
            const requestParams = getRequestParams("GET", request);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'master/orderType/0', requestParams);
            const data = await response.json();
            this.setState({
                orderTypeList: data.payload
            });
        } catch (err) {
            console.log("Error in " + this.displayName + ".getOrderType function", err);
        }
    }

    getPaymentTerms = async () => {
        try {
            var request = {};
            const requestParams = getRequestParams("GET", request);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'paymentTermType/dropdownType', requestParams);
            const data = await response.json();
            this.setState({
                paymentTermList: data.payload
            });
        } catch (err) {
            console.log("Error in " + this.displayName + ".getPaymentTerms function", err);
        }
    }

    getItemMaster = async () => {
        try {
            var request = {};
            request.CategoryId = 0;
            request.HSNId = 0;
            request.ItemId = 0;
            const requestParams = getRequestParams("POST", request);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'items/item/', requestParams);
            const data = await response.json();

            this.setState({
                itemList: data.payload != null ? data.payload : []
            });
        } catch (err) {
            console.log("Error in " + this.displayName + ".getItemMaster function", err);
        }
    }

    getServiceItemMaster = async () => {
        try {
            var request = {};
            const requestParams = getRequestParams("GET", request);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'master/getServiceByCustomer/' + this.state.accountId, requestParams);
            const data = await response.json();

            this.setState({
                serviceItemList: data.payload,
            });
        } catch (err) {
            console.log("Error in " + this.displayName + ".getServiceItemMaster function", err);
            this.setState({
                loadPanelVisible: false
            });
        }
    }

    getHsnCodeMaster = async () => {
        try {
            var request = {};
            const requestParams = getRequestParams("GET", request);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'hsnsacCode/hscacccodes/0', requestParams);
            const data = await response.json();
            this.setState({
                hsnsacList: data.payload,
            });
        } catch (err) {
            console.log("Error in " + this.displayName + ".getHsnCodeMaster function", err);
        }
    }

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        let errors = this.state.errors;

        switch (name) {
            // case 'GrossAmount':
            //  var newgrossAmount =  parseFloat(this.state.grossAmount) - parseFloat(this.state.discount) + parseFloat(this.state.otherCharges) + parseFloat(this.state.shippingCharges)
            //         this.setState({ orderNo: event.target.value });
            //     break;
            case 'Remarks':
                if (alphanumericValidation.test(event.target.value))
                    this.setState({ remarks: event.target.value });
                break;
            case 'TermsAndConditions':
                if (alphanumericValidation.test(event.target.value))
                    this.setState({ termsAndConditions: event.target.value });
                break;
            default:
                this.setState({ [name]: value });
                break;
        }

        errors[name] = "";
        this.setState({
            errors: errors
        });

        //this.state.errors[name] = "";
    }

    getAccountTax = async (id) => {
        try {
            var request = {};
            const requestParams = getRequestParams("GET", request);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'taxConfiguration/accountTax/' + id, requestParams);
            const data = await response.json();
            this.setState({
                accountTaxList: data.payload != null ? data.payload : [],
            });
            if (data.payload == null) {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: "Please set tax for the customer",
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.setState({ accountId: 0 });
                    }
                    this.setState({ isSubmited: false })
                });
            }
        } catch (err) {
            console.log("Error in " + this.displayName + ".getContractList function", err);
        }
    }
    getCustomerContractByCustomer = async (id) => {
        try {
            var request = {};
            const requestParams = getRequestParams("GET", request);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'contractConfiguration/getCustomerContractByCustomer/' + id, requestParams);
            const data = await response.json();
            this.setState({
                customerContractList: data.payload != null ? data.payload : [],
            });
            if (data.payload !== null) {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: "Already contract set for the customer.",
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.setState({ accountId: 0 });
                    }
                    this.setState({ isSubmited: false })
                });
            }
        } catch (err) {
            console.log("Error in " + this.displayName + ".getContractList function", err);
        }
    }

    onRowInserting = async (e) => {
        this.setState({ isNewItem: true });
    }

    handleShippingChargesChange = (event) => {
        const newValue = event.value == null ? 0:event.value ;
        var totalTax = 0.00;
        // var singleTax = newValue === 0 ? 0: ((newValue * 18) / 100);
        if (newValue > 0) {
            if (this.state.accountTaxList !== null) {
                if (this.state.accountTaxList.length > 0) {
                    this.state.accountTaxList.map((taxitem, i) => {
                        var singleTax = parseFloat(((newValue * taxitem.percentage) / 100), 2);
                        totalTax = totalTax + singleTax;
                    });
                }
            }
        }
       
    //     this.setState({ "shippingCharges": newValue, taxshippingValue: totalTax });
    //    // var charge = parseFloat(this.state.otherCharges) + parseFloat(totalTax) + parseFloat(this.state.taxTotalAmount) + parseFloat(newValue)
    //     var newgrossAmount = parseFloat(charge) - parseFloat(this.state.discount); 
    //     //var newgrossAmount = parseFloat(this.state.discount) + parseFloat(this.state.otherCharges) + parseFloat(newValue)
    //     this.setState({ grossAmount: newgrossAmount ,taxshippingValue: totalTax});
    
    this.setState({ "shippingCharges": newValue, taxshippingValue: totalTax });
    var charge = parseFloat(this.state.otherCharges) + parseFloat(totalTax) + parseFloat(this.state.showTaxAmount) + parseFloat(newValue)
     var newgrossAmount = parseFloat(charge) - parseFloat(this.state.discount); 
     this.setState({ grossAmount: newgrossAmount ,taxshippingValue: totalTax});

    }

    handleDiscountChange = (event) => {
        // const newValue = event.value;
        // this.setState({ "discount": newValue });
        // // if(this.state.grossAmount != 0){
        // //     var newgrossAmount =parseFloat(this.state.grossAmount) - parseFloat(newValue) + parseFloat(this.state.otherCharges) + parseFloat(this.state.shippingCharges)     
        // //     this.setState({grossAmount : newgrossAmount});
        // // }
        // // else{
        // //     var newgrossAmount =  parseFloat(newValue) + parseFloat(this.state.otherCharges) + parseFloat(this.state.shippingCharges)
        // //     this.setState({grossAmount : newgrossAmount});
        // // }
        // var newgrossAmount = parseFloat(newValue) + parseFloat(this.state.otherCharges) + parseFloat(this.state.shippingCharges)
        // this.setState({ grossAmount: newgrossAmount });
        const newValue = event.value;
        this.setState({ "discount": newValue });
        // var test = parseFloat(this.state.taxOtherCharges) + parseFloat(this.state.taxshippingValue)+parseFloat(this.state.taxTotalAmount)+ parseFloat(this.state.shippingCharges) + parseFloat(this.state.otherCharges)
        // var newgrossAmount = parseFloat(newValue) - parseFloat(test)
        // this.setState({ grossAmount: newgrossAmount });
        var test = parseFloat(this.state.taxOtherCharges) + parseFloat(this.state.taxshippingValue)+parseFloat(this.state.showTaxAmount)+ parseFloat(this.state.shippingCharges) + parseFloat(this.state.otherCharges)
        var newgrossAmount = parseFloat(test) - parseFloat(newValue) 
        this.setState({ grossAmount: newgrossAmount });

    }

    handleOtherChargesChange = (event) => {
        // const newValue = event.value;
        // this.setState({ "otherCharges": newValue });
        const newValue = event.value == null ? 0:event.value ;
        // var singleTax = newValue === 0 ? 0: ((newValue * 18) / 100);
         var totalTax = 0.00;
         if (newValue > 0) {
             if(this.state.accountTaxList !== null){
                 if (this.state.accountTaxList.length > 0) {
                     this.state.accountTaxList.map((taxitem, i) => {
                         var singleTax = parseFloat(((newValue * taxitem.percentage) / 100), 2);
                         totalTax = totalTax + singleTax;
                     });
                 }
             }
            
         }
         this.setState({ "otherCharges": newValue , taxOtherCharges : totalTax});
        // var charge =   parseFloat(totalTax) + parseFloat(this.state.taxshippingValue) + parseFloat(this.state.taxTotalAmount) + parseFloat(newValue)
        var charge =   parseFloat(totalTax) + parseFloat(this.state.taxshippingValue) + parseFloat(this.state.showTaxAmount) + parseFloat(newValue)
         var newgrossAmount =  parseFloat(charge) - parseFloat(this.state.discount) ;
         this.setState({grossAmount : newgrossAmount});
    }

    handleGrossAmountChange = (event) => {
        const newValue = event.value;
        this.setState({ "grossAmount": newValue });
    }

    handleAccountChange = (selectedOption) => {
        this.setState({
            accountId: (selectedOption.selectedItem !== null ? selectedOption.selectedItem.id : 0),
        });
        let errors = this.state.errors;

        if (selectedOption.selectedItem !== null && selectedOption.selectedItem.id > 0) {
            //this.state.errors["accountId"] = "";
            errors.accountId = "";
            this.setState({
                errors: errors
            });
            setTimeout(() => this.getAccountTax(selectedOption.selectedItem.id), 500);
            setTimeout(() => this.getServiceItemMaster(), 500);
            setTimeout(() => this.getCustomerContractByCustomer(selectedOption.selectedItem.id), 500);
        }
    }

    handleOrderTypeChange = (selectedOption) => {
        this.setState({
            orderTypeId: (selectedOption.selectedItem !== null ? selectedOption.selectedItem.id : 0),
        });
        let errors = this.state.errors;
        if (selectedOption.selectedItem !== null && selectedOption.selectedItem.id > 0) {
            //this.state.errors["orderTypeId"] = "";
            errors.orderTypeId = "";
            this.setState({
                errors: errors
            });
        }
    }

    handlePaymentTermChange = (selectedOption) => {
        this.setState({
            paymentTermId: (selectedOption.selectedItem !== null ? selectedOption.selectedItem.id : 0),
        });
        let errors = this.state.errors;
        if (selectedOption.selectedItem !== null && selectedOption.selectedItem.id > 0) {
            //this.state.errors["paymentTermId"] = "";
            errors.paymentTermId = "";
            this.setState({
                errors: errors
            });
        }
    }

    handleOrderStatusChange = (selectedOption) => {
        this.setState({
            orderStatusId: (selectedOption.selectedItem !== null ? selectedOption.selectedItem.id : 0),
        });
    }

    handleOrderDateChanged = (date) => {
        this.setState({
            orderDate: date.value,
            shippingDate: date.value
        });
    }

    handleShippingDateChanged = (date) => {
        this.setState({ shippingDate: date.value });
    }

    handleStartDateChanged = (date) => {
        this.setState({
            startDate: date.value
            , endDate: date.value
        });
    }
    handleEndDateChanged = (date) => {
        this.setState({ endDate: date.value });
    }
    handleContractStatusChange = (selectedOption) => {
        this.setState({
            contractStatusId: (selectedOption.selectedItem !== null ? selectedOption.selectedItem.id : 0),
        });
        let errors = this.state.errors;
        if (selectedOption.selectedItem !== null && selectedOption.selectedItem.id > 0) {
            //this.state.errors["contractStatusId"] = "";
            errors.contractStatusId = "";
            this.setState({
                errors: errors
            });
        }
    }
    handleContractTypeChange = (selectedOption) => {
        this.setState({
            contractTypeId: (selectedOption.selectedItem !== null ? selectedOption.selectedItem.id : 0),
        });
        let errors = this.state.errors;
        if (selectedOption.selectedItem !== null && selectedOption.selectedItem.id > 0) {
            //this.state.errors["contractTypeId"] = "";
            errors.contractTypeId = "";
            this.setState({
                errors: errors
            });
        }
    }
    handlePaymentTermTypeChange = (selectedOption) => {
        this.setState({
            paymentTermTypeId: (selectedOption.selectedItem !== null ? selectedOption.selectedItem.id : 0),
            paymentTermTypeName: (selectedOption.selectedItem !== null ? selectedOption.selectedItem.name : ""),
        });
        let errors = this.state.errors;
        if (selectedOption.selectedItem !== null && selectedOption.selectedItem.id > 0) {
            //this.state.errors["paymentTermTypeId"] = "";
            errors.paymentTermTypeId = "";
            this.setState({
                errors: errors
            });
        }
    }

    calculateQtyChange = (newData, value, currentRowData) => {
        newData.qty = parseInt(value);
        var totalItemPrice = 0.00;
        totalItemPrice = currentRowData.price !== undefined ? parseFloat(currentRowData.price, 3) * value : 0;
        var totalTax = 0.00;
        // if (totalItemPrice > 0) {
        //     if (this.state.accountTaxList.length > 0) {
        //         this.state.accountTaxList.map((taxitem, i) => {
        //             var singleTax = parseFloat(((totalItemPrice * taxitem.percentage) / 100), 2);
        //             totalTax = totalTax + singleTax;
        //         });
        //     }
        // }
        // newData.taxAmount = parseFloat(totalTax, 2).toFixed(precisionFormats[2]);
        // newData.totalAmount = (parseFloat(totalTax, 2) + parseFloat(totalItemPrice, 3)).toFixed(precisionFormats[2]);
        // if (currentRowData.discount !== undefined) {
        //     newData.totalAmount = (parseFloat(newData.totalAmount) - parseFloat(currentRowData.discount)).toFixed(precisionFormats[2]);
        // }
        // this.setState ({ grossAmount : newData.totalAmount});
        newData.totalAmount = (parseFloat(totalItemPrice, 3)).toFixed(precisionFormats[2]);
        if (newData.totalAmount > 0) {
            if (this.state.accountTaxList.length > 0) {
                this.state.accountTaxList.map((taxitem, i) => {
                    var singleTax = parseFloat(((newData.totalAmount * taxitem.percentage) / 100), 2);
                    totalTax = totalTax + singleTax;
                });
            }
            var taxSubTotalAmount = parseFloat(totalTax).toFixed(precisionFormats[2]);
            var newGrossAmount = parseFloat(totalTax) + parseFloat(newData.totalAmount)
            this.setState({ showTaxAmount : newGrossAmount,grossAmount : newGrossAmount,taxSubTotal :taxSubTotalAmount });
        }
        return newData;
    }

    valueChangedEvent = (data) => {

    }

    calculatePriceChange = (newData, value, currentRowData) => {

        newData.price = parseFloat(value).toFixed(precisionFormats[2]);
        var totalItemPrice = 0.00;
        currentRowData.qty = (parseInt(this.state.orderTypeId) === parseInt(orderType.Serviceopex)) ? currentRowData.qty : currentRowData.qty;
        totalItemPrice = currentRowData.qty !== undefined ? parseFloat(currentRowData.qty, 3) * value : 0;
        var totalTax = 0.00;

        // if (totalItemPrice > 0) {
        //     if (this.state.accountTaxList.length > 0) {
        //         this.state.accountTaxList.map((taxitem, i) => {
        //             var singleTax = parseFloat(((totalItemPrice * taxitem.percentage) / 100), 2);
        //             totalTax = totalTax + singleTax;
        //         });
        //     }
        // }
        
        // newData.taxAmount = parseFloat(totalTax, 2).toFixed(precisionFormats[2]);
        // newData.totalAmount = (parseFloat(totalTax, 2) + parseFloat(totalItemPrice, 3)).toFixed(precisionFormats[2]);
        // if (currentRowData.discount !== undefined) {
        //     newData.totalAmount = (parseFloat(newData.totalAmount) - parseFloat(currentRowData.discount)).toFixed(precisionFormats[2]);
        // }
        //   this.setState ({ grossAmount : newData.totalAmount});
  newData.totalAmount = (parseFloat(totalItemPrice, 3)).toFixed(precisionFormats[2]);
          if (newData.totalAmount > 0) {
            if (this.state.accountTaxList.length > 0) {
                this.state.accountTaxList.map((taxitem, i) => {
                    
                    var singleTax = parseFloat(((newData.totalAmount * taxitem.percentage) / 100), 2);
                    totalTax = totalTax + singleTax;
                });
            }
            var taxSubTotalAmount = parseFloat(totalTax).toFixed(precisionFormats[2]);
            var newGrossAmount = parseFloat(totalTax) + parseFloat(newData.totalAmount)
            this.setState({ showTaxAmount : newGrossAmount,grossAmount : newGrossAmount,taxSubTotal :taxSubTotalAmount});
    }
}

    calculateDiscountChange = (newData, value, currentRowData) => {
        newData.discount = parseFloat(value).toFixed(precisionFormats[2]);
        newData.totalAmount = (parseFloat(currentRowData.totalAmount) - parseFloat(value)).toFixed(precisionFormats[2]);
        // this.setState ({ grossAmount : newData.totalAmount});
    }

    handleItemGridChanges = (newData, value, currentRowData) => {
        //alert("Hello");
    }

    handleValidation = () => {
        let formIsValid = true;
        try {
            let errors = this.state.errors;
            errors.accountId = 0;
            //errors.orderNo = "";
            errors.paymentTermId = 0;
            errors.orderTypeId = 0;
            // errors.shippingCharges = "";
            // errors.discount = "";
            // errors.otherCharges = "";
            //errors.grossAmount = "";
            errors.remarks = "";
            errors.itemDetailsGrid = ""

            if (parseInt(this.state.orderTypeId) === parseInt(orderType.Serviceopex)) {
                errors.contractTypeId = 0;
                //errors.contractStatusId = 0;
                errors.paymentTermTypeId = 0;
            }

            if (this.state.accountId === 0) {
                formIsValid = false;
                errors.accountId = "Please select customer.";
            }
            // if (this.state.orderNo.trim() === "") {
            //     formIsValid = false;
            //     errors.orderNo = "Please enter order number.";
            // }
            if (this.state.orderTypeId === 0) {
                formIsValid = false;
                errors.orderTypeId = "Please select order type.";
            }
            if (this.state.paymentTermId === 0) {
                formIsValid = false;
                errors.paymentTermId = "Please select payment term.";
            }
            // if (this.state.shippingCharges === 0) {
            //     formIsValid = false;
            //     errors.shippingCharges = "Please enter shipping charges.";
            // }
            // if (this.state.discount === 0) {
            //     formIsValid = false;
            //     errors.discount = "Please enter discount amount.";
            // }
            // if (this.state.otherCharges === 0) {
            //     formIsValid = false;
            //     errors.otherCharges = "Please enter other charges.";
            // }
            // if(this.state.grossAmount === 0){
            //     formIsValid = false;
            //     errors.grossAmount = "Please enter gross acmount.";
            // }
            if (this.state.remarks.trim() === "") {
                formIsValid = false;
                errors.remarks = "Please enter remarks.";
            }
            if (this.state.itemDetails.length == 0) {
                formIsValid = false;
                errors.itemDetailsGrid = "Please enter item(s).";
            }

            if (parseInt(this.state.orderTypeId) === parseInt(orderType.Serviceopex)) {
                if (this.state.contractTypeId === 0) {
                    formIsValid = false;
                    errors.contractTypeId = "Please select contract type.";
                }
                // if (this.state.contractStatusId === 0) {
                //     formIsValid = false;
                //     errors.contractStatusId = "Please select contract status.";
                // }
                if (this.state.paymentTermTypeId === 0) {
                    formIsValid = false;
                    errors.paymentTermTypeId = "Please select payment term.";
                }
            }

            this.setState({ errors: errors });
        }
        catch (err) {
            console.log("Error in " + this.displayName + ".handleValidation function", err);
            this.setState({ isSubmited: false })
        }
        return formIsValid;
    }

    handleSalesOrderSubmit = async (e) => {
        e.preventDefault();
        try {
            if (this.handleValidation()) {
                this.setState({
                    isSubmited: true
                });
                const dataSource = this.refs.grdSalesOrderAttributesRef.instance.getDataSource();
                var attributeArray = dataSource._items;
                var request = new Object();
                request.SONo = this.state.orderNo;
                request.SODate = formatDate(this.state.orderDate, "MM/dd/yyyy hh:mm");
                request.ReferenceSONo = this.state.referenceSoNo;
                request.VendorId = 0;
                request.CustomerId = parseInt(this.state.accountId);
                request.OrderTypeId = parseInt(this.state.orderTypeId);
                request.PaymentTermsId = parseInt(this.state.paymentTermId);
                request.OrderStatusId = this.state.salesorderId === 0 ? parseInt(OrderStatus.PENDING) : this.state.orderStatusId;

                request.ShippingCharges = parseFloat(this.state.shippingCharges);
                request.OtherCharges = parseFloat(this.state.otherCharges);

                var orderItemsList = [];
                var totalAmount = 0, taxAmount = 0;

                attributeArray.forEach((element, index) => {
                  
                    var orderItemRequest = {};
                    orderItemRequest.Id = this.state.isNewItem === true ? 0 : element.id;
                    orderItemRequest.ItemId = element.itemId;
                    orderItemRequest.ItemName = "";
                    orderItemRequest.Description = element.description;
                    orderItemRequest.HSNSACCode = element.hsnsacCode;
                    orderItemRequest.Qty = (parseInt(this.state.orderTypeId) === parseInt(orderType.Serviceopex)) ? parseInt(element.qty) : parseInt(element.qty);
                    orderItemRequest.Price = parseFloat(element.price);
                    orderItemRequest.Discount = element.discount == undefined ? 0.0 : parseFloat(element.discount);
                    orderItemRequest.TotalAmount = parseFloat(element.totalAmount);
                    orderItemRequest.TaxAmount = parseFloat(0);
                    orderItemRequest.Remarks = "";
                    orderItemRequest.WarrentyTerms = "";
                    totalAmount = totalAmount + parseFloat(element.totalAmount);
                  //  taxAmount = taxAmount + parseFloat(element.taxAmount);
                    orderItemsList.push(orderItemRequest);
                });
                request.OrderDetails = {};
                request.OrderDetails = orderItemsList;

                 request.TotalAmount = parseFloat(totalAmount);
                 request.Discount = parseFloat(this.state.discount);
                 request.TaxAmount = parseFloat(this.state.taxSubTotal);
                 request.GrossAmount =parseFloat(this.state.grossAmount); //parseFloat(totalAmount) - parseFloat(this.state.discount) + parseFloat(this.state.otherCharges) + parseFloat(this.state.shippingCharges);
                 request.Remarks = this.state.remarks;
                 request.TermsCondition = this.state.termsAndConditions;
                 request.SalesOrderDocument = "";
                 request.UserId = this.state.sessionUser.id;
                 request.ApprovedBy = this.state.sessionUser.id;
                 request.DeliveryDate = formatDate(this.state.shippingDate, "MM/dd/yyyy hh:mm");
                 request.Source = "Web";
                 request.CreatedBy = this.state.sessionUser.id;
                 request.CreatedOn = formatDate(new Date(), "MM/dd/yyyy hh:mm");
                 request.UpdatedBy = this.state.sessionUser.id;
                 request.UpdatedOn = formatDate(new Date(), "MM/dd/yyyy hh:mm");
                if(this.state.id == 0){
                    if (parseInt(this.state.orderTypeId) == parseInt(orderType.Serviceopex)) {
                        var contractConfigurationRequest = {};
                        contractConfigurationRequest = {};
                        contractConfigurationRequest.Id = parseInt(0);
                        contractConfigurationRequest.CustomerId = parseInt(this.state.accountId);
                        contractConfigurationRequest.ContractTypeId = parseInt(this.state.contractTypeId);
                        contractConfigurationRequest.ContractStart = formatDate(this.state.startDate, "MM/dd/yyyy hh:mm");
                        contractConfigurationRequest.ContractEnd = formatDate(this.state.endDate, "MM/dd/yyyy hh:mm");
                        contractConfigurationRequest.ContractStatusId = parseInt(this.state.contractStatusId);
                        contractConfigurationRequest.Comments = "";
                        contractConfigurationRequest.PaymentTermsId = parseInt(this.state.paymentTermTypeId);
                        contractConfigurationRequest.BillingType = this.state.billingTypeName;
                        request.ContractConfigurationRequest = {};
                        request.ContractConfigurationRequest = contractConfigurationRequest;
    
                        var contractServicesItemList = [];
                        attributeArray.forEach((element, index) => {
                            var contractServicesItem = {};
                            contractServicesItem.Id = 0;
                            contractServicesItem.ServiceItemId = parseInt(element.itemId);
                            contractServicesItem.BasedOnUnitId = element.basedOnUnitId == undefined ? 0 : parseInt(element.basedOnUnitId);
                            contractServicesItem.BasedOnUnit = element.basedOnUnit == undefined ? '' :parseInt(element.basedOnUnit);
                            contractServicesItem.TermTypeId = parseInt(this.state.paymentTermTypeId);
                            contractServicesItem.TermType = this.state.paymentTermTypeName;
                            contractServicesItem.UnitPrice = parseFloat(element.price);
                            contractServicesItem.SalePrice = parseFloat(element.price);
                            contractServicesItem.Limit = "";
                            contractServicesItem.Description = element.description;
                            contractServicesItem.WefDate = formatDate(new Date(), "MM/dd/yyyy hh:mm");
                            contractServicesItemList.push(contractServicesItem);
                        });
    
                        request.CustomerContractService = {};
                        request.CustomerContractService = contractServicesItemList;
                    }
                }
             
                const requestParams = getRequestParams('POST', request);
                const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'salesorder/' + this.state.id + '/saleOrder', requestParams);

                const data = await response.json();
                if (data.isSuccess) {
                    this.setState({ saleDocumentOrderId: data.payload, resultMeassage: data.message })
                    if (this.state.documentSelectedFile.size !== undefined || this.state.documentSelectedFile.length > 0) {
                        if (this.state.id == 0) {
                            this.documentUpload();
                        }
                    }
                    else {
                        Swal.fire({
                            icon: 'success',
                            title: 'Success',
                            text: data.message,
                        }).then((result) => {
                            if (result.isConfirmed) {
                                if (data.isSuccess) {
                                    window.location.href = "/salesorder/list";
                                }
                            }
                            this.setState({ isSubmited: false })
                        });
                    }

                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: data.message,
                    }).then((result) => {
                        if (result.isConfirmed) {
                        }
                        this.setState({ isSubmited: false })
                    });
                    this.setState({ isSubmited: false })
                }

                this.setState({
                    isSubmited: true
                })
            }
        } catch (err) {
            console.log("Error in " + this.displayName + ".handleSalesOrderSubmit function", err);
            this.setState({ isSubmited: false })
        }
    }

    handleClearControls = () => {
        this.setState({
            isSubmited: false,
            loadPanelVisible: false,
            accountList: [],
            paymentTermList: [],
            itemList: [],
            itemDetails: [],
            hsnsacList: [],
            orderStatusList: [],
            id: 0,
            accountId: 0,
            orderNo: "",
            orderDate: new Date(),
            shippingDate: new Date(),
            paymentTermId: 0,
            shippingCharges: 0.00,
            discount: 0.00,
            otherCharges: 0.00,
            grossAmount: 0.00,
            remarks: "",
            termsAndConditions: "",
            accountTaxList: [],
            sessionUser: JSON.parse(sessionStorage.getItem("sessionUser")),
            salesorderId: this.props.location.state === undefined ? "" : this.props.location.state.salesorderId,
            isNewItem: false,
            orderStatusId: 0,
            errors: {
                accountId: 0,
                orderNo: "",
                orderDate: new Date(),
                shippingDate: new Date(),
                paymentTermId: 0,
                shippingCharges: 0.00,
                discount: 0.00,
                otherCharges: 0.00,
                grossAmount: 0.00,
                remarks: "",
                termsAndConditions: "",
            }
        });
    }
    handleCancel = () => {
        window.location.href = "/salesorder/list";
    }
    openDocumentModel = () => {
        this.setState({
            documentModelIsOpen: true
        });
        this.getDocuments();
    }
    closeDocumentModel = () => {
        this.setState({
            documentModelIsOpen: false
        });
    }
    getDocuments = async () => {
        try {
            this.setState({
                loadPanelVisible: true
            });
            const requestParams = getRequestParams('GET', {});
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'file/' + parseInt(documentType.SalesOrder) + '/' + this.state.salesorderId + '/documents', requestParams);
            const data = await response.json();
            var imageListObj = [];
            var pdfListObj = [];
            var excelListObj = [];
            var otherListObj = [];
            if (data.documents != null && data.documents.length > 0) {
                for (var i = 0; i < data.documents.length; i++) {
                    if (data.documents[i].fileGuid != '00000000-0000-0000-0000-000000000000') {
                        const requestParamsImage = getRequestParams('GET', '');
                        const responseImage = await fetch(process.env.REACT_APP_API_BASE_URL + 'file/download/' + data.documents[i].fileGuid, requestParamsImage);
                        const dataImage = await responseImage.json();

                        if (dataImage.fileExtension.toUpperCase() == '.PDF') {
                            var img = new Object();
                            img.src = 'data:' + dataImage.mimeType + ';base64,' + dataImage.fileContent;
                            img.fileGuid = data.documents[i].fileGuid;
                            img.fileName = dataImage.fileName;
                            pdfListObj.push(img);
                        } else if (dataImage.fileExtension.toUpperCase() == '.XLXS' || dataImage.fileExtension.toUpperCase() == '.CSV' || dataImage.fileExtension.toUpperCase() == '.XLS') {
                            var img = new Object();
                            img.src = 'data:' + dataImage.mimeType + ';base64,' + dataImage.fileContent;
                            img.fileGuid = data.documents[i].fileGuid;
                            img.fileName = dataImage.fileName;
                            excelListObj.push(img);
                        } else if (dataImage.fileExtension.toUpperCase() == '.JPG' || dataImage.fileExtension.toUpperCase() == '.PNG' || dataImage.fileExtension.toUpperCase() == '.JPEG') {
                            var img = new Object();
                            img.src = 'data:' + dataImage.mimeType + ';base64,' + dataImage.fileContent;
                            img.fileGuid = data.documents[i].fileGuid;
                            imageListObj.push(img);
                        } else {
                            var img = new Object();
                            img.src = 'data:' + dataImage.mimeType + ';base64,' + dataImage.fileContent;
                            img.fileGuid = data.documents[i].fileGuid;
                            img.fileName = dataImage.fileName;
                            otherListObj.push(img);
                        }

                    }
                }
            }

            this.setState({
                loadPanelVisible: false,
                imageList: imageListObj,
                pdfList: pdfListObj,
                excelList: excelListObj,
                otherList: otherListObj
            });

        } catch (e) {
            console.log(e);
        }
    }

    onEditorPreparing = (e) => {
        if (e.parentType === "dataRow" && e.dataField === "itemId") {
            e.editorOptions.onValueChanged = (ev) => {
                let selectedItem = ev.component.option("selectedItem");
                e.setValue(selectedItem);
            };
        }
    }

    setItemCellValue = (rowData, value) => {
        rowData.itemId = value.id;
        rowData.hsnsacCode = value.hsnCode;
    }

    setServiceItemCellValue = (rowData, value) => {

        rowData.itemId = value.id;
        rowData.hsnsacCode = value.hsnSacCode;
        rowData.price = value.unitPrice;
        rowData.basedOnUnit = value.basedOnUnit;
        rowData.basedOnUnitId = value.basedOnUnitId;
    }

    handleFileInputChange = async (e) => {
        let images = e.target.files;
        var i;
        for (i = 0; i < e.target.files.length; i++) {
            this.state.targetFile.push(images[i]);
        }
        this.setState({
            documentSelectedFile: this.state.targetFile
        })
        //console.log(this.state.documentSelectedFile);
    }
    removeAll = () => {
        document.getElementById("file").value = null;
    }
    resetFileInput = (e) => {
        var data = this.state.documentSelectedFile.filter(item => item.name === e.target.value)
        var _data = this.state.documentSelectedFile.filter(item => item.name !== data[0].name)
        var data1 = this.state.documentSelectedFile.filter(item => item.name === data[0].name)
        this.setState({
            documentSelectedFile: _data,
            targetFile: _data
        }, () => 500)

        const arrLength = _data.length;
        //console.log(arrLength - 1);
        if (_data.length == 0) {
            this.removeAll();
        }
        else {
            document.getElementById("file").value = null
        }
    };
    documentUpload = async () => {
        for (let i = 0; i < this.state.documentSelectedFile.length; i++) {
            //if (this.state.documentSelectedFile.size > 0)
            {
                const url = process.env.REACT_APP_API_BASE_URL + 'file/upload';
                const formData = new FormData();
                //console.log(this.state.documentSelectedFile);
                formData.append('body', this.state.documentSelectedFile[i]);
                const config = {
                    headers: {
                        'content-type': 'multipart/form-data',
                        'UserId': this.state.sessionUser.id,
                        'Authorization': 'Bearer ' + sessionStorage.getItem('session_token'),
                    },
                };
                this.setState({
                    loadPanelVisible: true
                });
                axios.post(url, formData, config).then((response) => {
                    var resp = response.data;
                    if (resp != null) {
                        this.setState({
                            fileGuid: resp.fileGuid
                        })
                        this.saveDocument(resp)
                    }
                });
                this.setState({
                    loadPanelVisible: false
                });
            }
        }
    }
    saveDocument = async (data) => {
        if (data !== null) {
            this.setState({
                fileGuid: data.fileGuid
            });
            try {
                var isSuccess = false;
                var resultMessage = '';
                //for (var i = 0; i < this.state.uniqueId.length; i++)
                {
                    var request = new Object();
                    request.FileGuid = data.fileGuid;
                    request.FileRecordType = parseInt(1);
                    request.UserId = Number(this.state.sessionUser.id);
                    request.DocumentType = parseInt(1);
                    request.Id = this.state.saleDocumentOrderId;
                    const requestVehicleParams = getRequestParams('POST', request);
                    const responseVehicle = await fetch(process.env.REACT_APP_API_BASE_URL + 'file/save-documents', requestVehicleParams);
                    const dataVehicle = await responseVehicle.json();
                    isSuccess = dataVehicle.isSuccess;
                    resultMessage = dataVehicle.message;
                }
                //  this.setState({ isSubmited: false })
                if (isSuccess) {
                    Swal.fire({
                        icon: isSuccess ? 'success' : 'error',
                        title: isSuccess ? 'success' : 'Oops...',
                        text: this.state.resultMeassage,
                    }).then((result) => {
                        if (isSuccess) {
                            window.location.href = "/salesorder/list"
                            //  this.closeModel();
                            //this.getDocuments();
                        }
                    });
                } else {
                    this.onfailure('Failed to upload image.', resultMessage);
                }
                this.setState({ isSubmited: false })
            } catch (e) {
                //
            }

        }
    }
    calculateSelectedRow = (options) => {
        if (options.name === 'SelectedRowsSummary') {
            if (options.summaryProcess === 'start') {
                options.totalValue = 0.00;
            } else if (options.summaryProcess === 'calculate') {
                options.totalValue += parseInt(options.value.discount);
                var amount = options.totalValue
                //this.setState({ discount: amount });
            }
        }
        else
            if (options.name === 'SelectedTotalSummary') {
                if (options.summaryProcess === 'start') {
                    options.totalValue = 0.00;
                } else if (options.summaryProcess === 'calculate') {
                    options.totalValue += parseInt(options.value.totalAmount);
                    var amount = options.totalValue
                    var newGrossAmount = amount 
                  //  numberGridFormat(newGrossAmount)
                  
                    if (this.state.salesorderId !== 0) {
                     //   numberGridFormat(newGrossAmount)
                        this.setState({ grossAmount: this.state.grossAmount });
                    }
                    else if(this.state.showTaxAmount == 0){
                        this.setState({ grossAmount: amount ,showTaxAmount : newGrossAmount});
                    }
                    else{
                        var updateshowTax = parseFloat(this.state.taxSubTotal) + parseFloat(newGrossAmount)
                        this.setState({ grossAmount: updateshowTax ,showTaxAmount : updateshowTax});
                    }
                   
                }
            }
    }
    calculateCustomSummary = (options) => {
        // Calculating "customSummary1"
        if (options.name == "SelectedRowsSummary") {
            switch (options.summaryProcess) {
                case "start":
                    // Initializing "totalValue" here
                    break;
                case "calculate":
                    // Modifying "totalValue" here
                    break;
                case "finalize":
                    // Assigning the final value to "totalValue" here
                    break;
            }
        }

        // Calculating "customSummary2"
        if (options.name == "customSummary2") {
            // ...
            // Same "switch" statement here
        }
    }

    handleBillingTypeChange = (selectedOption) => {
        this.setState({
            billingTypeName: (selectedOption.selectedItem !== null ? selectedOption.selectedItem.name : ""),
        });
    }
    render() {
        const customizeQtyText = (data) => {
            return `Total - ${data.value}`;
        }

        const customizePriceText = (data) => {
            return `Total - ${data.value.toFixed(precisionFormats[2])}`;
        }

        const customizeTaxText = (data) => {
            return `Total - ${data.value.toFixed(precisionFormats[2])}`;
        }

        // const customizeDiscountText = (data) => {
        //     return `Total - ${data.value.toFixed(precisionFormats[2])}`;
        // }

        const customizeTotalAmountText = (data) => {
            return `Total - ${data.value.toFixed(precisionFormats[2])}`;
        }
        // const handleItemGridChanges = (data) => {
        //   
        //     return <div>Hello</div>
        // }

        const renderGridNumberCell = (data) => {
            //return<div>{parseFloat(data.value !== undefined ? numberGridFormat(data.value) : 0.00) }</div> 
            return <div><small><i className={currencySymbolFormats.currencyFormat}></i></small>&nbsp;&nbsp;{parseFloat(data.value !== undefined ? data.value : 0.00).toFixed(precisionFormats[2])}</div>;
        }

        return (
            <Fragment>
                <div className="section-body">
                    <div className='card bg-filter'>
                        <div className='card-body'>
                            <div className="section-header">
                                <h6 className='m-0'>{this.state.salesorderId > 0 ? "SO#" + this.state.orderNo : "New Sales Order"} </h6>
                                <div className="section-header-buttons">
                                    <button type="submit" className="btn btn-success" onClick={this.handleSalesOrderSubmit} >
                                        {this.state.isSubmited && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                        {!this.state.isSubmited && <span title="Submit">Submit</span>}
                                    </button>&nbsp;&nbsp;
                                    {/* <button type="submit" className="btn btn-primary">
                                    <span title="Save and Send"><i className="fas fa-check"></i> Save and Send</span>
                                </button>&nbsp;&nbsp; */}
                                    {/* <button type="submit" className="btn btn-success">
                                        <span title="Save and Print"><i className="fas fa-print"></i> Save and Print</span>
                                    </button>&nbsp;&nbsp; */}
                                    <button type="submit" className="btn btn-outline-secondary" onClick={this.handleCancel}>
                                        <span title="Cancel">Cancel</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='card mb-2'>
                        <div className='card-body'>
                            <div className='sub-title-color font-weight-bold'>Order Info</div>
                            <div className='row'>
                                <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <label htmlFor="">Customer <span className="text-danger">*</span></label>
                                        <SelectBox
                                            dataSource={this.state.accountList}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select customer"
                                            name="id"
                                            defaultValue={0}
                                            value={this.state.accountId}
                                            displayExpr='name'
                                            valueExpr='id'
                                            onSelectionChanged={this.handleAccountChange}
                                            showClearButton={true}
                                            disabled={this.state.id > 0}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["accountId"].length > 0 && <span className="error invalid-feedback">{this.state.errors["accountId"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-3 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <label htmlFor="">SO Order Type <span className="text-danger">*</span></label>
                                        <SelectBox
                                            dataSource={this.state.orderTypeList}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select order type"
                                            name="id"
                                            defaultValue={0}
                                            value={this.state.orderTypeId}
                                            displayExpr='name'
                                            valueExpr='id'
                                            onSelectionChanged={this.handleOrderTypeChange}
                                            showClearButton={true}
                                            disabled={this.state.id > 0}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["orderTypeId"].length > 0 && <span className="error invalid-feedback">{this.state.errors["orderTypeId"]}</span>}
                                    </div>
                                </div>

                                {
                                    this.state.id > 0 ?
                                        <div className="col-lg-2 col-md-3 col-sm-12 col-xs-12">
                                            <div className="form-group">
                                                <label htmlFor="">SO Order#</label>
                                                <input type="text" className="form-control form-control-sm" name="orderNo" placeholder="Enter Order No" value={this.state.orderNo}
                                                    disabled={this.state.id > 0} title="Order No" maxLength="50" onChange={this.handleInputChange} />
                                                {this.state.errors["orderNo"].length > 0 && <span className="error invalid-feedback">{this.state.errors["orderNo"]}</span>}
                                            </div>
                                        </div> : null}
                                <div className="col-lg-2 col-md-3 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <label htmlFor="">Reference SO#</label>
                                        <input type="text" className="form-control form-control-sm" name="referenceSoNo" placeholder="Enter sales order number" value={this.state.referenceSoNo}
                                            title="Order No" maxLength="50" onChange={this.handleInputChange} />
                                        {/* {this.state.errors["orderNo"].length > 0 && <span className="error invalid-feedback">{this.state.errors["orderNo"]}</span>} */}
                                    </div>
                                </div> </div>
                            <div className='row'>
                                <div className="col-lg-2 col-md-3 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <label htmlFor="">SO Order Date</label>
                                        <DateBox type="date"
                                            className="replay-date-time"
                                            name="orderDate"
                                            placeholder="dd/mm/yyyy"
                                            displayFormat="dd/MM/yyyy"
                                            showAnalogClock={true}
                                            useMaskBehavior={true}
                                            defaultValue={this.state.orderDate}
                                            value={this.state.orderDate}
                                            onValueChanged={this.handleOrderDateChanged}
                                            width="100%"
                                            // disabled={this.state.id > 0}
                                            acceptCustomValue={false}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-3 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <label htmlFor="">Shipping Date</label>
                                        <DateBox type="date"
                                            className="replay-date-time"
                                            name="shippingDate"
                                            placeholder="dd/mm/yyyy"
                                            displayFormat="dd/MM/yyyy"
                                            showAnalogClock={true}
                                            useMaskBehavior={true}
                                            defaultValue={this.state.shippingDate}
                                            value={this.state.shippingDate}
                                            onValueChanged={this.handleShippingDateChanged}
                                            min={this.state.shippingDate}
                                            width="100%"
                                            disabled={false}
                                            acceptCustomValue={false}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-2 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <label htmlFor="">Payment Term <span className="text-danger">*</span></label>
                                        <SelectBox
                                            dataSource={this.state.paymentTermList}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select payment term"
                                            name="id"
                                            defaultValue={0}
                                            value={this.state.paymentTermId}
                                            displayExpr='name'
                                            valueExpr='id'
                                            onSelectionChanged={this.handlePaymentTermChange}
                                            showClearButton={true}
                                           // disabled={this.state.id > 0}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["paymentTermId"].length > 0 && <span className="error invalid-feedback">{this.state.errors["paymentTermId"]}</span>}
                                    </div>
                                </div>
                                {
                                    this.state.salesorderId > 0 ?
                                        <div className="col-lg-2 col-md-3 col-sm-12 col-xs-12">
                                            <div className="form-group">
                                                <label htmlFor="">SO Order Status</label>
                                                <SelectBox
                                                    dataSource={this.state.orderStatusList}
                                                    className="basic-multi-select"
                                                    classNamePrefix="select"
                                                    placeholder="Select Order Status"
                                                    name="id"
                                                    defaultValue={0}
                                                    value={this.state.orderStatusId}
                                                    displayExpr='name'
                                                    valueExpr='id'
                                                    onSelectionChanged={this.handleOrderStatusChange}
                                                    showClearButton={true}
                                                    disabled={false}
                                                    searchEnabled={true}
                                                />
                                                {this.state.errors["accountId"].length > 0 && <span className="error invalid-feedback">{this.state.errors["accountId"]}</span>}
                                            </div>
                                        </div>
                                        : null
                                }
                            </div>
                        </div>
                    </div>
                    {
                        parseInt(this.state.orderTypeId) === parseInt(orderType.Serviceopex) ?
                            <div>
                                <div className='card mb-2'>
                                    <div className='card-body'>
                                        {/* <ul className="navbar-nav mr-3">
                                            <li className="nav-item">
                                                <h6 className='mb-0'><label id='lblPageTitle'>Set Contract</label></h6>
                                            </li>
                                        </ul>
                                         */}
                                        <div className='sub-title-color font-weight-bold mb-1'>Contract Info</div>
                                        <div className='row'>
                                            <div className="col-lg-2">
                                                <div className="form-group">
                                                    <label htmlFor="">Contract Type</label>
                                                    <SelectBox
                                                        dataSource={this.state.contractType}
                                                        className="basic-multi-select"
                                                        classNamePrefix="select"
                                                        placeholder="Select contract type"
                                                        name="id"
                                                        defaultValue={0}
                                                        value={this.state.contractTypeId}
                                                        displayExpr='name'
                                                        valueExpr='id'
                                                        onSelectionChanged={this.handleContractTypeChange}
                                                        showClearButton={true}
                                                        disabled={this.state.salesorderId > 0 ? true : false}
                                                        //disabled={this.state.id > 0}
                                                        searchEnabled={true}
                                                    />
                                                    {this.state.errors["contractTypeId"].length > 0 && <span className="error invalid-feedback">{this.state.errors["contractTypeId"]}</span>}
                                                </div>
                                            </div>
                                            {
                                                this.state.salesorderId > 0 ?
                                                    <div className="col-lg-2">
                                                        <div className="form-group">
                                                            <label htmlFor="">Contract Status</label>
                                                            <SelectBox
                                                                dataSource={this.state.contractStatus}
                                                                className="basic-multi-select"
                                                                classNamePrefix="select"
                                                                placeholder="Select contract status"
                                                                name="id"
                                                                defaultValue={0}
                                                                value={this.state.contractStatusId}
                                                                displayExpr='name'
                                                                valueExpr='id'
                                                                onSelectionChanged={this.handleContractStatusChange}
                                                                showClearButton={true}
                                                                disabled={this.state.salesorderId > 0 ? true : false}
                                                                //disabled={this.state.id > 0}
                                                                searchEnabled={true}
                                                            />
                                                            {/* {this.state.errors["contractStatusId"].length > 0 && <span className="error invalid-feedback">{this.state.errors["contractStatusId"]}</span>} */}
                                                        </div>
                                                    </div> : null
                                            }
                                            <div className="col-lg-2">
                                                <div className="form-group">
                                                    <label htmlFor="">Payment Term</label>
                                                    <SelectBox
                                                        dataSource={this.state.paymentTermType}
                                                        className="basic-multi-select"
                                                        classNamePrefix="select"
                                                        placeholder="Select Payment term"
                                                        name="id"
                                                        defaultValue={0}
                                                        value={this.state.paymentTermTypeId}
                                                        displayExpr='name'
                                                        valueExpr='id'
                                                        onSelectionChanged={this.handlePaymentTermTypeChange}
                                                        showClearButton={true}
                                                        disabled={this.state.salesorderId > 0 ? true : false}
                                                        //disabled={this.state.id > 0}
                                                        searchEnabled={true}
                                                    />
                                                    {this.state.errors["paymentTermTypeId"].length > 0 && <span className="error invalid-feedback">{this.state.errors["paymentTermTypeId"]}</span>}
                                                </div>
                                            </div>
                                            <div className="col-lg-2">
                                                <div className="form-group">
                                                    <label>Start Date </label>
                                                    <DateBox type="date"
                                                        className="start-date-time"
                                                        name="startDate"
                                                        placeholder="dd/mm/yyyy"
                                                        displayFormat="dd/MM/yyyy"
                                                        showAnalogClock={true}
                                                        useMaskBehavior={true}
                                                        defaultValue="dd/MM/yyyy"
                                                        value={this.state.startDate}
                                                        onValueChanged={this.handleStartDateChanged}
                                                        width="100%"
                                                        disabled={this.state.salesorderId > 0 ? true : false}
                                                        acceptCustomValue={false}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-2">
                                                <div className="form-group">
                                                    <label>End Date </label>
                                                    <DateBox type="date"
                                                        className="end-date-time"
                                                        name="endDate"
                                                        placeholder="dd/mm/yyyy"
                                                        displayFormat="dd/MM/yyyy"
                                                        showAnalogClock={true}
                                                        useMaskBehavior={true}
                                                        defaultValue={this.state.endDate}
                                                        value={this.state.endDate}
                                                        min={this.state.endDate}
                                                        onValueChanged={this.handleEndDateChanged}
                                                        width="100%"
                                                        disabled={this.state.salesorderId > 0 ? true : false}
                                                        acceptCustomValue={false}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-2">
                                                <div className="form-group">
                                                    <label>Billing Type <span className="text-danger">*</span></label>
                                                    <SelectBox
                                                        dataSource={this.state.billingTypeList}
                                                        className="basic-multi-select"
                                                        classNamePrefix="select"
                                                        placeholder="Select billing type"
                                                        name="name"
                                                        defaultValue={0}
                                                        value={this.state.billingTypeName}
                                                        displayExpr='name'
                                                        valueExpr='name'
                                                        onSelectionChanged={this.handleBillingTypeChange}
                                                        showClearButton={true}
                                                        disabled={this.state.salesorderId > 0 ? true : false}
                                                        searchEnabled={true}
                                                    />
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            : null}

                    <div className='card mb-2'>
                        <div className='card-body'>
                            <div className='sub-title-color font-weight-bold mb-1'>Item Info</div>
                            <div className='row'>
                                <div className='col-12'>

                                    <DataGrid
                                        ref="grdSalesOrderAttributesRef"
                                        dataSource={this.state.itemDetails}
                                        keyExpr="id"
                                        allowColumnReordering={true}
                                        showBorders={true}
                                        onRowUpdating={this.onRowUpdating}
                                        onRowUpdated={this.onRowUpdated}
                                        onRowInserting={this.onRowInserting}
                                        orRowInserted={this.onRowInserted}
                                        onRowRemoved={this.onRowRemoved}
                                        onEditorPreparing={this.onEditorPreparing}>
                                        <Paging enabled={false} />
                                        <Editing mode="row"
                                            allowUpdating= {this.state.salesorderId === 0 ? true : false}
                                            allowDeleting= {this.state.salesorderId === 0 ? true : false}
                                            allowAdding= {(this.state.salesorderId === 0 && this.state.accountId > 0 && this.state.orderTypeId > 0) ? true : false}
                                            editorOptions={true} />
                                        {
                                            parseInt(this.state.orderTypeId) === parseInt(orderType.GoodsCapex) ?
                                                <Column dataField="itemId" caption="Item" setCellValue={this.setItemCellValue}>
                                                    <Lookup dataSource={this.state.itemList} displayExpr="name" valueExpr="id" />
                                                    <RequiredRule />
                                                </Column>
                                                : <Column dataField="itemId" caption="Item" setCellValue={this.setServiceItemCellValue}>
                                                    <Lookup dataSource={this.state.serviceItemList} displayExpr="name" valueExpr="id" />
                                                    <RequiredRule />
                                                </Column>
                                        }
                                        <Column dataField="description" caption="Description">
                                            <RequiredRule maxLength={50} />
                                            <PatternRule message={'Please enter description'} pattern={alphanumericSpecialcaseValidation} />
                                            <StringLengthRule message="Description can not be more than 50 characters" max={50} />
                                        </Column>
                                        {parseInt(this.state.orderTypeId) === parseInt(orderType.Serviceopex) ?
                                            <Column dataField="hsnsacCode" caption="SAC Code" allowEditing={false}>
                                                {/* <Lookup dataSource={this.state.hsnsacList} displayExpr="name" valueExpr="code" allowEditing={false} /> */}
                                                <RequiredRule />
                                            </Column> :
                                            <Column dataField="hsnsacCode" caption="HSN Code" allowEditing={false}>
                                                {/* <Lookup dataSource={this.state.hsnsacList} displayExpr="name" valueExpr="code" allowEditing={false} /> */}
                                                <RequiredRule />
                                            </Column>
                                        }

                                        <Column dataField="qty" caption="Item Qty" dataType="number" setCellValue={this.calculateQtyChange} alignment="right">
                                            <RequiredRule />
                                            <PatternRule message={'Quantity should be in number'} pattern={numberValidation} />
                                        </Column>

                                        <Column dataField="price" caption="Sale Price" dataType="number" setCellValue={this.calculatePriceChange} format={"#,##0.##"} alignment="right"
                                            cellRender={renderGridNumberCell}>
                                            <RequiredRule />
                                            <PatternRule message={'Item Price should be decimal'} pattern={floatValidation} />
                                        </Column>
                                        {/* <Column dataField="taxAmount" caption="Tax Amount" dataType="decimal" allowEditing={false} format={"#,##0.##"} alignment="right"
                                            cellRender={renderGridNumberCell}>
                                            <RequiredRule />
                                            <PatternRule message={'Tax Amount should be decimal'} pattern={floatValidation} />
                                        </Column> */}
                                        {/* <Column dataField="discount" caption="Discount" dataType="decimal" setCellValue={this.calculateDiscountChange} disabled={true} format={"#,##0.##"} alignment="right"
                                            cellRender={renderGridNumberCell}>
                                            <PatternRule message={'Item Discount should be decimal'} pattern={floatValidation} />
                                        </Column> */}
                                        <Column dataField="totalAmount" caption="Item Total" dataType="decimal" allowEditing={false} format={"#,##0.##"} alignment="right"
                                            cellRender={renderGridNumberCell}></Column>
                                        <Summary calculateCustomSummary={this.calculateSelectedRow}>
                                            <TotalItem
                                                name="SelectedRowsSummary"
                                                summaryType="custom"
                                                // valueFormat="currency"
                                                displayFormat="Total - {0}"
                                                showInColumn="discount" />
                                            <TotalItem
                                                name="SelectedTotalSummary"
                                                summaryType="custom"
                                                // valueFormat="currency"
                                                displayFormat="Total - {0}"
                                                showInColumn="totalAmount" />
                                            {/* <TotalItem column="qty"   summaryType="sum"  customizeText={customizeQtyText} />
                                            <TotalItem column="price" summaryType="sum" customizeText={customizePriceText} />
                                            <TotalItem column="taxAmount" summaryType="sum" customizeText={customizeTaxText} />
                                            <TotalItem column="discount" name="discount"  summaryType="custom"  />
                                            <TotalItem column="totalAmount" summaryType="sum" customizeText={customizeTotalAmountText} /> */}
                                        </Summary>
                                        <SortByGroupSummaryInfo summaryItem="count" />
                                        {this.state.errors["itemDetailsGrid"].length > 0 && <span className="error invalid-feedback">{this.state.errors["itemDetailsGrid"]}</span>}
                                    </DataGrid>
                                </div>
                            </div>
                            <div className='row mt-3'>
                                <div className='col-9'>
                                    <div className="form-group">
                                        <label htmlFor="">Remarks</label>
                                        {/* <textarea className="form-control" placeholder="Enter remarks here"></textarea> */}
                                        <textarea type="text" className="form-control form-control-sm" name="remarks" autoComplete="remarks" placeholder="Enter remarks"
                                            maxLength="200" title="Remarks" value={this.state.remarks} onChange={this.handleInputChange} style={{ width: "90%", height: "50px" }} />
                                        {this.state.errors["remarks"].length > 0 && <span className="error invalid-feedback">{this.state.errors["remarks"]}</span>}
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="">Terms & Conditions</label>
                                        <textarea type="text" className="form-control form-control-sm" name="TermsAndConditions" autoComplete="termsAndConditions" placeholder="Enter terms & conditions"
                                            maxLength="200" title="Enter terms & conditions" value={this.state.termsAndConditions} onChange={this.handleInputChange} style={{ width: "90%", height: "50px" }} />
                                        {this.state.errors["termsAndConditions"].length > 0 && <span className="error invalid-feedback">{this.state.errors["termsAndConditions"]}</span>}
                                    </div>
                                </div>
                                <div className='col-3'>
                                    <table border="0" cellPadding={"5"} cellSpacing={"0"} width={"100%"} align="right">

                                        {
                                            parseInt(this.state.orderTypeId) === parseInt(orderType.GoodsCapex) ?
                                                <tr>
                                                    <td className='text-left' width="40%">Shipping Charges</td>
                                                    <td>
                                                        <NumberBox type="number" className="form-control form-control-sm" name="ShippingCharges" placeholder="Enter shipping charges" 
                                                            format="#0.00" defaultValue={0.00} title="Shipping Charges" value={this.state.shippingCharges} onValueChanged={this.handleShippingChargesChange} min={0.00} />
                                                        {this.state.errors["shippingCharges"].length > 0 && <span className="error invalid-feedback">{this.state.errors["shippingCharges"]}</span>}
                                                    </td>
                                                </tr>
                                                : null
                                        }

                                        <tr>
                                            <td className='text-left'>Other Charges</td>
                                            <td>

                                                <NumberBox type="number" className="form-control form-control-sm" name="OtherCharges" placeholder="0.00" 
                                                    format="#0.00" defaultValue={0.00} title="Other Charges" value={this.state.otherCharges} onValueChanged={this.handleOtherChargesChange} min={0.00} />
                                                {this.state.errors["otherCharges"].length > 0 && <span className="error invalid-feedback">{this.state.errors["otherCharges"]}</span>}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className='text-left'>Discount</td>
                                            <td>
                                                <NumberBox type="number" className="form-control form-control-sm" dataType="float" name="Discount" placeholder="0.00" 
                                                    format="#0.00" defaultValue={0.00} title="Discount" value={this.state.discount} onValueChanged={this.handleDiscountChange} min={0.00} />
                                                {this.state.errors["discount"].length > 0 && <span className="error invalid-feedback">{this.state.errors["discount"]}</span>}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className='text-left' width="40%">Total Tax
                                            <div className='total-tax-amount'>
                                                {/* data.payload[0].taxName */}
                                                {this.state.accountTaxList == null ? [] : this.state.accountTaxList.map((item, index) => {
                                                    return (
                                                        <span>{item.taxName + '@' + item.percentage + '%'}</span>
                                                    )
                                                })}
                                            </div>
                                            </td>
                                            <td>                                             

                                                {/* {this.state.accountTaxList == null ?[]:    <div>{this.state.accountTaxList[0].taxName + '@' + this.state.accountTaxList[0].percentage}</div> } */}
                                                <NumberBox type="number" className="form-control form-control-sm" name="taxSubTotal" placeholder="0.00"
                                                    defaultValue={0.00} title="taxSubTotal" disabled ={true} value={this.state.taxSubTotal}  min={0.00} />
                                                {/* {this.state.errors["shippingCharges"].length > 0 && <span className="error invalid-feedback">{this.state.errors["shippingCharges"]}</span>} */}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Gross Amount
                                            </td>
                                            <td>
                                                <NumberBox type="number" className="form-control form-control-sm gross-total" name="GrossAmount" placeholder="0.00" disabled={true}
                                                    format="#0.00" defaultValue={0.00} title="Gross Amount" value={this.state.grossAmount} onValueChanged={this.handleGrossAmountChange} min={0.00} />
                                                {/* {this.state.errors["grossAmount"].length > 0 && <span className="error invalid-feedback">{this.state.errors["grossAmount"]}</span>} */}
                                            </td>
                                        </tr>
                                      
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>


                    {/* customizeText={customizeDiscountText} */}
                    <div className='card' style={{ display: this.state.salesorderId === 0 ? 'block' : 'none' }}>
                        <div className='card-body'>
                            <div className='sub-title-color font-weight-bold mb-1'>Document Info</div>
                            <div className='row'>
                                <div className="col-8">
                                    <div className="form-group">
                                        <label htmlFor="">Attach File(s) to New Sales Order</label>
                                        <input className="form-control" type="file" id="file" onClick={() => { this.removeAll() }} onChange={(e) => { this.handleFileInputChange(e, false) }} />
                                        {/* <label htmlFor="" className='small text-muted'>You can upload a maximum of 5 files, 5MB each</label> */}
                                        {this.state.documentSelectedFile.map((item, index) => {
                                            return (
                                                <div key={index}>
                                                    <label >{item.name} <button value={item.name} onClick={this.resetFileInput} className="btn btn-outline-secondary fas fa-trash text-danger"></button></label>
                                                </div>
                                            );
                                        })}
                                        {/* <label htmlFor="" className='small text-muted'>You can upload a maximum of 5 files, 5MB each</label> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <Modal ariaHideApp={false}
                    isOpen={this.state.documentModelIsOpen}
                    style={customStyles}
                    className={"react-modal"}
                    onRequestClose={this.closeDocumentModel}
                    shouldCloseOnOverlayClick={false}
                    contentLabel="Example Modal">
                    <form method="post" style={this.state.isSubmited ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                        <div className="modal-header">
                            <h6 className="modal-title">Document</h6>
                        </div>
                        <div className="modal-body">
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <div className="box-body no-padding" style={{ maxHeight: 400 + 'px', overflowY: "scroll" }}>
                                    <div>
                                        <ul className="users-list clearfix">
                                            {this.state.imageList.length > 0 && this.state.imageList.map((item, j) =>
                                                <li style={{ position: "relative" }} key={j}>
                                                    <a href={item.src} download={item.fileName}><div className="framehover"><img src={item.src} width="150" alt="Image" /></div></a>
                                                </li>
                                            )}
                                            {this.state.pdfList.length > 0 && this.state.pdfList.map((item, j) =>
                                                <li style={{ position: "relative" }} key={j}>
                                                    <a href={item.src} download={item.fileName}><div className="framehover"><img src="../../assets/img/pdf_icon.png" width="150" alt="Blank Image" /></div></a>
                                                </li>
                                            )}
                                            {this.state.excelList.length > 0 && this.state.excelList.map((item, j) =>
                                                <li style={{ position: "relative" }} key={j}>
                                                    <a href={item.src} download={item.fileName}><div className="framehover"><img src="../../assets/img/excel_icon.png" width="100" alt="Blank Image" /></div></a>
                                                </li>
                                            )}
                                            {this.state.otherList.length > 0 && this.state.otherList.map((item, j) =>
                                                <li style={{ position: "relative" }} key={j}>
                                                    <a href={item.src} download={item.fileName}><div className="framehover"><img src="../../assets/img/other_icon.png" width="100" alt="Blank Image" /></div></a>
                                                </li>
                                            )}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-outline-secondary" onClick={this.closeDocumentModel} title="Cancel">Cancel</button>
                        </div>
                    </form>
                </Modal>
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={position}
                    visible={this.state.loadPanelVisible}
                    showIndicator={true}
                    shading={true}
                    showPane={true}
                    hideOnOutsideClick={false}
                />
            </Fragment>
        )
    };
}