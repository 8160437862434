import React, { Fragment, Component } from 'react';
import { ChangePasswordComponent } from '../shared/ChangePasswordComponent';

export class TopbarComponent extends Component {
    static displayName = TopbarComponent.name;
    
    constructor(props) {
        super(props);
        //this.changepasswordcomponent = React.createRef();
        //this.handleLogout = this.handleLogout.bind();
        this.state = {
            sessionUser: JSON.parse(sessionStorage.getItem("sessionUser"))
        }

        this.handleLogout = this.handleLogout.bind(this);
    }

    openchangesPasswordModel = (e) => {
        e.preventDefault();
        this.refs.changepasswordcomponent.showModal();
    }

    handleLogout = async (e) => {
        e.preventDefault();
        sessionStorage.clear();
        window.location.href = "/";
    }

    render() {
        return (
            <Fragment>
                <div className="navbar-bg"></div>
                <nav className="navbar navbar-custom navbar-expand-lg main-navbar" name="topGeneralBar">
                    <form className="form-inline mr-auto">
                        <ul className="navbar-nav mr-3">
                            <li><a href="#" data-toggle="sidebar" className="nav-link nav-link-lg"><i className="fas fa-bars"></i></a></li>
                            <li className="nav-item">
                                <h6 className='mb-0'><label id='lblPageTitle'></label></h6>
                            </li>
                        </ul>
                    </form>
                    <ul className="navbar-nav navbar-right">
                            <li className="dropdown"><a href="#" data-toggle="dropdown" className="nav-link dropdown-toggle nav-link-lg nav-link-user">
                                <img alt="image" src="assets/img/avatar/avatar-1.png" className="rounded-circle mr-1"/>
                                    <div className="d-sm-none d-lg-inline-block">{this.state.sessionUser.firstName + " " + this.state.sessionUser.lastName}</div></a>
                                <div className="dropdown-menu dropdown-menu-right">
                                    <div className="dropdown-title">Logged in 5 min ago</div>
                                    <a href="features-profile.html" className="dropdown-item has-icon">
                                        <i className="far fa-user"></i> Profile
                                    </a>
                                    <a href="features-activities.html" className="dropdown-item has-icon">
                                        <i className="fas fa-bolt"></i> Activities
                                    </a>
                                    <a href="features-settings.html" className="dropdown-item has-icon">
                                        <i className="fas fa-cog"></i> Settings
                                    </a>
                                    <a href="#" className="dropdown-item has-icon" onClick={(e) => { this.openchangesPasswordModel(e) }}>
                                        <i className="fas fa-lock-open"></i> Change Password
                                    </a>
                                    <div className="dropdown-divider"></div>
                                    <a href="#" className="dropdown-item has-icon text-danger" onClick={this.handleLogout}>
                                        <i className="fas fa-sign-out-alt"></i> Logout
                                    </a>
                                </div>
                            </li>
                        </ul>
                </nav>
                <ChangePasswordComponent ref="changepasswordcomponent" />
            </Fragment>
        );
    }
}