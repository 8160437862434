import React, { Fragment, Component } from 'react';
import DataGrid, {
    Column,
    Export, Grouping,
    GroupPanel,
    HeaderFilter, Pager,
    Paging,
    SearchPanel,
    SortByGroupSummaryInfo,
    Editing, Lookup,
    RequiredRule,
    PatternRule,
} from 'devextreme-react/data-grid'; //npm i devextreme-react
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import { formatDate, getRequestParams } from '../../helpers/globalfunctions.js';
import { alphanumericValidation, alphanumericSpecialcaseValidation } from '../../helpers/globalvalidations.js';
import { currencySymbolFormats, precisionFormats } from '../../helpers/fixcodes.js';
import { LoadPanel } from 'devextreme-react/load-panel';
import Modal from 'react-modal'; //npm install --save react-modal;
import { SelectBox } from 'devextreme-react/select-box';
import Swal from 'sweetalert2'; //npm i sweetalert2 --save
import DateBox from 'devextreme-react/date-box';
import { Template } from 'devextreme-react/core/template';
import NumberBox from 'devextreme-react/number-box';
import { jsPDF } from 'jspdf';
import { exportDataGrid } from 'devextreme/pdf_exporter';
const exportFormats = ['pdf', 'xlsx'];
const position = { of: '#historydiv' };

const customStyles = {
    content: {
        width: '50%',
    }
}

const customItemAttributeStyles = {
    content: {
        width: '30%',
    }
}

export class ItemComponent extends Component {
    static displayName = ItemComponent.name;

    constructor(props) {
        super(props);
        this.grdAttributesRef = React.createRef();

        this.state = {
            isSubmited: false,
            loadPanelVisible: false,
            itemAttributeModelIsOpen: false,
            itemSource: null,
            id: 0,
            attributes: [],
            categoryList: null,
            attributeList: null,
            attributeTypeList: null,
            hsncodeList: null,
            itemModal: false,
            itemName: "",
            itemCode: "",
            categoryId: 0,
            category: "",
            itemDescription: "",
            unitPrice: 0.00,
            wefDate: new Date(),
            attributeRequests: null,
            attributeHtml: null,
            filterCategoryId: 0,
            hsncodeId: 0,
            sessionUser: JSON.parse(sessionStorage.getItem("sessionUser")),
            errors: {
                itemName: "",
                itemCode: "",
                categoryId: 0,
                category: "",
                itemDescription: "",
                unitPrice: 0.00,
                wefDate: new Date(),
                attributes: "",
            }
        }

        this.onEditingStart = this.onEditingStart.bind(this);
    }

    async componentDidMount() {
        this.setState({
            loadPanelVisible: true
        });
        await this.getCategoryMaster();
        await this.getAttributeType();
        await this.getAttribute(0);
        await this.getItemMaster();
        await this.getHsnSacCodeType();
        this.setState({
            loadPanelVisible: false
        });
    }

    getItemMaster = async () => {
        try {
            var request = {};
            //request.categoryId = this.state.categoryId;
            request.CategoryId = parseInt(this.state.filterCategoryId);
            request.HSNId = parseInt(this.state.hsncodeId);
            const requestParams = getRequestParams("POST", request);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'items/item/', requestParams);
            const data = await response.json();

            this.setState({
                itemSource: data.payload != null ? data.payload : []
            });
        } catch (err) {
            console.log("Error in " + this.displayName + ".getItemMaster function", err);
        }
    }

    getHsnSacCodeType = async () => {
        try {
            var request = {};
            const requestParams = getRequestParams("GET", request);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'hsnsacCode/hscacccodes/0')
            let data = await response.json();
            if (data.payload != null) {
                data = JSON.parse(JSON.stringify(data.payload));
                let hsnList = data.filter(item => item.type == 'HSN');
                this.setState({
                    hsncodeList: hsnList
                });
            }
            else {
                this.setState({
                    hsncodeList: null
                });
            }

        } catch (err) {
            console.log("Error in " + this.displayName + ".getHsnSacCodeType function", err);
        }
    }

    attributeTypeChanged = (e) => {

    }

    getAttributeType = async () => {
        try {
            var request = {};
            const requestParams = getRequestParams("GET", request);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'attributes/attributetype/0', requestParams);
            const data = await response.json();
            this.setState({
                attributeTypeList: data.payload != null ? data.payload : []
            });
        } catch (err) {
            console.log("Error in " + this.displayName + ".getAttributeType function", err);
        }
    }

    getAttribute = async (attributeTypeId) => {
        try {
            var request = {};
            const requestParams = getRequestParams("GET", request);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'attributes/attribute/0/' + parseInt(attributeTypeId), requestParams);
            const data = await response.json();
            this.setState({
                attributeList: data.payload != null ? data.payload : []
            });
        } catch (err) {
            console.log("Error in " + this.displayName + ".getAttribute function", err);
        }
    }

    getCategoryMaster = async () => {
        try {
            var request = {};
            request.categoryId = this.state.categoryId;
            const requestParams = getRequestParams("GET", request);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'itemCategory/category/0/' + this.state.filterCategoryId, requestParams);
            const data = await response.json();
            this.setState({
                categoryList: data.payload != null ? data.payload : []
            });
        } catch (err) {
            console.log("Error in " + this.displayName + ".getCategoryMaster function", err);
        }
    }

    handleSearchItem = async () => {
        await this.getItemMaster();
    }

    handleClear = () => {
        this.setState({
            categoryId: 0,
            hsncodeId: 0
        });
        setTimeout(() => this.getCategoryMaster(), 100);
    }

    getItemAttribute = async (itemId) => {
        try {
            var request = {};
            const requestParams = getRequestParams("GET", request);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'itemAttribute/attributes/' + itemId, requestParams);
            const data = await response.json();
            this.setState({
                attributes: data.payload != null ? data.payload : []
            });
            let attributeHtmlItem = this.bindAttributeHtml(data.payload != null ? data.payload : []);
            this.setState({ attributeHtml: attributeHtmlItem });

        } catch (err) {
            console.log("Error in " + this.displayName + ".getItemAttribute function", err);
        }
    }

    bindAttributeHtml(attributeObject) {
        let attributeHtmlItem = [];
        let attributeHtml = JSON.parse(JSON.stringify(attributeObject));
        attributeHtml.forEach((attribute, i) => {
            attributeHtmlItem.push(<div className='row' key={i}>
                <div className='col-6'>
                    <div>
                        {attribute.attributeType}
                    </div></div>
                <div className='col-6'>
                    <div>
                        <div className="d-flex flex-row justify-content-between">
                            {attribute.attributeValue}
                        </div></div>
                </div>
            </div>);
        });
        return attributeHtmlItem;
    }

    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift({
            location: 'after',
            widget: 'dxButton',
            options: {
                icon: 'refresh',
                onClick: this.getItemMaster.bind(this)
            }
        });
        e.toolbarOptions.items.unshift({
            location: 'before',
            template: ''
        },
        )
    }

    handleNumericChange = (event) => {
        const newValue = event.value;
        this.setState({ "unitPrice": newValue });
    }

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        let errors = this.state.errors;

        switch (name) {
            case 'itemName':
                if (alphanumericValidation.test(event.target.value))
                    this.setState({ itemName: event.target.value });
                break;
            case 'itemCode':
                if (alphanumericValidation.test(event.target.value))
                    this.setState({ itemCode: event.target.value });
                break;
            case 'itemDescription':
                if (alphanumericSpecialcaseValidation.test(event.target.value))
                    this.setState({ itemDescription: event.target.value });
                break;
            default:
                this.setState({ [name]: value });
                break;
        }
        //this.state.errors[name] = "";
        // this.setState({
        //     errors: {
        //         [name]: "",
        //     }
        // });

        this.setState({
            errors: errors
        });
    }

    handleItemModal = async (e) => {
        this.setState({
            itemModal: true,
            singelData: null
        });
    }

    editItemModal = (e, data) => {
        e.preventDefault();
        if (data != null) {
            this.setState({
                itemModal: true,
                singelData: data,
                id: data.id,
                itemName: data.name,
                itemCode: data.code,
                categoryId: data.categoryId,
                itemDescription: data.description,
                unitPrice: data.unitPrice,
                wefDate: formatDate(data.wefDate, 'dd/mm/yyyy'),
            });
            setTimeout(() => this.getItemAttribute(data.id), 100);
        }
    }

    viewItemAttributeModal = (e, data) => {
        e.preventDefault();
        if (data != null) {
            this.getItemAttribute(data.id);
            this.setState({
                itemAttributeModelIsOpen: true,
                itemName: data.name,
            })
        }
    }

    logEvent(eventName) {
        this.setState((state) => ({ events: [eventName].concat(state.events) }));
    }

    closeItemModal = () => {
        this.setState({
            isSubmited: false,
            loadPanelVisible: false,
            attributes: [],
            itemModal: false,
            itemName: "",
            itemCode: "",
            categoryId: 0,
            category: "",
            itemDescription: "",
            unitPrice: 0.00,
            wefDate: new Date(),
            attributeRequests: [],
            id: 0,
            errors: {
                itemName: "",
                itemCode: "",
                categoryId: 0,
                category: "",
                itemDescription: "",
                unitPrice: 0.00,
                wefDate: new Date(),
                attributes: "",
            }
        });
    }

    closeItemAttributeModal = () => {
        this.setState({
            isSubmited: false,
            loadPanelVisible: false,
            itemAttributeModelIsOpen: false,
            attributes: [],
            itemModal: false,
            itemName: "",
            itemCode: "",
            categoryId: 0,
            category: "",
            itemDescription: "",
            unitPrice: 0.00,
            wefDate: new Date(),
            attributeRequests: [],
            id: 0,
            errors: {
                itemName: "",
                itemCode: "",
                categoryId: 0,
                category: "",
                itemDescription: "",
                unitPrice: 0.00,
                wefDate: new Date(),
                attributes: "",
            }
        });
    }

    handleValidation = () => {
        let formIsValid = true;
        try {
            let errors = this.state.errors;
            errors.itemName = "";
            errors.itemCode = "";
            errors.categoryId = 0;
            errors.itemDescription = "";
            errors.unitPrice = 0.00;
            errors.attributes = "";

            if (this.state.itemName.trim() === "") {
                formIsValid = false;
                errors.itemName = "Please enter item name.";
            }
            if (this.state.itemCode.trim() === "") {
                formIsValid = false;
                errors.itemCode = "Please select item code.";
            }
            if (this.state.categoryId === 0) {
                formIsValid = false;
                errors.categoryId = "Please select item category.";
            }
            if (this.state.itemDescription.trim() === "") {
                formIsValid = false;
                errors.itemDescription = "Please enter item description.";
            }
            if (parseFloat(this.state.unitPrice) === 0 || this.state.unitPrice === null) {
                formIsValid = false;
                errors.unitPrice = "Please enter unit price.";
            }
            if (this.state.attributes.length === 0) {
                formIsValid = false;
                errors.attributes = "Please add attribute(s).";
            }
            this.setState({ errors: errors });
        }
        catch (err) {
            console.log("Error in " + this.displayName + ".handleValidation function", err);
            this.setState({ isSubmited: false })
        }
        return formIsValid;
    }

    handleCategoryChange = (selectedOption) => {
        this.setState({
            categoryId: (selectedOption.selectedItem !== null ? selectedOption.selectedItem.id : 0),
            category: (selectedOption.selectedItem !== null ? selectedOption.selectedItem.name : "")
        });

        let errors = this.state.errors;
        if (selectedOption.selectedItem !== null && selectedOption.selectedItem.id > 0) {
            //this.state.errors["categoryId"] = "";
            errors.categoryId = "";
            this.setState({
                errors: errors
            });
        }
    }

    handleWefDateChanged = (date) => {
        this.setState({ wefDate: date.value });
    }

    handleItemSubmit = async (e) => {
        e.preventDefault();
        try {
            if (this.handleValidation()) {
                const dataSource = this.refs.grdAttributesRef.instance.getDataSource();
                var attributeArray = dataSource._items;

                this.setState({ isSubmited: true, attributes: attributeArray });
                var newObject = new Object();
                newObject.Code = this.state.itemCode.trim();
                newObject.Name = this.state.itemName.trim();
                newObject.ItemCode = this.state.itemCode.trim();
                newObject.Category = this.state.category.trim();
                newObject.CategoryId = this.state.categoryId;
                newObject.Description = this.state.itemDescription.trim();
                newObject.UnitPrice = parseFloat(this.state.unitPrice);
                newObject.WefDate = formatDate(this.state.wefDate, "MM/dd/yyyy hh:mm");
                newObject.CreatedBy = this.state.sessionUser.id;
                newObject.CreatedOn = new Date();
                newObject.UpdatedBy = this.state.sessionUser.id;
                newObject.UpdatedOn = new Date();

                var attributeList = [];
                attributeArray.forEach((element, index) => {
                    var itemAttribute = {};
                    itemAttribute.ItemId = 0;
                    itemAttribute.AttributeId = element.attributeId;
                    itemAttribute.AttributeValue = element.attributeValue;
                    attributeList.push(itemAttribute);
                });
                newObject.AttributeList = attributeList;
                const requestParams = getRequestParams('POST', newObject);
                const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'items/' + this.state.id + '/item', requestParams);
                const data = await response.json();
                if (data.isSuccess) {
                    Swal.fire({
                        icon: 'success',
                        title: 'Success',
                        text: data.message,
                    }).then((result) => {
                        if (result.isConfirmed) {
                            this.closeItemModal();
                            setTimeout(() => this.getItemMaster(), 100);
                        }
                        this.setState({ isSubmited: false })
                    });
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: data.message,
                    }).then((result) => {
                        if (result.isConfirmed) {
                        }
                        this.setState({ isSubmited: false })
                    });
                    // toast.error(data.message, {
                    //     position: toast.POSITION.TOP_CENTER,
                    //     autoClose: 2000,
                    //     style: { width: "310px" },
                    //     hideProgressBar: false,
                    //     newestOnTop: false,
                    //     closeButton: false
                    // });
                    this.setState({ isSubmited: false })
                }
            }
        } catch (err) {
            console.log("Error in " + this.displayName + ".handleItemSubmit function", err);
            this.setState({ isSubmited: false })
        }
    }
    handleFilterCategoryChange = (selectedOption) => {
        this.setState({
            filterCategoryId: (selectedOption.selectedItem !== null ? selectedOption.selectedItem.id : 0),
        });
    }

    handleHsnCodeChange = (selectedOption) => {
        this.setState({
            hsncodeId: (selectedOption.selectedItem !== null ? selectedOption.selectedItem.id : 0),
        });
    }

    onEditorPreparing = (e) => {
        if (e.parentType === "dataRow" && e.dataField === "attributeTypeId") {
            e.editorOptions.onValueChanged = (ev) => {
                let selectedItem = ev.component.option("selectedItem");
                e.setValue(selectedItem);
            };
        }
    }

    onEditingStart = (e) => {
        setTimeout(() => this.getAttribute(e.data.attributeTypeId), 500);
    }

    setCellValue = (rowData, value) => {
        rowData.attributeTypeId = value.id;
        setTimeout(() => this.getAttribute(value.id), 500);
    }

    // setAttribute = (e) => {
    //     this.getAttribute(e.data.attributeTypeId);
    //     this.setState({
    //         attributeTypeId: e.data.attributeTypeId
    //     });
    // }

    toolbarItemRender = () => {
        return (
            <Fragment>
                <div className='filter-header'>
                    <div className="row">
                        <div className="col">
                            <div className="row">
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                    <SelectBox
                                        dataSource={this.state.categoryList}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        placeholder="Select category"
                                        name="id"
                                        defaultValue={0}
                                        value={this.state.filterCategoryId}
                                        displayExpr='name'
                                        valueExpr='id'
                                        onSelectionChanged={this.handleFilterCategoryChange}
                                        showClearButton={true}
                                        disabled={false}
                                        searchEnabled={true}
                                    />
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                    <button type="submit" className="btn btn-success" onClick={this.getItemMaster}>
                                        <span title="Search"><i className="fas fa-search"></i> Search</span>
                                    </button>&nbsp;&nbsp;
                                    <button type="submit" className="btn btn-outline-secondary" onClick={this.handleClear}>
                                        <span title="Clear"><i className="fas fa-eraser"></i> Clear</span>
                                    </button>&nbsp;&nbsp;
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }

    onExporting = (e) => {
        const doc = new jsPDF();
        if (e.format === "pdf") {
            exportDataGrid({
                jsPDFDocument: doc,
                component: e.component,
                columnWidths: 50
                // indent: 10,
            }).then(() => {
                doc.save('ItemMaster.pdf');
            });
        }
    }

    handleClear = () => {
        this.setState({
            filterCategoryId: 0,
            hsncodeId: 0
        });
        setTimeout(() => this.getItemMaster(), 100);
    }
    onRowUpdating = (e) => {
    }
    onRowUpdated = (e) => {

    }
    render() {
        const renderGridCell = (data) => {
            return <div>
                <a href="#" className="fas fa-pen" title="Edit" onClick={(e) => { this.editItemModal(e, data.data) }} ></a>&nbsp;&nbsp;
            </div>;
        }
        const renderAttributeGridCell = (data) => {
            return <div>
                <a href="#" className="fas fa-list" title="Attribute(s)" onClick={(e) => { this.viewItemAttributeModal(e, data.data) }} ></a>&nbsp;&nbsp;
            </div>;
        }

        const renderGridNumberCell = (data) => {
            return <div><small><i className={currencySymbolFormats.currencyFormat}></i></small>&nbsp;&nbsp;{parseFloat(data.value).toFixed(precisionFormats[2])}</div>;
        }

        return (
            <Fragment>
                <div className="section-body">
                    <div className='card bg-filter'>
                        <div className='card-body'>
                            <div className="section-header">
                                <div className='row'>
                                    <div className="col-lg-2">
                                        <div className="form-group">
                                            <label htmlFor="">Item Category</label>
                                            <SelectBox
                                                dataSource={this.state.categoryList}
                                                className="basic-multi-select"
                                                classNamePrefix="select"
                                                placeholder="Select  item category"
                                                name="id"
                                                defaultValue={0}
                                                value={this.state.filterCategoryId}
                                                displayExpr='name'
                                                valueExpr='id'
                                                onSelectionChanged={this.handleFilterCategoryChange}
                                                showClearButton={true}
                                                disabled={false}
                                                searchEnabled={true}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-lg-2">
                                        <div className="form-group">
                                            <label htmlFor="">HSN Code</label>
                                            <SelectBox
                                                dataSource={this.state.hsncodeList}
                                                className="basic-multi-select"
                                                classNamePrefix="select"
                                                placeholder="Select HSN Code"
                                                name="id"
                                                defaultValue={0}
                                                value={this.state.hsncodeId}
                                                displayExpr='code'
                                                valueExpr='id'
                                                onSelectionChanged={this.handleHsnCodeChange}
                                                showClearButton={true}
                                                disabled={false}
                                                searchEnabled={true}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-2 col-md-2 col-sm-12 col-xs-12 pl-0">
                                        <div className="form-group">
                                            <label htmlFor="">&nbsp;</label><br />
                                            <button type="submit" className="btn btn-success" title='Search' onClick={this.handleSearchItem}>
                                                Search
                                            </button>&nbsp;&nbsp;
                                            <button type="submit" className="btn btn-outline-secondary" title='Clear' onClick={this.handleClear}>
                                                Clear
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <button type="submit" className="btn btn-success" title='New' onClick={this.handleItemModal}>
                                        New
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='card'>
                        <div className='card-body'>
                            <DataGrid
                                dataSource={this.state.itemSource}
                                keyExpr="id"
                                id='gridItemMasterList'
                                selection={{ mode: 'single' }}
                                columnsAutoWidth={false}
                                showColumnLines={true}
                                showRowLines={true}
                                showBorders={true}
                                rowAlternationEnabled={false}
                                allowColumnResizing={true}
                                columnResizingMode='widget'
                                //columnMinWidth={100}
                                onExporting={this.onExporting}
                                onToolbarPreparing={this.onToolbarPreparing}>
                                <Column dataField="id" width={40} caption="" allowFiltering={false} allowSorting={false} cellRender={renderGridCell} />
                                <Column dataField="name" caption="Name" allowSearch={true} allowFiltering={false} allowSorting={true} width={180} />
                                <Column dataField="code" caption="Code" allowSearch={true} allowFiltering={false} allowSorting={true} width={80} />
                                <Column dataField="category" caption="Category" allowSearch={true} allowFiltering={false} allowSorting={true} width={180} />
                                <Column dataField="hsnCode" caption="HSN Code" allowSearch={true} allowFiltering={false} allowSorting={true} width={90} />
                                {/* <Column dataField="description" caption="Description" allowSearch={true} allowFiltering={false} allowSorting={true} width={500} /> */}
                                {/* <Column dataField="wefDate" caption="Wef Date" allowSearch={true} allowFiltering={false} allowSorting={true} width={150} format={DateFormat.DateTime24NorthFormat} dataType="datetime" /> */}
                                <Column dataField="unitPrice" caption="Unit Price" allowSearch={true} allowFiltering={false} allowSorting={true} width={100} dataType="number"
                                    format={"#,##0.###"}
                                    cellRender={renderGridNumberCell}
                                />
                                <Column dataField="id" caption="Attributes" allowFiltering={false} allowSorting={false} cellRender={renderAttributeGridCell} width={80} />
                                {/* <Column dataField="description" caption="Description" allowSearch={true} allowFiltering={false} allowSorting={true} width={750} />
                                    <Column dataField="wefDate" caption="Wef Date" allowSearch={true} allowFiltering={false} allowSorting={true} width={150} format={DateFormat.DateTime24NorthFormat} dataType="datetime" /> */}
                                <SortByGroupSummaryInfo summaryItem="count" />
                                <GroupPanel visible={false} />
                                <HeaderFilter visible={true} />
                                <Template name="toolbarTemplate" render={this.toolbarItemRender} />
                                <SearchPanel visible={true} highlightCaseSensitive={true} width={240} placeholder="Search..." />
                                <Grouping autoExpandAll={true} />
                                <Paging defaultPageSize={20} />
                                <Pager showPageSizeSelector={true} allowedPageSizes={[10, 20, 50]} showInfo={true} visible={true} />
                                <Export enabled={true} allowExportSelectedData={false} fileName="ItemMaster" />
                            </DataGrid>
                        </div>
                    </div>
                </div>
                <Modal ariaHideApp={false}
                    isOpen={this.state.itemAttributeModelIsOpen}
                    style={customItemAttributeStyles}
                    className={"react-modal"}
                    onRequestClose={this.closeItemAttributeModal}
                    shouldCloseOnOverlayClick={false}
                    contentLabel="Example Modal">
                    <form method="post" style={this.state.isSubmited ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                        <div className="modal-header">
                            <h6 className="modal-title">Attributes - {this.state.itemName}</h6>
                        </div>
                        <div className="modal-body">

                            <DataGrid
                                dataSource={this.state.attributes}
                                //   keyExpr="id"
                                id='gridItemMasterList'
                                selection={{ mode: 'single' }}
                                columnsAutoWidth={false}
                                showColumnLines={true}
                                showRowLines={true}
                                showBorders={true}
                                rowAlternationEnabled={false}
                                allowColumnResizing={true}
                                columnResizingMode='widget'
                            //columnMinWidth={100}
                            >
                                <Column dataField="attributeType" width={120} caption="Attribute Type" allowFiltering={false} allowSorting={false} />
                                <Column dataField="attributeValue" caption="Attribute Value" allowSearch={true} allowFiltering={false} allowSorting={true} width={200} />

                                <SortByGroupSummaryInfo summaryItem="count" />
                                <GroupPanel visible={false} />
                                <HeaderFilter visible={true} />
                                <Template name="toolbarTemplate" render={this.toolbarItemRender} />
                                <SearchPanel visible={false} highlightCaseSensitive={true} width={240} placeholder="Search..." />
                                <Grouping autoExpandAll={true} />
                                <Paging defaultPageSize={20} />
                                <Pager showPageSizeSelector={true} allowedPageSizes={[10, 20, 50]} showInfo={true} visible={true} />
                                {/* <Export enabled={true} formats={exportFormats} allowExportSelectedData={false} fileName="ItemMaster" /> */}
                            </DataGrid>
                            {/* {this.state.attributeHtml} */}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-outline-secondary" onClick={this.closeItemAttributeModal} title="Cancel">Cancel</button>
                        </div>
                    </form>
                </Modal>

                <Modal ariaHideApp={false}
                    isOpen={this.state.itemModal}
                    style={customStyles}
                    className={"react-modal"}
                    //onAfterOpen={this.handleAssignDataToState}
                    onRequestClose={this.closeItemModal}
                    shouldCloseOnOverlayClick={false}
                    contentLabel="Example Modal">
                    <form method="post" onSubmit={this.handleItemSubmit} style={this.state.isSubmited ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                        <div className="modal-header">
                            <h6 className="modal-title">{this.state.id > 0 ? "Update Item" : "New Item"}</h6>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-lg-3">
                                    <div className="form-group">
                                        <label>Name <span className="text-danger">*</span></label>
                                        <input type="text" className="form-control form-control-sm" name="itemName" placeholder="Enter item name" value={this.state.itemName}
                                            disabled={this.state.id > 0} title="Item Name" maxLength="50" onChange={this.handleInputChange} />
                                        {this.state.errors["itemName"].length > 0 && <span className="error invalid-feedback">{this.state.errors["itemName"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-2">
                                    <div className="form-group">
                                        <label htmlFor="">Code <span className="text-danger">*</span></label>
                                        <input type="text" className="form-control form-control-sm" name="itemCode" placeholder="Enter item code" maxLength="20" title="Item Code" value={this.state.itemCode}
                                            onChange={this.handleInputChange} />
                                        {this.state.errors["itemCode"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["itemCode"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div className="form-group">
                                        <label>Category <span className="text-danger">*</span></label>
                                        <SelectBox
                                            dataSource={this.state.categoryList}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select category"
                                            name="id"
                                            defaultValue={0}
                                            value={this.state.categoryId}
                                            displayExpr='name'
                                            valueExpr='id'
                                            onSelectionChanged={this.handleCategoryChange}
                                            showClearButton={true}
                                            disabled={false}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["categoryId"].length > 0 && <span className="error invalid-feedback">{this.state.errors["categoryId"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-2">
                                    <div className="form-group">
                                        <label htmlFor="">Unit Price <span className="text-danger">*</span></label>
                                        <NumberBox type="number" className="form-control form-control-sm" name="unitPrice" placeholder="Enter unit price"
                                            defaultValue={0.00}
                                            title="Unit Price" value={this.state.unitPrice} onValueChanged={this.handleNumericChange} min={0} />
                                        {this.state.errors["unitPrice"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["unitPrice"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-3">
                                    <div className="form-group">
                                        <label>Wef Date <span className="text-danger">*</span></label>
                                        <DateBox type="date"
                                            className="replay-date-time"
                                            name="wefDate"
                                            placeholder="dd/MM/yyyy"
                                            displayFormat="dd/MM/yyyy"
                                            showAnalogClock={true}
                                            useMaskBehavior={true}
                                            defaultValue={this.state.wefDate}
                                            value={this.state.wefDate}
                                            onValueChanged={this.handleWefDateChanged}
                                            width="100%"
                                            disabled={this.state.id > 0}
                                            acceptCustomValue={false}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-5">
                                    <div className="form-group">
                                        <label htmlFor="">Description <span className="text-danger">*</span></label>
                                        <textarea type="text" className="form-control form-control-sm" name="itemDescription" autoComplete="itemDescription" placeholder="Enter item description"
                                            maxLength="150" title="Item Description" value={this.state.itemDescription} onChange={this.handleInputChange} />
                                        {this.state.errors["itemDescription"].length > 0 && <span className="error invalid-feedback">{this.state.errors["itemDescription"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    {/* <h6>Attributes</h6> */}
                                    <DataGrid
                                        ref="grdAttributesRef"
                                        dataSource={this.state.attributes}
                                        keyExpr="id"
                                        allowColumnReordering={true}
                                        showBorders={true}
                                        // onRowUpdating={this.onRowUpdating}
                                        // onRowUpdated={this.onRowUpdated}
                                        onEditorPreparing={this.onEditorPreparing}
                                        onEditingStart={this.onEditingStart}>
                                        <Paging enabled={true} />
                                        <Editing mode="row" allowUpdating={true} allowDeleting={true} allowAdding={true} />
                                        <Column dataField="attributeTypeId" caption="Attribute Type" setCellValue={this.setCellValue}>
                                            <Lookup dataSource={this.state.attributeTypeList} displayExpr="name" valueExpr="id" />
                                            <RequiredRule />
                                        </Column>
                                        <Column dataField="attributeId" caption="Attribute Name">
                                            <Lookup dataSource={this.state.attributeList} displayExpr="name" valueExpr="id" />
                                            <RequiredRule />
                                        </Column>
                                        <Column dataField="attributeValue" Caption="Attribute Value">
                                            <RequiredRule />
                                            <PatternRule
                                                message={'Attribute value should be alphanumeric'}
                                                pattern={alphanumericValidation} />
                                        </Column>
                                    </DataGrid>
                                    {this.state.errors["attributes"].length > 0 && <span className="error invalid-feedback">{this.state.errors["attributes"]}</span>}
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="submit" className="btn btn-success" onClick={this.handleItemSubmit} >
                                {this.state.isSubmited && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                {!this.state.isSubmited && <span title="Submit">Submit</span>}
                            </button>
                            <button type="button" className="btn btn-outline-secondary" onClick={this.closeItemModal} title="Cancel">Cancel</button>
                        </div>
                    </form>
                </Modal>

                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={position}
                    visible={this.state.loadPanelVisible}
                    showIndicator={true}
                    shading={true}
                    showPane={true}
                    hideOnOutsideClick={false}
                />
            </Fragment>
        )
    };
}