
import DataGrid, {
    Column,
    Export, Grouping,
    GroupPanel,
    HeaderFilter, Pager,
    Paging,
    SearchPanel,
    Selection,
    Editing,
    SortByGroupSummaryInfo
} from 'devextreme-react/data-grid'; //npm i devextreme-react
import React, { Fragment, Component } from "react";
import { formatDate, getRequestParams } from '../../helpers/globalfunctions.js';
import Modal from 'react-modal'; //npm install --save react-modal;
import { SelectBox } from 'devextreme-react/select-box';
import { DateFormat } from '../../helpers/fixcodes.js';
import Swal from 'sweetalert2'; //npm i sweetalert2 --save
import { LoadPanel } from 'devextreme-react/load-panel';
import DateBox from 'devextreme-react/date-box';
import axios from 'axios';
const position = { of: '#historydiv' };

const customStyles = {
    content: {
        width: '50%',
    }
}
var billingType = [
    { id: 1, name: "Advance" }
    , { id: 2, name: "Post" }
]

export class ContractItemDetailComponent extends Component {
    static displayName = ContractItemDetailComponent.name;

    constructor(props) {
        super(props);
        this.state = {
            loadPanelVisible: false,
            itemModelIsOpen: false,
            sessionUser: JSON.parse(sessionStorage.getItem("sessionUser")),
            customerId: this.props.location.state === undefined ? 0 : this.props.location.state.customerId,
            soNo: this.props.location.state === undefined ? "" : this.props.location.state.soNo,
            contractId: this.props.location.state === undefined ? 0 : this.props.location.state.contractId,
            startDate: this.props.location.state === undefined ? new Date() : this.props.location.state.startDate,
            endDate: this.props.location.state === undefined ? new Date() : this.props.location.state.endDate,
            customerList: null,
            typeList: [],
            constractTypeId: this.props.location.state === undefined ? 0 : this.props.location.state.contractTypeId,
            statusList: [],
            contractStatusId: this.props.location.state === undefined ? 0 : this.props.location.state.contractStatusId,
            billingTypeList: billingType,
            billingTypeName: this.props.location.state === undefined ? "" : this.props.location.state.billingTypeName,
            paymentTermTypeList: [],
            paymentTermTypeId: this.props.location.state === undefined ? 0 : this.props.location.state.paymentTermTypeId,
            selectedRowKey: [],
            selectedContractItemRowKey: [],
            vehicleDeviceList: [],
            unmappedDeviceList: [],
            referenceNo: "",
            syncModelIsOpen: false,
            selectedUnmappedRowKey: [],
            file: null,
            errors: {
                file: null,
            }
        }
    }

    //lifecycle page load
    async componentDidMount() {
        await this.getContractStatus();
        await this.getContractType();
        await this.getPaymentTermType();
        await this.getCustomer();
        await this.getVehicleDeviceList();
        await this.getUnmmapedItem();
    }
    getPaymentTermType = async () => {
        try {
            var request = {};
            const requestParams = getRequestParams("GET", request);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'paymentTermType/dropdownType', requestParams);
            const data = await response.json();
            this.setState({
                paymentTermTypeList: data.payload
            });
        } catch (err) {
            console.log("Error in " + this.displayName + ".getPaymentTermType function", err);
        }
    }
    getContractStatus = async () => {
        try {
            var request = {};
            const requestParams = getRequestParams("GET", request);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'contractConfiguration/contractStatus/0', requestParams);
            const data = await response.json();
            this.setState({
                statusList: data.payload
            });
        } catch (err) {
            console.log("Error in " + this.displayName + ".getContractList function", err);
        }
    }
    getContractType = async () => {
        try {
            var request = {};
            const requestParams = getRequestParams("GET", request);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'contractConfiguration/contractType/0', requestParams);
            const data = await response.json();
            this.setState({
                typeList: data.payload
            });
        } catch (err) {
            console.log("Error in " + this.displayName + ".getContractList function", err);
        }
    }
    getCustomer = async () => {
        try {
            var request = {};
            const requestParams = getRequestParams("GET", request);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'account/dropdownAccount/2', requestParams);
            const data = await response.json();
            this.setState({
                customerList: data.payload
            });
        } catch (err) {
            console.log("Error in " + this.displayName + ".getContractList function", err);
        }
    }
    handleStartDateChanged = (date) => {
        this.setState({ startDate: date.value });
    }

    handleEndDateChanged = (date) => {
        this.setState({ endDate: date.value });
    }
    handleCustomerChange = (selectedOption) => {
        this.setState({
            customerId: (selectedOption.selectedItem !== null ? selectedOption.selectedItem.id : 0),
        });
    }
    handleBillingTypeChange = (selectedOption) => {
        this.setState({
            billingTypeName: (selectedOption.selectedItem !== null ? selectedOption.selectedItem.name : ""),
        });
    }
    handleContractStatusChange = (selectedOption) => {
        this.setState({
            contractStatusId: (selectedOption.selectedItem !== null ? selectedOption.selectedItem.id : 0),
        });
    }
    handleContractTypeChange = (selectedOption) => {
        this.setState({
            constractTypeId: (selectedOption.selectedItem !== null ? selectedOption.selectedItem.id : 0),
        });
    }
    handlePaymentTermTypeChange = (selectedOption) => {
        this.setState({
            paymentTermTypeId: (selectedOption.selectedItem !== null ? selectedOption.selectedItem.id : 0),
        });
    }
    getVehicleDeviceList = async () => {
        try {
            var request = {};
            request.SONo = this.state.soNo;
            request.CustomerId = this.state.customerId;
            const requestParams = getRequestParams("POST", request);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'contractItem/getContractItemDetails', requestParams);
            const data = await response.json();
            this.setState({
                vehicleDeviceList: data.payload,
                referenceNo: data.payload === null ? "" : data.payload[0].referenceSONo,
            });

        }
        catch (err) {
            console.log("Error in " + this.displayName + ".getContractItemList function", err);
        }
    }
    getUnmmapedItem = async () => {
        try {
            var request = {};
            const requestParams = getRequestParams("GET", request);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'contractItem/getUnmappedContractItem', requestParams);
            const data = await response.json();
            this.setState({
                unmappedDeviceList: data.payload
            });
        } catch (err) {
            console.log("Error in " + this.displayName + ".getUnmmapedItem function", err);
        }
    }
    handleMultipleSelectionChanged = (e) => {
        this.setState({ selectedRowKey: e.selectedRowKeys })
    }
    handleMultipleContractItemUnmappedSelectionChanged = (e) => {
        this.setState({ selectedContractItemRowKey: e.selectedRowKeys })
    }
    handleMultipleUnmappedSelectionChanged = (e) => {
        this.setState({ selectedUnmappedRowKey: e.selectedRowKeys })
    }
    handleCancel = () => {
        window.location.href = "/contractitem";
    }

    openItemModel = () => {
        this.setState({ itemModelIsOpen: true });
        setTimeout(() => {
            this.getUnmmapedItem();
        }, 100);
    }
    closeItemModel = () => {
        this.setState({ itemModelIsOpen: false });
    }
    openItemSyncModel = () => {
        this.setState({ syncModelIsOpen: true });
        setTimeout(() => {
            this.getUnmmapedItem();
        }, 100);
    }
    closeItemSyncModel = () => {
        this.setState({ syncModelIsOpen: false });
    }
    handleUnmappedContractItem = async (e) => {
        e.preventDefault();
        try {
            if (this.state.selectedRowKey != null && this.state.selectedRowKey.length === 0) {
                Swal.fire({
                    icon: 'error',
                    text: "Please select at least one row.",
                }).then((result) => {
                    this.setState({ isSwapTrip: false })
                });
                return;
            }
            let itemList = [];
            let selectedRowKeys = this.state.selectedRowKey;
            let devicelIst = this.state.vehicleDeviceList;
            let list = this.state.vehicleDeviceList.filter((v) => selectedRowKeys.filter((id) => id === v.id).length > 0);
            let _list = devicelIst.filter((elem) => !list.find(({ id }) => elem.id === id));
            
            list.map((gridData, i) => {
                let newgrid = new Object();
                newgrid.Id = gridData.id;
                newgrid.ContractId = gridData.contractId;
                newgrid.CustomerId = gridData.customerId;
                newgrid.StockItemId = gridData.stockItemId;
                newgrid.SerialNo = gridData.serialNo;
                newgrid.VehicleNo = gridData.vehicleNo;
                newgrid.ReferenceSONo = gridData.referenceSONo
                itemList.push(newgrid);
            });

            Swal.fire({
                html: "This will update qty in contract as well, do you want to continue?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes',
                allowOutsideClick: false
            }).then((result) => {
                if (result.isConfirmed) {
                    this.setState({ loadPanelVisible: true });
                    var newObject = {};
                      newObject.contractItem = itemList;
                      newObject.CustomerId = this.state.customerId;
                      newObject.ContractId = this.state.contractId;
                      newObject.SONo = this.state.soNo;
                      newObject.ReferenceSONo = this.state.referenceNo;
                      newObject.UserId = this.state.sessionUser.id;
                      newObject.Qty =_list.length; 
                      this.setState({ loadPanelVisible: true });
                    const requestParams = getRequestParams('POST', newObject);
                    fetch(process.env.REACT_APP_API_BASE_URL + 'contractItem/unmappedContractItem', requestParams)
                        .then(response => { if (response.ok) return response.json() })
                        .then(data => {
                            this.setState({ loadPanelVisible: false });
                            if (data.isSuccess) {
                                Swal.fire({
                                    icon: data.isSuccess === true ? 'success' : 'error',
                                    text: data.message,
                                    // text: data.message,
                                }).then((result) => {
                                    if (data.isSuccess === true) {
                                        if (result.isConfirmed) {
                                            this.getVehicleDeviceList()
                                            //this.clearData();
                                        }
                                    }
                                })
                            }
                        });
                }
            })






            // var newObject = {};
            // newObject.contractItem = itemList;
            // newObject.CustomerId = this.state.customerId;
            // newObject.SONo = this.state.soNo;
            // newObject.UserId = this.state.sessionUser.id;
            // this.setState({ loadPanelVisible: true });
            // const requestParams = getRequestParams('POST', newObject);
            // const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'contractItem/unmappedContractItem', requestParams);
            // const data = await response.json();
            // this.setState({ loadPanelVisible: false });
            // if (data.isSuccess) {
            //     Swal.fire({
            //         icon: 'success',
            //         title: 'Success',
            //         text: data.message,
            //     }).then((result) => {
            //         if (result.isConfirmed) {
            //             this.getVehicleDeviceList();
            //             //this.closeVehicleDeviceModel();
            //         }
            //         this.setState({ isSubmited: false })
            //     })
            // }
            // else {
            //     Swal.fire({
            //         icon: 'oops',
            //         title: 'oops',
            //         text: data.message,
            //     }).then((result) => {
            //         if (result.isConfirmed) {
            //             this.getVehicleDeviceList();
            //             //this.closeVehicleDeviceModel();
            //         }
            //         this.setState({ isSubmited: false })
            //     })
            // }
            // this.setState({ isSubmited: false })


        } catch (err) {
            console.log("Error in " + this.displayName + ".handleItemSubmit function", err);
            this.setState({ isSubmited: false })
        }
    }
    handleMapContractItemSubmit = async (e) => {
        e.preventDefault();
        try {
            if (this.state.selectedContractItemRowKey != null && this.state.selectedContractItemRowKey.length === 0) {
                Swal.fire({
                    icon: 'error',
                    text: "Please select at least one row.",
                }).then((result) => {
                    this.setState({ isSwapTrip: false })
                });
                return;
            }
            let itemList = [];
            let selectedRowKeys = this.state.selectedContractItemRowKey;
            let list = this.state.unmappedDeviceList.filter((v) => selectedRowKeys.filter((id) => id === v.stockItemId).length > 0);
            list.map((gridData, i) => {
                let newgrid = new Object();
                newgrid.Id = gridData.id;
                newgrid.SerialNo = gridData.serialNo;
                newgrid.StockItemId = gridData.stockItemId;
                newgrid.VehicleNo = gridData.vehicleNo
                itemList.push(newgrid);
            });
            let itemqty = this.state.vehicleDeviceList == null ? 0 :this.state.vehicleDeviceList.length
            
           var message = "The contract total item qty is" + '(' + itemqty +')' + "." +"<br/>"+
                              "Do you want to add new item(s)"+ "(" +list.length +")"+ "to this contract?"
                              
            Swal.fire({
                html: message,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes',
                allowOutsideClick: false
            }).then((result) => {
                if (result.isConfirmed) {
                    this.setState({ loadPanelVisible: true });
                    var newObject = {};
                    newObject.contractItem = itemList;
                    newObject.CustomerId = this.state.customerId;
                    newObject.SONo = this.state.soNo;
                    newObject.ReferenceSONo = this.state.referenceNo;
                    newObject.UserId = this.state.sessionUser.id;
                    newObject.ContractId = this.state.contractId;
                    newObject.UserId = this.state.sessionUser.id;
                    newObject.ItemQty = itemqty !== 0 ? parseInt(itemqty) +parseInt(list.length) :list.length;
                    newObject.ProRataQty = selectedRowKeys.length;
                     this.setState({ loadPanelVisible: true });
                    const requestParams = getRequestParams('POST', newObject);
                    fetch(process.env.REACT_APP_API_BASE_URL + 'contractItem/mappedContractItem', requestParams)
                        .then(response => { if (response.ok) return response.json() })
                        .then(data => {
                            this.setState({ loadPanelVisible: false });
                            if (data.isSuccess) {
                                Swal.fire({
                                    icon: data.isSuccess === true ? 'success' : 'error',
                                    text: data.message,
                                    // text: data.message,
                                }).then((result) => {
                                    if (data.isSuccess === true) {
                                        if (result.isConfirmed) {
                                            this.getVehicleDeviceList();
                                            this.getUnmmapedItem();
                                            this.closeItemModel();
                                        }
                                    }
                                })
                            }
                        });
                }
            })



            // var newObject = {};
            // newObject.contractItem = itemList;
            // newObject.CustomerId = this.state.customerId;
            // newObject.SONo = this.state.soNo;
            // newObject.UserId = this.state.sessionUser.id;
            // newObject.ReferenceSONo = this.state.referenceNo;
            // newObject.ContractId = this.state.contractId;
            // this.setState({ loadPanelVisible: true });
            // const requestParams = getRequestParams('POST', newObject);
            // const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'contractItem/mappedContractItem', requestParams);
            // const data = await response.json();
            // this.setState({ loadPanelVisible: false });
            // if (data.isSuccess) {
            //     Swal.fire({
            //         icon: 'success',
            //         title: 'Success',
            //         text: data.message,
            //     }).then((result) => {
            //         if (result.isConfirmed) {
            //             this.getVehicleDeviceList();
            //             this.getUnmmapedItem();
            //             this.closeItemModel();
            //         }
            //         this.setState({ isSubmited: false })
            //     })
            // }


        } catch (err) {
            console.log("Error in " + this.displayName + ".handleItemSubmit function", err);
            this.setState({ isSubmited: false })
        }
    }
    handleUpdateContractItem = async (e) => {
        e.preventDefault();
        try {

            let itemList = [];
            let selectedRowKeys = this.state.selectedRowKey;
            let list = this.state.vehicleDeviceList.filter((v) => selectedRowKeys.filter((id) => id === v.id).length > 0);
            list.map((gridData, i) => {
                let newgrid = new Object();
                newgrid.Id = gridData.id;
                newgrid.ContractId = gridData.contractId;
                newgrid.CustomerId = gridData.customerId;
                newgrid.StockItemId = gridData.stockItemId;
                newgrid.SerialNo = gridData.serialNo;
                newgrid.VehicleNo = gridData.vehicleNo;
                newgrid.ReferenceSONo = gridData.referenceSONo
                itemList.push(newgrid);
            });
            var newObject = {};
            newObject.contractItem = itemList;
            newObject.Id = this.state.contractId;
            newObject.CustomerId = this.state.customerId;
            newObject.SONo = this.state.soNo;
            newObject.UserId = this.state.sessionUser.id;
            newObject.ContractStatusId = this.state.contractStatusId;
            newObject.ContractTypeId = this.state.constractTypeId;
            newObject.PaymentTermsId = this.state.paymentTermTypeId;
            newObject.BillingType = this.state.billingTypeName;
            newObject.ContractStart = formatDate(this.state.startDate, "yyyy/MM/dd");
            newObject.ContractEnd = formatDate(this.state.endDate, "yyyy/MM/dd");
            this.setState({ loadPanelVisible: true });
            const requestParams = getRequestParams('POST', newObject);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'contractItem/updateContractItem', requestParams);
            const data = await response.json();
            this.setState({ loadPanelVisible: false });
            if (data.isSuccess) {
                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: data.message,
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.getVehicleDeviceList();
                        //this.closeVehicleDeviceModel();
                    }
                    this.setState({ isSubmited: false })
                })
            }
            else {
                Swal.fire({
                    icon: 'oops',
                    title: 'oops',
                    text: data.message,
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.getVehicleDeviceList();
                        //this.closeVehicleDeviceModel();
                    }
                    this.setState({ isSubmited: false })
                })
            }
            this.setState({ isSubmited: false })


        } catch (err) {
            console.log("Error in " + this.displayName + ".handleItemSubmit function", err);
            this.setState({ isSubmited: false })
        }
    }
    handleMapItemSubmit = async (e) => {
        e.preventDefault();
        try {
            if (this.state.selectedUnmappedRowKey != null && this.state.selectedUnmappedRowKey.length === 0) {
                Swal.fire({
                    icon: 'error',
                    text: "Please select at least one row.",
                }).then((result) => {
                    this.setState({ isSwapTrip: false })
                });
                return;
            }
            let itemList = [];
            let selectedRowKeys = this.state.selectedUnmappedRowKey;
            let list = this.state.unmappedDeviceList.filter((v) => selectedRowKeys.filter((id) => id === v.id).length > 0);
            list.map((gridData, i) => {
                let newgrid = new Object();
                newgrid.Id = gridData.id;
                newgrid.ContractId = gridData.contractId;
                newgrid.CustomerId = gridData.customerId;
                newgrid.StockItemId = gridData.stockItemId;
                newgrid.SerialNo = gridData.serialNo;
                newgrid.VehicleNo = gridData.vehicleNo;
                newgrid.ReferenceSONo = gridData.referenceSONo
                itemList.push(newgrid);
            });
            var newObject = {};
            newObject.contractItem = itemList;
            newObject.CustomerId = this.state.customerId;
            newObject.SONo = this.state.soNo;
            newObject.UserId = this.state.sessionUser.id;
            newObject.ReferenceSONo = this.state.referenceNo;
            newObject.ContractId = this.state.contractId;
            this.setState({ loadPanelVisible: true });
            const requestParams = getRequestParams('POST', newObject);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'contractItem/mappedItem', requestParams);
            const data = await response.json();
            this.setState({ loadPanelVisible: false });
            if (data.isSuccess) {
                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: data.message,
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.getVehicleDeviceList();
                        this.getUnmmapedItem();
                        this.closeItemModel();
                    }
                    this.setState({ isSubmited: false })
                })
            }


        } catch (err) {
            console.log("Error in " + this.displayName + ".handleItemSubmit function", err);
            this.setState({ isSubmited: false })
        }
    }

    handleAttachmentInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        let errors = this.state.errors;
        switch (name) {
            case 'file':
                errors.file = "";
                if (event.target.files.length === 0) {
                    this.setState({ file: null });
                    errors.file = "Please select attachment.";
                } else {
                    this.state.errors["file"] = '';
                    this.setState({ file: event.target.files[0] });
                }
                break;
            default:
                this.setState({
                    [name]: value
                });
                break;
        }
    }
    getAttachment = async (e) => {
        e.preventDefault();
        {
            if (this.state.file == null) {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: "Please upload file.",
                }).then((result) => {
                    this.setState({ isSubmited: false })
                });
                return;
            }
            let itemqty = this.state.vehicleDeviceList
            var message = "The contract total item qty is" + '(' + itemqty.length +')' + "." +"<br/>"+
            "Do you want to add new item(s) to this contract item?"
            Swal.fire({
                html: message,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes',
                allowOutsideClick: false
            }).then((result) => {
                if (result.isConfirmed) {
                    const url = process.env.REACT_APP_API_BASE_URL + "ImportData/ImportContractItem";
                        const formData = new FormData();
                        formData.append('files', this.state.file);
                        console.log(this.state.file);
                        //  formData.append('SoNo', this.state.soNo);
                        //  formData.append('ContractId',this.state.contractId);
                        //  formData.append("CustomerId", this.state.customerId);
                        const config = {
                            headers: {
                                'Content-Type': 'multipart/form-data',
                                'ContractId': this.state.contractId,
                                'CustomerId': this.state.customerId,
                                'SoNo': this.state.soNo,
                                'Authorization': 'Bearer ' + sessionStorage.getItem('session_token'),
                            },
                        };
                        axios.post(url, formData, config).then((response) => {
                                    var resp = response.data;
                                    this.setState({
                                        loadPanelVisible: false
                                    });
                
                           {
                                Swal.fire({
                                    icon: resp.isSuccess === true ? 'success' : 'error',
                                    text: resp.message,
                                    // text: data.message,
                                }).then((result) => {
                                    if (resp.isSuccess === true) {
                                        if (result.isConfirmed) {
                                            this.getVehicleDeviceList();
                                            this.getUnmmapedItem();
                                            this.closeItemModel();
                                        }
                                    }
                                })
                            }
                        });
                }
            })

            // try {
            //     this.setState({
            //         loadPanelVisible: true
            //     });
            //     const url = process.env.REACT_APP_API_BASE_URL + "ImportData/ImportContractItem";
            //     const formData = new FormData();
            //     formData.append('files', this.state.file);
            //     console.log(this.state.file);
            //     //  formData.append('SoNo', this.state.soNo);
            //     //  formData.append('ContractId',this.state.contractId);
            //     //  formData.append("CustomerId", this.state.customerId);
            //     const config = {
            //         headers: {
            //             'Content-Type': 'multipart/form-data',
            //             'ContractId': this.state.contractId,
            //             'CustomerId': this.state.customerId,
            //             'SoNo': this.state.soNo,
            //             'Authorization': 'Bearer ' + sessionStorage.getItem('session_token'),
            //         },
            //     };
            //     axios.post(url, formData, config).then((response) => {
            //         var resp = response.data;
            //         this.setState({
            //             loadPanelVisible: false
            //         });

            //         Swal.fire({
            //             icon: resp.isSuccess == true ? 'success' : 'error',
            //             text: "File uploaded successfully",
            //         }).then((result) => {
            //              {
            //                 if (result.isConfirmed) {
            //                     this.setState({ file: null })
            //                     this.getVehicleDeviceList()
            //                 }
            //                 this.setState({ isSubmited: false })
            //             }
                        


            //         })
            //         this.setState({
            //             loadPanelVisible: false
            //         });
            //     });
            // }
            // catch (err) {
            //     console.log("Error in " + this.displayName + ".getAttachment milkData function", err);
            // }
        }

    }
    downloadSampleDocument = (e) => {
        e.preventDefault();
        window.location.href = "../../assets/excel/CustomerItem.xlsx";
    }
    render() {
        return (
            <Fragment>
                <div className='card bg-filter'>
                    <div className='card-body'>
                        <div className="section-header">
                            <h6 class="m-0">Edit Contract Item</h6>
                            <div className="section-header-buttons">
                                <button type="button" className="btn btn-success" onClick={this.openItemModel} title="Add Contract Item">Add Item To Contract</button>&nbsp;&nbsp;                                
                                {/* <button type="button" className="btn btn-success" onClick={this.openItemSyncModel} title="Sync with Inventory">Sync with Inventory</button>&nbsp;&nbsp; */}
                                <button type="button" className="btn btn-success" onClick={this.handleUnmappedContractItem} title="Unmapped Item">Unmapped Item</button>&nbsp;&nbsp;
                                {/* <button type="button" className="btn btn-success" onClick={this.handleUpdateContractItem} title="Update Contract Item">Update Contract Item</button>&nbsp;&nbsp; */}
                                <input type="file" name="file" onChange={this.handleAttachmentInputChange} />
                                <button type="submit" className="btn btn-success" onClick={this.getAttachment}>
                                    <span title="Upload">Upload</span>
                                </button>&nbsp;&nbsp;
                                <button type="button" className="btn btn-success" onClick={this.downloadSampleDocument} title="Download Sample Document">Download Sample Document</button>&nbsp;&nbsp;
                                <button type="button" className="btn btn-outline-secondary" onClick={this.handleCancel} title="Back">Back</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='card'>
                    <div className='card-body'>
                        <div className='row'>
                            <div className="col-lg-4">
                                <div className="form-group">
                                    <label>Customer <span className="text-danger">*</span></label>
                                    <SelectBox
                                        dataSource={this.state.customerList}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        placeholder="Select customer"
                                        name="id"
                                        defaultValue={0}
                                        value={this.state.customerId}
                                        displayExpr='name'
                                        valueExpr='id'
                                        onSelectionChanged={this.handleCustomerChange}
                                        showClearButton={true}
                                        disabled={false}
                                        searchEnabled={true}
                                    />
                                    {/* {this.state.errors["customerId"].length > 0 && <span className="error invalid-feedback">{this.state.errors["customerId"]}</span>} */}
                                </div>
                            </div>
                            <div className="col-lg-2">
                                <div className="form-group">
                                    <label>Contract Type <span className="text-danger">*</span></label>
                                    <SelectBox
                                        dataSource={this.state.typeList}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        placeholder="Select contract type"
                                        name="id"
                                        defaultValue={0}
                                        value={this.state.constractTypeId}
                                        displayExpr='name'
                                        valueExpr='id'
                                        onSelectionChanged={this.handleContractTypeChange}
                                        showClearButton={true}
                                        disabled={false}
                                        searchEnabled={true}
                                    />
                                    {/* {this.state.errors["constractTypeId"].length > 0 && <span className="error invalid-feedback">{this.state.errors["constractTypeId"]}</span>} */}
                                </div>
                            </div>
                            <div className="col-lg-2">
                                <div className="form-group">
                                    <label>Contract Status <span className="text-danger">*</span></label>
                                    <SelectBox
                                        dataSource={this.state.statusList}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        placeholder="Select contract status"
                                        name="id"
                                        defaultValue={0}
                                        value={this.state.contractStatusId}
                                        displayExpr='name'
                                        valueExpr='id'
                                        onSelectionChanged={this.handleContractStatusChange}
                                        showClearButton={true}
                                        disabled={false}
                                        searchEnabled={true}
                                    />
                                    {/* {this.state.errors["contractStatusId"].length > 0 && <span className="error invalid-feedback">{this.state.errors["contractStatusId"]}</span>} */}
                                </div>
                            </div>
                            <div className="col-lg-2">
                                <div className="form-group">
                                    <label>Billing Type <span className="text-danger">*</span></label>
                                    <SelectBox
                                        dataSource={this.state.billingTypeList}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        placeholder="Select billing type"
                                        name="name"
                                        defaultValue={0}
                                        value={this.state.billingTypeName}
                                        displayExpr='name'
                                        valueExpr='name'
                                        onSelectionChanged={this.handleBillingTypeChange}
                                        showClearButton={true}
                                        disabled={false}
                                        searchEnabled={true}
                                    />
                                    {/* {this.state.errors["billingTypeName"].length > 0 && <span className="error invalid-feedback">{this.state.errors["billingTypeName"]}</span>} */}
                                </div>
                            </div>
                            <div className="col-lg-2">
                                <div className="form-group">
                                    <label>Payment Term <span className="text-danger">*</span></label>
                                    <SelectBox
                                        dataSource={this.state.paymentTermTypeList}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        placeholder="Select payment term"
                                        name="id"
                                        defaultValue={0}
                                        value={this.state.paymentTermTypeId}
                                        displayExpr='name'
                                        valueExpr='id'
                                        onSelectionChanged={this.handlePaymentTermTypeChange}
                                        showClearButton={true}
                                        disabled={false}
                                        searchEnabled={true}
                                    />
                                    {/* {this.state.errors["paymentTermTypeId"].length > 0 && <span className="error invalid-feedback">{this.state.errors["paymentTermTypeId"]}</span>} */}
                                </div>
                            </div>
                            <div className="col-lg-2">
                                <div className="form-group">
                                    <label>Contract Start <span className="text-danger">*</span></label>
                                    <DateBox type="date"
                                        className="replay-date-time"
                                        name="startDate"
                                        placeholder="dd/mm/yyyy"
                                        displayFormat="dd/MM/yyyy"
                                        showAnalogClock={true}
                                        useMaskBehavior={true}
                                        defaultValue={this.state.startDate}
                                        value={this.state.startDate}
                                        onValueChanged={this.handleStartDateChanged}
                                        width="100%"
                                        disabled={false}
                                        acceptCustomValue={false}
                                        min={this.state.min}
                                    />
                                </div>
                            </div>
                            <div className="col-lg-2">
                                <div className="form-group">
                                    <label>Contract End <span className="text-danger">*</span></label>
                                    <DateBox type="date"
                                        className="replay-date-time"
                                        name="endDate"
                                        placeholder="dd/mm/yyyy"
                                        displayFormat="dd/MM/yyyy"
                                        showAnalogClock={true}
                                        useMaskBehavior={true}
                                        defaultValue={this.state.endDate}
                                        value={this.state.endDate}
                                        onValueChanged={this.handleEndDateChanged}
                                        width="100%"
                                        disabled={false}
                                        acceptCustomValue={false}
                                        min={this.state.startDate}
                                    />
                                </div>
                            </div>
                            <div className="col-lg-2">
                                <div className="form-group">
                                    <label htmlFor="">SO#</label>
                                    <input type="text" className="form-control form-control-sm" name="soNo" value={this.state.soNo} placeholder="Enter sales order number" maxLength="50" title="soNo" disabled={true} onChange={this.handleInputChange} />
                                </div>
                            </div>
                            <div className="col-lg-2">
                                <div className="form-group">
                                    <label htmlFor="">Reference SO#</label>
                                    <input type="text" className="form-control form-control-sm" name="referenceNo" value={this.state.referenceNo} placeholder="Enter reference number" maxLength="50" title="referenceNo" disabled={true} onChange={this.handleInputChange} />
                                </div>
                            </div>
                        </div>
                        <div>
                            <DataGrid
                                dataSource={this.state.vehicleDeviceList}
                                keyExpr="id"
                                //  selection={{ mode: 'single' }}
                                columnsAutoWidth={false}
                                showColumnLines={true}
                                showRowLines={true}
                                showBorders={true}
                                rowAlternationEnabled={false}
                                allowColumnResizing={true}
                                columnResizingMode='widget'
                                onSelectionChanged={this.handleMultipleSelectionChanged}
                            // selectedRowKeys={this.state.selectedRowKey}
                            //  onToolbarPreparing={this.onToolbarPreparing}
                            >
                                <Selection
                                    mode="multiple"
                                    selectAllMode={"allPages"}
                                    showCheckBoxesMode={"always"}
                                />

                                {/* <Column dataField="soNo" caption="SO#" allowSearch={true} allowFiltering={false} allowSorting={true} width={150} />
                                        <Column dataField="referenceSONo" caption="Reference No" allowSearch={true} allowFiltering={false} allowSorting={true} width={250} /> */}
                                <Column dataField="serialNo" caption="Serial#" allowSearch={true} allowFiltering={false} allowSorting={true} width={250} />
                                <Column dataField="vehicleNo" caption="Vehicle#" allowSearch={true} allowFiltering={false} allowSorting={true} width={150} />
                                <Column dataField="installedOn" caption="Installed On" allowSearch={true} allowFiltering={false} allowSorting={true} width={150} format={DateFormat.DateWesternFormat} dataType="date" />

                                <SortByGroupSummaryInfo summaryItem="count" />
                                <GroupPanel visible={false} />
                                {/* <Template name="toolbarTemplate" /> */}
                                <HeaderFilter visible={true} />
                                <SearchPanel visible={true} highlightCaseSensitive={true} width={240} placeholder="Search..." />
                                <Grouping autoExpandAll={true} />
                                <Paging defaultPageSize={10} />
                                <Pager showPageSizeSelector={true} allowedPageSizes={[10, 20, 50]} showInfo={true} visible={true} />
                                <Export enabled={true} allowExportSelectedData={false} fileName="VehicleDevice" />
                            </DataGrid>
                        </div>
                    </div>
                </div>

                <Modal ariaHideApp={false}
                    isOpen={this.state.itemModelIsOpen}
                    style={customStyles}
                    className={"react-modal"}
                    //onAfterOpen={this.handleAssignDataToState}
                    onRequestClose={this.closeItemModel}
                    shouldCloseOnOverlayClick={false}
                    contentLabel="Example Modal">
                    <form method="post" onSubmit={this.handleMapContractItemSubmit} style={this.state.isSubmited ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                        <div className="modal-header">
                            <h6 className="modal-title">Add Item(s) To Contract</h6>
                        </div>
                        <div className="modal-body">
                            <div className='row'>
                                <div className="col-lg-12">
                                    <DataGrid
                                        dataSource={this.state.unmappedDeviceList}
                                        keyExpr="stockItemId"
                                        //  selection={{ mode: 'single' }}
                                        columnsAutoWidth={false}
                                        showColumnLines={true}
                                        showRowLines={true}
                                        showBorders={true}
                                        rowAlternationEnabled={false}
                                        allowColumnResizing={true}
                                        columnResizingMode='widget'
                                        onSelectionChanged={this.handleMultipleContractItemUnmappedSelectionChanged}
                                    //  selectedRowKeys={this.state.selectedRowKey}
                                    //  onToolbarPreparing={this.onToolbarPreparing}
                                    >
                                        <Selection
                                            mode="multiple"
                                            selectAllMode={"allPages"}
                                            showCheckBoxesMode={"always"}
                                        />

                                        {/* <Column dataField="soNo" caption="SO#" allowSearch={true} allowFiltering={false} allowSorting={true} width={150} />
                                        <Column dataField="referenceSONo" caption="Reference No" allowSearch={true} allowFiltering={false} allowSorting={true} width={150} />*/}
                                        <Column dataField="serialNo" caption="Serial#" allowSearch={true} allowFiltering={false} allowSorting={true} width={150} />
                                        <Column dataField="vehicleNo" caption="Vehicle#" allowSearch={true} allowFiltering={false} allowSorting={true} width={150} />

                                        <SortByGroupSummaryInfo summaryItem="count" />
                                        <GroupPanel visible={false} />
                                        {/* <Template name="toolbarTemplate" /> */}
                                        <HeaderFilter visible={true} />
                                        <SearchPanel visible={true} highlightCaseSensitive={true} width={240} placeholder="Search..." />
                                        <Grouping autoExpandAll={true} />
                                        <Paging defaultPageSize={10} />
                                        <Pager showPageSizeSelector={true} allowedPageSizes={[10, 20, 50]} showInfo={true} visible={true} />
                                        <Export enabled={true} allowExportSelectedData={false} fileName="VehicleDevice" />
                                    </DataGrid>
                                </div>
                            </div>


                        </div>
                        <div className="modal-footer">
                            <button type="submit" className="btn btn-success" onClick={this.handleMapContractItemSubmit} >
                                {this.state.isSubmited && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                {!this.state.isSubmited && <span title="Submit">Submit</span>}
                            </button>
                            <button type="button" className="btn btn-outline-secondary" onClick={this.closeItemModel} title="Cancel">Cancel</button>
                        </div>
                    </form>
                </Modal>
                //Sync Model
                <Modal ariaHideApp={false}
                    isOpen={this.state.syncModelIsOpen}
                    style={customStyles}
                    className={"react-modal"}
                    //onAfterOpen={this.handleAssignDataToState}
                    onRequestClose={this.closeItemSyncModel}
                    shouldCloseOnOverlayClick={false}
                    contentLabel="Example Modal">
                    <form method="post" onSubmit={this.handleMapItemSubmit} style={this.state.isSubmited ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                        <div className="modal-header">
                            <h6 className="modal-title">Sync Item With Inventory</h6>
                        </div>
                        <div className="modal-body">
                            <div className='row'>
                                <div className="col-lg-12">
                                    <DataGrid
                                        dataSource={this.state.unmappedDeviceList}
                                        keyExpr="id"
                                        //  selection={{ mode: 'single' }}
                                        columnsAutoWidth={false}
                                        showColumnLines={true}
                                        showRowLines={true}
                                        showBorders={true}
                                        rowAlternationEnabled={false}
                                        allowColumnResizing={true}
                                        columnResizingMode='widget'
                                        onSelectionChanged={this.handleMultipleUnmappedSelectionChanged}
                                    //  selectedRowKeys={this.state.selectedRowKey}
                                    //  onToolbarPreparing={this.onToolbarPreparing}
                                    >
                                        <Selection
                                            mode="multiple"
                                            selectAllMode={"allPages"}
                                            showCheckBoxesMode={"always"}
                                        />

                                        {/* <Column dataField="soNo" caption="SO#" allowSearch={true} allowFiltering={false} allowSorting={true} width={150} />
                                        <Column dataField="referenceSONo" caption="Reference No" allowSearch={true} allowFiltering={false} allowSorting={true} width={150} />*/}
                                        <Column dataField="serialNo" caption="Serial#" allowSearch={true} allowFiltering={false} allowSorting={true} width={150} />
                                        <Column dataField="vehicleNo" caption="Vehicle#" allowSearch={true} allowFiltering={false} allowSorting={true} width={150} />

                                        <SortByGroupSummaryInfo summaryItem="count" />
                                        <GroupPanel visible={false} />
                                        {/* <Template name="toolbarTemplate" /> */}
                                        <HeaderFilter visible={true} />
                                        <SearchPanel visible={true} highlightCaseSensitive={true} width={240} placeholder="Search..." />
                                        <Grouping autoExpandAll={true} />
                                        <Paging defaultPageSize={10} />
                                        <Pager showPageSizeSelector={true} allowedPageSizes={[10, 20, 50]} showInfo={true} visible={true} />
                                        <Export enabled={true} allowExportSelectedData={false} fileName="VehicleDevice" />
                                    </DataGrid>
                                </div>
                            </div>


                        </div>
                        <div className="modal-footer">
                            <button type="submit" className="btn btn-success" onClick={this.handleMapItemSubmit} >
                                {this.state.isSubmited && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                {!this.state.isSubmited && <span title="Submit">Submit</span>}
                            </button>
                            <button type="button" className="btn btn-outline-secondary" onClick={this.closeItemSyncModel} title="Cancel">Cancel</button>
                        </div>
                    </form>
                </Modal>
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={position}
                    visible={this.state.loadPanelVisible}
                    showIndicator={true}
                    shading={true}
                    showPane={true}
                    hideOnOutsideClick={false}
                />
            </Fragment>
        )
    };
}