import React, { Component } from 'react';
import { SelectBox } from 'devextreme-react/select-box';
import DateBox from 'devextreme-react/date-box';
import DataGrid, {
    Column,
    Grouping,
    ColumnFixing,
    HeaderFilter, Pager,
    Paging,
    SearchPanel,
    SortByGroupSummaryInfo,
    Summary, TotalItem, Selection
} from 'devextreme-react/data-grid'; //npm i devextreme-react
import Modal from 'react-modal'; //npm install --save react-modal;
import { LoadPanel } from 'devextreme-react/load-panel';
import Swal from 'sweetalert2';
import { currencySymbolFormats, precisionFormats } from '../../helpers/fixcodes.js';
import { formatDate, getRequestParams, numberGridFormat } from '../../helpers/globalfunctions.js';
import RadioGroup from 'devextreme-react/radio-group';
const addressTypeRadio = ['Post Billing', 'Advance Billing'];
const position = { of: '#historydiv' };
const customStyles = {
    content: {
        width: '30%',
    }
}

export class GenerateInvoiceComponent extends Component {
    static displayName = GenerateInvoiceComponent.name;
    constructor(props) {
        super(props);
        this.state = {
            termTypeList: [],
            loadPanelVisible: false,
            invoiceStartDate: new Date(),
            invoiceEndDate: new Date(),
            termTypeId: 0,
            selectionRowKey: [],
            generateInvoiceList: null,
            pastGenerateInvoiceList: null,
            generateInvoiceDetailList: [],
            sessionUser: JSON.parse(sessionStorage.getItem("sessionUser")),
            addressType: addressTypeRadio[0],
            vehicleAndDeviceModel: false,
            vehicleDeviceList: null,
            filterRowsId: [],
            gridValue: true,
            pastSelectionRowKey: [],

        }
    }
    async componentDidMount() {
        await this.getPaymentTermType();
        await this.getAutoGenerateInvoice();
        await this.getPastAutoGenerateInvoice();
    }
    getPaymentTermType = async () => {
        try {
            var request = {};
            const requestParams = getRequestParams("GET", request);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'paymentTermType/dropdownType', requestParams);
            const data = await response.json();

            this.setState({
                termTypeList: data.payload
            });
        } catch (err) {
            console.log("Error in " + this.displayName + ".getPaymentTermType function", err);
        }
    }
    getAutoGenerateInvoice = async () => {
        try {
debugger; this.setState({loadPanelVisible : true});
            var request = {};
            request.BillingFromDate = formatDate(this.state.invoiceStartDate, "yyyy-MM-dd");
            request.BillingToDate = formatDate(this.state.invoiceEndDate, "yyyy-MM-dd");
            request.TermTypeId = this.state.termTypeId
            request.BillingType = this.state.addressType === "Post Billing" ? "Post" : "Advance";
            const requestParams = getRequestParams("POST", request);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'invoice/getAutoGenerateInvoice', requestParams);
            const data = await response.json();
             this.setState({
                generateInvoiceList: data.payload,
                loadPanelVisible: false
                //generateInvoiceDetailList: data.autoGenerateInvoicedetails
            })

        } catch (err) {
            console.log("Error in " + this.displayName + ".getPaymentTermType function", err);
            this.setState({ loadPanelVisible: false});
        }
    }
    getPastAutoGenerateInvoice = async () => {
        try {

            var request = {};
            request.BillingFromDate = formatDate(this.state.invoiceStartDate, "yyyy-MM-dd");
            request.BillingToDate = formatDate(this.state.invoiceEndDate, "yyyy-MM-dd");
            request.TermTypeId = this.state.termTypeId
            request.BillingType = this.state.addressType === "Post Billing" ? "Post" : "Advance";
            const requestParams = getRequestParams("POST", request);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'invoice/getPastAutoGenerateInvoice', requestParams);
            const data = await response.json();
            this.setState({
                pastGenerateInvoiceList: data.payload,
                //generateInvoiceDetailList: data.autoGenerateInvoicedetails
            })

        } catch (err) {
            console.log("Error in " + this.displayName + ".getPaymentTermType function", err);
        }
    }



    getContractItemList = async (customerId, soNo) => {
        try {
            var request = {};
            request.customerId = customerId;
            request.SONo = soNo
            const requestParams = getRequestParams("POST", request);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'contractItem/getContractItemDetails', requestParams);
            const data = await response.json();

            this.setState({
                vehicleDeviceList: data.payload,
            })

        } catch (err) {
            console.log("Error in " + this.displayName + ".getPaymentTermType function", err);
        }
    }
    handleStartDateChanged = (date) => {
        this.setState({ invoiceStartDate: date.value });
    }
    handleEndDateChanged = (date) => {
        this.setState({ invoiceEndDate: date.value });
    }
    handlePaymentTermTypeChange = (selectedOption) => {
        this.setState({
            termTypeId: (selectedOption.selectedItem !== null ? selectedOption.selectedItem.id : 0),
        });
    }

    handleGenerateInvoice = async (e) => {
        e.preventDefault();
        try {
            console.log(this.state.gridValue);
            debugger;
            if (this.state.gridValue === true) {
                if (this.state.selectionRowKey != null && this.state.selectionRowKey.length === 0) {
                    Swal.fire({
                        icon: 'error',
                        text: "Please select at least one row.",
                    }).then((result) => {
                        return;
                    });
                    return;
                }


                let invoiceDetailList = [];
                let customerList = [];
                let list = this.state.generateInvoiceList.filter((item) => this.state.selectionRowKey.filter((rowIndex) => rowIndex == item.rowIndex).length > 0);
                let filterRows = [];
                let customerName = [];
                //let filterRowsId = [];
                var i;
                // var j = 0;
                // for (i = 0; i < list.length; i++) {
                //     if (list[i].totalItemQty !== list[i].contractItemQty) {
                //         filterRows.push(list[i].rowIndex)
                //         customerName.push(list[i].customerName + ' ' + '(' + list[i].soNo + ')')


                //     }
                // }
                // let _list = list.filter((item) => !filterRows.filter((rowIndex) => rowIndex == item.rowIndex).length > 0);

                // var message = "Item qty re missMatched for below customerContract"

                // // "Do you want to generate"+' '+_list.length + ' ' +"draft invoice(s)",
                // Swal.fire({
                //     html: message + "<br/>" + customerName.join("<br/>") + "<br/>" + "Do you want to still generate invoice",
                //     icon: 'warning',
                //     showCancelButton: true,
                //     confirmButtonColor: '#3085d6',
                //     cancelButtonColor: '#d33',
                //     confirmButtonText: 'Yes',
                //     allowOutsideClick: false
                // }).then((result) => {
                //     if (result.isConfirmed) {
                //         alert("helo");
                //     }
                // })
                // let _list = this.state.generateInvoiceDetailList.filter((item) => customerList.filter((customerId) => customerId == item.customerId).length > 0);
                // list.map((gridData, i) => {
                //     let newgrid = new Object();
                //     newgrid.id = gridData.rowIndex;
                //     newgrid.CustomerId = gridData.customerId;
                //     newgrid.CustomerContractId = gridData.customerContractId;
                //     newgrid.TotalAmount = gridData.totalAmount;
                //     newgrid.TotalTax = gridData.totalTax;
                //     newgrid.GrossAmount = gridData.totalAmount;
                //     newgrid.PaymentTermId = gridData.paymentTermId;
                //     newgrid.DueDate = gridData.dueDate;
                //     newgrid.invoiceStartDate = gridData.invoiceStartDate;
                //     newgrid.invoiceEndDate = gridData.invoiceEndDate;
                //     invoiceList.push(newgrid);
                // });

                list.map((gridData, i) => {
                    let newgrid = new Object();
                    newgrid.id = gridData.rowIndex;
                    newgrid.CustomerId = gridData.customerId;
                    newgrid.CustomerContractId = gridData.customerContractId;
                    newgrid.TotalTax = gridData.totalTaxAmount;
                    newgrid.UnitPrice = gridData.unitPrice;
                    newgrid.TotalAmount = gridData.totalAmount;
                    newgrid.GrossAmount = gridData.grossAmount;
                    newgrid.TotalQty = gridData.totalItemQty;
                    newgrid.PaymentTermsId = gridData.paymentTermId;
                    newgrid.Remarks = gridData.Remarks;
                    newgrid.ServiceItemId = gridData.serviceItemId;
                    newgrid.PaymentTermId = gridData.paymentTermId;
                    newgrid.SoNo = gridData.soNo;
                    invoiceDetailList.push(newgrid);
                });
              

                // var htmlMessage = customerName.length == 0 ? "Do you want to generate" + ' ' + _list.length + ' ' + "draft invoice(s)" : message + "<br/>" + customerName.join("<br/>") + "<br/>" + "Do you want to still generate invoice"
                var htmlMessage ="Do you want to generate" + ' ' + list.length + ' ' + "draft invoice(s)"     
                Swal.fire({
                    html: htmlMessage,//"Do you want to generate"+' '+list.length + ' ' +"draft invoice(s)",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes',
                    allowOutsideClick: false
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.setState({ loadPanelVisible: true });
                        var request = new Object();
                        request.UserId = this.state.sessionUser.id;
                        request.autoGenerateInvoice = invoiceDetailList;
                        request.autoGenerateInvoicedetails = invoiceDetailList;
                        const requestParams = getRequestParams('POST', request);
                        fetch(process.env.REACT_APP_API_BASE_URL + 'invoice/autoGenerateInvoice', requestParams)
                            .then(response => { if (response.ok) return response.json() })
                            .then(data => {
                                this.setState({ loadPanelVisible: false });
                                if (data.isSuccess === true) {
                                    Swal.fire({
                                        icon: data.isSuccess === true ? 'success' : 'error',
                                        text: data.message,
                                        // text: data.message,
                                    }).then((result) => {
                                        if (data.isSuccess === true) {
                                            if (result.isConfirmed) {
                                                this.getAutoGenerateInvoice();
                                                this.setState({ loadPanelVisible: false });
                                            }
                                        }
                                    })
                                }
                            });
                    }
                })
            }
            else {
                if (this.state.pastSelectionRowKey != null && this.state.pastSelectionRowKey.length === 0) {
                    Swal.fire({
                        icon: 'error',
                        text: "Please select at least one row from pending invoice.",
                    }).then((result) => {
                        return;
                    });
                    return;
                }


                let invoiceDetailList = [];
                let customerList = [];
                let list = this.state.pastGenerateInvoiceList.filter((item) => this.state.pastSelectionRowKey.filter((rowIndex) => rowIndex == item.rowIndex).length > 0);
                let filterRows = [];
                let customerName = [];
                //let filterRowsId = [];
                var i;
                // var j = 0;
                // for (i = 0; i < list.length; i++) {
                //     if (list[i].totalItemQty !== list[i].contractItemQty) {
                //         filterRows.push(list[i].rowIndex)
                //         customerName.push(list[i].customerName + ' ' + '(' + list[i].soNo + ')')


                //     }
                // }
                // let _list = list.filter((item) => !filterRows.filter((rowIndex) => rowIndex == item.rowIndex).length > 0);

                // var message = "Item qty re missMatched for below customerContract"

                // // "Do you want to generate"+' '+_list.length + ' ' +"draft invoice(s)",
                // Swal.fire({
                //     html: message + "<br/>" + customerName.join("<br/>") + "<br/>" + "Do you want to still generate invoice",
                //     icon: 'warning',
                //     showCancelButton: true,
                //     confirmButtonColor: '#3085d6',
                //     cancelButtonColor: '#d33',
                //     confirmButtonText: 'Yes',
                //     allowOutsideClick: false
                // }).then((result) => {
                //     if (result.isConfirmed) {
                //         alert("helo");
                //     }
                // })
              
                list.map((gridData, i) => {
                    let newgrid = new Object();
                    newgrid.id = gridData.rowIndex;
                    newgrid.CustomerId = gridData.customerId;
                    newgrid.CustomerContractId = gridData.customerContractId;
                    newgrid.TotalTax = gridData.totalTaxAmount;
                    newgrid.UnitPrice = gridData.unitPrice;
                    newgrid.TotalAmount = gridData.totalAmount;
                    newgrid.GrossAmount = gridData.grossAmount;
                    newgrid.TotalQty = gridData.totalItemQty;
                    newgrid.PaymentTermsId = gridData.paymentTermId;
                    newgrid.Remarks = gridData.Remarks;
                    newgrid.ServiceItemId = gridData.serviceItemId;
                    newgrid.PaymentTermId = gridData.paymentTermId;
                    newgrid.SoNo = gridData.soNo;
                    invoiceDetailList.push(newgrid);
                });
            // var htmlMessage = customerName.length == 0 ? "Do you want to generate" + ' ' + _list.length + ' ' + "draft invoice(s)" : message + "<br/>" + customerName.join("<br/>") + "<br/>" + "Do you want to still generate invoice"
            var htmlMessage ="Do you want to generate" + ' ' + list.length + ' ' + "draft invoice(s)"     
            Swal.fire({
                    html: htmlMessage,//"Do you want to generate"+' '+list.length + ' ' +"draft invoice(s)",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes',
                    allowOutsideClick: false
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.setState({ loadPanelVisible: true });
                        var request = new Object();
                        request.UserId = this.state.sessionUser.id;
                        request.autoGenerateInvoice = invoiceDetailList;
                        request.autoGenerateInvoicedetails = invoiceDetailList;
                        const requestParams = getRequestParams('POST', request);
                        fetch(process.env.REACT_APP_API_BASE_URL + 'invoice/autoGenerateInvoice', requestParams)
                            .then(response => { if (response.ok) return response.json() })
                            .then(data => {
                                this.setState({ loadPanelVisible: false });
                                if (data.isSuccess === true) {
                                    Swal.fire({
                                        icon: data.isSuccess === true ? 'success' : 'error',
                                        text: data.message,
                                        // text: data.message,
                                    }).then((result) => {
                                        if (data.isSuccess === true) {
                                            if (result.isConfirmed) {
                                                this.setState({ pastSelectionRowKey: [] });
                                                this.getPastAutoGenerateInvoice();
                                            }
                                        }
                                    })
                                }
                            });
                    }
                })
            }



        } catch (err) {
            console.log("Error in " + this.displayName + ".handleGenerateInvoice function", err);
        }
    }
    handleMultipleSelectionChanged = (e) => {
        this.setState({
            selectionRowKey: e.selectedRowKeys,

        });
    }
    handlePastMultipleSelectionChanged = (e) => {
        this.setState({
            pastSelectionRowKey: e.selectedRowKeys,

        });
    }
    handleClear = () => {
        this.setState({
            termTypeId: 0,
            selectionRowKey: [],
            pastSelectionRowKey: [],
        });
        setTimeout(() => this.getPastAutoGenerateInvoice(), 100);
        setTimeout(() => this.getAutoGenerateInvoice(), 100);
    }
    changeSelectionAddressType = (e) => {
        this.setState({
            addressType: e.value,
        });
        setTimeout(() => { this.getAutoGenerateInvoice() }, 500);
    }


    pastPendingGrid = (e) => {
        this.setState({
            addressType: "Post Billing",
            gridValue: false,
        });
        setTimeout(() => { this.getPastAutoGenerateInvoice() }, 500);
    }

    showVehicleAndDevices = (e, data) => {
        e.preventDefault();
        this.setState({
            vehicleAndDeviceModel: true,
        })
        setTimeout(() => {
            this.getContractItemList(data.customerId, data.soNo);
        }, 200);
    }

    closeModel = () => {
        this.setState({
            vehicleAndDeviceModel: false,
        })
    }

    render() {
        const renderGridNumberCell = (data) => {
            return numberGridFormat(data.value)
        }
        const renderTotalGridNumberCell = (data) => {
            return numberGridFormat(data.value)
        }
        const customizeText = (data) => {
            var value = 'Total: ' + numberGridFormat(data.value);
            return value
        }
        const customizeGrossText = (data) => {
            var value = 'Total: ' + numberGridFormat(data.value);
            return value
        }
        const renderGridAmountCell = (data) => {
            return <div>{parseFloat(data.value).toFixed(precisionFormats[2])}</div>;
        }
        const renderGridCell = (data) => {
            return <div>
                <a href="/#" title="Address" onClick={(e) => { this.showVehicleAndDevices(e, data.data) }} >{data.value}</a>
            </div>;
        }

        return (
            <div className="section-body">
                <div className='card bg-filter'>
                    <div className='card-body'>
                        <div className="section-header">
                            <div className='row'>
                                {/* <div className='col-lg-2 col-md-2 col-sm-12'>
                                    <div className="form-group">
                                        <label htmlFor="">Payment Term</label>
                                        <SelectBox
                                            dataSource={this.state.termTypeList}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select payment term"
                                            name="id"
                                            defaultValue={0}
                                            value={this.state.termTypeId}
                                            displayExpr='name'
                                            valueExpr='id'
                                            onSelectionChanged={this.handlePaymentTermTypeChange}
                                            showClearButton={true}
                                            searchEnabled={true}
                                        />
                                    </div>
                                </div> */}
                                <div className='col-lg-2 col-md-2 col-sm-12'>
                                    <div className="form-group">
                                        <label htmlFor="">Invoice Start Date</label>
                                        <DateBox type="date"
                                            className="replay-date-time"
                                            name="invoiceStartDate"
                                            placeholder="dd/mm/yyyy"
                                            displayFormat="dd/MM/yyyy"
                                            showAnalogClock={true}
                                            useMaskBehavior={true}
                                            defaultValue="dd/MM/yyyy"
                                            value={this.state.invoiceStartDate}
                                            onValueChanged={this.handleStartDateChanged}
                                            width="100%"
                                            acceptCustomValue={false}
                                        />
                                    </div>
                                </div>
                                <div className='col-lg-2 col-md-2 col-sm-12'>
                                    <div className="form-group">
                                        <label htmlFor="">Invoice End Date</label>
                                        <DateBox type="date"
                                            className="replay-date-time"
                                            name="invoiceEndDate"
                                            placeholder="dd/mm/yyyy"
                                            displayFormat="dd/MM/yyyy"
                                            showAnalogClock={true}
                                            useMaskBehavior={true}
                                            defaultValue="dd/MM/yyyy"
                                            value={this.state.invoiceEndDate}
                                            onValueChanged={this.handleEndDateChanged}
                                            width="100%"
                                            acceptCustomValue={false}
                                        />
                                    </div>
                                </div>

                                <div className="col-lg-4 col-md-3 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <label htmlFor="">&nbsp;</label><br />
                                        <button type="submit" className="btn btn-success" onClick={this.getAutoGenerateInvoice}>
                                            Search
                                        </button>&nbsp;&nbsp;
                                        <button type="submit" className="btn btn-outline-secondary" onClick={this.handleClear}>
                                            Clear
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div className='card'>
                    <div className='card-body'>
                        <div className='d-flex'>
                            <RadioGroup items={addressTypeRadio} defaultValue={addressTypeRadio[0]} layout="horizontal" value={this.state.addressType} onValueChanged={this.changeSelectionAddressType} />
                            <button type="submit" className="btn btn-success" onClick={this.handleGenerateInvoice}>
                                Generate Invoice
                            </button>
                        </div>
                        <div className="mt-3">
                            <ul className="nav nav-tabs mt-1 ml-2" id="custom-tabs-tab" role="tablist">
                                <li className="nav-item">
                                    <a className="nav-link active" id="custom-tabs-home-tab" data-toggle="pill" href="#custom-tabs-home" role="tab" aria-controls="custom-tabs-home" aria-selected="true" onClick={(e) => this.setState({ gridValue: true })} >Active Customer Contract</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" id="custom-tabs-profile-tab" data-toggle="pill" href="#custom-tabs-profile" role="tab" aria-controls="custom-tabs-profile" aria-selected="false" onClick={(e) => this.pastPendingGrid()}   >Pending Customer Contract</a>
                                </li>
                            </ul>
                            <div className="tab-content tab-bordered" id="custom-tabs-tabContent" >
                                <div className="tab-pane fade active show" id="custom-tabs-home" role="tabpanel" aria-labelledby="custom-tabs-home-tab"  >
                                    <DataGrid
                                        dataSource={this.state.generateInvoiceList}
                                        keyExpr="rowIndex"
                                        id='gridGenerateInvoiceList'
                                        columnsAutoWidth={false}
                                        showColumnLines={true}
                                        showRowLines={true}
                                        showBorders={true}
                                        rowAlternationEnabled={false}
                                        allowColumnResizing={true}
                                        columnResizingMode='widget'
                                        //selectedRowKeys={this.state.selectionRowKey}
                                        onSelectionChanged={this.handleMultipleSelectionChanged}
                                        onToolbarPreparing={this.onToolbarPreparing}>
                                        <Selection
                                            mode="multiple"
                                            selectAllMode={"allPages"}
                                            showCheckBoxesMode={"always"}
                                        />
                                        <Column dataField="customerName" caption="Customer" allowSearch={true} allowFiltering={false} allowSorting={true} width={450} />
                                        <Column dataField="soNo" caption="SO#" allowSearch={true} allowFiltering={false} allowSorting={true} width={100} />
                                        <Column dataField="paymentTermName" caption="Payment Term" allowSearch={true} allowFiltering={false} allowSorting={true} width={120} />
                                        <Column dataField="totalItemQty" caption="Item Qty" allowSearch={true} allowFiltering={false} allowSorting={true} width={100} cellRender={renderGridCell} />
                                        {/* <Column dataField="z" caption="Item Qty" allowSearch={true} allowFiltering={false} allowSorting={true} width={100}/> */}
                                        <Column dataField="totalTaxAmount" caption="Total Tax" allowSearch={true} allowFiltering={false} allowSorting={true} width={200} cellRender={renderTotalGridNumberCell} />
                                        <Column dataField="grossAmount" caption="Total Amount" allowSearch={true} allowFiltering={false} allowSorting={true} minWidth={100}
                                            format={"#,##0.###"}
                                            cellRender={renderGridNumberCell}
                                        />
                                        {/* <Column dataField="amountDue" caption="Amount Due" allowSearch={true} allowFiltering={false} allowSorting={true} width={150} cellRender={renderGridAmountCell} />
                                <Column dataField="dueDate" caption="Due Date" allowSearch={true} allowFiltering={false} allowSorting={true} width={150} format={DateFormat.DateNorthFormat} dataType="datetime" /> */}
                                        <Summary>
                                            <TotalItem column="grossAmount" summaryType="sum" customizeText={customizeGrossText} />
                                            <TotalItem column="totalTax" summaryType="sum" customizeText={customizeText} />
                                        </Summary>
                                        <SortByGroupSummaryInfo summaryItem="count" />
                                        <HeaderFilter visible={true} />
                                        <SearchPanel visible={true} highlightCaseSensitive={true} width={240} placeholder="Search..." />
                                        <Grouping autoExpandAll={true} />
                                        <Paging defaultPageSize={10} />
                                        <Pager showPageSizeSelector={true} allowedPageSizes={[10, 20, 50, 500]} showInfo={true} visible={true} />
                                        <ColumnFixing enabled={true} />
                                    </DataGrid>
                                </div>
                                <div className="tab-pane fade" id="custom-tabs-profile" role="tabpanel" aria-labelledby="custom-tabs-profile-tab" >
                                    <DataGrid
                                        dataSource={this.state.pastGenerateInvoiceList}
                                        keyExpr="rowIndex"
                                        //  id='gridGenerateInvoiceList'
                                        columnsAutoWidth={false}
                                        showColumnLines={true}
                                        showRowLines={true}
                                        showBorders={true}
                                        rowAlternationEnabled={false}
                                        allowColumnResizing={true}
                                        columnResizingMode='widget'
                                        //selectedRowKeys={this.state.selectionRowKey}
                                        onSelectionChanged={this.handlePastMultipleSelectionChanged}
                                        onToolbarPreparing={this.onToolbarPreparing}>
                                        <Selection
                                            mode="multiple"
                                            selectAllMode={"allPages"}
                                            showCheckBoxesMode={"always"}
                                        />
                                        <Column dataField="customerName" caption="Customer" allowSearch={true} allowFiltering={false} allowSorting={true} width={450} />
                                        <Column dataField="soNo" caption="SO#" allowSearch={true} allowFiltering={false} allowSorting={true} width={100} />
                                        <Column dataField="paymentTermName" caption="Payment Term" allowSearch={true} allowFiltering={false} allowSorting={true} width={120} />
                                        <Column dataField="totalItemQty" caption="Item Qty" allowSearch={true} allowFiltering={false} allowSorting={true} width={100} cellRender={renderGridCell} />
                                        {/* <Column dataField="z" caption="Item Qty" allowSearch={true} allowFiltering={false} allowSorting={true} width={100}/> */}
                                        <Column dataField="totalTaxAmount" caption="Total Tax" allowSearch={true} allowFiltering={false} allowSorting={true} width={200} cellRender={renderTotalGridNumberCell} />
                                        <Column dataField="grossAmount" caption="Total Amount" allowSearch={true} allowFiltering={false} allowSorting={true} minWidth={100}
                                            format={"#,##0.###"}
                                            cellRender={renderGridNumberCell}
                                        />
                                        {/* <Column dataField="amountDue" caption="Amount Due" allowSearch={true} allowFiltering={false} allowSorting={true} width={150} cellRender={renderGridAmountCell} />
                                <Column dataField="dueDate" caption="Due Date" allowSearch={true} allowFiltering={false} allowSorting={true} width={150} format={DateFormat.DateNorthFormat} dataType="datetime" /> */}
                                        <Summary>
                                            <TotalItem column="grossAmount" summaryType="sum" customizeText={customizeGrossText} />
                                            <TotalItem column="totalTax" summaryType="sum" customizeText={customizeText} />
                                        </Summary>
                                        <SortByGroupSummaryInfo summaryItem="count" />
                                        <HeaderFilter visible={true} />
                                        <SearchPanel visible={true} highlightCaseSensitive={true} width={240} placeholder="Search..." />
                                        <Grouping autoExpandAll={true} />
                                        <Paging defaultPageSize={10} />
                                        <Pager showPageSizeSelector={true} allowedPageSizes={[10, 20, 50, 500]} showInfo={true} visible={true} />
                                        <ColumnFixing enabled={true} />
                                    </DataGrid>
                                </div>
                            </div>
                        </div>

                    </div>

                    <Modal ariaHideApp={false}
                        isOpen={this.state.vehicleAndDeviceModel}
                        style={customStyles}
                        className={"react-modal"}
                        onRequestClose={this.closeModel}
                        shouldCloseOnOverlayClick={false}
                        contentLabel="Example Modal">
                        <form method="post" style={this.state.isSubmited ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                            <div className="modal-header">
                                <h6 className="modal-title">Vehicle# and Devices</h6>
                            </div>
                            <div className="modal-body">
                                <div className='card'>
                                    <div className='card-body'>
                                        <DataGrid
                                            dataSource={this.state.vehicleDeviceList}
                                            keyExpr="id"
                                            id='gridHsnCodeMasterList'
                                            selection={{ mode: 'single' }}
                                            columnsAutoWidth={false}
                                            showColumnLines={true}
                                            showRowLines={true}
                                            showBorders={true}
                                            rowAlternationEnabled={false}
                                            allowColumnResizing={true}
                                            columnResizingMode='widget'
                                            width="100%"
                                            onToolbarPreparing={this.onToolbarPreparing}
                                            onExporting={this.onExporting}>
                                            <Column dataField="vehicleNo" caption="Vehicle#" allowSearch={true} allowFiltering={false} allowSorting={true} width={150} />
                                            <Column dataField="serialNo" caption="Serial#" allowSearch={true} allowFiltering={false} allowSorting={true} width={150} />

                                            <SortByGroupSummaryInfo summaryItem="count" />
                                            {/* <GroupPanel visible={false} /> */}
                                            {/* <Template name="toolbarTemplate" /> */}
                                            <HeaderFilter visible={true} />
                                            <SearchPanel visible={true} highlightCaseSensitive={true} width={240} placeholder="Search..." />
                                            {/* <Grouping autoExpandAll={true} /> */}
                                            <Paging defaultPageSize={10} />
                                            {/* <Pager showPageSizeSelector={true} allowedPageSizes={[10, 20, 50]} showInfo={true} visible={true} /> */}
                                            {/* <Export enabled={true}  allowExportSelectedData={false} fileName="Item current status" /> */}
                                        </DataGrid>
                                    </div>
                                </div>
                                {/* <div className='row mt-2'>
                                <div className='col-6'>
                                    <div className='bg-body-tertiary p-3 rounded'>
                                        <div className='font-weight-bold mb-2'>Vehicle No :-</div>
                                        <span className='text-wrap'>{this.state.billingAddress}</span>
                                    </div>
                                </div>
                                <div className='col-6'>
                                    <div className='bg-body-tertiary p-3 rounded'>
                                        <div className='font-weight-bold mb-2'>Devices :-</div>
                                        <span className='text-wrap'>{this.state.shippingAddress}</span>
                                    </div>
                                </div>
                            </div> */}
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-outline-secondary" onClick={this.closeModel} title="Cancel">Cancel</button>
                            </div>
                        </form>
                    </Modal>
                    <LoadPanel
                        shadingColor="rgba(0,0,0,0.4)"
                        position={position}
                        visible={this.state.loadPanelVisible}
                        showIndicator={true}
                        shading={true}
                        showPane={true}
                        hideOnOutsideClick={false}
                    />
                </div>

            </div>
        )
    }

}