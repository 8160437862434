import React, { Component, Fragment } from "react";
import { NavItem, NavLink } from "reactstrap";
import { Link } from "react-router-dom";

export class SidebarComponent extends Component {
  static displayName = SidebarComponent.name;

  constructor(props) {
    super(props);

    this.state = {
      pageName: "Dashboard",
    };
  }

  componentDidMount() {
    //document.querySelector("#lblPageTitle").innerHTML = sessionStorage.getItem("session_PageName") === null ? "Dashboard" : sessionStorage.getItem("session_PageName");
    setTimeout(() => {
      document.querySelector("#lblPageTitle").innerHTML =
        sessionStorage.getItem("session_PageName") === null
          ? "Dashboard"
          : sessionStorage.getItem("session_PageName");
    }, 1000);
  }

  menuClick = async (e, currentPageName) => {
    document.querySelector("#lblPageTitle").innerHTML = currentPageName;
    sessionStorage.setItem("session_PageName", currentPageName);
  };

  render() {
    return (
      <Fragment>
        <div className="main-sidebar">
          <aside id="sidebar-wrapper">
            <div className="sidebar-brand">
              <a href="/">
                <img
                  src="../assets/img/logo-utwill-billing.png"
                  width={"150px"}
                  alt="UTWiz Inventory Management System"
                  className="img-filter"
                />
              </a>
            </div>
            <div className="sidebar-brand-silverstar-sm">
              <a href="/">U</a>
            </div>
            <div className="companyLogoBox sidebar-brand-sm">
              <img
                src="../assets/img/defaultlogo.png"
                alt="UTWiz Billing Logo"
              />
            </div>
            <div className="sidebar-brand sidebar-brand-sm"></div>
            <div>
              <ul className="sidebar-menu">
                <NavItem>
                  <NavLink tag={Link} to="/dashboard" title="Dashboard">
                    <i className="fas fa-th-large"></i>
                    <span>Dashboard</span>
                  </NavLink>
                </NavItem>
                <NavItem className="dropdown">
                  <a
                    href="/"
                    className="nav-link has-dropdown"
                    title="Product"
                    data-toggle="dropdown"
                  >
                    <i className="fas fa-box"></i>
                    <span>Product</span>
                  </a>
                  <ul className="dropdown-menu">
                    <NavItem>
                      <NavLink
                        tag={Link}
                        to="/category"
                        title="Item Category"
                        onClick={(e) => {
                          this.menuClick(e, "Item Category");
                        }}
                      >
                        <i className="far fa-dot-circle"></i>
                        <span>Item Category</span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        tag={Link}
                        to="/attributeType"
                        title="Attribute Type"
                        onClick={(e) => {
                          this.menuClick(e, "Product");
                        }}
                      >
                        <i className="far fa-dot-circle"></i>
                        <span>Attribute Type</span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        tag={Link}
                        to="/attribute"
                        title="Attribute"
                        onClick={(e) => {
                          this.menuClick(e, "Attribute");
                        }}
                      >
                        <i className="far fa-dot-circle"></i>
                        <span>Attribute</span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        tag={Link}
                        to="/item"
                        title="Item"
                        onClick={(e) => {
                          this.menuClick(e, "Item");
                        }}
                      >
                        <i className="far fa-dot-circle"></i>
                        <span>Item</span>
                      </NavLink>
                    </NavItem>
                  </ul>
                </NavItem>
                <NavItem className="dropdown">
                  <a
                    href="/"
                    className="nav-link has-dropdown"
                    title="Stock"
                    data-toggle="dropdown"
                  >
                    <i className="fas fa-boxes"></i>
                    <span>Stock</span>
                  </a>
                  <ul className="dropdown-menu">
                    <NavItem>
                      <NavLink
                        tag={Link}
                        to="/stock"
                        title="Stock Item"
                        onClick={(e) => {
                          this.menuClick(e, "Stock Item");
                        }}
                      >
                        <i className="far fa-dot-circle"></i>
                        <span>Stock Item</span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        tag={Link}
                        to="/qualitycheck"
                        title="Quality Check"
                        onClick={(e) => {
                          this.menuClick(e, "Quality Check");
                        }}
                      >
                        <i className="far fa-dot-circle"></i>
                        <span>Quality Check</span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        tag={Link}
                        to="/itemcurrentstatus"
                        title="Inventory"
                        onClick={(e) => {
                          this.menuClick(e, "Inventory");
                        }}
                      >
                        <i className="far fa-dot-circle"></i>
                        <span>Inventory</span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        tag={Link}
                        to="/activedevices"
                        title="Active Device"
                        onClick={(e) => {
                          this.menuClick(e, "Active Device");
                        }}
                      >
                        <i className="far fa-dot-circle"></i>
                        <span>Active Device</span>
                      </NavLink>
                    </NavItem>
                  </ul>
                </NavItem>
                <NavItem className="dropdown">
                  <a
                    href="/"
                    className="nav-link has-dropdown"
                    title="Sales"
                    data-toggle="dropdown"
                  >
                    <i className="fas fa-upload"></i>
                    <span>Sales</span>
                  </a>
                  <ul className="dropdown-menu">
                    <NavItem>
                      <NavLink
                        tag={Link}
                        to="/salesorder/list"
                        title="Sales Order"
                        onClick={(e) => {
                          this.menuClick(e, "Sales Order");
                        }}
                      >
                        <i className="far fa-dot-circle"></i>
                        <span>Sales Order</span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        tag={Link}
                        to="/salereturn/list"
                        title="Sales Return"
                        onClick={(e) => {
                          this.menuClick(e, "Sales Return");
                        }}
                      >
                        <i className="far fa-dot-circle"></i>
                        <span>Sales Return</span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        tag={Link}
                        to="/creditnote/list"
                        title="Credit Note"
                        onClick={(e) => {
                          this.menuClick(e, "Credit Note");
                        }}
                      >
                        <i className="far fa-dot-circle"></i>
                        <span>Credit Note</span>
                      </NavLink>
                    </NavItem>
                  </ul>
                </NavItem>
                <NavItem className="dropdown">
                  <a
                    href="/"
                    className="nav-link has-dropdown"
                    title="Purchase"
                    data-toggle="dropdown"
                  >
                    <i className="fas fa-download"></i>
                    <span>Purchase</span>
                  </a>
                  <ul className="dropdown-menu">
                    <NavItem>
                      <NavLink
                        tag={Link}
                        to="/purchaseorder/list"
                        title="Purchase Order"
                        onClick={(e) => {
                          this.menuClick(e, "Purchase Order");
                        }}
                      >
                        <i className="far fa-dot-circle"></i>
                        <span>Purchase Order</span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        tag={Link}
                        to="/purchasereturn/list"
                        title="Purchase Return"
                        onClick={(e) => {
                          this.menuClick(e, "Purchase Return");
                        }}
                      >
                        <i className="far fa-dot-circle"></i>
                        <span>Purchase Return</span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        tag={Link}
                        to="/debitnote/list"
                        title="Debit Note"
                        onClick={(e) => {
                          this.menuClick(e, "Debit Note");
                        }}
                      >
                        <i className="far fa-dot-circle"></i>
                        <span>Debit Note</span>
                      </NavLink>
                    </NavItem>
                  </ul>
                </NavItem>
                <NavItem className="dropdown">
                  <a
                    href="/"
                    className="nav-link has-dropdown"
                    title="Invoice & Billing"
                    data-toggle="dropdown"
                  >
                    <i className="fas fa-file-invoice"></i>
                    <span>Invoice & Billing</span>
                  </a>
                  <ul className="dropdown-menu">
                    <NavItem>
                      <NavLink
                        tag={Link}
                        to="/invoice/list"
                        title="Invoice"
                        onClick={(e) => {
                          this.menuClick(e, "Invoice");
                        }}
                      >
                        <i className="far fa-dot-circle"></i>
                        <span>Invoice</span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        tag={Link}
                        to="/generateinvoice"
                        title="Generate Invoice"
                        onClick={(e) => {
                          this.menuClick(e, "Generate Invoice");
                        }}
                      >
                        <i className="far fa-dot-circle"></i>
                        <span>Generate Invoice</span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        tag={Link}
                        to="/bill/list"
                        title="Billing"
                        onClick={(e) => {
                          this.menuClick(e, "Billing");
                        }}
                      >
                        <i className="far fa-dot-circle"></i>
                        <span>Billing</span>
                      </NavLink>
                    </NavItem>
                    {/* <NavItem>
                                            <NavLink tag={Link} to="/" title="Debit Note" onClick={(e) => { this.menuClick(e, "History") }}>
                                                <i className="far fa-dot-circle"></i><span>History</span>
                                            </NavLink>
                                        </NavItem> */}

                    {/* <NavItem>
                                            <NavLink tag={Link} to="/paymentreceivable" title="Payment Receivable" onClick={(e) => { this.menuClick(e, "Payment Receivable") }}>
                                                <i className="far fa-dot-circle"></i><span>Payment Receivable</span>
                                            </NavLink>
                                        </NavItem> 
                                        <NavItem>
                                            <NavLink tag={Link} to="/paymentpayables" title="Payment Payable" onClick={(e) => { this.menuClick(e, "Payment Payable") }}>
                                                <i className="far fa-dot-circle"></i><span>Payment Payable</span>
                                            </NavLink>
                                        </NavItem> */}
                  </ul>
                </NavItem>
                <NavItem className="dropdown">
                  <a
                    href="/"
                    className="nav-link has-dropdown"
                    title="Operations"
                    data-toggle="dropdown"
                  >
                    <i class="fas fa-tools"></i>
                    <span>Operations</span>
                  </a>
                  <ul className="dropdown-menu">
                    <NavItem>
                      <NavLink
                        tag={Link}
                        to="/requestrequisition"
                        title="IRForm Requisition"
                        onClick={(e) => {
                          this.menuClick(e, "IRForm Requisition");
                        }}
                      >
                        <i className="far fa-dot-circle"></i>
                        <span>IRForm Requisition</span>
                      </NavLink>
                    </NavItem>
                  </ul>
                </NavItem>
                <NavItem className="dropdown">
                  <a
                    href="/"
                    className="nav-link has-dropdown"
                    title="Payment"
                    data-toggle="dropdown"
                  >
                    <i className="fas fa-rupee-sign"></i>
                    <span>Payment</span>
                  </a>
                  <ul className="dropdown-menu">
                    <NavItem>
                      <NavLink
                        tag={Link}
                        to="/payment"
                        title="Payment"
                        onClick={(e) => {
                          this.menuClick(e, "Payment");
                        }}
                      >
                        <i className="far fa-dot-circle"></i>
                        <span>Payment</span>
                      </NavLink>
                    </NavItem>

                    <NavItem>
                      <NavLink
                        tag={Link}
                        to="/invoicepayment/list"
                        title="Invoice Payment"
                        onClick={(e) => {
                          this.menuClick(e, "Invoice Payment");
                        }}
                      >
                        <i className="far fa-dot-circle"></i>
                        <span>Invoice Payment</span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        tag={Link}
                        to="/billpayment/list"
                        title="Bill Payment"
                        onClick={(e) => {
                          this.menuClick(e, "Bill Payment");
                        }}
                      >
                        <i className="far fa-dot-circle"></i>
                        <span>Bill Payment</span>
                      </NavLink>
                    </NavItem>
                  </ul>
                </NavItem>
                <NavItem className="dropdown">
                  <a
                    href="/"
                    className="nav-link has-dropdown"
                    title="Customer Settings"
                    data-toggle="dropdown"
                  >
                    <i class="fas fa-user-cog"></i>
                    <span>Customer Settings</span>
                  </a>
                  <ul className="dropdown-menu">
                    <NavItem>
                      <NavLink
                        tag={Link}
                        to="/contract"
                        title="Customer Contract"
                        onClick={(e) => {
                          this.menuClick(e, "Customer Contract");
                        }}
                      >
                        <i className="far fa-dot-circle"></i>
                        <span>Customer Contract</span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        tag={Link}
                        to="/taxconfiguration"
                        title="Customer Tax"
                        onClick={(e) => {
                          this.menuClick(e, "Customer Settings");
                        }}
                      >
                        <i className="far fa-dot-circle"></i>
                        <span>Customer Tax</span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        tag={Link}
                        to="/contractitem"
                        title="Contract Item"
                        onClick={(e) => {
                          this.menuClick(e, "Contract Item");
                        }}
                      >
                        <i className="far fa-dot-circle"></i>
                        <span>Contract Item</span>
                      </NavLink>
                    </NavItem>
                  </ul>
                </NavItem>
                <NavItem className="dropdown">
                  <a
                    href="/"
                    className="nav-link has-dropdown"
                    title="Master"
                    data-toggle="dropdown"
                  >
                    <i className="fas fa-bars"></i>
                    <span>Master</span>
                  </a>
                  <ul className="dropdown-menu">
                    <NavItem>
                      <NavLink
                        tag={Link}
                        to="/customer"
                        title="Customer"
                        onClick={(e) => {
                          this.menuClick(e, "Master");
                        }}
                      >
                        <i className="far fa-dot-circle"></i>
                        <span>Customer</span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        tag={Link}
                        to="/vendor"
                        title="Vendor"
                        onClick={(e) => {
                          this.menuClick(e, "Master");
                        }}
                      >
                        <i className="far fa-dot-circle"></i>
                        <span>Vendor</span>
                      </NavLink>
                    </NavItem>

                    <NavItem>
                      <NavLink
                        tag={Link}
                        to="/serviceitem"
                        title="Service Item"
                        onClick={(e) => {
                          this.menuClick(e, "Master");
                        }}
                      >
                        <i className="far fa-dot-circle"></i>
                        <span>Service Item</span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        tag={Link}
                        to="/courier"
                        title="Courier"
                        onClick={(e) => {
                          this.menuClick(e, "Master");
                        }}
                      >
                        <i className="far fa-dot-circle"></i>
                        <span>Courier</span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        tag={Link}
                        to="/users"
                        title="User"
                        onClick={(e) => {
                          this.menuClick(e, "User");
                        }}
                      >
                        <i className="far fa-dot-circle"></i>
                        <span>User</span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        tag={Link}
                        to="/customermapping"
                        title="Account Mapping"
                        onClick={(e) => {
                          this.menuClick(e, "Master");
                        }}
                      >
                        <i className="far fa-dot-circle"></i>
                        <span>Account Mapping</span>
                      </NavLink>
                    </NavItem>
                    {/* <NavItem>
                                            <NavLink tag={Link} to="/" title="Status" onClick={(e) => { this.menuClick(e, "Status") }}>
                                                <i className="far fa-dot-circle"></i><span>Status</span>
                                            </NavLink>
                                        </NavItem> */}

                    <NavItem>
                      <NavLink
                        tag={Link}
                        to="/warehouse"
                        title="Warehouse"
                        onClick={(e) => {
                          this.menuClick(e, "Master");
                        }}
                      >
                        <i className="far fa-dot-circle"></i>
                        <span>Warehouse</span>
                      </NavLink>
                    </NavItem>
                  </ul>
                </NavItem>
                <NavItem className="dropdown">
                  <a
                    href="/"
                    className="nav-link has-dropdown"
                    title="Tax"
                    data-toggle="dropdown"
                  >
                    <i className="fas fa-percentage"></i>
                    <span>Tax</span>
                  </a>
                  <ul className="dropdown-menu">
                    <NavItem>
                      <NavLink
                        tag={Link}
                        to="/hsncode"
                        title="HSN & SAC Code"
                        onClick={(e) => {
                          this.menuClick(e, "HSN & SAC Code");
                        }}
                      >
                        <i className="far fa-dot-circle"></i>
                        <span>HSN & SAC Code</span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        tag={Link}
                        to="/tax"
                        title="Tax"
                        onClick={(e) => {
                          this.menuClick(e, "Tax");
                        }}
                      >
                        <i className="far fa-dot-circle"></i>
                        <span>Tax</span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        tag={Link}
                        to="/hsntax"
                        title="HSN & SAC Tax"
                        onClick={(e) => {
                          this.menuClick(e, "Tax");
                        }}
                      >
                        <i className="far fa-dot-circle"></i>
                        <span>HSN & SAC Tax</span>
                      </NavLink>
                    </NavItem>
                  </ul>
                </NavItem>
                {/* <NavItem className="dropdown">
                                    <a href="/" className="nav-link has-dropdown" title="Reports" data-toggle="dropdown">
                                        <i className="fas fa-file-contract"></i><span>Reports</span>
                                    </a>
                                    <ul className="dropdown-menu">
                                        <NavItem>
                                            <NavLink tag={Link} to="/" title="Sales by Customer" onClick={(e) => { this.menuClick(e, "Reports • Sales by Customer") }}>
                                                <i className="far fa-dot-circle"></i><span>Sales by Customer</span>
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink tag={Link} to="/" title="Sales by Item" onClick={(e) => { this.menuClick(e, "Reports • Sales by Item") }}>
                                                <i className="far fa-dot-circle"></i><span>Sales by Item</span>
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink tag={Link} to="/" title="Sales Order" onClick={(e) => { this.menuClick(e, "Reports • Sales Order") }}>
                                                <i className="far fa-dot-circle"></i><span>Sales Order</span>
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink tag={Link} to="/" title="Inventory | Inventory" onClick={(e) => { this.menuClick(e, "Reports • Stock | Inventory") }}>
                                                <i className="far fa-dot-circle"></i><span>Stock | Inventory</span>
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink tag={Link} to="/" title="Item QC Details" onClick={(e) => { this.menuClick(e, "Reports • Item QC Details") }}>
                                                <i className="far fa-dot-circle"></i><span>Item QC Details</span>
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink tag={Link} to="/" title="Invoice and Bill" onClick={(e) => { this.menuClick(e, "Reports • Invoice and Bill") }}>
                                                <i className="far fa-dot-circle"></i><span>Invoice and Bill</span>
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink tag={Link} to="/" title="Payment Received" onClick={(e) => { this.menuClick(e, "Reports • Payment Received") }}>
                                                <i className="far fa-dot-circle"></i><span>Payment Received</span>
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink tag={Link} to="/" title="Payment Made" onClick={(e) => { this.menuClick(e, "Reports • Payment Made") }}>
                                                <i className="far fa-dot-circle"></i><span>Payment Made</span>
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink tag={Link} to="/" title="Purchase Order" onClick={(e) => { this.menuClick(e, "Reports • Purchase Order") }}>
                                                <i className="far fa-dot-circle"></i><span>Purchase Order</span>
                                            </NavLink>
                                        </NavItem>
                                    </ul>
                                </NavItem> */}
                <NavItem>
                  <NavLink tag={Link} to="/eventlog" title="Event Log">
                    <i className="fas fa-list"></i>
                    <span>Event Log</span>
                  </NavLink>
                </NavItem>
              </ul>
            </div>
            {/* <ul className="sidebar-menu">
                            <li className="nav-item active">
                                <a title="Dashboard" className="nav-link" href="/dashboard"  onClick={(e) => { this.menuClick(e, "Dashboard") }}>
                                    <i className="fas fa-th-large"></i><span>Dashboard</span></a></li>
                            <li className="nav-item">
                                <a href="/" className="nav-link has-dropdown" title="Product" data-toggle="dropdown">
                                    <i className="fas fa-box"></i><span>Product</span></a>
                                <ul className="dropdown-menu">
                                    <li className="nav-item"><a title="Item Category" className="nav-link" href="/category" onClick={(e) => { this.menuClick(e, "Product • Category") }}>
                                        <i className="far fa-dot-circle"></i><span>Item Category</span></a></li>
                                    <li className="nav-item"><a title="Item" className="nav-link" href="/item" onClick={(e) => { this.menuClick(e, "Product • Item") }}>
                                        <i className="far fa-dot-circle"></i><span>Item</span></a></li>
                                </ul>
                            </li>
                            <li className="nav-item">
                                <a href="/" className="nav-link has-dropdown" title="Stock" data-toggle="dropdown">
                                    <i className="fas fa-boxes"></i><span>Stock</span></a>
                                <ul className="dropdown-menu">
                                    <li className="nav-item"><a title="Inventory" className="nav-link" href="/stock" onClick={(e) => { this.menuClick(e, "Inventory") }}><i className="far fa-dot-circle"></i><span>Inventory</span></a></li>
                                    <li className="nav-item"><a title="QC" className="nav-link" href="/qualitycheck" onClick={(e) => { this.menuClick(e, "Quality Check") }}><i className="far fa-dot-circle"></i><span>QC</span></a></li>
                                </ul>
                            </li>
                            <li className="nav-item">
                                <a href="/" className="nav-link has-dropdown" title="Sales" data-toggle="dropdown">
                                    <i className="fas fa-upload"></i><span>Sales</span></a>
                                <ul className="dropdown-menu">
                                    <li className="nav-item"><a title="Sales Order" className="nav-link" href="/salesorder/list" onClick={(e) => { this.menuClick(e, "Sales Order") }}><i className="far fa-dot-circle"></i><span>Sales Order</span></a></li>
                                    <li className="nav-item"><a title="Sales Return" className="nav-link" href="/salereturn/list" onClick={(e) => { this.menuClick(e, "Sales Return") }}><i className="far fa-dot-circle"></i><span>Sales Return</span></a></li>
                                    <li className="nav-item"><a title="Credit Note" className="nav-link" href="/creditnote" onClick={(e) => { this.menuClick(e, "Credit Note") }}><i className="far fa-dot-circle"></i><span>Credit Note</span></a></li>
                                </ul>
                            </li>
                            <li className="nav-item">
                                <a href="/" className="nav-link has-dropdown" title="Purchase" data-toggle="dropdown">
                                    <i className="fas fa-download"></i><span>Purchase</span></a>
                                <ul className="dropdown-menu">
                                    <li className="nav-item"><a title="Purchase Order" className="nav-link" href="/purchaseorder/list" onClick={(e) => { this.menuClick(e, "Purchase Order") }}><i className="far fa-dot-circle"></i><span>Purchase Order</span></a></li>
                                    <li className="nav-item"><a title="Purchase Return" className="nav-link" href="/purchasereturn/list" onClick={(e) => { this.menuClick(e, "Purchse Return") }}><i className="far fa-dot-circle"></i><span>Purchase Return</span></a></li>
                                    <li className="nav-item"><a title="Debit Note" className="nav-link" href="/debitnote" onClick={(e) => { this.menuClick(e, "Debit Note") }}><i className="far fa-dot-circle"></i><span>Debit Note</span></a></li>
                                </ul>
                            </li>
                            <li className="nav-item">
                                <a href="/" className="nav-link has-dropdown" title="Invoice & Billing" data-toggle="dropdown">
                                    <i className="fas fa-file-invoice"></i><span>Invoice & Billing</span></a>
                                <ul className="dropdown-menu">
                                    <li className="nav-item"><a title="Invoice" className="nav-link" href="/invoice/list" onClick={(e) => { this.menuClick(e, "Invoice") }}><i className="far fa-dot-circle"></i><span>Invoice</span></a></li>                                    
                                    <li className="nav-item"><a title="Billing" className="nav-link" href="/bill/list" onClick={(e) => { this.menuClick(e, "Billing") }}><i className="far fa-dot-circle"></i><span>Billing</span></a></li>
                                    <li className="nav-item"><a title="History" className="nav-link" href="/" onClick={(e) => { this.menuClick(e, "History") }}><i className="far fa-dot-circle"></i><span>History</span></a></li>
                                    <li className="nav-item"><a title="AutoGenerate" className="nav-link" href="/generateinvoice" onClick={(e) => { this.menuClick(e, "AutoGenerate") }}><i className="far fa-dot-circle"></i><span>AutoGenerate</span></a></li>
                                    <li className="nav-item"><a title="Invoice Payment" className="nav-link" href="/invoicepayment/list" onClick={(e) => { this.menuClick(e, "Invoice Payment") }}><i className="far fa-dot-circle"></i><span>Invoice Payment</span></a></li>                                    
                                    <li className="nav-item"><a title="Payment Receivable" className="nav-link" href="/paymentreceivable" onClick={(e) => { this.menuClick(e, "Payment Receivable") }}><i className="far fa-dot-circle"></i><span>Payment Receivable</span></a></li>                                    
                                    <li className="nav-item"><a title="Payment Payable" className="nav-link" href="/paymentpayables" onClick={(e) => { this.menuClick(e, "Payment Payable") }}><i className="far fa-dot-circle"></i><span>Payment Payable</span></a></li>
                                </ul>
                            </li>
                            <li className="nav-item">
                            <a href="/" className="nav-link has-dropdown" title="Invoice & Billing" data-toggle="dropdown">
                                    <i className="fas fa-rupee-sign"></i><span>Payment</span></a>
                                    <ul className="dropdown-menu">                                    
                                    <li className="nav-item"><a title="Payment" className="nav-link" href="/payment" onClick={(e) => { this.menuClick(e, "Payment") }}><i className="far fa-dot-circle"></i><span>Payment</span></a></li>                                    
                                    <li className="nav-item"><a title="Invoice Payment" className="nav-link" href="/" onClick={(e) => { this.menuClick(e, "Invoice Payment") }}><i className="far fa-dot-circle"></i><span>Invoice Payment</span></a></li>
                                    <li className="nav-item"><a title="Bill Payment" className="nav-link" href="/billpayment" onClick={(e) => { this.menuClick(e, "Bill Payment") }}><i className="far fa-dot-circle"></i><span>Bill Payment</span></a></li>                                    
                                </ul>
                            </li>
                            <li className="nav-item">
                                <a href="/" className="nav-link has-dropdown" title="Settings" data-toggle="dropdown">
                                    <i className="fas fa-cog"></i><span>Settings</span></a>
                                <ul className="dropdown-menu">
                                    <li className="nav-item"><a title="Customer Contract" className="nav-link" href="/contract" onClick={(e) => { this.menuClick(e, "Customer Contract") }}><i className="far fa-dot-circle"></i><span>Customer Contract</span></a></li>
                                    <li className="nav-item"><a title="Tax Configuration" className="nav-link" href="/taxconfiguration" onClick={(e) => { this.menuClick(e, "Tax Configuration") }}><i className="far fa-dot-circle"></i><span>Tax Configuration</span></a></li>                                    
                                </ul>
                            </li>
                            <li className="nav-item">
                                <a href="/" className="nav-link has-dropdown" title="Master" data-toggle="dropdown">
                                    <i className="fas fa-bars"></i><span>Master</span></a>
                                <ul className="dropdown-menu">
                                    <li className="nav-item"><a title="Customers" className="nav-link" href="/customer" onClick={(e) => { this.menuClick(e, "Customers") }}><i className="far fa-dot-circle"></i><span>Customers</span></a></li>
                                    <li className="nav-item"><a title="Vendors/Suppliers" className="nav-link" href="/vendor" onClick={(e) => { this.menuClick(e, "Vendors/Suppliers") }}><i className="far fa-dot-circle"></i><span>Vendors/Suppliers</span></a></li>
                                    <li className="nav-item"><a title="HSN & SAC Code" className="nav-link" href="/hsncode" onClick={(e) => { this.menuClick(e, "HSN & SAC Code") }}><i className="far fa-dot-circle"></i><span>HSN & SAC Code</span></a></li>
                                    <li className="nav-item"><a title="Tax" className="nav-link" href="/tax" onClick={(e) => { this.menuClick(e, "Tax") }}><i className="far fa-dot-circle"></i><span>Tax</span></a></li>
                                    <li className="nav-item"><a title="Service Item" className="nav-link" href="/serviceitem" onClick={(e) => { this.menuClick(e, "Service Item") }}><i className="far fa-dot-circle"></i><span>Service Item</span></a></li>
                                    <li className="nav-item"><a title="Courier" className="nav-link" href="/courier" onClick={(e) => { this.menuClick(e, "Courier") }}><i className="far fa-dot-circle"></i><span>Courier</span></a></li>
                                    <li className="nav-item"><a title="Users" className="nav-link" href="/users" onClick={(e) => { this.menuClick(e, "Users") }}><i className="far fa-dot-circle"></i><span>Users</span></a></li>
                                    <li className="nav-item"><a title="Status" className="nav-link" href="/" onClick={(e) => { this.menuClick(e, "Status") }}><i className="far fa-dot-circle"></i><span>Status</span></a></li>
                                    <li className="nav-item"><a title="Attribute Type" className="nav-link" href="/attributeType" onClick={(e) => { this.menuClick(e, "Attribute Type") }}><i className="far fa-dot-circle"></i><span>Attribute Type</span></a></li>
                                    <li className="nav-item"><a title="Attribute" className="nav-link" href="/attribute" onClick={(e) => { this.menuClick(e, "Attribute") }}><i className="far fa-dot-circle"></i><span>Attribute</span></a></li>
                                    <li className="nav-item"><a title="Warehouse" className="nav-link" href="/warehouse/list" onClick={(e) => { this.menuClick(e, "Warehouse") }}><i className="far fa-dot-circle"></i><span>Warehouse</span></a></li>
                                </ul>
                            </li>
                            <li className="nav-item">
                                <a href="/" className="nav-link has-dropdown" title="Reports" data-toggle="dropdown">
                                    <i className="fas fa-file-contract"></i><span>Reports</span></a>
                                <ul className="dropdown-menu">
                                    <li className="nav-item"><a title="Sales By Customer" className="nav-link" href="/" onClick={(e) => { this.menuClick(e, "Sales By Customer") }}><i className="far fa-dot-circle"></i><span>Sales By Customer</span></a></li>
                                    <li className="nav-item"><a title="Sales by Item" className="nav-link" href="/" onClick={(e) => { this.menuClick(e, "Sales by Item") }}><i className="far fa-dot-circle"></i><span>Sales by Item</span></a></li>
                                    <li className="nav-item"><a title="Sales Order" className="nav-link" href="/" onClick={(e) => { this.menuClick(e, "Sales Order") }}><i className="far fa-dot-circle"></i><span>Sales Order</span></a></li>
                                    <li className="nav-item"><a title="Stock | Inventory" className="nav-link" href="/" onClick={(e) => { this.menuClick(e, "Stock | Inventory") }}><i className="far fa-dot-circle"></i><span>Stock | Inventory</span></a></li>
                                    <li className="nav-item"><a title="Item QC Details" className="nav-link" href="/" onClick={(e) => { this.menuClick(e, "Item QC Details") }}><i className="far fa-dot-circle"></i><span>Item QC Details</span></a></li>
                                    <li className="nav-item"><a title="Invoice and Bill" className="nav-link" href="/" onClick={(e) => { this.menuClick(e, "Invoice and Bill") }}><i className="far fa-dot-circle"></i><span>Invoice and Bill</span></a></li>
                                    <li className="nav-item"><a title="Payment Received" className="nav-link" href="/" onClick={(e) => { this.menuClick(e, "Payment Received") }}><i className="far fa-dot-circle"></i><span>Payment Received</span></a></li>
                                    <li className="nav-item"><a title="Payment Made" className="nav-link" href="/" onClick={(e) => { this.menuClick(e, "Payment Made") }}><i className="far fa-dot-circle"></i><span>Payment Made</span></a></li>
                                    <li className="nav-item"><a title="Purchase Order" className="nav-link" href="/" onClick={(e) => { this.menuClick(e, "Purchase Order") }}><i className="far fa-dot-circle"></i><span>Purchase Order</span></a></li>
                                </ul>
                            </li>
                            <li className="nav-item">
                                <a title="EventLog" className="nav-link" href="eventlog" onClick={(e) => { this.menuClick(e, "Event Log") }}>
                                    <i className="fas fa-th-large"></i><span>Event Log</span></a></li>
                        </ul> */}
          </aside>
        </div>
      </Fragment>
    );
  }
}
