import React, { Fragment, Component } from 'react';
import DataGrid, {
    Column,
    Paging,
    SortByGroupSummaryInfo,
    Editing, Lookup,
    RequiredRule,
    PatternRule,
    StringLengthRule,
    Summary, TotalItem,
} from 'devextreme-react/data-grid'; //npm i devextreme-react
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import { getRequestParams, formatDate } from '../../helpers/globalfunctions.js';
import { alphanumericValidation, floatValidation, numberValidation, alphanumericSpecialcaseValidation } from '../../helpers/globalvalidations.js';
import { precisionFormats, currencySymbolFormats, documentType, invoiceStatus } from '../../helpers/fixcodes.js';
import DateBox from 'devextreme-react/date-box';
import { LoadPanel } from 'devextreme-react/load-panel';
import { SelectBox } from 'devextreme-react/select-box';
import NumberBox from 'devextreme-react/number-box';
import Swal from 'sweetalert2'; //npm i sweetalert2 --save
import Modal from 'react-modal'; //npm install --save react-modal
import moment from 'moment';
import { orderType } from '../../helpers/fixcodes.js';
import axios from 'axios'; //npm install--save axios

const position = { of: '#historydiv' };
const customStyles = {
    content: {
        width: '50%',
    }
}
export class InvoicesComponent extends Component {
    static displayName = InvoicesComponent.name;
    constructor(props) {
        super(props);
        
        this.grdInvoiceAttributesRef = React.createRef();
        this.state = {
            isSubmited: false,
            loadPanelVisible: false,
            documentModelIsOpen: false,
            accountList: null,
            paymentTermList: null,
            itemList: [],
            itemDetails: [],
            hsnsacList: null,
            orderStatusList: null,
            invoiceType: null,
            salesOrderList: null,
            id: 0,
            accountId: this.props.location.state === undefined ? 0 : this.props.location.state.customerId,
            orderNo: this.props.location.state === undefined ? "" : this.props.location.state.orderNo,
            invoiceDate: this.props.location.state === undefined ? new Date() : this.props.location.state.invoiceDate,
            dueDate: this.props.location.state === undefined ? moment(new Date()).add(45, 'day').format("MM/DD/YYYY") : this.props.location.state.dueDate,
            invoiceNo: this.props.location.state === undefined ? "" : this.props.location.state.invoiceNo,
            invoiceId: this.props.location.state === undefined ? 0 : this.props.location.state.invoiceId,
            paymentTermId: this.props.location.state === undefined ? 0 : this.props.location.state.paymentTermTypeId,
            invoiceTypeId: 0,
            shippingCharges: 0.00,
            discount: 0.00,
            otherCharges: 0.00,
            grossAmount: 0.00,
            remarks: "",
            termsAndConditions: "",
            accountTaxList: null,
            sessionUser: JSON.parse(sessionStorage.getItem("sessionUser")),
            isNewItem: false,
            orderStatusId: 0,
            imageList: [],
            pdfList: [],
            excelList: [],
            otherList: [],
            minimiumDate: new Date(),
            documentSelectedFile: [],
            invoiceDocumentOrderId: 0,
            resultMeassage: "",
            targetFile: [],
            serviceItemList: null,
            taxshippingValue: 0,
            taxOtherCharges: 0,
            taxDiscount: 0,
            taxTotalAmount: 0,
            taxSubTotal: 0,
            showGrossAmount: 0,
            errors: {
                accountId: "",
                orderNo: "",
                orderDate: new Date(),
                shippingDate: new Date(),
                paymentTermId: 0,
                shippingCharges: 0.00,
                discount: 0.00,
                otherCharges: 0.00,
                grossAmount: 0.00,
                remarks: "",
                termsAndConditions: "",
                itemDetailsGrid: "",
                invoiceTypeId: "",
            }
        }

        this.assginData = this.assginData.bind(this);
        this.generateInvoice = this.generateInvoice.bind(this);
    }

    async componentDidMount() {
        this.setState({
            loadPanelVisible: true
        });
        await this.getAccounts();
        await this.getPaymentTerms();
        await this.getItemMaster();
        await this.getHsnCodeMaster();
        await this.getInvoiceType();
        await this.getSalesOrder();
        await this.getServiceItemMaster();
        if (this.state.invoiceId > 0) {
            await this.getInvoiceList();
        }
        this.setState({
            loadPanelVisible: false
        });
    }
    getSalesOrder = async () => {
        try {
            var request = {};
            const requestParams = getRequestParams("GET", null);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'salesorder/getSaleOrderByCustomerId/' + this.state.accountId, requestParams);
            const data = await response.json();

            this.setState({
                salesOrderList: data.payload,
            });
        } catch (err) {
            console.log("Error in " + this.displayName + ".getSalesOrder function", err);
        }
    }
    getInvoiceList = async () => {
        this.setState({
            loadPanelVisible: true
        });
        try {
            var request = {};
            request.FromDate = formatDate(this.state.invoiceDate, "yyyy-MM-dd");
            request.ToDate = formatDate(this.state.invoiceDate, "yyyy-MM-dd");
            request.PaymentTermTypeId = parseInt(this.state.paymentTermId);
            request.InvoiceId = this.state.invoiceId
            const requestParams = getRequestParams("POST", request);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'invoice/GetDetails/' + this.state.invoiceId, requestParams);
            let data = await response.json();
            this.assginData(data.payload);
        } catch (err) {
            console.log("Error in " + this.displayName + ".getInvoiceList function", err);
            this.setState({
                loadPanelVisible: false
            });


        }
    }

    assginData = (salesOrder) => {
        if (salesOrder !== null && salesOrder !== undefined) {
            var invoiceSummary = salesOrder.invoiceSummary;
            var invoiceDetails = salesOrder.invoiceDetails;
            this.getAccountTax(invoiceSummary.customerId);
            //setTimeout(() => this.getServiceItemMaster(), 500);
            this.setState({
                id: invoiceSummary.id,
                accountId: invoiceSummary.customerId,
                paymentTermId: invoiceSummary.paymentTermTypeId,
                invoiceTypeId: invoiceSummary.invoiceTypeId,
                taxSubTotal: invoiceSummary.taxAmount,
                shippingCharges: parseFloat(invoiceSummary.shippingCharges).toFixed(precisionFormats[2]),
                discount: parseFloat(invoiceSummary.discount).toFixed(precisionFormats[2]),
                otherCharges: parseFloat(invoiceSummary.othercharges).toFixed(precisionFormats[2]),
                grossAmount: parseFloat(invoiceSummary.grossAmount).toFixed(precisionFormats[2]),
                remarks: invoiceSummary.remarks,
                termsAndConditions: invoiceSummary.termsCondition,
                itemDetails: invoiceDetails,
                // orderStatusId: invoiceSummary.orderStatusId,
            });

        }
    }

    getServiceItemMaster = async () => {
        try {
            var request = {};
            const requestParams = getRequestParams("GET", request);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'master/getServiceByCustomer/' + this.state.accountId, requestParams);
            const data = await response.json();
            this.setState({
                serviceItemList: data.payload,
            });
        } catch (err) {
            console.log("Error in " + this.displayName + ".getServiceItemMaster function", err);
            this.setState({
                loadPanelVisible: false
            });
        }
    }
    getAccounts = async () => {
        try {
            var request = {};
            const requestParams = getRequestParams("GET", request);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'account/dropdownAccount/2', requestParams);
            const data = await response.json();
            this.setState({
                accountList: data.payload
            });
        } catch (err) {
            console.log("Error in " + this.displayName + ".getAccounts function", err);
        }
    }

    getPaymentTerms = async () => {
        try {
            var request = {};
            const requestParams = getRequestParams("GET", request);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'paymentTermType/dropdownType', requestParams);
            const data = await response.json();
            this.setState({
                paymentTermList: data.payload
            });
        } catch (err) {
            console.log("Error in " + this.displayName + ".getPaymentTerms function", err);
        }
    }
    getInvoiceType = async () => {
        try {
            var request = {};
            const requestParams = getRequestParams("GET", request);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'invoice/type', requestParams);
            const data = await response.json();
            this.setState({
                invoiceType: data.payload
            });
        } catch (err) {
            console.log("Error in " + this.displayName + ".getPaymentTerms function", err);
        }
    }

    getItemMaster = async () => {
        try {
            var request = {};
            const requestParams = getRequestParams("POST", request);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'items/item/', requestParams);
            const data = await response.json();
            this.setState({
                itemList: data.payload != null ? data.payload : []
            });
        } catch (err) {
            console.log("Error in " + this.displayName + ".getItemMaster function", err);
        }
    }

    getHsnCodeMaster = async () => {
        try {
            var request = {};
            const requestParams = getRequestParams("GET", request);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'hsnsacCode/hscacccodes/0', requestParams);
            const data = await response.json();
            this.setState({
                hsnsacList: data.payload,
            });
        } catch (err) {
            console.log("Error in " + this.displayName + ".getHsnCodeMaster function", err);
        }
    }

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({ [name]: value });
        this.state.errors[name] = "";
    }

    getAccountTax = async (id) => {
        try {
            var request = {};
            const requestParams = getRequestParams("GET", request);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'taxConfiguration/accountTax/' + id, requestParams);
            const data = await response.json();
            this.setState({
                accountTaxList: data.payload != null ? data.payload : [],
            });

        } catch (err) {
            console.log("Error in " + this.displayName + ".getContractList function", err);
        }
    }

    onRowInserting = async (e) => {
        this.setState({ isNewItem: true });
    }

    handleShippingChargesChange = (event) => {
        // const newValue = event.value;
        // this.setState({ "shippingCharges": newValue });
        const newValue = event.value == null ? 0 : event.value;
        var totalTax = 0.00;
        // var singleTax = newValue === 0 ? 0: ((newValue * 18) / 100);
        if (newValue > 0) {
            if (this.state.accountTaxList !== null) {
                if (this.state.accountTaxList.length > 0) {
                    this.state.accountTaxList.map((taxitem, i) => {
                        var singleTax = parseFloat(((newValue * taxitem.percentage) / 100), 2);
                        totalTax = totalTax + singleTax;
                    });
                }
            }
        }

        this.setState({ "shippingCharges": newValue, taxshippingValue: totalTax });
        // var charge = parseFloat(this.state.otherCharges) + parseFloat(totalTax) + parseFloat(this.state.taxTotalAmount) + parseFloat(newValue)
        var charge = parseFloat(this.state.otherCharges) + parseFloat(totalTax) + parseFloat(this.state.showGrossAmount) + parseFloat(newValue)
        
        var newgrossAmount = parseFloat(charge) - parseFloat(this.state.discount);
        //var newgrossAmount = parseFloat(this.state.discount) + parseFloat(this.state.otherCharges) + parseFloat(newValue)
        this.setState({ grossAmount: newgrossAmount, taxshippingValue: totalTax });


    }

    handleDiscountChange = (event) => {
        // const newValue = event.value;
        // this.setState({ "discount": newValue });
        const newValue = event.value == null ? 0 : event.value;
        this.setState({ "discount": newValue });
        //  var test = parseFloat(this.state.taxOtherCharges) + parseFloat(this.state.taxshippingValue)+parseFloat(this.state.taxTotalAmount)+ parseFloat(this.state.shippingCharges) + parseFloat(this.state.otherCharges)
        var test = parseFloat(this.state.taxOtherCharges) + parseFloat(this.state.taxshippingValue) + parseFloat(this.state.showGrossAmount) + parseFloat(this.state.shippingCharges) + parseFloat(this.state.otherCharges)
        var newgrossAmount = parseFloat(test) - parseFloat(newValue)
        this.setState({ grossAmount: newgrossAmount });
    }

    handleOtherChargesChange = (event) => {
        // const newValue = event.value;
        // this.setState({ "otherCharges": newValue });
        const newValue = event.value == null ? 0 : event.value;
        // var singleTax = newValue === 0 ? 0: ((newValue * 18) / 100);
        var totalTax = 0.00;
        if (newValue > 0) {
            if (this.state.accountTaxList !== null) {
                if (this.state.accountTaxList.length > 0) {
                    this.state.accountTaxList.map((taxitem, i) => {
                        var singleTax = parseFloat(((newValue * taxitem.percentage) / 100), 2);
                        totalTax = totalTax + singleTax;
                    });
                }
            }

        }
        this.setState({ "otherCharges": newValue, taxOtherCharges: totalTax });
        // var charge =   parseFloat(totalTax) + parseFloat(this.state.taxshippingValue) + parseFloat(this.state.taxTotalAmount) + parseFloat(newValue)
        var charge = parseFloat(totalTax) + parseFloat(this.state.taxshippingValue) + parseFloat(this.state.showGrossAmount) + parseFloat(newValue)
        var newgrossAmount = parseFloat(charge) - parseFloat(this.state.discount);
        this.setState({ grossAmount: newgrossAmount });
    }

    handleGrossAmountChange = (event) => {
        const newValue = event.value;
        this.setState({ "grossAmount": newValue });
    }

    handleAccountChange = (selectedOption) => {
        this.setState({
            accountId: (selectedOption.selectedItem !== null ? selectedOption.selectedItem.id : 0),
        });
        let errors = this.state.errors;
        if (selectedOption.selectedItem !== null && selectedOption.selectedItem.id > 0) {
            errors.accountId = "";
            //this.state.errors["accountId"] = "";
            this.setState({
                errors: errors
            });
            setTimeout(() => this.getSalesOrder(), 100);
            setTimeout(() => this.getAccountTax(selectedOption.selectedItem.id), 100);
        }
    }

    handleOrderChange = (selectedOption) => {
        
        this.setState({
            orderNo: (selectedOption.selectedItem !== null ? selectedOption.selectedItem.soNo : ""),
            invoiceTypeId: (selectedOption.selectedItem !== null ? selectedOption.selectedItem.orderTypeId : 0)
        });
        let errors = this.state.errors;

        if (selectedOption.selectedItem !== null && selectedOption.selectedItem.soNo !== null) {
            errors.orderNo = "";
            this.setState({
                errors: errors
            });
            //this.state.errors["accountId"] = "";
            // this.setState({
            //     errors: {
            //         "accountId": ""
            //     }
            // });
            this.getOrderDetails(selectedOption.selectedItem.id, selectedOption.selectedItem.orderTypeId);
        }

    }
    getOrderDetails = async (id, orderTypeId) => {
        try {
            var request = {};
            const requestParams = getRequestParams("GET", request);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'salesorder/getSalesdetailsForInvoice/' + id + '/' + orderTypeId, requestParams);
            const data = await response.json();
            debugger;
            this.setState({
                itemDetails: data.payload != null ? data.payload : [],
                shippingCharges: data.payload != null ? data.payload[0].shippingCharges : 0.00,
                discount: data.payload != null ? data.payload[0].summaryDiscount : 0.00,
                otherCharges: data.payload != null ? data.payload[0].otherCharges : 0.00,
                grossAmount: data.payload != null ? data.payload[0].grossAmount : 0.00,
                taxSubTotal: data.payload != null ? data.payload[0].taxAmount : 0.00,
            });
            setTimeout(() => this.getServiceItemMaster(), 500);
        } catch (err) {
            console.log("Error in " + this.displayName + ".getContractList function", err);
        }
    }
    handlePaymentTermChange = (selectedOption) => {
        this.setState({
            paymentTermId: (selectedOption.selectedItem !== null ? selectedOption.selectedItem.id : 0),
        });
        let errors = this.state.errors;
        if (selectedOption.selectedItem !== null && selectedOption.selectedItem.id > 0) {
            errors.paymentTermId = "";
            //this.state.errors["paymentTermId"] = "";
            this.setState({
                errors: errors
            });
        }
    }

    handleInvoiceTypeChange = (selectedOption) => {
        this.setState({
            invoiceTypeId: (selectedOption.selectedItem !== null ? selectedOption.selectedItem.id : 0),
        });
        let errors = this.state.errors;
        if (selectedOption.selectedItem !== null && selectedOption.selectedItem.id > 0) {
            //this.state.errors["invoiceTypeId"] = "";
            errors.invoiceTypeId = "";
            this.setState({
                errors: errors
            });
        }
    }
    handleInvoiceDateChanged = (date) => {
        this.setState({
            invoiceDate: date.value,
            //shippingDate: date.value
        });
    }

    handleDueDateChanged = (date) => {
        this.setState({ dueDate: date.value });
    }

    calculateQtyChange = (newData, value, currentRowData) => {
        newData.qty = parseInt(value);
        var totalItemPrice = 0.00;
        totalItemPrice = currentRowData.price !== undefined ? parseFloat(currentRowData.price, 3) * value : 0;
        var totalTax = 0.00;
        // if (totalItemPrice > 0) {
        //     if (this.state.accountTaxList.length > 0) {
        //         this.state.accountTaxList.map((taxitem, i) => {
        //             var singleTax = parseFloat(((totalItemPrice * taxitem.percentage) / 100), 2);
        //             totalTax = totalTax + singleTax;
        //         });
        //     }
        // }
        // // newData.taxAmount = parseFloat(totalTax, 2).toFixed(precisionFormats[2]);
        // // newData.totalAmount = (parseFloat(totalTax, 2) + parseFloat(totalItemPrice, 3)).toFixed(precisionFormats[2]);
        // newData.taxAmount = parseFloat(totalTax, 2).toFixed(precisionFormats[2]);
        // this.setState({ showGrossAmount : newData.taxAmount});
        newData.totalAmount = (parseFloat(totalItemPrice, 3)).toFixed(precisionFormats[2]);
        if (newData.totalAmount > 0) {
            if (this.state.accountTaxList.length > 0) {
                this.state.accountTaxList.map((taxitem, i) => {
                    var singleTax = parseFloat(((newData.totalAmount * taxitem.percentage) / 100), 2);
                    totalTax = totalTax + singleTax;
                });
            }
            var taxSubTotalAmount = parseFloat(totalTax).toFixed(precisionFormats[2]);
            var newGrossAmount = parseFloat(totalTax) + parseFloat(newData.totalAmount)
            this.setState({ showGrossAmount: newGrossAmount, grossAmount: newGrossAmount, taxSubTotal: taxSubTotalAmount });
        }
        return newData;
    }

    calculatePriceChange = (newData, value, currentRowData) => {
        newData.price = parseFloat(value).toFixed(precisionFormats[2]);
        var totalItemPrice = 0.00;
        totalItemPrice = currentRowData.qty !== undefined ? parseFloat(currentRowData.qty, 3) * value : 0;
        var totalTax = 0.00;
        
        // if (totalItemPrice > 0) {
        //     if (this.state.accountTaxList.length > 0) {
        //         this.state.accountTaxList.map((taxitem, i) => {
        //             var singleTax = parseFloat(((totalItemPrice * taxitem.percentage) / 100), 2);
        //             totalTax = totalTax + singleTax;
        //         });
        //     }
        // }
        //newData.taxAmount = parseFloat(totalTax, 2).toFixed(precisionFormats[2]);
        //newData.totalAmount = (parseFloat(totalTax, 2) + parseFloat(totalItemPrice, 3)).toFixed(precisionFormats[2]);
        // newData.taxAmount = parseFloat(totalTax, 2).toFixed(precisionFormats[2]);
        // this.setState({ showGrossAmount : newData.taxAmount});
        newData.totalAmount = (parseFloat(totalItemPrice, 3)).toFixed(precisionFormats[2]);
        if (newData.totalAmount > 0) {
            if (this.state.accountTaxList.length > 0) {
                this.state.accountTaxList.map((taxitem, i) => {
                    
                    var singleTax = parseFloat(((newData.totalAmount * taxitem.percentage) / 100), 2);
                    totalTax = totalTax + singleTax;
                });
            }
            
            console.log(this.state.taxSubTotal)
            var _subtax = this.state.taxSubTotal === 0 ? parseFloat(0) : parseFloat(this.state.taxSubTotal);
           var taxSubTotalAmount = parseFloat(totalTax) + parseFloat(_subtax); //parseFloat(totalTax).toFixed(precisionFormats[2]);
          //var taxSubTotalAmount = parseFloat(totalTax);
          var newGrossAmount = parseFloat(totalTax) + parseFloat(newData.totalAmount)
            this.setState({ showGrossAmount: newGrossAmount, grossAmount: newGrossAmount, taxSubTotal: taxSubTotalAmount });
        }
    }

    calculateDiscountChange = (newData, value, currentRowData) => {
        newData.discount = parseFloat(value).toFixed(precisionFormats[2]);
        newData.totalAmount = (parseFloat(currentRowData.totalAmount) - parseFloat(value)).toFixed(precisionFormats[2]);
    }

    handleValidation = () => {
        let formIsValid = true;
        try {
            let errors = this.state.errors;
            errors.accountId = 0;
            errors.paymentTermId = 0;
            errors.remarks = "";
            errors.itemDetailsGrid = ""
            errors.invoiceTypeId = 0

            if (this.state.accountId === 0) {
                formIsValid = false;
                errors.accountId = "Please select customer.";
            }
            if (this.state.paymentTermId === 0) {
                formIsValid = false;
                errors.paymentTermId = "Please select payment term.";
            }

            if (this.state.remarks.trim() === "") {
                formIsValid = false;
                errors.remarks = "Please enter remarks.";
            }
            if (this.state.itemDetails.length == 0) {
                formIsValid = false;
                errors.itemDetailsGrid = "Please enter item(s).";
            }
            if (this.state.invoiceTypeId === 0) {
                formIsValid = false;
                errors.invoiceTypeId = "Please enter invoice type.";
            }
            this.setState({ errors: errors });
        }
        catch (err) {
            console.log("Error in " + this.displayName + ".handleValidation function", err);
            this.setState({ isSubmited: false })
        }
        return formIsValid;
    }
    isGuid = (stringToTest) => {
        if (stringToTest[0] === "{") {
            stringToTest = stringToTest.substring(1, stringToTest.length - 1);
        }
        var regexGuid = /^(\{){0,1}[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}(\}){0,1}$/gi;
        return regexGuid.test(stringToTest);
    }
    generateInvoice = async (e) => {
        e.preventDefault();
        try {
            if (this.handleValidation()) {
                this.setState({
                    isSubmited: true
                })
                const dataSource = this.refs.grdInvoiceAttributesRef.instance.getDataSource();
                var attributeArray = dataSource._items;
                var request = {};
                request.InvoiceId = this.state.invoiceId
                request.InvoiceDate = formatDate(this.state.invoiceDate, "MM/dd/yyyy hh:mm");
                request.InvoiceDueDate = formatDate(this.state.dueDate, "MM/dd/yyyy hh:mm");
                request.OrderNumber = this.state.orderNo;
                request.CustomerId = this.state.accountId;
                request.PaymentTermTypeId = this.state.paymentTermId;
                request.InvoiceTypeId = this.state.invoiceTypeId;
                var orderItemsList = [];
                var totalAmount = 0, taxAmount = 0;
                attributeArray.forEach((element, index) => {
                    var orderItemRequest = {};
                    orderItemRequest.Id = this.isGuid(element.id) === true ? 0 : element.id;
                    orderItemRequest.ServiceItemId = element.itemId;
                    orderItemRequest.ItemName = "";
                    orderItemRequest.Description = element.description;
                    orderItemRequest.HsnSacCode = element.hsnsacCode;
                    orderItemRequest.TotalQty = parseInt(element.qty);
                    orderItemRequest.UnitPrice = parseFloat(element.price);
                    //orderItemRequest.Discount =element.discount == undefined ? 0.0 :parseFloat(element.discount);
                    orderItemRequest.TotalAmount = parseFloat(element.totalAmount);
                    //orderItemRequest.TotalTax = parseFloat(element.taxAmount);
                    orderItemRequest.Remarks = "";
                    totalAmount = totalAmount + parseFloat(element.totalAmount);
                    //taxAmount = taxAmount + parseFloat(element.taxAmount);
                    orderItemsList.push(orderItemRequest);
                });

                request.OrderDetails = {};
                request.autoGenerateInvoiceDetails = orderItemsList;
                request.TotalAmount = parseFloat(totalAmount);
                request.Discount = parseFloat(this.state.discount);
                request.TaxAmount = parseFloat(this.state.taxSubTotal);
                request.GrossAmount = parseFloat(this.state.grossAmount);  // parseFloat(totalAmount) - parseFloat(this.state.discount) + parseFloat(this.state.otherCharges) + parseFloat(this.state.shippingCharges);
                request.ShippingCharges = parseFloat(this.state.shippingCharges);
                request.OtherCharges = parseFloat(this.state.otherCharges);
                request.Remarks = this.state.remarks;
                request.TermsCondition = this.state.termsAndConditions === undefined ? "" :this.state.termsAndConditions;
                request.UserId = this.state.sessionUser.id;
                request.Source = "Web";
                
                request.InvoiceStatusId = this.state.invoiceId === 0 ? parseInt(invoiceStatus.Draft) : parseInt(invoiceStatus.Approved);
                const requestParams = getRequestParams('POST', request);
                const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'invoice/generateInvoice', requestParams);
                const data = await response.json();
                if (data.isSuccess) {
                    this.setState({ invoiceDocumentOrderId: data.payload, resultMeassage: data.message })
                    if (this.state.documentSelectedFile.size !== undefined || this.state.documentSelectedFile.length > 0) {
                        if (this.state.id == 0) {
                            this.documentUpload();
                        }
                    }
                    else {
                        Swal.fire({
                            icon: 'success',
                            title: 'Success',
                            text: data.message,
                        }).then((result) => {
                            if (result.isConfirmed) {
                                if (data.isSuccess) {
                                    window.location.href = "/invoice/list";
                                }
                            }
                            this.setState({ isSubmited: false })
                        });
                    }

                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: data.message,
                    }).then((result) => {
                        if (result.isConfirmed) {
                        }
                        this.setState({ isSubmited: false })
                    });
                    this.setState({ isSubmited: false })
                }

                this.setState({
                    isSubmited: true
                })
            }
        } catch (err) {
            console.log("Error in " + this.displayName + ".generateInvoice function", err);
            this.setState({ isSubmited: false })
        }
    }

    handleClearControls = () => {
        this.setState({
            isSubmited: false,
            loadPanelVisible: false,
            accountList: [],
            paymentTermList: [],
            itemList: [],
            itemDetails: [],
            hsnsacList: [],
            id: 0,
            accountId: 0,
            orderNo: "",
            orderDate: new Date(),
            shippingDate: new Date(),
            paymentTermId: 0,
            shippingCharges: 0.00,
            discount: 0.00,
            otherCharges: 0.00,
            grossAmount: 0.00,
            remarks: "",
            termsAndConditions: "",
            accountTaxList: [],
            sessionUser: JSON.parse(sessionStorage.getItem("sessionUser")),
            isNewItem: false,
            orderStatusId: 0,
            errors: {
                accountId: 0,
                orderNo: "",
                orderDate: new Date(),
                shippingDate: new Date(),
                paymentTermId: 0,
                shippingCharges: 0.00,
                discount: 0.00,
                otherCharges: 0.00,
                grossAmount: 0.00,
                remarks: "",
                termsAndConditions: "",
            }
        });
    }
    handleCancel = () => {
        window.location.href = "/invoice/list";
    }
    openDocumentModel = () => {
        this.setState({
            documentModelIsOpen: true
        });
        setTimeout(() => this.getDocuments(), 100);
    }
    closeDocumentModel = () => {
        this.setState({
            documentModelIsOpen: false
        });
    }
    getDocuments = async () => {
        try {
            this.setState({
                loadPanelVisible: true
            });
            const requestParams = getRequestParams('GET', {});
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'file/' + parseInt(documentType.Invoice) + '/' + this.state.invoiceId + '/documents', requestParams);
            const data = await response.json();
            var imageListObj = [];
            var pdfListObj = [];
            var excelListObj = [];
            var otherListObj = [];
            if (data.documents != null && data.documents.length > 0) {
                for (var i = 0; i < data.documents.length; i++) {
                    if (data.documents[i].fileGuid != '00000000-0000-0000-0000-000000000000') {
                        var img = {};
                        const requestParamsImage = getRequestParams('GET', '');
                        const responseImage = await fetch(process.env.REACT_APP_API_BASE_URL + 'file/download/' + data.documents[i].fileGuid, requestParamsImage);
                        const dataImage = await responseImage.json();
                        if (dataImage.fileExtension.toUpperCase() === '.PDF') {
                            img.src = 'data:' + dataImage.mimeType + ';base64,' + dataImage.fileContent;
                            img.fileGuid = data.documents[i].fileGuid;
                            img.fileName = dataImage.fileName;
                            pdfListObj.push(img);
                        } else if (dataImage.fileExtension.toUpperCase() === '.XLXS' || dataImage.fileExtension.toUpperCase() === '.CSV' || dataImage.fileExtension.toUpperCase() === '.XLS') {
                            img.src = 'data:' + dataImage.mimeType + ';base64,' + dataImage.fileContent;
                            img.fileGuid = data.documents[i].fileGuid;
                            img.fileName = dataImage.fileName;
                            excelListObj.push(img);
                        } else if (dataImage.fileExtension.toUpperCase() === '.JPG' || dataImage.fileExtension.toUpperCase() === '.PNG' || dataImage.fileExtension.toUpperCase() === '.JPEG') {
                            img.src = 'data:' + dataImage.mimeType + ';base64,' + dataImage.fileContent;
                            img.fileGuid = data.documents[i].fileGuid;
                            imageListObj.push(img);
                        } else {
                            img.src = 'data:' + dataImage.mimeType + ';base64,' + dataImage.fileContent;
                            img.fileGuid = data.documents[i].fileGuid;
                            img.fileName = dataImage.fileName;
                            otherListObj.push(img);
                        }

                    }
                }
            }

            this.setState({
                loadPanelVisible: false,
                imageList: imageListObj,
                pdfList: pdfListObj,
                excelList: excelListObj,
                otherList: otherListObj
            });

        } catch (e) {
            console.log(e);
        }
    }

    onEditorPreparing = (e) => {
        if (e.parentType === "dataRow" && e.dataField === "itemId") {
            e.editorOptions.onValueChanged = (ev) => {
                let selectedItem = ev.component.option("selectedItem");
                e.setValue(selectedItem);
            };
        }
    }
    setCellValue = (rowData, value) => {
        rowData.itemId = value.id;
        rowData.hsnsacCode = value.hsnCode;
    }

    setServiceItemCellValue = (rowData, value) => {
        rowData.itemId = value.id;
        rowData.hsnsacCode = value.hsnSacCode;
    }

    handleFileInputChange = async (e) => {
        let images = e.target.files;
        var i;
        for (i = 0; i < e.target.files.length; i++) {
            this.state.targetFile.push(images[i]);
        }
        this.setState({
            documentSelectedFile: this.state.targetFile
        })
        //console.log(this.state.documentSelectedFile);
    }
    removeAll = () => {
        document.getElementById("file").value = null;
    }
    resetFileInput = (e) => {
        var data = this.state.documentSelectedFile.filter(item => item.name === e.target.value)
        var _data = this.state.documentSelectedFile.filter(item => item.name !== data[0].name)
        var data1 = this.state.documentSelectedFile.filter(item => item.name === data[0].name)
        this.setState({
            documentSelectedFile: _data,
            targetFile: _data
        }, () => 500)
        const arrLength = _data.length;
        console.log(arrLength - 1);
        if (_data.length == 0) {
            this.removeAll();
        }
        else {
            document.getElementById("file").value = null
        }
    };
    documentUpload = async () => {
        for (let i = 0; i < this.state.documentSelectedFile.length; i++) {
            const url = process.env.REACT_APP_API_BASE_URL + 'file/upload';
            const formData = new FormData();
            console.log(this.state.documentSelectedFile);
            formData.append('body', this.state.documentSelectedFile[i]);
            const config = {
                headers: {
                    'content-type': 'multipart/form-data',
                    'UserId': this.state.sessionUser.id,
                    'Authorization': 'Bearer ' + sessionStorage.getItem('session_token'),
                },
            };
            this.setState({
                loadPanelVisible: true
            });
            axios.post(url, formData, config).then((response) => {
                var resp = response.data;
                if (resp != null) {
                    this.setState({
                        fileGuid: resp.fileGuid
                    })
                    this.saveDocument(resp)
                }
            });
            this.setState({
                loadPanelVisible: false
            });
        }
    }
    saveDocument = async (data) => {
        if (data !== null) {
            this.setState({
                fileGuid: data.fileGuid
            });
            try {
                var isSuccess = false;
                var resultMessage = '';
                var request = new Object();
                request.FileGuid = data.fileGuid;
                request.FileRecordType = parseInt(3);
                request.UserId = Number(this.state.sessionUser.id);
                request.DocumentType = parseInt(documentType.Invoice);
                request.Id = this.state.invoiceDocumentOrderId;
                const requestVehicleParams = getRequestParams('POST', request);
                const responseVehicle = await fetch(process.env.REACT_APP_API_BASE_URL + 'file/save-documents', requestVehicleParams);
                const dataVehicle = await responseVehicle.json();
                isSuccess = dataVehicle.isSuccess;
                resultMessage = dataVehicle.message;
                if (isSuccess) {
                    Swal.fire({
                        icon: isSuccess ? 'success' : 'error',
                        title: isSuccess ? 'success' : 'Oops...',
                        text: this.state.resultMeassage,
                    }).then((result) => {
                        if (isSuccess) {
                            window.location.href = "/invoice/list"
                        }
                    });
                } else {
                    this.onfailure('Failed to upload image.', resultMessage);
                }
                this.setState({ isSubmited: false })
            } catch (e) {

            }

        }
    }

    calculateSelectedRow = (options) => {
        if (options.name === 'SelectedRowsSummary') {
            if (options.summaryProcess === 'start') {
                options.totalValue = 0.00;
            } else if (options.summaryProcess === 'calculate') {
                options.totalValue += parseInt(options.value.discount);
                var amount = options.totalValue
                //this.setState({ discount: amount });
            }
        }
        else
            if (options.name === 'SelectedTotalSummary') {
                if (options.summaryProcess === 'start') {
                    options.totalValue = 0.00;
                } else if (options.summaryProcess === 'calculate') {
                    options.totalValue += parseInt(options.value.totalAmount);
                    var amount = options.totalValue
                    var newGrossAmount = amount
                    
                    // if (this.state.invoiceId !== 0) {
                    //     this.setState({ grossAmount: newGrossAmount });
                    // }
                    // 
                    // console.log(this.state.grossAmount);
               
                    // this.setState({ grossAmount: this.state.grossAmount, showGrossAmount: newGrossAmount })
                    if (this.state.invoiceId !== 0) {
                           this.setState({ grossAmount: this.state.grossAmount });
                       }
                       else if(this.state.orderNo !== null){
                        this.setState({ grossAmount: this.state.grossAmount ,showGrossAmount : this.state.grossAmount});
                       }
                       else if(this.state.showGrossAmount == 0){
                           this.setState({ grossAmount: amount ,showGrossAmount : newGrossAmount});
                       }
                       
                       
                       else{
                        
                           var updateshowTax = parseFloat(this.state.taxSubTotal) + parseFloat(newGrossAmount)
                           this.setState({ grossAmount: updateshowTax ,showGrossAmount : updateshowTax});
                       }
                      
                    
                   
                }
            }
    }


    render() {
        const customizeQtyText = (data) => {
            return `Total - ${data.value}`;
        }

        const customizePriceText = (data) => {
            return `Total - ${data.value.toFixed(precisionFormats[2])}`;
        }

        const customizeTaxText = (data) => {
            return `Total - ${data.value.toFixed(precisionFormats[2])}`;
        }

        const customizeDiscountText = (data) => {
            return `Total - ${data.value.toFixed(precisionFormats[2])}`;
        }

        const customizeTotalAmountText = (data) => {
            return `Total - ${data.value.toFixed(precisionFormats[2])}`;
        }

        const renderGridNumberCell = (data) => {
            return <div><small><i class={currencySymbolFormats.currencyFormat}></i></small>&nbsp;&nbsp;{parseFloat(data.value !== undefined ? data.value : 0.00).toFixed(precisionFormats[2])}</div>;
        }

        return (
            <Fragment>
                <div className="section-body">
                    <div className='card bg-filter'>
                        <div className='card-body'>
                            <div className="section-header">
                                <h6 className='m-0'>{this.state.invoiceId > 0 ? "Invoice# " + this.state.invoiceNo : "New Invoice"} </h6>
                                <div className="section-header-buttons">
                                    <button type="submit" className="btn btn-success" onClick={this.generateInvoice} >
                                        {this.state.isSubmited && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                        {!this.state.isSubmited && <span title="Submit">Submit</span>}
                                    </button>&nbsp;&nbsp;
                                    <button type="submit" className="btn btn-success" onClick={this.generateInvoice} style={{ display: this.state.invoiceId > 0 ? 'block' : 'none' }} >
                                        {this.state.isSubmited && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                        {!this.state.isSubmited && <span title="Submit">Submit & Approve </span>}
                                    </button>&nbsp;&nbsp;
                                    <button type="submit" className="btn btn-outline-secondary" onClick={this.handleCancel}>
                                        <span title="Cancel">Cancel</span>
                                    </button>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className='card mb-2'>
                        <div className='card-body'>
                            <div className='sub-title-color font-weight-bold'>Invoice Info</div>
                            <div className='row'>
                                <div className="col-lg-4 col-md-3 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <label htmlFor="">Customer <span className="text-danger">*</span></label>
                                        <SelectBox
                                            dataSource={this.state.accountList}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select customer"
                                            name="id"
                                            defaultValue={0}
                                            value={this.state.accountId}
                                            displayExpr='name'
                                            valueExpr='id'
                                            onSelectionChanged={this.handleAccountChange}
                                            showClearButton={true}
                                            disabled={this.state.invoiceId > 0}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["accountId"].length > 0 && <span className="error invalid-feedback">{this.state.errors["accountId"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-3 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <label htmlFor="">Order <span className="text-danger">*</span></label>
                                        <SelectBox
                                            dataSource={this.state.salesOrderList}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select order"
                                            name="soNo"
                                            defaultValue={0}
                                            value={this.state.orderNo}
                                            displayExpr='soNo'
                                            valueExpr='soNo'
                                            onSelectionChanged={this.handleOrderChange}
                                            showClearButton={true}
                                            disabled={this.state.id > 0}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["orderNo"].length > 0 && <span className="error invalid-feedback">{this.state.errors["orderNo"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-3 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <label htmlFor="">Invoice Type<span className="text-danger">*</span></label>
                                        <SelectBox
                                            dataSource={this.state.invoiceType}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select invoice type"
                                            name="id"
                                            defaultValue={0}
                                            value={this.state.invoiceTypeId}
                                            displayExpr='name'
                                            valueExpr='id'
                                            onSelectionChanged={this.handleInvoiceTypeChange}
                                            showClearButton={true}
                                            disabled={this.state.invoiceId > 0}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["invoiceTypeId"].length > 0 && <span className="error invalid-feedback">{this.state.errors["invoiceTypeId"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-3 col-sm-12 col-xs-12">
                                    &nbsp;
                                </div>

                                {/* <div className="col-lg-2 col-md-3 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <label htmlFor="">Order#</label>
                                        <input type="text" className="form-control form-control-sm" name="orderNo" placeholder="Enter Order No" value={this.state.orderNo}
                                            disabled={this.state.id > 0} title="Order No" maxLength="20" onChange={this.handleInputChange} />
                                        {this.state.errors["orderNo"].length > 0 && <span className="error invalid-feedback">{this.state.errors["orderNo"]}</span>}
                                    </div>
                                </div> */}
                                <div className="col-lg-2 col-md-3 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <label htmlFor="">Invoice Date</label>
                                        <DateBox type="date"
                                            className="replay-date-time"
                                            name="invoiceDate"
                                            placeholder="dd/mm/yyyy"
                                            displayFormat="dd/MM/yyyy"
                                            showAnalogClock={true}
                                            useMaskBehavior={true}
                                            defaultValue={this.state.invoiceDate}
                                            value={this.state.invoiceDate}
                                            onValueChanged={this.handleInvoiceDateChanged}
                                            width="100%"
                                            //disabled={this.state.invoiceId > 0}
                                            acceptCustomValue={false}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-3 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <label htmlFor="">Due Date</label>
                                        <DateBox type="date"
                                            className="replay-date-time"
                                            name="dueDate"
                                            placeholder="dd/mm/yyyy"
                                            displayFormat="dd/MM/yyyy"
                                            showAnalogClock={true}
                                            useMaskBehavior={true}
                                            defaultValue={this.state.dueDate}
                                            value={this.state.dueDate}
                                            onValueChanged={this.handleDueDateChanged}
                                            min={this.state.dueDate}
                                            width="100%"
                                            //  disabled={false}
                                            disabled={this.state.invoiceId > 0}
                                            acceptCustomValue={false}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-3 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <label htmlFor="">Payment Term <span className="text-danger">*</span></label>
                                        <SelectBox
                                            dataSource={this.state.paymentTermList}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select payment term"
                                            name="id"
                                            defaultValue={0}
                                            value={this.state.paymentTermId}
                                            displayExpr='name'
                                            valueExpr='id'
                                            onSelectionChanged={this.handlePaymentTermChange}
                                            showClearButton={true}
                                            disabled={this.state.invoiceId > 0}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["paymentTermId"].length > 0 && <span className="error invalid-feedback">{this.state.errors["paymentTermId"]}</span>}
                                    </div>
                                </div>

                            </div>
                            <div className='row'>
                                <div className="col-lg-2" style={{ display: this.state.invoiceId > 0 ? 'block' : 'none' }}>
                                    <br></br>
                                    <button className="btn btn-ouline-secondary" title="View Document(s)" onClick={() => { this.openDocumentModel() }} ><span className='fas fa-folder'></span>&nbsp;View Document(s)</button>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className='card mb-2'>
                        <div className='card-body'>
                            <div className='sub-title-color font-weight-bold'>Item Info</div>
                            <DataGrid
                                ref="grdInvoiceAttributesRef"
                                dataSource={this.state.itemDetails}
                                keyExpr="id"
                                allowColumnReordering={true}
                                showBorders={true}
                                onRowUpdating={this.onRowUpdating}
                                onRowUpdated={this.onRowUpdated}
                                onRowInserting={this.onRowInserting}
                                onRowRemoved={this.onRowRemoved}
                                onEditorPreparing={this.onEditorPreparing}>
                                <Paging enabled={false} />
                                <Editing mode="row"
                                    allowUpdating={true}
                                    allowDeleting={true}
                                    allowAdding={this.state.accountId > 0 && this.state.invoiceTypeId > 0 ? true : false}
                                    editorOptions={true} />

                                {
                                    parseInt(this.state.invoiceTypeId) === parseInt(orderType.GoodsCapex) ?
                                        <Column dataField="itemId" caption="Item" setCellValue={this.setCellValue} allowEditing={this.state.invoiceId > 0 ? false : true}>
                                            <Lookup dataSource={this.state.itemList} displayExpr="name" valueExpr="id" />
                                            <RequiredRule />
                                        </Column>
                                        : <Column dataField="itemId" caption="Item" setCellValue={this.setServiceItemCellValue}>
                                            <Lookup dataSource={this.state.serviceItemList} displayExpr="name" valueExpr="id" />
                                            <RequiredRule />
                                        </Column>
                                }

                                <Column dataField="description" caption="Description">
                                    <RequiredRule />
                                    <PatternRule message={'Please enter description'} pattern={alphanumericSpecialcaseValidation} />
                                    <StringLengthRule message="Description can not be more than 150 characters" max={150} />
                                </Column>
                                <Column dataField="hsnsacCode" caption="HSN Code" allowEditing={false}>
                                    {/* <Lookup dataSource={this.state.hsnsacList} displayExpr="name" valueExpr="code" allowEditing={false} /> */}
                                    <RequiredRule />
                                </Column>
                                <Column dataField="qty" caption="Qty" dataType="number" setCellValue={this.calculateQtyChange} alignment="right">
                                    <RequiredRule />
                                    <PatternRule message={'Quantity should be in number'} pattern={numberValidation} />
                                </Column>
                                <Column dataField="price" caption="Unit Price" dataType="decimal" setCellValue={this.calculatePriceChange} format={"#,##0.##"} alignment="right"
                                    cellRender={renderGridNumberCell}>
                                    <RequiredRule />
                                    <PatternRule message={'Item Price should be decimal'} pattern={floatValidation} />
                                </Column>
                                {/* <Column dataField="taxAmount" caption="Tax Amount" dataType="decimal" allowEditing={false} format={"#,##0.##"} alignment="right"
                                        cellRender={renderGridNumberCell}>
                                        <RequiredRule />
                                        <PatternRule message={'Tax Amount should be decimal'} pattern={floatValidation} />
                                    </Column> */}
                                {/* <Column dataField="discount" caption="Discount" dataType="decimal" setCellValue={this.calculateDiscountChange} disabled={true} format={"#,##0.##"} alignment="right"
                                        cellRender={renderGridNumberCell}> */}
                                {/* <RequiredRule /> */}
                                {/* <PatternRule message={'Item Discount should be decimal'} pattern={floatValidation} />
                                    </Column> */}
                                <Column dataField="totalAmount" caption="Item Total" dataType="decimal" allowEditing={false} format={"#,##0.##"} alignment="right"
                                    cellRender={renderGridNumberCell}></Column>
                                <Summary calculateCustomSummary={this.calculateSelectedRow}>
                                    <TotalItem
                                        name="SelectedRowsSummary"
                                        summaryType="custom"
                                        // valueFormat="currency"
                                        displayFormat="Total - {0}"
                                        showInColumn="discount" />
                                    <TotalItem
                                        name="SelectedTotalSummary"
                                        summaryType="custom"
                                        // valueFormat="currency"
                                        displayFormat="Sub Total - {0}"
                                        showInColumn="totalAmount" />
                                    {/* <TotalItem column="qty" summaryType="sum" customizeText={customizeQtyText} />
                                        <TotalItem column="price" summaryType="sum" customizeText={customizePriceText} />
                                        <TotalItem column="taxAmount" summaryType="sum" customizeText={customizeTaxText} />
                                        <TotalItem column="discount" summaryType="sum" customizeText={customizeDiscountText} />
                                        <TotalItem column="totalAmount" summaryType="sum" customizeText={customizeTotalAmountText} /> */}
                                </Summary>
                                <SortByGroupSummaryInfo summaryItem="count" />
                                {this.state.errors["itemDetailsGrid"].length > 0 && <span className="error invalid-feedback">{this.state.errors["itemDetailsGrid"]}</span>}
                            </DataGrid>
                            <div className='row mt-3'>
                                <div className='col-9'>
                                    <div className="form-group">
                                        <label htmlFor="">Remarks</label>
                                        {/* <textarea className="form-control" placeholder="Enter remarks here"></textarea> */}
                                        <textarea type="text" className="form-control form-control-sm" name="remarks" placeholder="Enter remarks"
                                            maxLength="200" title="Remarks" value={this.state.remarks} onChange={this.handleInputChange} style={{ width: "90%", height: "50px" }} />
                                        {this.state.errors["remarks"].length > 0 && <span className="error invalid-feedback">{this.state.errors["remarks"]}</span>}
                                    </div>
                                </div>
                                <div className='col-3'>
                                    <table border="0" cellPadding={"5"} cellSpacing={"0"} width={"100%"} align="right">
                                        <tr>
                                            <td className='text-left' width="40%">Shipping Charges</td>
                                            <td>
                                                <NumberBox type="number" className="form-control form-control-sm" name="ShippingCharges" placeholder="Enter shipping charges"
                                                    defaultValue={0.00} title="Shipping Charges" value={this.state.shippingCharges} onValueChanged={this.handleShippingChargesChange} min={0.00} />
                                                {/* {this.state.errors["shippingCharges"].length > 0 && <span className="error invalid-feedback">{this.state.errors["shippingCharges"]}</span>} */}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className='text-left'>Other Charges</td>
                                            <td>
                                                <NumberBox type="number" className="form-control form-control-sm" name="OtherCharges" placeholder="Enter other charges"
                                                    defaultValue={0.00} title="Other Charges" value={this.state.otherCharges} onValueChanged={this.handleOtherChargesChange} min={0.00} />
                                                {this.state.errors["otherCharges"].length > 0 && <span className="error invalid-feedback">{this.state.errors["otherCharges"]}</span>}
                                            </td>
                                        </tr>

                                        <tr>
                                            <td className='text-left'>Discount</td>
                                            <td>
                                                <NumberBox type="number" className="form-control form-control-sm" name="Discount" placeholder="Enter discount"
                                                    defaultValue={0.00} title="Discount" value={this.state.discount} onValueChanged={this.handleDiscountChange} min={0.00} />
                                                {/* {this.state.errors["discount"].length > 0 && <span className="error invalid-feedback">{this.state.errors["discount"]}</span>} */}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className='text-left' width="40%">
                                                Total Tax
                                                <div className='total-tax-amount'>{this.state.accountTaxList == null ? [] : this.state.accountTaxList.map((item, index) => {
                                                    return (
                                                        <span>{item.taxName + '@' + item.percentage + '%'}</span>
                                                    )
                                                })}</div>
                                            </td>
                                            <td>
                                                {/* data.payload[0].taxName */}
                                                {/* {this.state.accountTaxList == null ?[]:    <div>{this.state.accountTaxList[0].taxName + '@' + this.state.accountTaxList[0].percentage}</div> } */}
                                                <NumberBox type="number" className="form-control form-control-sm" name="taxSubTotal" placeholder="0.00"
                                                    defaultValue={0.00} title="taxSubTotal" disabled={true} value={this.state.taxSubTotal} min={0.00} />
                                                {/* {this.state.errors["shippingCharges"].length > 0 && <span className="error invalid-feedback">{this.state.errors["shippingCharges"]}</span>} */}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className='text-left'>Gross amount</td>
                                            <td>
                                                <NumberBox type="number" className="form-control form-control-sm gross-total" name="GrossAmount" placeholder="0.00" disabled={true}
                                                    format="#0.00" defaultValue={0.00} title="Gross Amount" value={this.state.grossAmount} onValueChanged={this.handleGrossAmountChange} min={0.00} />
                                                {/* {this.state.errors["grossAmount"].length > 0 && <span className="error invalid-feedback">{this.state.errors["grossAmount"]}</span>} */}
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>


                        </div>
                    </div>
                    <div className='card'  style={{ display: this.state.invoiceId === 0 ? 'block' : 'none' }}>
                        <div className='card-body'>
                            <div className='sub-title-color font-weight-bold'>Document Info</div>
                            <div className='row'>
                                {/* <div className="col-6">
                                    <div className="form-group">
                                        <label htmlFor="">Terms & Conditions</label>
                                        <textarea type="text" className="form-control form-control-sm" name="TermsAndConditions" autoComplete="termsAndConditions" placeholder="Enter terms & conditions"
                                            maxLength="200" title="Enter terms & conditions" value={this.state.termsAndConditions} onChange={this.handleInputChange} />
                                        {this.state.errors["termsAndConditions"].length > 0 && <span className="error invalid-feedback">{this.state.errors["termsAndConditions"]}</span>}
                                    </div>
                                </div> */}
                                <div className="col-8">
                                    <div className="form-group">
                                        <label htmlFor="">Attach File(s) to Invoice</label>
                                        <input className="form-control" type="file" id="file" onClick={() => { this.removeAll() }} onChange={(e) => { this.handleFileInputChange(e, false) }} />
                                        {/* <label htmlFor="" className='small text-muted'>You can upload a maximum of 5 files, 5MB each</label> */}
                                        {this.state.documentSelectedFile.map((item, index) => {
                                            return (
                                                <div key={index}>
                                                    <label >{item.name} <button value={item.name} onClick={this.resetFileInput} class="btn btn-outline-secondary fas fa-trash text-danger"></button></label>
                                                </div>
                                            );
                                        })}
                                        {/* <label htmlFor="" className='small text-muted'>You can upload a maximum of 5 files, 5MB each</label> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal ariaHideApp={false}
                    isOpen={this.state.documentModelIsOpen}
                    style={customStyles}
                    className={"react-modal"}
                    onRequestClose={this.closeDocumentModel}
                    shouldCloseOnOverlayClick={false}
                    contentLabel="Example Modal">
                    <form method="post" style={this.state.isSubmited ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                        <div className="modal-header">
                            <h6 className="modal-title">Document</h6>
                        </div>
                        <div className="modal-body">
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <div className="box-body no-padding" style={{ maxHeight: 400 + 'px', overflowY: "scroll" }}>
                                    <div>
                                        <ul className="users-list clearfix">
                                            {this.state.imageList.length > 0 && this.state.imageList.map((item, j) =>
                                                <li style={{ position: "relative" }} key={j}>
                                                    <a href={item.src} download={item.fileName}><div className="framehover"><img src={item.src} width="150" alt="Image" /></div></a>
                                                </li>
                                            )}
                                            {this.state.pdfList.length > 0 && this.state.pdfList.map((item, j) =>
                                                <li style={{ position: "relative" }} key={j}>
                                                    <a href={item.src} download={item.fileName}><div className="framehover"><img src="../../assets/img/pdf_icon.png" width="150" alt="Blank Image" /></div></a>
                                                </li>
                                            )}
                                            {this.state.excelList.length > 0 && this.state.excelList.map((item, j) =>
                                                <li style={{ position: "relative" }} key={j}>
                                                    <a href={item.src} download={item.fileName}><div className="framehover"><img src="../../assets/img/excel_icon.png" width="100" alt="Blank Image" /></div></a>
                                                </li>
                                            )}
                                            {this.state.otherList.length > 0 && this.state.otherList.map((item, j) =>
                                                <li style={{ position: "relative" }} key={j}>
                                                    <a href={item.src} download={item.fileName}><div className="framehover"><img src="../../assets/img/other_icon.png" width="100" alt="Blank Image" /></div></a>
                                                </li>
                                            )}

                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="modal-footer">
                            <button type="button" className="btn btn-outline-secondary" onClick={this.closeDocumentModel} title="Cancel">Cancel</button>
                        </div>
                    </form>
                </Modal>
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={position}
                    visible={this.state.loadPanelVisible}
                    showIndicator={true}
                    shading={true}
                    showPane={true}
                    hideOnOutsideClick={false}
                />
            </Fragment>
        )
    };
}