import React, { Fragment, Component } from 'react';
import DataGrid, {
    Column,
    Export, Grouping,
    GroupPanel,
    HeaderFilter, Pager,
    Paging,
    SearchPanel,
    SortByGroupSummaryInfo
} from 'devextreme-react/data-grid'; //npm i devextreme-react
import Modal from 'react-modal'; //npm install --save react-modal;
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import { PatternFormat } from 'react-number-format';
import { getRequestParams } from '../../helpers/globalfunctions.js';
import { alphanumericValidation, alphanumericSpecialcaseValidation, numberValidation } from '../../helpers/globalvalidations.js';
import { LoadPanel } from 'devextreme-react/load-panel';
import Swal from 'sweetalert2'; //npm i sweetalert2 --save
import { toast } from 'react-toastify'; //npm i react-toastify --save

const position = { of: '#historydiv' };

const customStyles = {
    content: {
        width: '50%',
    }
}

export class WarehouseComponent extends Component {
    static displayName = WarehouseComponent.name;

    constructor(props) {
        super(props);

        this.state = {
            isSubmited: false,
            loadPanelVisible: false,
            warehouseModal: false,
            WarehouseSource: null,
            singelData: null,
            id: 0,
            Account: "",
            Name: "",
            phone: "",
            ContactPerson: "",
            State: "",
            City: "",
            Country: "",
            ZipCode: "",
            Address1: "",
            Address2: "",
            accountList: [],
            accountId: 0,
            sessionUser: JSON.parse(sessionStorage.getItem("sessionUser")),
            warehousename: "",
            contactPerson: "",
            phone: "",
            address: "",
            errors: {
                isSubmited: false,
                loadPanelVisible: false,
                warehousename: "",
                contactPerson: "",
                phone: "",
                address: "",
                errors: "",
                id: 0,
                Account: "",
                Name: "",
                phone: "",
                ContactPerson: "",
                State: "",
                City: "",
                Country: "",
                ZipCode: "",
                Address1: "",
                Address2: "",
            }
        }
    }

    async componentDidMount() {
        await this.getWarehouseMaster();
        await this.getAccounts();


    }

    getWarehouseMaster = async () => {
        this.setState({
            loadPanelVisible: true
        });
        try {
            var request = {};
            const requestParams = getRequestParams("GET", request);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'warehouse/warehouse', requestParams);
            const data = await response.json();
            this.setState({
                WarehouseSource: data.payload,
                loadPanelVisible: false
            });
        } catch (err) {
            console.log("Error in " + this.displayName + ".getWarehouseMaster function", err);
            this.setState({
                loadPanelVisible: false
            });
        }
    }

    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift({
            location: 'after',
            widget: 'dxButton',
            options: {
                icon: 'refresh',
                onClick: this.getWarehouseMaster.bind(this)
            }
        });
    }

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        //const alphanumericValidation = /^[A-Za-z0-9]*$/;
        debugger;
        const numeric = /^[]?\d*(?:[]\d*)?$/;
        switch (name) {
            case 'categoryName':
                if (alphanumericValidation.test(event.target.value))
                    this.setState({ categoryName: event.target.value });
                break;
            case 'hsnCode':
                if (alphanumericValidation.test(event.target.value))
                    this.setState({ hsnCode: event.target.value });
                break;
            case 'categoryDescription':
                if (alphanumericValidation.test(event.target.value))
                    this.setState({ categoryDescription: event.target.value });
                break;
            case 'Name':
                if (alphanumericValidation.test(event.target.value))
                    this.setState({ Name: event.target.value });
                break;
            case 'ContactPerson':
                if (alphanumericValidation.test(event.target.value)) {
                    //console.log("true");
                    this.setState({ ContactPerson: event.target.value });
                }
                break;
            case 'State':
                if (alphanumericValidation.test(event.target.value)) {
                    //console.log("true");
                    this.setState({ State: event.target.value });
                }
                break;
            case 'City':
                if (alphanumericValidation.test(event.target.value)) {
                    //console.log("true");
                    this.setState({ City: event.target.value });
                }
                break;
            case 'Country':
                if (alphanumericValidation.test(event.target.value)) {
                    //console.log("true");
                    this.setState({ Country: event.target.value });
                }
                break;
            case 'Address1':
                if (alphanumericSpecialcaseValidation.test(event.target.value)) {
                    //console.log("true");
                    this.setState({ Address1: event.target.value });
                }
                break;
            case 'Address2':
                if (alphanumericSpecialcaseValidation.test(event.target.value)) {
                    //console.log("true");
                    this.setState({ Address2: event.target.value });
                }
                break;

            case 'ZipCode':
                if (numeric.test(event.target.value)) {
                    //console.log("true");
                    this.setState({ ZipCode: event.target.value });
                }
                
                break;
            default:
                this.setState({ [name]: value });
                break;
        }

        //this statement use for hidding given validation
        this.state.errors[name] = "";

    }

    handleWarehouseModal = async (e) => {
        this.setState({
            warehouseModal: true,
            singelData: null
        });
    }

    closeWarehouseModal = () => {
        this.setState({
            isSubmited: false,
            loadPanelVisible: false,
            warehouseModal: false,
            singelData: null,
            id: 0,
            warehousename: "",
            contactPerson: "",
            phone: "",
            address: "",
            Account: "",
            Name: "",
            phone: "",
            ContactPerson: "",
            State: "",
            City: "",
            Country: "",
            ZipCode: "",
            Address1: "",
            Address2: "",
            file: null,
            fileGuid: "00000000-0000-0000-0000-000000000000",
            accountId: 0,
            documentTypeId: 0,
            errors: {
                warehousename: "",
                contactPerson: "",
                phone: "",
                address: "",
                errors: "",
                id: 0,
                Account: "",
                Name: "",
                phone: "",
                ContactPerson: "",
                State: "",
                City: "",
                Country: "",
                ZipCode: "",
                Address1: "",
                Address2: "",


            }
        });
    }

    handleValidation = () => {
        let formIsValid = true;

        try {
            let errors = this.state.errors;
            errors.Account = "";
            errors.Name = "";
            errors.phone = "";
            errors.ContactPerson = "";
            errors.State = "";
            errors.City = "";
            errors.Country = "";
            errors.ZipCode = "";
            errors.Address1 = "";
            errors.Address2 = "";

            // if (this.state.Account.trim() === "") {
            //     formIsValid = false;
            //     errors.Account = "Please select account ."; //assiging the value 
            // }
            if (this.state.Name.trim() === "") {
                formIsValid = false;
                errors.Name = "Please enter warehouse name.";
            }
            if (this.state.phone === null || this.state.phone.trim() === "") {
                formIsValid = false;
                errors.phone = "Please enter phone number.";
            }
            else if (this.state.phone.trim().replace("(", "").replace(")", "").replace("-", "").replaceAll(" ", "").length != 10) {
                formIsValid = false;
                errors.phone = "Invalid mobile number.";
            }
            if (this.state.ContactPerson.trim() === '') {
                formIsValid = false;
                errors.ContactPerson = "Please enter contact person.";
            }
            if (this.state.State.trim() === "") {
                formIsValid = false;
                errors.State = "Please enter state.";
            }
            if (this.state.City.trim() === "") {
                formIsValid = false;
                errors.City = "Please enter city.";
            }
            if (this.state.Country.trim() === "") {
                formIsValid = false;
                errors.Country = "Please enter country.";
            }
            if (this.state.ZipCode.trim() === "") {
                formIsValid = false;
                errors.ZipCode = "Please enter postal code.";
            }
            if (this.state.Address1.trim() === "") {
                formIsValid = false;
                errors.Address1 = "Please enter address 1.";
            }
            if (this.state.Address2.trim() === "") {
                formIsValid = false;
                errors.Address2 = "Please enter address 2.";
            }

            this.setState({ errors: errors });
        }
        catch (err) {
            console.log("Error in " + this.displayName + ".handleValidation function", err);
            this.setState({ isSubmited: false })
        }
        return formIsValid;
    }


    getAccounts = async () => {

        try {
            var request = {};
            const requestParams = getRequestParams("GET", request);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'account/dropdownAccount/0', requestParams);
            const data = await response.json();
            this.setState({
                accountList: data.payload
            });
        } catch (err) {
            console.log("Error in " + this.displayName + ".getAccounts function", err);
        }
    }


    handleAccountChange = (selectedOption) => {
        this.setState({
            accountId: (selectedOption.selectedItem !== null ? selectedOption.selectedItem.id : 0),
        });

        if (selectedOption.selectedItem !== null && selectedOption.selectedItem.id > 0) {
            this.state.errors["Account"] = "";
        }
    }

    handleWarehouseSubmit = async (e) => {
        e.preventDefault();

        try {
            if (this.handleValidation()) {
                this.setState({ isSubmited: true });
                var newObject = {};

                //   newObject.Account = this.state.Account.trim();
                newObject.Name = this.state.Name.trim();
                newObject.phone = this.state.phone.trim();
                newObject.ContactPerson = this.state.ContactPerson.trim();
                newObject.State = this.state.State.trim();
                newObject.City = this.state.City.trim();
                newObject.Country = this.state.Country.trim();
                newObject.Address1 = this.state.Address1.trim();
                newObject.Address2 = this.state.Address2.trim();
                newObject.PostalCode = this.state.ZipCode;
                newObject.CreatedBy = this.state.sessionUser.id;
                newObject.UpdatedBy = this.state.sessionUser.id;
                // newObject.CreatedOn = formatDate(new Date(),'MM/dd/yyyy hh:mm');
                // newObject.UpdatedOn = formatDate(new Date(), 'MM/dd/yyyy hh:mm');

                const requestParams = getRequestParams('POST', newObject);
                const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'warehouse/' + this.state.id + '/warehouse', requestParams);
                const data = await response.json();

                if (data.isSuccess) {
                    Swal.fire({
                        icon: 'success',
                        title: 'Success',
                        text: data.message,
                    }).then((result) => {
                        if (result.isConfirmed) {
                            this.getWarehouseMaster();
                            // this.closeWarehouseModal();
                            window.location.href = "/warehouse/list"

                        }
                        this.setState({ isSubmited: false })
                    })
                }
                else {
                    toast.error(data.message, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        style: { width: "310px" },
                        hideProgressBar: false,
                        newestOnTop: false,
                        closeButton: false
                    });
                    this.setState({ isSubmited: false })
                }
            }
        }
        catch (err) {
            console.log("Error in " + this.displayName + ".handleWarehouseSubmit function", err);
            this.setState({ isSubmited: false })
        }
    }

    openWarehouseModel = () => {
        this.setState({
            warehouseModal: true,
            singelData: null
        })
    }

    editWarehouse(e, data) {

        e.preventDefault();
        if (data != null) {
            // history.push({
            // pathname: '/warehouse/new',
            //use for passing data 
            this.setState({
                warehouseModal: true,
                singelData: data,
                id: data.id,
                Account: data.account,
                Name: data.warehousename,
                phone: data.phone,
                ContactPerson: data.contactPerson,
                State: data.state,
                City: data.city,
                Country: data.country,
                ZipCode: data.postalCode,
                Address1: data.address1,
                Address2: data.address2,

                // sourceUrl: '/warehouse/list', sourceState: this.state
            });
        }
        // window.location.reload(false);
    }

    render() {
        const renderGridCell = (data) => {
            return <div>
                <a href="#" className="fas fa-pen" title="Edit" onClick={(e) => { this.editWarehouse(e, data.data) }} ></a>&nbsp;&nbsp;
            </div>;
        }
        return (
            <Fragment>
                <div className="section-body">
                    <div className='card bg-filter'>
                        <div className='card-body'>
                            <div className="section-header">
                                <h6 class="m-0">Warehouse</h6>
                                <div className="section-header-buttons">
                                    <button type="submit" className="btn btn-success" onClick={this.openWarehouseModel}>
                                        New
                                    </button>
                                  </div>
                            </div>                          
                        </div>
                    </div>
                    <div className='card'>
                    <div className='card-body'>
                            <DataGrid
                            dataSource={this.state.WarehouseSource}
                            //keyExpr="id"
                            selection={{ mode: 'single' }}
                            columnsAutoWi dth={false}
                            showColumnLines={true}
                            showRowLines={true}
                            showBorders={true}
                            rowAlternationEnabled={false}
                            allowColumnResizing={true}
                            columnResizingMode='widget'
                            onToolbarPreparing={this.onToolbarPreparing}>
                            <Column dataField="id" width={35} caption="" allowFiltering={false} allowSorting={false} cellRender={renderGridCell} />
                            {/* <Column dataField="account" caption="Account" allowSearch={true} allowFiltering={false} allowSorting={true} width={300} />                                     */}
                            <Column dataField="warehousename" caption="Warehouse" allowSearch={true} allowFiltering={false} allowSorting={true} width={150} />
                            <Column dataField="contactPerson" caption="Contact Person" allowSearch={true} allowFiltering={false} allowSorting={true} width={150} />
                            <Column dataField="phone" caption="Phone" allowSearch={true} allowFiltering={false} allowSorting={true} width={150} />
                            <Column dataField="address" caption="Address" allowSearch={true} allowFiltering={false} allowSorting={true} width={300} />
                            <SortByGroupSummaryInfo summaryItem="count" />
                            <GroupPanel visible={false} />
                            {/* <Template name="toolbarTemplate" /> */}
                            <HeaderFilter visible={true} />
                            <SearchPanel visible={true} highlightCaseSensitive={true} width={240} placeholder="Search..." />
                            <Grouping autoExpandAll={true} />
                            <Paging defaultPageSize={20} />
                            <Pager showPageSizeSelector={true} allowedPageSizes={[10, 20, 50]} showInfo={true} visible={true} />
                            <Export enabled={true} allowExportSelectedData={false} fileName="WareHouse" />
                        </DataGrid>
                            </div>
                    </div>
                </div>

                <Modal ariaHideApp={false}
                    isOpen={this.state.warehouseModal}
                    style={customStyles}
                    className={"react-modal"}
                    //onAfterOpen={this.handleAssignDataToState}
                    onRequestClose={this.closeWarehouseModal}
                    shouldCloseOnOverlayClick={false}
                    contentLabel="Example Modal">
                    <form method="post" onSubmit={this.handleWarehouseSubmit} style={this.state.isSubmited ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                        <div className="modal-header">
                            <h6 className="modal-title">{this.state.id > 0 ? "Update Warehouse" : "New Warehouse"}</h6>
                            {/* <h6 className="modal-title">Add Warehouse </h6> */}
                        </div>
                        <div className="modal-body">
                            <div className='row'>
                                {/* <div className="col-lg-3">
                                    <div className="form-group">
                                        <label htmlFor="">Account </label>
                                        <SelectBox
                                            dataSource={this.state.accountList}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Selet Account"
                                            name="id"
                                            defaultValue={0}
                                            value={this.state.accountId}
                                            displayExpr='name'
                                            valueExpr='id'
                                            onSelectionChanged={this.handleAccountChange}
                                            showClearButton={true}
                                            disabled={this.state.id > 0}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["Account"].length > 0 && <span className="error invalid-feedback">{this.state.errors["Account"]}</span>}
                                    </div>
                                </div> */}

                                <div className="col-lg-3">
                                    <div className="form-group">
                                        <label htmlFor="">Name <span className="text-danger">*</span></label>
                                        <input type="text" className="form-control form-control-sm" name="Name" value={this.state.Name} placeholder="Enter warehouse name" 
                                        disabled={this.state.id > 0}  maxLength="50" title="Name" onChange={this.handleInputChange} />
                                        {this.state.errors["Name"].length > 0 && <span className="error invalid-feedback">{this.state.errors["Name"]}</span>}
                                    </div>
                                </div>

                                <div className="col-lg-3 ">
                                    <div className="form-group">
                                        <label>Phone <span className="text-danger">*</span></label>
                                        <PatternFormat className="form-control form-control-sm" format="### #### ###" name="phone"
                                            placeholder="Enter phone" value={this.state.phone}
                                            title="Phone" maxLength="50" onChange={this.handleInputChange} />
                                        {this.state.errors["phone"].length > 0 && <span className="error invalid-feedback">{this.state.errors["phone"]}</span>}
                                    </div>
                                </div>

                                <div className="col-lg-3 ">
                                    <div className="form-group">
                                        <label htmlFor="">Contact Person <span className="text-danger">*</span></label>
                                        <input type="text" className="form-control form-control-sm" name="ContactPerson" value={this.state.ContactPerson}
                                            placeholder="Enter contact person name" maxLength="50" title="ContactPerson" onChange={this.handleInputChange} />
                                        {this.state.errors["ContactPerson"].length > 0 && <span className="error invalid-feedback">{this.state.errors["ContactPerson"]}</span>}
                                    </div>
                                </div>

                                <div className="col-lg-3">
                                    <div className="form-group">
                                        <label htmlFor="">State <span className="text-danger">*</span></label>
                                        <input type="text" className="form-control form-control-sm" name="State" value={this.state.State}
                                            placeholder="Enter state" maxLength="50" title="State" onChange={this.handleInputChange} />
                                        {this.state.errors["State"].length > 0 && <span className="error invalid-feedback">{this.state.errors["State"]}</span>}
                                    </div>
                                </div>

                                <div className="col-lg-3 ">
                                    <div className="form-group">
                                        <label htmlFor="">City <span className="text-danger">*</span></label>
                                        <input type="text" className="form-control form-control-sm" name="City" value={this.state.City}
                                            placeholder="Enter city" maxLength="50" title="City" onChange={this.handleInputChange} />
                                        {this.state.errors["City"].length > 0 && <span className="error invalid-feedback">{this.state.errors["City"]}</span>}
                                    </div>
                                </div>

                                <div className="col-lg-3 ">
                                    <div className="form-group">
                                        <label htmlFor="">Country <span className="text-danger">*</span></label>
                                        <input type="text" className="form-control form-control-sm" name="Country" value={this.state.Country}
                                            placeholder="Enter country" maxLength="50" title="Country" onChange={this.handleInputChange} />
                                        {this.state.errors["Country"].length > 0 && <span className="error invalid-feedback">{this.state.errors["Country"]}</span>}
                                    </div>
                                </div>

                                <div className="col-lg-3 ">
                                    <div className="form-group">
                                        <label htmlFor="">Postal Code <span className="text-danger">*</span></label>
                                        <PatternFormat className="form-control form-control-sm" format="#######" name="ZipCode"
                                            placeholder="Enter postal code" value={this.state.ZipCode}
                                            title="Postal Code" maxLength="50" onChange={this.handleInputChange} />
                                        {this.state.errors["ZipCode"].length > 0 && <span className="error invalid-feedback">{this.state.errors["ZipCode"]}</span>}
                                    </div>
                                </div>


                                <div className="col-lg-6 ">
                                    <div className="form-group">
                                        <label htmlFor="">Address 1 <span className="text-danger">*</span></label>
                                        <textarea name="Address1" className="form-control form-control-sm" value={this.state.Address1}
                                            placeholder="Enter address 1" maxLength="200" onChange={this.handleInputChange} ></textarea>
                                        {this.state.errors["Address1"].length > 0 && <span className="error invalid-feedback">{this.state.errors["Address1"]}</span>}
                                    </div>
                                </div>

                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <label htmlFor="">Address 2 <span className="text-danger">*</span></label>
                                        <textarea name="Address2" className="form-control form-control-sm" value={this.state.Address2}
                                            placeholder="Enter address 2" maxLength="200" onChange={this.handleInputChange} ></textarea>
                                        {this.state.errors["Address2"].length > 0 && <span className="error invalid-feedback">{this.state.errors["Address2"]}</span>}
                                    </div>
                                </div>
                            </div>                            
                        </div>
                        <div className="modal-footer">
                                <button type="submit" className="btn btn-success" onClick={this.handleWarehouseSubmit} >
                                    {this.state.isSubmited && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                    {!this.state.isSubmited && <span title="Submit">Submit</span>}
                                </button>
                                <button type="submit" className="btn btn-outline-secondary" onClick={this.closeWarehouseModal}>
                                    <span title="Cancel"><i class="fas fa-times"></i> Cancel</span>
                                </button>
                            </div>
                    </form>
                </Modal>

                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={position}
                    visible={this.state.loadPanelVisible}
                    showIndicator={true}
                    shading={true}
                    showPane={true}
                    hideOnOutsideClick={false}
                />
            </Fragment >
        )
    };
}