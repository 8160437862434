import React, { Fragment, Component } from 'react';
import DataGrid, {
    Column,
    Paging,
    SortByGroupSummaryInfo,
    Editing, Lookup,
    RequiredRule,
    PatternRule,
    StringLengthRule,
} from 'devextreme-react/data-grid'; //npm i devextreme-react
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import Modal from 'react-modal'; //npm install --save react-modal
import { getRequestParams, formatDate } from '../../helpers/globalfunctions.js';
import { alphanumericValidation, floatValidation } from '../../helpers/globalvalidations.js';
import DateBox from 'devextreme-react/date-box';
import { precisionFormats, currencySymbolFormats } from '../../helpers/fixcodes.js';
import { LoadPanel } from 'devextreme-react/load-panel';
import { SelectBox } from 'devextreme-react/select-box';
import Swal from 'sweetalert2'; //npm i sweetalert2 --save
import axios from 'axios'; //npm install--save axios
const customStyles = {
    content: {
        width: '50%',
    }
}
const position = { of: '#historydiv' };

export class PurchseReturnComponent extends Component {
    static displayName = PurchseReturnComponent.name;
    constructor(props) {
        super(props);

        this.grdSalesReturnAttributesRef = React.createRef();
        this.state = {
            isSubmited: false,
            loadPanelVisible: false,
            documentModelIsOpen: false,
            accountList: null,
            paymentTermList: null,
            itemList: [],
            itemDetails: [],
            accountTaxList: [],
            hsnsacList: null,
            itemReturnStatus: null,
            billList: null,
            id: 0,
            accountId: this.props.location.state === undefined ? 0 : this.props.location.state.accountId,
            receivedDate: this.props.location.state === undefined ? new Date() : this.props.location.state.dispatchedDate,
            invoiceId: 0,
            invoiceNo: this.props.location.state === undefined ? "" : this.props.location.state.invoiceNo,
            comments: "",
            awbNo: "",
            sessionUser: JSON.parse(sessionStorage.getItem("sessionUser")),
            purchasereturnId: this.props.location.state === undefined ? 0 : this.props.location.state.purchasereturnId,
            isNewItem: false,
            installerName: "",
            purchasereturnsource: null,
            returnStatusId: this.props.location.state === undefined ? 0 : this.props.location.state.returnStatusId,
            returnStatusName: this.props.location.state === undefined ? "" : this.props.location.state.returnStatus,
            rmaNo: "",
            imageList: [],
            pdfList: [],
            excelList: [],
            otherList: [],
            documentSelectedFile: [],
            purchaseReturnDocumentOrderId: 0,
            resultMeassage: "",
            progressInfos: [],
            targetFile: [],
            errors: {
                accountId: 0,
                invoiceNo: "",
                comments: "",
                awbNo: "",
                installerName: "",
                itemDetailsGrid: ""
            }
        }

        this.assginData = this.assginData.bind(this);
        this.handlePurchaseReturnSubmit = this.handlePurchaseReturnSubmit.bind(this);
    }

    async componentDidMount() {
        this.setState({
            loadPanelVisible: true
        });
        await this.getItemReturneStatus();
        await this.getAccounts();
        //await this.getItemMaster();
        //await this.getBill();

        if (this.state.purchasereturnId !== 0) {

            await this.getPurchaseReturnMaster();
        }
        this.setState({
            loadPanelVisible: false
        });
    }

    getItemReturneStatus = async () => {
        try {
            var request = {};
            const requestParams = getRequestParams("GET", request);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'salesorder/returnedItemStatus', requestParams);
            const data = await response.json();
            this.setState({
                itemReturnStatus: data.payload
            });
        } catch (err) {
            console.log("Error in " + this.displayName + ".getItemReturneStatus function", err);
        }
    }

    getAccounts = async () => {
        try {
            var request = {};
            const requestParams = getRequestParams("GET", request);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'account/dropdownAccount/3', requestParams);
            const data = await response.json();
            this.setState({
                accountList: data.payload
            });
        } catch (err) {
            console.log("Error in " + this.displayName + ".getAccounts function", err);
        }
    }


    getPaymentTerms = async () => {
        try {
            var request = {};
            const requestParams = getRequestParams("GET", request);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'paymentTermType/dropdownType', requestParams);
            const data = await response.json();
            this.setState({
                paymentTermList: data.payload
            });
        } catch (err) {
            console.log("Error in " + this.displayName + ".getPaymentTerms function", err);
        }
    }

    getItemMaster = async () => {
        try {
            
            var invoiceId = this.state.invoiceId;
            const requestParams = getRequestParams("GET", null);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'billing/getBillDetails/' + invoiceId, requestParams);
            const data = await response.json();
            
            this.setState({
                itemList: data.payload != null ? data.payload : [],
            });
        } catch (err) {
            console.log("Error in " + this.displayName + ".getItemMaster function", err);
        }
    }
    getHSNTax = async (id) => {
        try {

            var request = {};
            const requestParams = getRequestParams("GET", request);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'hsntax/HSNCodeTax/' + id, requestParams);
            const data = await response.json();
            this.setState({
                accountTaxList: data.payload != null ? data.payload : [],
            });
            if (data.payload == null) {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: "Please set tax for the item",
                }).then((result) => {
                    if (result.isConfirmed) {
                        if (data.isSuccess) {
                            this.setState({ itemDetails: null })
                        }
                    }
                    this.setState({ isSubmited: false })
                });
            }

        } catch (err) {
            console.log("Error in " + this.displayName + ".getContractList function", err);
        }
    }

    // getBill = async () => {
    //    
    //     try {
    //         var request = {};
    //         const requestParams = getRequestParams("GET", request);
    //         const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'billing/billingDropDown', requestParams);
    //         const data = await response.json();
    //         this.setState({
    //             billList: data.payload != null ? data.payload : []
    //         });
    //     } catch (err) {
    //         console.log("Error in " + this.displayName + ".getBill function", err);
    //     }
    // }
    getBill = async () => {
        try {
            var request = {};
            const requestParams = getRequestParams("GET", request);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'billing/billingDropDownByCustomer/' + this.state.accountId, requestParams);
            const data = await response.json();
            
            this.setState({
                billList: data.payload != null ? data.payload : []
            });
        } catch (err) {
            console.log("Error in " + this.displayName + ".getBill function", err);
        }
    }

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        let errors = this.state.errors;
        switch (name) {
            case 'awbNo':
                if (alphanumericValidation.test(event.target.value))
                    this.setState({ awbNo: event.target.value });
                break;
            case 'comments':
                if (alphanumericValidation.test(event.target.value))
                    this.setState({ comments: event.target.value });
                break;
            case 'installerName':
                if (alphanumericValidation.test(event.target.value))
                    this.setState({ installerName: event.target.value });
                break;
            default:
                this.setState({ [name]: value });
                break;
        }
        errors[name] = "";
        //this.state.errors[name] = "";
        // this.setState({
        //     errors: {
        //         [name]: ""
        //     }
        // });
        this.setState({
            errors: errors
        });
    }

    handleAccountChange = (selectedOption) => {
       
        this.setState({
            accountId: (selectedOption.selectedItem !== null ? selectedOption.selectedItem.id : 0),
        });
        let errors = this.state.errors;
        if (selectedOption.selectedItem !== null && selectedOption.selectedItem.id > 0) {
            //this.state.errors["accountId"] = "";
            errors.accountId = "";
            this.setState({
                errors: errors
            });
            setTimeout(() => this.getBill(), 100);
        }

    }
    handleBillChange = (selectedOption) => {
        this.setState({
            invoiceId: (selectedOption.selectedItem !== null ? selectedOption.selectedItem.id : 0),
            invoiceNo: (selectedOption.selectedItem !== null ? selectedOption.selectedItem.name : ""),
        });

        let errors = this.state.errors;
        if (selectedOption.selectedItem !== null && selectedOption.selectedItem.name !== "") {
            //this.state.errors["invoiceNo"] = "";
            errors.invoiceNo = "";
            this.setState({
                errors: errors
            });
        }
        if (selectedOption.selectedItem !== null && selectedOption.selectedItem.id !== 0) {
            setTimeout(() => this.getItemMaster(), 100);
        }
        else {
            this.setState({ itemDetails: [] });
        }
    }
    handleStatusChanges = (selectedOption) => {
        this.setState({
            returnStatusId: (selectedOption.selectedItem !== null ? selectedOption.selectedItem.id : 0),
            returnStatusName: (selectedOption.selectedItem !== null ? selectedOption.selectedItem.name : ""),
        });

    }
    handlereceivedDateChanged = (date) => {
        this.setState({ receivedDate: date.value });
    }
    handleValidation = () => {
        let formIsValid = true;
        try {
            let errors = this.state.errors;
            errors.accountId = 0;
            errors.invoiceNo = 0;
            errors.comments = "";
            errors.awbNo = "";
            errors.installerName = "";

            if (this.state.accountId === 0) {
                formIsValid = false;
                errors.accountId = "Please select vendor.";
            }
            if (this.state.comments.trim() === "") {
                formIsValid = false;
                errors.comments = "Please enter comment.";
            }
            if (this.state.invoiceNo.trim() === "") {
                formIsValid = false;
                errors.invoiceNo = "Please select bill number.";
            }
            if (this.state.awbNo.trim() === "") {
                formIsValid = false;
                errors.awbNo = "Please enter awb number.";
            }
            if (this.state.installerName.trim() === "") {
                formIsValid = false;
                errors.installerName = "Please enter installer name.";
            }
            if (this.state.itemDetails.length === 0) {
                formIsValid = false;
                errors.itemDetailsGrid = "Please add item(s).";
            }
            this.setState({ errors: errors });
        }
        catch (err) {
            console.log("Error in " + this.displayName + ".handleValidation function", err);
            this.setState({ isSubmited: false })
        }
        return formIsValid;
    }

    isGuid = (stringToTest) => {
        if (stringToTest[0] === "{") {
            stringToTest = stringToTest.substring(1, stringToTest.length - 1);
        }
        var regexGuid = /^(\{){0,1}[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}(\}){0,1}$/gi;
        return regexGuid.test(stringToTest);
    }

    handlePurchaseReturnSubmit = async (e) => {
        e.preventDefault();
        try {
            if (this.handleValidation()) {
                this.setState({
                    isSubmited: true
                })

                const dataSource = this.refs.grdSalesReturnAttributesRef.instance.getDataSource();
                var attributeArray = dataSource._items;

                var _data = this.state.itemList
                var totalAmount = 0;
                var orderItemsList = [];

                var totalItemPrice = 0.00;
                 var taxAmount = 0.00;
                   var totalAmount = 0.00; var finalTotalAmount = 0.00;
                 attributeArray.forEach((element, index) => {
                    
                    var orderItemRequest = {};
                    orderItemRequest.Id = this.isGuid(element.id) === true ? 0 : element.id;
                    orderItemRequest.StockItemId = parseInt(element.stockItemId);
                    orderItemRequest.SerialNo = element.serialNo;
                    orderItemRequest.Remarks = element.remarks;
                    var result = _data.filter((e) => e.id == element.stockItemId);
                    result.forEach(
                        (i, index) => {
                            
                            orderItemRequest.Price = i.unitPrice;
                            orderItemRequest.HSNSACCode = i.hsnsacCode;
                            totalItemPrice = parseInt(1) * i.unitPrice ;
                            totalAmount = totalItemPrice ;
                            finalTotalAmount += totalItemPrice
                            orderItemRequest.totalAmount = totalAmount;
                            orderItemRequest.itemName = i.name;
                        }
                    )
                    orderItemsList.push(orderItemRequest);
                });
                
                var totalTax = 0.00;
                if (totalItemPrice > 0) {
                    if (this.state.accountTaxList.length > 0) {
                        this.state.accountTaxList.map((taxitem, i) => {
                            var singleTax = parseFloat(((finalTotalAmount * taxitem.percentage) / 100), 2);
                            totalTax = totalTax + singleTax;
                        });
                    }
                }

                
                var request = {};
                request.VendorId = this.state.accountId;
                request.InstallerName = this.state.installerName;
                request.InvoiceNo = this.state.invoiceNo;
                request.invoiceId = this.state.invoiceId;
                request.DispatchedOn = formatDate(this.state.receivedDate, "MM/dd/yyyy hh:mm");
                request.Comments = this.state.comments;
                request.ReturnStatus = this.state.returnStatusId === 0 ? parseInt(1) : this.state.returnStatusId;
                request.ReturnType = 2;
                request.AWBNo = this.state.awbNo;
                request.UserId = this.state.sessionUser.id;
                request.itemReturnDetails = orderItemsList;
                request.ReturnStatusName = this.state.returnStatusName;
                request.TotalQty = orderItemsList.length;
                request.GrossAmount = parseFloat(totalTax) + parseFloat(finalTotalAmount);
                request.TotalAmount = parseFloat(finalTotalAmount);
                request.TaxAmount = parseFloat(totalTax);;
                request.RMANo = this.state.rmaNo;
                
                const requestParams = getRequestParams('POST', request);
                const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'purchaseReturn/' + this.state.id + '/purchaseReturn', requestParams);
                const data = await response.json();
                if (data.isSuccess) {
                    this.setState({ purchaseReturnDocumentOrderId: data.payload, resultMeassage: data.message });
                    if (this.state.documentSelectedFile.size !== undefined || this.state.documentSelectedFile.length > 0) {
                        if (this.state.id === 0) {
                            this.documentUpload();
                        }
                    }
                    else {
                        Swal.fire({
                            icon: 'success',
                            title: 'Success',
                            text: data.message,
                        }).then((result) => {
                            if (result.isConfirmed) {
                                this.handleClearControls();
                                window.location.href = "/purchasereturn/list";
                            }
                            this.setState({ isSubmited: false })
                        })
                    }
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: data.message,
                    }).then((result) => {
                        if (result.isConfirmed) {
                        }
                        this.setState({ isSubmited: false })
                    })
                    this.setState({ isSubmited: false })
                }

                this.setState({
                    isSubmited: false
                })
            }
        } catch (err) {
            console.log("Error in " + this.displayName + ".handlePurchaseReturnSubmit function", err);
            this.setState({ isSubmited: false })
        }
    }

    handleClearControls = () => {
        this.setState({
            isSubmited: false,
            loadPanelVisible: false,
            accountList: [],
            paymentTermList: [],
            itemList: [],
            itemDetails: [],
            hsnsacList: [],
            itemReturnStatus: [],
            id: 0,
            accountId: 0,
            orderNo: "",
            receivedDate: new Date(),
            shippingDate: new Date(),
            paymentTermId: 0,
            shippingCharges: 0.00,
            discount: 0.00,
            otherCharges: 0.00,
            grossAmount: 0.00,
            remarks: "",
            termsAndConditions: "",
            accountTaxList: [],
            sessionUser: JSON.parse(sessionStorage.getItem("sessionUser")),
            purchasereturnId: this.props.location.state === undefined ? "" : this.props.location.state.purchasereturnId,
            isNewItem: false,
            errors: {
                accountId: 0,
                orderNo: "",
                receivedDate: new Date(),
                shippingDate: new Date(),
                paymentTermId: 0,
                shippingCharges: 0.00,
                discount: 0.00,
                otherCharges: 0.00,
                grossAmount: 0.00,
                remarks: "",
                termsAndConditions: "",
            }
        });
    }
    getPurchaseReturnMaster = async () => {
        this.setState({
            loadPanelVisible: true
        });
        try {
            var request = {};
            request.DispatchedOn = this.state.receivedDate === "" ? "" : formatDate(this.state.receivedDate, "MM/dd/yyyy");
            request.ReturnStatusId = (this.state.returnStatusId);
            request.ItemReturnId = this.state.purchasereturnId;
            request.InvoiceNo = this.state.invoiceNo === null ? "" : this.state.invoiceNo
            const requestParams = getRequestParams("POST", request);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'purchaseReturn/purchaseReturnDetails', requestParams);
            const data = await response.json();
            this.setState({
                purchasereturnsource: data.payload,
                loadPanelVisible: false
            });
            this.assginData(data.payload);
        } catch (err) {
            console.log("Error in " + this.displayName + ".getPurchaseReturnMaster function", err);
            this.setState({
                loadPanelVisible: false
            });
            if (this.state.salesorderId > 0) {
                setTimeout(() => this.assginData(), 500);
            }
        }
    }
    assginData = (purchasereturnData) => {
        if (purchasereturnData !== null && purchasereturnData !== undefined) {
            var purchasereturn = purchasereturnData.itemReturn;
            var itemReturnDetails = purchasereturnData.itemReturnDetails;
            this.setState({
                id: purchasereturn.id,
                accountId: purchasereturn.vendorId,
                invoiceId: purchasereturn.invoiceId,
                invoiceNo: purchasereturn.invoiceNo,
                awbNo: purchasereturn.awbNo,
                receivedOn: formatDate(purchasereturn.dispatchedOn, "MM/dd/yyyy hh:mm"),
                comments: purchasereturn.comments,
                installerName: purchasereturn.installerName,
                returnStatusId: purchasereturn.returnStatusId,
                rmaNo: purchasereturn.rmaNo,
                itemId: itemReturnDetails.stockItemId,
                serial: itemReturnDetails.serialNo,
                remark: itemReturnDetails.remarks,
                itemDetails: itemReturnDetails
            });
            setTimeout(() => this.getBill(), 100);
            setTimeout(() => this.getItemMaster(), 100);
        }
    }
    onRowInserting = async (e) => {
        this.setState({ isNewItem: true });
    }
    onRowUpdating = async (e) => {
        this.setState({ isNewItem: false });
    }
    onRowUpdated = async (e) => {
        this.setState({ isNewItem: false });
    }
    handleCancel = () => {
        window.location.href = "/purchasereturn/list";
    }

    openDocumentModel = () => {
        this.setState({
            documentModelIsOpen: true
        });
        this.getDocuments();
    }
    closeDocumentModel = () => {
        this.setState({
            documentModelIsOpen: false
        });
    }
    getDocuments = async () => {
        try {
            this.setState({
                loadPanelVisible: true
            });
            const requestParams = getRequestParams('GET', {});
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'file/' + parseInt(5) + '/' + this.state.id + '/documents', requestParams);
            const data = await response.json();
            var imageListObj = [];
            var pdfListObj = [];
            var excelListObj = [];
            var otherListObj = [];
            if (data.documents != null && data.documents.length > 0) {
                for (var i = 0; i < data.documents.length; i++) {
                    if (data.documents[i].fileGuid !== '00000000-0000-0000-0000-000000000000') {
                        var img = {};
                        const requestParamsImage = getRequestParams('GET', '');
                        const responseImage = await fetch(process.env.REACT_APP_API_BASE_URL + 'file/download/' + data.documents[i].fileGuid, requestParamsImage);
                        const dataImage = await responseImage.json();

                        if (dataImage.fileExtension.toUpperCase() === '.PDF') {
                            img.src = 'data:' + dataImage.mimeType + ';base64,' + dataImage.fileContent;
                            img.fileGuid = data.documents[i].fileGuid;
                            img.fileName = dataImage.fileName;
                            pdfListObj.push(img);
                        } else if (dataImage.fileExtension.toUpperCase() === '.XLXS' || dataImage.fileExtension.toUpperCase() === '.CSV' || dataImage.fileExtension.toUpperCase() === '.XLS') {
                            img.src = 'data:' + dataImage.mimeType + ';base64,' + dataImage.fileContent;
                            img.fileGuid = data.documents[i].fileGuid;
                            img.fileName = dataImage.fileName;
                            excelListObj.push(img);
                        } else if (dataImage.fileExtension.toUpperCase() === '.JPG' || dataImage.fileExtension.toUpperCase() === '.PNG' || dataImage.fileExtension.toUpperCase() === '.JPEG') {
                            img.src = 'data:' + dataImage.mimeType + ';base64,' + dataImage.fileContent;
                            img.fileGuid = data.documents[i].fileGuid;
                            imageListObj.push(img);
                        } else {
                            img.src = 'data:' + dataImage.mimeType + ';base64,' + dataImage.fileContent;
                            img.fileGuid = data.documents[i].fileGuid;
                            img.fileName = dataImage.fileName;
                            otherListObj.push(img);
                        }
                    }
                }
            }

            this.setState({
                loadPanelVisible: false,
                imageList: imageListObj,
                pdfList: pdfListObj,
                excelList: excelListObj,
                otherList: otherListObj
            });

        } catch (e) {
            console.log(e);
        }
    }

    handleFileInputChange = async (e) => {
        let images = e.target.files;
        var i;
        for (i = 0; i < e.target.files.length; i++) {
            this.state.targetFile.push(images[i]);
        }
        this.setState({
            documentSelectedFile: this.state.targetFile

        })
    }
    resetFileInput = (e) => {
        var data = this.state.documentSelectedFile.filter(item => item.name === e.target.value);
        var _data = this.state.documentSelectedFile.filter(item => item.name !== data[0].name);
        //var data1 = this.state.documentSelectedFile.filter(item => item.name === data[0].name)
        this.setState({
            documentSelectedFile: _data,
            targetFile: _data
        }, () => 500)
        const arrLength = _data.length;
        console.log(arrLength - 1);
        if (_data.length === 0) {
            this.removeAll();
        }
        else {
            document.getElementById("file").value = null;
        }
    };
    removeAll = () => {
        document.getElementById("file").value = null;
    }
    documentUpload = async () => {
        for (let i = 0; i < this.state.documentSelectedFile.length; i++) {
            const url = process.env.REACT_APP_API_BASE_URL + 'file/upload';
            const formData = new FormData();
            //console.log(this.state.documentSelectedFile);
            formData.append('body', this.state.documentSelectedFile[i]);
            const config = {
                headers: {
                    'content-type': 'multipart/form-data',
                    'UserId': this.state.sessionUser.id,
                    'Authorization': 'Bearer ' + sessionStorage.getItem('session_token'),
                },
            };
            this.setState({
                loadPanelVisible: true
            });
            axios.post(url, formData, config).then((response) => {
                var resp = response.data;
                if (resp != null) {
                    this.setState({
                        fileGuid: resp.fileGuid
                    })
                    this.saveDocument(resp)
                }
            });
            this.setState({
                loadPanelVisible: false
            });
        }

    }
    saveDocument = async (data) => {
        this.setState({ isSubmited: true })
        if (data !== null) {
            this.setState({
                fileGuid: data.fileGuid
            });
            try {
                var isSuccess = false;
                var resultMessage = '';
                //for (var i = 0; i < this.state.uniqueId.length; i++)
                {
                    var request = {};
                    request.FileGuid = data.fileGuid;
                    request.FileRecordType = parseInt(5);
                    request.UserId = Number(this.state.sessionUser.id);
                    request.DocumentType = parseInt(5);
                    request.Id = this.state.purchaseReturnDocumentOrderId;
                    const requestVehicleParams = getRequestParams('POST', request);
                    const responseVehicle = await fetch(process.env.REACT_APP_API_BASE_URL + 'file/save-documents', requestVehicleParams);
                    const dataVehicle = await responseVehicle.json();
                    isSuccess = dataVehicle.isSuccess;
                    resultMessage = dataVehicle.message;
                }
                this.setState({
                    isSubmited: false
                });
                if (isSuccess) {
                    Swal.fire({
                        icon: isSuccess ? 'success' : 'error',
                        title: isSuccess ? 'success' : 'Oops...',
                        text: this.state.resultMeassage,
                    }).then((result) => {
                        if (isSuccess) {
                            window.location.href = "/purchasereturn/list"
                            //  this.closeModel();
                            //this.getDocuments();
                        }
                    });
                } else {
                    this.onfailure('Failed to upload image.', resultMessage);
                }
            } catch (e) {
                //
            }

        }
    }
    setCellValue = (rowData, value) => {
        rowData.stockItemId = value.id;
        // rowData.hsnsacCode = value.hsnCode;
        rowData.unitPrice = value.unitPrice;
        this.getHSNTax(value.hsnId);
    }
    onEditorPreparing = (e) => {
        if (e.parentType === "dataRow" && e.dataField === "stockItemId") {

            e.editorOptions.onValueChanged = (ev) => {
                let selectedItem = ev.component.option("selectedItem");
                e.setValue(selectedItem);
            };
        }
    }
    calculatePriceChange = (newData, value, currentRowData) => {
        newData.price = parseFloat(value).toFixed(precisionFormats[2]);
        var totalItemPrice = 0.00;
        totalItemPrice = parseInt(1) !== undefined ? parseFloat(parseInt(1), 3) * value : 0;
        var totalTax = 0.00;
        if (totalItemPrice > 0) {
            if (this.state.accountTaxList.length > 0) {
                this.state.accountTaxList.map((taxitem, i) => {
                    var singleTax = parseFloat(((totalItemPrice * taxitem.percentage) / 100), 2);
                    totalTax = totalTax + singleTax;
                });
            }
        }
        newData.taxAmount = parseFloat(totalTax, 2).toFixed(precisionFormats[2]);
        newData.totalAmount = (parseFloat(totalTax, 2) + parseFloat(totalItemPrice, 3)).toFixed(precisionFormats[2]);
        // this.setState ({ grossAmount : newData.totalAmount + newData.totalAmount});
    }

    render() {
        const renderGridNumberCell = (data) => {
            return <div><small><i class={currencySymbolFormats.currencyFormat}></i></small>&nbsp;&nbsp;{parseFloat(data.value !== undefined ? data.value : 0.00).toFixed(precisionFormats[2])}</div>;

        }
        return (
            <Fragment>
                <div className="section-body">
                    <div className='card bg-filter'>
                        <div className='card-body'>
                            <div className="section-header">
                                <h6 className='m-0'>{this.state.purchasereturnId > 0 ? "Purchase Return#" + this.state.rmaNo : "New Purchase Return"} </h6>
                                <div className="section-header-buttons">
                                    <button type="submit" className="btn btn-success" onClick={this.handlePurchaseReturnSubmit} >
                                        {this.state.isSubmited && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                        {!this.state.isSubmited && <span title="Submit">Submit</span>}
                                    </button>&nbsp;&nbsp;
                                    {/* <button type="submit" className="btn btn-success">
                                    <span title="Save and Print"><i className="fas fa-print"></i> Save and Print</span>
                                </button>&nbsp;&nbsp; */}
                                    <button type="submit" className="btn btn-outline-secondary" onClick={this.handleCancel}>
                                        <span title="Cancel">Cancel</span>
                                    </button>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className='card mb-2'>
                        <div className='card-body'>
                            <div className='sub-title-color font-weight-bold'>Bill Info</div>
                            <div className='row'>
                            <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <label htmlFor="">Vendor <span className="text-danger">*</span></label>
                                        <SelectBox
                                            dataSource={this.state.accountList}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select vendor"
                                            name="id"
                                            defaultValue={0}
                                            value={this.state.accountId}
                                            displayExpr='name'
                                            valueExpr='id'
                                            onSelectionChanged={this.handleAccountChange}
                                            showClearButton={true}
                                            disabled={this.state.purchasereturnId > 0}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["accountId"].length > 0 && <span className="error invalid-feedback">{this.state.errors["accountId"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-3 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <label htmlFor="">Bill# <span className="text-danger">*</span></label>
                                        <SelectBox
                                            dataSource={this.state.billList}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select bill number"
                                            name="name"
                                            defaultValue={0}
                                            value={this.state.invoiceNo}
                                            displayExpr='name'
                                            valueExpr='name'
                                            onSelectionChanged={this.handleBillChange}
                                            showClearButton={true}
                                            disabled={this.state.purchasereturnId > 0}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["invoiceNo"].length > 0 && <span className="error invalid-feedback">{this.state.errors["invoiceNo"]}</span>}
                                    </div>
                                </div>
                                

                                {
                                    this.state.purchasereturnId !== 0 ?

                                        <div className="col-lg-2 col-md-3 col-sm-12 col-xs-12">
                                            <div className="form-group">
                                                <label htmlFor="">Item Status</label>
                                                <SelectBox
                                                    dataSource={this.state.itemReturnStatus}
                                                    className="basic-multi-select"
                                                    classNamePrefix="select"
                                                    placeholder="Select Invoice"
                                                    name="id"
                                                    defaultValue={0}
                                                    value={this.state.returnStatusId}
                                                    displayExpr='name'
                                                    valueExpr='id'
                                                    onSelectionChanged={this.handleStatusChanges}
                                                    showClearButton={true}
                                                    disabled={this.state.purchasereturnId > 0}
                                                    searchEnabled={true}
                                                />
                                            </div>
                                        </div> : null
                                }
                                <div className="col-lg-2 col-md-3 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <label htmlFor="">AWB# <span className="text-danger">*</span></label>
                                        <input type="text" className="form-control form-control-sm" name="awbNo" placeholder="Enter awb number" value={this.state.awbNo}
                                             disabled={this.state.purchasereturnId > 0} title="AWB#" maxLength="20" onChange={this.handleInputChange} />
                                        {this.state.errors["awbNo"].length > 0 && <span className="error invalid-feedback">{this.state.errors["awbNo"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-3 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <label htmlFor="">Dispatched Date</label>
                                        <DateBox type="date"
                                            className="replay-date-time"
                                            name="receivedDate"
                                            placeholder="dd/MM/yyyy"
                                            displayFormat="dd/MM/yyyy"
                                            showAnalogClock={true}
                                            useMaskBehavior={true}
                                            defaultValue={this.state.receivedDate}
                                            value={this.state.receivedDate}
                                            onValueChanged={this.handlereceivedDateChanged}
                                            width="100%"
                                            disabled={this.state.purchasereturnId > 0}
                                            acceptCustomValue={false}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-3 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <label htmlFor="">Installer Name <span className="text-danger">*</span></label>
                                        <input type="text" className="form-control form-control-sm" name="installerName" placeholder="Enter installer name" disabled={this.state.purchasereturnId > 0} value={this.state.installerName}
                                             title="Installer Name" maxLength="20" onChange={this.handleInputChange} />
                                        {this.state.errors["installerName"].length > 0 && <span className="error invalid-feedback">{this.state.errors["installerName"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <label htmlFor="">Comment <span className="text-danger">*</span></label>
                                        <input type="text" className="form-control form-control-sm" name="comments" placeholder="Enter comment" value={this.state.comments}
                                            disabled={this.state.purchasereturnId > 0}  title="Comments" maxLength="150" onChange={this.handleInputChange} />
                                        {this.state.errors["comments"].length > 0 && <span className="error invalid-feedback">{this.state.errors["comments"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-3 col-sm-12 col-xs-12" style={{ display: this.state.purchasereturnId > 0 ? 'block' : 'none' }}>
                                    <div className="form-group">
                                        <label htmlFor="">RMA# <span className="text-danger">*</span></label>
                                        <input type="text" className="form-control form-control-sm" name="rmaNo" placeholder="Enter rma number" disabled={this.state.purchasereturnId > 0} value={this.state.rmaNo}
                                            title="RMA#" maxLength="20" onChange={this.handleInputChange} />

                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-3 col-sm-12 col-xs-12" style={{ display: this.state.purchasereturnId > 0 ? 'block' : 'none', marginTop: 30 + 'px', color: '#16c0e7' }}>
                                    <span className="sub-title-color font-weight-bold" title="View Document(s)" onClick={() => { this.openDocumentModel() }} ><span className='fas fa-folder'></span>&nbsp;View Document(s)</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='card mb-2'>
                        <div className='card-body'>
                            <div className='sub-title-color font-weight-bold'>Item Info</div>
                            <div>
                                <DataGrid
                                    ref="grdSalesReturnAttributesRef"
                                    dataSource={this.state.itemDetails}
                                    keyExpr="id"
                                    allowColumnReordering={true}
                                    showBorders={true}
                                    onRowUpdating={this.onRowUpdating}
                                    onRowUpdated={this.onRowUpdated}
                                    onRowInserting={this.onRowInserting}
                                    onEditorPreparing={this.onEditorPreparing}
                                >
                                    <Paging enabled={true} />
                                    <Editing mode="row"
                                        allowUpdating={this.state.purchasereturnId === 0 ? true : false}
                                        allowDeleting={this.state.purchasereturnId === 0 ? true : false}
                                        allowAdding={this.state.purchasereturnId === 0 ? true : false}

                                        // allowUpdating={true}
                                        // allowDeleting={true}
                                        // allowAdding={this.state.invoiceId === 0 ? false : true}
                                        editorOptions={true} />
                                    <Column dataField="stockItemId" caption="Item" setCellValue={this.setCellValue} >
                                        <Lookup dataSource={this.state.itemList} displayExpr="name" valueExpr="id" />
                                        <RequiredRule />
                                    </Column>
                                    <Column dataField="serialNo" caption="Serial#" >
                                        <PatternRule message={'Quantity should be in number'} pattern={alphanumericValidation} />
                                        <StringLengthRule message="Description can not be more than 150 characters" max={150} />
                                    </Column>
                                    <Column dataField="unitPrice" caption="Unit Price" dataType="decimal" setCellValue={this.calculatePriceChange} format={"#,##0.##"} alignment="right"
                                        cellRender={renderGridNumberCell}>
                                        <RequiredRule />
                                        <PatternRule message={'Item Price should be decimal'} pattern={floatValidation} />
                                    </Column>
                                    {/* <Column dataField="taxAmount" caption="Tax Amount" dataType="decimal" allowEditing={false} format={"#,##0.##"} alignment="right"
                                            cellRender={renderGridNumberCell}>
                                            <RequiredRule />
                                            <PatternRule message={'Tax Amount should be decimal'} pattern={floatValidation} />
                                        </Column> */}
                                    <Column dataField="remarks" caption="Remarks">
                                        <PatternRule message={'Quantity should be in number'} pattern={alphanumericValidation} />
                                        <StringLengthRule message="Description can not be more than 150 characters" max={150} />
                                    </Column>
                                    <SortByGroupSummaryInfo summaryItem="count" />
                                    {this.state.errors["itemDetailsGrid"].length > 0 && <span className="error invalid-feedback">{this.state.errors["itemDetailsGrid"]}</span>}
                                </DataGrid>
                            </div>


                        </div>
                    </div>
                    <div className='card' style={{ display: this.state.purchasereturnId === 0 ? 'block' : 'none' }}>
                        <div className='card-body'>
                            <div className='sub-title-color font-weight-bold'>Document Info</div>
                            <div className='row'>
                                <div className="col-6">
                                    <div className="form-group">
                                        <label htmlFor="">Attach File(s) to Purchase Return</label>
                                        <input className="form-control" type="file" id="file" onClick={() => { this.removeAll() }} onChange={(e) => { this.handleFileInputChange(e, false) }} />
                                        {/* <label htmlFor="" className='small text-muted'>You can upload a maximum of 5 files, 5MB each</label> */}
                                        {this.state.documentSelectedFile.map((item, index) => {
                                            return (
                                                <div key={index}>
                                                    <label >{item.name} <button value={item.name} onClick={this.resetFileInput} class="btn btn-outline-secondary fas fa-trash text-danger"></button></label>
                                                </div>
                                            );
                                        })}
                                        {/* <label htmlFor="" className='small text-muted'>You can upload a maximum of 5 files, 5MB each</label> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Modal ariaHideApp={false}
                        isOpen={this.state.documentModelIsOpen}
                        style={customStyles}
                        className={"react-modal"}
                        onRequestClose={this.closeDocumentModel}
                        shouldCloseOnOverlayClick={false}
                        contentLabel="Example Modal">
                        <form method="post" style={this.state.isSubmited ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                            <div className="modal-header">
                                <h6 className="modal-title">Document</h6>
                            </div>
                            <div className="modal-body">
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    <div className="box-body no-padding" style={{ maxHeight: 400 + 'px', overflowY: "scroll" }}>
                                        <div>
                                            <ul className="users-list clearfix">
                                                {this.state.imageList.length > 0 && this.state.imageList.map((item, j) =>
                                                    <li style={{ position: "relative" }} key={j}>
                                                        <a href={item.src} download={item.fileName}><div className="framehover"><img src={item.src} width="150" alt="Blank" /></div></a>
                                                    </li>
                                                )}
                                                {this.state.pdfList.length > 0 && this.state.pdfList.map((item, j) =>
                                                    <li style={{ position: "relative" }} key={j}>
                                                        <a href={item.src} download={item.fileName}><div className="framehover"><img src="../../assets/img/pdf_icon.png" width="150" alt="Blank" /></div></a>
                                                    </li>
                                                )}
                                                {this.state.excelList.length > 0 && this.state.excelList.map((item, j) =>
                                                    <li style={{ position: "relative" }} key={j}>
                                                        <a href={item.src} download={item.fileName}><div className="framehover"><img src="../../assets/img/excel_icon.png" width="100" alt="Blank" /></div></a>
                                                    </li>
                                                )}
                                                {this.state.otherList.length > 0 && this.state.otherList.map((item, j) =>
                                                    <li style={{ position: "relative" }} key={j}>
                                                        <a href={item.src} download={item.fileName}><div className="framehover"><img src="../../assets/img/other_icon.png" width="100" alt="Blank" /></div></a>
                                                    </li>
                                                )}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="modal-footer">
                                <button type="button" className="btn btn-outline-secondary" onClick={this.closeDocumentModel} title="Cancel">Cancel</button>
                            </div>
                        </form>
                    </Modal>
                </div>

                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={position}
                    visible={this.state.loadPanelVisible}
                    showIndicator={true}
                    shading={true}
                    showPane={true}
                    hideOnOutsideClick={false}
                />
            </Fragment>
        )
    };
}
