import React, { Fragment, Component } from 'react';
import DataGrid, {
    Column,
    Export, Grouping,
    GroupPanel,
    HeaderFilter, Pager,
    Paging,
    SearchPanel,
    SortByGroupSummaryInfo,
    Summary, TotalItem,
} from 'devextreme-react/data-grid'; //npm i devextreme-react
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import { getRequestParams, formatDate, numberGridFormat } from '../../helpers/globalfunctions.js';
import { LoadPanel } from 'devextreme-react/load-panel';
import { alphanumericValidation } from '../../helpers/globalvalidations.js';
import { currencySymbolFormats, precisionFormats } from '../../helpers/fixcodes.js';
import { SelectBox } from 'devextreme-react/select-box';
import { createBrowserHistory } from 'history';
import { DocumentsComponent } from '../shared/DocumentsComponent.jsx';
import { jsPDF } from 'jspdf';
import { exportDataGrid } from 'devextreme/pdf_exporter';
const exportFormats = ['pdf', 'xlsx'];
const position = { of: '#historydiv' };
const history = createBrowserHistory();

export class PurchseReturnListComponent extends Component {
    static displayName = PurchseReturnListComponent.name;
    constructor(props) {
        super(props);

        this.state = {
            isSubmited: false,
            loadPanelVisible: false,
            purchaseReturnSource: null,
            itemReturnStatusList: null,
            accountList: null,
            dispatchedDate: "",
            invoiceNo: "",
            returnStatusId: 0,
            filterAccountId: 0

        }
    }

    async componentDidMount() {
        await this.getPurchaseReturnMaster();
        await this.getReturnStatus();
        await this.getAccounts();
    }

    getPurchaseReturnMaster = async () => {
        this.setState({
            loadPanelVisible: true
        });
        try {
            var request = {};
            request.DispatchedOn = this.state.dispatchedDate === "" ? "" : formatDate(this.state.dispatchedDate, "MM/dd/yyyy");
            request.ReturnStatusId = (this.state.returnStatusId);
            request.ItemReturnId = parseInt(0);
            request.InvoiceNo = this.state.invoiceNo === null ? "" : this.state.invoiceNo;
            request.AccountId = this.state.filterAccountId
            const requestParams = getRequestParams("POST", request);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'purchaseReturn/purchaseReturn', requestParams);
            const data = await response.json();
            this.setState({
                purchaseReturnSource: data.payload,
                loadPanelVisible: false
            });
        } catch (err) {
            console.log("Error in " + this.displayName + ".getPurchaseReturnMaster function", err);
            this.setState({
                loadPanelVisible: false
            });
        }
    }
    getAccounts = async () => {
        try {
            var request = {};
            const requestParams = getRequestParams("GET", request);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'account/dropdownAccount/3', requestParams);
            const data = await response.json();
            this.setState({
                accountList: data.payload
            });
        } catch (err) {
            console.log("Error in " + this.displayName + ".getAccounts function", err);
        }
    }

    getReturnStatus = async () => {
        try {
            var request = {};
            const requestParams = getRequestParams("GET", request);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'salesorder/returnedItemStatus', requestParams);
            const data = await response.json();
            this.setState({
                itemReturnStatusList: data.payload
            });
        } catch (err) {
            console.log("Error in " + this.displayName + ".getItemReturneStatus function", err);
        }
    }
    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift({
            location: 'after',
            widget: 'dxButton',
            options: {
                icon: 'refresh',
                onClick: this.getPurchaseReturnMaster.bind(this)
            }
        });
    }

    editSalesOrder(e, data) {
        e.preventDefault();
        history.push({
            pathname: '/purchasereturn/new',

            state: {
                purchasereturnId: data.id, dispatchedDate: data.dispatchedOn,
                invoiceNo: data.invoiceNo, returnStatusId: data.returnStatusId,
                returnStatus: data.returnStatus,
                accountId: data.customerId,
                sourceUrl: "/purchasereturn/list", sourceState: this.state
            }
        });
        window.location.reload(false);
    }

    handleAddPurchaseReturn = (e) => {
        sessionStorage.setItem("session_PageName", "Purchase Return");
        window.location.href = "/purchasereturn/new";
    }
    handleOnViewUploadDocument = async (e, data) => {
        e.preventDefault();
        try {
            var documentArray = []
            let row = {};
            row.id = data.id === null ? 0 : Number(data.id);
            row.soNo = data.rmaNo === null ? '' : data.rmaNo.trim();
            documentArray.push(row);
            this.refs.documentsComponent.showModal('Purchase Return', documentArray, data.rmaNo);
        } catch (ex) {
            console.log(ex);
        }
    }
    handleStatusChanges = (selectedOption) => {
        this.setState({
            returnStatusId: (selectedOption.selectedItem !== null ? selectedOption.selectedItem.id : 0),
        });
    }
    handleReceivedDateChanged = (date) => {
        this.setState({ dispatchedDate: date.value });
    }
    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        switch (name) {
            case 'invoiceNo':
                if (alphanumericValidation.test(event.target.value))
                    this.setState({ invoiceNo: event.target.value });
                break;
            default:
                this.setState({ [name]: value });
                break;
        }
    }
    handleClose = () => {
        this.setState({
            dispatchedDate: "",
            invoiceNo: "",
            returnStatusId: 0,
            filterAccountId: 0
        });
        setTimeout(() => this.getPurchaseReturnMaster(), 100);
    }
    onExporting = (e) => {
        const doc = new jsPDF();
        if (e.format === "pdf") {
            exportDataGrid({
                jsPDFDocument: doc,
                component: e.component,
                columnWidths: 50
                // indent: 10,
            }).then(() => {
                doc.save('PurchaseReturn.pdf');
            });
        }
    }
    handleFilterAccountChange = (selectedOption) => {
        this.setState({
            filterAccountId: (selectedOption.selectedItem !== null ? selectedOption.selectedItem.id : 0),
        });


    }
    render() {
        const renderGridCell = (data) => {
            return <div>
                <a href="/#" className="fas fa-eye" title="Edit" onClick={(e) => { this.editSalesOrder(e, data.data) }} >&nbsp;</a>
                <a href="/#" className="fas fa-file-alt" title="document" onClick={(e) => { this.handleOnViewUploadDocument(e, data.data) }} >&nbsp;</a>
            </div>;
        }
        const renderGridNumberCell = (data) => {
            return numberGridFormat(data.value)
            // return <div><small><i class={currencySymbolFormats.currencyFormat}></i></small>&nbsp;&nbsp;{parseFloat(data.value).toFixed(precisionFormats[2])}</div>;
        }

        const customizeText = (data) => {
            var value = 'Total: ' + numberGridFormat(data.value);
            return value
            // return `Total - ${data.value.toFixed(precisionFormats[2])}`;
        }
        return (
            <Fragment>
                <div className="section-body">
                    <div className='card bg-filter'>
                        <div className='card-body'>
                            <div className="section-header">
                            <div className='row'>
                                        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                                            <div className="form-group">
                                                <label htmlFor="">Vendor</label>
                                                <SelectBox
                                                    dataSource={this.state.accountList}
                                                    className="basic-multi-select"
                                                    classNamePrefix="select"
                                                    placeholder="Select vendor"
                                                    name="id"
                                                    defaultValue={0}
                                                    value={this.state.filterAccountId}
                                                    displayExpr='name'
                                                    valueExpr='id'
                                                    onSelectionChanged={this.handleFilterAccountChange}
                                                    showClearButton={true}
                                                    searchEnabled={true}
                                                />

                                            </div>
                                        </div>
                                        <div className="col-lg-2 col-md-3 col-sm-12 col-xs-12">
                                            <div className="form-group">
                                                <label htmlFor="">Item Status</label>
                                                <SelectBox
                                                    dataSource={this.state.itemReturnStatusList}
                                                    className="basic-multi-select"
                                                    classNamePrefix="select"
                                                    placeholder="Select status"
                                                    name="id"
                                                    defaultValue={0}
                                                    value={this.state.returnStatusId}
                                                    displayExpr='name'
                                                    valueExpr='id'
                                                    onSelectionChanged={this.handleStatusChanges}
                                                    showClearButton={true}
                                                    //disabled={this.state.salesreturnId > 0}
                                                    searchEnabled={true}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-2 col-md-3 col-sm-12 col-xs-12">
                                            <div className="form-group">
                                                <label htmlFor="">Bill#</label>
                                                <input type="text" className="form-control form-control-sm" name="invoiceNo" placeholder="Enter bill number" value={this.state.invoiceNo}
                                                    title="Invoice No" maxLength="20" onChange={this.handleInputChange} />
                                            </div>
                                        </div>
                                        {/* <div className='col-lg-2 col-md-2 col-sm-12'>
                                            <div className="form-group">
                                                <label htmlFor="">Dispatched Date </label>
                                                <DateBox type="date"
                                                    className="replay-date-time"
                                                    name="dispatchedDate"
                                                    placeholder="dd/mm/yyyy"
                                                    displayFormat="dd/MM/yyyy"
                                                    showAnalogClock={true}
                                                    useMaskBehavior={true}
                                                    defaultValue="dd/MM/yyyy"
                                                    value={this.state.dispatchedDate}
                                                    onValueChanged={this.handleReceivedDateChanged}
                                                    width="100%"
                                                    acceptCustomValue={false}
                                                />
                                            </div>
                                        </div> */}
                                        <div className="col-lg-4 col-md-3 col-sm-12 col-xs-12">
                                            <div className="form-group">
                                                <label htmlFor="">&nbsp;</label><br />
                                                <button type="submit" className="btn btn-success" title='Search' onClick={this.getPurchaseReturnMaster}>
                                                    Search
                                                </button>&nbsp;&nbsp;
                                                <button type="submit" className="btn btn-outline-secondary" title='Clear' onClick={this.handleClose}>
                                                    Clear
                                                </button>&nbsp;&nbsp;
                                                <button type="submit" className="btn btn-success" title='New' onClick={this.handleAddPurchaseReturn}>
                                                   New
                                                 </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        
                                    </div>
                            </div>

                        </div>
                    </div>
                    <div className='card'>
                        <div className='card-body'>
                            <DataGrid
                                dataSource={this.state.purchaseReturnSource}
                                keyExpr="id"
                                id='gridSalesOrderList'
                                selection={{ mode: 'single' }}
                                columnsAutoWidth={false}
                                showColumnLines={true}
                                showRowLines={true}
                                showBorders={true}
                                rowAlternationEnabled={false}
                                allowColumnResizing={true}
                                columnResizingMode='widget'
                                onExporting={this.onExporting}
                                onToolbarPreparing={this.onToolbarPreparing}>
                                <Column dataField="id" width={50} caption="" allowFiltering={false} allowSorting={false} cellRender={renderGridCell} />
                                <Column dataField="rmaNo" caption="RMA#" allowSearch={true} allowFiltering={false} allowSorting={true} width={150} />
                                <Column dataField="vendorName" caption="Vendor" allowSearch={true} allowFiltering={false} allowSorting={true} width={150} />
                                <Column dataField="invoiceNo" caption="Bill#" allowSearch={true} allowFiltering={false} allowSorting={true} width={150} />
                                <Column dataField="awbNo" caption="AWB#" allowSearch={true} allowFiltering={false} allowSorting={true} width={180} />
                                <Column dataField="returnStatus" caption="Status" allowSearch={true} allowFiltering={false} allowSorting={true} width={180} />
                                <Column dataField="dispatchedOn" caption="Dispatched Date" allowSearch={true} allowFiltering={false} allowSorting={true} width={180} dataType="datetime" format="dd/MM/yyyy HH:mm" />
                                {/* <Column dataField="receivedOn" caption="Received Date" allowSearch={true} allowFiltering={false} allowSorting={true} width={180} dataType="datetime" format="dd/MM/yyyy HH:mm" /> */}
                                <Column dataField="totalQty" caption="Total Quantity" allowSearch={true} allowFiltering={false} allowSorting={true} width={120} />
                                <Column dataField="grossAmount" caption="Total Amount" allowSearch={true} allowFiltering={false} allowSorting={true} width={150}
                                    format={"#,##0.###"}
                                    cellRender={renderGridNumberCell}
                                />
                                {/* <Column dataField="returnStatus" caption="Status" allowSearch={true} allowFiltering={false} allowSorting={true} width={100} /> */}
                                <Summary>
                                    <TotalItem column="grossAmount" summaryType="sum" customizeText={customizeText} />
                                </Summary>
                                <SortByGroupSummaryInfo summaryItem="count" />
                                <GroupPanel visible={false} />
                                {/* <Template name="toolbarTemplate" /> */}
                                <HeaderFilter visible={true} />
                                <SearchPanel visible={true} highlightCaseSensitive={true} width={240} placeholder="Search..." />
                                <Grouping autoExpandAll={true} />
                                <Paging defaultPageSize={20} />
                                <Pager showPageSizeSelector={true} allowedPageSizes={[10, 20, 50]} showInfo={true} visible={true} />
                                <Export enabled={true} allowExportSelectedData={false} fileName="Purchase Return" />
                            </DataGrid>
                        </div>
                    </div>
                </div>
                <DocumentsComponent ref="documentsComponent" />
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={position}
                    visible={this.state.loadPanelVisible}
                    showIndicator={true}
                    shading={true}
                    showPane={true}
                    hideOnOutsideClick={false}
                />
            </Fragment>
        )
    };
}