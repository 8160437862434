import React, { Component } from 'react';
import Modal from 'react-modal'; //npm install --save react-modal
import Swal from 'sweetalert2';
import { getRequestParams } from '../../helpers/globalfunctions.js';
import { OrderStatus,documentType } from '../../helpers/fixcodes.js';

import { LoadPanel } from 'devextreme-react/load-panel';
import { SelectBox } from 'devextreme-react/select-box';
import axios from 'axios'; //npm install--save axios

const position = { of: '#historydiv' };

const customStyles = {
    content: {
        width: '40%',
    }
}
export class DocumentsComponent extends Component {
    static displayName = DocumentsComponent.name;
    constructor(props) {
        super(props);
        this.state = {
            isOpenModal: false,
            isSubmited: false,
            uniqueId: [],
            fileRecordType: 0,
            displayNumber: '',
            pageName: '',
            isOpen: false,
            currImg: 0,
            documentSelectedFile: [],
            fileGuid: '00000000-0000-0000-0000-000000000000',
            imageList: [],
            pdfList: [],
            excelList: [],
            otherList: [],
            browseFileTitle: '',
            refreshRideSource: props.refreshDataSource,
            handleRefresh: props.handleRefresh,
            closeInvoicePayment: props.closeInvoicePayment,
            documentTypeId: 0,
            documentList: [],
            Name: "",
            currImg: 0,
            sessionUser: JSON.parse(sessionStorage.getItem("sessionUser")),
            documentTypeDisabled: true,
            errors: {
                documentTypeId: 0,
            },
            vendorDocumentDetails: {
                vendorName: '',
            },
            customerDocumentDetails: {
                customerName: '',
            },
            saleOrderDocumentDetails: {
                soNo: '',
            },
            purchaseOrderDocumentDetails: {
                poNo: '',
            },

        }

        this.handleInputChange = this.handleInputChange.bind(this);
        this.documentUpload = this.documentUpload.bind(this);

    }

    async componentDidMount() {

        await this.getDocumentType();

    }
    getDocumentType = async () => {
        try {
            var request = {};
            const requestParams = getRequestParams("GET", request);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'file/GetdocumentType', requestParams);
            const data = await response.json();
            this.setState({
                documentList: data.payload
            });
        } catch (err) {
            console.log("Error in " + this.displayName + ".getAccounts function", err);
        }
    }
    handleInputChange = async (event) => {
        if (this.state.documentType === 1) {
            if (event.target.files[0].type !== 'image/jpeg' && event.target.files[0].type !== 'image/png') {
                const isValid = await Swal.fire({
                    title: "<small>Invalid file!</small>",
                    text: "Only .jpeg, .jpg, .png files are allowed to upload.",
                    icon: "success",
                    width: '25%',
                    dangerMode: false,
                    allowOutsideClick: false
                });
                return;
            }
        }
        // if (this.state.documentTypeId === 0) {
        //     {
        //         let formIsValid = true;
        //         let errors = this.state.errors;
        //         errors.documentTypeId = "";
        //         if (this.state.documentTypeId === 0) {
        //             formIsValid = false;
        //             errors.documentTypeId = "Please select document.";
        //         }
        //         this.setState({ errors: errors });
        //         return formIsValid;
        //     }
        // }


        this.setState({
            loadPanelVisible: true
        });
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        if (event.target.files.length > 0) {
            this.setState({ documentSelectedFile: event.target.files[0] })
            let myVar = setTimeout(() => this.documentUpload(), 200)
        }
        this.setState({
            loadPanelVisible: false
        });
    }

    documentUpload = async () => {
        if (this.state.documentSelectedFile.size !== undefined) {
            if (this.state.documentSelectedFile.size > 0) {
                const url = process.env.REACT_APP_API_BASE_URL + 'file/upload';
                const formData = new FormData();
                console.log(this.state.documentSelectedFile);
                formData.append('body', this.state.documentSelectedFile);
                const config = {
                    headers: {
                        'content-type': 'multipart/form-data',
                        'UserId': this.state.sessionUser.id,
                        'Authorization': 'Bearer ' + sessionStorage.getItem('session_token'),
                    },
                };
                this.setState({
                    loadPanelVisible: true
                });
                axios.post(url, formData, config).then((response) => {
                    var resp = response.data;
                    if (resp != null) {
                        this.setState({
                            fileGuid: resp.fileGuid
                        })
                        this.saveDocument(resp)
                    }
                });
                this.setState({
                    loadPanelVisible: false
                });
            }
        }
    }
    saveDocument = async (data) => {
        //
        if (data !== null) {
            this.setState({
                fileGuid: data.fileGuid
            });
            try {
                var isSuccess = false;
                var resultMessage = '';
                for (var i = 0; i < this.state.uniqueId.length; i++) {
                    var request = new Object();
                    request.FileGuid = data.fileGuid;
                    request.FileRecordType = this.state.fileRecordType;
                    request.UserId = Number(this.state.sessionUser.id);
                    request.DocumentType = this.state.documentTypeId;
                    request.Id = this.state.uniqueId[i];
                    const requestVehicleParams = getRequestParams('POST', request);
                    const responseVehicle = await fetch(process.env.REACT_APP_API_BASE_URL + 'file/save-documents', requestVehicleParams);
                    const dataVehicle = await responseVehicle.json();
                    isSuccess = dataVehicle.isSuccess;
                    resultMessage = dataVehicle.message;
                }
                this.setState({
                    loadPanelVisible: false
                });
                if (isSuccess) {
                    Swal.fire({
                        icon: isSuccess ? 'success' : 'error',
                        title: isSuccess ? 'success' : 'Oops...',
                        text: "File uploaded successfully",
                    }).then((result) => {
                        if (isSuccess) {
                          //  this.closeModel();
                            this.getDocuments();
                        }
                    });
                } else {
                    this.onfailure('Failed to upload image.', resultMessage);
                }
            } catch (e) {
                //
            }

        }
    }
    closeModel = () => {
        this.setState({
            documentTypeId: 0,
            errors: {
                documentTypeId: 0
            }
        })
    }
    showModal(pageName, uniqueId, displayNumber) {
        try {
            if (pageName === "Vendor Document") {
                let vendorDocumentDetails = this.state.vendorDocumentDetails;
                vendorDocumentDetails.vendorName = uniqueId[0].vendorName;
                let id = Number(uniqueId[0].id);
                uniqueId = [];
                uniqueId.push(id);
                this.setState({
                    documentTypeId :documentType.Vendor,
                    fileRecordType: 1,
                    browseFileTitle: 'Add Vendor Document',
                    vendorDocumentDetails: vendorDocumentDetails,
                    documentTypeDisabled: true
                })
            }
            else if (pageName === "Customer Document") {
                let customerDocumentDetails = this.state.customerDocumentDetails;
                customerDocumentDetails.customerName = uniqueId[0].customerName;
                let id = Number(uniqueId[0].id);
                uniqueId = [];
                uniqueId.push(id);
                this.setState({
                    documentTypeId :documentType.Customer,
                    fileRecordType: 2,
                    browseFileTitle: 'Add Customer Document',
                    customerDocumentDetails: customerDocumentDetails,
                    documentTypeDisabled: true
                })
            }
            
            else if (pageName === "Purchase Order"){
                let customerDocumentDetails = this.state.purchaseOrderDocumentDetails;
                customerDocumentDetails.poNo = uniqueId[0].poNo;
                let id = Number(uniqueId[0].id);
                uniqueId = [];
                uniqueId.push(id);
                this.setState({
                    documentTypeId :documentType.PurchaseOrder,
                    fileRecordType: 4,
                    browseFileTitle: 'Add Purchase Order Document',
                    customerDocumentDetails: customerDocumentDetails,
                    documentTypeDisabled: true
                })  
            }
            else if(pageName === "SO#"){
                let customerDocumentDetails = this.state.purchaseOrderDocumentDetails;
                customerDocumentDetails.poNo = uniqueId[0].poNo;
                let id = Number(uniqueId[0].id);
                uniqueId = [];
                uniqueId.push(id);
                
                this.setState({
                    documentTypeId :documentType.SalesOrder,
                    fileRecordType: 5,
                    browseFileTitle: 'Add Sales Order Document',
                    customerDocumentDetails: customerDocumentDetails,
                    documentTypeDisabled: true,
                    pageName : "SO#"
                })  
            }
            else if(pageName === "Sales Return"){
                let customerDocumentDetails = this.state.purchaseOrderDocumentDetails;
                customerDocumentDetails.poNo = uniqueId[0].poNo;
                let id = Number(uniqueId[0].id);
                uniqueId = [];
                uniqueId.push(id);
                this.setState({
                    documentTypeId :documentType.SalesReturn,
                    fileRecordType: 5,
                    browseFileTitle: 'Add Sales Return Document',
                    customerDocumentDetails: customerDocumentDetails,
                    documentTypeDisabled: true
                })  
            }
            else if(pageName === "Purchase Return"){
                let customerDocumentDetails = this.state.purchaseOrderDocumentDetails;
                customerDocumentDetails.poNo = uniqueId[0].poNo;
                let id = Number(uniqueId[0].id);
                uniqueId = [];
                uniqueId.push(id);
                this.setState({
                    documentTypeId :documentType.PurchaseReturn,
                    fileRecordType: 5,
                    browseFileTitle: 'Add Sales Return Document',
                    customerDocumentDetails: customerDocumentDetails,
                    documentTypeDisabled: true
                })  
            }
            else{

            }

            
            this.setState({
                pageName: pageName,
                uniqueId: uniqueId,
                displayNumber: displayNumber,
                isOpenModal: true,
            });
            let myVar = setTimeout(() => this.getDocuments(), 200)
        } catch (ex) {

            console.log(ex);
        }
    }
    getDocuments = async () => {
        try {
            this.setState({
                loadPanelVisible: true
            });
            const requestParams = getRequestParams('GET', {});
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'file/' + this.state.documentTypeId + '/' + this.state.uniqueId[0] + '/documents', requestParams);
            const data = await response.json();
            var imageListObj = [];
            var pdfListObj = [];
            var excelListObj = [];
            var otherListObj = [];
            if (data.documents != null && data.documents.length > 0) {
                for (var i = 0; i < data.documents.length; i++) {
                    if (data.documents[i].fileGuid != '00000000-0000-0000-0000-000000000000') {
                        const requestParamsImage = getRequestParams('GET', '');
                        const responseImage = await fetch(process.env.REACT_APP_API_BASE_URL + 'file/download/' + data.documents[i].fileGuid, requestParamsImage);
                        const dataImage = await responseImage.json();

                        if (dataImage.fileExtension.toUpperCase() == '.PDF') {
                            var img = new Object();
                            img.src = 'data:' + dataImage.mimeType + ';base64,' + dataImage.fileContent;
                            img.fileGuid = data.documents[i].fileGuid;
                            img.fileName = dataImage.fileName;
                            pdfListObj.push(img);
                        } else if (dataImage.fileExtension.toUpperCase() == '.XLXS' || dataImage.fileExtension.toUpperCase() == '.CSV' || dataImage.fileExtension.toUpperCase() == '.XLS') {
                            var img = new Object();
                            img.src = 'data:' + dataImage.mimeType + ';base64,' + dataImage.fileContent;
                            img.fileGuid = data.documents[i].fileGuid;
                            img.fileName = dataImage.fileName;
                            excelListObj.push(img);
                        } else if (dataImage.fileExtension.toUpperCase() == '.JPG' || dataImage.fileExtension.toUpperCase() == '.PNG' || dataImage.fileExtension.toUpperCase() == '.JPEG') {
                            var img = new Object();
                            img.src = 'data:' + dataImage.mimeType + ';base64,' + dataImage.fileContent;
                            img.fileGuid = data.documents[i].fileGuid;
                            imageListObj.push(img);
                        } else {
                            var img = new Object();
                            img.src = 'data:' + dataImage.mimeType + ';base64,' + dataImage.fileContent;
                            img.fileGuid = data.documents[i].fileGuid;
                            img.fileName = dataImage.fileName;
                            otherListObj.push(img);
                        }

                    }
                }
            }

            this.setState({
                loadPanelVisible: false,
                imageList: imageListObj,
                pdfList: pdfListObj,
                excelList: excelListObj,
                otherList: otherListObj
            });

        } catch (e) {
            console.log(e);
        }
    }
    handleDocumentTypeChange = (selectedOption) => {
        this.setState({
            documentTypeId: (selectedOption.selectedItem !== null ? selectedOption.selectedItem.id : 0)
        });
    }
    closeDocumentModal = () => {
        this.setState({
            isOpenModal: false,
            isSubmited: false,
            uniqueId: [],
            documentType: 0,
            displayNumber: '',
            pageName: '',
            isOpen: false,
            currImg: 0,
            documentSelectedFile: [],
            fileGuid: '00000000-0000-0000-0000-000000000000',
            imageList: [],
            pdfList: [],
            excelList: [],
            otherList: [],
            browseFileTitle: '',
            documentTypeId: 0,
            errors: {
                documentTypeId: '',
            },
            vendorDocumentDetails: {
                vendorName: '',
            },
        });

        if (this.state.refreshRideSource != undefined) {
            this.state.handleRefresh(true);
            this.state.refreshRideSource();
        }
        if (this.state.closeInvoicePayment != undefined) {
            this.state.closeInvoicePayment();
        }
    }
    openImgsViewer=(event, index) =>{
        event.preventDefault()
        debugger;
        this.setState({
            currImg: index,
            isOpen: true,
        })
    }
    closeImgsViewer = ()=> {
        this.setState({
            currImg: 0,
            isOpen: false,
        })
    }
    gotoPrev = ()=> {
        this.setState({
            currImg: this.state.currImg - 1
        })
    }
    gotoNext=()=> {
        this.setState({
            currImg: this.state.currImg + 1
        })
    }
    render() {
        return (
            <div>
                <Modal ariaHideApp={false}
                    isOpen={this.state.isOpenModal}
                    style={customStyles}
                    className={"react-modal"}
                    onAfterOpen={this.afterOpenDocumentModal}
                    onRequestClose={this.closeDocumentModal}
                    shouldCloseOnOverlayClick={false}
                    contentLabel="Example Modal">
                    <form method="post" style={this.state.isSubmited ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                        <div className="modal-header">
                            {/*<h6 className="modal-title">{this.state.pageName} - {this.state.displayNumber}</h6>*/}
                            <h6 className="modal-title">{this.state.pageName
                                + ((this.state.displayNumber === null || this.state.displayNumber === '') ? '' : ' - ' + this.state.displayNumber)}
                            </h6>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.closeDocumentModal}>
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                {/* <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                                    <div className="form-group">
                                        <label>Document For</label>
                                        <SelectBox
                                        dataSource={this.state.documentList}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        placeholder="Select document For"
                                        name="id"
                                        defaultValue={0}
                                        value={this.state.documentTypeId}
                                        displayExpr='name'
                                        valueExpr='id'
                                        onSelectionChanged={this.handleDocumentTypeChange}
                                        showClearButton={true}
                                        disabled={this.state.documentTypeDisabled}
                                        searchEnabled={true}
                                    />
                                    {this.state.errors["documentTypeId"].length > 0 && <span className="error invalid-feedback">{this.state.errors["documentTypeId"]}</span>}
                                    </div>
                                </div>   */}
                                {/* {this.state.browseFileTitle} */}
                                <div className="col-xs-12 col-sm-12 col-md-8 col-lg-8">
                                    <div className="form-group">
                                        <label>Add Document</label>
                                        <div className="input-group">
                                            <div className="custom-file">
                                                <input type="file" name="driverFile" className="custom-file-input" onChange={this.handleInputChange} />
                                                <label className="custom-file-label">Choose file</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>                             
                            </div>

                            <div className="row">
                                
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    <div className="box-body no-padding">
                                        <div>
                                            <ul className="list-group list-group-horizontal">
                                                {this.state.imageList.length > 0 && this.state.imageList.map((item, j) =>
                                                    <li className='list-group-item' style={{ position: "relative" }} key={j}>
                                                        {/* <a href={item.src} onClick={(e) => { this.openImgsViewer(e, j) }}><div className="framehover"><img src={item.src} width="150" alt="Image" /></div></a> */}
                                                        <a href={item.src} ><div className="framehover"><img src={item.src} width="150" alt="Image" /></div></a>
                                                    </li>
                                                )}
                                                {this.state.pdfList.length > 0 && this.state.pdfList.map((item, j) =>
                                                    <li className='list-group-item' style={{ position: "relative" }} key={j}>
                                                        <a href={item.src} download={item.fileName}><div className="framehover"><img src="../../assets/img/pdf_icon.png" width="150" alt="Blank Image" /></div></a>
                                                    </li>
                                                )}
                                                {this.state.excelList.length > 0 && this.state.excelList.map((item, j) =>
                                                    <li className='list-group-item' style={{ position: "relative" }} key={j}>
                                                        <a href={item.src} download={item.fileName}><div className="framehover"><img src="../../assets/img/excel_icon.png" width="100" alt="Blank Image" /></div></a>
                                                    </li>
                                                )}
                                                {this.state.otherList.length > 0 && this.state.otherList.map((item, j) =>
                                                    <li className='list-group-item' style={{ position: "relative" }} key={j}>
                                                        <a href={item.src} download={item.fileName}><div className="framehover"><img src="../../assets/img/other_icon.png" width="100" alt="Blank Image" /></div></a>
                                                    </li>
                                                )}

                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-outline-secondary" onClick={this.closeDocumentModal} title="Cancel">Cancel</button>
                        </div>
                        {/* <ImgsViewer
                        imgs={this.state.imageList}
                        currImg={this.state.currImg}
                        onClickPrev={this.gotoPrev}
                        onClickNext={this.gotoNext}
                       // onClose={this.closeViewer}
                        isOpen={this.state.isOpen}
                        onClose={this.closeImgsViewer}
                    /> */}
                    </form>
                </Modal>
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={position}
                    visible={this.state.loadPanelVisible}
                    showIndicator={true}
                    shading={true}
                    showPane={true}
                    closeOnOutsideClick={false}
                />
            </div>
        )


    }
}