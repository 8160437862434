import DataGrid, {
  Column,
  Export,
  Grouping,
  GroupPanel,
  HeaderFilter,
  Pager,
  Paging,
  SearchPanel,
  SortByGroupSummaryInfo,
} from "devextreme-react/data-grid"; //npm i devextreme-react
import React, { Fragment, Component } from "react";
import DateBox from "devextreme-react/date-box";
import Modal from "react-modal"; //npm install --save react-modal;
import { SelectBox } from "devextreme-react/select-box";
import { getRequestParams } from "../../helpers/globalfunctions";
import { exportFormats, symbolFormats } from "../../helpers/fixcodes.js";
import TextArea from "devextreme-react/text-area";
import Swal from "sweetalert2";
import { toast } from "react-toastify"; //npm i react-toastify --save
import { TextBox } from "devextreme-react";
import { formatDate } from "../../helpers/globalfunctions.js";

const customStyles = {
  content: {
    width: "40%",
  },
};

export class ActiveDevicesComponent extends Component {
  static displayName = ActiveDevicesComponent.name;

  constructor(props) {
    super(props);
    this.state = {
      customerList: [],
      accountId: 0,
      customerId: 0,
      filterCustomerId: 0,
      soNo: "",
      wefDate: new Date(),
      addCustomerActiveDevices: false,
      activeDeviceList: [],
      activeCustomerId: 0,
      activeDevice: 0,
      id: 0,
      errors: {
        customerId: 0,
        activeDevice: 0,
      },
    };
  }

  async componentDidMount() {
    await this.getCustomers();
    await this.getCustomerActiveDevices();
    //await this.getCustomerActiveDevicesById();
  }

  getCustomers = async () => {
    try {
      var request = {};
      const requestParams = getRequestParams("GET", request);
      const response = await fetch(
        process.env.REACT_APP_API_BASE_URL + "account/dropdownAccount/2",
        requestParams
      );
      const data = await response.json();
      this.setState({
        customerList: data.payload,
      });
    } catch (err) {
      console.log("Error in " + this.displayName + ".getAccount function", err);
    }
  };

  getCustomerActiveDevices = async () => {
    try {
      var request = {};
      const requestParams = getRequestParams("GET", request);
      const response = await fetch(
        process.env.REACT_APP_API_BASE_URL +
          "CustomerDevice/GetCustomerActiveDevice",
        requestParams
      );
      const data = await response.json();
      this.setState({
        activeDeviceList: data.payload,
      });
    } catch (err) {
      console.log(
        "Error in " + this.displayName + ".getCustomer active devices",
        err
      );
    }
  };

  getCustomerActiveDevicesById = async () => {
    try {
      var request = {};
      //this.state.wefDate=formatDate(this.state.wefDate, "yyyy-MM-dd");
      const requestParams = getRequestParams("GET", request);
      const response = await fetch(
        process.env.REACT_APP_API_BASE_URL +
          "CustomerDevice/GetCustomerActiveDeviceById/" +
          this.state.filterCustomerId,
        requestParams
      );
      const data = await response.json();
      this.setState({
        activeDeviceList: data.payload,
      });
    } catch (err) {
      console.log(
        "Error in " + this.displayName + ".getCustomer Active Devices By Id",
        err
      );
    }
  };

  //dropdown value change
  handleFilterCustomerChange = (selectedOption) => {
    this.setState({
      filterCustomerId:
        selectedOption.selectedItem !== null
          ? selectedOption.selectedItem.id
          : 0,
    });
  };
  handleCustomerChange = (selectedOption) => {
    this.setState({
      customerId:
        selectedOption.selectedItem !== null
          ? selectedOption.selectedItem.id
          : 0,
    });
  };

  handleWefDateChanged = (Date) => {
    this.setState({ wefDate: Date.value });
  };

  handleSearch = async () => {
    await this.getCustomerActiveDevicesById();
  };

  handleCancel = () => {
    this.setState({
      customerId: 0,
      filterCustomerId: 0,
      wefDate: new Date(),
    });
    this.getCustomerActiveDevices();
  };

  newCustomerActiveDevices = () => {
    this.setState({
      addCustomerActiveDevices: true,
    });
  };

  handleValidation = () => {
    let formIsValid = true;
    try {
      let errors = this.state.errors;
      errors.customerId = 0;
      errors.activeDevice = 0;

      if (this.state.customerId == 0) {
        formIsValid = false;
        errors.customerId = "Please select customer.";
      }
      if (this.state.activeDevice == 0) {
        formIsValid = false;
        errors.activeDevice = "Please anter active device.";
      }
      this.setState({ errors: errors });
    } catch (err) {
      console.log(
        "Error in " + this.displayName + ".isActiveDeviceValidation Function",
        err
      );
    }
    return formIsValid;
  };

  handleActiveDeviceSubmit = async (e) => {
    e.preventDefault();
    try {
      if (this.handleValidation()) {
        this.setState({ isSubmited: true });
        //
        var newObject = {};
        newObject.CustomerId = parseInt(this.state.customerId);
        newObject.ItemQty = parseInt(this.state.activeDevice);

        const requestParams = getRequestParams("POST", newObject);
        const response = await fetch(
          process.env.REACT_APP_API_BASE_URL +
            "CustomerDevice/" +
            this.state.accountId +
            "/activeDevice",
          requestParams
        );
        const data = await response.json();

        if (data.isSuccess) {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: data.message,
          }).then((result) => {
            if (result.isConfirmed) {
              this.closeActiveDeviceModal();
              this.getCustomerActiveDevices();
            }
            this.setState({ isSubmited: false });
          });
        } else {
          toast.error(data.message, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 2000,
            style: { width: "310px" },
            hideProgressBar: false,
            newestOnTop: false,
            closeButton: false,
          });
          this.setState({ isSubmited: false });
        }
      }
    } catch (err) {
      console.log(
        "Error in " + this.displayName + ".handleActiveDeviceSubmit function",
        err
      );
      this.setState({ isSubmited: false });
    }
  };

  handleTextAreaValueChanged = (event) => {
    this.setState({
      activeDevice: event.value,
    });
  };

  closeActiveDeviceModal = () => {
    this.setState({
      addCustomerActiveDevices: false,
      customerId: 0,
      activeDevice: 0,
    });
  };

  editActiveDeviceModal = (e, data) => {
    e.preventDefault();

    if (data != null) {
      this.setState({
        addCustomerActiveDevices: true,
        singleData: data,
        id: data.id,
        customerId: data.customerId,
        activeDevice: data.itemQty,
      });
    }
  };

  render() {
    // const renderGridCell = (data) => {
    //     return <div>
    //         <a href="#" className="fas fa-pen" title="Edit" onClick={(e) => { this.editActiveDeviceModal(e, data.data) }} >&nbsp;</a>&nbsp;&nbsp;
    //     </div>;
    // }
    //     const renderGridNumberCell = (data) => {
    //         return <div>{parseFloat(data.value).toFixed(2)}&nbsp;&nbsp;<small><i class={symbolFormats.percentageFormat}></i></small></div>;
    // }
    return (
      <Fragment>
        <div className="section-body">
          <div className="card bg-filter">
            <div className="card-body">
              <div className="section-header">
                <div className="row">
                  <div className="col-lg-4">
                    <div className="form-group">
                      <label htmlFor="">Customer</label>
                      <SelectBox
                        dataSource={this.state.customerList}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        placeholder="Select Customer"
                        name="id"
                        defaultValue={0}
                        value={this.state.filterCustomerId}
                        displayExpr="name"
                        valueExpr="id"
                        onSelectionChanged={this.handleFilterCustomerChange}
                        showClearButton={true}
                        //disabled={this.state.id < 0}
                        searchEnabled={true}
                      />
                    </div>
                  </div>
                  {/* <div className="col-lg-2">
                                        <div className="form-group">
                                            <label>WEF Date</label>
                                            <DateBox type="date"
                                                className="wef-date"
                                                name="wefDate"
                                                placeholder="Select Date"
                                                displayFormat="MM/dd/yyyy"
                                                showAnalogClock={true}
                                                useMaskBehavior={true}
                                                defaultValue="MM/dd/yyyy"
                                                value={this.state.wefDate}
                                                onValueChanged={this.handleWefDateChanged}
                                                width="100%"
                                                disabled={false}
                                                acceptCustomValue={false}
                                            />
                                        </div>
                                    </div> */}
                  <div className="col-lg-2 pl-0">
                    <div className="form-group">
                      <label>&nbsp;</label>
                      <br></br>
                      <button
                        type="submit"
                        className="btn btn-success"
                        /**style={{float: 'right'}}**/ onClick={
                          this.handleSearch
                        }
                      >
                        Search
                      </button>
                      &nbsp;&nbsp;
                      <button
                        type="submit"
                        className="btn btn-custom-gray"
                        onClick={this.handleCancel}
                      >
                        <span title="Clear">Clear</span>
                      </button>
                    </div>
                  </div>
                </div>
                <div>
                  {/* <button type="New" title="New" className="btn btn-success" onClick={this.newCustomerActiveDevices}>
                                        New
                                    </button> */}
                </div>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-body">
              <DataGrid
                dataSource={this.state.activeDeviceList}
                keyExpr="customerId"
                selection={{ mode: "single" }}
                columnsAutoWidth={false}
                showColumnLines={true}
                showRowLines={true}
                showBorders={true}
                rowAlternationEnabled={false}
                allowColumnResizing={true}
                columnResizingMode="widget"
                onExporting={this.onExporting}
                onToolbarPreparing={this.onToolbarPreparing}
              >
                {/* <Column dataField="id" width={30} caption="" allowFiltering={false} allowSorting={false} /> */}
                <Column
                  dataField="name"
                  caption="Customer"
                  allowSearch={true}
                  allowFiltering={false}
                  allowSorting={true}
                  width={450}
                />
                <Column
                  dataField="soNo"
                  caption="SO#"
                  allowSearch={true}
                  allowFiltering={false}
                  allowSorting={true}
                  width={100}
                />
                {/* <Column dataField="activeDeviceQty" caption="Active Devices" allowSearch={true} allowFiltering={false} allowSorting={true} width={150} /> */}
                <Column
                  dataField="itemQty"
                  caption="ItemQty"
                  allowSearch={true}
                  allowFiltering={false}
                  allowSorting={true}
                  width={150}
                />
                <Column
                  dataField=""
                  caption=""
                  allowSearch={true}
                  allowFiltering={false}
                  allowSorting={true}
                  width={150}
                />
                {/* <Column dataField="wefDate" caption="Wef Date" allowSearch={true} allowFiltering={false} allowSorting={true} width={400} dataType="datetime" format="dd/MM/yyyy" /> */}
                <SortByGroupSummaryInfo summaryItem="count" />
                <GroupPanel visible={false} />
                {/* <Template name="toolbarTemplate" /> */}
                <HeaderFilter visible={true} />
                <SearchPanel
                  visible={true}
                  highlightCaseSensitive={true}
                  width={240}
                  placeholder="Search..."
                />
                <Grouping autoExpandAll={true} />
                <Paging defaultPageSize={10} />
                <Pager
                  showPageSizeSelector={true}
                  allowedPageSizes={[10, 20, 50]}
                  showInfo={true}
                  visible={true}
                />
                <Export
                  enabled={true}
                  allowExportSelectedData={false}
                  fileName="Active Devices"
                />
              </DataGrid>
            </div>
          </div>
        </div>
        <Modal
          ariaHideApp={false}
          isOpen={this.state.addCustomerActiveDevices}
          style={customStyles}
          className={"react-modal"}
          onRequestClose={this.closeActiveDeviceModal}
          shouldCloseOnOverlayClick={false}
          contentLabel="Example Modal"
        >
          <form
            method="post"
            onSubmit={this.handleActiveDeviceSubmit}
            style={
              this.state.isSubmited
                ? { pointerEvents: "none", opacity: "0.4" }
                : {}
            }
          >
            <div className="modal-header">
              <h6 className="modal-title">
                {this.state.id > 0
                  ? "Update Active Device"
                  : "New Active Device"}
              </h6>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-lg-8">
                  <div className="form-group">
                    <label>
                      Customer <span className="text-danger">*</span>
                    </label>
                    <SelectBox
                      dataSource={this.state.customerList}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      placeholder="Select customer"
                      name="id"
                      defaultValue={0}
                      value={this.state.customerId}
                      displayExpr="name"
                      valueExpr="id"
                      onSelectionChanged={this.handleCustomerChange}
                      showClearButton={true}
                      //disabled={this.state.customerId > 0}
                      searchEnabled={true}
                    />

                    {this.state.errors["customerId"].length > 0 && (
                      <span className="error invalid-feedback">
                        {this.state.errors["customerId"]}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="form-group">
                    <label>
                      Active Device <span className="text-danger">*</span>
                    </label>
                    <TextBox
                      height={30}
                      className="form-control form-control-sm"
                      name="activeDevice"
                      placeholder="Enter active device"
                      value={this.state.activeDevice}
                      onValueChanged={this.handleTextAreaValueChanged}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="modal-footer">
              <button
                type="submit"
                className="btn btn-success"
                onClick={this.handleActiveDeviceSubmit}
              >
                {this.state.isSubmited && (
                  <span title="In-process">
                    <i className="fas fa-spinner fa-spin"></i> In-process
                  </span>
                )}
                {!this.state.isSubmited && <span title="Submit">Submit</span>}
              </button>
              <button
                type="button"
                className="btn btn-custom-gray"
                onClick={this.closeActiveDeviceModal}
                title="Cancel Active Device Modal"
              >
                Cancel
              </button>
            </div>
          </form>
        </Modal>
      </Fragment>
    );
  }
}

//export default ;
