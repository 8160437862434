import React, { Fragment, Component } from 'react';
import DataGrid, {
    Column,
    Export, Grouping,
    GroupPanel,
    HeaderFilter, Pager,
    Paging,
    SearchPanel,
    SortByGroupSummaryInfo
} from 'devextreme-react/data-grid'; //npm i devextreme-react
import Modal from 'react-modal'; //npm install --save react-modal;
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import { SelectBox } from 'devextreme-react/select-box';
import { getRequestParams } from '../../helpers/globalfunctions.js';
import { alphanumericValidation } from '../../helpers/globalvalidations.js';
import { LoadPanel } from 'devextreme-react/load-panel';
import Swal from 'sweetalert2'; //npm i sweetalert2 --save
import { toast } from 'react-toastify'; //npm i react-toastify --save
const position = { of: '#historydiv' };
const type = [
    { id: 1, name: 'HSN' },
    { id: 2, name: 'SAC' },
];
const customStyles = {
    content: {
        width: '20%',
    }
}

export class HsnCodeComponent extends Component {
    static displayName = HsnCodeComponent.name;

    constructor(props) {
        super(props);

        this.state = {
            isSubmited: false,
            loadPanelVisible: false,
            hsnModelIsOpen: false,
            hsncodesource: null,
            singelData: null,
            hsnsacList: null,
            hsnsacId: 0,
            id: 0,
            hsnName: "",
            hsnCode: "",
            hsnType: "",
            hsntypeList: type,
            errors: {
                hsnName: "",
                hsnCode: "",
                hsnType: "",
            }
        }
        this.handleHsnSubmit = this.handleHsnSubmit.bind(this);
    }

    async componentDidMount() {
        this.setState({
            loadPanelVisible: true
        });
        await this.getHsnSacCodeType();
        await this.getHsnCodeMaster();
        this.setState({
            loadPanelVisible: false
        });
    }

    getHsnCodeMaster = async () => {
        try {
            const requestParams = getRequestParams("GET", null);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'hsnsacCode/hscacccodes/' + this.state.hsnsacId, requestParams);
            const data = await response.json();
            this.setState({
                hsncodesource: data.payload,
            });
        } catch (err) {
            console.log("Error in " + this.displayName + ".getHsnCodeMaster function", err);
            this.setState({
                loadPanelVisible: false
            });
        }
    }

    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift({
            location: 'after',
            widget: 'dxButton',
            options: {
                icon: 'refresh',
                onClick: this.getHsnCodeMaster.bind(this)
            }
        });
    }

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        let errors = this.state.errors;

        //const alphanumericValidation = /^[A-Za-z0-9]*$/;
        switch (name) {
            case 'hsnName':
                if (alphanumericValidation.test(event.target.value))
                    this.setState({ hsnName: event.target.value });
                break;
            case 'hsnCode':
                if (alphanumericValidation.test(event.target.value))
                    this.setState({ hsnCode: event.target.value });
                break;
            default:
                this.setState({ [name]: value });
                break;
        }

        errors[name] = "";
        this.setState({
            errors: errors
        });
    }

    handleHsmModal = (e) => {
        this.setState({
            hsnModelIsOpen: true,
            singelData: null
        });
    }

    closeHsnModal = () => {
        this.setState({
            isSubmited: false,
            loadPanelVisible: false,
            hsnModelIsOpen: false,
            id: 0,
            hsnName: "",
            hsnCode: "",
            hsnType: "",
            hsnsacId: 0,
            errors: {
                hsnName: "",
                hsnCode: "",
                hsnType: "",
            }
        });
        setTimeout(() => this.getHsnCodeMaster(), 500);
    }

    handleHsnSacChange = (selectedOption) => {
        this.setState({
            hsnsacId: (selectedOption.selectedItem !== null ? selectedOption.selectedItem.id : 0),
            //  category: (selectedOption.selectedItem !== null ? selectedOption.selectedItem.name : "")
        });

        // if (selectedOption.selectedItem !== null && selectedOption.selectedItem.id > 0) {
        //     this.state.errors["categoryId"] = "";
        // }
    }

    handleHsnSacTypeChange = (selectedOption) => {
        this.setState({
            hsnType: (selectedOption.selectedItem !== null ? selectedOption.selectedItem.name : ""),
        });
      if (selectedOption.selectedItem !== null && selectedOption.selectedItem.id > 0) {
            this.state.errors["hsnType"] = "";
        }

    }
    getHsnSacCodeType = async () => {
        try {
            //var request = {};
            //const requestParams = getRequestParams("GET", request);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'hsnsacCode/dropdown/hsnsactype')
            const data = await response.json();
            this.setState({
                hsnsacList: data.payload
            });
        } catch (err) {
            console.log("Error in " + this.displayName + ".getHsnSacCodeType function", err);
        }
    }

    handleValidation = () => {
        let formIsValid = true;
        try {
            let errors = this.state.errors;
            errors.hsnCode = "";
            errors.hsnName = "";
            errors.hsnType = "";

            if (this.state.hsnCode.trim() === "") {
                formIsValid = false;
                errors.hsnCode = "Please enter code.";
            }
            if (this.state.hsnName.trim() === "") {
                formIsValid = false;
                errors.hsnName = "Please enter code name.";
            }
            if (this.state.hsnType.trim() === "") {
                formIsValid = false;
                errors.hsnType = "Please select code type.";
            }
            this.setState({ errors: errors });
        }
        catch (err) {
            console.log("Error in " + this.displayName + ".handleValidation function", err);
            this.setState({ isSubmited: false })
        }
        return formIsValid;
    }

    handleHsnSubmit = async (e) => {
        e.preventDefault();
        try {
            if (this.handleValidation()) {
                this.setState({ isSubmited: true });
                var newObject = {};
                newObject.Code = this.state.hsnCode.trim();
                newObject.Name = this.state.hsnName.trim();
                newObject.Type = this.state.hsnType.trim();
                const requestParams = getRequestParams('POST', newObject);
                const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'hsnsacCode/' + this.state.id + '/HSNSACode', requestParams);
                const data = await response.json();
                if (data.isSuccess) {
                    Swal.fire({
                        icon: 'success',
                        title: 'Success',
                        text: data.message,
                    }).then((result) => {
                        if (result.isConfirmed) {
                            this.getHsnCodeMaster();
                            this.closeHsnModal();
                        }
                        this.setState({ isSubmited: false })
                    })
                }
                else {
                    toast.error(data.message, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        style: { width: "310px" },
                        hideProgressBar: false,
                        newestOnTop: false,
                        closeButton: false
                    });
                    this.setState({ isSubmited: false })
                }
            }
        }
        catch (err) {
            console.log("Error in " + this.displayName + ".handleHsnSubmit function", err);
            this.setState({ isSubmited: false })
        }
    }

    edithsnModelModal = (e, data) => {
        e.preventDefault();
        if (data != null) {
            this.setState({
                hsnModelIsOpen: true,
                singelData: data,
                id: data.id,
                hsnName: data.name,
                hsnCode: data.code,
                hsnType: data.type,
            });
        }
    }

    render() {
        const renderGridCell = (data) => {
            return <div>
                <a href="/#" className="fas fa-pen" title="Edit" onClick={(e) => { this.edithsnModelModal(e, data.data) }} >&nbsp;</a>&nbsp;&nbsp;
            </div>;
        }

        return (
            <Fragment>
                <div className="section-body">
                    <div className='card bg-filter'>
                        <div className='card-body'>
                            <div className="section-header">
                                <div className='row'>
                                    <div className="col-lg-2">
                                        <div className="form-group">
                                            <label htmlFor="">HSN & SAC Code</label>
                                            <SelectBox
                                                dataSource={this.state.hsnsacList}
                                                className="basic-multi-select"
                                                classNamePrefix="select"
                                                placeholder="Select HSN & SAC code"
                                                name="id"
                                                defaultValue={0}
                                                value={this.state.hsnsacId}
                                                displayExpr='name'
                                                valueExpr='id'
                                                onSelectionChanged={this.handleHsnSacChange}
                                                showClearButton={true}
                                                disabled={this.state.id > 0}
                                                searchEnabled={true}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-3 col-sm-12 col-xs-12 pl-0">
                                        <div className="form-group">
                                            <label htmlFor="">&nbsp;</label><br />
                                            <button type="submit" className="btn btn-success" onClick={this.getHsnCodeMaster} title="Search">
                                                Search
                                            </button>&nbsp;&nbsp;
                                            <button type="submit" className="btn btn-outline-secondary" onClick={this.closeHsnModal} title="Clear">
                                                Clear
                                            </button>&nbsp;&nbsp;
                                            
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <button type="submit" className="btn btn-success" onClick={this.handleHsmModal} title="New">
                                        New
                                    </button>
                                </div>

                            </div>
                            
                        </div>
                    </div>
                    <div className='card'>
                    <div className='card-body'>
                                <DataGrid
                                    dataSource={this.state.hsncodesource}
                                    keyExpr="id"
                                    id='gridHsnCodeMasterList'
                                    selection={{ mode: 'single' }}
                                    columnsAutoWidth={false}
                                    showColumnLines={true}
                                    showRowLines={true}
                                    showBorders={true}
                                    rowAlternationEnabled={false}
                                    allowColumnResizing={true}
                                    columnResizingMode='widget'
                                    onToolbarPreparing={this.onToolbarPreparing}>
                                    <Column dataField="id" width={35} caption="" allowFiltering={false} allowSorting={false} cellRender={renderGridCell} />
                                    <Column dataField="name" caption="Name" allowSearch={true} allowFiltering={false} allowSorting={true} width={150} />
                                    <Column dataField="type" caption="Type" allowSearch={true} allowFiltering={false} allowSorting={true} width={100} />
                                    <Column dataField="code" caption="Code" allowSearch={true} allowFiltering={false} allowSorting={true} width={150} />                                    
                                    <SortByGroupSummaryInfo summaryItem="count" />
                                    <GroupPanel visible={false} />
                                    {/* <Template name="toolbarTemplate" /> */}
                                    <HeaderFilter visible={true} />
                                    <SearchPanel visible={true} highlightCaseSensitive={true} width={240} placeholder="Search..." />
                                    <Grouping autoExpandAll={true} />
                                    <Paging defaultPageSize={20} />
                                    <Pager showPageSizeSelector={true} allowedPageSizes={[10, 20, 50]} showInfo={true} visible={true} />
                                    <Export enabled={true} allowExportSelectedData={false} fileName="HSNSACCode" />
                                </DataGrid>
                            </div>
                    </div>
                </div>

                <Modal ariaHideApp={false}
                    isOpen={this.state.hsnModelIsOpen}
                    style={customStyles}
                    className={"react-modal"}
                    //onAfterOpen={this.handleAssignDataToState}
                    onRequestClose={this.closeHsnModal}
                    shouldCloseOnOverlayClick={false}
                    contentLabel="Example Modal">
                    <form method="post" onSubmit={this.handleHsnSubmit} style={this.state.isSubmited ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                        <div className="modal-header">
                            <h6 className="modal-title">{this.state.id > 0 ? "Update HSN/SAC" : "New HSN/SAC"}</h6>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="form-group">

                                        {/* <input type="text" className="form-control form-control-sm" name="hsnType" autoComplete="hsnType" placeholder="e.g. HSN or SAC" maxLength="5" title="HSN Type" value={this.state.hsnType} onChange={this.handleInputChange} />
                                        {this.state.errors["hsnType"].length > 0 && <span className="error invalid-feedback">{this.state.errors["hsnType"]}</span>} */}
                                        <div className="form-group">
                                            <label htmlFor="">Type <span className="text-danger">*</span></label>
                                            <SelectBox
                                                dataSource={this.state.hsntypeList}
                                                className="basic-multi-select"
                                                classNamePrefix="select"
                                                placeholder="Select HSN & SAC code"
                                                name="name"
                                                defaultValue={0}
                                                value={this.state.hsnType}
                                                displayExpr='name'
                                                valueExpr='name'
                                                onSelectionChanged={this.handleHsnSacTypeChange}
                                                showClearButton={true}
                                                disabled={this.state.id > 0}
                                                searchEnabled={true}
                                            />
                                            {this.state.errors["hsnType"].length > 0 && <span className="error invalid-feedback">{this.state.errors["hsnType"]}</span>}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="form-group">
                                        <label>Name <span className="text-danger">*</span></label>
                                        <input type="text" className="form-control form-control-sm" name="hsnName" placeholder="Enter name" value={this.state.hsnName} 
                                            title="HSN Name" maxLength="50" onChange={this.handleInputChange} />
                                        {this.state.errors["hsnName"].length > 0 && <span className="error invalid-feedback">{this.state.errors["hsnName"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="form-group">
                                        <label htmlFor="">Code <span className="text-danger">*</span></label>
                                        <input type="text" className="form-control form-control-sm" name="hsnCode" placeholder="Enter code" maxLength="20" title="Code" value={this.state.hsnCode}
                                            onChange={this.handleInputChange} />
                                        {this.state.errors["hsnCode"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["hsnCode"]}</span>}
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="submit" className="btn btn-success" onClick={this.handleHsnSubmit} >
                                {this.state.isSubmited && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                {!this.state.isSubmited && <span title="Submit">Submit</span>}
                            </button>
                            <button type="button" className="btn btn-outline-secondary" onClick={this.closeHsnModal} title="Cancel">Cancel</button>
                        </div>
                    </form>
                </Modal>
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={position}
                    visible={this.state.loadPanelVisible}
                    showIndicator={true}
                    shading={true}
                    showPane={true}
                    hideOnOutsideClick={false}
                />
            </Fragment>
        )
    };
}