import React, { Fragment, Component } from 'react';
import DataGrid, {
    Column,
    ColumnChooser,
    ColumnFixing, Export, Format, Grouping,
    GroupPanel,
    HeaderFilter, Pager,
    Paging,
    SearchPanel,
    Selection,
    Editing, Lookup,
    SortByGroupSummaryInfo
}
    from 'devextreme-react/data-grid';
import { DateFormat, symbolFormats } from '../../helpers/fixcodes.js';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import Modal from 'react-modal'; //npm install --save react-modal;
import { SelectBox } from 'devextreme-react/select-box';
import { getRequestParams, formatDate } from '../../helpers/globalfunctions.js';
import Swal from 'sweetalert2'; //npm i sweetalert2 --save
import { toast } from 'react-toastify'; //npm i react-toastify --save
import { jsPDF } from 'jspdf';
import { exportDataGrid } from 'devextreme/pdf_exporter';
const exportFormats = ['pdf', 'xlsx'];
const customStyles = {
    content: {
        width: '30%',
    }
}

const taxFormat = {
    type: 'percent',
    precision: 1,
};

export class HSNTaxComponent extends Component {
    static displayName = HSNTaxComponent.name;
    constructor(props) {
        super(props);
        this.state = {
            isSubmited: false,
            loadPanelVisible: false,
            configModelIsOpen: false,
            selectedRowKey: [],
            datasource: [],
            id: 0,
            hsnCodeList: [],
            taxList: [],
            selectedTaxConfigList: [],
            hsnId: 0,
            hsnCode: "",
            checkBoxesMode: "always",
            accountTaxList: [],
            sessionUser: JSON.parse(sessionStorage.getItem("sessionUser")),
            wefDate: formatDate(new Date(), "MM/dd/yyyy hh:mm"),
            errors: {
                hsnId: 0,
            }
        }
    }
    async componentDidMount() {
        this.setState({
            loadPanelVisible: true
        });
        await this.getTaxConfiguration();
        await this.getCustomer();
        this.setState({
            loadPanelVisible: false
        });
    }
    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift({
            location: 'after',
            widget: 'dxButton',
            options: {
                icon: 'refresh',
                onClick: this.getTaxConfiguration.bind(this)
            }
        });
    }

    getTaxConfiguration = async () => {
        try {
            var request = {};
            const requestParams = getRequestParams("GET", request);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'hsntax/0', requestParams);
            const data = await response.json();
            this.setState({
                datasource: data.payload
            });
        } catch (err) {
            console.log("Error in " + this.displayName + ".getContractList function", err);
        }
    }
    getCustomer = async () => {
        try {
            var request = {};
            const requestParams = getRequestParams("GET", request);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'hsnsacCode/hscacccodes/0', requestParams);
            const data = await response.json();

            this.setState({
                hsnCodeList: data.payload
            });
        } catch (err) {
            console.log("Error in " + this.displayName + ".getContractList function", err);
        }
    }

    getTax = async (id) => {
        try {
            var request = {};

            const requestParams = getRequestParams("GET", request);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'hsntax/configurationByHSNId/' + id, requestParams);
            const data = await response.json();
            this.setState({
                taxList: data.payload,
            });
            setTimeout(() => this.setCheckedTaxDetails(), 500);

        } catch (err) {
            console.log("Error in " + this.displayName + ".getContractList function", err);
        }
    }

    setCheckedTaxDetails = (e) => {
        let selectedRowKeys = [];
        let selectedRows = this.state.taxList.filter((m) => { return m.enable === true; });
        for (let index = 0; index < selectedRows.length; index++) {
            selectedRowKeys.push(selectedRows[index].taxId);
        }

        this.setState({
            selectedRowKey: selectedRowKeys
        });
    }

    openTaxCofigurationModel = () => {
        this.setState({
            configModelIsOpen: true,
        })
        this.getTax(0);
    }
    handleHSNTaxChange = (selectedOption) => {
        this.setState({
            hsnId: (selectedOption.selectedItem !== null ? selectedOption.selectedItem.id : 0),
            hsnCode: (selectedOption.selectedItem !== null ? selectedOption.selectedItem.name : ""),
        });
        if (selectedOption.selectedItem !== null && selectedOption.selectedItem.id > 0) {
            this.state.errors["hsnId"] = "";
        }
        this.getTax(selectedOption.selectedItem.id);
    }

    closeModel = () => {
        this.setState({
            configModelIsOpen: false,
            selectedRowKey: [],
            hsnId: 0,
            taxList: [],
            id: 0,
            errors: {
                hsnId: 0
            }
        })
    }
    handleMultipleSelectionChanged = (e) => {
        this.setState({
            selectedRowKey: e.selectedRowKeys
        });
    }
    handleTaxConfigurationSubmit = async (e) => {
        e.preventDefault();
        try {
            let formIsValid = true;
            let errors = this.state.errors;
            errors.hsnId = 0;
            if (this.state.hsnId === 0) {
                if (this.state.hsnId === 0) {
                    formIsValid = false;
                    errors.hsnId = "Please select HSN/SAC.";
                }
                this.setState({ errors: errors });
                return formIsValid;
            }

            if (this.state.selectedRowKey != null && this.state.selectedRowKey.length === 0) {
                Swal.fire({
                    icon: 'error',
                    text: "Please select at least one tax.",
                }).then((result) => {
                    this.setState({ isSwapTrip: false })
                });
                return;
            }
            let taxList = [];
            let selectedRowKeys = this.state.selectedRowKey;
            let list = this.state.taxList.filter((tax) => selectedRowKeys.filter((id) => id == tax.taxId).length > 0);
            list.map((gridData, i) => {
                let newgrid = new Object();
                newgrid.TaxConfigurationId = gridData.id;
                newgrid.WefDate = gridData.wefDate;
                newgrid.Percentage = parseFloat(gridData.percentage, 3);
                newgrid.Id = gridData.taxId;
                newgrid.enable = gridData.enable;
                taxList.push(newgrid);
            });
            var newObject = {};
            newObject.UserId = this.state.sessionUser.id;
            newObject.HSNId = this.state.hsnId;
            newObject.HSNCode = this.state.hsnCode;
            newObject.Tax = taxList;

            const requestParams = getRequestParams('POST', newObject);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'hsntax', requestParams);
            const data = await response.json();
            if (data.isSuccess) {
                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: data.message,
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.getTaxConfiguration();
                        this.closeModel();
                    }
                    this.setState({ isSubmited: false })
                })
            }
            else {
                toast.error(data.message, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    style: { width: "310px" },
                    hideProgressBar: false,
                    newestOnTop: false,
                    closeButton: false
                });
                this.setState({ isSubmited: false })
            }

        } catch (err) {
            console.log("Error in " + this.displayName + ".handleItemSubmit function", err);
            this.setState({ isSubmited: false })
        }
    }
    editTaxConfigurationModal = async (e, griddata) => {
        
        e.preventDefault();
        this.setState({
            configModelIsOpen: true,
            hsnId: griddata.id,
            id: 1,
        })
        this.getTax(griddata.id);
    }
    onExporting = (e) => {
        const doc = new jsPDF();
        if (e.format === "pdf") {
            exportDataGrid({
                jsPDFDocument: doc,
                component: e.component,
                columnWidths: 50
                // indent: 10,
            }).then(() => {
                doc.save('TaxConfiguration.pdf');
            });
        }
    }
    render() {
        const renderGridCell = (data) => {
            return <div>
                <a href="/#" className="fas fa-pen" title="Edit" onClick={(e) => { this.editTaxConfigurationModal(e, data.data) }} ></a>
            </div>;
        }

        const renderGridNumberCell = (data) => {
            return <div>{parseFloat(data.value).toFixed(2)}&nbsp;&nbsp;<small><i class={symbolFormats.percentageFormat}></i></small></div>;
        }

        // const renderGridNumberCell = (data) => {
        //     return <div>{parseFloat(data.value).toFixed(precisionFormats[3])}&nbsp;&nbsp;<small><i class={symbolFormats.percentageFormat}></i></small></div>;
        // }
        return (
            <Fragment>
                <div className="section-body">
                    <div className='card bg-filter'>
                        <div className='card-body'>
                            <div className="section-header">
                                <h6 class="m-0">HSN & SAC Tax</h6>
                                <div className="section-header-buttons">
                                    <button type="New" className="btn btn-success" onClick={this.openTaxCofigurationModel}>
                                        <span title="New">Set Tax</span>
                                    </button>
                                    {/* <a href="/#" className="btn btn-outline-secondary"><i className="fas fa-filter"></i></a>
                                    <div className="btn-group">
                                        <button type="button" title='More' className="btn btn-outline-secondary dropdown-toggle dropdown-icon" data-toggle="dropdown" aria-expanded="false">
                                            <i className="fas fa-ellipsis-h"></i>
                                        </button>
                                        <div className="dropdown-menu" role="menu">
                                            <a className="dropdown-item" href="/#"><i className="fas fa-file-import"></i> Import</a>
                                            <a className="dropdown-item" href="/#"><i className="fas fa-file-import"></i> Export</a>
                                            <a className="dropdown-item" href="/#"><i className="fas fa-print"></i> Print</a>
                                            <a className="dropdown-item" href="/#"><i className="fas fa-envelope"></i> Email</a>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='card'>
                        <div className='card-body'>
                            <DataGrid
                                dataSource={this.state.datasource}
                                keyExpr="id"
                                selection={{ mode: 'single' }}
                                columnsAutoWidth={false}
                                showColumnLines={true}
                                showRowLines={true}
                                showBorders={true}
                                rowAlternationEnabled={false}
                                allowColumnResizing={true}
                                columnResizingMode='widget'
                                onExporting={this.onExporting}
                                onToolbarPreparing={this.onToolbarPreparing}>
                                <Column dataField="id" caption="" allowSearch={true} allowFiltering={false} allowSorting={true} width={35} cellRender={renderGridCell} />
                                <Column dataField="name" caption="HSN/SAC Name" allowSearch={true} allowFiltering={false} allowSorting={true} width={150} />
                                <Column dataField="code" caption="HSN/SAC Code" allowSearch={true} allowFiltering={false} allowSorting={true} width={150} />
                                <Column dataField="tax" caption="Tax" allowSearch={true} allowFiltering={false} allowSorting={true} width={150} />
                                <SortByGroupSummaryInfo summaryItem="count" />
                                <GroupPanel visible={false} />
                                <HeaderFilter visible={false} />
                                <SearchPanel visible={true} highlightCaseSensitive={true} width={240} placeholder="Search..." />
                                <Grouping autoExpandAll={true} />
                                <Paging defaultPageSize={20} />
                                <Pager showPageSizeSelector={true} allowedPageSizes={[10, 20, 50]} showInfo={true} visible={true} />
                                <Export enabled={true} allowExportSelectedData={false} fileName="HSNtax" />
                            </DataGrid>
                        </div>
                    </div>
                </div>
                <Modal ariaHideApp={false}
                    isOpen={this.state.configModelIsOpen}
                    style={customStyles}
                    className={"react-modal"}
                    onRequestClose={this.closeModel}
                    shouldCloseOnOverlayClick={false}
                    contentLabel="Example Modal">
                    <form method="post" style={this.state.isSubmited ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                        <div className="modal-header">
                            <h6 className="modal-title">Set Tax Configuration</h6>
                        </div>
                        <div className="modal-body">
                            <div className='row'>
                                <div className="col-lg-12">
                                    <div className="form-group">
                                        <label>HSN/SAC Code <span className="text-danger">*</span></label>
                                        <SelectBox
                                            dataSource={this.state.hsnCodeList}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select HSN/SAC code"
                                            name="id"
                                            defaultValue={0}
                                            value={this.state.hsnId}
                                            displayExpr='hsnsacNameCode'
                                            valueExpr='id'
                                            onSelectionChanged={this.handleHSNTaxChange}
                                            showClearButton={true}
                                            disabled={this.state.id > 0}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["hsnId"].length > 0 && <span className="error invalid-feedback">{this.state.errors["hsnId"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <DataGrid
                                        dataSource={this.state.taxList}
                                        keyExpr="taxId"
                                        columnsAutoWidth={false}
                                        showColumnLines={true}
                                        showRowLines={true}
                                        showBorders={true}
                                        rowAlternationEnabled={false}
                                        allowColumnResizing={true}
                                        columnResizingMode='widget'
                                        onSelectionChanged={this.handleMultipleSelectionChanged}
                                        selectedRowKeys={this.state.selectedRowKey}
                                        onRowUpdating={this.onRowUpdating}
                                        onRowUpdated={this.onRowUpdated}
                                        onRowInserting={this.onRowInserting}
                                        onRowRemoved={this.onRowRemoved}>
                                        <Selection
                                            mode="multiple"
                                            selectAllMode={"allPages"}
                                            showCheckBoxesMode={this.state.checkBoxesMode}
                                        />
                                        <Editing mode="cell" allowUpdating={false} />
                                        {/* <Editing mode="row"
                                            allowUpdating={true}
                                            allowDeleting={true}
                                            allowAdding={true}
                                            editorOptions={this.state.id > 0} /> */}
                                        <Column dataField="taxName" caption="Tax" allowSearch={true} allowFiltering={false} allowSorting={true} allowUpdating={false} width={250} />
                                        {/* <Column dataField="wefDate" caption="WEF Date" value={this.state.wefDate} allowSearch={true} allowFiltering={false} editorOptions={false} allowUpdating={false}
                                            allowSorting={true} width={200} format={DateFormat.DateWesternFormat} dataType="date" /> */}
                                        <Column dataField="percentage" caption="Percentage" allowSearch={true} allowFiltering={false} allowSorting={true}
                                            width={60} allowUpdating={false} cellRender={renderGridNumberCell} />
                                        <SortByGroupSummaryInfo summaryItem="count" />
                                        <GroupPanel visible={false} />
                                        <HeaderFilter visible={true} />
                                        <SearchPanel visible={false} highlightCaseSensitive={true} width={240} placeholder="Search..." />
                                        <Grouping autoExpandAll={true} />
                                        <Paging defaultPageSize={10} />
                                        <Pager showPageSizeSelector={false} allowedPageSizes={[10, 20, 50]} showInfo={true} visible={true} />
                                        <Export enabled={false} allowExportSelectedData={false} fileName="Custmer" />
                                    </DataGrid>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="submit" className="btn btn-success" onClick={this.handleTaxConfigurationSubmit} >
                                {this.state.isSubmited && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                {!this.state.isSubmited && <span title="Update">Submit</span>}
                            </button>
                            <button type="button" className="btn btn-outline-secondary" onClick={this.closeModel} title="Cancel">Cancel</button>
                        </div>
                    </form>
                </Modal>
            </Fragment>
        )
    }
}