import React, { Fragment, Component } from "react";
import DataGrid, {
  Column,
  Export,
  Grouping,
  GroupPanel,
  HeaderFilter,
  Pager,
  Paging,
  SearchPanel,
  SortByGroupSummaryInfo,
} from "devextreme-react/data-grid"; //npm i devextreme-react
import Modal from "react-modal"; //npm install --save react-modal;
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import { TextBox } from "devextreme-react";
import { formatDate, getRequestParams } from "../../helpers/globalfunctions.js";
import { alphanumericValidation } from "../../helpers/globalvalidations.js";
import { QCResultStatus, exportFormats } from "../../helpers/fixcodes.js";
import { LoadPanel } from "devextreme-react/load-panel";
import Swal from "sweetalert2"; //npm i sweetalert2 --save
import { toast } from "react-toastify"; //npm i react-toastify --save
import { SelectBox } from "devextreme-react/select-box";
import DateBox from "devextreme-react/date-box";
import { jsPDF } from "jspdf";
import axios from "axios";
import { exportDataGrid } from "devextreme/pdf_exporter";

const addressTypeRadio = ["Add By Vendor", "Add By Customer"];
const position = { of: "#historydiv" };
const customStyles = {
  content: {
    width: "40%",
  },
};

export class StockComponent extends Component {
  static displayName = StockComponent.name;

  constructor(props) {
    super(props);
    this.state = {
      isSubmited: false,
      loadPanelVisible: false,
      sessionUser: JSON.parse(sessionStorage.getItem("sessionUser")),
      stockSource: [],
      itemList: [],
      warehouseList: [],
      id: 0,
      itemId: 0,
      serialNumber: "",
      warehouseId: 0,
      validFrom: new Date(),
      validTo: new Date(),
      isNew: true,
      installerName: "",
      filterItemId: 0,
      filterBillNo: "",
      filterSerialNo: "",
      filterValidDate: "",
      filterWarehouseId: 0,
      filterInstaller: "",
      min: new Date(),
      billList: null,
      billNo: "",
      billId: 0,
      billItemList: [],
      itemSource: null,
      file: null,
      saleOrderList: [],
      accountList: null,
      customerId: 0,
      soNo: "",
      visibleBillNo: false,
      addressType: addressTypeRadio[0],
      errors: {
        itemId: 0,
        serialNumber: "",
        warehouseId: 0,
        //validFrom: new Date(),
        //validTo: new Date(),
        installerName: "",
        billNo: "",
        //file:null,
      },
    };
  }

  async componentDidMount() {
    await this.getStocks();
    await this.getItemMaster();
    await this.getWarehouseMaster();
    await this.getBillMaster();
    await this.getItemList(0);
    // await this.getSaleOrderMaster(0);
    await this.getAccounts();
  }

  getStocks = async () => {
    debugger;
    this.setState({
      loadPanelVisible: true,
    });
    try {
      var request = {};
      request.ItemId = parseInt(this.state.filterItemId);
      request.BillNo = this.state.filterBillNo;
      request.SerialNo = this.state.filterSerialNo;
      request.WarehouseId = parseInt(this.state.filterWarehouseId);
      request.InstallerName = this.state.filterInstaller;
      request.ValidDate =
        this.state.filterValidDate !== ""
          ? formatDate(this.state.filterValidDate, "MM/dd/yyyy hh:mm")
          : "";
      const requestParams = getRequestParams("POST", request);
      const response = await fetch(
        process.env.REACT_APP_API_BASE_URL +
          "itemstock/stock/" +
          parseInt(this.state.id),
        requestParams
      );
      const data = await response.json();
      this.setState({
        stockSource: data.payload,
        loadPanelVisible: false,
      });
    } catch (err) {
      console.log("Error in " + this.displayName + ".getStocks function", err);
      this.setState({
        loadPanelVisible: false,
      });
    }
  };

  onExporting = (e) => {
    const doc = new jsPDF();
    if (e.format === "pdf") {
      exportDataGrid({
        jsPDFDocument: doc,
        component: e.component,
        columnWidths: 50,
        // indent: 10,
      }).then(() => {
        doc.save(e.fileName);
      });
    }
  };

  handleSearchStock = async () => {
    await this.getStocks();
  };

  getItemMaster = async () => {
    try {
      var request = {};
      request.CategoryId = 0;
      request.HSNId = 0;
      request.ItemId = 0;
      const requestParams = getRequestParams("POST", request);
      const response = await fetch(
        process.env.REACT_APP_API_BASE_URL + "items/item/",
        requestParams
      );
      const data = await response.json();
      this.setState({
        itemList: data.payload != null ? data.payload : [],
      });
    } catch (err) {
      console.log(
        "Error in " + this.displayName + ".getItemMaster function",
        err
      );
    }
  };

  getWarehouseMaster = async () => {
    try {
      var request = {};
      const requestParams = getRequestParams("GET", request);
      const response = await fetch(
        process.env.REACT_APP_API_BASE_URL + "master/warehouse/dropdown",
        requestParams
      );
      const data = await response.json();
      this.setState({
        warehouseList: data.payload != null ? data.payload : [],
      });
    } catch (err) {
      console.log(
        "Error in " + this.displayName + ".getWarehouseMaster function",
        err
      );
    }
  };
  getBillMaster = async () => {
    try {
      var request = {};
      const requestParams = getRequestParams("GET", request);
      const response = await fetch(
        process.env.REACT_APP_API_BASE_URL + "billing/billingDropDown",
        requestParams
      );
      const data = await response.json();
      this.setState({
        billList: data.payload != null ? data.payload : [],
      });
    } catch (err) {
      console.log(
        "Error in " + this.displayName + ".getWarehouseMaster function",
        err
      );
    }
  };
  getSaleOrderMaster = async (customerId) => {
    try {
      var request = {};
      const requestParams = getRequestParams("GET", request);
      const response = await fetch(
        process.env.REACT_APP_API_BASE_URL +
          "salesorder/getSaleOrderByCustomerId/" +
          customerId,
        requestParams
      );
      const data = await response.json();

      this.setState({
        saleOrderList: data.payload != null ? data.payload : [],
      });
    } catch (err) {
      console.log(
        "Error in " + this.displayName + ".getWarehouseMaster function",
        err
      );
    }
  };

  getItemList = async (id) => {
    this.setState({
      loadPanelVisible: true,
    });
    try {
      var request = {};
      request.FromDate = formatDate(new Date(), "yyyy-MM-dd");
      request.ToDate = formatDate(new Date(), "yyyy-MM-dd");
      request.billId = this.state.billId;
      const requestParams = getRequestParams("POST", request);
      const response = await fetch(
        process.env.REACT_APP_API_BASE_URL +
          "billing/GetDetails/" +
          this.state.billId,
        requestParams
      );
      const data = await response.json();

      this.setState({
        billItemList:
          data.payload.billDetails != null ? data.payload.billDetails : [],
        loadPanelVisible: false,
      });
    } catch (err) {
      console.log(
        "Error in " + this.displayName + ".getInvoiceList function",
        err
      );
      this.setState({
        loadPanelVisible: false,
      });
    }
  };
  getAccounts = async () => {
    try {
      var request = {};
      const requestParams = getRequestParams("GET", request);
      const response = await fetch(
        process.env.REACT_APP_API_BASE_URL + "account/dropDownAccountPayment",
        requestParams
      );
      const data = await response.json();
      this.setState({
        accountList: data.payload,
      });
    } catch (err) {
      console.log(
        "Error in " + this.displayName + ".getAccounts function",
        err
      );
    }
  };

  // getItemMaster = async () => {
  //     try {
  //         var request = {};
  //         //request.categoryId = this.state.categoryId;
  //         request.CategoryId = parseInt(0);
  //         request.HSNId = parseInt(0);
  //         const requestParams = getRequestParams("POST", request);
  //         const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'items/item/', requestParams);
  //         const data = await response.json();

  //         this.setState({
  //             itemSource: data.payload != null ? data.payload : []
  //         });
  //     } catch (err) {
  //         console.log("Error in " + this.displayName + ".getItemMaster function", err);
  //     }
  // }
  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    switch (name) {
      case "serialNumber":
        if (alphanumericValidation.test(event.target.value))
          this.setState({ serialNumber: event.target.value });
        break;
      case "installerName":
        if (alphanumericValidation.test(event.target.value))
          this.setState({ installerName: event.target.value });
        break;
      case "filterBillNo":
        if (alphanumericValidation.test(event.target.value))
          this.setState({ filterBillNo: event.target.value });
        break;
      case "filterSerialNo":
        if (alphanumericValidation.test(event.target.value))
          this.setState({ filterSerialNo: event.target.value });
        break;
      case "filterInstaller":
        if (alphanumericValidation.test(event.target.value))
          this.setState({ filterInstaller: event.target.value });
        break;
      default:
        this.setState({ [name]: value });
        break;
    }
  };

  handlestockModal = async (e) => {
    this.setState({
      stockModal: true,
      singelData: null,
    });
  };

  handleBillItemChange = (selectedOption) => {
    this.setState({
      itemId:
        selectedOption.selectedItem !== null
          ? selectedOption.selectedItem.itemId
          : 0,
    });
  };

  handleItemChange = (selectedOption) => {
    this.setState({
      itemId:
        selectedOption.selectedItem !== null
          ? selectedOption.selectedItem.id
          : 0,
    });
  };
  handleFilterItemChange = (selectedOption) => {
    this.setState({
      filterItemId:
        selectedOption.selectedItem !== null
          ? selectedOption.selectedItem.id
          : 0,
    });
  };

  handleWarehouseChange = (selectedOption) => {
    this.setState({
      warehouseId:
        selectedOption.selectedItem !== null
          ? selectedOption.selectedItem.id
          : 0,
    });
  };

  handleFilterWarehouseChange = (selectedOption) => {
    this.setState({
      filterWarehouseId:
        selectedOption.selectedItem !== null
          ? selectedOption.selectedItem.id
          : 0,
    });
  };

  handleValidFromDateChanged = (date) => {
    this.setState({ validFrom: date.value });
  };

  handleValidToDateChanged = (date) => {
    this.setState({ validTo: date.value });
  };

  handleFilterValidDateChanged = (date) => {
    this.setState({ filterValidDate: date.value });
  };
  handleBillChange = (selectedOption) => {
    this.setState({
      billNo:
        selectedOption.selectedItem !== null
          ? selectedOption.selectedItem.name
          : "",
      billId:
        selectedOption.selectedItem !== null
          ? selectedOption.selectedItem.id
          : 0,
      visibleBillNo: selectedOption.selectedItem !== null ? true : false,
    });

    console.log(this.state.billNo);
    console.log(this.state.billId);
    setTimeout(() => this.getItemList(selectedOption.selectedItem.id), 100);
  };

  handleFilterBillChange = (selectedOption) => {
    this.setState({
      filterBillNo:
        selectedOption.selectedItem !== null
          ? selectedOption.selectedItem.name
          : "",
    });
  };

  handleSoNoChange = (selectedOption) => {
    this.setState({
      soNo:
        selectedOption.selectedItem !== null
          ? selectedOption.selectedItem.soNo
          : "",
    });
  };
  handleCustomerChange = (selectedOption) => {
    this.setState({
      customerId:
        selectedOption.selectedItem !== null
          ? selectedOption.selectedItem.id
          : 0,
    });
    if (selectedOption.selectedItem !== null) {
      setTimeout(() => {
        this.getSaleOrderMaster(selectedOption.selectedItem.id);
      }, 500);
    } else {
      this.setState({ saleOrderList: [] });
    }
  };

  handleValidation = () => {
    let formIsValid = true;
    try {
      let errors = this.state.errors;
      errors.itemId = 0;
      errors.serialNumber = "";
      errors.warehouseId = 0;
      errors.validFrom = "";
      errors.validTo = "";

      if (this.state.itemId === 0) {
        formIsValid = false;
        errors.itemId = "Please select an item.";
      }
      if (this.state.serialNumber.trim() === "") {
        formIsValid = false;
        errors.serialNumber = "Please enter serial number.";
      }
      // if (this.state.warehouseId === 0) {
      //     formIsValid = false;
      //     errors.warehouseId = "Please select warehouse.";
      // }
      // if (this.state.validFrom === null) {
      //     formIsValid = false;
      //     errors.validFrom = "Please enter valid from date.";
      // }
      // if (this.state.validTo === null) {
      //     formIsValid = false;
      //     errors.validTo = "Please select valid to date.";
      // }
      this.setState({ errors: errors });
    } catch (err) {
      console.log(
        "Error in " +
          this.displayName +
          ".isFormhandleValidationValid function",
        err
      );
      formIsValid = false;
    }
    return formIsValid;
  };

  handleStockSubmit = async (e) => {
    e.preventDefault();
    try {
      if (this.handleValidation()) {
        this.setState({ isSubmited: true });
        var newObject = {};
        newObject.BillNo = this.state.billNo.trim();
        newObject.ItemId = parseInt(this.state.itemId);
        newObject.SerialNo = this.state.serialNumber;
        newObject.WarehouseId = parseInt(this.state.warehouseId);
        newObject.IsNew = this.state.isNew;
        newObject.QCPassed = parseInt(QCResultStatus.PENDING);
        //newObject.ValidFrom = formatDate(this.state.validFrom, "MM/dd/yyyy hh:mm");
        newObject.ValidFrom =
          this.state.validFrom === null
            ? ""
            : formatDate(this.state.validFrom, "MM/dd/yyyy hh:mm");
        //newObject.ValidTo = formatDate(this.state.validTo, "MM/dd/yyyy hh:mm");
        newObject.ValidTo =
          this.state.validTo === null
            ? ""
            : formatDate(this.state.validTo, "MM/dd/yyyy hh:mm");
        newObject.InstallerName = this.state.installerName;
        
        newObject.Source = "Web";
        newObject.CreatedBy = parseInt(this.state.sessionUser.id);
        newObject.CreatedOn = formatDate(new Date(), "MM/dd/yyyy hh:mm");
        newObject.UpdatedBy = parseInt(this.state.sessionUser.id);
        newObject.UpdatedOn = formatDate(new Date(), "MM/dd/yyyy hh:mm");
        newObject.SONo = this.state.soNo;
        newObject.CustomerId = this.state.customerId;
        const requestParams = getRequestParams("POST", newObject);
        const response = await fetch(
          process.env.REACT_APP_API_BASE_URL +
            "itemstock/" +
            this.state.id +
            "/stock",
          requestParams
        );
        const data = await response.json();

        if (data.isSuccess) {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: data.message,
          }).then((result) => {
            if (result.isConfirmed) {
              this.closeStockModal();
              setTimeout(() => this.getStocks(), 100);
            }
            this.setState({ isSubmited: false });
          });
        } else {
          toast.error(data.message, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 2000,
            style: { width: "310px" },
            hideProgressBar: false,
            newestOnTop: false,
            closeButton: false,
          });
          this.setState({ isSubmited: false });
        }
      }
    } catch (err) {
      console.log(
        "Error in " + this.displayName + ".handleStockSubmit function",
        err
      );
      this.setState({ isSubmited: false });
    }
  };

  handleTextAreaValueChanged = (event) => {
    this.setState({
      //activeDevice: event.value,
    });
  };

  closeStockModal = () => {
    this.setState({
      isSubmited: false,
      loadPanelVisible: false,
      stockModal: false,
      id: 0,
      itemId: 0,
      serialNumber: "",
      warehouseId: 0,
      validFrom: new Date(),
      validTo: new Date(),
      isNew: false,
      installerName: "",
      billNo: "",
      billId: 0,
      billItemList: [],
      itemSource: null,
      soNo: "",
      customerId: 0,
      soNo: "",
      visibleBillNo: false,
      addressType: addressTypeRadio[0],
      saleOrderList: [],
      errors: {
        itemId: 0,
        serialNumber: "",
        warehouseId: 0,
        validFrom: "",
        validTo: "",
        installerName: "",
      },
    });
  };
  handleClearSearch = () => {
    this.setState({
      stockSource: [],
      id: 0,
      itemId: 0,
      serialNumber: "",
      warehouseId: 0,
      validFrom: new Date(),
      validTo: new Date(),
      isNew: true,
      installerName: "",
      filterItemId: 0,
      filterBillNo: "",
      filterSerialNo: "",
      filterValidDate: "",
      filterWarehouseId: 0,
      errors: {
        itemId: 0,
        serialNumber: "",
        warehouseId: 0,
        validFrom: new Date(),
        validTo: new Date(),
        installerName: "",
      },
    });
    setTimeout(() => this.getStocks(), 500);
  };

  editstockModal = (e, data) => {
    e.preventDefault();
    if (data != null) {
      setTimeout(() => this.getItemList(), 100);
      this.setState({
        stockModal: true,
        singelData: data,
        id: data.id,
        itemId: data.itemId,
        serialNumber: data.serialNo,
        warehouseId: data.warehouseId,
        validFrom: data.validFrom,
        validTo: data.validTo,
        isNew: data.isNew,
        installerName: data.installerName,
        billNo: data.billNo,
        soNo: data.soNo,
        customerId: data.customerId,
      });
    }
  };

  onToolbarPreparing = (e) => {
    e.toolbarOptions.items.unshift({
      location: "after",
      widget: "dxButton",
      options: {
        icon: "refresh",
        onClick: this.getStocks.bind(this),
      },
    });
    e.toolbarOptions.items.unshift({
      location: "before",
      template: "",
    });
  };
  qcModel = (e, Griddata) => {
    e.preventDefault();
    sessionStorage.setItem("session_PageName", "Quality Check");
    this.props.history.push({
      pathname: "/qualitycheck",
      state: { stockId: Griddata.id, sourceUrl: "/stock" },
    });
    window.location.reload(false);
  };

  handleAttachmentInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    let errors = this.state.errors;
    switch (name) {
      case "file":
        errors.file = "";
        if (event.target.files.length === 0) {
          this.setState({ file: null });
          errors.file = "Please select attachment.";
        } else {
          this.state.errors["file"] = "";
          this.setState({ file: event.target.files[0] });
        }
        break;
      default:
        this.setState({
          [name]: value,
        });
        break;
    }
  };
  getAttachment = async (e) => {
    e.preventDefault();
    {
      if (this.state.file == null) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Please upload file.",
        }).then((result) => {
          this.setState({ isSubmited: false });
        });
        return;
      }
      try {
        this.setState({
          loadPanelVisible: true,
        });
        const url = process.env.REACT_APP_API_BASE_URL + "ImportData/ItemStock";
        const formData = new FormData();
        formData.append("files", this.state.file);
        console.log(this.state.file);

        // formData.append('accountId', secureLocalStorage.getItem('session_accountId'));
        // formData.append('userId', secureLocalStorage.getItem('session_userId'));
        const config = {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + sessionStorage.getItem("session_token"),
          },
        };
        axios.post(url, formData, config).then((response) => {
          var resp = response.data;
          this.setState({
            loadPanelVisible: false,
          });

          Swal.fire({
            icon: resp.isSuccess == true ? "success" : "error",
            text: resp.message,
          }).then((result) => {
            if (resp.isSuccess == true) {
              if (result.isConfirmed) {
                this.setState({ file: null });
              }
              this.setState({ isSubmited: false });
            } else {
              this.setState({ isSubmited: false });
            }
          });
          this.setState({
            loadPanelVisible: false,
          });
        });
      } catch (err) {
        console.log(
          "Error in " + this.displayName + ".getAttachment milkData function",
          err
        );
      }
    }
  };
  changeSelectionAddressType = (data) => {
    this.setState({ addressType: data.value });
  };
  downloadSampleDocument = (e) => {
    e.preventDefault();
    window.location.href = "../../assets/excel/StockSampleDocument.xlsx";
  };
  render() {
    const renderGridCell = (data) => {
      return (
        <div>
          <a
            href="#"
            className="fas fa-pen"
            title="Edit"
            onClick={(e) => {
              this.editstockModal(e, data.data);
            }}
          ></a>
          &nbsp;&nbsp;
        </div>
      );
    };
    const renderQcStatusGridCell = (data) => {
      return (
        <div>
          <a
            href="#"
            onClick={(e) => {
              this.qcModel(e, data.data);
            }}
          >
            {data.data.qcStatus}
          </a>
          &nbsp;&nbsp;
        </div>
      );
    };
    const renderBillNoGridCell = (data) => {
      return (
        <div>
          {" "}
          {data.data.billNo !== "" ? <div>{data.data.billNo}</div> : "N/A"}
        </div>
      );
    };
    return (
      <Fragment>
        <div className="section-body">
          <div className="card bg-filter">
            <div className="card-body">
              <div className="section-header">
                <div className="mr-3">
                  <div className="row">
                    <div className="col-lg-2 col-md-2 col-sm-12">
                      <div className="form-group">
                        <label htmlFor="">Item</label>
                        <SelectBox
                          dataSource={this.state.itemList}
                          className="basic-multi-select"
                          classNamePrefix="select item"
                          placeholder="Select item"
                          name="id"
                          defaultValue={0}
                          value={this.state.filterItemId}
                          displayExpr="name"
                          valueExpr="id"
                          onSelectionChanged={this.handleFilterItemChange}
                          showClearButton={true}
                          searchEnabled={true}
                        />
                      </div>
                    </div>
                    <div className="col-lg-2 col-md-2 col-sm-12">
                      <div className="form-group">
                        <label htmlFor="">Bill#</label>
                        <SelectBox
                          dataSource={this.state.billList}
                          className="basic-multi-select"
                          classNamePrefix="select bill"
                          placeholder="Select bill"
                          name="name"
                          defaultValue={0}
                          value={this.state.filterBillNo}
                          displayExpr="name"
                          valueExpr="name"
                          onSelectionChanged={this.handleFilterBillChange}
                          showClearButton={true}
                          searchEnabled={true}
                        />
                      </div>
                    </div>
                    {/* <div className="col-lg-2 col-md-3 col-sm-12 col-xs-12">
                                            <div className="form-group">
                                                <label htmlFor="">Invoice#</label>
                                                <input type="text" className="form-control form-control-sm" name="filterBillNo" placeholder="Enter invoice number" value={this.state.filterBillNo}
                                                    title="Invoice No" maxLength="20" onChange={this.handleInputChange} />
                                            </div>
                                        </div> */}
                    <div className="col-lg-2 col-md-3 col-sm-12 col-xs-12">
                      <div className="form-group">
                        <label htmlFor="">Serial#</label>
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          name="filterSerialNo"
                          placeholder="Enter serial number"
                          value={this.state.filterSerialNo}
                          title="Serial No"
                          maxLength="20"
                          onChange={this.handleInputChange}
                        />
                      </div>
                    </div>
                    <div className="col-lg-2 col-md-2 col-sm-12">
                      <div className="form-group">
                        <label htmlFor="">Warehouse</label>
                        <SelectBox
                          dataSource={this.state.warehouseList}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          placeholder="Select warehouse"
                          name="id"
                          defaultValue={0}
                          value={this.state.filterWarehouseId}
                          displayExpr="name"
                          valueExpr="id"
                          onSelectionChanged={this.handleFilterWarehouseChange}
                          showClearButton={true}
                          searchEnabled={true}
                        />
                      </div>
                    </div>
                    {/* <div className="col-lg-2 col-md-3 col-sm-12 col-xs-12">
                                            <div className="form-group">
                                                <label htmlFor="">Installer</label>
                                                <input type="text" className="form-control form-control-sm" name="filterInstaller" placeholder="Enter Serial No" value={this.state.filterInstaller}
                                                    title="Invoice No" maxLength="20" onChange={this.handleInputChange} />
                                            </div>
                                        </div> */}
                    {/* <div className='col-lg-2 col-md-2 col-sm-12'>
                                            <div className="form-group">
                                                <label htmlFor="">Valid Date</label>
                                                <DateBox type="date"
                                                    className="replay-date-time"
                                                    name="filterValidDate"
                                                    placeholder="dd/mm/yyyy"
                                                    displayFormat="dd/MM/yyyy"
                                                    showAnalogClock={true}
                                                    useMaskBehavior={true}
                                                    defaultValue="dd/MM/yyyy"
                                                    value={this.state.filterValidDate}
                                                    onValueChanged={this.handleFilterValidDateChanged}
                                                    width="100%"
                                                    acceptCustomValue={false} />
                                            </div>
                                        </div> */}
                    <div className="col-lg-2 col-md-2 col-sm-12 col-xs-12">
                      <div className="form-group">
                        <label htmlFor="">&nbsp;</label>
                        <br />
                        <button
                          type="submit"
                          className="btn btn-success"
                          onClick={this.handleSearchStock}
                        >
                          Search
                        </button>
                        &nbsp;&nbsp;
                        <button
                          type="submit"
                          className="btn btn-outline-secondary"
                          onClick={this.handleClearSearch}
                        >
                          <span title="Clear">Clear</span>
                        </button>
                        &nbsp;&nbsp;
                        <button
                          type="submit"
                          className="btn btn-success"
                          onClick={this.handlestockModal}
                        >
                          New
                        </button>
                      </div>
                    </div>
                    <div className="col-lg-2 col-md-3 col-sm-3 col-xs-3">
                      <div className="form-group">
                        <div>&nbsp;</div>
                        <input
                          type="file"
                          name="file"
                          onChange={this.handleAttachmentInputChange}
                        />
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                      <div className="form-group">
                        <div>&nbsp;</div>
                        <button
                          type="submit"
                          className="btn btn-success"
                          onClick={this.getAttachment}
                        >
                          <span title="Upload">Upload</span>
                        </button>
                        &nbsp;&nbsp;
                        <button
                          type="button"
                          className="btn btn-success"
                          title="Download Sample Document"
                          onClick={this.downloadSampleDocument}
                        >
                          Download Sample Document
                        </button>
                        &nbsp;&nbsp;
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-body">
              <DataGrid
                dataSource={this.state.stockSource}
                keyExpr="id"
                id="gridStockList"
                selection={{ mode: "single" }}
                columnsAutoWidth={false}
                showColumnLines={true}
                showRowLines={true}
                showBorders={true}
                rowAlternationEnabled={false}
                allowColumnResizing={true}
                columnResizingMode="widget"
                onExporting={this.onExporting}
                onToolbarPreparing={this.onToolbarPreparing}
              >
                <Column
                  dataField="id"
                  width={35}
                  caption=""
                  allowFiltering={false}
                  allowSorting={false}
                  cellRender={renderGridCell}
                />
                <Column
                  dataField="billNo"
                  caption="Bill#"
                  allowSearch={true}
                  allowFiltering={false}
                  allowSorting={true}
                  width={100}
                  cellRender={renderBillNoGridCell}
                />
                {/* <Column dataField="soNo" caption="SO#" allowSearch={true} allowFiltering={false} allowSorting={true} width={100} /> */}
                <Column
                  dataField="itemName"
                  caption="Item Name"
                  allowSearch={true}
                  allowFiltering={false}
                  allowSorting={true}
                  width={130}
                />
                <Column
                  dataField="serialNo"
                  caption="Serial#"
                  allowSearch={true}
                  allowFiltering={false}
                  allowSorting={true}
                  width={180}
                />
                <Column
                  dataField="simNo"
                  caption="SimNo#"
                  allowSearch={true}
                  allowFiltering={false}
                  allowSorting={true}
                  width={180}
                />
                <Column
                  dataField="warehouseName"
                  caption="Warehouse"
                  allowSearch={true}
                  allowFiltering={false}
                  allowSorting={true}
                  width={150}
                />
                <Column
                  dataField="validFrom"
                  caption="Valid From"
                  allowSearch={true}
                  allowFiltering={false}
                  allowSorting={true}
                  width={150}
                  dataType="datetime"
                  format="dd/MM/yyyy"
                />
                <Column
                  dataField="validTo"
                  caption="Valid To"
                  allowSearch={true}
                  allowFiltering={false}
                  allowSorting={true}
                  width={150}
                  dataType="datetime"
                  format="dd/MM/yyyy"
                />
                <Column
                  dataField="qcStatus"
                  caption="QC Status"
                  allowSearch={true}
                  allowFiltering={false}
                  allowSorting={true}
                  width={100}
                  cellRender={renderQcStatusGridCell}
                />
                {/* <Column dataField="qcResult" caption="QC Result" allowSearch={true} allowFiltering={false} allowSorting={true} width={180} /> */}
                <SortByGroupSummaryInfo summaryItem="count" />
                <GroupPanel visible={false} />
                {/* <Template name="toolbarTemplate" /> */}
                <HeaderFilter visible={true} />
                <SearchPanel
                  visible={true}
                  highlightCaseSensitive={true}
                  width={240}
                  placeholder="Search..."
                />
                <Grouping autoExpandAll={true} />
                <Paging defaultPageSize={20} />
                <Pager
                  showPageSizeSelector={true}
                  displayMode="compact"
                  allowedPageSizes={[10, 20, 50]}
                  showInfo={true}
                  visible={true}
                />
                <Export
                  enabled={true}
                  allowExportSelectedData={false}
                  fileName="Stock"
                />
              </DataGrid>
            </div>
          </div>
        </div>

        <Modal
          ariaHideApp={false}
          isOpen={this.state.stockModal}
          style={customStyles}
          className={"react-modal"}
          onRequestClose={this.closeStockModal}
          shouldCloseOnOverlayClick={false}
          contentLabel="Example Modal"
        >
          <form
            method="post"
            onSubmit={this.handleStockSubmit}
            style={
              this.state.isSubmited
                ? { pointerEvents: "none", opacity: "0.4" }
                : {}
            }
          >
            <div className="modal-header">
              <h6 className="modal-title">
                {this.state.id > 0 ? "Update Stock" : "New Stock"}
              </h6>
            </div>
            <div className="modal-body">
              <div className="row">
                {/* <RadioGroup items={addressTypeRadio} defaultValue={addressTypeRadio[0]} layout="horizontal" value={this.state.addressType} onValueChanged={this.changeSelectionAddressType} /> */}
                <div className="col-lg-8">
                  <div className="form-group">
                    <label htmlFor="">Account</label>
                    <SelectBox
                      dataSource={this.state.accountList}
                      className="basic-multi-select"
                      classNamePrefix="select bill"
                      placeholder="Select account"
                      name="name"
                      defaultValue={0}
                      value={this.state.customerId}
                      displayExpr="name"
                      valueExpr="id"
                      onSelectionChanged={this.handleCustomerChange}
                      //disabled={this.state.id > 0}
                      showClearButton={true}
                      searchEnabled={true}
                    />
                  </div>
                </div>
                {this.state.customerId == 1 || this.state.customerId == 0 ? (
                  <div className="col-lg-4">
                    <div className="form-group">
                      <label htmlFor="">Bill#</label>
                      <SelectBox
                        dataSource={this.state.billList}
                        className="basic-multi-select"
                        classNamePrefix="select bill"
                        placeholder="Select bill"
                        name="name"
                        defaultValue={0}
                        value={this.state.billNo}
                        displayExpr="name"
                        valueExpr="name"
                        onSelectionChanged={this.handleBillChange}
                        disabled={this.state.id > 0}
                        showClearButton={true}
                        searchEnabled={true}
                      />
                    </div>
                  </div>
                ) : (
                  ""
                )
                // <div className='col-lg-4'>
                //     <div className="form-group">
                //         <label htmlFor="">SO#</label>
                //         <SelectBox
                //             dataSource={this.state.saleOrderList}
                //             className="basic-multi-select"
                //             classNamePrefix="select bill"
                //             placeholder="Select sales order number"
                //             name="soNo"
                //             defaultValue={0}
                //             value={this.state.soNo}
                //             displayExpr='soNo'
                //             valueExpr='soNo'
                //             onSelectionChanged={this.handleSoNoChange}
                //             //disabled={this.state.id > 0}
                //             showClearButton={true}
                //             searchEnabled={true}
                //         />
                //     </div>
                // </div>
                }

                <div
                  className={
                    this.state.billId != 0
                      ? "col-lg-4 displayBlock"
                      : "displayNone"
                  }
                >
                  <div className="form-group">
                    <label>
                      Item <span className="text-danger">*</span>
                    </label>
                    <SelectBox
                      dataSource={this.state.billItemList}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      placeholder="Select item"
                      name="itemId"
                      defaultValue={0}
                      value={this.state.itemId}
                      displayExpr="itemName"
                      valueExpr="itemId"
                      onSelectionChanged={this.handleBillItemChange}
                      showClearButton={true}
                      disabled={this.state.id > 0}
                      searchEnabled={true}
                    />
                    {/* {this.state.errors["itemId"].length > 0 && <span className="error invalid-feedback">{this.state.errors["itemId"]}</span>} */}
                  </div>
                </div>

                <div
                  className={
                    this.state.billId != 0
                      ? "displayNone"
                      : "col-lg-4 displayBlock"
                  }
                >
                  <div className="form-group">
                    <label>
                      Item <span className="text-danger">*</span>
                    </label>
                    <SelectBox
                      dataSource={this.state.itemList}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      placeholder="Select item"
                      name="id"
                      defaultValue={0}
                      value={this.state.itemId}
                      displayExpr="name"
                      valueExpr="id"
                      onSelectionChanged={this.handleItemChange}
                      showClearButton={true}
                      disabled={this.state.id > 0}
                      searchEnabled={true}
                    />
                    {this.state.errors["itemId"].length > 0 && (
                      <span className="error invalid-feedback">
                        {this.state.errors["itemId"]}
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-lg-4">
                  <div className="form-group">
                    <label>
                      Serial# <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      name="serialNumber"
                      placeholder="Enter serial number"
                      value={this.state.serialNumber}
                      title="Serial#"
                      maxLength="20"
                      onChange={this.handleInputChange}
                    />
                    {this.state.errors["serialNumber"].length > 0 && (
                      <span className="error invalid-feedback">
                        {this.state.errors["serialNumber"]}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="form-group">
                    <label>
                      SimNo# <span className="text-danger">*</span>
                    </label>
                    <TextBox
                      height={30}
                      className="form-control form-control-sm"
                      name="simNo"
                      placeholder="Enter SimNo"
                      //value={this.state.activeDevice}
                      onValueChanged={this.handleTextAreaValueChanged}
                    />
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="form-group">
                    <label>Warehouse</label>
                    <SelectBox
                      dataSource={this.state.warehouseList}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      placeholder="Select warehouse"
                      name="id"
                      defaultValue={0}
                      value={this.state.warehouseId}
                      displayExpr="name"
                      valueExpr="id"
                      onSelectionChanged={this.handleWarehouseChange}
                      showClearButton={true}
                      disabled={false}
                      searchEnabled={true}
                    />
                    {/* {this.state.errors["warehouseId"].length > 0 && <span className="error invalid-feedback">{this.state.errors["warehouseId"]}</span>} */}
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="form-group">
                    <label htmlFor="">Valid From</label>
                    <DateBox
                      type="date"
                      className="replay-date-time"
                      name="orderDate"
                      placeholder="dd/mm/yyyy"
                      displayFormat="dd/MM/yyyy"
                      showAnalogClock={true}
                      useMaskBehavior={true}
                      defaultValue={this.state.validFrom}
                      value={this.state.validFrom}
                      onValueChanged={this.handleValidFromDateChanged}
                      width="100%"
                      min={this.state.min}
                      // disabled={this.state.id > 0}
                      acceptCustomValue={false}
                    />
                    {/* {this.state.errors["validFrom"].length > 0 && <span className="error invalid-feedback">{this.state.errors["validFrom"]}</span>} */}
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="form-group">
                    <label htmlFor="">Valid To</label>
                    <DateBox
                      type="date"
                      className="replay-date-time"
                      name="shippingDate"
                      placeholder="dd/mm/yyyy"
                      displayFormat="dd/MM/yyyy"
                      showAnalogClock={true}
                      useMaskBehavior={true}
                      defaultValue={this.state.validTo}
                      value={this.state.validTo}
                      onValueChanged={this.handleValidToDateChanged}
                      width="100%"
                      // disabled={this.state.id > 0}
                      acceptCustomValue={false}
                    />
                    {/* {this.state.errors["validTo"].length > 0 && <span className="error invalid-feedback">{this.state.errors["validTo"]}</span>} */}
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="form-group">
                    <label>
                      Installer Name <span className="text-danger"></span>
                    </label>
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      name="installerName"
                      placeholder="Enter installer name"
                      value={this.state.installerName}
                      title="Installer Name"
                      maxLength="20"
                      onChange={this.handleInputChange}
                    />
                    {this.state.errors["installerName"].length > 0 && (
                      <span className="error invalid-feedback">
                        {this.state.errors["installerName"]}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="submit"
                className="btn btn-success"
                onClick={this.handleStockSubmit}
              >
                {this.state.isSubmited && (
                  <span title="In-process">
                    <i className="fas fa-spinner fa-spin"></i> In-process
                  </span>
                )}
                {!this.state.isSubmited && <span title="Submit">Submit</span>}
              </button>
              <button
                type="button"
                className="btn btn-outline-secondary"
                onClick={this.closeStockModal}
                title="Cancel"
              >
                Cancel
              </button>
            </div>
          </form>
        </Modal>
        <LoadPanel
          shadingColor="rgba(0,0,0,0.4)"
          position={position}
          visible={this.state.loadPanelVisible}
          showIndicator={true}
          shading={true}
          showPane={true}
          hideOnOutsideClick={false}
        />
      </Fragment>
    );
  }
}
