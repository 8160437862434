import React, { Fragment, Component } from 'react';
import DataGrid, {
    Column,
    Export, Grouping,
    GroupPanel,
    HeaderFilter, Pager,
    Paging,
    SearchPanel,
    SortByGroupSummaryInfo,
    Selection,
    Editing,
    RequiredRule,
    PatternRule
} from 'devextreme-react/data-grid'; //npm i devextreme-react
import { alphanumericValidation, floatValidation } from '../../helpers/globalvalidations.js';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import Modal from 'react-modal'; //npm install --save react-modal
import Swal from 'sweetalert2'; //npm i sweetalert2 --save
import { toast } from 'react-toastify'; //npm i react-toastify --save
import { getRequestParams, formatDate } from '../../helpers/globalfunctions.js';
import { DateFormat, exportFormats, precisionFormats,currencySymbolFormats, symbolFormats } from '../../helpers/fixcodes.js';
import { PatternFormat } from 'react-number-format';
import { SelectBox } from 'devextreme-react/select-box';
import DateBox from 'devextreme-react/date-box';

const customStyles = {
    content: {
        width: '60%',
    }
}

export class EditCustomerVendorComponent extends Component {
    static displayName = EditCustomerVendorComponent.name;
    constructor(props) {
        super(props);
        this.state = {
            isSubmited: false,
            loadPanelVisible: false,
            customername: this.props.location.state.data === undefined ? "" : this.props.location.state.data.name,
            phone: this.props.location.state.data === undefined ? "" : this.props.location.state.data.phone1,
            email: this.props.location.state.data === undefined ? "" : this.props.location.state.data.email,
            website: this.props.location.state.data === undefined ? "" : this.props.location.state.data.website,
            GSTNo: this.props.location.state.data === undefined ? "" : this.props.location.state.data.gstNo,
            panNo: this.props.location.state.data === undefined ? "" : this.props.location.state.data.panNo,
            contactPerson: this.props.location.state.data === undefined ? "" : this.props.location.state.data.contactPerson,
            billingAddressLine1: this.props.location.state.data === undefined ? "" : this.props.location.state.data.billingAddress1,
            billingAddressLine2: this.props.location.state.data === undefined ? "" : this.props.location.state.data.billingAddress2,
            billingCity: this.props.location.state.data === undefined ? "" : this.props.location.state.data.billingCity,
            billingState: this.props.location.state.data === undefined ? "" : this.props.location.state.data.billingState,
            billingZipCode: this.props.location.state.data === undefined ? "" : this.props.location.state.data.billingPostalCode,
            shippingAddressLine1: this.props.location.state.data === undefined ? "" : this.props.location.state.data.shippingAddress1,
            shippingAddressLine2: this.props.location.state.data === undefined ? "" : this.props.location.state.data.shippingAddress2,
            shippingCity: this.props.location.state.data === undefined ? "" : this.props.location.state.data.shippingCity,
            shippingState: this.props.location.state.data === undefined ? "" : this.props.location.state.data.shippingState,
            shippingZipCode: this.props.location.state.data === undefined ? "" : this.props.location.state.data.shippingPostalCode,
            firstName: this.props.location.state.data === undefined ? "" : this.props.location.state.data.firstName,
            lastName: this.props.location.state.data === undefined ? "" : this.props.location.state.data.lastName,
            userName: "",
            password: "",
            accountTypeName: this.props.location.state.data === undefined ? "" : this.props.location.state.AccountTypeName,
            Id: this.props.location.state.data === undefined ? 0 : this.props.location.state.data.id,
            sourceUrl: this.props.location.state.sourceUrl,
            userId: this.props.location.state.data === undefined ? "" : this.props.location.state.data.userId,
            sessionUser: JSON.parse(sessionStorage.getItem("sessionUser")),
            imageList: [],
            pdfList: [],
            excelList: [],
            otherList: [],
            documentType: this.props.location.state.data === undefined ? "" : this.props.location.state.documentTypeId,
            customerContractList: [],
            contractConfigurationModal: false,
            customerContractId: 0,
            startDate: new Date(),
            endDate: new Date(),
            contractTypeId: 0,
            contractType:[],
            contractStatusId: 0,
            contractStatus:[],
            paymentTermTypeId: 0,
            paymentTermType:[],
            servicesource: [],
            contractSoNo: "",
            selectedItemKeys :[],
            selectedRowsData:[],
            errors: {
                customername: "",
                phone: "",
                email: "",
                website: "",
                GSTNo: "",
                panNo: "",
                contactPerson: "",
                billingAddressLine1: "",
                billingCity: "",
                billingState: "",
                billingZipCode: "",
                shippingAddressLine1: "",
                shippingCity: "",
                shippingState: "",
                shippingZipCode: "",
                firstName: "",
                lastName: "",
                userName: "",
                password: "",
                contractTypeId: 0,
                contractStatusId: 0,
                paymentTermTypeId: 0,
            }
        }
    }
    async componentDidMount() {
        this.setState({
            loadPanelVisible: true
        });
        await this.getContractList();
       this.setState({
            loadPanelVisible: false
        });
    }
    getContractList = async () => {
        try {
            var request = {};
            request.CustomerId = this.state.Id;
            // request.ContractTypeId = this.state.filterConstractTypeId;
            // request.ContractStatusId = this.state.filterContractStatusId;
            // request.PaymentTermsId = this.state.filterPaymentTermTypeId;
            // request.ContractStart = this.state.filterStartDate === "" ? "" : formatDate(this.state.filterStartDate, "yyyy-MM-dd");
            // request.ContractEnd = this.state.filterEndDate === "" ? "" : formatDate(this.state.filterEndDate, "yyyy-MM-dd")
            const requestParams = getRequestParams("POST", request);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'contractConfiguration/contractConfiguration', requestParams);            
            const data = await response.json();            
            this.setState({
                customerContractList: data.payload != null ? data.payload : []
                // ,contractTypeId: data.payload !== null ? data.payload[0].contractTypeId : 0
                // ,contractStatusId: data.payload !== null ? data.payload[0].contractStatusId : 0
                // ,paymentTermTypeId: data.payload !== null ? data.payload[0].paymentTermsId : 0
                // ,startDate: data.payload !== null ? formatDate(data.payload[0].contractStart, "MM/dd/yyyy hh:mm") : ""
                // ,endDate: data.payload !== null ? formatDate(data.payload[0].contractEnd, "MM/dd/yyyy hh:mm") : ""
            });
        } catch (err) {
            console.log("Error in " + this.displayName + ".getContractList function", err);
        }
    }
    getContractType = async () => {
        try {
          var request = {};
            const requestParams = getRequestParams("GET", request);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'contractConfiguration/contractType/0', requestParams);
           const data = await response.json();
           
            this.setState({
                contractType: data.payload
            });
        } catch (err) {
            console.log("Error in " + this.displayName + ".getContractType function", err);
        }
    }

    getContractStatus = async () => {
        try { 
            var request = {};
            const requestParams = getRequestParams("GET", request);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'contractConfiguration/contractStatus/0', requestParams);
            const data = await response.json();
            
          this.setState({
                contractStatus: data.payload
            });
        } catch (err) {
            console.log("Error in " + this.displayName + ".getContractStatus function", err);
        }
    }

    getPaymentTermType = async () => {
        try {
          var request = {};
            const requestParams = getRequestParams("GET", request);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'paymentTermType/dropdownType', requestParams);
            const data = await response.json();
            
           this.setState({
                paymentTermType: data.payload
            });
        } catch (err) {
            console.log("Error in " + this.displayName + ".getPaymentTermType function", err);
        }
    }
    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        let errors = this.state.errors;

        const numeric = /^[]?\d*(?:[]\d*)?$/;

        switch (name) {
            case 'customername':
                if (alphanumericValidation.test(event.target.value))
                    this.setState({ customername: event.target.value });
                break;
            case 'phone':
                if (numeric.test(event.target.value))
                    this.setState({ phone: event.target.value });
                break;
            case 'email':
                if (alphanumericValidation.test(event.target.value))
                    this.setState({ email: event.target.value });
                break;
            case 'website':
                if (alphanumericValidation.test(event.target.value))
                    this.setState({ website: event.target.value });
                break;
            case 'GSTNo':
                if (alphanumericValidation.test(event.target.value))
                    this.setState({ GSTNo: event.target.value });
                break;
            case 'panNo':
                if (alphanumericValidation.test(event.target.value))
                    this.setState({ panNo: event.target.value });
                break;
            case 'contactPerson':
                if (alphanumericValidation.test(event.target.value))
                    this.setState({ contactPerson: event.target.value });
                break;
            case 'billingAddressLine1':
                if (alphanumericValidation.test(event.target.value))
                    this.setState({ billingAddressLine1: event.target.value });
                break;
            case 'billingAddressLine2':
                if (alphanumericValidation.test(event.target.value))
                    this.setState({ billingAddressLine2: event.target.value });
                break;
            case 'billingCity':
                if (alphanumericValidation.test(event.target.value))
                    this.setState({ billingCity: event.target.value });
                break;
            case 'billingState':
                if (alphanumericValidation.test(event.target.value))
                    this.setState({ billingState: event.target.value });
                break;
            case 'billingZipCode':
                if (numeric.test(event.target.value))
                    this.setState({ billingZipCode: event.target.value });
                break;
            case 'shippingAddressLine1':
                if (alphanumericValidation.test(event.target.value))
                    this.setState({ shippingAddressLine1: event.target.value });
                break;
            case 'shippingAddressLine2':
                if (alphanumericValidation.test(event.target.value))
                    this.setState({ shippingAddressLine2: event.target.value });
                break;
            case 'shippingCity':
                if (alphanumericValidation.test(event.target.value))
                    this.setState({ shippingCity: event.target.value });
                break;
            case 'shippingState':
                if (alphanumericValidation.test(event.target.value))
                    this.setState({ shippingState: event.target.value });
                break;
            case 'shippingZipCode':
                if (numeric.test(event.target.value))
                    this.setState({ shippingZipCode: event.target.value });
                break;
            case 'firstName':
                if (alphanumericValidation.test(event.target.value))
                    this.setState({ firstName: event.target.value });
                break;
            case 'lastName':
                if (alphanumericValidation.test(event.target.value))
                    this.setState({ lastName: event.target.value });
                break;
            case 'userName':
                if (alphanumericValidation.test(event.target.value))
                    this.setState({ userName: event.target.value });
                break;
            case 'password':
                if (alphanumericValidation.test(event.target.value))
                    this.setState({ password: event.target.value });
                break;
            default:
                this.setState({ [name]: value });
                break;
        }
        errors[name] = "";

        this.setState({
            [name]: value,
            errors: errors
        });
    }

    handleValidation = () => {
        let formIsValid = true;
        try {
            let errors = this.state.errors;
            errors.customername = "";
            errors.phone = "";
            errors.email = "";
            errors.website = "";
            errors.GSTNo = "";
            errors.panNo = "";
            errors.contactPerson = "";
            errors.billingAddressLine1 = "";
            errors.billingCity = "";
            errors.billingState = "";
            errors.billingZipCode = "";
            errors.shippingAddressLine1 = "";
            errors.shippingCity = "";
            errors.shippingState = "";
            errors.shippingZipCode = "";
            errors.firstName = "";
            errors.lastName = "";
            errors.userName = "";
            errors.password = "";
            if (this.state.customername.trim() === "") {
                formIsValid = false;
                errors.customername = "Please enter name.";
            }
            if (this.state.email === null || this.state.email.trim() === '') {
                formIsValid = false;
                errors.email = "Please enter email.";
            } else if (this.state.email !== '') {
                let regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                if (!regEmail.test(this.state.email)) {
                    formIsValid = false;
                    errors.email = "Please enter valid email.";
                }
            }
            if (this.state.phone === null || this.state.phone.trim() === "") {
                formIsValid = false;
                errors.phone = "Please enter mobile.";
            } else if (this.state.phone.trim().replace("(", "").replace(")", "").replace("-", "").replaceAll(" ", "").length !== 10) {
                formIsValid = false;
                errors.phone = "Invalid mobile number.";
            }
            if (this.state.website.trim() === "") {
                formIsValid = false;
                errors.website = "Please enter website.";
            }
            if (this.state.GSTNo.trim() === "") {
                formIsValid = false;
                errors.GSTNo = "Please enter GST number.";
            }
            if (this.state.panNo.trim() === "") {
                formIsValid = false;
                errors.panNo = "Please enter PAN number.";
            }
            if (this.state.contactPerson.trim() === "") {
                formIsValid = false;
                errors.contactPerson = "Please enter contact person.";
            }
            if (this.state.billingAddressLine1.trim() === "") {
                formIsValid = false;
                errors.billingAddressLine1 = "Please enter billing address.";
            }
            if (this.state.billingCity.trim() === "") {
                formIsValid = false;
                errors.billingCity = "Please enter city.";
            }
            if (this.state.billingState.trim() === "") {
                formIsValid = false;
                errors.billingState = "Please enter state.";
            }
            if (this.state.billingZipCode.trim() === "") {
                formIsValid = false;
                errors.billingZipCode = "Please enter postal code.";
            }
            if (this.state.shippingAddressLine1.trim() === "") {
                formIsValid = false;
                errors.shippingAddressLine1 = "Please enter shipping address.";
            }
            if (this.state.shippingCity.trim() === "") {
                formIsValid = false;
                errors.shippingCity = "Please enter city.";
            }
            if (this.state.shippingState.trim() === "") {
                formIsValid = false;
                errors.shippingState = "Please enter state.";
            }
            if (this.state.shippingZipCode.trim() === "") {
                formIsValid = false;
                errors.shippingZipCode = "Please enter postal code.";
            }
            // if (this.state.userName.trim() === "") {
            //     formIsValid = false;
            //     errors.userName = "Please enter User Name.";
            // }
            // if (this.state.firstName.trim() === "") {
            //     formIsValid = false;
            //     errors.firstName = "Please enter First Name.";
            // }
            // if (this.state.lastName.trim() === "") {
            //     formIsValid = false;
            //     errors.lastName = "Please enter Last Name.";
            // }
            // if (this.state.password.trim() != '' && vIsPasswordComplexity == true) {
            //     const renumbersymbolexp = /[^0-9]/g;
            //     const lowercaseexp = /[^a-z]/g;
            //     const uppercaseexp = /[^A-Z]/g;
            //     const specialcharacters = /[^!@#$%^&*]/g;

            //     var IsPwdnumbersymbol = this.state.password.replace(renumbersymbolexp, "").length;
            //     var IsLowercase = this.state.password.replace(lowercaseexp, "").length;
            //     var IsUppercase = this.state.password.replace(uppercaseexp, "").length;
            //     var IsSpecialCharacters = this.state.password.replace(specialcharacters, "").length;

            //     if (this.state.password.length < vMinLength) {
            //         formIsValid = false;
            //         errors.password = "Password must be " + vMinLength + " characters long";
            //     }
            //     else if (IsPwdnumbersymbol < vMinNumber) {
            //         formIsValid = false;
            //         errors.password = "Password must contains atleast " + vMinNumber + " number";
            //     }
            //     else if (IsLowercase < vMinLowerCaseLetter) {
            //         formIsValid = false;
            //         errors.password = "Password must contains atleast " + vMinLowerCaseLetter + " lowercase alphabet";
            //     }
            //     else if (IsUppercase < vMinUpperCaseLetter) {
            //         formIsValid = false;
            //         errors.password = "Password must contains atleast " + vMinUpperCaseLetter + " uppercase alphabet";
            //     }
            //     else if (IsSpecialCharacters < vMinSpecialCharacter) {
            //         formIsValid = false;
            //         errors.password = "Password must contains atleast " + vMinSpecialCharacter + " special character";
            //     }
            // }
            this.setState({ errors: errors });
        }
        catch (err) {
            console.log("Error in " + this.displayName + ".handleValidation function", err);
            this.setState({ isSubmited: false })
        }
        return formIsValid;
    }
    handleContractConfiguration =  async(e, data) => {
        e.preventDefault();
     
         this.setState({
            contractConfigurationModal: true,
            customerContractId: data.id,
            contractSoNo: data.soNo,
            contractTypeId: data.contractTypeId,
            contractStatusId : data.contractStatusId,
            paymentTermTypeId: data.paymentTermsId,
            startDate : data.contractStart,
            endDate : data.contractEnd
        });
        console.log(this.state.customername);
       setTimeout(() => this.getCustomerContractService(), 500);
        setTimeout(() => this.getContractType(), 500);
        setTimeout(() => this.getContractStatus(), 500);
       setTimeout(() => this.getPaymentTermType(), 500);
        
    }

    closeContractConfigurationModal = () => {
        this.setState({
            contractConfigurationModal: false,
            contractTypeId: 0,
            contractStatusId: 0,
            paymentTermTypeId: 0,
            contractSoNo: "",
            startDate: new Date(),
            endDate: new Date(),
            // selectedItemKeys :[],
            // selectedRowsData:[],
            // servicesource :[],
            customerContractId :0,
            // errors:{
            //     contractTypeId: 0,
            //     contractStatusId: 0,
            //     paymentTermTypeId: 0,
            // }
        });
    }

    getCustomerContractService = async () => {
        try {
            var request = {};
            request.Id = this.state.customerContractId;
            const requestParams = getRequestParams("POST", request);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'contractConfiguration/customerContractService', requestParams);
            const data = await response.json();
            
            this.setState({
                servicesource: data.payload !== null ? data.payload : []
            });
         setTimeout(() => this.setContractDetails(), 500);
        } catch (err) {
            console.log("Error in " + this.displayName + ".getCustomerContractService function", err);
        }
    }

    setContractDetails = (e) => {
        
        let selectedRowKeys = [];
        let selectedRows = this.state.servicesource.filter((m) => { return m.id > 0; });
        for (let index = 0; index < selectedRows.length; index++) {
            selectedRowKeys.push(selectedRows[index].rowIndex);
        }

        this.setState({
            // contractTypeId: this.state.servicesource !== null ? this.state.servicesource[0].contractTypeId : 0
            // ,contractStatusId: this.state.servicesource !== null ? this.state.servicesource[0].contractStatusId : 0
            // ,paymentTermTypeId: this.state.servicesource !== null ? this.state.servicesource[0].paymentTermsId : 0
            // ,startDate: this.state.servicesource !== null ? formatDate(this.state.servicesource[0].contractStart, "MM/dd/yyyy hh:mm") : ""
            // ,endDate: this.state.servicesource !== null ? formatDate(this.state.servicesource[0].contractEnd, "MM/dd/yyyy hh:mm") : ""
            selectedItemKeys: selectedRowKeys
        });
        console.log(this.state.selectedItemKeys);                                                                                                                                                                           
    }

    handleAccountSubmit = async (e) => {
        e.preventDefault();
        try {
            if (this.handleValidation()) {
                this.setState({ isSubmited: true });
                var newObject = {};
                newObject.Id = this.state.Id;
                newObject.name = this.state.customername.trim();
                newObject.Email = this.state.email.trim();
                newObject.Phone1 = this.state.phone;
                newObject.Website = this.state.website;
                newObject.GSTNo = this.state.GSTNo;
                newObject.PANNo = this.state.panNo;
                newObject.ContactPerson = this.state.contactPerson;
                newObject.ShippingAddress1 = this.state.shippingAddressLine1;
                newObject.ShippingAddress2 = this.state.shippingAddressLine2;
                newObject.ShippingCity = this.state.shippingCity;
                newObject.ShippingState = this.state.shippingState;
                newObject.ShippingPostalCode = this.state.shippingZipCode;
                newObject.BillingAddress1 = this.state.billingAddressLine1;
                newObject.BillingAddress2 = this.state.billingAddressLine2;
                newObject.BillingCity = this.state.billingCity;
                newObject.BillingState = this.state.billingState;
                newObject.BillingPostalCode = this.state.billingZipCode;
                newObject.UserName = this.state.userName;
                newObject.Password = this.state.password;
                newObject.FirstName = this.state.firstName;
                newObject.LastName = this.state.lastName;
                newObject.accountTypeName = this.state.accountTypeName;
                newObject.UserId = this.state.userId;
                newObject.CreatedBy = this.state.sessionUser.id;
                newObject.CreatedOn = new Date();
                newObject.UpdatedBy = this.state.sessionUser.id;
                newObject.UpdatedOn = new Date();

                var contractConfigurationRequest = {};
                contractConfigurationRequest = {};
                contractConfigurationRequest.Id = parseInt(this.state.customerContractId);
                contractConfigurationRequest.CustomerId = parseInt(0);
                contractConfigurationRequest.ContractTypeId = parseInt(this.state.contractTypeId);
                contractConfigurationRequest.ContractStart = formatDate(this.state.startDate, "MM/dd/yyyy hh:mm");
                contractConfigurationRequest.ContractEnd = formatDate(this.state.endDate, "MM/dd/yyyy hh:mm");
                contractConfigurationRequest.ContractStatusId = parseInt(this.state.contractStatusId);
                contractConfigurationRequest.Comments = "";
                contractConfigurationRequest.PaymentTermsId = parseInt(this.state.paymentTermTypeId);
                newObject.ContractConfigurationRequest = {};
                newObject.ContractConfigurationRequest = contractConfigurationRequest;

                var contractServicesItemList = [];

                if (this.state.selectedRowsData !== undefined) {
                    this.state.selectedRowsData.forEach((item, index) => {
                        var contractServicesItem = {};
                        contractServicesItem.Id = parseInt(item.id);
                        contractServicesItem.ItemName = item.name;
                        contractServicesItem.ServiceItemId = parseInt(item.serviceItemId);
                        contractServicesItem.BasedOnUnitId = parseInt(item.basedOnUnitId);
                        contractServicesItem.BasedOnUnit = item.basedOnUnit;
                        contractServicesItem.TermTypeId = parseInt(item.termTypeId);
                        contractServicesItem.TermType = item.termType;
                        contractServicesItem.UnitPrice = parseFloat(item.unitPrice);
                        contractServicesItem.SalePrice = parseFloat(item.salePrice);
                        contractServicesItem.Limit = item.limit;
                        contractServicesItem.Description = "";
                        contractServicesItem.WefDate = formatDate(new Date(), "MM/dd/yyyy hh:mm");
                        contractServicesItemList.push(contractServicesItem);
                    });
                }
                newObject.CustomerContractService = {};
                newObject.CustomerContractService = contractServicesItemList;

                const requestParams = getRequestParams('POST', newObject);
                const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'account/Update', requestParams);
                const data = await response.json();
                if (data.isSuccess) {
                    Swal.fire({
                        icon: 'success',
                        title: 'Success',
                        text: data.message,
                    }).then((result) => {
                        if (result.isConfirmed) {
                            window.location.href = this.state.sourceUrl;
                        }
                        this.setState({ isSubmited: false })
                    })
                }
                else {
                    toast.error(data.message, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        style: { width: "310px" },
                        hideProgressBar: false,
                        newestOnTop: false,
                        closeButton: false
                    });
                    this.setState({ isSubmited: false })
                }
            }
        } catch (err) {
            console.log("Error in " + this.displayName + ".handleAccountSubmit function", err);
            this.setState({ isSubmited: false })
        }
    }

    handleCustomerContractSubmit = async (e) => {
        e.preventDefault();
        if (this.handleContractValidation()) {
            this.setState({
                contractConfigurationModal: false,
            });
        }
    }

    handleContractValidation = () => {
        let formIsValid = true;
        try {
            let errors = this.state.errors;
            errors.contractTypeId = 0;
            errors.contractStatusId = 0;
            errors.paymentTermTypeId = 0;
            if (this.state.contractTypeId === 0) {
                formIsValid = false;
                errors.contractTypeId = "Please select contract type.";
            }
            if (this.state.contractStatusId === 0) {
                formIsValid = false;
                errors.contractStatusId = "Please select contract status.";
            }
            if (this.state.paymentTermTypeId === 0) {
                formIsValid = false;
                errors.paymentTermTypeId = "Please select payment term.";
            }
            this.setState({ errors: errors });
        }
        catch (err) {
            console.log("Error in " + this.displayName + ".handleValidation function", err);
            this.setState({ isSubmited: false })
        }
        return formIsValid;
    }


    closeModal = () => {
        this.setState({
            isSubmited: false,
            loadPanelVisible: false,
            customername: "",
            phone: "",
            email: "",
            website: "",
            GSTNo: "",
            panNo: "",
            contactPerson: "",
            billingAddressLine1: "",
            billingAddressLine2: "",
            billingCity: "",
            billingState: "",
            billingZipCode: "",
            shippingAddressLine1: "",
            shippingAddressLine2: "",
            shippingCity: "",
            shippingState: "",
            shippingZipCode: "",
            firstName: "",
            lastName: "",
            userName: "",
            password: "",
            errors: {
                customername: "",
                phone: "",
                email: "",
                website: "",
                GSTNo: "",
                panNo: "",
                contactPerson: "",
                billingAddressLine1: "",
                billingCity: "",
                billingState: "",
                billingZipCode: "",
                shippingAddressLine1: "",
                shippingCity: "",
                shippingState: "",
                shippingZipCode: "",
                firstName: "",
                lastName: "",
                userName: "",
                password: "",
            }

        });
    }
    copyBillingAddress = () => {
        this.setState({
            shippingAddressLine1: this.state.billingAddressLine1,
            shippingAddressLine2: this.state.billingAddressLine2,
            shippingCity: this.state.billingCity,
            shippingState: this.state.billingState,
            shippingZipCode: this.state.billingZipCode
        })
    }
    handleCancel = () => {
        window.location.href = this.state.sourceUrl;
    }
    openDocumentModel = () => {
        this.setState({
            documentModelIsOpen: true
        });
        this.getDocuments();
    }
    closeDocumentModel = () => {
        this.setState({
            documentModelIsOpen: false
        });
    }
    getDocuments = async () => {
        try {
            this.setState({
                loadPanelVisible: true
            });
            const requestParams = getRequestParams('GET', {});
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'file/' + this.state.documentType + '/' + this.state.Id + '/documents', requestParams);
            const data = await response.json();
            var imageListObj = [];
            var pdfListObj = [];
            var excelListObj = [];
            var otherListObj = [];
            if (data.documents != null && data.documents.length > 0) {
                for (var i = 0; i < data.documents.length; i++) {
                    if (data.documents[i].fileGuid !== '00000000-0000-0000-0000-000000000000') {
                        var img = {};
                        const requestParamsImage = getRequestParams('GET', '');
                        const responseImage = await fetch(process.env.REACT_APP_API_BASE_URL + 'file/download/' + data.documents[i].fileGuid, requestParamsImage);
                        const dataImage = await responseImage.json();

                        if (dataImage.fileExtension.toUpperCase() === '.PDF') {
                            img.src = 'data:' + dataImage.mimeType + ';base64,' + dataImage.fileContent;
                            img.fileGuid = data.documents[i].fileGuid;
                            img.fileName = dataImage.fileName;
                            pdfListObj.push(img);
                        } else if (dataImage.fileExtension.toUpperCase() === '.XLXS' || dataImage.fileExtension.toUpperCase() === '.CSV' || dataImage.fileExtension.toUpperCase() === '.XLS') {
                            img.src = 'data:' + dataImage.mimeType + ';base64,' + dataImage.fileContent;
                            img.fileGuid = data.documents[i].fileGuid;
                            img.fileName = dataImage.fileName;
                            excelListObj.push(img);
                        } else if (dataImage.fileExtension.toUpperCase() === '.JPG' || dataImage.fileExtension.toUpperCase() === '.PNG' || dataImage.fileExtension.toUpperCase() === '.JPEG') {
                            img.src = 'data:' + dataImage.mimeType + ';base64,' + dataImage.fileContent;
                            img.fileGuid = data.documents[i].fileGuid;
                            imageListObj.push(img);
                        } else {
                            img.src = 'data:' + dataImage.mimeType + ';base64,' + dataImage.fileContent;
                            img.fileGuid = data.documents[i].fileGuid;
                            img.fileName = dataImage.fileName;
                            otherListObj.push(img);
                        }
                    }
                }
            }

            this.setState({
                loadPanelVisible: false,
                imageList: imageListObj,
                pdfList: pdfListObj,
                excelList: excelListObj,
                otherList: otherListObj
            });
        } catch (e) {
            console.log(e);
        }
    }

    handleStartDateChanged = (date) => {
        this.setState({
            startDate: date.value
            , endDate: date.value
        });
    }
    handleEndDateChanged = (date) => {
        this.setState({ endDate: date.value });
    }
    handleContractStatusChange = (selectedOption) => {
        this.setState({
            contractStatusId: (selectedOption.selectedItem !== null ? selectedOption.selectedItem.id : 0),
        });
        // if (selectedOption.selectedItem !== null && selectedOption.selectedItem.id > 0) {
        //     //this.state.errors["contractStatusId"] = "";
        //     this.setState({
        //         errors:{
        //             "contractStatusId":""
        //         }
        //     });
        // }
    }
    handleContractTypeChange = (selectedOption) => {
        this.setState({
            contractTypeId: (selectedOption.selectedItem !== null ? selectedOption.selectedItem.id : 0),
        });
        // if (selectedOption.selectedItem !== null && selectedOption.selectedItem.id > 0) {
        //     //this.state.errors["contractTypeId"] = "";
        //     this.setState({
        //         errors: {
        //             "contractTypeId": ""
        //         }
        //     });
        // }
    }
    handlePaymentTermTypeChange = (selectedOption) => {
        this.setState({
            paymentTermTypeId: (selectedOption.selectedItem !== null ? selectedOption.selectedItem.id : 0),
        });
        // if (selectedOption.selectedItem !== null && selectedOption.selectedItem.id > 0) {
        //     //this.state.errors["paymentTermTypeId"] = "";
        //     this.setState({
        //         errors: {
        //             "paymentTermTypeId": ""
        //         }
        //     });
        // }
    }
    handleServiceChanged = (data) => {
        this.setState({
            selectedItemKeys: data.selectedRowKeys,
            selectedRowsData: data.selectedRowsData,
        });
    }

    render() {
        const renderGridCell = (data) => {
            return <div>
                <a href="/#" className="fas fa-pen" title="Edit" onClick={(e) => { this.handleContractConfiguration(e, data.data) }} >&nbsp;</a>
                {/* <a href="/#" className="fas fa-file-alt" title="Document(s)" onClick={(e) => { this.handleOnViewUploadDocument(e, data.data) }} ></a> */}
            </div>;
        }

        const renderGridNumberCell = (data) => {
            return <div><small><i className={currencySymbolFormats.currencyFormat}></i></small>&nbsp;&nbsp;{parseFloat(data.value !== undefined ? data.value : 0.00).toFixed(precisionFormats[2])}</div>;
        }

        return (
            <Fragment>
                <div className="section-body">
                    <div className='card bg-filter'>
                        <div className='card-body'>
                            <div className="section-header">
                                <h6 className='m-0'>Update {this.state.accountTypeName}</h6>
                                <div className="section-header-buttons">
                                    <button type="submit" className="btn btn-success" onClick={this.handleAccountSubmit} >
                                        {this.state.isSubmited && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                        {!this.state.isSubmited && <span title="Save">Save</span>}
                                    </button>&nbsp;&nbsp;
                                    <button type="submit" className="btn btn-outline-secondary" onClick={this.handleCancel}>
                                        <span title="Cancel">Cancel</span>
                                    </button>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                    <div className='card'>
                       <div className='card-body'>
                         {/* <div className='sub-title-color font-weight-bold'>Personal Info</div> */}
                         <div className='row'>
                                <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <label htmlFor="">Name <span className="text-danger">*</span></label>
                                        <input type="text" className="form-control form-control-sm" name="customername" value={this.state.customername} placeholder="e.g. John Dave"
                                            maxLength="200" title="Name" onChange={this.handleInputChange} />
                                        {this.state.errors["customername"].length > 0 && <span className="error invalid-feedback">{this.state.errors["customername"]}</span>}
                                    </div>
                                </div>

                                <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <label>Phone <span className="text-danger">*</span></label>
                                        <PatternFormat className="form-control form-control-sm" format="### #### ###" name="phone" placeholder="Enter phone" value={this.state.phone}
                                            title="Contact No" maxLength="50" onChange={this.handleInputChange} />
                                        {this.state.errors["phone"].length > 0 && <span className="error invalid-feedback">{this.state.errors["phone"]}</span>}
                                    </div>
                                </div>

                                <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <label htmlFor="">Email <span className="text-danger">*</span></label>
                                        <input type="text" className="form-control form-control-sm" name="email" value={this.state.email} placeholder="e.g. user@domain.com" maxLength="50" title="Email" onChange={this.handleInputChange} />
                                        {this.state.errors["email"].length > 0 && <span className="error invalid-feedback">{this.state.errors["email"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <label htmlFor="">Website <span className="text-danger">*</span></label>
                                        <input type="text" className="form-control form-control-sm" name="website" value={this.state.website} placeholder="e.g. www.example.com" maxLength="50" title="Website" onChange={this.handleInputChange} />
                                        {this.state.errors["website"].length > 0 && <span className="error invalid-feedback">{this.state.errors["website"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <label htmlFor="">GST Number<span className="text-danger">*</span></label>
                                        <input type="text" className="form-control form-control-sm" name="GSTNo" value={this.state.GSTNo} placeholder="e.g. 01GSTGS1234F0A1" maxLength="20" title="GST Number" onChange={this.handleInputChange} />
                                        {this.state.errors["GSTNo"].length > 0 && <span className="error invalid-feedback">{this.state.errors["GSTNo"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <label htmlFor="">PAN Number <span className="text-danger">*</span></label>
                                        <input type="text" className="form-control form-control-sm" name="panNo" value={this.state.panNo} placeholder="e.g. AAAAA0000A" maxLength="10" title="PAN Number" onChange={this.handleInputChange} />
                                        {this.state.errors["panNo"].length > 0 && <span className="error invalid-feedback">{this.state.errors["panNo"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <label htmlFor="">Contact Person <span className="text-danger">*</span></label>
                                        <input type="text" className="form-control form-control-sm" name="contactPerson" value={this.state.contactPerson} placeholder="e.g Mary Fruge" maxLength="50" title="Contact Person" onChange={this.handleInputChange} />
                                        {this.state.errors["contactPerson"].length > 0 && <span className="error invalid-feedback">{this.state.errors["contactPerson"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-3 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <br></br>
                                        <button type="submit" className="btn btn-link" title="View Document(s)" onClick={() => { this.openDocumentModel() }} ><span className='fas fa-folder'></span>&nbsp;View Document(s)</button>
                                    </div>
                                </div>

                            </div>
                            {/*<div className='sub-title-color font-weight-bold mt-3'>User Info</div>
                            <div className='row'>
                                <div className="col-lg-2 col-md-2 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <label htmlFor="">First Name <span className="text-danger">*</span></label>
                                        <input type="text" className="form-control form-control-sm" name="firstName" value={this.state.firstName} placeholder="Enter first name" maxLength="50" title="First Name" onChange={this.handleInputChange} />
                                        {this.state.errors["firstName"].length > 0 && <span className="error invalid-feedback">{this.state.errors["firstName"]}</span>}
                                    </div>
                                </div>

                                <div className="col-lg-2 col-md-2 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <label>Last Name <span className="text-danger">*</span></label>
                                        <input type="text" className="form-control form-control-sm" name="lastName" value={this.state.lastName} placeholder="Enter last name" maxLength="50" title="Last Name" onChange={this.handleInputChange} />
                                        {this.state.errors["lastName"].length > 0 && <span className="error invalid-feedback">{this.state.errors["lastName"]}</span>}
                                    </div>
                                </div>
                                 <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <label htmlFor="">UserName</label>
                                        <input type="text" className="form-control form-control-sm" name="userName" value={this.state.userName} placeholder="Enter User Name" maxLength="50" title="User Name" onChange={this.handleInputChange} />
                                        {this.state.errors["userName"].length > 0 && <span className="error invalid-feedback">{this.state.errors["userName"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <label htmlFor="">Password</label>
                                        <input type="password" className="form-control form-control-sm" name="password" value={this.state.password} placeholder="Enter Password" maxLength="150" title="Password" onChange={this.handleInputChange} />
                                        {this.state.errors["password"].length > 0 && <span className="error invalid-feedback">{this.state.errors["password"]}</span>}
                                    </div>
                                </div> 
                            </div>*/}
                            {/* <div className='sub-title-color font-weight-bold mt-3'>Addresses</div> */}
                            <div className='row mt-2'>
                                <div className='col-6'>
                                    <div className='bg-body-tertiary p-3 rounded'>
                                        <div className='font-weight-bold mb-2'>Billing Address</div>
                                        <div className='row'>
                                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                <div className="form-group">
                                                    <label htmlFor="">Address Line 1 <span className="text-danger">*</span></label>
                                                    <input type="text" className="form-control form-control-sm" name="billingAddressLine1" value={this.state.billingAddressLine1} placeholder="Enter address line 1" maxLength="100" title="Address Line 1" onChange={this.handleInputChange} />
                                                    {this.state.errors["billingAddressLine1"].length > 0 && <span className="error invalid-feedback">{this.state.errors["billingAddressLine1"]}</span>}
                                                </div>
                                            </div>
                                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                <div className="form-group">
                                                    <label htmlFor="">Address Line 2</label>
                                                    <input type="text" className="form-control form-control-sm" name="billingAddressLine2" value={this.state.billingAddressLine2} placeholder="Enter address line 2" maxLength="100" title="Address Line 2" onChange={this.handleInputChange} />
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                                                <div className="form-group">
                                                    <label htmlFor="">City <span className="text-danger">*</span></label>
                                                    <input type="text" className="form-control form-control-sm" name="billingCity" value={this.state.billingCity} placeholder="Enter city" maxLength="50" title="City" onChange={this.handleInputChange} />
                                                    {this.state.errors["billingCity"].length > 0 && <span className="error invalid-feedback">{this.state.errors["billingCity"]}</span>}
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                                                <div className="form-group">
                                                    <label htmlFor="">State <span className="text-danger">*</span></label>
                                                    <input type="text" className="form-control form-control-sm" name="billingState" value={this.state.billingState} placeholder="Enter state" maxLength="50" title="State" onChange={this.handleInputChange} />
                                                    {this.state.errors["billingState"].length > 0 && <span className="error invalid-feedback">{this.state.errors["billingState"]}</span>}
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                                                <div className="form-group">
                                                    <label htmlFor="">Postal Code <span className="text-danger">*</span></label>
                                                    <PatternFormat className="form-control form-control-sm" format="######" name="billingZipCode" placeholder="Enter postal code" value={this.state.billingZipCode} title="Postal Code" maxLength="7" onChange={this.handleInputChange} />
                                                    {/* <input type="text" className="form-control form-control-sm" name="billingZipCode" value={this.state.billingZipCode} placeholder="Enter postal code" maxLength="7" title="Postal Code" onChange={this.handleInputChange} /> */}
                                                    {this.state.errors["billingZipCode"].length > 0 && <span className="error invalid-feedback">{this.state.errors["billingZipCode"]}</span>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-6'>
                                    <div className='bg-body-tertiary p-3 rounded'>
                                        <div className="d-flex flex-row justify-content-between">
                                            <div className='font-weight-bold mb-2'>Shipping Address</div>
                                            <div><button type="button" className="btn btn-link" onClick={this.copyBillingAddress}><i className="fas fa-copy"></i> Copy billing Address</button></div>
                                        </div>
                                        <div className='row'>
                                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                <div className="form-group">
                                                    <label htmlFor="">Address Line 1 <span className="text-danger">*</span></label>
                                                    <input type="text" className="form-control form-control-sm" name="shippingAddressLine1" value={this.state.shippingAddressLine1} placeholder="Enter address line 1" maxLength="100" title="Address Line 1" onChange={this.handleInputChange} />
                                                    {this.state.errors["shippingAddressLine1"].length > 0 && <span className="error invalid-feedback">{this.state.errors["shippingAddressLine1"]}</span>}
                                                </div>
                                            </div>
                                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                <div className="form-group">
                                                    <label htmlFor="">Address Line 2</label>
                                                    <input type="text" className="form-control form-control-sm" name="shippingAddressLine2" value={this.state.shippingAddressLine2} placeholder="Enter address line 2" maxLength="100" title="Address Line 2" onChange={this.handleInputChange} />
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                                                <div className="form-group">
                                                    <label htmlFor="">City <span className="text-danger">*</span></label>
                                                    <input type="text" className="form-control form-control-sm" name="shippingCity" value={this.state.shippingCity} placeholder="Enter city" maxLength="50" title="City" onChange={this.handleInputChange} />
                                                    {this.state.errors["shippingCity"].length > 0 && <span className="error invalid-feedback">{this.state.errors["shippingCity"]}</span>}
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                                                <div className="form-group">
                                                    <label htmlFor="">State <span className="text-danger">*</span></label>
                                                    <input type="text" className="form-control form-control-sm" name="shippingState" value={this.state.shippingState} placeholder="Enter state" maxLength="50" title="State" onChange={this.handleInputChange} />
                                                    {this.state.errors["shippingState"].length > 0 && <span className="error invalid-feedback">{this.state.errors["shippingState"]}</span>}
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                                                <div className="form-group">
                                                    <label htmlFor="">Postal Code <span className="text-danger">*</span></label>
                                                    <PatternFormat className="form-control form-control-sm" format="######" name="shippingZipCode" placeholder="Enter postal code" value={this.state.shippingZipCode} title="Postal Code" maxLength="7" onChange={this.handleInputChange} />
                                                    {/* <input type="text" className="form-control form-control-sm" name="shippingZipCode" value={this.state.shippingZipCode} placeholder="Enter postal code" maxLength="7" title="Postal Code" onChange={this.handleInputChange} /> */}
                                                    {this.state.errors["shippingZipCode"].length > 0 && <span className="error invalid-feedback">{this.state.errors["shippingZipCode"]}</span>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <Modal ariaHideApp={false}
                                    isOpen={this.state.documentModelIsOpen}
                                    style={customStyles}
                                    className={"react-modal"}
                                    onRequestClose={this.closeDocumentModel}
                                    shouldCloseOnOverlayClick={false}
                                    contentLabel="Example Modal">
                                    <form method="post" style={this.state.isSubmited ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                                        <div className="modal-header">
                                            <h6 className="modal-title">Document</h6>
                                        </div>
                                        <div className="modal-body">
                                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                                <div className="box-body no-padding" style={{ maxHeight: 400 + 'px', overflowY: "scroll" }}>
                                                    <div>
                                                        <ul className="users-list clearfix">
                                                            {this.state.imageList.length > 0 && this.state.imageList.map((item, j) =>
                                                                <li style={{ position: "relative" }} key={j}>
                                                                    <a href={item.src} download={item.fileName}><div className="framehover"><img src={item.src} width="150" alt="Blank" /></div></a>
                                                                </li>
                                                            )}
                                                            {this.state.pdfList.length > 0 && this.state.pdfList.map((item, j) =>
                                                                <li style={{ position: "relative" }} key={j}>
                                                                    <a href={item.src} download={item.fileName}><div className="framehover"><img src="../../assets/img/pdf_icon.png" width="150" alt="Blank" /></div></a>
                                                                </li>
                                                            )}
                                                            {this.state.excelList.length > 0 && this.state.excelList.map((item, j) =>
                                                                <li style={{ position: "relative" }} key={j}>
                                                                    <a href={item.src} download={item.fileName}><div className="framehover"><img src="../../assets/img/excel_icon.png" width="100" alt="Blank" /></div></a>
                                                                </li>
                                                            )}
                                                            {this.state.otherList.length > 0 && this.state.otherList.map((item, j) =>
                                                                <li style={{ position: "relative" }} key={j}>
                                                                    <a href={item.src} download={item.fileName}><div className="framehover"><img src="../../assets/img/other_icon.png" width="100" alt="Blank" /></div></a>
                                                                </li>
                                                            )}

                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="modal-footer">
                                            <button type="button" className="btn btn-outline-secondary" onClick={this.closeDocumentModel} title="Cancel">Cancel</button>
                                        </div>
                                    </form>
                                </Modal>
                            </div>
                            <div className='sub-title-color font-weight-bold mt-3' style={{ display: this.state.accountTypeName === 'Customer' ? 'block' : 'none' }}>Contract Info</div>
                            <div style={{ display: this.state.accountTypeName === 'Customer' ? 'block' : 'none' }}>
                                <DataGrid
                                    dataSource={this.state.customerContractList}
                                    keyExpr="id"
                                    selection={{ mode: 'single' }}
                                    columnsAutoWidth={false}
                                    showColumnLines={true}
                                    showRowLines={true}
                                    showBorders={true}
                                    rowAlternationEnabled={false}
                                    allowColumnResizing={true}
                                    columnResizingMode='widget'
                                    onExporting={this.onExporting}
                                    onToolbarPreparing={this.onToolbarPreparing}>
                                    <Column dataField="id" caption="" allowSearch={true} allowFiltering={false} allowSorting={true} width={35} cellRender={renderGridCell} />
                                    <Column dataField="contractTypeName" caption="Contract Type" allowSearch={true} allowFiltering={false} allowSorting={true} width={110} />
                                    <Column dataField="soNo" caption="SO#" allowSearch={true} allowFiltering={false} allowSorting={true} width={100} />
                                    <Column dataField="contractPeriod" caption="Period" allowSearch={true} allowFiltering={false} allowSorting={true} width={200} format={DateFormat.DateNorthFormat} dataType="datetime" />
                                    <Column dataField="paymentTermsName" caption="Payment Term" allowSearch={true} allowFiltering={false} allowSorting={true} width={130} />
                                    <Column dataField="amount" caption="Amount" allowSearch={true} allowFiltering={false} allowSorting={true} width={100} cellRender={renderGridNumberCell} />
                                    <Column dataField="contractStatusName" caption="Contract Status" allowSearch={true} allowFiltering={false} allowSorting={true} width={250} />
                                    <SortByGroupSummaryInfo summaryItem="count" />
                                    <GroupPanel visible={false} />
                                    <HeaderFilter visible={true} />
                                    <SearchPanel visible={false} highlightCaseSensitive={true} width={240} placeholder="Search..." />
                                    <Grouping autoExpandAll={true} />
                                    <Paging defaultPageSize={20} />
                                    <Pager showPageSizeSelector={true} allowedPageSizes={[10, 20, 50]} showInfo={true} visible={true} />
                                    <Export enabled={false} formats={exportFormats} allowExportSelectedData={false} fileName="Contract Configuration" />
                                </DataGrid>
                            </div>
                       </div>
                    </div>
                </div>


                <Modal ariaHideApp={false}
                    isOpen={this.state.contractConfigurationModal}
                    style={customStyles}
                    className={"react-modal"}
                    onRequestClose={this.closeContractConfigurationModal}
                    shouldCloseOnOverlayClick={false}
                    contentLabel="Example Modal">
                    <form method="post" onSubmit={this.handleCustomerContractSubmit} style={this.state.isSubmited ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                        <div className="modal-header">
                            <h6 className="modal-title">Set Contract Configuration - {this.state.customername}</h6>
                        </div>
                       
                        <div className="modal-body">
                            <div className="row">
                                 <div className="col-lg-3">
                                    <div className="form-group">
                                        <label htmlFor="">Contract Type</label>
                                        <SelectBox
                                            dataSource={this.state.contractType}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select contract type"
                                            name="id"
                                            defaultValue={0}
                                            value={this.state.contractTypeId}
                                            displayExpr='name'
                                            valueExpr='id'
                                            onSelectionChanged={this.handleContractTypeChange}
                                            showClearButton={true}
                                            disabled={false}
                                            //disabled={this.state.id > 0}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["contractTypeId"].length > 0 && <span className="error invalid-feedback">{this.state.errors["contractTypeId"]}</span>}
                                    </div>
                                </div> 
                               <div className="col-lg-3">
                                    <div className="form-group">
                                        <label htmlFor="">Contract Status</label>
                                        <SelectBox
                                            dataSource={this.state.contractStatus}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select contract status"
                                            name="id"
                                            defaultValue={0}
                                            value={this.state.contractStatusId}
                                            displayExpr='name'
                                            valueExpr='id'
                                            onSelectionChanged={this.handleContractStatusChange}
                                            showClearButton={true}
                                            disabled={false}
                                            //disabled={this.state.id > 0}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["contractStatusId"].length > 0 && <span className="error invalid-feedback">{this.state.errors["contractStatusId"]}</span>}
                                     </div>
                                </div> 
                                <div className="col-lg-2">
                                    <div className="form-group">
                                        <label htmlFor="">Payment Term</label>
                                        <SelectBox
                                            dataSource={this.state.paymentTermType}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select Peymanet term"
                                            name="id"
                                            defaultValue={0}
                                            value={this.state.paymentTermTypeId}
                                            displayExpr='name'
                                            valueExpr='id'
                                            onSelectionChanged={this.handlePaymentTermTypeChange}
                                            showClearButton={true}
                                            disabled={false}
                                            //disabled={this.state.id > 0}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["paymentTermTypeId"].length > 0 && <span className="error invalid-feedback">{this.state.errors["paymentTermTypeId"]}</span>}
                                    </div>
                                </div> 
                                <div className="col-lg-2">
                                    <div className="form-group">
                                        <label>Start Date </label>
                                        <DateBox type="date"
                                            className="start-date-time"
                                            name="startDate"
                                            placeholder="dd/mm/yyyy"
                                            displayFormat="dd/MM/yyyy"
                                            showAnalogClock={true}
                                            useMaskBehavior={true}
                                            defaultValue="dd/MM/yyyy"
                                            value={this.state.startDate}
                                            onValueChanged={this.handleStartDateChanged}
                                            width="100%"
                                            disabled={true}
                                            acceptCustomValue={false}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-2">
                                    <div className="form-group">
                                        <label>End Date </label>
                                        <DateBox type="date"
                                            className="end-date-time"
                                            name="endDate"
                                            placeholder="dd/mm/yyyy"
                                            displayFormat="dd/MM/yyyy"
                                            showAnalogClock={true}
                                            useMaskBehavior={true}
                                            defaultValue={this.state.endDate}
                                            value={this.state.endDate}
                                            min={this.state.endDate}
                                            onValueChanged={this.handleEndDateChanged}
                                            width="100%"
                                            disabled={true}
                                            acceptCustomValue={false}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <DataGrid
                                    ref="grdContractServiceRef"
                                    dataSource={this.state.servicesource}
                                    keyExpr="rowIndex"
                                    allowColumnReordering={true}
                                    showBorders={true}
                                    onRowUpdating={this.onRowUpdating}
                                    onRowUpdated={this.onRowUpdated}
                                    onRowInserting={this.onRowInserting}
                                    onRowRemoved={this.onRowRemoved}
                                    onEditorPreparing={this.onEditorPreparing}
                                    selectedRowKeys={this.state.selectedItemKeys}
                                    onSelectionChanged={this.handleServiceChanged}>
                                    <Selection mode="multiple" />
                                    <Paging enabled={false} />
                                    <Editing mode="cell"
                                        allowUpdating={true}
                                        allowDeleting={false}
                                        allowAdding={false}
                                        editorOptions={true} />
                                    <Column dataField="name" caption="Service" allowSearch={true} allowEditing={false} allowFiltering={false} allowSorting={true} width={200} />
                                    <Column dataField="basedOnUnit" caption="Based On" allowSearch={true} allowEditing={false} allowFiltering={false} allowSorting={true} width={100} />
                                    <Column dataField="termType" caption="Term Type" allowSearch={true} allowEditing={false} allowFiltering={false} allowSorting={true} width={100} />
                                    <Column dataField="unitPrice" caption="Standard Unit Price" allowSearch={true} allowEditing={false} allowFiltering={false} allowSorting={true} width={150}
                                        dataType="number" cellRender={renderGridNumberCell} />
                                    <Column dataField="salePrice" caption="Sale Price" dataType="decimal" format={"#,##0.##"} alignment="right"
                                        cellRender={renderGridNumberCell} width={150}>
                                        <RequiredRule />
                                        <PatternRule message={'Sale Price should be decimal'} pattern={floatValidation} />
                                    </Column>
                                </DataGrid>
                            </div>
                        </div>
                        <div className="modal-footer">
                          
                                <button type="submit" className="btn btn-success">
                                    {this.state.isSubmited && <span title="In-process" onClick={this.handleCustomerContractSubmit}><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                    {!this.state.isSubmited && <span title="Submit">Submit</span>}
                                </button>
                                
                            <button type="button" className="btn btn-outline-secondary" onClick={this.closeContractConfigurationModal} title="Cancel">Cancel</button>
                        </div>
                    </form>
                </Modal>
            </Fragment>
        )
    };
}