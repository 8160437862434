export const DateFormat = {
    DateTime24WesternFormat: "MM/dd/yyyy HH:mm",
    DateTime12WesternFormat: "MM/dd/yyyy hh:mm",
    DateTime24WesternLongFormat: "MM/dd/yyyy HH:mm:ss",
    DateWesternFormat: "MM/dd/yyyy",

    DateTime24NorthFormat: "dd/MM/yyyy HH:mm",
    DateTime12NorthFormat: "dd/MM/yyyy hh:mm",
    DateTime24NorthLongFormat: "dd/MM/yyyy HH:mm:ss",
    DateNorthFormat: "dd/MM/yyyy",

    Time24Format: "HH:mm",
    Time12Format: "hh:mm",
    Time24FormatLong: "HH:mm:ss",
    Time24FormatLongAMPM: "hh:mm:ss a",
};

export const OrderStatus = {
    PENDING: 1,
    COMPLETED: 2
}

export const QCResultStatus = {
    REPAIRED: 1,
    REPLACED: 2,
    REFUNDED: 3,
    PENDING: 4,
    FAILED: 5,
    PASSED: 6
}

export const exportFormats = [
    'pdf','xlsx'
];

export const symbolFormats = {
    percentageFormat: "fas fa-percentage",
}

export const currencySymbolFormats = {
    currencyFormat: "",
}

export const precisionFormats = {
    0:"0",
    1:"1",
    2:"2",
    3:"3",
}

export const orderType = {
    GoodsCapex: 1,
    Serviceopex: 2
}
export const documentType = {
    SalesOrder:	1,
    PurchaseOrder:2,
    Invoice	:3,
    SalesReturn	:4,
    PurchaseReturn:5,
    Customer:6,
    Vendor:7,
    Courier:8,
    CreditNote:	9,
    DebitNote:10,
    Bills:1002,
    Bills:1003,
    
}
export const invoiceStatus = {
    Draft:1,
    Approved:2,
    SentToCustomer	:3,
    SentToTally:4,
    Paid:5,
    PartiallyPaid:6,
    Overdue:7,
    Filed:8,
    Void:9,
}
export const approvedBy ={
    Appproved:1
}