import React, { Fragment, Component } from 'react';
import DataGrid, {
    Column,
    Export, Grouping,
    GroupPanel,
    HeaderFilter, Pager,
    Paging,
    SearchPanel,
    SortByGroupSummaryInfo
} from 'devextreme-react/data-grid'; //npm i devextreme-react
import Modal from 'react-modal'; //npm install --save react-modal;
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import { getRequestParams } from '../../helpers/globalfunctions.js';
import { alphanumericValidation, alphanumericSpecialcaseValidation } from '../../helpers/globalvalidations.js';
import { LoadPanel } from 'devextreme-react/load-panel';
import Swal from 'sweetalert2'; //npm i sweetalert2 --save
import { toast } from 'react-toastify'; //npm i react-toastify --save
import { SelectBox } from 'devextreme-react/select-box';
import { jsPDF } from 'jspdf';
import { exportDataGrid } from 'devextreme/pdf_exporter';
const exportFormats = ['pdf', 'xlsx'];

const position = { of: '#historydiv' };
const customStyles = {
    content: {
        width: '20%',
    }
}

export class AttributeComponent extends Component {
    static displayName = AttributeComponent.name;

    constructor(props) {
        super(props);

        this.state = {
            isSubmited: false,
            loadPanelVisible: false,
            attributeModelIsOpen: false,
            attributesource: null,
            attributeTypeList: null,
            singelData: null,
            id: 0,
            name: "",
            description: "",
            attributeTypeId: 0,
            filterAttributeTypeId: 0,
            errors: {
                name: "",
                description: "",
                attributeTypeId: 0,
            }
        }
        this.handleAttributeSubmit = this.handleAttributeSubmit.bind(this);
    }

    async componentDidMount() {
        await this.getAttributeMaster();
        await this.getAttributeType();
    }

    getAttributeMaster = async () => {
        this.setState({
            loadPanelVisible: true
        });
        try {
            var request = {};
            const requestParams = getRequestParams("GET", request);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'attributes/attribute/' + this.state.id + '/' + this.state.filterAttributeTypeId, requestParams);
            const data = await response.json();
            this.setState({
                attributesource: data.payload,
                loadPanelVisible: false
            });
        } catch (err) {
            console.log("Error in " + this.displayName + ".getAttributeMaster function", err);
            this.setState({
                loadPanelVisible: false
            });
        }
    }

    getAttributeType = async () => {
        this.setState({
            loadPanelVisible: true
        });
        try {
            var request = "";
            const requestParams = getRequestParams("GET", request);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'attributes/attributetype/0', requestParams);
            const data = await response.json();
            this.setState({
                attributeTypeList: data.payload,
                loadPanelVisible: false
            });

        } catch (err) {
            console.log("Error in " + this.displayName + ".getAttributeType function", err);
            this.setState({
                loadPanelVisible: false
            });
        }
    }

    //icon refresh
    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift({
            location: 'after',
            widget: 'dxButton',
            options: {
                icon: 'refresh',
                onClick: this.getAttributeMaster.bind(this)
            }
        });
    }

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        //const alphanumericValidation = /^[A-Za-z0-9]*$/;
        switch (name) {
            case 'name':
                if (alphanumericValidation.test(event.target.value))
                    this.setState({ name: event.target.value });
                break;
            case 'description':
                if (alphanumericSpecialcaseValidation.test(event.target.value))
                    this.setState({ description: event.target.value });
                break;
            default:
                this.setState({ [name]: value });
                break;
        }
        this.setState({ [name]: value });
        this.state.errors[name] = "";
    }

    handleAttributeModal = (e) => {
        this.setState({
            attributeModelIsOpen: true,
            singelData: null
        });
    }

    closeAttributeModal = () => {
        this.setState({
            isSubmited: false,
            loadPanelVisible: false,
            attributeModelIsOpen: false,
            singelData: null,
            id: 0,
            name: "",
            description: "",
            attributeTypeId: 0,
            errors: {
                name: "",
                description: "",
                attributeTypeId: 0,
            }
        });
    }

    handleValidation = () => {
        let formIsValid = true;
        try {
            let errors = this.state.errors;
            errors.name = "";
            errors.description = "";
            errors.attributeTypeId = 0;

            if (this.state.name.trim() === "") {
                formIsValid = false;
                errors.name = "Please enter attribute name.";
            }
            if (this.state.description.trim() === "") {
                formIsValid = false;
                errors.description = "Please enter description.";
            }
            if (this.state.attributeTypeId === 0) {
                formIsValid = false;
                errors.attributeTypeId = "Please select attribute type.";
            }
            this.setState({ errors: errors });
        }
        catch (err) {
            console.log("Error in " + this.displayName + ".handleValidation function", err);
            this.setState({ isSubmited: false })
        }
        return formIsValid;
    }

    handleAttributeTypeChange = (selectedOption) => {
        this.setState({
            attributeTypeId: (selectedOption.selectedItem !== null ? selectedOption.selectedItem.id : 0)
        });

        if (selectedOption.selectedItem !== null && selectedOption.selectedItem.id > 0) {
            this.state.errors.attributeTypeId = "";
        }
    }
    handleFilterAttributeTypeChange = (selectedOption) => {
        this.setState({
            filterAttributeTypeId: (selectedOption.selectedItem !== null ? selectedOption.selectedItem.id : 0)
        });
    }

    handleAttributeSubmit = async (e) => {
        e.preventDefault();
        try {
            if (this.handleValidation()) {
                this.setState({ isSubmited: true });
                var newObject = {};
                newObject.Name = this.state.name.trim();
                newObject.Description = this.state.description.trim();
                newObject.AttributeTypeId = this.state.attributeTypeId;
                const requestParams = getRequestParams('POST', newObject);
                const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'attributes/' + this.state.id + '/attribute', requestParams);
                const data = await response.json();
                if (data.isSuccess) {
                    Swal.fire({
                        icon: 'success',
                        title: 'Success',
                        text: data.message,
                    }).then((result) => {
                        if (result.isConfirmed) {
                            this.getAttributeMaster();
                            this.closeAttributeModal();
                        }
                        this.setState({ isSubmited: false })
                    })
                }
                else {
                    toast.error(data.message, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        style: { width: "310px" },
                        hideProgressBar: false,
                        newestOnTop: false,
                        closeButton: false
                    });
                    this.setState({ isSubmited: false })
                }
            }
        }
        catch (err) {
            console.log("Error in " + this.displayName + ".handleAttributeSubmit function", err);
            this.setState({ isSubmited: false })
        }
    }

    editAttributeModelModal = (e, data) => {
        e.preventDefault();

        if (data != null) {
            this.setState({
                attributeModelIsOpen: true,
                singelData: data,
                id: data.id,
                name: data.name,
                description: data.description,
                attributeTypeId: data.attributeTypeId,
            });
            setTimeout(() => this.getAttributeType(), 100);
        }

    }

    handleClear = () => {
        this.setState({
            filterAttributeTypeId: 0
        });
        setTimeout(() => this.getAttributeMaster(), 100);
        //this.getAttributeMaster();
    }
    onExporting = (e) => {
        const doc = new jsPDF();
        if (e.format === "pdf") {
            exportDataGrid({
                jsPDFDocument: doc,
                component: e.component,
                columnWidths: 50
                // indent: 10,
            }).then(() => {
                doc.save('Attribute.pdf');
            });
        }
    }
    render() {
        const renderGridCell = (data) => {
            return <div>
                <a href="#" className="fas fa-pen" title="Edit" onClick={(e) => { this.editAttributeModelModal(e, data.data) }} ></a>&nbsp;&nbsp;
            </div>;
        }

        return (
            <Fragment>
                <div className="section-body">
                    <div className='card bg-filter'>
                        <div className='card-body'>
                            <div className="section-header">
                                <div className='row'>
                                    <div className="col-lg-2">
                                        <div className="form-group">
                                            <label>Attribute Type</label>
                                            <SelectBox
                                                dataSource={this.state.attributeTypeList}
                                                className="basic-multi-select"
                                                classNamePrefix="select"
                                                placeholder="Select attribute type"
                                                name="id"
                                                defaultValue={0}
                                                value={this.state.filterAttributeTypeId}
                                                displayExpr='name'
                                                valueExpr='id'
                                                onSelectionChanged={this.handleFilterAttributeTypeChange}
                                                showClearButton={true}
                                                disabled={false}
                                                searchEnabled={true}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-lg-6 pl-0'>
                                        <br></br>
                                        <button type="submit" className="btn btn-success" onClick={this.getAttributeMaster}>
                                            Search
                                        </button>&nbsp;&nbsp;
                                        <button type="submit" className="btn btn-outline-secondary" onClick={this.handleClear}>
                                            <span title="Clear">Clear</span>
                                        </button>
                                    </div>
                                </div>
                                <div className="section-header-buttons">
                                    <label htmlFor="">&nbsp;</label><br />
                                    <button type="submit" className="btn btn-success" onClick={this.handleAttributeModal}>
                                        New
                                    </button>
                                </div>


                            </div>

                        </div>
                    </div>
                    <div className='card'>
                        <div className='card-body'>
                            <DataGrid
                                dataSource={this.state.attributesource}
                                keyExpr="id"
                                id='gridAttributeMasterList'
                                selection={{ mode: 'single' }}
                                columnsAutoWidth={false}
                                showColumnLines={true}
                                showRowLines={true}
                                showBorders={true}
                                rowAlternationEnabled={false}
                                allowColumnResizing={true}
                                columnResizingMode='widget'
                                onExporting={this.onExporting}
                                onToolbarPreparing={this.onToolbarPreparing}>

                                <Column dataField="id" width={30} caption="" allowFiltering={false} allowSorting={false} cellRender={renderGridCell} />
                                <Column dataField="name" caption="Name" allowSearch={true} allowFiltering={false} allowSorting={true} width={150} />
                                <Column dataField="description" caption="Description" allowSearch={true} allowFiltering={false} allowSorting={true} width={300} />
                                <Column dataField="attributeTypeName" caption="Attribute Type" allowSearch={true} allowFiltering={false} allowSorting={true} width={300} />

                                <SortByGroupSummaryInfo summaryItem="count" />
                                <GroupPanel visible={false} />
                                {/* <Template name="toolbarTemplate" /> */}
                                <HeaderFilter visible={true} />
                                <SearchPanel visible={true} highlightCaseSensitive={true} width={240} placeholder="Search..." />
                                <Grouping autoExpandAll={true} />
                                <Paging defaultPageSize={20} />
                                <Pager showPageSizeSelector={true} allowedPageSizes={[10, 20, 50]} showInfo={true} visible={true} />
                                <Export enabled={true} allowExportSelectedData={false} fileName="AttributeMaster" />
                            </DataGrid>
                        </div>
                    </div>
                </div>
                <Modal ariaHideApp={false}
                    isOpen={this.state.attributeModelIsOpen}
                    style={customStyles}
                    className={"react-modal"}
                    //onAfterOpen={this.handleAssignDataToState}
                    onRequestClose={this.closeAttributeModal}
                    shouldCloseOnOverlayClick={false}
                    contentLabel="Example Modal">
                    <form method="post" onSubmit={this.handleAttributeSubmit} style={this.state.isSubmited ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                        <div className="modal-header">
                            <h6 className="modal-title">{this.state.id > 0 ? "Update Attribute" : "New Attribute"}</h6>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="form-group">
                                        <label>Type <span className="text-danger">*</span></label>
                                        <SelectBox
                                            dataSource={this.state.attributeTypeList}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select attribute type"
                                            name="id"
                                            defaultValue={0}
                                            value={this.state.attributeTypeId}
                                            displayExpr='name'
                                            valueExpr='id'
                                            onSelectionChanged={this.handleAttributeTypeChange}
                                            showClearButton={true}
                                            disabled={false}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["attributeTypeId"].length > 0 && <span className="error invalid-feedback">{this.state.errors["attributeTypeId"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="form-group">
                                        <label>Name <span className="text-danger">*</span></label>
                                        <input type="text" className="form-control form-control-sm" name="name" placeholder="Enter attribute name" value={this.state.name} disabled={this.state.id > 0} title="Attribute Name" maxLength="50" onChange={this.handleInputChange} />
                                        {this.state.errors["name"].length > 0 && <span className="error invalid-feedback">{this.state.errors["name"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="form-group">
                                        <label htmlFor="">Description <span className="text-danger">*</span></label>
                                        <textarea type="text" className="form-control form-control-sm" name="description" placeholder="Enter description" maxLength="50" title="Attribute Description" value={this.state.description} onChange={this.handleInputChange} />
                                        {this.state.errors["description"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["description"]}</span>}
                                    </div>
                                </div>
                            </div></div>
                        <div className="modal-footer">
                            <button type="submit" className="btn btn-success" onClick={this.handleAttributeSubmit} >
                                {this.state.isSubmited && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                {!this.state.isSubmited && <span title="Submit">Submit</span>}
                            </button>
                            <button type="button" className="btn btn-outline-secondary" onClick={this.closeAttributeModal} title="Cancel">Cancel</button>
                        </div>
                    </form>
                </Modal>
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={position}
                    visible={this.state.loadPanelVisible}
                    showIndicator={true}
                    shading={true}
                    showPane={true}
                    hideOnOutsideClick={false}
                />
            </Fragment>
        )
    };
}