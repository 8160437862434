import React, { Fragment, Component } from 'react';
import DataGrid, {
    Column,
    Export, Grouping,
    GroupPanel,
    HeaderFilter, Pager,
    Paging,
    SearchPanel,
    SortByGroupSummaryInfo
} from 'devextreme-react/data-grid'; //npm i devextreme-react
import Modal from 'react-modal'; //npm install --save react-modal;
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import { getRequestParams, formatDate } from '../../helpers/globalfunctions.js';
import { alphanumericValidation, regEmail, vMinLength, vMinUpperCaseLetter, vMinLowerCaseLetter, vMinNumber, vMinSpecialCharacter, vIsPasswordComplexity } from '../../helpers/globalvalidations.js';
import { LoadPanel } from 'devextreme-react/load-panel';
import Swal from 'sweetalert2'; //npm i sweetalert2 --save
import { toast } from 'react-toastify'; //npm i react-toastify --save
import { SelectBox } from 'devextreme-react/select-box';
import { PatternFormat } from 'react-number-format';
import { ChangePasswordComponent } from '../shared/ChangePasswordComponent';
import { jsPDF } from 'jspdf';
import { exportDataGrid } from 'devextreme/pdf_exporter';
const exportFormats = ['pdf', 'xlsx'];
const position = { of: '#historydiv' };

const customStyles = {
    content: {
        width: '50%',
    }
}

export class UsersComponent extends Component {
    static displayName = UsersComponent.name;

    constructor(props) {
        super(props);

        this.state = {
            isSubmited: false,
            loadPanelVisible: false,
            sessionUser: JSON.parse(sessionStorage.getItem("sessionUser")),
            userModal: false,
            userSource: null,
            userTypeList: null,
            accountList: null,
            id: 0,
            firstName: "",
            lastName: "",
            accountId: 0,
            userTypeId: 0,
            userName: "",
            newPassword: "",
            confirmPassword: "",
            contactNo: "",
            email: "",
            active: true,
            passwordEncryptKey: "",
            filterAccountId: 0,
            filterUserTypeId: 0,
            filterUserName: "",
            errors: {
                firstName: "",
                lastName: "",
                userTypeId: 0,
                userName: "",
                newPassword: "",
                confirmPassword: "",
                contactNo: "",
                email: "",
                accountId: 0.
            }
        }
    }

    async componentDidMount() {
        this.setState({
            loadPanelVisible: true
        });
        await this.getAccounts();
        await this.getUsers();
        await this.getUserType();
        this.setState({
            loadPanelVisible: false
        });
    }

    getUsers = async () => {
        try {
            var request = {};
            request.AccountId = this.state.filterAccountId;
            request.UserTypeId = this.state.filterUserTypeId;
            request.UserName = this.state.filterUserName;
            const requestParams = getRequestParams("POST", request);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'users/user', requestParams);
            const data = await response.json();
            this.setState({
                userSource: data.payload,
                loadPanelVisible: false
            });
        } catch (err) {
            console.log("Error in " + this.displayName + ".getUsers function", err);
            this.setState({
                loadPanelVisible: false
            });
        }
    }

    getUserType = async () => {
        try {
            var request = "";
            const requestParams = getRequestParams("GET", request);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'users/userType', requestParams);
            const data = await response.json();
            this.setState({
                userTypeList: data.payload,
                loadPanelVisible: false
            });

        } catch (err) {
            console.log("Error in " + this.displayName + ".getUserType function", err);
            this.setState({
                loadPanelVisible: false
            });
        }
    }

    getAccounts = async () => {
        try {
            var request = {};
            const requestParams = getRequestParams("GET", request);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'account/dropdownAccount/0', requestParams);
            const data = await response.json();
            this.setState({
                accountList: data.payload
            });
        } catch (err) {
            console.log("Error in " + this.displayName + ".getAccounts function", err);
        }
    }

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        let errors = this.state.errors;

        switch (name) {
            case 'userName':
                if (alphanumericValidation.test(event.target.value))
                    this.setState({ userName: event.target.value });
                break;
            case 'firstName':
                if (alphanumericValidation.test(event.target.value))
                    this.setState({ firstName: event.target.value });
                break;
            case 'lastName':
                if (alphanumericValidation.test(event.target.value))
                    this.setState({ lastName: event.target.value });
                break;
            case 'email':
                this.setState({ email: event.target.value });
                break;
            case 'filterUserName':
                this.setState({ filterUserName: event.target.value });
                break;
            default:
                this.setState({ [name]: value });
                break;
        }
        errors[name] = "";
        //this.state.errors[name] = "";
        this.setState({
            errors: errors
        });
    }

    handleUserModal = async (e) => {
        this.setState({
            userModal: true,
            singelData: null
        });
    }

    openchangesPasswordModel = (e, data) => {
        e.preventDefault();
        this.refs.changepasswordcomponent.showModal(data);
    }

    handleUserTypeChange = (selectedOption) => {
        this.setState({
            userTypeId: (selectedOption.selectedItem !== null ? selectedOption.selectedItem.id : 0)
        });
        let errors = this.state.errors;
        if (selectedOption.selectedItem !== null && selectedOption.selectedItem.id > 0) {
            //this.state.errors["userTypeId"] = "";
            errors.userTypeId = "";
            this.setState({
                errors: errors
            });
        }
    }
    handleFilterUserTypeChange = (selectedOption) => {
        this.setState({
            filterUserTypeId: (selectedOption.selectedItem !== null ? selectedOption.selectedItem.id : 0)
        });
    }

    handleAccountChange = (selectedOption) => {
        this.setState({
            accountId: (selectedOption.selectedItem !== null ? selectedOption.selectedItem.id : 0),
        });
        let errors = this.state.errors;
        if (selectedOption.selectedItem !== null && selectedOption.selectedItem.id > 0) {
            //this.state.errors["accountId"] = "";
            errors.accountId = "";
            this.setState({
                errors: errors
            });
        }
    }
    handleFilterAccountChange = (selectedOption) => {
        this.setState({
            filterAccountId: (selectedOption.selectedItem !== null ? selectedOption.selectedItem.id : 0),
        });
    }

    closeUserModal = () => {
        this.setState({
            isSubmited: false,
            loadPanelVisible: false,
            userModal: false,
            id: 0,
            firstName: "",
            lastName: "",
            accountId: 0,
            userTypeId: 0,
            userName: "",
            newPassword: "",
            confirmPassword: "",
            contactNo: "",
            email: "",
            active: false,
            passwordEncryptKey: "",
            errors: {
                firstName: "",
                lastName: "",
                userTypeId: 0,
                userName: "",
                newPassword: "",
                confirmPassword: "",
                contactNo: "",
                email: "",
                accountId: 0.
            }
        });
    }

    handleValidation = () => {
        let formIsValid = true;
        try {
            let errors = this.state.errors;
            errors.accountId = "";
            errors.userName = "";
            errors.userTypeId = "";
            errors.firstName = "";
            errors.lastName = "";
            errors.newPassword = "";
            errors.confirmPassword = "";
            errors.email = "";
            errors.contactNo = "";

            if (this.state.accountId === 0) {
                formIsValid = false;
                errors.accountId = "Please select account.";
            }
            if (this.state.userName.trim() === '') {
                formIsValid = false;
                errors.userName = "Please enter username.";
            }
            if (this.state.userTypeId === 0) {
                formIsValid = false;
                errors.userTypeId = "Please select user type.";
            }
            if (this.state.firstName.trim() === '') {
                formIsValid = false;
                errors.firstName = "Please enter first name.";
            }
            if (this.state.lastName.trim() === '') {
                formIsValid = false;
                errors.lastName = "Please enter last name.";
            }
            if (this.state.userTypeId === 0) {
                formIsValid = false;
                errors.userTypeId = "Please select user type.";
            }
            if (Number(this.state.id) === 0) {
                if (this.state.newPassword === '') {
                    formIsValid = false;
                    errors.newPassword = "Please enter password.";
                }
            }
            if (Number(this.state.id) === 0) {
                if (this.state.confirmPassword === '') {
                    formIsValid = false;
                    errors.confirmPassword = "Please enter re-type password.";
                }
            }
            if (Number(this.state.id) === 0) {
                if (this.state.confirmPassword !== this.state.newPassword) {
                    formIsValid = false;
                    errors.confirmPassword = "Password does not match.";
                }
            }

            if (this.state.contactNo === null || this.state.contactNo.trim() === "") {
                formIsValid = false;
                errors.contactNo = "Please enter contact number.";
            } else if (this.state.contactNo.trim().replace("(", "").replace(")", "").replace("-", "").replaceAll(" ", "").length !== 10) {
                formIsValid = false;
                errors.contactNo = "Invalid contact number.";
            }
            if (this.state.email === null || this.state.email.trim() === '') {
                formIsValid = false;
                errors.email = "Please enter email.";
            } else if (this.state.email !== '') {
                if (!regEmail.test(this.state.email)) {
                    formIsValid = false;
                    errors.email = "Please enter valid email.";
                }
            }

            if (this.state.newPassword.trim() !== '' && vIsPasswordComplexity === true) {
                const renumbersymbolexp = /[^0-9]/g;
                const lowercaseexp = /[^a-z]/g;
                const uppercaseexp = /[^A-Z]/g;
                const specialcharacters = /[^!@#$%^&*]/g;

                var IsPwdnumbersymbol = this.state.newPassword.replace(renumbersymbolexp, "").length;
                var IsLowercase = this.state.newPassword.replace(lowercaseexp, "").length;
                var IsUppercase = this.state.newPassword.replace(uppercaseexp, "").length;
                var IsSpecialCharacters = this.state.newPassword.replace(specialcharacters, "").length;

                if (this.state.newPassword.length < vMinLength) {
                    formIsValid = false;
                    errors.newPassword = "Password must be " + vMinLength + " characters long.";
                }
                else if (IsPwdnumbersymbol < vMinNumber) {
                    formIsValid = false;
                    errors.newPassword = "Password must contains atleast " + vMinNumber + " number.";
                }
                else if (IsLowercase < vMinLowerCaseLetter) {
                    formIsValid = false;
                    errors.newPassword = "Password must contains atleast " + vMinLowerCaseLetter + " lowercase alphabet.";
                }
                else if (IsUppercase < vMinUpperCaseLetter) {
                    formIsValid = false;
                    errors.newPassword = "Password must contains atleast " + vMinUpperCaseLetter + " uppercase alphabet.";
                }
                else if (IsSpecialCharacters < vMinSpecialCharacter) {
                    formIsValid = false;
                    errors.newPassword = "Password must contains atleast " + vMinSpecialCharacter + " special character.";
                }
            }
            this.setState({ errors: errors });
        } catch (err) {
            console.log("Error in " + this.displayName + ".isFormhandleValidationValid function", err);
            formIsValid = false;
        }
        return formIsValid;
    }

    handleUserSubmit = async (e) => {
        e.preventDefault();
        try {
            if (this.handleValidation()) {
                this.setState({ isSubmited: true });
                var newObject = {};
                newObject.AccountId = parseInt(this.state.accountId);
                newObject.UserId = this.state.id;
                newObject.UserTypeId = parseInt(this.state.userTypeId);
                newObject.UserName = this.state.userName.trim();
                newObject.Password = this.state.newPassword.trim();
                newObject.FirstName = this.state.firstName.trim();
                newObject.LastName = this.state.lastName.trim();
                newObject.Email = this.state.email.trim();
                newObject.ContactNo = this.state.contactNo.trim();
                newObject.Active = this.state.active;
                newObject.Source = "Web";
                newObject.CreatedBy = this.state.sessionUser.id;
                newObject.UpdatedBy = this.state.sessionUser.id;
                newObject.CreatedOn = formatDate(new Date(), 'MM/dd/yyyy hh:mm');
                newObject.UpdatedOn = formatDate(new Date(), 'MM/dd/yyyy hh:mm');
                const requestParams = getRequestParams('POST', newObject);
                const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'users/' + this.state.id + '/user', requestParams);
                const data = await response.json();
                if (data.isSuccess) {
                    Swal.fire({
                        icon: 'success',
                        title: 'Success',
                        text: data.message,
                    }).then((result) => {
                        if (result.isConfirmed) {
                            this.closeUserModal();
                            setTimeout(() => this.getUsers(), 500);
                        }
                        this.setState({ isSubmited: false })
                    })
                }
                else {
                    toast.error(data.message, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        style: { width: "310px" },
                        hideProgressBar: false,
                        newestOnTop: false,
                        closeButton: false
                    });
                    this.setState({ isSubmited: false })
                }
            }
        } catch (err) {
            console.log("Error in " + this.displayName + ".handleUserSubmit function", err);
            this.setState({ isSubmited: false })
        }
    }

    editUserModal = (e, data) => {
        e.preventDefault();
        if (data !== null) {
            this.setState({
                userModal: true,
                singelData: data,
                id: data.id,
                firstName: data.firstName,
                lastName: data.lastName,
                accountId: data.accountId,
                userTypeId: data.userTypeId,
                userName: data.userName,
                contactNo: data.contactNo,
                email: data.email,
                active: data.active,
            });
        }
    }

    onExporting = (e) => {
        const doc = new jsPDF();
        if (e.format === "pdf") {
            exportDataGrid({
                jsPDFDocument: doc,
                component: e.component,
                columnWidths: 50
                // indent: 10,
            }).then(() => {
                doc.save('Users.pdf');
            });
        }
    }
handleClose = ()=>{
    this.setState({
        filterAccountId: 0,
        filterUserTypeId: 0,
        filterUserName: ""
    });
    setTimeout(() => this.getUsers(), 500);
    //this.getUsers();
}
    
    render() {
        const renderGridCell = (data) => {
            return <div>
                <a href="/#" className="fas fa-pen" title="Edit" onClick={(e) => { this.editUserModal(e, data.data) }} >&nbsp;</a>
                <a href="/#" className="fas fa-lock-open" title="Change Password" onClick={(e) => { this.openchangesPasswordModel(e, data.data) }} >&nbsp;</a>
            </div>;
        }

        return (
            <Fragment>
                <div className="section-body">
                    <div className='card bg-filter'>
                        <div className='card-body'>
                            <div className="section-header">
                                <div className="row">
                                    <div className="col-lg-4">
                                        <div className="form-group">
                                            <label htmlFor="">Account</label>
                                            <SelectBox
                                                dataSource={this.state.accountList}
                                                className="basic-multi-select"
                                                classNamePrefix="select"
                                                placeholder="Select account"
                                                name="id"
                                                defaultValue={0}
                                                value={this.state.filterAccountId}
                                                displayExpr='name'
                                                valueExpr='id'
                                                onSelectionChanged={this.handleFilterAccountChange}
                                                showClearButton={true}
                                                disabled={this.state.id > 0}
                                                searchEnabled={true}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-2">
                                        <div className="form-group">
                                            <label>User Type </label>
                                            <SelectBox
                                                dataSource={this.state.userTypeList}
                                                className="basic-multi-select"
                                                classNamePrefix="select"
                                                placeholder="Select user type"
                                                name="id"
                                                defaultValue={0}
                                                value={this.state.filterUserTypeId}
                                                displayExpr='name'
                                                valueExpr='id'
                                                onSelectionChanged={this.handleFilterUserTypeChange}
                                                showClearButton={true}
                                                disabled={false}
                                                searchEnabled={true}
                                            />
                                            {/* {this.state.errors["userTypeId"].length > 0 && <span className="error invalid-feedback">{this.state.errors["userTypeId"]}</span>} */}
                                        </div>
                                    </div>
                                    <div className="col-lg-2">
                                        <div className="form-group">
                                            <label>Username</label>
                                            <input type="text" className="form-control form-control-sm" name="filterUserName" placeholder="Enter username" value={this.state.filterUserName}
                                                title="filterUserName" maxLength="50" onChange={this.handleInputChange} />
                                            {/* {this.state.errors["email"].length > 0 && <span className="error invalid-feedback">{this.state.errors["email"]}</span>} */}
                                        </div>
                                    </div>
                                    <div className="col-lg-2" style={{marginTop : 15 + 'px'}}>
                                        <div className="form-group">
                                            <button type="New" className="btn btn-success" onClick={this.getUsers}>
                                                Search
                                            </button>&nbsp;&nbsp;
                                            <button type="New" className="btn btn-outline-secondary" onClick={this.handleClose}>
                                                Clear
                                            </button>&nbsp;&nbsp;
                                            <button type="submit" className="btn btn-success" onClick={this.handleUserModal}>
                                                New
                                            </button>  
                                            
                                        </div>
                                    </div>
                                </div>
                                <div className="section-header-buttons">  
                                                                
                                    {/* <a href="#" className="btn btn-outline-secondary" title="Filter"><i className="fas fa-filter"></i></a>
                                    <div className="btn-group">
                                        <button type="button" title="More" className="btn btn-outline-secondary dropdown-toggle dropdown-icon" data-toggle="dropdown" aria-expanded="false">
                                            <i className="fas fa-ellipsis-h"></i>
                                        </button>
                                        <div className="dropdown-menu" role="menu">
                                            <a className="dropdown-item" href="#"><i className="fas fa-file-import"></i> Import</a>
                                            <a className="dropdown-item" href="#"><i className="fas fa-file-import"></i> Export</a>
                                            <a className="dropdown-item" href="#"><i className="fas fa-print"></i> Print</a>
                                            <a className="dropdown-item" href="#"><i className="fas fa-envelope"></i> Email</a>
                                        </div>
                                    </div> */}
                                </div>
                            </div>                            
                        </div>
                    </div>
                    <div className='card'>
                    <div className='card-body'>
                                <DataGrid
                                    dataSource={this.state.userSource}
                                    keyExpr="id"
                                    id='gridUsersList'
                                    selection={{ mode: 'single' }}
                                    columnsAutoWidth={false}
                                    showColumnLines={true}
                                    showRowLines={true}
                                    showBorders={true}
                                    rowAlternationEnabled={false}
                                    allowColumnResizing={true}
                                    columnResizingMode='widget'
                                    onExporting={this.onExporting}
                                  onToolbarPreparing={this.onToolbarPreparing}>
                                    <Column dataField="id" width={55} caption="" allowFiltering={false} allowSorting={false} cellRender={renderGridCell} />                                    
                                    <Column dataField="accountName" caption="Account" allowSearch={true} allowFiltering={false} allowSorting={true} width={400} />
                                    {/* <Column dataField="firstName" caption="First Name" allowSearch={true} allowFiltering={false} allowSorting={true} width={150} />
                                    <Column dataField="lastName" caption="Last Name" allowSearch={true} allowFiltering={false} allowSorting={true} width={150} /> */}
                                    <Column dataField="userTypeName" caption="User Type" allowSearch={true} allowFiltering={false} allowSorting={true} width={150} />
                                    <Column dataField="userName" caption="Username" allowSearch={true} allowFiltering={false} allowSorting={true} width={150} />
                                    <Column dataField="fullName" caption="Name" allowSearch={true} allowFiltering={false} allowSorting={true} width={180} />
                                    <Column dataField="contactNo" caption="Contact Number" allowSearch={true} allowFiltering={false} allowSorting={true} width={120} />
                                    <Column dataField="email" caption="Email" allowSearch={true} allowFiltering={false} allowSorting={true} width={300} />
                                    <SortByGroupSummaryInfo summaryItem="count" />
                                    <GroupPanel visible={false} />
                                    {/* <Template name="toolbarTemplate" /> */}
                                    <HeaderFilter visible={true} />
                                    <SearchPanel visible={true} highlightCaseSensitive={true} width={240} placeholder="Search..." />
                                    <Grouping autoExpandAll={true} />
                                    <Paging defaultPageSize={20} />
                                    <Pager showPageSizeSelector={true} allowedPageSizes={[10, 20, 50]} showInfo={true} visible={true} />
                                    <Export enabled={true}  allowExportSelectedData={false} fileName="Users" />
                                </DataGrid>
                            </div>
                    </div>
                </div>

                <Modal ariaHideApp={false}
                    isOpen={this.state.userModal}
                    style={customStyles}
                    className={"react-modal"}
                    onRequestClose={this.closeUserModal}
                    shouldCloseOnOverlayClick={false}
                    contentLabel="Example Modal">
                    <form method="post" onSubmit={this.handleUserSubmit} style={this.state.isSubmited ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                        <div className="modal-header">
                            <h6 className="modal-title">{this.state.id > 0 ? "Update User" : "New User"}</h6>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <label htmlFor="">Account <span className="text-danger">*</span></label>
                                        <SelectBox
                                            dataSource={this.state.accountList}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select account"
                                            name="id"
                                            defaultValue={0}
                                            value={this.state.accountId}
                                            displayExpr='name'
                                            valueExpr='id'
                                            onSelectionChanged={this.handleAccountChange}
                                            showClearButton={true}
                                            disabled={this.state.id > 0}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["accountId"].length > 0 && <span className="error invalid-feedback">{this.state.errors["accountId"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div className="form-group">
                                        <label>First Name <span className="text-danger">*</span></label>
                                        <input type="text" className="form-control form-control-sm" name="firstName" placeholder="e.g. John" value={this.state.firstName}
                                            title="First Name" maxLength="50" onChange={this.handleInputChange} />
                                        {this.state.errors["firstName"].length > 0 && <span className="error invalid-feedback">{this.state.errors["firstName"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div className="form-group">
                                        <label>Last Name <span className="text-danger">*</span></label>
                                        <input type="text" className="form-control form-control-sm" name="lastName" placeholder="e.g. Dave" value={this.state.lastName}
                                            title="Last Name" maxLength="50" onChange={this.handleInputChange} />
                                        {this.state.errors["lastName"].length > 0 && <span className="error invalid-feedback">{this.state.errors["lastName"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div className="form-group">
                                        <label>User Type <span className="text-danger">*</span></label>
                                        <SelectBox
                                            dataSource={this.state.userTypeList}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select user type"
                                            name="id"
                                            defaultValue={0}
                                            value={this.state.userTypeId}
                                            displayExpr='name'
                                            valueExpr='id'
                                            onSelectionChanged={this.handleUserTypeChange}
                                            showClearButton={true}
                                            disabled={false}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["userTypeId"].length > 0 && <span className="error invalid-feedback">{this.state.errors["userTypeId"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div className="form-group">
                                        <label>Username <span className="text-danger">*</span></label>
                                        <input type="text" className="form-control form-control-sm" name="userName" placeholder="Enter username" value={this.state.userName}
                                            disabled={this.state.id > 0} title="Username" maxLength="50" onChange={this.handleInputChange} />
                                        {this.state.errors["userName"].length > 0 && <span className="error invalid-feedback">{this.state.errors["userName"]}</span>}
                                    </div>
                                </div>
                                <div className={this.state.id > 0 ? "col-lg-3 displayNone" : "col-lg-3"}>
                                    <div className="form-group">
                                        <label>Password <span className="text-danger">*</span></label>
                                        <input type="password" className="form-control form-control-sm" name="newPassword" placeholder="Enter password" value={this.state.newPassword}
                                            title="Password" maxLength="150" onChange={this.handleInputChange} />
                                        {this.state.errors["newPassword"].length > 0 && <span className="error invalid-feedback">{this.state.errors["newPassword"]}</span>}
                                    </div>
                                </div>
                                <div className={this.state.id > 0 ? "col-lg-3 displayNone" : "col-lg-3"}>
                                    <div className="form-group">
                                        <label>Re-type Password <span className="text-danger">*</span></label>
                                        <input type="password" className="form-control form-control-sm" name="confirmPassword" placeholder="Re-type password" value={this.state.confirmPassword}
                                            title="Confirm Password" maxLength="150" onChange={this.handleInputChange} />
                                        {this.state.errors["confirmPassword"].length > 0 && <span className="error invalid-feedback">{this.state.errors["confirmPassword"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div className="form-group">
                                        <label>Email <span className="text-danger">*</span></label>
                                        <input type="text" className="form-control form-control-sm" name="email" placeholder="e.g. user@domain.com" value={this.state.email}
                                            title="Email" maxLength="50" onChange={this.handleInputChange} />
                                        {this.state.errors["email"].length > 0 && <span className="error invalid-feedback">{this.state.errors["email"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div className="form-group">
                                        <label>Contact Number <span className="text-danger">*</span></label>
                                        <PatternFormat className="form-control form-control-sm" format="(###) #### ###" name="contactNo"
                                            placeholder="Enter contact number" value={this.state.contactNo}
                                            title="Contact Number" onChange={this.handleInputChange} />
                                        {this.state.errors["contactNo"].length > 0 && <span className="error invalid-feedback">{this.state.errors["contactNo"]}</span>}
                                    </div>
                                </div>
                                <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-3" style={{ display: this.state.id <= 0 ? 'none' : 'block',}}>
                                    <div className="form-group">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" id="active" name="active" checked={this.state.active} onChange={this.handleInputChange} />
                                            <label className="form-check-label" htmlFor="active" style={{ fontSize: "inherit" }}> Active &nbsp;</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="submit" className="btn btn-success" onClick={this.handleUserSubmit} >
                                {this.state.isSubmited && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                {!this.state.isSubmited && <span title="Submit">Submit</span>}
                            </button>
                            <button type="button" className="btn btn-outline-secondary" onClick={this.closeUserModal} title="Cancel">Cancel</button>
                        </div>
                    </form>
                </Modal>
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={position}
                    visible={this.state.loadPanelVisible}
                    showIndicator={true}
                    shading={true}
                    showPane={true}
                    hideOnOutsideClick={false}
                />
                <ChangePasswordComponent ref="changepasswordcomponent" />
            </Fragment>
        )
    };
}