import React, { Fragment, Component } from 'react';

export class ItemAttributeComponent extends Component {
    static displayName = ItemAttributeComponent.name;

    constructor(props) {
        super(props);

        this.state = {
            isSubmited: false,
            loadPanelVisible: false,
        }
    }

    render() {
        return (
            <Fragment>
                <div></div>
            </Fragment>
        )
    };
}
