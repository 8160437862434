import React, { Fragment, Component } from 'react';
import DataGrid, {
    Column,
    Export, Grouping,
    GroupPanel,
    HeaderFilter, Pager,
    Paging,
    SearchPanel,
    SortByGroupSummaryInfo
} from 'devextreme-react/data-grid'; //npm i devextreme-react
import Modal from 'react-modal'; //npm install --save react-modal;
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import { getRequestParams, formatDate } from '../../helpers/globalfunctions.js';
import { exportFormats, symbolFormats } from '../../helpers/fixcodes.js';
import { alphanumericValidation } from '../../helpers/globalvalidations.js';
import { LoadPanel } from 'devextreme-react/load-panel';
import Swal from 'sweetalert2'; //npm i sweetalert2 --save
import { toast } from 'react-toastify'; //npm i react-toastify --save
import NumberBox from 'devextreme-react/number-box';
import TextArea from 'devextreme-react/text-area';
import DateBox from 'devextreme-react/date-box';
import { SelectBox } from 'devextreme-react/select-box';
import { jsPDF } from 'jspdf';
import { exportDataGrid } from 'devextreme/pdf_exporter';
const position = { of: '#historydiv' };

const customStyles = {
    content: {
        width: '30%',
    }
}

export class TaxComponent extends Component {
    static displayName = TaxComponent.name;

    constructor(props) {
        super(props);

        this.state = {
            isSubmited: false,
            loadPanelVisible: false,
            taxModelIsOpen: false,
            id: 0,
            taxsource: [],
            taxtypesource: [],
            taxTypeId: 0,
            name: "",
            code: "",
            percentage: 0,
            wefDate: new Date(),
            description: "",
            filterTaxTypeId: 0,
            filterWefDate: "",
            createdOn: new Date(),
            updatedOn: new Date(),
            sessionUser: JSON.parse(sessionStorage.getItem("sessionUser")),
            errors: {
                taxTypeId: 0,
                name: "",
                code: "",
                percentage: 0,
                wefDate: "",
                description: "",
            }
        }
    }

    async componentDidMount() {
        await this.getTaxType();
        await this.getTaxMaster();
    }

    getTaxType = async () => {
        this.setState({
            loadPanelVisible: true
        });
        try {
            var request = {};
            const requestParams = getRequestParams("GET", request);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'tax/getType/', requestParams);
            const data = await response.json();
            this.setState({
                taxtypesource: data.payload,
                loadPanelVisible: false
            });
        } catch (err) {
            console.log("Error in " + this.displayName + ".getTaxMaster function", err);
            this.setState({
                loadPanelVisible: false
            });
        }
    }

    getTaxMaster = async () => {
        this.setState({
            loadPanelVisible: true
        });
        try {
            var request = {};
            request.Id = parseInt(0);
            request.TaxTypeId = parseInt(this.state.filterTaxTypeId);
            request.WefDate = this.state.filterWefDate === "" ? "" : formatDate(this.state.filterWefDate, "dd/MM/yyyy");

            const requestParams = getRequestParams("POST", request);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'tax/getTax/', requestParams);
            const data = await response.json();
            this.setState({
                taxsource: data.payload,
                loadPanelVisible: false
            });
        } catch (err) {
            console.log("Error in " + this.displayName + ".getTaxMaster function", err);
            this.setState({
                loadPanelVisible: false
            });
        }
    }

    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift({
            location: 'after',
            widget: 'dxButton',
            options: {
                icon: 'refresh',
                onClick: this.getTaxMaster.bind(this)
            }
        });
    }

    handleClear = () => {
        this.setState({
            isSubmited: false,
            loadPanelVisible: false,
            taxsource: [],
            filterTaxTypeId: 0,
            filterWefDate: "",
        });

        setTimeout(() => this.getTaxMaster(), 500);
    }

    onExporting = (e) => {
        const doc = new jsPDF();
        if (e.format === "pdf") {
            exportDataGrid({
                jsPDFDocument: doc,
                component: e.component,
                columnWidths: 50,
                // indent: 10,
            }).then(() => {
                doc.save(e.fileName);
            });
        }
    }

    handleTaxTypeChange = (selectedOption) => {
        this.setState({
            taxTypeId: (selectedOption.selectedItem !== null ? selectedOption.selectedItem.id : 0),
        });
    }

    handleFilterTaxTypeChange = (selectedOption) => {
        this.setState({
            filterTaxTypeId: (selectedOption.selectedItem !== null ? selectedOption.selectedItem.id : 0),
        });
    }

    handleWefDateChanged = (date) => {
        this.setState({ eefDate: date.value });
    }

    handleFilterWefDateChanged = (date) => {
        this.setState({ filterWefDate: date.value });
    }

    handleTextAreaValueChanged = (event) => {
        this.setState({
            description: event.value,
        });
    }

    handlePercentageChange = (event) => {
        const newValue = event.value;
        this.setState({ "percentage": newValue });
    }

    handleTaxModal = (e) => {
        this.setState({
            taxModelIsOpen: true,
            id: 0,
            singelData: null
        });
    }

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        //const alphanumericValidation = /^[A-Za-z0-9]*$/;
        switch (name) {
            case 'Name':
                if (alphanumericValidation.test(event.target.value))
                    this.setState({ name: event.target.value });
                break;
            case 'Description':
                if (alphanumericValidation.test(event.target.value))
                    this.setState({ description: event.target.value });
                break;
            case 'Code':
                if (alphanumericValidation.test(event.target.value))
                    this.setState({ code: event.target.value });
                break;
            default:
                this.setState({ [name]: value });
                break;
        }
    }

    handleValidation = () => {
        let formIsValid = true;
        try {
            let errors = this.state.errors;
            errors.name = "";
            errors.taxTypeId = 0;
            errors.name = "";
            errors.code = "";
            errors.percentage = "";
            errors.wefDate = "";

            if (this.state.name.trim() === "") {
                formIsValid = false;
                errors.name = "Please enter tax name.";
            }
            if (this.state.taxTypeId === 0) {
                formIsValid = false;
                errors.taxTypeId = "Please select tax type.";
            }
            if (this.state.code === "") {
                formIsValid = false;
                errors.code = "Please enter tax code.";
            }
            if (this.state.percentage === 0) {
                formIsValid = false;
                errors.percentage = "Please enter tax percentage.";
            }
            if (this.state.wefDate === "") {
                formIsValid = false;
                errors.wefDate = "Please enter wef date.";
            }
            this.setState({ errors: errors });
        }
        catch (err) {
            console.log("Error in " + this.displayName + ".handleValidation function", err);
            this.setState({ isSubmited: false })
        }
        return formIsValid;
    }

    handleTaxSubmit = async (e) => {
        e.preventDefault();
        try {
            if (this.handleValidation()) {
                this.setState({ isSubmited: true });

                var newObject = {};
                newObject.TaxTypeId = parseInt(this.state.taxTypeId);
                newObject.Name = this.state.name.trim();
                newObject.Code = this.state.code;
                newObject.Percentage = parseFloat(this.state.percentage);
                newObject.WefDate = formatDate(this.state.wefDate, "MM/dd/yyyy hh:mm");
                newObject.Description = this.state.description;
                newObject.CreatedBy = parseInt(this.state.sessionUser.id);
                newObject.CreatedOn = formatDate(this.state.createdOn, "MM/dd/yyyy hh:mm");
                newObject.UpdatedBy = parseInt(this.state.sessionUser.id);
                newObject.updatedOn = formatDate(this.state.updatedOn, "MM/dd/yyyy hh:mm");
                const requestParams = getRequestParams('POST', newObject);

                const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'tax/' + this.state.id + '/tax', requestParams);
                const data = await response.json();
                if (data.isSuccess) {
                    Swal.fire({
                        icon: 'success',
                        title: 'Success',
                        text: data.message,
                    }).then((result) => {
                        if (result.isConfirmed) {
                            this.getTaxMaster();
                            this.closeTaxModal();
                        }
                        this.setState({ isSubmited: false })
                    })
                }
                else {
                    toast.error(data.message, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        style: { width: "310px" },
                        hideProgressBar: false,
                        newestOnTop: false,
                        closeButton: false
                    });
                    this.setState({ isSubmited: false })
                }
            }
        }
        catch (err) {
            console.log("Error in " + this.displayName + ".handleTaxSubmit function", err);
            this.setState({ isSubmited: false })
        }
    }

    closeTaxModal = () => {
        this.setState({
            isSubmited: false,
            loadPanelVisible: false,
            taxModelIsOpen: false,
            taxTypeId: 0,
            name: "",
            code: "",
            percentage: 0,
            wefDate: new Date(),
            description: "",
            filterTaxTypeId: 0,
            filterWefDate: "",
            errors: {
                taxTypeId: 0,
                name: "",
                code: "",
                percentage: 0,
                wefDate: "",
                description: "",
            }
        });
    }

    editTaxModal = (e, data) => {
        e.preventDefault();
        if (data != null) {
            this.setState({
                taxModelIsOpen: true,
                singelData: data,
                id: data.id,
                taxTypeId: data.taxTypeId,
                name: data.name,
                code: data.code,
                percentage: data.percentage,
                wefDate: data.wefDate,
                description: data.description,
            });
        }
    }

    render() {
        const renderGridCell = (data) => {
            return <div>
                <a href="#" className="fas fa-pen" title="Edit" onClick={(e) => { this.editTaxModal(e, data.data) }} ></a>&nbsp;&nbsp;
            </div>;
        }

        const renderGridNumberCell = (data) => {
            return <div>{parseFloat(data.value).toFixed(2)}&nbsp;&nbsp;<small><i class={symbolFormats.percentageFormat}></i></small></div>;
        }

        return (
            <Fragment>
                <div className="section-body">
                    <div className='card bg-filter'>
                        <div className='card-body'>
                            <div className="section-header">
                                <div className='row'>
                                    <div className='col-lg-2 col-md-2 col-sm-12'>
                                        <div className="form-group">
                                            <label htmlFor="">Tax Type</label>
                                            <SelectBox
                                                dataSource={this.state.taxtypesource}
                                                className="basic-multi-select"
                                                classNamePrefix="select"
                                                placeholder="Select tax type"
                                                name="id"
                                                defaultValue={0}
                                                value={this.state.filterTaxTypeId}
                                                displayExpr='name'
                                                valueExpr='id'
                                                onSelectionChanged={this.handleFilterTaxTypeChange}
                                                showClearButton={true}
                                                searchEnabled={true}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-lg-2 col-md-2 col-sm-12'>
                                        <div className="form-group">
                                            <label htmlFor="">WEF Date</label>
                                            <DateBox type="date"
                                                className="wef-date-time"
                                                name="wefDate"
                                                placeholder="dd/mm/yyyy"
                                                displayFormat="dd/MM/yyyy"
                                                showAnalogClock={true}
                                                useMaskBehavior={true}
                                                defaultValue="dd/MM/yyyy"
                                                value={this.state.filterWefDate}
                                                onValueChanged={this.handleFilterWefDateChanged}
                                                width="100%"
                                                acceptCustomValue={false}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-3 col-sm-12 col-xs-12 pl-0">
                                        <div className="form-group">
                                            <label htmlFor="">&nbsp;</label><br />
                                            <button type="submit" className="btn btn-success" onClick={this.getTaxMaster}>
                                                Search
                                            </button>&nbsp;&nbsp;
                                            <button type="submit" className="btn btn-outline-secondary" onClick={this.handleClear}>
                                                Clear
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div>

                                    <button type="submit" className="btn btn-success" onClick={this.handleTaxModal}>
                                        New
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='card'>
                        <div className='card-body'>
                            <DataGrid
                                dataSource={this.state.taxsource}
                                keyExpr="id"
                                id='gridTxMasterList'
                                selection={{ mode: 'single' }}
                                columnsAutoWidth={false}
                                showColumnLines={true}
                                showRowLines={true}
                                showBorders={true}
                                rowAlternationEnabled={false}
                                allowColumnResizing={true}
                                columnResizingMode='widget'
                                onExporting={this.onExporting}
                                onToolbarPreparing={this.onToolbarPreparing}>
                                <Column dataField="id" width={30} caption="" allowFiltering={false} allowSorting={false} cellRender={renderGridCell} />
                                <Column dataField="name" caption="Name" allowSearch={true} allowFiltering={false} allowSorting={true} width={100} />
                                <Column dataField="taxType" caption="Tax Type" allowSearch={true} allowFiltering={false} allowSorting={true} width={100} />
                                <Column dataField="code" caption="Tax Code" allowSearch={true} allowFiltering={false} allowSorting={true} width={80} />
                                <Column dataField="percentage" caption="Percentage(%)" allowSearch={true} allowFiltering={false} allowSorting={true} width={120} dataType="number" cellRender={renderGridNumberCell} />
                                <Column dataField="description" caption="Description" allowSearch={true} allowFiltering={false} allowSorting={true} width={350} />
                                <Column dataField="wefDate" caption="WEF Date" allowSearch={true} allowFiltering={false} allowSorting={true} width={100} dataType="datetime" format="dd/MM/yyyy" />
                                <SortByGroupSummaryInfo summaryItem="count" />
                                <GroupPanel visible={false} />
                                {/* <Template name="toolbarTemplate" /> */}
                                <HeaderFilter visible={true} />
                                <SearchPanel visible={true} highlightCaseSensitive={true} width={240} placeholder="Search..." />
                                <Grouping autoExpandAll={true} />
                                <Paging defaultPageSize={20} />
                                <Pager showPageSizeSelector={true} allowedPageSizes={[10, 20, 50]} showInfo={true} visible={true} />
                                <Export enabled={true} allowExportSelectedData={false} fileName="TaxMaster" />
                            </DataGrid>
                        </div>
                    </div>
                </div>

                <Modal ariaHideApp={false}
                    isOpen={this.state.taxModelIsOpen}
                    style={customStyles}
                    className={"react-modal"}
                    //onAfterOpen={this.handleAssignDataToState}
                    onRequestClose={this.closeTaxModal}
                    shouldCloseOnOverlayClick={false}
                    contentLabel="Example Modal">
                    <form method="post" onSubmit={this.handleTaxSubmit} style={this.state.isSubmited ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                        <div className="modal-header">
                            <h6 className="modal-title">{this.state.id > 0 ? "Update Tax" : "New Tax"}</h6>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <label htmlFor="">Tax Name <span className="text-danger">*</span></label>
                                        <input type="text" className="form-control form-control-sm" name="Name" placeholder="Enter name" maxLength="50"
                                            title="Name" value={this.state.name}
                                            onChange={this.handleInputChange} />
                                        {this.state.errors["name"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["name"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <label>Tax Type<span className="text-danger">*</span></label>
                                        <SelectBox
                                            dataSource={this.state.taxtypesource}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select tax type"
                                            name="id"
                                            defaultValue={0}
                                            value={this.state.taxTypeId}
                                            displayExpr='name'
                                            valueExpr='id'
                                            onSelectionChanged={this.handleTaxTypeChange}
                                            showClearButton={true}
                                            disabled={false}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["taxTypeId"].length > 0 && <span className="error invalid-feedback">{this.state.errors["taxTypeId"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <label htmlFor="">Tax Code <span className="text-danger">*</span></label>
                                        <input type="text" className="form-control form-control-sm" name="Code" placeholder="Enter tax code" maxLength="20"
                                            title="Code" value={this.state.code}
                                            onChange={this.handleInputChange} />
                                        {this.state.errors["code"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["code"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <label htmlFor="">Percentage <span className="text-danger">*</span></label>
                                        <NumberBox type="number" className="form-control form-control-sm" name="Percentage" placeholder="Enter percentage"
                                            format="#0.00"
                                            defaultValue={0.00} title="Percentage" value={this.state.percentage} onValueChanged={this.handlePercentageChange}
                                            min={0.00} max={99.99} />
                                        {this.state.errors["percentage"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["percentage"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <label htmlFor="">Wef Date <span className="text-danger">*</span></label>
                                        <DateBox type="date"
                                            className="replay-date-time"
                                            name="wefDate"
                                            placeholder="dd/mm/yyyy"
                                            displayFormat="dd/MM/yyyy"
                                            showAnalogClock={true}
                                            useMaskBehavior={true}
                                            defaultValue={this.state.wefDate}
                                            value={this.state.wefDate}
                                            onValueChanged={this.handleWefDateChanged}
                                            width="100%"
                                            disabled={this.state.id > 0}
                                            acceptCustomValue={false}
                                        />
                                        {this.state.errors["wefDate"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["wefDate"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="form-group">
                                        <label htmlFor="">Description</label>
                                        <TextArea
                                            height={60}
                                            maxLength="200"
                                            className="form-control form-control-sm"
                                            name="Description" placeholder="Enter description"
                                            value={this.state.description}
                                            onValueChanged={this.handleTextAreaValueChanged}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="submit" className="btn btn-success" onClick={this.handleTaxSubmit} >
                                {this.state.isSubmited && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                {!this.state.isSubmited && <span title="Submit">Submit</span>}
                            </button>
                            <button type="button" className="btn btn-outline-secondary" onClick={this.closeTaxModal} title="Cancel">Cancel</button>
                        </div>
                    </form>
                </Modal>

                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={position}
                    visible={this.state.loadPanelVisible}
                    showIndicator={true}
                    shading={true}
                    showPane={true}
                    hideOnOutsideClick={false}
                />
            </Fragment>
        )
    };
}