
import DataGrid, {
    Column,
    Export, Grouping,
    GroupPanel,
    HeaderFilter, Pager,
    Paging,
    Scrolling,
    SearchPanel,
    SortByGroupSummaryInfo
} from 'devextreme-react/data-grid'; //npm i devextreme-react
import React, { Fragment, Component } from "react";
import { getRequestParams } from '../../helpers/globalfunctions.js';
import Modal from 'react-modal'; //npm install --save react-modal;
import { SelectBox } from 'devextreme-react/select-box';
import { createBrowserHistory } from 'history';
import { LoadPanel } from 'devextreme-react/load-panel';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify'; //npm i react-toastify --save
import { TextBox } from 'devextreme-react';
import { DateFormat } from '../../helpers/fixcodes.js';
const history = createBrowserHistory();

const customStyles = {
    content: {
        width: '40%',
    }
}

export class ContractItemComponent extends Component {
    static displayName = ContractItemComponent.name;

    constructor(props) {
        super(props);
        this.state = {
            contractitemList: [],
            sessionUser: JSON.parse(sessionStorage.getItem("sessionUser")),
            contractItemModelIsOpen: false,
            itemModelIsOpen: false,
            customerList: [],
            customerContractList: null,
            soNoAndRefList: null,
            vehicleAndSerialNoList: null,
            customerId: 0,
            id: 0,
            contractId: 0,
            stockItemId: 0,
            referenceSONo: "",
            serialNo : '',
            vehicleNo : "",
            //status : 0,            
            //referenceSONo: "",
            // SONo: 0,
            // SONo: "",
            soNo: "",
            vehicleDeviceList: [],
            selectedRowKey: [],
            addContractItemModel: false,
            itemQty :0,
            filterSerialNo :"",
            filterCustomerId :0,
            filterSoNo:"",

            errors: {
                customerId: "",
                soNo: "",
                referenceSONo: "",
                serialNo: "",
                vehicleNo: "",
            }
        }
    }

    //lifecycle page load
    async componentDidMount() {
        await this.getCustomerContract();
        await this.getCustomers();
    }

    getCustomerContract = async () => {
        try {
            var request = {};
            request.SONo = this.state.filterSoNo;
            request.CustomerId = this.state.filterCustomerId;
            const requestParams = getRequestParams("POST", request);
            
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'contractConfiguration/getCustomerContractDetails', requestParams);
            const data = await response.json();
            this.setState({
                customerContractList: data.payload
            })
        }
        catch (err) {
            console.log("Error in " + this.displayName + ".getContractItemList function", err);
        }
    }
    getCustomerContractBySerialNo = async () => {
        try {
            var request = {};
            request.SerialNo =this.state.filterSerialNo
            const requestParams = getRequestParams("POST", request);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'contractItem/ContractDataBySerialNo', requestParams);
            const data = await response.json();
            
            this.setState({
                filterCustomerId: data.payload.customerId,
                filterSoNo : data.payload.soNo 
            })
            setTimeout(() => {
                this.getCustomerContract()
            }, 100);
        }
        catch (err) {
            console.log("Error in " + this.displayName + ".getContractItemList function", err);
        }
    }
    getCustomers = async () => {
        try {
            var request = {};
            const requestParams = getRequestParams("GET", request);
            const response  = await fetch(process.env.REACT_APP_API_BASE_URL + 'account/dropdownAccount/2', requestParams);
            const data = await response.json();
            this.setState({
                customerList: data.payload
            });
        } catch (err) {
            console.log("Error in " + this.displayName + ".getAccount function", err);
        }
    }
    getSoNoAndReferenceNoByCustomerId = async () => {
        try {
            //;
            var request = {};
            const requestParams = getRequestParams("GET", null);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'contractItem/getSoNoAndReferenceNoByCustomerId/' +this.state.customerId , requestParams);
            const data = await response.json();
            this.setState({
                soNoAndRefList: data.payload[0].soNo === null ? [] :data.payload,
                contractId :data.payload[0].soNo === null ? data.payload[0].contractId : 0
            });
            if(data.payload[0].soNo === null){
                setTimeout(() => {
                    this.getItemQtyByCustomerId();
                }, 100);
            }
           
        } catch (err) {
            console.log("Error in " + this.displayName + ".getSoNoAndReferenceNoByCustomerId function", err);
        }
    }
    getItemQtyByCustomerId = async () => {
        try {
            var request = {};
            request.customerId = this.state.customerId;
            request.SONo = this.state.soNo;
            const requestParams = getRequestParams("POST", request);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'contractItem/getItemQtyByCustomer' , requestParams);
            const data = await response.json();
           
            this.setState({
                itemQty:data
            });
      } catch (err) {
            console.log("Error in " + this.displayName + ".getSoNoAndReferenceNoByCustomerId function", err);
        }
    }
    getVehicleNoAndSerialNo = async () => {
        try {
           var request = {};
            const requestParams = getRequestParams("GET", null);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'contractItem/getVehicleNoAndSerialNo' , requestParams);
            const data = await response.json();
            this.setState({
                vehicleAndSerialNoList: data.payload,
                //stockItemId :data.payload[0].serialNo === null ? data.payload[0].stockItemId : 0
            });
        } catch (err) {
            console.log("Error in " + this.displayName + ".getVehicleNoAndSerialNo function", err);
        }
    }
    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift({
            location: 'after',
            widget: 'dxButton',
            options: {
                icon: 'refresh',
                onClick: this.getCustomerContract.bind(this)
            }
        });
    }
    getContractdetail(e, data) {
        e.preventDefault();
        history.push({
            pathname: '/contractitemdetails',
            state: {
                customerId: data.customerId,
                soNo: data.soNo,
                contractId: data.id,
                contractTypeId: data.contractTypeId,
                contractStatusId: data.contractStatusId,
                billingTypeName: data.billingType,
                paymentTermTypeId: data.paymentTermsId,
                startDate: data.contractStart,
                endDate: data.contractEnd,
                sourceUrl: "/contractitem",
                sourceState: this.state
            }
        });
        window.location.reload(false);
    }
    handleCustomerChange = (selectedOption) => {
        this.setState({
            customerId: (selectedOption.selectedItem !== null ? selectedOption.selectedItem.id : 0)
        });
        //let errors = this.errors;
        if (selectedOption.selectedItem !== null && selectedOption.selectedItem.id > 0) {
            //errors.customerId = "";
            //this.state.errors["accountId"] = "";
            this.setState({
               // errors: errors
            });
            setTimeout(() => this.getSoNoAndReferenceNoByCustomerId(), 100);
            //setTimeout(() => this.getAccountTax(selectedOption.selectedItem.id), 100);
        }
    }
    handlesoNoChange = (selectedOption) => {
        
        this.setState({
            soNo: (selectedOption.selectedItem !== null ? selectedOption.selectedItem.soNo : ''),
            referenceSONo: (selectedOption.selectedItem !== null ? selectedOption.selectedItem.referenceSONo : ''),
            contractId: (selectedOption.selectedItem !== null ? selectedOption.selectedItem.contractId : 0),
        });
        setTimeout(() => {
            this.getItemQtyByCustomerId();
        }, 100);
        console.log(this.state.contractId);
    }
    // handleReferenceChange = (selectedOption) => {
    //     this.setState({
    //         referenceSONo: (selectedOption.selectedItem !== null ? selectedOption.selectedItem.referenceSONo : ''),
    //     });
    // }
    handleSerialNoChange = (selectedOption) => {
        
        this.setState({
            serialNo: (selectedOption.selectedItem !== null ? selectedOption.selectedItem.serialNo : ''),
            vehicleNo: (selectedOption.selectedItem !== null ? selectedOption.selectedItem.vehicleNo : ''),
            stockItemId: (selectedOption.selectedItem !== null ? selectedOption.selectedItem.stockItemId : 0),
        });
    }
    handleTextAreaValueChanged = (selectedOption) => {      
        this.setState({
            vehicleNo: (selectedOption.selectedItem !== null ? selectedOption.selectedItem.vehicleNo : ''),
        });
    }

    handleValidation = () => {
        let formIsValid = true;
        try {
            let errors = this.state.errors;
            errors.customerId = 0;
            errors.soNo = 0;
            errors.referenceSONo = 0;
            errors.serialNo = 0;
            errors.vehicleNo = 0;

            if (this.state.customerId === 0) {
                formIsValid = false;
                errors.customerId = "Please Select Customer.";
            }
            // if (this.state.soNo === 0) {
            //     formIsValid = false;
            //     errors.soNo = "Please Select SONo#";
            // }
            // if (this.state.referenceSONo === 0) {
            //     formIsValid = false;
            //     errors.referenceSONo = "Please Select ReferenceSONo#";
            // }
            if (this.state.serialNo === "") {
                formIsValid = false;
                errors.serialNo = "Please Select SerialNo.";
            }
            if (this.state.vehicleNo === "") {
                formIsValid = false;
                errors.vehicleNo = "Please Select VehicleNo.";
            }
            this.setState({ errors: errors });
        } catch (err) {
            console.log("Error in " + this.displayName + ".iscontractItemValidation Function", err);
        }
        return formIsValid;
    }

    handleContractItemSubmit = async (e) => {
        
        e.preventDefault();
        try {
            if (this.handleValidation()) {
                this.setState({ isSubmited: true });
             
             var newObject = {};
            newObject.CustomerId = parseInt(this.state.customerId);
            newObject.ContractId = parseInt(this.state.contractId);
            newObject.StockItemId = parseInt(this.state.stockItemId);
            newObject.SONo = this.state.soNo;
            newObject.ReferenceSONo = this.state.referenceSONo;
            newObject.SerialNo = this.state.serialNo;
            newObject.VehicleNo = this.state.vehicleNo;
            //newObject.Status = parseInt(this.state.status);
            const requestParams = getRequestParams('POST', newObject);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'contractItem/contractItemInsert', requestParams);
            const data = await response.json();

            if (data.isSuccess) {
                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: data.message,
                }).then((result) => {
                    if(result.isConfirmed){
                        this.closeContractItemModel();
                        //this.getCustomerActiveDevices();
                    }
                    this.setState({ isSubmited: false })
                })
            }
            else {
                toast.error(data.message, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    style: { width: "310px" },
                    hideProgressBar: false,
                    newestOnTop: false,
                    closeButton: false
                });
                this.setState({ isSubmited: false })
            }
        }
        }catch (err) {
            console.log("Error in " + this.displayName + ".handleContractItemSubmit function", err);
            this.setState({ isSubmited: false })        
    }
}
    newContractItem = () => {
        this.setState({
            addContractItemModel: true
        })
        this.getVehicleNoAndSerialNo();
    }

    closeContractItemModel = () => {
        this.setState({
            addContractItemModel: false,
            customerId: 0,
            id: 0,
            referenceSONo: 0,
            soNo: 0,
            serialNo: 0,
            vehicleNo: 0,
            errors: {
                customerId: 0,
                soNo: 0,
                referenceSONo: 0,
                serialNo: "",
                vehicleNo: "",

            }
        })
    }
    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        const alphanumericValidation = /^[A-Za-z0-9\s]*$/;
        switch (name) {
            case 'serialNo':
                if (alphanumericValidation.test(event.target.value))
                    this.setState({ filterSerialNo: event.target.value });
                break;
                default:
                    this.setState({ [name]: value });
                    break;
                }
        this.setState({ [name]: value });
     }
     handleClearSearch = ()=>{
         this.setState({
             filterSerialNo: "",
             filterCustomerId: 0,
             filterSoNo: ""
         });
        setTimeout(() => {
            this.getCustomerContract();
        }, 100);
     }
    render() {
        const renderItemGrid = (data) => {
            return <div className='text-center'>
                <a href="/#" className="fas fa-pen" title="Edit Contract" onClick={(e) => { this.getContractdetail(e, data.data) }} ></a>
            </div>;
        }
        return (
            <Fragment>
                <div div className="section-body">
                    <div className="card bg-filter">
                        <div className="card-body">
                            <div className="section-header">
                                <div className='row'>
                                    <div className="col-lg-3">
                                        <div className="form-group">
                                            <label>&nbsp;</label>
                                            {/* <SelectBox
                                                dataSource={this.state.paymentTermTypeList}
                                                className="basic-multi-select"
                                                classNamePrefix="select"
                                                placeholder="Select payment term"
                                                name="id"
                                                defaultValue={0}
                                                value={this.state.filterPaymentTermTypeId}
                                                displayExpr='name'
                                                valueExpr='id'
                                                onSelectionChanged={this.handleFilterPaymentTermTypeChange}
                                                showClearButton={true}
                                                disabled={false}
                                                searchEnabled={true}
                                            /> */}
                                           <input type="text" className="form-control form-control-sm" name="serialNo" placeholder="Enter SerialNo " value={this.state.filterSerialNo}
                                             title="serialNo" maxLength="50"  onChange={this.handleInputChange} />
                                        </div>
                                    </div>
                                    <div className='col-lg-6 pl-0'>
                                        <br />
                                        <button type="submit" className="btn btn-success" onClick={this.getCustomerContractBySerialNo}>
                                            Search
                                        </button>&nbsp;&nbsp;
                                        <button type="submit" className="btn btn-outline-secondary" onClick={this.handleClearSearch}>
                                            Clear
                                        </button>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                                </div>
                    <div className='card'>
                        <div className='card-body'>
                        {/* <button type="New" title="+ New" className="btn btn-success" onClick={this.newContractItem}>
                                            <span title="New"><i className="fas fa-plus"></i></span> New
                                        </button> */}
                            <DataGrid
                                dataSource={this.state.customerContractList}
                                keyExpr="id"
                                selection={{ mode: 'single' }}
                                columnsAutoWidth={false}
                                showColumnLines={true}
                                showRowLines={true}
                                showBorders={true}
                                rowAlternationEnabled={false}
                                allowColumnResizing={true}
                                columnResizingMode='widget'
                                onExporting={this.onExporting}
                            //onToolbarPreparing={this.onToolbarPreparing}
                            >
                                <Column dataField="ids" caption="" allowSearch={true} allowFiltering={false} allowSorting={true} width={30} cellRender={renderItemGrid} />
                                <Column dataField="id" caption="Contract Id" allowSearch={true} allowFiltering={false} allowSorting={true} width={100} />
                                <Column dataField="customerName" caption="Customer" allowSearch={true} allowFiltering={false} allowSorting={true} width={420} />
                                <Column dataField="soNo" caption="SO#" allowSearch={true} allowFiltering={false} allowSorting={true} width={100} />
                                <Column dataField="itemQty" caption="Item Qty" allowSearch={true} allowFiltering={false} allowSorting={true} width={100} />
                                <Column dataField="contractTypeName" caption="Contract Type" allowSearch={true} allowFiltering={false} allowSorting={true} width={130} />
                                <Column dataField="contractStart" caption="Contract Start" allowSearch={true} allowFiltering={false} allowSorting={true} width={130} format={DateFormat.DateNorthFormat} dataType="datetime" />
                                <Column dataField="contractEnd" caption="Contract End" allowSearch={true} allowFiltering={false} allowSorting={true} width={130} format={DateFormat.DateNorthFormat} dataType="datetime" />
                                <Column dataField="contractStatusName" caption="Status" allowSearch={true} allowFiltering={false} allowSorting={true} width={110} />
                                <Column dataField="paymentTermsName" caption="Payment Term" allowSearch={true} allowFiltering={false} allowSorting={true} width={100} />
                                {/* <Column dataField="comments" caption="Comment" allowSearch={true} allowFiltering={false} allowSorting={true} /> */}

                                <SortByGroupSummaryInfo summaryItem="count" />
                                <GroupPanel visible={false} />
                                {/* <Template name="toolbarTemplate" /> */}
                                <HeaderFilter visible={true} />
                                <Scrolling showScrollbar="always"/>
                                <SearchPanel visible={true} highlightCaseSensitive={true} width={240} placeholder="Search..." />
                                <Grouping autoExpandAll={true} />
                                <Paging defaultPageSize={20} />
                                <Pager showPageSizeSelector={true} allowedPageSizes={[10, 20, 50]} showInfo={true} visible={true} />
                                <Export enabled={false} allowExportSelectedData={false} fileName="Contract Item" />
                            </DataGrid>
                        </div>
                    </div>
                </div>
                <Modal ariaHideApp={false}
                    isOpen={this.state.addContractItemModel}
                    style={customStyles}
                    className={"react-modal"}
                    onRequestClose={this.closeContractItemModel}
                    shouldCloseOnOverlayClick={false}
                    contentLabel="Example Modal">
                    <form method="post" onSubmit={this.handleContractItemSubmit} style={this.state.isSubmited ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                        <div className="modal-header">
                            <h6 className="modal-title">{this.state.id > 0 ? "Update Contract Item" : "New Contract Item" }</h6>
                        </div>
                        <div className="modal-body">
                            <div className='row'>
                                <div className="col-lg-8">
                                    <div className="form-group">
                                        <label>Customer <span className="text-danger">*</span></label>
                                        <SelectBox
                                            dataSource={this.state.customerList}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select customer"
                                            name="id"
                                            defaultValue={0}
                                            value={this.state.customerId}
                                            displayExpr='name'
                                            valueExpr='id'
                                            onSelectionChanged={this.handleCustomerChange}
                                            showClearButton={true}
                                          //  disabled={this.state.customerId > 0}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["customerId"].length > 0 && <span className="error invalid-feedback">{this.state.errors["customerId"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-8">
                                    <div className="form-group">
                                        <label>SONo# <span className="text-danger">*</span></label>
                                        <SelectBox
                                            dataSource={this.state.soNoAndRefList}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select SONo#"
                                            name="id"
                                            defaultValue={0}
                                            value={this.state.soNo}
                                            displayExpr='soNo'
                                            valueExpr='soNo'
                                            onSelectionChanged={this.handlesoNoChange}
                                            showClearButton={true}
                                            //disabled={this.state.SONo > 0}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["soNo"].length > 0 && <span className="error invalid-feedback">{this.state.errors["soNo"]}</span>}
                                    </div>
                                </div> 
                               <div className="col-lg-4">
                                    <div className="form-group">
                                    <label>ReferenceSONo# <span className="text-danger">*</span></label>
                                      <input type="text" className="form-control form-control-sm" name="ReferenceSONo#" placeholder="Enter ReferenceSONo# " value={this.state.referenceSONo}
                                             title="Username" maxLength="50"  />
                                    </div>
                                </div>
                                {/* <div className="col-lg-8">
                                    <div className="form-group">
                                        <label>ReferenceSONo# <span className="text-danger">*</span></label>
                                        <SelectBox
                                            dataSource={this.state.soNoAndRefList}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select ReferenceSONo#"
                                            name="id"
                                            defaultValue={0}
                                            value={this.state.referenceSONo}
                                            displayExpr='referenceSONo'
                                            valueExpr='referenceSONo'
                                            onSelectionChanged={this.handleReferenceChange}
                                            showClearButton={true}
                                            disabled={this.state.referenceSONo > 0}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["referenceSONo"].length > 0 && <span className="error invalid-feedback">{this.state.errors["referenceSONo"]}</span>}
                                    </div>
                                </div> */}
                                <div className="col-lg-8">
                                    <div className="form-group">
                                        <label>Serial No <span className="text-danger">*</span></label>
                                        <SelectBox
                                            dataSource={this.state.vehicleAndSerialNoList}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select SerialNo"
                                            name="serialNo"
                                            defaultValue={0}
                                            value={this.state.serialNo}
                                            displayExpr='serialNo'
                                            valueExpr='serialNo'
                                            onSelectionChanged={this.handleSerialNoChange}
                                            showClearButton={true}
                                            //disabled={this.state.serialNo > 0}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["serialNo"].length > 0 && <span className="error invalid-feedback">{this.state.errors["serialNo"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="form-group">
                                    <label>VehicleNo <span className="text-danger">*</span></label>
                                      <input type="text" className="form-control form-control-sm" name="VehicleNo" placeholder="Enter Vehicle No" value={this.state.vehicleNo}
                                             title="Username" maxLength="50"  />
                                    </div>
                                </div>
                                 <div className="col-lg-4">
                                    <div className="form-group">
                                    <label>Item Qty <span className="text-danger">*</span></label>
                                      <input type="text" className="form-control form-control-sm" name="VehicleNo" placeholder="Enter Vehicle No" value={this.state.itemQty}
                                             title="Username" maxLength="50"  disabled />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="submit" className="btn btn-success" onClick={this.handleContractItemSubmit} >
                                {this.state.isSubmited && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                {!this.state.isSubmited && <span title="Submit"><i className="fas fa-check"></i> Submit</span>}
                            </button>
                            <button type="button" className="btn btn-custom-gray" onClick={this.closeContractItemModel} title="Cancel Contract Item Modal"><i className="fas fa-times"></i> Cancel</button>
                        </div>    
                    </form>
                </Modal>
            </Fragment>
        )
    };
}

