import React, { Fragment, Component } from 'react';
import DataGrid, {
    Column,
    Export, Grouping,
    GroupPanel,
    HeaderFilter, Pager,
    Paging,
    SearchPanel,
    SortByGroupSummaryInfo
} from 'devextreme-react/data-grid'; //npm i devextreme-react

import { getRequestParams ,formatDate,numberGridFormat} from '../../helpers/globalfunctions.js';
import { exportFormats } from '../../helpers/fixcodes.js';
import { jsPDF } from 'jspdf';
import { createBrowserHistory } from 'history';
import { SelectBox } from 'devextreme-react/select-box';
import { exportDataGrid } from 'devextreme/pdf_exporter';
import DateBox from 'devextreme-react/date-box';
const history = createBrowserHistory();

export class CreditNotesListComponent extends Component {
    static displayName = CreditNotesListComponent.name;

    constructor(props) {
        super(props);
        this.state = {
            creditNotsList: null,
            accountList: null,
            invoiceList: null,
            accountId:0,
            invoiceNo:"",
            fromDate : new Date(),
            toDate: new Date()
        }
    }
    
    async componentDidMount() {
        await this.getCreditNotesMaster();
        await this.getAccounts();
        await this.getInvoice();

    }

    getAccounts = async () => {
        try {
            var request = {};
            const requestParams = getRequestParams("GET", request);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'account/dropdownAccount/2', requestParams);
            const data = await response.json();
            this.setState({
                accountList: data.payload
            });
        } catch (err) {
            console.log("Error in " + this.displayName + ".getAccounts function", err);
        }
    }
    handleAccountChange = (selectedOption) => {
        this.setState({
            accountId: (selectedOption.selectedItem !== null ? selectedOption.selectedItem.id : 0),
        });
      

    }
    getInvoice = async () => {
        try {
            var request = {};
            const requestParams = getRequestParams("GET", request);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'invoice/invoiceDropDown', requestParams);
            const data = await response.json();
            this.setState({
                invoiceList: data.payload != null ? data.payload : []
            });
        } catch (err) {
            console.log("Error in " + this.displayName + ".getInvoice function", err);
        }
    }
    handleInvoiceChange = (selectedOption) => {
        this.setState({
            invoiceId: (selectedOption.selectedItem !== null ? selectedOption.selectedItem.id : 0),
            invoiceNo: (selectedOption.selectedItem !== null ? selectedOption.selectedItem.name : ""),
        });
     }

    getCreditNotesMaster = async () => {
        try {
            var request = {};
            request.Id= 0;
            request.InvoiceNo = this.state.invoiceNo;
            request.CustomerId = this.state.accountId;
            request.FromDate = formatDate(this.state.fromDate, "yyyy-MM-dd")  + ' '+ '00:00:00';
            request.ToDate = formatDate(this.state.toDate, "yyyy-MM-dd") + ' ' + '23:59:59';
            const requestParams = getRequestParams("Post", request);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'creditnote/creditNote', requestParams);
            const data = await response.json();
            this.setState({
                creditNotsList: data.payload
            });
        } catch (err) {
            console.log("Error in " + this.displayName + ".getCreditNotesMaster function", err);
        }
    }

    onExporting = (e) => {
        const doc = new jsPDF();
        if (e.format === "pdf") {
            exportDataGrid({
                jsPDFDocument: doc,
                component: e.component,
                columnWidths: 50,
               // indent: 10,
            }).then(() => {
                doc.save(e.fileName);
            });            
        }
    }

    handleCategoryModal = async (e) => {
        this.setState({
            categoryModal: true,
            singelData: null
        });        
    }
    
    editCreditNote(e, data) {
        e.preventDefault();
        history.push({
            pathname: '/creditnote/new',
            state: {
                creditNoteId: data.id,
                creditNoteNo: data.creditNoteNo,
                creditNoteDate: data.creditNoteDate,
                validTill: data.validTill,
                invoiceNo: data.invoiceNo,
                customerId: data.customerId,
                remarks:data.remarks,
                grossAmount :data.grossAmount,
                sourceUrl: "/creditnote/list",
                sourceState: this.state
            }
        });
        window.location.reload(false);
    }
    handleAddCreditNote = (e) => {
        sessionStorage.setItem("session_PageName", "Credit Note");
        window.location.href = "/creditnote/new";
    }
    handleFromDateChanged = (date) => {
        this.setState({ fromDate: date.value });
    }
    handleToDateChanged = (date) => {
        this.setState({ toDate: date.value });
    }
    handleClear = () => {
        this.setState({
            accountId: 0,
            invoiceNo: "",
            fromDate: new Date(),
            toDate: new Date()
        });
    }

    render() {
        const renderGridCell = (data) => {
            return <div>
                <a href="/#" className="fas fa-eye" title="Edit" onClick={(e) => { this.editCreditNote(e, data.data) }}>&nbsp;</a>
            </div>;
        }
        const renderGridNumberCell = (data) => {
            return numberGridFormat(data.value)
            //  return <div><small><i class={currencySymbolFormats.currencyFormat}></i></small>&nbsp;&nbsp;{parseFloat(data.value).toFixed(precisionFormats[2])}</div>;
        }
        return (
            <Fragment>
                <div className="section-body">
                    <div className='card bg-filter'>
                        <div className='card-body'>
                            <div className="section-header">
                                {/* <div className="section-header-buttons">
                                    <button type="submit" className="btn btn-success" onClick={this.handleAddCreditNote}>
                                        New
                                    </button>&nbsp;&nbsp;
                                </div> */}
                            </div>
                        

                        <div className='row'>
                                <div className="col-lg-4 col-md-3 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <label htmlFor="">Vendor</label>
                                        <SelectBox
                                            dataSource={this.state.accountList}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select vendor"
                                            name="id"
                                            defaultValue={0}
                                            value={this.state.accountId}
                                            displayExpr='name'
                                            valueExpr='id'
                                            onSelectionChanged={this.handleAccountChange}
                                            showClearButton={true}
                                            //disabled={this.state.purchasereturnId > 0}
                                            searchEnabled={true}
                                        />
                                        {/* {this.state.errors["accountId"].length > 0 && <span className="error invalid-feedback">{this.state.errors["accountId"]}</span>} */}
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-3 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <label htmlFor="">Invoice#</label>
                                        <SelectBox
                                            dataSource={this.state.invoiceList}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select Invoice"
                                            name="name"
                                            defaultValue={0}
                                            value={this.state.invoiceNo}
                                            displayExpr='name'
                                            valueExpr='name'
                                            onSelectionChanged={this.handleInvoiceChange}
                                            showClearButton={true}
                                          // disabled={this.state.purchasereturnId > 0}
                                            searchEnabled={true}
                                        />
                                        {/* {this.state.errors["invoiceNo"].length > 0 && <span className="error invalid-feedback">{this.state.errors["invoiceNo"]}</span>} */}
                                    </div>
                                </div>
                                <div className='col-lg-2 col-md-2 col-sm-12'>
                                        <div className="form-group">
                                            <label htmlFor="">From Date</label>
                                            <DateBox type="date"
                                                className="replay-date-time"
                                                name="fromDate"
                                                placeholder="dd/mm/yyyy"
                                                displayFormat="dd/MM/yyyy"
                                                showAnalogClock={true}
                                                useMaskBehavior={true}
                                                defaultValue="dd/MM/yyyy"
                                                value={this.state.fromDate}
                                                onValueChanged={this.handleFromDateChanged}
                                                width="100%"
                                                acceptCustomValue={false}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-lg-2 col-md-2 col-sm-12'>
                                        <div className="form-group">
                                            <label htmlFor="">To Date</label>
                                            <DateBox type="date"
                                                className="replay-date-time"
                                                name="toDate"
                                                placeholder="dd/mm/yyyy"
                                                displayFormat="dd/MM/yyyy"
                                                showAnalogClock={true}
                                                useMaskBehavior={true}
                                                defaultValue="dd/MM/yyyy"
                                                value={this.state.toDate}
                                                onValueChanged={this.handleToDateChanged}
                                                width="100%"
                                                acceptCustomValue={false}
                                            />
                                        </div>
                                    </div>
                                <div className="col-lg-2 col-md-2 col-sm-12 col-xs-12">
                                        <div className="form-group">
                                            <label htmlFor="">&nbsp;</label><br />
                                            <button type="submit" className="btn btn-success" onClick={this.getCreditNotesMaster}>
                                                Search
                                            </button>&nbsp;&nbsp;
                                            <button type="submit" className="btn btn-outline-secondary" onClick={this.handleClear}>
                                                Clear
                                            </button>&nbsp;&nbsp;
                                            {/* <button type="submit" className="btn btn-success" onClick={this.handleAddCreditNote}>
                                                New
                                            </button>                                           */}
                                        </div>
                                    </div>
                           </div>
                        </div>   
                    </div>
                    <div className='card'>
                    <div className='card-body'>
                        <DataGrid
                            dataSource={this.state.creditNotsList}
                            keyExpr="id"
                            id='gridcreditNotsList'
                            selection={{ mode: 'single' }}
                            columnsAutoWidth={false}
                            showColumnLines={true}
                            showRowLines={true}
                            showBorders={true}
                            rowAlternationEnabled={false}
                            allowColumnResizing={true}
                            onExporting={this.onExporting}
                            columnResizingMode='widget'
                            onToolbarPreparing={this.onToolbarPreparing}>
                            <Column dataField="" caption="" allowSearch={true} allowFiltering={false} allowSorting={true} width={50} cellRender={renderGridCell} />
                            <Column dataField="creditNoteNo" caption="Credit Note#" allowSearch={true} allowFiltering={false} allowSorting={true} width={180} />
                            <Column dataField="creditNoteDate" caption="Credit Note Date" allowSearch={true} allowFiltering={false} allowSorting={true} width={200} />
                            <Column dataField="customerName" caption="Customer Name" allowSearch={true} allowFiltering={false} allowSorting={true} width={250} />
                            <Column dataField="invoiceNo" caption="Invoice#" allowSearch={true} allowFiltering={false} allowSorting={true} width={180} />
                            <Column dataField="grossAmount" caption="Gross Amount" allowSearch={true} allowFiltering={false} allowSorting={true} width={180} cellRender={renderGridNumberCell} />
                            <Column dataField="remarks" caption="Remarks" allowSearch={true} allowFiltering={false} allowSorting={true} width={180} />

                            <SortByGroupSummaryInfo summaryItem="count" />
                            <GroupPanel visible={false} />
                            {/* <Template name="toolbarTemplate" /> */}
                            <HeaderFilter visible={true} />
                            <SearchPanel visible={true} highlightCaseSensitive={true} width={240} placeholder="Search..." />
                            <Grouping autoExpandAll={true} />
                            <Paging defaultPageSize={20} />
                            <Pager showPageSizeSelector={true} allowedPageSizes={[10, 20, 50]} showInfo={true} visible={true} />
                            <Export enabled={true}  allowExportSelectedData={false} fileName="CreditNotes" />
                        </DataGrid>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    };
}
