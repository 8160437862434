import React, { Fragment, Component } from 'react';
import DataGrid, {
    Column,
    Export, Grouping,
    GroupPanel,
    HeaderFilter, Pager,
    Paging,
    SearchPanel,
    SortByGroupSummaryInfo,
    Summary, TotalItem,
} from 'devextreme-react/data-grid'; //npm i devextreme-react
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import { getRequestParams, formatDate,numberGridFormat } from '../../helpers/globalfunctions.js';
import { LoadPanel } from 'devextreme-react/load-panel';
import { alphanumericValidation } from '../../helpers/globalvalidations.js';
import { currencySymbolFormats, precisionFormats } from '../../helpers/fixcodes.js';
import { SelectBox } from 'devextreme-react/select-box';
import { createBrowserHistory } from 'history';
import DateBox from 'devextreme-react/date-box';
import { DocumentsComponent } from '../shared/DocumentsComponent.jsx';
import { jsPDF } from 'jspdf';
import moment from 'moment';
import { exportDataGrid } from 'devextreme/pdf_exporter';
const exportFormats = ['pdf', 'xlsx'];
const position = { of: '#historydiv' };
const history = createBrowserHistory();
const today = new Date();
    const currentYear = today.getFullYear();
    const yearStartMonth = today.getMonth();
    const startYear = today.getMonth() ? currentYear : currentYear - 1;
    const endYear = startYear + 1;
    const startDate = new Date(startYear, 3, 1);
    const endDate = new Date(endYear, 1, 2);
    const aprilStartDate = new Date(startYear, 3, 1);
    

export class SalesOrderListComponent extends Component {
    static displayName = SalesOrderListComponent.name;
    constructor(props) {
        super(props);

        this.state = {
            isSubmited: false,
            loadPanelVisible: false,
            salesordersource: null,
            accountList: null,
            accountId: 0,
            orderDate: "",
            shippingDate: "",
            invoiceNo: "",
            orderNo: "",
            financialStartDate: moment().date(1).month(3).year(startYear).format('MM-DD-YYYY'),
            financialEndDate: moment().date(31).month(2).year(endYear).format('MM-DD-YYYY'),
            // financialStartDate :startDate,
            // financialEndDate :endDate,
            goodDataSource : null,
            serviceDateSource: null,
        }

        this.getSalesOrderMaster = this.getSalesOrderMaster.bind(this);
    }

    async componentDidMount() {
        await this.getSalesOrderMaster();
        await this.getAccounts();
    }

    getSalesOrderMaster = async () => {
        this.setState({
            loadPanelVisible: true
        });
        try {
            var request = {};
            request.DeliveryDate = this.state.shippingDate === "" ? "" : formatDate(this.state.shippingDate, "yyyy-MM-dd");
            request.SODate = this.state.orderDate === "" ? "" : formatDate(this.state.orderDate, "yyyy-MM-dd");
            request.FinancialFromDate = formatDate(this.state.financialStartDate, "yyyy-MM-dd");
            request.FinancialToDate = formatDate(this.state.financialEndDate, "yyyy-MM-dd");
            request.SalesOrderId = parseInt(0);
            request.CustomerId = parseInt(this.state.accountId);
            request.InvoiceNo = this.state.invoiceNo;
            request.OrderNo = this.state.orderNo;
            
            const requestParams = getRequestParams("POST", request);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'salesorder/salesOrder', requestParams);
            let data = await response.json();
            if (data.payload != null) {
                data = JSON.parse(JSON.stringify(data.payload));
                
                const goodDataSource = data.filter(item => item.orderType ==="Goods (Capex)" );
                const serviceDateSource = data.filter(item => item.orderType === "Service (Opex)");
                this.setState({
                    goodDataSource: goodDataSource,
                    serviceDateSource: serviceDateSource
                });
            }
            else {
                this.setState({
                    goodDataSource: [],
                    serviceDateSource: []
                });
            }
            this.setState({
                salesordersource: data.payload,
                loadPanelVisible: false
            });
           
        } catch (err) {
            console.log("Error in " + this.displayName + ".getSalesOrderMaster function", err);
            this.setState({
                loadPanelVisible: false
            });
        }
    }
    getAccounts = async () => {
        try {
            var request = {};
            const requestParams = getRequestParams("GET", request);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'account/dropdownAccount/2', requestParams);
            const data = await response.json();
            this.setState({
                accountList: data.payload
            });
        } catch (err) {
            console.log("Error in " + this.displayName + ".getAccounts function", err);
        }
    }
    
    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift({
            location: 'after',
            widget: 'dxButton',
            options: {
                icon: 'refresh',
                onClick: this.getSalesOrderMaster.bind(this)
            }
        });
    }

    editSalesOrder(e, data) {
        e.preventDefault();
        history.push({
            pathname: '/salesorder/new',
            state: {
                salesorderId: data.id,
                orderNo: data.soNo,
                orderDate: data.orderDate,
                shippingDate: data.shippingDate,
                invoiceNo: data.invoiceNo,
                customerId: data.customerId,
                sourceUrl: "/salesorder",
                sourceState: this.state
            }
        });
        window.location.reload(false);
    }

    handleAddSalesOrder = (e) => {
        sessionStorage.setItem("session_PageName", "Sales Order");
        window.location.href = "/salesorder/new";
    }
    handleOnViewUploadDocument = async (e, data) => {
        e.preventDefault();
        try {
            var documentArray = []
            let row = {};
            row.id = data.id === null ? 0 : Number(data.id);
            row.soNo = data.soNo === null ? '' : data.soNo.trim();
            documentArray.push(row);
            this.refs.documentsComponent.showModal('SO#', documentArray, data.soNo);
        } catch (ex) {
            console.log(ex);
        }
    }
    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        let errors = this.state.errors;

        switch (name) {
            case 'orderNo':
                if (alphanumericValidation.test(event.target.value))
                    this.setState({ orderNo: event.target.value });
                break;
            case 'invoiceNo':
                if (alphanumericValidation.test(event.target.value))
                    this.setState({ invoiceNo: event.target.value });
                break;
            default:
                this.setState({ [name]: value });
                break;
        }
        errors[name] = "";

        this.setState({
            errors: errors
        });
    }
    handleOrderDateChanged = (date) => {
        this.setState({ orderDate: date.value });
    }

    handleShippingDateChanged = (date) => {
        this.setState({ shippingDate: date.value });
    }
    handleAccountChange = (selectedOption) => {
        this.setState({
            accountId: (selectedOption.selectedItem !== null ? selectedOption.selectedItem.id : 0),
        });
    }
    handleFinacialStartDateChanged = (date) => {
        this.setState({ financialStartDate: date.value });
    }
    handleFinacialEndDateChanged = (date) => {
        this.setState({ financialEndDate: date.value });
    }
    onExporting = (e) => {
        const doc = new jsPDF();
        if (e.format === "pdf") {
            exportDataGrid({
                jsPDFDocument: doc,
                component: e.component,
                columnWidths: 50
                // indent: 10,
            }).then(() => {
                doc.save('SalesOrder.pdf');
            });
        }
    }
    handleClear = () => {
        this.setState({
            accountId: 0,
            orderDate: "",
            shippingDate: "",
            invoiceNo: "",
            orderNo: ""
        });
        setTimeout(() => this.getSalesOrderMaster(), 100);
    }
    calculateCustomSummary = (options)=>{
        
    }

    calculateSelectedRow = (options)=> {
        if (options.name === 'SelectedRowsSummary') {
          if (options.summaryProcess === 'start') {
            options.totalValue = 0;
          } else if (options.summaryProcess === 'calculate') {
            if (options.component.isRowSelected(options.value.ID)) {
              options.totalValue += options.value.SaleAmount;
             }
          }
        }
    }
    render() {
        const renderGridCell = (data) => {
            return <div className='text-center'>
                <a href="/#" className="fas fa-pen" title="Edit" onClick={(e) => { this.editSalesOrder(e, data.data) }} >&nbsp;</a>
                <a href="/#" className="fas fa-file-alt" title="Document(s)" onClick={(e) => { this.handleOnViewUploadDocument(e, data.data) }} >&nbsp;</a>
                {/* <a href="/#" className="fas fa-print" title="Print" >&nbsp;</a>
                <a href="/#" className="fas fa-envelope" title="Email" >&nbsp;</a> */}
            </div>;
        }

        const renderGridNumberCell = (data) => {
            // return <div><small><i className={currencySymbolFormats.currencyFormat}></i></small>&nbsp;&nbsp;{parseFloat(data.value).toFixed(precisionFormats[2])}</div>;
            return numberGridFormat(data.value)
        }
        const renderGridPaymentCell = (data) => {
            return <div><small><i className={currencySymbolFormats.currencyFormat}></i></small>&nbsp;&nbsp;{parseFloat(data.value).toFixed(precisionFormats[2])}</div>;
        }
        const customizeText = (data) => {
            // return `Total - ${data.value.toFixed(precisionFormats[2])}`;
            var value = 'Total: ' + numberGridFormat(data.value);
            return value
        }


    //     const calculateCustomSummary = (data) =>{
    // console.log('hello');

    //     }
        return (
            <Fragment>
                <div className="section-body">
                    <div className='card bg-filter'>
                    <div className='card-body'>
                            <div className="section-header">
                                <div className='row'>
                                    <div className='col-lg-4 col-md-2 col-sm-12'>
                                        <div className="form-group">
                                            <label htmlFor="">Customer</label>
                                            <SelectBox
                                                dataSource={this.state.accountList}
                                                className="basic-multi-select"
                                                classNamePrefix="select"
                                                placeholder="Select customer"
                                                name="id"
                                                defaultValue={0}
                                                value={this.state.accountId}
                                                displayExpr='name'
                                                valueExpr='id'
                                                onSelectionChanged={this.handleAccountChange}
                                                showClearButton={true}
                                                searchEnabled={true}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-2 col-md-3 col-sm-12 col-xs-12">
                                        <div className="form-group">
                                            <label htmlFor="">Sales Order#</label>
                                            <input type="text" className="form-control form-control-sm" name="orderNo" placeholder="Enter order number" value={this.state.orderNo}
                                                title="Order No" maxLength="20" onChange={this.handleInputChange} />
                                        </div>
                                    </div>

                                    <div className='col-lg-2 col-md-2 col-sm-12'>
                                        <div className="form-group">
                                            <label htmlFor="">Sales Order Date</label>
                                            <DateBox type="date"
                                                className="replay-date-time"
                                                name="orderDate"
                                                placeholder="dd/mm/yyyy"
                                                displayFormat="dd/MM/yyyy"
                                                showAnalogClock={true}
                                                useMaskBehavior={true}
                                                defaultValue="dd/MM/yyyy"
                                                value={this.state.orderDate}
                                                onValueChanged={this.handleOrderDateChanged}
                                                width="100%"
                                                acceptCustomValue={false}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-lg-2 col-md-2 col-sm-12'>
                                                <div className="form-group">
                                                    <label>Financial Start Date </label>
                                                    <DateBox type="date"
                                                        className="start-date-time"
                                                        name="financialStartDate"
                                                        placeholder="dd/mm/yyyy"
                                                        displayFormat="dd/MM/yyyy"
                                                        showAnalogClock={true}
                                                        useMaskBehavior={true}
                                                        defaultValue="dd/MM/yyyy"
                                                        value={this.state.financialStartDate}
                                                        onValueChanged={this.handleFinacialStartDateChanged}
                                                        width="100%"
                                                       // disabled={this.state.salesorderId > 0 ? true : false}
                                                        acceptCustomValue={false}
                                                    />
                                                </div>
                                            </div>
                                            <div className='col-lg-2 col-md-2 col-sm-12'>
                                                <div className="form-group">
                                                    <label>Financial End Date</label>
                                                    <DateBox type="date"
                                                        className="end-date-time"
                                                        name="financialEndDate"
                                                        placeholder="dd/mm/yyyy"
                                                        displayFormat="dd/MM/yyyy"
                                                        showAnalogClock={true}
                                                        useMaskBehavior={true}
                                                        defaultValue={this.state.financialEndDate}
                                                        value={this.state.financialEndDate}
                                                        onValueChanged={this.handleFinacialEndDateChanged}
                                                        width="100%"
                                                    //    disabled={this.state.salesorderId > 0 ? true : false}
                                                        acceptCustomValue={false}
                                                    />
                                                </div>
                                            </div>
                                    {/* <div className='col-lg-2 col-md-2 col-sm-12'>
                                            <div className="form-group">
                                                <label htmlFor="">Shipping Date</label>
                                                <DateBox type="date"
                                                    className="replay-date-time"
                                                    name="shippingDate"
                                                    placeholder="dd/mm/yyyy"
                                                    displayFormat="dd/MM/yyyy"
                                                    showAnalogClock={true}
                                                    useMaskBehavior={true}
                                                    defaultValue={this.state.shippingDate}
                                                    value={this.state.shippingDate}
                                                    onValueChanged={this.handleShippingDateChanged}
                                                    width="100%"
                                                    disabled={false}
                                                    acceptCustomValue={false}
                                                />
                                            </div>
                                        </div> */}

                                    {/* <div className="col-lg-2 col-md-3 col-sm-12 col-xs-12">
                                        <div className="form-group">
                                            <label htmlFor="">Invoice#</label>
                                            <input type="text" className="form-control form-control-sm" name="invoiceNo" placeholder="Enter invoice number" value={this.state.invoiceNo}
                                                title="Invoice No" maxLength="20" onChange={this.handleInputChange} />
                                        </div>
                                    </div> */}
                                    <div className="col-lg-4 col-md-3 col-sm-12 col-xs-12">
                                        <div className="form-group">
                                            <label htmlFor="">&nbsp;</label><br />
                                            <button type="submit" className="btn btn-success" onClick={this.getSalesOrderMaster}>
                                                Search
                                            </button>&nbsp;&nbsp;
                                            <button type="submit" className="btn btn-outline-secondary" onClick={this.handleClear}>
                                                Clear
                                            </button>&nbsp;&nbsp;
                                            <button type="submit" className="btn btn-success" onClick={this.handleAddSalesOrder}>
                                                New
                                            </button>                                          
                                        </div>
                                    </div>
                                </div>
                            </div>                           
                        </div>
                    </div>                    
                    <div className='card'>
                    <div className='card-body'>
                    <ul className="nav nav-tabs mt-1 ml-2" id="custom-tabs-tab" role="tablist">
                            <li className="nav-item">
                                <a className="nav-link active" id="custom-tabs-home-tab" data-toggle="pill" href="#custom-tabs-home" role="tab" aria-controls="custom-tabs-home" aria-selected="true">Goods</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" id="custom-tabs-profile-tab" data-toggle="pill" href="#custom-tabs-profile" role="tab" aria-controls="custom-tabs-profile" aria-selected="false" >Service</a>
                            </li>
                        </ul>
                        <div className="tab-content tab-bordered" id="custom-tabs-tabContent" >
                            <div className="tab-pane fade active show" id="custom-tabs-home" role="tabpanel" aria-labelledby="custom-tabs-home-tab" >
                                <DataGrid
                                    dataSource={this.state.goodDataSource}
                                    keyExpr="id"
                                    id='gridSalesOrderList'
                                    selection={{ mode: 'single' }}
                                    columnsAutoWidth={false}
                                    showColumnLines={true}
                                    showRowLines={true}
                                    showBorders={true}
                                    rowAlternationEnabled={false}
                                    allowColumnResizing={true}
                                    columnResizingMode='widget'
                                    onExporting={this.onExporting}
                                    onToolbarPreparing={this.onToolbarPreparing}>
                                    <Column dataField="id" width={100} caption="" allowFiltering={false} allowSorting={false} cellRender={renderGridCell} />
                                    <Column dataField="soNo" caption="SO#" allowSearch={true} allowFiltering={false} allowSorting={true} width={100} />
                                    <Column dataField="orderDate" caption="SO Date" allowSearch={true} allowFiltering={false} allowSorting={true} width={150} dataType="datetime" format="dd/MM/yyyy" />
                                    <Column dataField="customerName" caption="Customer" allowSearch={true} allowFiltering={false} allowSorting={true} width={380} />
                                    <Column dataField="grossAmount" caption="Gross Amount" allowSearch={true} allowFiltering={false} allowSorting={true} width={200}
                                        format={"#,##0.###"}
                                        cellRender={renderGridNumberCell}
                                    />
                                    <Column dataField="referenceSONo" caption="Ref. SO#" allowSearch={true} allowFiltering={false} allowSorting={true} width={200} />
                                    <Column dataField="orderType" caption="SO Type" allowSearch={true} allowFiltering={false} allowSorting={true} width={200} />
                                    {/* <Column dataField="paymentdue" caption="Payment Due" allowSearch={true} allowFiltering={false} allowSorting={true} width={100}  cellRender={renderGridPaymentCell}/> */}
                                    {/* <Column dataField="shippingDate" caption="Shipped Date" allowSearch={true} allowFiltering={false} allowSorting={true} width={150} dataType="datetime" format="dd/MM/yyyy HH:mm" /> */}
                                    {/* <Column dataField="orderStatus" caption="Status" allowSearch={true} allowFiltering={false} allowSorting={true} width={120} /> */}
                                    <Summary calculateCustomSummary={this.calculateSelectedRow}>
                                        <TotalItem column="grossAmount" valueFormat="currency" summaryType="sum" customizeText={customizeText} />
                                    </Summary>
                                    <SortByGroupSummaryInfo summaryItem="count" />
                                    <GroupPanel visible={false} />
                                    {/* <Template name="toolbarTemplate" /> */}
                                    <HeaderFilter visible={true} />
                                    <SearchPanel visible={true} highlightCaseSensitive={true} width={240} placeholder="Search..." />
                                    <Grouping autoExpandAll={true} />
                                    <Paging defaultPageSize={20} />
                                    <Pager showPageSizeSelector={true} allowedPageSizes={[10, 20, 50]} showInfo={true} visible={true} />
                                    <Export enabled={true} allowExportSelectedData={false} fileName="Sales Order" />
                                </DataGrid>
                            </div>
                            <div className="tab-pane fade" id="custom-tabs-profile" role="tabpanel" aria-labelledby="custom-tabs-profile-tab" >
                                <DataGrid
                                    dataSource={this.state.serviceDateSource}
                                    keyExpr="id"
                                    id='gridSalesOrderList'
                                    selection={{ mode: 'single' }}
                                    columnsAutoWidth={false}
                                    showColumnLines={true}
                                    showRowLines={true}
                                    showBorders={true}
                                    rowAlternationEnabled={false}
                                    allowColumnResizing={true}
                                    columnResizingMode='widget'
                                    onExporting={this.onExporting}
                                    onToolbarPreparing={this.onToolbarPreparing}>
                                    <Column dataField="id" width={80} caption="" allowFiltering={false} allowSorting={false} cellRender={renderGridCell} />
                                    <Column dataField="soNo" caption="SO#" allowSearch={true} allowFiltering={false} allowSorting={true} width={100} />
                                    <Column dataField="orderDate" caption="SO Date" allowSearch={true} allowFiltering={false} allowSorting={true} width={110} dataType="datetime" format="dd/MM/yyyy" />
                                    <Column dataField="customerName" caption="Customer" allowSearch={true} allowFiltering={false} allowSorting={true} width={450} />
                                    <Column dataField="grossAmount" caption="Gross Amount" allowSearch={true} allowFiltering={false} allowSorting={true} width={180}
                                        format={"#,##0.###"}
                                        cellRender={renderGridNumberCell}
                                    />
                                    <Column dataField="referenceSONo" caption="Ref. SO#" allowSearch={true} allowFiltering={false} allowSorting={true} width={200} />
                                    <Column dataField="orderType" caption="SO Type" allowSearch={true} allowFiltering={false} allowSorting={true} width={200} />
                                    {/* <Column dataField="paymentdue" caption="Payment Due" allowSearch={true} allowFiltering={false} allowSorting={true} width={100}  cellRender={renderGridPaymentCell}/> */}
                                    {/* <Column dataField="shippingDate" caption="Shipped Date" allowSearch={true} allowFiltering={false} allowSorting={true} width={150} dataType="datetime" format="dd/MM/yyyy HH:mm" /> */}
                                    {/* <Column dataField="orderStatus" caption="Status" allowSearch={true} allowFiltering={false} allowSorting={true} width={120} /> */}
                                    <Summary calculateCustomSummary={this.calculateSelectedRow}>
                                        <TotalItem column="grossAmount" valueFormat="currency" summaryType="sum" customizeText={customizeText} />
                                    </Summary>
                                    <SortByGroupSummaryInfo summaryItem="count" />
                                    <GroupPanel visible={false} />
                                    {/* <Template name="toolbarTemplate" /> */}
                                    <HeaderFilter visible={true} />
                                    <SearchPanel visible={true} highlightCaseSensitive={true} width={240} placeholder="Search..." />
                                    <Grouping autoExpandAll={true} />
                                    <Paging defaultPageSize={20} />
                                    <Pager showPageSizeSelector={true} allowedPageSizes={[10, 20, 50]} showInfo={true} visible={true} />
                                    <Export enabled={true} allowExportSelectedData={false} fileName="Sales Order" />
                                </DataGrid>
                            </div>

                        </div>    
                    </div>
                    </div>
                </div>
                <DocumentsComponent ref="documentsComponent" />
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={position}
                    visible={this.state.loadPanelVisible}
                    showIndicator={true}
                    shading={true}
                    showPane={true}
                    hideOnOutsideClick={false}
                />
            </Fragment>
        )
    };
}