import React, { Fragment, Component } from 'react';
import DataGrid, {
    Column,
    Paging,
    SortByGroupSummaryInfo,
    Editing, Lookup,
    RequiredRule,
    PatternRule,
    StringLengthRule,
    Summary, TotalItem,
} from 'devextreme-react/data-grid'; //npm i devextreme-react
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import { getRequestParams, formatDate, numberGridFormat } from '../../helpers/globalfunctions.js';
import { alphanumericValidation, floatValidation, numberValidation, alphanumericSpecialcaseValidation } from '../../helpers/globalvalidations.js';
import { precisionFormats, symbolFormats, currencySymbolFormats, documentType } from '../../helpers/fixcodes.js';
import DateBox from 'devextreme-react/date-box';
import { LoadPanel } from 'devextreme-react/load-panel';
import { SelectBox } from 'devextreme-react/select-box';
import NumberBox from 'devextreme-react/number-box';
import Swal from 'sweetalert2'; //npm i sweetalert2 --save
import Modal from 'react-modal'; //npm install --save react-modal
import moment from 'moment';
import axios from 'axios'; //npm install--save axios

const position = { of: '#historydiv' };
const customStyles = {
    content: {
        width: '50%',
    }
}
export class BillsComponent extends Component {
    static displayName = BillsComponent.name;
    constructor(props) {
        super(props);
        this.grdInvoiceAttributesRef = React.createRef();
        this.state = {
            isSubmited: false,
            isSubmit: false,
            loadPanelVisible: false,
            documentModelIsOpen: false,
            accountList: null,
            paymentTermList: null,
            itemList: [],
            itemDetails: [],
            hsnsacList: null,
            orderStatusList: null,
            invoiceType: null,
            purchaseOrderList: null,
            orderId: 0,
            id: 0,
            accountId: this.props.location.state === undefined ? 0 : this.props.location.state.customerId,
            orderNo: this.props.location.state === undefined ? "" : this.props.location.state.orderNo,
            billDate: this.props.location.state === undefined ? new Date() : this.props.location.state.billDate,
            dueDate: this.props.location.state === undefined ? moment(new Date()).add(30, 'day').format("MM/DD/YYYY") : this.props.location.state.dueDate,
            billNo: this.props.location.state === undefined ? "" : this.props.location.state.billNo,
            billId: this.props.location.state === undefined ? 0 : this.props.location.state.billId,
            referenceNo: "",
            paymentTermId: 0,
            invoiceTypeId: 0,
            shippingCharges: 0.00,
            discount: 0.00,
            otherCharges: 0.00,
            grossAmount: 0.00,
            remarks: "",
            termsAndConditions: "",
            accountTaxList: null,
            sessionUser: JSON.parse(sessionStorage.getItem("sessionUser")),
            isNewItem: false,
            orderStatusId: 0,
            imageList: [],
            pdfList: [],
            excelList: [],
            otherList: [],
            minimiumDate: new Date(),
            documentSelectedFile: [],
            invoiceDocumentOrderId: 0,
            resultMeassage: "",
            targetFile: [],
            taxshippingValue: 0,
            taxOtherCharges: 0,
            taxDiscount: 0,
            taxTotalAmount: 0,
            taxSubTotal: 0,
            showTaxAmount: 0,
            errors: {
                accountId: 0,
                orderNo: "",
                billNo: "",
                orderDate: new Date(),
                shippingDate: new Date(),
                paymentTermId: 0,
                shippingCharges: 0.00,
                discount: 0.00,
                otherCharges: 0.00,
                grossAmount: 0.00,
                remarks: "",
                termsAndConditions: "",
                itemDetailsGrid: "",
                invoiceTypeId: 0,
                referenceNo: ""
            }
        }

        this.assginData = this.assginData.bind(this);
        this.generateBills = this.generateBills.bind(this);
    }

    async componentDidMount() {
        this.setState({
            loadPanelVisible: true
        });
        await this.getAccounts();
        await this.getItemMaster();
        await this.getHsnCodeMaster();
        await this.getPurchaseOrder();
        if (this.state.billId > 0) {
            await this.getInvoiceList();
        }
        this.setState({
            loadPanelVisible: false
        });
    }

    getInvoiceList = async () => {
        this.setState({
            loadPanelVisible: true
        });
        try {
            var request = {};
            request.FromDate = formatDate(this.state.billDate, "yyyy-MM-dd");
            request.ToDate = formatDate(this.state.billDate, "yyyy-MM-dd");
            request.billId = this.state.billId
            const requestParams = getRequestParams("POST", request);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'billing/GetDetails/' + this.state.billId, requestParams);
            let data = await response.json();
            this.assginData(data.payload);
        } catch (err) {
            console.log("Error in " + this.displayName + ".getInvoiceList function", err);
            this.setState({
                loadPanelVisible: false
            });
        }
    }

    assginData = (salesOrder) => {

        if (salesOrder !== null && salesOrder !== undefined) {
            var invoiceSummary = salesOrder.billSummary;
            var invoiceDetails = salesOrder.billDetails;
            // this.getAccountTax(invoiceSummary.customerId);

            this.setState({
                id: invoiceSummary.id,
                accountId: invoiceSummary.customerId,
                paymentTermId: invoiceSummary.paymentTermTypeId,
                invoiceTypeId: invoiceSummary.invoiceTypeId,
                taxSubTotal: invoiceSummary.taxAmount,
                shippingCharges: parseFloat(invoiceSummary.shippingCharges).toFixed(precisionFormats[2]),
                discount: parseFloat(invoiceSummary.discount).toFixed(precisionFormats[2]),
                otherCharges: parseFloat(invoiceSummary.othercharges).toFixed(precisionFormats[2]),
                grossAmount: parseFloat(invoiceSummary.grossAmount).toFixed(precisionFormats[2]),
                remarks: invoiceSummary.remarks,
                termsAndConditions: invoiceSummary.termsCondition,
                referenceNo: invoiceSummary.referenceNo,
                itemDetails: invoiceDetails,
                // orderStatusId: invoiceSummary.orderStatusId,
            });
        }
    }

    getAccounts = async () => {
        try {
            var request = {};
            const requestParams = getRequestParams("GET", request);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'account/dropdownAccount/3', requestParams);
            const data = await response.json();
            this.setState({
                accountList: data.payload
            });
        } catch (err) {
            console.log("Error in " + this.displayName + ".getAccounts function", err);
        }
    }
    getPurchaseOrder = async () => {
        try {
            var request = {};
            const requestParams = getRequestParams("GET", null);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'purchase/getPurchaseOrderDropdown', requestParams);
            const data = await response.json();
            this.setState({
                purchaseOrderList: data.payload
            });
        } catch (err) {
            console.log("Error in " + this.displayName + ".getPurchaseOrder function", err);
        }
    }
    getItemMaster = async () => {
        try {
            var request = {};
            request.CategoryId = 0;
            request.HSNId = 0;
            request.ItemId = 0;
            const requestParams = getRequestParams("POST", request);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'items/item/', requestParams);
            const data = await response.json();
            this.setState({
                itemList: data.payload != null ? data.payload : []
            });
        } catch (err) {
            console.log("Error in " + this.displayName + ".getItemMaster function", err);
        }
    }

    getHsnCodeMaster = async () => {
        try {
            var request = {};
            const requestParams = getRequestParams("GET", request);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'hsnsacCode/hscacccodes/0', requestParams);
            const data = await response.json();
            this.setState({
                hsnsacList: data.payload,
            });
        } catch (err) {
            console.log("Error in " + this.displayName + ".getHsnCodeMaster function", err);
        }
    }

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        let errors = this.state.errors;
        switch (name) {
            case 'billNo':
                if (alphanumericValidation.test(event.target.value))
                    this.setState({ billNo: event.target.value });
                break;
            case 'Remarks':
                if (alphanumericValidation.test(event.target.value))
                    this.setState({ remarks: event.target.value });
                break;
            case 'referenceNo':
                if (alphanumericValidation.test(event.target.value))
                    this.setState({ referenceNo: event.target.value });
                break;
            default:
                this.setState({ [name]: value });
                break;
        }

        errors[name] = "";
        this.setState({
            errors: errors
        });
        //this.state.errors[name] = "";
        // this.setState({
        //     errors: {
        //         [name]: ""
        //     }
        // });
    }

    getAccountTax = async (id) => {
        try {
            var request = {};
            const requestParams = getRequestParams("GET", request);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'hsntax/HSNCodeTax/' + id, requestParams);
            const data = await response.json();
            this.setState({
                accountTaxList: data.payload != null ? data.payload : [],
            });

        } catch (err) {
            console.log("Error in " + this.displayName + ".getContractList function", err);
        }
    }

    onRowInserting = async (e) => {
        this.setState({ isNewItem: true });
    }

    handleShippingChargesChange = (event) => {
        // const newValue = event.value;
        // this.setState({ "shippingCharges": newValue });
        const newValue = event.value == null ? 0 : event.value;
        var totalTax = 0.00;
        // var singleTax = newValue === 0 ? 0: ((newValue * 18) / 100);
        if (newValue > 0) {
            if (this.state.accountTaxList !== null) {
                if (this.state.accountTaxList.length > 0) {
                    this.state.accountTaxList.map((taxitem, i) => {
                        var singleTax = parseFloat(((newValue * taxitem.percentage) / 100), 2);
                        totalTax = totalTax + singleTax;
                    });
                }
            }
        }

        this.setState({ "shippingCharges": newValue, taxshippingValue: totalTax });
        // var charge = parseFloat(this.state.otherCharges) + parseFloat(totalTax) + parseFloat(this.state.taxTotalAmount) + parseFloat(newValue)
        var charge = parseFloat(this.state.otherCharges) + parseFloat(totalTax) + parseFloat(this.state.showTaxAmount) + parseFloat(newValue)
        
        var newgrossAmount = parseFloat(charge) - parseFloat(this.state.discount);
        //var newgrossAmount = parseFloat(this.state.discount) + parseFloat(this.state.otherCharges) + parseFloat(newValue)
        this.setState({ grossAmount: newgrossAmount, taxshippingValue: totalTax });
    }

    handleDiscountChange = (event) => {
        // const newValue = event.value;
        // this.setState({ "discount": newValue });
        const newValue = event.value == null ? 0 : event.value;
        this.setState({ "discount": newValue });
        //  var test = parseFloat(this.state.taxOtherCharges) + parseFloat(this.state.taxshippingValue)+parseFloat(this.state.taxTotalAmount)+ parseFloat(this.state.shippingCharges) + parseFloat(this.state.otherCharges)
        var test = parseFloat(this.state.taxOtherCharges) + parseFloat(this.state.taxshippingValue) + parseFloat(this.state.showTaxAmount) + parseFloat(this.state.shippingCharges) + parseFloat(this.state.otherCharges)
        var newgrossAmount = parseFloat(test) - parseFloat(newValue)
        this.setState({ grossAmount: newgrossAmount });
    }


    handleOtherChargesChange = (event) => {
        // const newValue = event.value;
        // this.setState({ "otherCharges": newValue });
        const newValue = event.value == null ? 0 : event.value;
        // var singleTax = newValue === 0 ? 0: ((newValue * 18) / 100);
        var totalTax = 0.00;
        if (newValue > 0) {
            if (this.state.accountTaxList !== null) {
                if (this.state.accountTaxList.length > 0) {
                    this.state.accountTaxList.map((taxitem, i) => {
                        var singleTax = parseFloat(((newValue * taxitem.percentage) / 100), 2);
                        totalTax = totalTax + singleTax;
                    });
                }
            }

        }
        this.setState({ "otherCharges": newValue, taxOtherCharges: totalTax });
        // var charge =   parseFloat(totalTax) + parseFloat(this.state.taxshippingValue) + parseFloat(this.state.taxTotalAmount) + parseFloat(newValue)
        var charge = parseFloat(totalTax) + parseFloat(this.state.taxshippingValue) + parseFloat(this.state.showTaxAmount) + parseFloat(newValue)
        var newgrossAmount = parseFloat(charge) - parseFloat(this.state.discount);
        this.setState({ grossAmount: newgrossAmount });
    }

    handleGrossAmountChange = (event) => {
        const newValue = event.value;
        this.setState({ "grossAmount": newValue });
    }

    handleAccountChange = (selectedOption) => {
        this.setState({
            accountId: (selectedOption.selectedItem !== null ? selectedOption.selectedItem.id : 0),
        });
        let errors = this.state.errors;

        if (selectedOption.selectedItem !== null && selectedOption.selectedItem.id > 0) {
            errors.accountId = "";
            this.setState({
                errors: errors
            });
            //this.state.errors["accountId"] = "";
            // this.setState({
            //     errors: {
            //         "accountId": ""
            //     }
            // });

        }
    }
    handleOrderChange = (selectedOption) => {
        this.setState({
            orderNo: (selectedOption.selectedItem !== null ? selectedOption.selectedItem.name : ""),
        });
        let errors = this.state.errors;

        if (selectedOption.selectedItem !== null && selectedOption.selectedItem.name !== null) {
            errors.orderNo = "";
            this.setState({
                errors: errors
            });
            //this.state.errors["accountId"] = "";
            // this.setState({
            //     errors: {
            //         "accountId": ""
            //     }
            // });
            this.getOrderDetails(selectedOption.selectedItem.id);
        }

    }

    getOrderDetails = async (id) => {
        try {
            var request = {};
            const requestParams = getRequestParams("GET", request);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'purchase/getdetails/' + id, requestParams);
            const data = await response.json();
            this.setState({
                itemDetails: data.payload != null ? data.payload : [],
                accountId: data.payload[0].customerId,
                shippingCharges: data.payload != null ? data.payload[0].shippingCharges : 0.00,
                otherCharges: data.payload != null ? data.payload[0].otherCharges : 0.00,
                discount: data.payload != null ? data.payload[0].summaryDiscount : 0.00,
                taxSubTotal: data.payload != null ? data.payload[0].taxAmount : 0.00,
                grossAmount : data.payload != null ? data.payload[0].grossAmount : 0.00,
            });

        } catch (err) {
            console.log("Error in " + this.displayName + ".getContractList function", err);
        }
    }
    handlebillDateChanged = (date) => {
        this.setState({
            billDate: date.value,
            //shippingDate: date.value
        });
    }

    handleDueDateChanged = (date) => {
        this.setState({ dueDate: date.value });
    }

    calculateQtyChange = (newData, value, currentRowData) => {
        newData.qty = parseInt(value);
        var totalItemPrice = 0.00;
        totalItemPrice = currentRowData.price !== undefined ? parseFloat(currentRowData.price, 3) * value : 0;
        var totalTax = 0.00;
        // if (totalItemPrice > 0) {
        //     if (this.state.accountTaxList.length > 0) {
        //         this.state.accountTaxList.map((taxitem, i) => {
        //             var singleTax = parseFloat(((totalItemPrice * taxitem.percentage) / 100), 2);
        //             totalTax = totalTax + singleTax;
        //         });
        //     }
        // }
        // newData.taxAmount = parseFloat(totalTax, 2).toFixed(precisionFormats[2]);
        // newData.totalAmount = (parseFloat(totalTax, 2) + parseFloat(totalItemPrice, 3)).toFixed(precisionFormats[2]);
        newData.totalAmount = (parseFloat(totalItemPrice, 3)).toFixed(precisionFormats[2]);
        if (newData.totalAmount > 0) {
            if (this.state.accountTaxList.length > 0) {
                this.state.accountTaxList.map((taxitem, i) => {
                    var singleTax = parseFloat(((newData.totalAmount * taxitem.percentage) / 100), 2);
                    totalTax = totalTax + singleTax;
                });
            }
            var taxSubTotalAmount = parseFloat(totalTax).toFixed(precisionFormats[2]);
            var newGrossAmount = parseFloat(totalTax) + parseFloat(newData.totalAmount)
            this.setState({ showTaxAmount: newGrossAmount, grossAmount: newGrossAmount, taxSubTotal: taxSubTotalAmount });
        }
        return newData;
    }

    calculatePriceChange = (newData, value, currentRowData) => {
        newData.price = parseFloat(value).toFixed(precisionFormats[2]);
        var totalItemPrice = 0.00;
        totalItemPrice = currentRowData.qty !== undefined ? parseFloat(currentRowData.qty, 3) * value : 0;
        var totalTax = 0.00;
        // if (totalItemPrice > 0) {
        //     if (this.state.accountTaxList.length > 0) {
        //         this.state.accountTaxList.map((taxitem, i) => {
        //             var singleTax = parseFloat(((totalItemPrice * taxitem.percentage) / 100), 2);
        //             totalTax = totalTax + singleTax;
        //         });
        //     }
        // }
        // newData.taxAmount = parseFloat(totalTax, 2).toFixed(precisionFormats[2]);
        // newData.totalAmount = (parseFloat(totalTax, 2) + parseFloat(totalItemPrice, 3)).toFixed(precisionFormats[2]);
        newData.totalAmount = (parseFloat(totalItemPrice, 3)).toFixed(precisionFormats[2]);
        if (newData.totalAmount > 0) {
            if (this.state.accountTaxList.length > 0) {
                this.state.accountTaxList.map((taxitem, i) => {
                    
                    var singleTax = parseFloat(((newData.totalAmount * taxitem.percentage) / 100), 2);
                    totalTax = totalTax + singleTax;
                });
            }
            var taxSubTotalAmount = parseFloat(totalTax).toFixed(precisionFormats[2]);
            var newGrossAmount = parseFloat(totalTax) + parseFloat(newData.totalAmount)
            this.setState({ showTaxAmount: newGrossAmount, grossAmount: newGrossAmount, taxSubTotal: taxSubTotalAmount });
        }

    }

    calculateDiscountChange = (newData, value, currentRowData) => {
        newData.discount = parseFloat(value).toFixed(precisionFormats[2]);
        newData.totalAmount = (parseFloat(currentRowData.totalAmount) - parseFloat(value)).toFixed(precisionFormats[2]);
    }

    handleValidation = () => {
        let formIsValid = true;
        try {
            let errors = this.state.errors;
            errors.accountId = 0;
            errors.paymentTermId = 0;
            errors.remarks = "";
            errors.itemDetailsGrid = ""
            errors.invoiceTypeId = 0
            errors.billNo = "";
            errors.referenceNo = "";
            if (this.state.accountId === 0) {
                formIsValid = false;
                errors.accountId = "Please select vendor.";
            }
            if (this.state.remarks.trim() === "") {
                formIsValid = false;
                errors.remarks = "Please enter remarks.";
            }
            if (this.state.orderNo.trim() === "") {
                formIsValid = false;
                errors.orderNo = "Please select order number.";
            }
            if (this.state.billNo.trim() === "") {
                formIsValid = false;
                errors.billNo = "Please enter bill number.";
            }
            if (this.state.itemDetails.length === 0) {
                formIsValid = false;
                errors.itemDetailsGrid = "Please enter item(s).";
            }
            if (this.state.referenceNo.trim() === "") {
                formIsValid = false;
                errors.referenceNo = "Please enter reference number.";
            }

            this.setState({ errors: errors });
        }
        catch (err) {
            console.log("Error in " + this.displayName + ".handleValidation function", err);
            this.setState({ isSubmited: false })
        }
        return formIsValid;
    }

    generateBills = async (e) => {
        e.preventDefault();
        try {
            if (this.handleValidation()) {
                this.setState({
                    isSubmited: true
                })
                const dataSource = this.refs.grdInvoiceAttributesRef.instance.getDataSource();
                var attributeArray = dataSource._items;
                var request = {};
                request.billId = this.state.billId;
                request.billNo = this.state.billNo;
                request.ReferenceNo = this.state.referenceNo;
                request.billDate = formatDate(this.state.billDate, "MM/dd/yyyy hh:mm");
                request.BillDueDate = formatDate(this.state.dueDate, "MM/dd/yyyy hh:mm");
                request.OrderNumber = this.state.orderNo;
                request.VendorId = this.state.accountId;
                var orderItemsList = [];
                var totalAmount = 0, taxAmount = 0;
                attributeArray.forEach((element, index) => {

                    var orderItemRequest = {};
                    orderItemRequest.Id = this.state.isNewItem === true ? 0 : element.id;
                    orderItemRequest.ItemId = element.itemId;
                    orderItemRequest.ItemName = "";
                    orderItemRequest.Description = element.description;
                    orderItemRequest.HSNSACCode = element.hsnsacCode;
                    orderItemRequest.Qty = parseInt(element.qty);
                    orderItemRequest.Price = parseFloat(element.price);
                    orderItemRequest.Discount = element.discount == undefined ? 0.0 : parseFloat(element.discount);
                    orderItemRequest.TotalAmount = parseFloat(element.totalAmount);
                    orderItemRequest.TaxAmount = parseFloat(element.taxAmount);
                    orderItemRequest.Remarks = "";
                    totalAmount = totalAmount + parseFloat(element.totalAmount);
                    taxAmount = taxAmount + parseFloat(element.taxAmount);
                    orderItemsList.push(orderItemRequest);
                });
                request.OrderDetails = {};
                request.billDetails = orderItemsList;
                request.TotalAmount = parseFloat(totalAmount);
                request.Discount = parseFloat(this.state.discount);
                request.TaxAmount = parseFloat(this.state.taxSubTotal);
                request.GrossAmount = parseFloat(this.state.grossAmount); //parseFloat(totalAmount) - parseFloat(this.state.discount) + parseFloat(this.state.otherCharges) + parseFloat(this.state.shippingCharges);
                request.ShippingCharges = parseFloat(this.state.shippingCharges);
                request.OtherCharges = parseFloat(this.state.otherCharges);
                request.Remarks = this.state.remarks;
                request.TermsCondition = this.state.termsAndConditions;
                request.UserId = this.state.sessionUser.id;
                request.Source = "Web";
                request.BillStatusId = this.state.billId === 0 ? parseInt(1) : parseInt(4);

                const requestParams = getRequestParams('POST', request);
                const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'billing/BillGenerate', requestParams);
                const data = await response.json();
                if (data.isSuccess) {
                    this.setState({ invoiceDocumentOrderId: data.payload, resultMeassage: data.message })
                    if (this.state.documentSelectedFile.size !== undefined || this.state.documentSelectedFile.length > 0) {
                        if (this.state.id == 0) {
                            this.documentUpload();
                        }
                    }
                    else {
                        Swal.fire({
                            icon: 'success',
                            title: 'Success',
                            text: data.message,
                        }).then((result) => {
                            if (result.isConfirmed) {
                                if (data.isSuccess) {
                                    window.location.href = "/bill/list";
                                }
                            }
                            this.setState({ isSubmited: false })
                        });
                    }

                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: data.message,
                    }).then((result) => {
                        if (result.isConfirmed) {
                        }
                        this.setState({ isSubmited: false })
                    });
                    this.setState({ isSubmited: false })
                }

                this.setState({
                    isSubmited: true
                })
            }
        } catch (err) {
            console.log("Error in " + this.displayName + ".generateBills function", err);
            this.setState({ isSubmited: false })
        }
    }

    handleClearControls = () => {
        this.setState({
            isSubmited: false,
            loadPanelVisible: false,
            accountList: [],
            paymentTermList: [],
            itemList: [],
            itemDetails: [],
            hsnsacList: [],
            id: 0,
            accountId: 0,
            orderNo: "",
            orderDate: new Date(),
            shippingDate: new Date(),
            paymentTermId: 0,
            shippingCharges: 0.00,
            discount: 0.00,
            otherCharges: 0.00,
            grossAmount: 0.00,
            remarks: "",
            termsAndConditions: "",
            accountTaxList: [],
            sessionUser: JSON.parse(sessionStorage.getItem("sessionUser")),
            isNewItem: false,
            orderStatusId: 0,
            billNo: "",
            errors: {
                accountId: 0,
                orderNo: "",
                orderDate: new Date(),
                shippingDate: new Date(),
                paymentTermId: 0,
                shippingCharges: 0.00,
                discount: 0.00,
                otherCharges: 0.00,
                grossAmount: 0.00,
                remarks: "",
                termsAndConditions: "",
                billNo: ""
            }
        });
    }
    handleCancel = () => {
        window.location.href = "/bill/list";
    }
    openDocumentModel = () => {
        this.setState({
            documentModelIsOpen: true
        });
        this.getDocuments();
    }
    closeDocumentModel = () => {
        this.setState({
            documentModelIsOpen: false
        });
    }
    getDocuments = async () => {
        try {
            this.setState({
                loadPanelVisible: true
            });
            const requestParams = getRequestParams('GET', {});
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'file/' + parseInt(documentType.Bills) + '/' + this.state.billId + '/documents', requestParams);
            const data = await response.json();
            var imageListObj = [];
            var pdfListObj = [];
            var excelListObj = [];
            var otherListObj = [];
            if (data.documents != null && data.documents.length > 0) {
                for (var i = 0; i < data.documents.length; i++) {
                    var img = {};
                    if (data.documents[i].fileGuid != '00000000-0000-0000-0000-000000000000') {
                        const requestParamsImage = getRequestParams('GET', '');
                        const responseImage = await fetch(process.env.REACT_APP_API_BASE_URL + 'file/download/' + data.documents[i].fileGuid, requestParamsImage);
                        const dataImage = await responseImage.json();

                        if (dataImage.fileExtension.toUpperCase() === '.PDF') {
                            img.src = 'data:' + dataImage.mimeType + ';base64,' + dataImage.fileContent;
                            img.fileGuid = data.documents[i].fileGuid;
                            img.fileName = dataImage.fileName;
                            pdfListObj.push(img);
                        } else if (dataImage.fileExtension.toUpperCase() === '.XLXS' || dataImage.fileExtension.toUpperCase() === '.CSV' || dataImage.fileExtension.toUpperCase() === '.XLS') {
                            img.src = 'data:' + dataImage.mimeType + ';base64,' + dataImage.fileContent;
                            img.fileGuid = data.documents[i].fileGuid;
                            img.fileName = dataImage.fileName;
                            excelListObj.push(img);
                        } else if (dataImage.fileExtension.toUpperCase() === '.JPG' || dataImage.fileExtension.toUpperCase() === '.PNG' || dataImage.fileExtension.toUpperCase() === '.JPEG') {
                            img.src = 'data:' + dataImage.mimeType + ';base64,' + dataImage.fileContent;
                            img.fileGuid = data.documents[i].fileGuid;
                            imageListObj.push(img);
                        } else {
                            img.src = 'data:' + dataImage.mimeType + ';base64,' + dataImage.fileContent;
                            img.fileGuid = data.documents[i].fileGuid;
                            img.fileName = dataImage.fileName;
                            otherListObj.push(img);
                        }
                    }
                }
            }

            this.setState({
                loadPanelVisible: false,
                imageList: imageListObj,
                pdfList: pdfListObj,
                excelList: excelListObj,
                otherList: otherListObj
            });

        } catch (e) {
            console.log(e);
        }
    }

    onEditorPreparing = (e) => {
        if (e.parentType === "dataRow" && e.dataField === "itemId") {
            e.editorOptions.onValueChanged = (ev) => {
                let selectedItem = ev.component.option("selectedItem");
                e.setValue(selectedItem);
            };
        }
    }
    setCellValue = (rowData, value) => {
        rowData.itemId = value.id;
        rowData.hsnsacCode = value.hsnCode;
        rowData.price = value.unitPrice;
        this.getAccountTax(value.hsnId);
    }

    handleFileInputChange = async (e) => {
        let images = e.target.files;
        var i;
        for (i = 0; i < e.target.files.length; i++) {
            this.state.targetFile.push(images[i]);
        }
        this.setState({
            documentSelectedFile: this.state.targetFile
        })
        //console.log(this.state.documentSelectedFile);
    }
    removeAll = () => {
        document.getElementById("file").value = null;
    }
    resetFileInput = (e) => {
        var data = this.state.documentSelectedFile.filter(item => item.name === e.target.value);
        var _data = this.state.documentSelectedFile.filter(item => item.name !== data[0].name);
        //var data1 = this.state.documentSelectedFile.filter(item => item.name === data[0].name)
        this.setState({
            documentSelectedFile: _data,
            targetFile: _data
        }, () => 500)
        const arrLength = _data.length;
        console.log(arrLength - 1);
        if (_data.length === 0) {
            this.removeAll();
        }
        else {
            document.getElementById("file").value = null
        }
    };
    documentUpload = async () => {
        for (let i = 0; i < this.state.documentSelectedFile.length; i++) {
            const url = process.env.REACT_APP_API_BASE_URL + 'file/upload';
            const formData = new FormData();
            console.log(this.state.documentSelectedFile);
            formData.append('body', this.state.documentSelectedFile[i]);
            const config = {
                headers: {
                    'content-type': 'multipart/form-data',
                    'UserId': this.state.sessionUser.id,
                    'Authorization': 'Bearer ' + sessionStorage.getItem('session_token'),
                },
            };
            this.setState({
                loadPanelVisible: true
            });
            axios.post(url, formData, config).then((response) => {
                var resp = response.data;
                if (resp != null) {
                    this.setState({
                        fileGuid: resp.fileGuid
                    })
                    this.saveDocument(resp)
                }
            });
            this.setState({
                loadPanelVisible: false
            });
        }
    }
    saveDocument = async (data) => {
        if (data !== null) {
            this.setState({
                fileGuid: data.fileGuid
            });
            try {
                var isSuccess = false;
                var resultMessage = '';
                var request = new Object();
                request.FileGuid = data.fileGuid;
                request.FileRecordType = parseInt(documentType.Bills);
                request.UserId = Number(this.state.sessionUser.id);
                request.DocumentType = parseInt(documentType.Bills);
                request.Id = this.state.invoiceDocumentOrderId;
                const requestVehicleParams = getRequestParams('POST', request);
                const responseVehicle = await fetch(process.env.REACT_APP_API_BASE_URL + 'file/save-documents', requestVehicleParams);
                const dataVehicle = await responseVehicle.json();
                isSuccess = dataVehicle.isSuccess;
                resultMessage = dataVehicle.message;
                if (isSuccess) {
                    Swal.fire({
                        icon: isSuccess ? 'success' : 'error',
                        title: isSuccess ? 'success' : 'Oops...',
                        text: this.state.resultMeassage,
                    }).then((result) => {
                        if (isSuccess) {
                            window.location.href = "/bill/list"
                        }
                    });
                } else {
                    this.onfailure('Failed to upload image.', resultMessage);
                }
                this.setState({ isSubmited: false })
            } catch (e) {

            }

        }
    }
    calculateSelectedRow = (options) => {
        if (options.name === 'SelectedRowsSummary') {
            if (options.summaryProcess === 'start') {
                options.totalValue = 0.00;
            } else if (options.summaryProcess === 'calculate') {
                options.totalValue += parseInt(options.value.discount);
                var amount = options.totalValue
                //this.setState({ discount: amount });
            }
        }
        else
            if (options.name === 'SelectedTotalSummary') {
                if (options.summaryProcess === 'start') {
                    options.totalValue = 0.00;
                } else if (options.summaryProcess === 'calculate') {
                    options.totalValue += parseInt(options.value.totalAmount);
                    var amount = options.totalValue
                    var newGrossAmount = amount
                    if (this.state.billId == 0) {
                        this.setState({ grossAmount: newGrossAmount });
                    }
                    
                    this.setState({ grossAmount: this.state.grossAmount, showTaxAmount: newGrossAmount });
                }
            }
    }
    render() {
        const customizeQtyText = (data) => {
            var value = 'Total: ' + numberGridFormat(data.value);
            return value
        }

        const customizePriceText = (data) => {
            var value = 'Total: ' + numberGridFormat(data.value);
            return value
        }

        const customizeTaxText = (data) => {
            var value = 'Total: ' + numberGridFormat(data.value);
            return value
        }

        const customizeDiscountText = (data) => {
            var value = 'Total: ' + numberGridFormat(data.value);
            return value
        }

        const customizeTotalAmountText = (data) => {
            var value = 'Total: ' + numberGridFormat(data.value);
            return value
        }

        const renderGridNumberCell = (data) => {
            return numberGridFormat(data.value)
            //return <div><small><i class={currencySymbolFormats.currencyFormat}></i></small>&nbsp;&nbsp;{parseFloat(data.value !== undefined ? data.value : 0.00).toFixed(precisionFormats[2])}</div>;
        }

        return (
            <Fragment>
                <div className="section-body">
                    <div className='card bg-filter'>
                        <div className='card-body'>
                            <div className="section-header">
                                <h6 className='m-0'>{this.state.billId > 0 ? "Update Bill" : "New Bill"} </h6>
                                <div className="section-header-buttons">
                                    <button type="submit" className="btn btn-success" onClick={this.generateBills} >
                                        {this.state.isSubmited && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                        {!this.state.isSubmited && <span title="Submit">Submit</span>}
                                    </button>&nbsp;&nbsp;
                                    <button type="submit" className="btn btn-success" onClick={this.generateBills} style={{ display: this.state.billId > 0 ? 'block' : 'none' }} >
                                        {this.state.isSubmit && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                        {!this.state.isSubmit && <span title="Submit">Submit & Approve </span>}
                                    </button>&nbsp;&nbsp;

                                    <button type="submit" className="btn btn-outline-secondary" onClick={this.handleCancel}>
                                        <span title="Cancel">Cancel</span>
                                    </button>
                                </div>
                            </div>


                        </div>
                    </div>
                    <div className='card mb-2'>
                        <div className='card-body'>
                            <div className='sub-title-color font-weight-bold'>Bill Info</div>
                            <div className='row'>
                                <div className="col-lg-2 col-md-3 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <label htmlFor="">Order# <span className="text-danger">*</span></label>
                                        <SelectBox
                                            dataSource={this.state.purchaseOrderList}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select order number"
                                            name="name"
                                            defaultValue={0}
                                            value={this.state.orderNo}
                                            displayExpr='name'
                                            valueExpr='name'
                                            onSelectionChanged={this.handleOrderChange}
                                            showClearButton={true}
                                            disabled={this.state.id > 0}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["orderNo"].length > 0 && <span className="error invalid-feedback">{this.state.errors["orderNo"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <label htmlFor="">Vendor</label>
                                        <SelectBox
                                            dataSource={this.state.accountList}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select vendor"
                                            name="id"
                                            defaultValue={0}
                                            value={this.state.accountId}
                                            displayExpr='name'
                                            valueExpr='id'
                                            onSelectionChanged={this.handleAccountChange}
                                            showClearButton={true}
                                            disabled={true}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["accountId"].length > 0 && <span className="error invalid-feedback">{this.state.errors["accountId"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-3 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <label htmlFor="">Bill# <span className="text-danger">*</span></label>
                                        <input type="text" className="form-control form-control-sm" name="billNo" placeholder="Enter bill number" value={this.state.billNo}
                                            disabled={this.state.id > 0} title="Bill No" maxLength="20" onChange={this.handleInputChange} />
                                        {this.state.errors["billNo"].length > 0 && <span className="error invalid-feedback">{this.state.errors["billNo"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-3 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <label htmlFor="">Reference# <span className="text-danger">*</span></label>
                                        <input type="text" className="form-control form-control-sm" name="referenceNo" placeholder="Enter reference number" value={this.state.referenceNo}
                                            disabled={this.state.id > 0} title="reference No" maxLength="20" onChange={this.handleInputChange} />
                                        {this.state.errors["referenceNo"].length > 0 && <span className="error invalid-feedback">{this.state.errors["referenceNo"]}</span>}
                                    </div>
                                </div>

                                {/* <div className="col-lg-2 col-md-3 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <label htmlFor="">Order#</label>
                                        <input type="text" className="form-control form-control-sm" name="orderNo" placeholder="Enter Order No" value={this.state.orderNo}
                                            disabled={this.state.id > 0} title="Order No" maxLength="20" onChange={this.handleInputChange} />
                                        {this.state.errors["orderNo"].length > 0 && <span className="error invalid-feedback">{this.state.errors["orderNo"]}</span>}
                                    </div>
                                </div> */}
                                <div className="col-lg-2 col-md-3 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <label htmlFor="">Bill Date</label>
                                        <DateBox type="date"
                                            className="replay-date-time"
                                            name="billDate"
                                            placeholder="dd/mm/yyyy"
                                            displayFormat="dd/MM/yyyy"
                                            showAnalogClock={true}
                                            useMaskBehavior={true}
                                            defaultValue={this.state.billDate}
                                            value={this.state.billDate}
                                            onValueChanged={this.handlebillDateChanged}
                                            width="100%"
                                            disabled={this.state.billId > 0}
                                            acceptCustomValue={false}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-3 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <label htmlFor="">Due Date</label>
                                        <DateBox type="date"
                                            className="replay-date-time"
                                            name="dueDate"
                                            placeholder="dd/mm/yyyy"
                                            displayFormat="dd/MM/yyyy"
                                            showAnalogClock={true}
                                            useMaskBehavior={true}
                                            defaultValue={this.state.dueDate}
                                            value={this.state.dueDate}
                                            onValueChanged={this.handleDueDateChanged}
                                            min={this.state.dueDate}
                                            width="100%"
                                            //  disabled={false}
                                            disabled={this.state.billId > 0}
                                            acceptCustomValue={false}
                                        />
                                    </div>
                                </div>

                            </div>
                            <div className='row'>
                                <div className="col-lg-2" style={{ display: this.state.billId > 0 ? 'block' : 'none' }}>
                                    <br></br>
                                    <button className="btn btn-ouline-secondary" title="View Document(s)" onClick={() => { this.openDocumentModel() }} ><span className='fas fa-folder'></span>&nbsp;View Document(s)</button>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className='card mb-2'>
                        <div className='card-body'>
                            <div className='sub-title-color font-weight-bold'>Item Info</div>
                            <div>
                                <DataGrid
                                    ref="grdInvoiceAttributesRef"
                                    dataSource={this.state.itemDetails}
                                    keyExpr="id"
                                    allowColumnReordering={true}
                                    showBorders={true}
                                    onRowUpdating={this.onRowUpdating}
                                    onRowUpdated={this.onRowUpdated}
                                    onRowInserting={this.onRowInserting}
                                    onRowRemoved={this.onRowRemoved}
                                    onEditorPreparing={this.onEditorPreparing}>
                                    <Paging enabled={false} />
                                    <Editing mode="row"
                                        allowUpdating={true}
                                        allowDeleting={true}
                                        //  allowAdding={true}
                                        editorOptions={true} />
                                    <Column dataField="itemId" caption="Item" setCellValue={this.setCellValue} allowEditing={this.state.billId > 0 ? false : true}>
                                        <Lookup dataSource={this.state.itemList} displayExpr="name" valueExpr="id" />
                                        <RequiredRule />
                                    </Column>
                                    <Column dataField="description" caption="Description">
                                        <RequiredRule />
                                        <PatternRule message={'Please enter description'} pattern={alphanumericSpecialcaseValidation} />
                                        <StringLengthRule message="Description can not be more than 150 characters" max={150} />
                                    </Column>
                                    <Column dataField="hsnsacCode" caption="HSN Code" allowEditing={false}>
                                        {/* <Lookup dataSource={this.state.hsnsacList} displayExpr="name" valueExpr="code" allowEditing={false} /> */}
                                        <RequiredRule />
                                    </Column>
                                    {/* <Column dataField="serialNo" caption="Serial#">
                                            <PatternRule message={'Quantity should be in number'} pattern={alphanumericValidation} />
                                            <StringLengthRule message="Description can not be more than 150 characters" max={150} />
                                        </Column> */}
                                    <Column dataField="qty" caption=" Item Qty" dataType="number" setCellValue={this.calculateQtyChange} alignment="right">
                                        <RequiredRule />
                                        <PatternRule message={'Quantity should be in number'} pattern={numberValidation} />
                                    </Column>
                                    <Column dataField="price" caption="Unit Price" dataType="number" setCellValue={this.calculatePriceChange} format={"#,##0.##"} alignment="right"
                                        cellRender={renderGridNumberCell}>
                                        <RequiredRule />
                                        <PatternRule message={'Item Price should be decimal'} pattern={floatValidation} />
                                    </Column>
                                    {/* <Column dataField="taxAmount" caption="Tax Amount" dataType="decimal" allowEditing={false} format={"#,##0.##"} alignment="right"
                                        cellRender={renderGridNumberCell}>
                                        <RequiredRule />
                                        <PatternRule message={'Tax Amount should be decimal'} pattern={floatValidation} />
                                    </Column> */}
                                    {/* <Column dataField="discount" caption="Discount" dataType="number" setCellValue={this.calculateDiscountChange} disabled={true} format={"#,##0.##"} alignment="right"
                                        cellRender={renderGridNumberCell}> */}
                                    {/* <RequiredRule /> */}
                                    {/* <PatternRule message={'Item Discount should be decimal'} pattern={floatValidation} />
                                    </Column> */}
                                    <Column dataField="totalAmount" caption="Total Amount" dataType="decimal" allowEditing={false} format={"#,##0.##"} alignment="right"
                                        cellRender={renderGridNumberCell}></Column>
                                    {/* <Summary>
                                        <TotalItem column="qty" summaryType="sum" customizeText={customizeQtyText} />
                                        <TotalItem column="price" summaryType="sum" customizeText={customizePriceText} />
                                        <TotalItem column="taxAmount" summaryType="sum" customizeText={customizeTaxText} />
                                        <TotalItem column="discount" summaryType="sum" customizeText={customizeDiscountText} />
                                        <TotalItem column="totalAmount" summaryType="sum" customizeText={customizeTotalAmountText} />
                                    </Summary> */}
                                    <Summary calculateCustomSummary={this.calculateSelectedRow}>
                                        <TotalItem
                                            name="SelectedRowsSummary"
                                            summaryType="custom"
                                            // valueFormat="currency"
                                            displayFormat="Total - {0}"
                                            showInColumn="discount" />
                                        <TotalItem
                                            name="SelectedTotalSummary"
                                            summaryType="custom"
                                            // valueFormat="currency"
                                            displayFormat="Sub Total - {0}"
                                            showInColumn="totalAmount" />
                                    </Summary>
                                    <SortByGroupSummaryInfo summaryItem="count" />
                                    {this.state.errors["itemDetailsGrid"].length > 0 && <span className="error invalid-feedback">{this.state.errors["itemDetailsGrid"]}</span>}
                                </DataGrid>
                            </div>
                            <div className='row mt-2'>
                                <div className='col-lg-9'>
                                    <div className="form-group">
                                        <label htmlFor="">Remarks <span className="text-danger">*</span></label>
                                        {/* <textarea className="form-control" placeholder="Enter remarks here"></textarea> */}
                                        <textarea type="text" className="form-control form-control-sm" name="remarks" autoComplete="remarks" placeholder="Enter remarks    "
                                            maxLength="200" title="Remarks" value={this.state.remarks} onChange={this.handleInputChange} style={{ width: "90%", height: "50px" }} />
                                        {this.state.errors["remarks"].length > 0 && <span className="error invalid-feedback">{this.state.errors["remarks"]}</span>}
                                    </div>
                                    {/* <div className="col-6">
                                    <div className="form-group">
                                        <label htmlFor="">Terms & Conditions</label>
                                        <textarea type="text" className="form-control form-control-sm" name="TermsAndConditions" autoComplete="termsAndConditions" placeholder="Enter terms & conditions"
                                            maxLength="200" title="Enter terms & conditions" value={this.state.termsAndConditions} onChange={this.handleInputChange} />
                                        {this.state.errors["termsAndConditions"].length > 0 && <span className="error invalid-feedback">{this.state.errors["termsAndConditions"]}</span>}
                                    </div>
                                </div> */}
                                </div>
                                <div className='col-lg-3'>
                                    <table border="0" cellPadding={"5"} cellSpacing={"0"} width={"100%"} align="right">
                                        <tr>
                                            <td className='text-left'>Shipping Charges</td>
                                            <td>
                                                <NumberBox type="number" className="form-control form-control-sm" name="ShippingCharges" placeholder="Enter shipping charges"
                                                    format="#0.00" defaultValue={0.00} title="Shipping Charges" value={this.state.shippingCharges} onValueChanged={this.handleShippingChargesChange} min={0.00} max={99.99} />
                                                {/* {this.state.errors["shippingCharges"].length > 0 && <span className="error invalid-feedback">{this.state.errors["shippingCharges"]}</span>} */}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className='text-left'>Other Charges</td>
                                            <td>
                                                <NumberBox type="number" className="form-control form-control-sm" name="OtherCharges" placeholder="Enter other charges"
                                                    defaultValue={0.00} title="Other Charges" value={this.state.otherCharges}
                                                    format="#0.00" onValueChanged={this.handleOtherChargesChange} min={0.00} max={99.99} />
                                                {this.state.errors["otherCharges"].length > 0 && <span className="error invalid-feedback">{this.state.errors["otherCharges"]}</span>}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className='text-left'>Discount</td>
                                            <td>
                                                <NumberBox type="number" className="form-control form-control-sm" name="Discount" placeholder="Enter discount"
                                                    defaultValue={0.00} title="Discount" value={this.state.discount} onValueChanged={this.handleDiscountChange}
                                                    format="#0.00" min={0.00} max={99.99} />
                                                {/* {this.state.errors["discount"].length > 0 && <span className="error invalid-feedback">{this.state.errors["discount"]}</span>} */}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className='text-left' width="40%">Total Tax
                                                <div className='total-tax-amount'>
                                                    {/* data.payload[0].taxName */}
                                                    {this.state.accountTaxList == null ? [] : this.state.accountTaxList.map((item, index) => {
                                                        return (
                                                            <span>{item.taxName + '@' + item.percentage + '%'}</span>
                                                        )
                                                    })}

                                                </div>
                                            </td>
                                            <td>
                                                {/* {this.state.accountTaxList == null ?[]:    <div>{this.state.accountTaxList[0].taxName + '@' + this.state.accountTaxList[0].percentage}</div> } */}
                                                <NumberBox type="number" className="form-control form-control-sm" name="showTaxAmount" placeholder="0.00"
                                                    defaultValue={0.00} title="showTaxAmount" disabled={true} value={this.state.taxSubTotal} min={0.00} />
                                                {/* {this.state.errors["shippingCharges"].length > 0 && <span className="error invalid-feedback">{this.state.errors["shippingCharges"]}</span>} */}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className='text-left'>Gross Amount</td>
                                            <td>
                                                <NumberBox type="number" className="form-control form-control-sm" name="GrossAmount" placeholder="0.00" disabled={true}
                                                    format="#0.00" defaultValue={0.00} title="Gross Amount" value={this.state.grossAmount} onValueChanged={this.handleGrossAmountChange} min={0.00} />
                                                {/* {this.state.errors["grossAmount"].length > 0 && <span className="error invalid-feedback">{this.state.errors["grossAmount"]}</span>} */}
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='card' style={{ display: this.state.billId === 0 ? 'block' : 'none' }}>
                        <div className='card-body'>
                            <div className='sub-title-color font-weight-bold'>Document Info</div>
                            <div className='row'>
                                <div className="col-8">
                                    <div className="form-group">
                                        <label htmlFor="">Attach File(s) to New Bill</label>
                                        <input className="form-control" type="file" id="file" onClick={() => { this.removeAll() }} onChange={(e) => { this.handleFileInputChange(e, false) }} />
                                        {/* <label htmlFor="" className='small text-muted'>You can upload a maximum of 5 files, 5MB each</label> */}
                                        {this.state.documentSelectedFile.map((item, index) => {
                                            return (
                                                <div key={index}>
                                                    <label >{item.name} <button value={item.name} onClick={this.resetFileInput} class="btn btn-outline-secondary fas fa-trash text-danger"></button></label>
                                                </div>
                                            );
                                        })}
                                        {/* <label htmlFor="" className='small text-muted'>You can upload a maximum of 5 files, 5MB each</label> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                
            
                <Modal ariaHideApp={false}
                    isOpen={this.state.documentModelIsOpen}
                    style={customStyles}
                    className={"react-modal"}
                    onRequestClose={this.closeDocumentModel}
                    shouldCloseOnOverlayClick={false}
                    contentLabel="Example Modal">
                    <form method="post" style={this.state.isSubmited ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                        <div className="modal-header">
                            <h6 className="modal-title">Document</h6>
                        </div>
                        <div className="modal-body">
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <div className="box-body no-padding" style={{ maxHeight: 400 + 'px', overflowY: "scroll" }}>
                                    <div>
                                        <ul className="users-list clearfix">
                                            {this.state.imageList.length > 0 && this.state.imageList.map((item, j) =>
                                                <li style={{ position: "relative" }} key={j}>
                                                    <a href={item.src} download={item.fileName}><div className="framehover"><img src={item.src} width="150" alt="Image" /></div></a>
                                                </li>
                                            )}
                                            {this.state.pdfList.length > 0 && this.state.pdfList.map((item, j) =>
                                                <li style={{ position: "relative" }} key={j}>
                                                    <a href={item.src} download={item.fileName}><div className="framehover"><img src="../../assets/img/pdf_icon.png" width="150" alt="Blank Image" /></div></a>
                                                </li>
                                            )}
                                            {this.state.excelList.length > 0 && this.state.excelList.map((item, j) =>
                                                <li style={{ position: "relative" }} key={j}>
                                                    <a href={item.src} download={item.fileName}><div className="framehover"><img src="../../assets/img/excel_icon.png" width="100" alt="Blank Image" /></div></a>
                                                </li>
                                            )}
                                            {this.state.otherList.length > 0 && this.state.otherList.map((item, j) =>
                                                <li style={{ position: "relative" }} key={j}>
                                                    <a href={item.src} download={item.fileName}><div className="framehover"><img src="../../assets/img/other_icon.png" width="100" alt="Blank Image" /></div></a>
                                                </li>
                                            )}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                            <div className="modal-footer">
                                <button type="button" className="btn btn-outline-secondary" onClick={this.closeDocumentModel} title="Cancel">Cancel</button>
                            </div>
                        </form>
                    </Modal>
                    <LoadPanel
                        shadingColor="rgba(0,0,0,0.4)"
                        position={position}
                        visible={this.state.loadPanelVisible}
                        showIndicator={true}
                        shading={true}
                        showPane={true}
                        hideOnOutsideClick={false}
                    />
                </div>
            </Fragment>
        )
    };
}