import React, { Fragment, Component } from 'react';
import DataGrid, {
    Column,
    Export, Grouping,
    GroupPanel,
    HeaderFilter, Pager,
    Paging,
    SearchPanel,
    SortByGroupSummaryInfo,TotalItem,Summary
} from 'devextreme-react/data-grid'; //npm i devextreme-react

import { getRequestParams,numberGridFormat } from '../../helpers/globalfunctions.js';
import { exportFormats } from '../../helpers/fixcodes.js';
import { jsPDF } from 'jspdf';
import { createBrowserHistory } from 'history';
import { exportDataGrid } from 'devextreme/pdf_exporter';
import { currencySymbolFormats, precisionFormats } from '../../helpers/fixcodes.js';
const history = createBrowserHistory();

export class InvoicePaymentList extends Component {
    static displayName = InvoicePaymentList.name;

    constructor(props) {
        super(props);
        this.state = {
            invoicepaymentSummary: [],
            invoicepaymentDetails: [],
        }
    }

    async componentDidMount() {
        await this.getInvoicePaymentMaster();
    }

    getInvoicePaymentMaster = async () => {
        try {
            var request = {};
            const requestParams = getRequestParams("GET");
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'invoicepayment/0/0', requestParams);
            const data = await response.json();
            this.setState({
                invoicepaymentSummary: data.payload.invoicePayment
            });
        } catch (err) {
            console.log("Error in " + this.displayName + ".getInvoicePaymentMaster function", err);
        }
    }

    onExporting = (e) => {
        const doc = new jsPDF();
        if (e.format === "pdf") {
            exportDataGrid({
                jsPDFDocument: doc,
                component: e.component,
                columnWidths: 50,
                // indent: 10,
            }).then(() => {
                doc.save(e.fileName);
            });
        }
    }

    handleCategoryModal = async (e) => {
        this.setState({
            categoryModal: true,
            singelData: null
        });
    }

    editInvoicePayment(e, data) {
        e.preventDefault();
        history.push({
            pathname: '/invoicepayment/new',
            state: {
                customerId: data.customerId,
                customerName: data.customerName,
                sourceUrl: "/invoicepayment/list",
                creditAmount: data.creditAmount,
                debitAmount: data.debitAmount,
                dueAmount: data.dueAmount,
                overDueAmount: data.overDueAmount,
                sourceState: this.state
            }
        });
        window.location.reload(false);
    }

    render() {
        const renderGridCell = (data) => {
            return <div>
                <a href="/#" title="Edit" onClick={(e) => { this.editInvoicePayment(e, data.data) }}  >{data.data.customerName}</a>&nbsp;&nbsp;
            </div>;
        }
        const renderGridTotalAmountCell = (data) => {
            return numberGridFormat(data.value)
            //return <div><small><i className={currencySymbolFormats.currencyFormat}></i></small>&nbsp;&nbsp;{parseFloat(data.value).toFixed(precisionFormats[2])}</div>;
        }
        const customizeCreditText = (data) => {
            var value = 'Total: ' + numberGridFormat(data.value);
            return value
            //return `Total - ${data.value.toFixed(precisionFormats[2])}`;
        }
        const customizeDebitText = (data) => {
            var value = 'Total: ' + numberGridFormat(data.value);
            return value
            // return `Total - ${data.value.toFixed(precisionFormats[2])}`;
        }
        const customizeDueText = (data) => {
            var value = 'Total: ' + numberGridFormat(data.value);
            return value
            //return `Total - ${data.value.toFixed(precisionFormats[2])}`;
        }
        const customizeoverText = (data) => {
            var value = 'Total: ' + numberGridFormat(data.value);
            return value
            //return `Total - ${data.value.toFixed(precisionFormats[2])}`;
        }
        return (
            <Fragment>
                <div className="section-body">
                    {/* <div className='card bg-filter'>
                        <div className='card-body'>
                            <div className="section-header">
                                <h6 className="card-title text-blue">Invoice Payment</h6>
                            </div>
                        </div>
                    </div> */}
                    <div className='card'>
                    <div className='card-body'>
                                <DataGrid
                                    dataSource={this.state.invoicepaymentSummary}
                                    keyExpr="customerId"
                                    id='invoicepaymentList'
                                    selection={{ mode: 'single' }}
                                    columnsAutoWidth={false}
                                    showColumnLines={true}
                                    showRowLines={true}
                                    showBorders={true}
                                    rowAlternationEnabled={false}
                                    allowColumnResizing={true}
                                    onExporting={this.onExporting}
                                    columnResizingMode='widget'
                                    onToolbarPreparing={this.onToolbarPreparing}>

                                    <Column dataField="customerName" caption="Customer" allowSearch={true} allowFiltering={false} allowSorting={true} width={400} cellRender={renderGridCell} />
                                    <Column dataField="creditAmount" caption="Credit" allowSearch={true} allowFiltering={false} allowSorting={true} width={200} cellRender={renderGridTotalAmountCell} />
                                    <Column dataField="debitAmount" caption="Debit" allowSearch={true} allowFiltering={false} allowSorting={true} width={300} cellRender={renderGridTotalAmountCell} />
                                    <Column dataField="dueInvoiceAmount" caption="Due Amount" allowSearch={true} allowFiltering={false} allowSorting={true} width={300} cellRender={renderGridTotalAmountCell} />
                                    <Column dataField="overDueAmount" caption="Over Due Amount" allowSearch={true} allowFiltering={false} allowSorting={true} width={180} cellRender={renderGridTotalAmountCell} />
                                    <Summary>
                                                <TotalItem column="creditAmount" summaryType="sum" customizeText={customizeCreditText} />
                                                <TotalItem column="debitAmount" summaryType="sum" customizeText={customizeDebitText} />
                                                <TotalItem column="dueInvoiceAmount" summaryType="sum" customizeText={customizeDueText} />
                                                <TotalItem column="overDueAmount" summaryType="sum" customizeText={customizeoverText} />
                                            </Summary>
                                    <SortByGroupSummaryInfo summaryItem="count" />
                                    <GroupPanel visible={false} />
                                    {/* <Template name="toolbarTemplate" /> */}
                                    <HeaderFilter visible={true} />
                                    <SearchPanel visible={true} highlightCaseSensitive={true} width={240} placeholder="Search..." />
                                    <Grouping autoExpandAll={true} />
                                    <Paging defaultPageSize={10} />
                                    <Pager showPageSizeSelector={true} allowedPageSizes={[10, 20, 50]} showInfo={true} visible={true} />
                                    <Export enabled={true} allowExportSelectedData={false} fileName="InvoicePayment" />
                                </DataGrid>
                            </div>
                    </div>
                </div>
            </Fragment>
        )
    };
}
