export const alphanumericValidation = /^[A-Za-z0-9\s]*$/;
export const alphanumericSpecialcaseValidation = /^[A-Za-z0-9_()/#@&-[\]\s]*$/;
export const alphaValidation = /^[a-zA-Z]*$/;
export const floatValidation = /^(?:\d*\.\d{1,3}|\d+)$/;
export const numberValidation = /^\d+$/;
export const regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const vIsPasswordComplexity = true;
export const vMinLength= 8;
export const vMinUpperCaseLetter= 1;
export const vMinLowerCaseLetter= 1;
export const vMinNumber= 1;
export const vMinSpecialCharacter= 1;
export const floatExpression = /^([+-]?([0-9]*[.])?[0-9]+)*$/;