import React, { Component } from 'react';
import jsSHA from "jssha"; // npm install jssha
import { getRequestParams } from '../../src/helpers/globalfunctions.js';


export class LoginComponent extends Component {
    static displayName = LoginComponent.name;

    constructor(props) {
        super(props);
        this.state = {
            userName: '', password: '', isSubmited: false, message: '',
            ipAddress: '', latitude: 0, longitude: 0,
            errors: {
                userName: '',
                password: '',
            },
            userToken: "",
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    handleValidation() {
        let formIsValid = true;
        try {
            let errors = this.state.errors;
            errors.userName = "";
            errors.password = "";
            this.setState({ message: '' });

            if (this.state.userName === '') {
                formIsValid = false;
                errors.userName = "Please enter username.";
            }

            if (this.state.password === '') {
                formIsValid = false;
                errors.password = "Please enter password.";
            }
            this.setState({ errors: errors });
        } catch (err) {
            console.log("Error in " + this.displayName + ".handleValidation function", err);
            formIsValid = false;
        }
        return formIsValid;
    }

    handleSubmit = async (e) => {
        try {
            e.preventDefault();
            if (this.handleValidation()) {
                this.setState({ isSubmited: true });
                await this.validate(false);
            }
        } catch (err) {
            console.log("Error in " + this.displayName + ".handleSubmit function", err);
            this.setState({ isSubmited: false, message: "Oops something went worng !!!" });
        }
    }

    async validate(redirectFlag) {
        try {
            sessionStorage.clear();
            let passwordHash = "";
            var passwordTokenRequest = new Object();
            passwordTokenRequest.UserName = this.state.userName;
            const passwordTokenRequestParams = getRequestParams('POST', passwordTokenRequest);

            if (this.state.userToken === "") {
                const passwordTokenResponse = await fetch(process.env.REACT_APP_API_BASE_URL + 'authenticate/password-generate-token/', passwordTokenRequestParams);
                const passwordToken = await passwordTokenResponse.json();

                if (passwordToken.isSuccess) {
                    passwordHash = this.calcHash(this.state.password, passwordToken.payload);
                } else {
                    this.setState({ isSubmited: false, message: passwordToken.message });
                    return;
                }
            }

            var request = new Object();
            request.UserName = this.state.userName;
            request.Password = this.state.password;
            request.IpAddress = this.state.ipAddress;
            request.Latitude = this.state.latitude;
            request.Longitude = this.state.longitude;

            const requestParams = getRequestParams('POST', request);
            //console.log("API Url: " + process.env.REACT_APP_API_BASE_URL);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'authenticate/validate/', requestParams);

            const data = await response.json();
            if (data.isSuccess) {
                sessionStorage.setItem("sessionUserLoggedin", data.isSuccess);
                sessionStorage.setItem("sessionUser", JSON.stringify(data.payload));
                sessionStorage.setItem("session_accountId", data.payload.accountId);
                sessionStorage.setItem("session_userId", data.payload.id);
                sessionStorage.setItem("session_userName", data.payload.userName);
                sessionStorage.setItem("session_firstName", data.payload.firstName);
                sessionStorage.setItem("session_lastName", data.payload.lastName);
                sessionStorage.setItem("session_email", data.payload.email);
                sessionStorage.setItem("session_contactNo", data.payload.contactNo);
                window.location.href = "/dashboard";
            }
            else {
                this.setState({
                    isSubmited: false
                    , userToken: ''
                    , message: data.message
                });
            }
        } catch (err) {
            this.setState({
                isSubmited: false
                , userToken: ''
            });
            console.log("Error in " + this.displayName + ".validate function", err);
        }
    }

    calcHash(value, securityKey) {
        try {
            let shaObj = new jsSHA("SHA-256", "TEXT", { encoding: "UTF8" });
            shaObj.update(value);
            let saltText = shaObj.getHash("HEX");

            let shaObj2 = new jsSHA("SHA-256", "TEXT", { encoding: "UTF8" });
            shaObj2.update(saltText + securityKey);

            return shaObj2.getHash("HEX");
        } catch (err) {
            console.log("Error in " + this.displayName + ".calcHash function", err);
        }
    }

    uuidv4() {
        return '00-0-4-1-000'.replace(/[^-]/g,
            s => ((Math.random() + ~~s) * 0x10000 >> s).toString(16).padStart(4, '0')
        );
    }

    render() {
        return (
            <div>
                <section className="section p-4">
                    <div className='bg-login'>
                        <div className="d-flex flex-wrap align-items-stretch login">
                            <div className="col-lg-9 col-md-6 col-sm-6 col-12 min-vh-100">
                                <img src="../assets/img/logo-utwiz-billing.svg" alt="UTWiz Billing" width="180" className='img-filter' />
                                <div className='img-graphic'>
                                    <img src="../assets/img/login-graphic.png" alt="" />
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6 col-12 min-vh-100">
                                <div className="m-3">
                                    <form method="POST" action="#" className="needs-validation loginform" noValidate="">
                                        <h5 className="mb-4 login-title">UTWiz Billing System<br></br><span className='font-weight-normal small' style={{ fontSize: "14px" }}>Can simplify the process of ordering, storing, billing and using inventory by automating end-to-end production, business management.
                                        </span></h5>
                                        <div className="form-group">
                                            <label htmlFor="email">Username</label>
                                            <input type="text" className="form-control" name="userName" maxLength={50} tabIndex="1" value={this.state.userName} onChange={this.handleInputChange} required autoFocus />
                                            <div className="error invalid-feedback">
                                                {this.state.errors["userName"].length > 0 && <span className='error-msg'>{this.state.errors["userName"]}</span>}
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <div className="d-block">
                                                <label htmlFor="password" className="control-label">Password</label>
                                            </div>
                                            <input id="password" type="password" className="form-control" maxLength={50} name="password" value={this.state.password} onChange={this.handleInputChange} tabIndex="2" required />
                                            <div className="error invalid-feedback">
                                                {this.state.errors["password"].length > 0 && <span className='error-msg'>{this.state.errors["password"]}</span>}
                                                {this.state.message.length > 0 && <span className='error-msg'>{this.state.message}</span>}
                                            </div>
                                        </div>
                                        <div className="form-group text-right">
                                            <div className="form-group float-left">
                                                <div className="custom-control custom-checkbox ">
                                                    <input type="checkbox" name="remember" className="custom-control-input" tabIndex="3" id="remember-me" />
                                                    <label className="custom-control-label" htmlFor="remember-me">Remember Me</label>
                                                </div>
                                            </div>
                                            <button type="submit" className="btn btn-primary btn-md icon-right" tabIndex="4" onClick={this.handleSubmit}>
                                                {this.state.isSubmited && <span title="Login"><i className="fas fa-spinner fa-spin"></i>&nbsp;&nbsp;Validating...</span>}
                                                {!this.state.isSubmited && <span title="Login">Login&nbsp;&nbsp;<i className="fas fa-sign-in-alt"></i></span>}
                                            </button>
                                        </div>
                                        <div className="mt-3">
                                            <a href="/forgotpassword" className="mt-3">
                                                <i className="fas fa-info-circle fa-xs"></i> Need a help, logging in please click here.
                                            </a>
                                        </div>
                                    </form>

                                </div>
                            </div>

                        </div>
                        <div className="text-small login-footer p-3">
                            <div className='row align-items-stretch'>
                                <div className='col-6'>Powered by <a href="http://www.plexitech.com/"><img src="../assets/img/logo-plexitech2.png" alt="logo img-filter" width="150" /></a> </div>
                                <div className='col-6 text-right'><span className="copyright">&copy; Copyright 2023 UTWiz. <a href="https://www.utwiz.com/privacy/">Privacy Policy</a></span></div>
                            </div>
                        </div>
                        <div class="logobar">
                            <div class="curved-corner-bottomleft"></div>
                            <div class="loginfooter">
                                Powered by <img src="../assets/img/logo-plexitech2.png" className="img-fluid p-3" width="200" />
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}