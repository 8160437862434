import DataGrid, {
  Column,
  Export,
  Grouping,
  GroupPanel,
  HeaderFilter,
  Pager,
  Paging,
  Selection,
  SearchPanel,
  SortByGroupSummaryInfo,
} from "devextreme-react/data-grid"; //npm i devextreme-react
import { TextBox } from "devextreme-react";
import { getRequestParams } from "../../helpers/globalfunctions";
import { Template } from "devextreme-react/core/template";
import {
  currencySymbolFormats,
  precisionFormats,
  approvedBy,
} from "../../helpers/fixcodes.js";
import { SelectBox } from "devextreme-react/select-box";
import React, { Fragment, Component } from "react";
import Modal from "react-modal"; //npm install --save react-modal;
import Swal from "sweetalert2";
import { toast } from "react-toastify"; //npm i react-toastify --save
import NumberBox from "devextreme-react/number-box";

const customStyles = {
  content: {
    width: "40%",
  },
};

export class RequestRequisitionComponent extends Component {
  static displayName = RequestRequisitionComponent.name;

  constructor(props) {
    super(props);
    this.state = {
      accountId: 0,
      id: 0,
      customerId: 0,
      itemId: 0,
      quantity: 0,
      installerId: 0,
      approvedBy: 0,
      //installer:0,
      isActive: false,
      customerList: [],
      sessionUser: JSON.parse(sessionStorage.getItem("sessionUser")),
      filterCustomerId: 0,
      itemList: [],
      installerList: [],
      filterItemId: 0,
      filterInstallerId: 0,
      requestRequisitionList: [],
      requestRequisitionModal: false,
      selectedRowKey: [],
      errors: {
        customerId: 0,
        itemId: 0,
        quantity: 0,
        installerId: 0,
      },
    };
  }

  async componentDidMount() {
    await this.getCustomers();
    await this.getItemMaster();
    await this.getRequestRequisitionById();
    await this.getInstaller();
  }

  getCustomers = async () => {
    try {
      var request = {};
      const requestParams = getRequestParams("GET", request);
      const response = await fetch(
        process.env.REACT_APP_API_BASE_URL + "account/dropdownAccount/2",
        requestParams
      );
      const data = await response.json();
      this.setState({
        customerList: data.payload,
      });
    } catch (err) {
      console.log("Error in " + this.displayName + ".getAccount function", err);
    }
  };

  getItemMaster = async () => {
    try {
      var request = {};
      //request.CategoryId = 0;
      request.ItemId = 0;
      const requestParams = getRequestParams("POST", request);
      const response = await fetch(
        process.env.REACT_APP_API_BASE_URL + "items/item/",
        requestParams
      );
      const data = await response.json();
      this.setState({
        itemList: data.payload != null ? data.payload : [],
      });
    } catch (err) {
      console.log(
        "Error in " + this.displayName + ".getItemMaster function",
        err
      );
    }
  };

  getInstaller = async () => {
    try {
      var request = {};
      const requestParams = getRequestParams("GET", request);
      const response = await fetch(
        process.env.REACT_APP_API_BASE_URL + "irform/getinstaller",
        requestParams
      );
      const data = await response.json();
      this.setState({
        installerList: data.payload,
      });
    } catch (err) {
      console.log(
        "Error in " + this.displayName + ".getInstaller function",
        err
      );
    }
  };

  getRequestRequisitionById = async () => {
    debugger;
    try {
      var request = {};
      const requestParams = getRequestParams("GET", request);
      const response = await fetch(
        process.env.REACT_APP_API_BASE_URL +
          "irform/GetIRForm/" +
          this.state.filterCustomerId +
          "/" +
          this.state.filterItemId +
          "/" +
          this.state.filterInstallerId,
        requestParams
      );
      const data = await response.json();

      this.setState({
        requestRequisitionList: data.payload,
      });
    } catch (err) {
      console.log(
        "Error in " + this.displayName + ".getRequest Requisition By Id",
        err
      );
    }
  };


    editRequestRequisition = (e, data) => {
        e.preventDefault();
        
        if (data != null) {
            this.setState({
                requestRequisitionModal: true,
                singleData: data,
                id: data.id,
                customerId: data.customerId,
                itemId: data.itemId,
                quantity: data.quantity,
                installerId: data.installerId,
                isActive: data.isActive,
            });
        }
        // if (data.ApprovedBy != null) {
        //     this.setState()
        //     Swal.fire({
        //         icon: 'error',
        //         text: "Please select at least one row.",
        //     }).then((result) => {
        //         return;
        //     });
        // }
    }
    //dropdown value change
    handleFilterCustomerChange = (selectedOption) => {
        this.setState({
            filterCustomerId: (selectedOption.selectedItem !== null ? selectedOption.selectedItem.id : 0)
        });
    }
    handleFilterItemChange = (selectedOption) => {
        this.setState({
            filterItemId: (selectedOption.selectedItem !== null ? selectedOption.selectedItem.id : 0)
        });
    }
    handleFilterInstallerChange = (selectedOption) => {
        this.setState({
            filterInstallerId: (selectedOption.selectedItem !== null ? selectedOption.selectedItem.id : 0)
        });
    }
    handleSearch = async () => {
        await this.getRequestRequisitionById();

    }
    handleCancel = () => {
        this.setState({
            filterCustomerId: 0,
            filterItemId: 0,
            filterInstallerId: 0,
        });
        this.getRequestRequisitionById();
    }
    handleCustomerChange = (selectedOption) => {
        this.setState({
            customerId: (selectedOption.selectedItem !== null ? selectedOption.selectedItem.id : 0)
        });
    }
    handleInstallerChange = (selectedOption) => {
        this.setState({
            installerId: (selectedOption.selectedItem !== null ? selectedOption.selectedItem.id : 0)
        });
    }
    handleItemChange = (selectedOption) => {
        this.setState({
            itemId: (selectedOption.selectedItem !== null ? selectedOption.selectedItem.id : 0)
        });
    }
    // Validaton
    handleValidation = () => {
        let formIsValid = true;
        try {
            let errors = this.state.errors;
            errors.customerId = 0;
            errors.itemId = 0;
            errors.quantity = 0;
            errors.installerId = 0;

      if (this.state.customerId === 0) {
        formIsValid = false;
        errors.customerId = "Please select customer.";
      }
      if (this.state.itemId === 0) {
        formIsValid = false;
        errors.itemId = "Please select item.";
      }
      if (this.state.quantity === 0) {
        formIsValid = false;
        errors.quantity = "Please enter quantity.";
      }
      if (this.state.installerId === 0) {
        formIsValid = false;
        errors.installerId = "Please select installer.";
      }
      this.setState({ errors: errors });
    } catch (err) {
      console.log(
        "Error in " +
          this.displayName +
          ".isRequestRequisitionValidation Function",
        err
      );
    }
    return formIsValid;
  };

    handleRequestRequisitionSubmit = async (e) => {
        e.preventDefault();
        
        try {
            if (this.handleValidation()) {
                this.setState({ isSubmited: true });
                var ApprovedBy = this.state.isActive === true ? this.state.sessionUser.id : 0;
                var newObject = {};
                newObject.CustomerId = parseInt(this.state.customerId);
                newObject.ItemId = parseInt(this.state.itemId);
                newObject.Quantity = parseInt(this.state.quantity);
                //newObject.InstallerId = parseInt(this.state.installer);
                newObject.InstallerId = this.state.installerId;
                newObject.ApprovedOn = this.state.isActive === true ? new Date() : null;
                const requestParams = getRequestParams('POST', newObject);
                const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'irform/' + this.state.id + '/' + ApprovedBy + '/createIRForm', requestParams);
                const data = await response.json();
                if (data.isSuccess) {
                    Swal.fire({
                        icon: 'success',
                        title: 'Success',
                        text: data.message,
                    }).then((result) => {
                        if (result.isConfirmed) {
                            this.closeRequestRequisitionModal();
                            this.getRequestRequisitionById();
                        }
                        this.setState({ isSubmited: false })
                    })
                }
                else {
                    toast.error(data.message, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        style: { width: "310px" },
                        hideProgressBar: false,
                        newestOnTop: false,
                        closeButton: false
                    });
                    this.setState({ isSubmited: false })
                }
            }
        } catch (err) {
            console.log("Error in " + this.displayName + ".handleRequestRequisitionSubmit function", err);
            this.setState({ isSubmited: false })
        }
    }

    closeRequestRequisitionModal = () => {
        this.setState({
            requestRequisitionModal: false,
            accountId: 0,
            id: 0,
            customerId: 0,
            itemId: 0,
            quantity: 0,
            installerId: 0,
            errors: {
                customerId: 0,
                itemId: 0,
                quantity: 0,
                installerId: 0,
            }
        });
    }
    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift({
            location: 'after',
            widget: 'dxButton',
            options: {
                icon: 'refresh',
            }
        });
        e.toolbarOptions.items.unshift({
            location: 'before',
            template: 'toolbarTemplate'
        });
    }
    newRequestRequisition = () => {
        this.setState({
            requestRequisitionModal: true,
            id: 0,
            customerId: 0,
            itemId: 0,
            quantity: 0,
            installer: 0,
            //isActive : false,
        })
    }
    handleInputChange = async (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
    }
    handleQtyChange = (event) => {
        const newValue = event.value;
        //
        this.setState({ "quantity": newValue });
    }
    // handleForApprovalIRForm = (e) => {
    //     this.setState({ selectedRowKey: e.selectedRowKeys });
    // };
    // toolbarItemRender = () => {
    //     return (
    //         <Fragment>
    //             <div className="row">
    //                 <div className="col-12">
    //                      {/* {this.state.approvedBy == parseInt(approvedBy.Draft) ?  */}
    //                         <button type="submit" className="btn btn-success" onClick={(e) => this.handleApprovebyLoginUser()} >
    //                             <span title="Approve"><i className="fas fa"></i>Approve</span> </button> 
    //                             {this.state.approvedBy == parseInt(approvedBy.Appproved)}

    //                 </div>
    //             </div>        
    //         </Fragment>
    //     );
    // }
    // handleApprovebyLoginUser = async (e) => {
    //     //e.preventDefault();
    //     
    //     try {
    //         if (this.state.selectedRowKey != null && this.state.selectedRowKey.length === 0) {
    //             Swal.fire({
    //                 icon: 'error',
    //                 text: "Please select at least one row.",
    //             }).then((result) => {
    //                 return;
    //             });
    //             return;
    //         }
    //         
    //         console.log(this.state.selectedRowKey);
    //         var irformMessage;
    //         //approvedBy == parseInt(approvedBy.Appproved)
    //         irformMessage = "Are you sure, you want to approve selected IRForm?"
    //         Swal.fire({
    //             html: irformMessage,
    //             icon: 'warning',
    //             showCancelButton: true,
    //             confirmButtonColor: '#3085d6',
    //             cancelButtonColor: '#d33',
    //             confirmButtonText: 'Yes',
    //             allowOutsideClick: false
    //         }).then((result) => {
    //             if (result.isConfirmed) {
    //                 this.setState({ loadPanelVisible: true });
    //                 var ApprovedBy = this.state.sessionUser.id
    //                 
    //                 const requestParams = getRequestParams('POST', this.state.selectedRowKey);
    //                 fetch(process.env.REACT_APP_API_BASE_URL + 'irform/approveIRform/' + ApprovedBy, requestParams)
    //                     .then(response => { if (response.ok) return response.json() })
    //                     .then(data => {
    //                         this.setState({ loadPanelVisible: false });
    //                         
    //                         if (data.isSuccess === true) {
    //                             Swal.fire({
    //                                 icon: data.isSuccess === true ? 'success' : 'error',
    //                                 text: data.message,
    //                                 // text: data.message,
    //                             }).then((result) => {
    //                                 if (data.isSuccess === true) {
    //                                     if (result.isConfirmed) {
    //                                         this.setState({ voidStatus: false });
    //                                     }
    //                                 }
    //                             })
    //                         }
    //                     });
    //             }
    //         })
    //     }
    //     catch (ex) {
    //         throw ex;
    //     }
    // } 

    render() {
        const renderGridCell = (data) => {
            //
            return <div>{data.data.approvedBy === 0 ?
                <a href="#" className="fas fa-pen" title="Edit" onClick={(e) => { this.editRequestRequisition(e, data.data) }} ></a>
                : ""
            }
            </div>
        }
        return (
            <Fragment>
                <div className="section-body">
                    <div className='card bg-filter'>
                        <div className='card-body'>
                            <div className="section-header">
                                <div className="row">
                                    <div className="col-lg-5">
                                        <div className="form-group">
                                            <label htmlFor="">Customer</label>
                                            <SelectBox
                                                dataSource={this.state.customerList}
                                                className="basic-multi-select"
                                                classNamePrefix="select"
                                                placeholder="Select Customer"
                                                name="id"
                                                defaultValue={0}
                                                value={this.state.filterCustomerId}
                                                displayExpr='name'
                                                valueExpr='id'
                                                onSelectionChanged={this.handleFilterCustomerChange}
                                                showClearButton={true}
                                                //disabled={this.state.id < 0}
                                                searchEnabled={true}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-lg-2 col-md-2 col-sm-12'>
                                        <div className="form-group">
                                            <label htmlFor="">Item</label>
                                            <SelectBox
                                                dataSource={this.state.itemList}
                                                className="basic-multi-select"
                                                classNamePrefix="select item"
                                                placeholder="Select item"
                                                name="id"
                                                defaultValue={0}
                                                value={this.state.filterItemId}
                                                displayExpr='name'
                                                valueExpr='id'
                                                onSelectionChanged={this.handleFilterItemChange}
                                                showClearButton={true}
                                                searchEnabled={true}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-lg-2 col-md-2 col-sm-12'>
                                        <div className="form-group">
                                            <label htmlFor="">Installer</label>
                                            <SelectBox
                                                dataSource={this.state.installerList}
                                                className="basic-multi-select"
                                                classNamePrefix="select installer"
                                                placeholder="Select Installer"
                                                name="id"
                                                defaultValue={0}
                                                value={this.state.filterInstallerId}
                                                displayExpr='name'
                                                valueExpr='id'
                                                onSelectionChanged={this.handleFilterInstallerChange}
                                                showClearButton={true}
                                                searchEnabled={true}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-lg-2'>
                                        <div className='form-group'>
                                            <label>&nbsp;</label><br></br>
                                            <button type="submit" className="btn btn-success" /**style={{float: 'right'}}**/ onClick={this.handleSearch}>
                                                Search
                                            </button>&nbsp;&nbsp;
                                            <button type="submit" className="btn btn-custom-gray" onClick={this.handleCancel}>
                                                <span title="Cancel"><i className="fas fa-eraser"></i></span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <label for="">&nbsp;</label>
                                    <button type="New" title="New" className="btn btn-success" onClick={this.newRequestRequisition}>
                                        New
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='card'>
                        <div className='card-body'>
                            <DataGrid
                                dataSource={this.state.requestRequisitionList}
                                keyExpr="id"
                                //selection={{ mode: 'single' }}
                                columnsAutoWidth={false}
                                showColumnLines={true}
                                showRowLines={true}
                                showBorders={true}
                                rowAlternationEnabled={false}
                                allowColumnResizing={true}
                                columnResizingMode='widget'
                                onSelectionChanged={this.handleForApprovalIRForm}
                                onExporting={this.onExporting}
                                onToolbarPreparing={this.onToolbarPreparing}>
                                {/* <Selection
                                            mode="multiple"
                                            selectAllMode={"allPages"}
                                            showCheckBoxesMode={"always"}
                                        /> */}
                <Column
                  dataField="id"
                  width={30}
                  caption=""
                  allowFiltering={false}
                  allowSorting={false}
                  cellRender={renderGridCell}
                />
                <Column
                  dataField="customerName"
                  caption="Customer"
                  allowSearch={true}
                  allowFiltering={false}
                  allowSorting={true}
                  width={420}
                />
                <Column
                  dataField="itemName"
                  caption="Item"
                  allowSearch={true}
                  allowFiltering={false}
                  allowSorting={true}
                  width={100}
                />
                <Column
                  dataField="quantity"
                  caption="Quantity"
                  allowSearch={true}
                  allowFiltering={false}
                  allowSorting={true}
                  width={80}
                />
                <Column
                  dataField="installer"
                  caption="Installer"
                  allowSearch={true}
                  allowFiltering={false}
                  allowSorting={true}
                  width={140}
                />
                <Column
                  dataField="approvedByName"
                  caption="Approved By"
                  allowSearch={true}
                  allowFiltering={false}
                  allowSorting={true}
                  width={140}
                />
                <Column
                  dataField="approvedOn"
                  caption="Approved On"
                  allowSearch={true}
                  allowFiltering={false}
                  allowSorting={true}
                  width={100}
                  dataType="datetime"
                  format="dd/MM/yyyy"
                />

                <SortByGroupSummaryInfo summaryItem="count" />
                <GroupPanel visible={false} />
                <Template
                  name="toolbarTemplate"
                  render={this.toolbarItemRender}
                />
                <HeaderFilter visible={true} />
                <SearchPanel
                  visible={true}
                  highlightCaseSensitive={true}
                  width={240}
                  placeholder="Search..."
                />
                <Grouping autoExpandAll={true} />
                <Paging defaultPageSize={10} />
                <Pager
                  showPageSizeSelector={true}
                  allowedPageSizes={[10, 20, 50]}
                  showInfo={true}
                  visible={true}
                />
                <Export
                  enabled={true}
                  allowExportSelectedData={false}
                  fileName="Request Requisition"
                />
              </DataGrid>
            </div>
          </div>
        </div>
        <Modal
          ariaHideApp={false}
          isOpen={this.state.requestRequisitionModal}
          style={customStyles}
          className={"react-modal"}
          onRequestClose={this.closeRequestRequisitionModal}
          shouldCloseOnOverlayClick={false}
          contentLabel="Example Modal"
        >
          <form
            method="post"
            onSubmit={this.handleRequestRequisitionSubmit}
            style={
              this.state.isSubmited
                ? { pointerEvents: "none", opacity: "0.4" }
                : {}
            }
          >
            <div className="modal-header">
              <h6 className="modal-title">
                {this.state.id > 0
                  ? "Update IRForm Requisition"
                  : "New IRForm Requisition"}
              </h6>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-lg-12">
                  <div className="form-group">
                    <label>
                      Customer <span className="text-danger">*</span>
                    </label>
                    <SelectBox
                      dataSource={this.state.customerList}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      placeholder="Select customer"
                      name="id"
                      defaultValue={0}
                      value={this.state.customerId}
                      displayExpr="name"
                      valueExpr="id"
                      onSelectionChanged={this.handleCustomerChange}
                      showClearButton={true}
                      //disabled={this.state.customerId > 0}
                      searchEnabled={true}
                    />

                    {this.state.errors["customerId"].length > 0 && (
                      <span className="error invalid-feedback">
                        {this.state.errors["customerId"]}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                    <label>
                      Item <span className="text-danger">*</span>
                    </label>
                    <SelectBox
                      dataSource={this.state.itemList}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      placeholder="Select item"
                      name="id"
                      defaultValue={0}
                      value={this.state.itemId}
                      displayExpr="name"
                      valueExpr="id"
                      onSelectionChanged={this.handleItemChange}
                      showClearButton={true}
                      //disabled={this.state.itemId > 0}
                      searchEnabled={true}
                    />
                    {this.state.errors["itemId"].length > 0 && (
                      <span className="error invalid-feedback">
                        {this.state.errors["itemId"]}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                    <label>
                      Installer <span className="text-danger">*</span>
                    </label>
                    <SelectBox
                      dataSource={this.state.installerList}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      placeholder="Select Installer"
                      name="id"
                      defaultValue={0}
                      value={this.state.installerId}
                      displayExpr="name"
                      valueExpr="id"
                      onSelectionChanged={this.handleInstallerChange}
                      showClearButton={true}
                      //disabled={this.state.itemId > 0}
                      searchEnabled={true}
                    />
                    {this.state.errors["installerId"].length > 0 && (
                      <span className="error invalid-feedback">
                        {this.state.errors["installerId"]}
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-lg-4">
                  <div className="form-group">
                    <label>
                      Quantity <span className="text-danger">*</span>
                    </label>
                    <NumberBox
                      type="number"
                      className="form-control form-control-sm"
                      name="quantity"
                      placeholder="0"
                      format="#0"
                      defaultValue={0}
                      title="Quantity"
                      value={this.state.quantity}
                      onValueChanged={this.handleQtyChange}
                      min={0}
                    />
                    {this.state.errors["quantity"].length > 0 && (
                      <span className="error invalid-feedback">
                        {this.state.errors["quantity"]}
                      </span>
                    )}
                  </div>
                </div>
                <div
                  className="col-lg-4 col-md-4 col-sm-4 col-xs-4"
                  style={{ display: this.state.id <= 0 ? "none" : "block" }}
                >
                  <label>&nbsp;</label>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="isActive"
                      name="isActive"
                      checked={this.state.isActive}
                      onChange={this.handleInputChange}
                    />
                    &nbsp;
                    <label className="form-check-label">
                      Approve<span className="text-danger"></span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="submit"
                className="btn btn-success"
                onClick={this.handleRequestRequisitionSubmit}
              >
                {this.state.isSubmited && (
                  <span title="In-process">
                    <i className="fas fa-spinner fa-spin"></i> In-process
                  </span>
                )}
                {!this.state.isSubmited && <span title="Submit">Submit</span>}
              </button>
              <button
                type="button"
                className="btn btn-custom-gray"
                onClick={this.closeRequestRequisitionModal}
                title="Cancel RequestRequisition Modal"
              >
                Cancel
              </button>
            </div>
          </form>
        </Modal>
      </Fragment>
    );
  }
}
