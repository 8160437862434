import React, { Fragment, Component } from 'react';
import DataGrid, {
    Column,
    Paging,
    SortByGroupSummaryInfo,
    Editing, Lookup,
    RequiredRule,
    PatternRule,
    StringLengthRule,
} from 'devextreme-react/data-grid'; //npm i devextreme-react
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import Modal from 'react-modal'; //npm install --save react-modal
import { getRequestParams, formatDate } from '../../helpers/globalfunctions.js';
import { alphanumericValidation } from '../../helpers/globalvalidations.js';
import DateBox from 'devextreme-react/date-box';
import { LoadPanel } from 'devextreme-react/load-panel';
import { SelectBox } from 'devextreme-react/select-box';
import Swal from 'sweetalert2'; //npm i sweetalert2 --save
import axios from 'axios'; //npm install--save axios
import { documentType ,currencySymbolFormats,precisionFormats} from '../../helpers/fixcodes.js';
const customStyles = {
    content: {
        width: '50%',
    }
}
const position = { of: '#historydiv' };

export class SalesReturnComponent extends Component {
    static displayName = SalesReturnComponent.name;
    constructor(props) {
        super(props);
        
        this.grdSalesReturnAttributesRef = React.createRef();
        this.state = {
            isSubmited: false,
            loadPanelVisible: false,
            documentModelIsOpen: false,
            accountList: [],
            paymentTermList: [],
            itemList: [],
            itemDetails: [],
            olditemDetails: [],
            hsnsacList: [],
            itemReturnStatus: [],
            invoiceList: [],
            id: 0,
            accountId: 0,
            receivedDate: this.props.location.state === undefined ? new Date() : this.props.location.state.receivedDate,
            invoiceId: 0,
            invoiceNo: this.props.location.state === undefined ? "" : this.props.location.state.invoiceNo,
            comments: "",
            awbNo: "",
            sessionUser: JSON.parse(sessionStorage.getItem("sessionUser")),
            salesreturnId: this.props.location.state === undefined ? 0 : this.props.location.state.salesreturnId,
            returnStatusId: this.props.location.state === undefined ? 0 : this.props.location.state.returnStatusId,
            isNewItem: false,
            installerName: "",
            salesreturnsource: [],
            rmaNo: "",
            imageList: [],
            pdfList: [],
            excelList: [],
            otherList: [],
            returnStatusName: this.props.location.state === undefined ? "" : this.props.location.state.returnStatus,
            documentSelectedFile: [],
            saleReturnDocumentOrderId: 0,
            resultMeassage: "",
            targetFile: [],
            inputValue: "",
            invoiceTypeId :0,
            serviceItemList :[],
            accountTaxList: [],
            errors: {
                accountId: 0,
                invoiceNo: "",
                comments: "",
                awbNo: "",
                installerName: "",
                itemDetailsGrid: ""
            }
        }

        this.assginData = this.assginData.bind(this);
        this.handleSalesReturnSubmit = this.handleSalesReturnSubmit.bind(this);
    }

    async componentDidMount() {
        this.setState({
            loadPanelVisible: true
        });
        await this.getItemReturneStatus();
        await this.getAccounts();
          await this.getItemMaster();
        await this.getInvoice();

        if (this.state.salesreturnId !== 0) {

            await this.getSalesReturnMaster();
        }
        this.setState({
            loadPanelVisible: false
        });
    }

    getItemReturneStatus = async () => {
        try {
            var request = {};
            const requestParams = getRequestParams("GET", request);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'salesorder/returnedItemStatus', requestParams);
            const data = await response.json();
            this.setState({
                itemReturnStatus: data.payload
            });
        } catch (err) {
            console.log("Error in " + this.displayName + ".getItemReturneStatus function", err);
        }
    }

    getAccounts = async () => {
        try {
            var request = {};
            const requestParams = getRequestParams("GET", request);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'account/dropdownAccount/2', requestParams);
            const data = await response.json();
            this.setState({
                accountList: data.payload
            });
        } catch (err) {
            console.log("Error in " + this.displayName + ".getAccounts function", err);
        }
    }


    getPaymentTerms = async () => {
        try {
            var request = {};
            const requestParams = getRequestParams("GET", request);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'paymentTermType/dropdownType', requestParams);
            const data = await response.json();
            this.setState({
                paymentTermList: data.payload
            });
        } catch (err) {
            console.log("Error in " + this.displayName + ".getPaymentTerms function", err);
        }
    }

    getItemMaster = async () => {
        try {
            
            var request = {};
            const requestParams = getRequestParams("GET", request);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'invoice/getItemDetailsForInvoice/' + this.state.invoiceId + '/' + this.state.invoiceTypeId, requestParams);
            const data = await response.json();
           
            this.setState({
                itemList: data.payload != null ? data.payload : [],
                accountId : data.payload[0].customerId,
                //invoiceId : data.payload[0].invoiceId
            });
            setTimeout(() => this.getAccountTax(data.payload[0].customerId), 500);
        } catch (err) {
            console.log("Error in " + this.displayName + ".getItemMaster function", err);
        }
    }

    getInvoice = async () => {
        try {
            
            var request = {};
            const requestParams = getRequestParams("GET", request);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'invoice/getInvoiceForSaleReturnByCustomer/' + this.state.accountId, requestParams);
            const data = await response.json();
            this.setState({
                invoiceList: data.payload != null ? data.payload : []
            });
        } catch (err) {
            console.log("Error in " + this.displayName + ".getInvoice function", err);
        }
    }

    getServiceItemMaster = async () => {
        try {
            var request = {};
            const requestParams = getRequestParams("GET", request);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'master/getServiceByCustomer/' + this.state.accountId, requestParams);
            const data = await response.json();
            this.setState({
                serviceItemList: data.payload,
            });
        } catch (err) {
            console.log("Error in " + this.displayName + ".getServiceItemMaster function", err);
            this.setState({
                loadPanelVisible: false
            });
        }
    }

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        let errors = this.state.errors;

        switch (name) {
            case 'awbNo':
                if (alphanumericValidation.test(event.target.value))
                    this.setState({ awbNo: event.target.value });
                break;
            case 'comments':
                if (alphanumericValidation.test(event.target.value))
                    this.setState({ comments: event.target.value });
                break;
            case 'installerName':
                if (alphanumericValidation.test(event.target.value))
                    this.setState({ installerName: event.target.value });
                break;
            default:
                this.setState({ [name]: value });
                break;
        }
        errors[name] = "";
        //this.state.errors[name] = "";
        // this.setState({
        //     errors: {
        //         [name]: ""
        //     }
        // });
        this.setState({
            errors: errors
        });
    }
    handleAccountChange = (selectedOption) => {
        
        this.setState({
            accountId: (selectedOption.selectedItem !== null ? selectedOption.selectedItem.id : 0),
        });
        let errors = this.state.errors;
        if (selectedOption.selectedItem !== null && selectedOption.selectedItem.id > 0) {
            //this.state.errors["accountId"] = "";
            errors.accountId = ""
            this.setState({
                errors: errors
            });
            setTimeout(() => this.getInvoice(), 100);
        }
        // if (selectedOption.selectedItem !== null && selectedOption.selectedItem.id !== 0) {
        //     setTimeout(() => this.getItemMaster(), 100);
        // }
        // else {
        //     this.setState({ itemDetails: [] });
        // }
    }
    handleInvoiceChange = (selectedOption) => {
        this.setState({
            invoiceId: (selectedOption.selectedItem !== null ? selectedOption.selectedItem.id : 0),
            invoiceNo: (selectedOption.selectedItem !== null ? selectedOption.selectedItem.invoiceNo : ""),
            invoiceTypeId :(selectedOption.selectedItem !== null ? selectedOption.selectedItem.invoiceTypeId : ""),
        });
        let errors = this.state.errors;
        if (selectedOption.selectedItem !== null && selectedOption.selectedItem.invoiceNo !== "") {
            //this.state.errors["invoiceNo"] = "";
            errors.invoiceNo = "";
            this.setState({
                errors: errors
            });
        }
    
        if (selectedOption.selectedItem !== null && selectedOption.selectedItem.id !== 0) {
            setTimeout(() => this.getItemMaster(), 100);
        }
        else {
            this.setState({ itemDetails: [] });
        }

    }
    handleStatusChanges = (selectedOption) => {
        this.setState({
            returnStatusId: (selectedOption.selectedItem !== null ? selectedOption.selectedItem.id : 0),
            returnStatusName: (selectedOption.selectedItem !== null ? selectedOption.selectedItem.name : ""),
        });

    }
    handlereceivedDateChanged = (date) => {
        this.setState({ receivedDate: date.value });
    }
    handleValidation = () => {
        let formIsValid = true;
        try {
            let errors = this.state.errors;
            errors.accountId = 0;
            errors.invoiceNo = 0;
            errors.comments = "";
            errors.awbNo = "";
            errors.installerName = "";
            errors.itemDetailsGrid = "";
            if (this.state.accountId === 0) {
                formIsValid = false;
                errors.accountId = "Please select customer.";
            }
            if (this.state.comments.trim() === "") {
                formIsValid = false;
                errors.comments = "Please enter comment.";
            }
            if (this.state.invoiceNo.trim() === "") {
                formIsValid = false;
                errors.invoiceNo = "Please select invoice.";
            }
            if (this.state.awbNo.trim() === "") {
                formIsValid = false;
                errors.awbNo = "Please enter awb number.";
            }
            if (this.state.installerName.trim() === "") {
                formIsValid = false;
                errors.installerName = "Please enter installer name.";
            }
            if (this.state.itemDetails.length === 0) {
                formIsValid = false;
                errors.itemDetailsGrid = "Please enter item(s).";
            }
            this.setState({ errors: errors });
        }
        catch (err) {
            console.log("Error in " + this.displayName + ".handleValidation function", err);
            this.setState({ isSubmited: false })
        }
        return formIsValid;
    }

    isGuid = (stringToTest) => {
        if (stringToTest[0] === "{") {
            stringToTest = stringToTest.substring(1, stringToTest.length - 1);
        }
        var regexGuid = /^(\{){0,1}[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}(\}){0,1}$/gi;
        return regexGuid.test(stringToTest);
    }

    handleSalesReturnSubmit = async (e) => {
        e.preventDefault();
        try {

            if (this.handleValidation()) {
                this.setState({
                    isSubmited: true
                })

                const dataSource = this.refs.grdSalesReturnAttributesRef.instance.getDataSource();
                var attributeArray = dataSource._items;
                // if (attributeArray != null && attributeArray.length === 0) {
                //     Swal.fire({
                //         icon: 'error',
                //         text: "Please add at least one row.",
                //     }).then((result) => {
                //         this.setState({ isSwapTrip: false })
                //     });
                //     return;
                // }
                
                console.log(this.state.isNewItem);
                var orderItemsList = [];
                var totalAmount = 0.00; var finalTotalAmount = 0.00;
                var totalItemPrice = 0.00; var taxAmount = 0.00;
                 var _data = this.state.itemList
                attributeArray.forEach((element, index) => {
                    var orderItemRequest = {};
                    orderItemRequest.Id = this.isGuid(element.id) === true ? 0 : element.id;
                    orderItemRequest.StockItemId = parseInt(element.stockItemId);
                    orderItemRequest.SerialNo = element.serialNo;
                    orderItemRequest.Remarks = element.remarks;
                    var result = _data.filter((e) => e.id == element.stockItemId);
                        result.forEach(
                        (i, index) => {
                           
                            orderItemRequest.Price = i.unitPrice;
                            orderItemRequest.HSNSACCode = i.hsnsacCode;
                            totalItemPrice = parseInt(1) * i.unitPrice ;
                            totalAmount = totalItemPrice ;
                            finalTotalAmount += totalItemPrice
                            orderItemRequest.totalAmount = totalAmount;
                            orderItemRequest.itemName = i.name;
                        }
                    )
                   
                    orderItemsList.push(orderItemRequest);
                });
                
                   var totalTax = 0.00;
                 if (totalItemPrice > 0) {
                     if (this.state.accountTaxList.length > 0) {
                         this.state.accountTaxList.map((taxitem, i) => {
                             var singleTax = parseFloat(((finalTotalAmount * taxitem.percentage) / 100), 2);
                             totalTax = totalTax + singleTax;
                         });
                     }
                 }
                
                taxAmount = parseFloat(totalTax, 2).toFixed(precisionFormats[2]);
                var request = {};
                request.CustomerId = this.state.accountId;
                request.InstallerName = this.state.installerName;
                request.InvoiceNo = this.state.invoiceNo;
                request.ReceivedOn = formatDate(this.state.receivedDate, "MM/dd/yyyy hh:mm");
                request.Comments = this.state.comments;
                request.ReturnStatus = this.state.returnStatusId === 0 ? parseInt(1) : this.state.returnStatusId;;
                request.ReturnType = 1;
                request.AWBNo = this.state.awbNo;
                request.UserId = this.state.sessionUser.id;
                request.itemReturnDetails = orderItemsList;
                request.ReturnStatusName = this.state.returnStatusName;
                request.RMANo = this.state.rmaNo;
                request.TotalQty = orderItemsList.length;
                request.GrossAmount = parseFloat(taxAmount) + parseFloat(finalTotalAmount);
                request.TotalAmount = parseFloat(finalTotalAmount);
                request.TaxAmount = parseFloat(taxAmount);
                request.InvoiceId = this.state.invoiceId;
               
                const requestParams = getRequestParams('POST', request);
                const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'saleReturn/' + this.state.id + '/saleReturn', requestParams);
                const data = await response.json();
                if (data.isSuccess) {
                    this.setState({ saleReturnDocumentOrderId: data.payload, resultMeassage: data.message });
                    if (this.state.documentSelectedFile.size !== undefined || this.state.documentSelectedFile.length > 0) {
                        if (this.state.id === 0) {
                            this.documentUpload();
                        }
                    }
                    else {
                        Swal.fire({
                            icon: 'success',
                            title: 'Success',
                            text: data.message,
                        }).then((result) => {
                            if (result.isConfirmed) {
                                this.handleClearControls();
                                window.location.href = "/salereturn/list";
                            }
                            this.setState({ isSubmited: false })
                        })
                    }


                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: data.message,
                    }).then((result) => {
                        if (result.isConfirmed) {
                        }
                        this.setState({ isSubmited: false })
                    })
                    this.setState({ isSubmited: false })
                }

                this.setState({
                    isSubmited: false
                })
            }
        } catch (err) {
            console.log("Error in " + this.displayName + ".handleSalesReturnSubmit function", err);
            this.setState({ isSubmited: false })
        }
    }

    handleClearControls = () => {
        this.setState({
            isSubmited: false,
            loadPanelVisible: false,
            accountList: [],
            paymentTermList: [],
            itemList: [],
            itemDetails: [],
            hsnsacList: [],
            itemReturnStatus: [],
            id: 0,
            accountId: 0,
            orderNo: "",
            receivedDate: new Date(),
            shippingDate: new Date(),
            paymentTermId: 0,
            shippingCharges: 0.00,
            discount: 0.00,
            otherCharges: 0.00,
            grossAmount: 0.00,
            remarks: "",
            termsAndConditions: "",
            accountTaxList: [],
            sessionUser: JSON.parse(sessionStorage.getItem("sessionUser")),
            salesreturnId: this.props.location.state === undefined ? "" : this.props.location.state.salesreturnId,
            isNewItem: false,
            errors: {
                accountId: 0,
                orderNo: "",
                receivedDate: new Date(),
                shippingDate: new Date(),
                paymentTermId: 0,
                shippingCharges: 0.00,
                discount: 0.00,
                otherCharges: 0.00,
                grossAmount: 0.00,
                remarks: "",
                termsAndConditions: "",
            }
        });
    }
    getSalesReturnMaster = async () => {
        this.setState({
            loadPanelVisible: true
        });
        try {
            //console.log(this.state.receivedDate);
            var request = {};
            request.ReceivedOn = this.state.receivedDate === "" ? "" : formatDate(this.state.receivedDate, "MM/dd/yyyy");
            request.ReturnStatusId = (this.state.returnStatusId);
            request.InvoiceNo = this.state.invoiceNo === null ? "" : this.state.invoiceNo
            request.ItemReturnId = parseInt(this.state.salesreturnId);
            const requestParams = getRequestParams("POST", request);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'saleReturn/saleReturnDetails', requestParams);
            const data = await response.json();
            this.setState({
                salesreturnsource: data.payload,
                //filterDataSource : data.payload,
                //filterDataSourcedetails : data.payload.itemReturnDetails,
                loadPanelVisible: false
            });
            this.assginData(data.payload);
        } catch (err) {
            console.log("Error in " + this.displayName + ".getSalesOrderMaster function", err);
            this.setState({
                loadPanelVisible: false
            });
            if (this.state.salesreturnId > 0) {
                setTimeout(() => this.assginData(), 500);
            }
        }
    }
    assginData = (salesReturn) => {
        if (salesReturn !== null && salesReturn !== undefined) {
            var saleReturn = salesReturn.itemReturn;
            var itemReturnDetails = salesReturn.itemReturnDetails;
            this.setState({
                id: saleReturn.id,
                accountId: saleReturn.customerId,
                invoiceNo: saleReturn.invoiceNo,
                invoiceId: saleReturn.invoiceId,
                awbNo: saleReturn.awbNo,
                receivedOn: formatDate(saleReturn.receivedOn, "MM/dd/yyyy hh:mm"),
                comments: saleReturn.comments,
                installerName: saleReturn.installerName,
                returnStatusId: saleReturn.returnStatusId,
                rmaNo: saleReturn.rmaNo,
                itemId: itemReturnDetails.stockItemId,
                serial: itemReturnDetails.serialNo,
                remark: itemReturnDetails.remarks,
                itemDetails: itemReturnDetails,
                olditemDetails: itemReturnDetails,
                invoiceTypeId :saleReturn.invoiceTypeId
            });
            setTimeout(() => {this.getInvoice() }, 100);
            setTimeout(() => this.getItemMaster(), 100);
        }
    }
    getAccountTax = async (id) => {
        try {
            var request = {};
            const requestParams = getRequestParams("GET", request);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'taxConfiguration/accountTax/' + id, requestParams);
            const data = await response.json();
            this.setState({
                accountTaxList: data.payload != null ? data.payload : [],
            });
            if (data.payload == null) {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: "Please set tax for the customer",
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.setState({ accountId: 0 });
                    }
                    this.setState({ isSubmited: false })
                });
            }
        } catch (err) {
            console.log("Error in " + this.displayName + ".getContractList function", err);
        }
    }
    onRowInserting = async (e) => {
        this.setState({ isNewItem: true });
    }
    onRowUpdating = async (e) => {
        this.setState({ isNewItem: false });
    }
    onRowUpdated = async (e) => {
        this.setState({ isNewItem: false });
    }
    handleCancel = () => {
        window.location.href = "/salereturn/list";
    }

    openDocumentModel = () => {
        this.setState({
            documentModelIsOpen: true
        });
        this.getDocuments();
    }
    closeDocumentModel = () => {
        this.setState({
            documentModelIsOpen: false
        });
    }
    getDocuments = async () => {
        try {
            this.setState({
                loadPanelVisible: true
            });
            const requestParams = getRequestParams('GET', {});
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'file/' + parseInt(documentType.SalesReturn) + '/' + this.state.id + '/documents', requestParams);
            const data = await response.json();
            var imageListObj = [];
            var pdfListObj = [];
            var excelListObj = [];
            var otherListObj = [];
            if (data.documents != null && data.documents.length > 0) {
                for (var i = 0; i < data.documents.length; i++) {
                    if (data.documents[i].fileGuid !== '00000000-0000-0000-0000-000000000000') {
                        var img = {};
                        const requestParamsImage = getRequestParams('GET', '');
                        const responseImage = await fetch(process.env.REACT_APP_API_BASE_URL + 'file/download/' + data.documents[i].fileGuid, requestParamsImage);
                        const dataImage = await responseImage.json();

                        if (dataImage.fileExtension.toUpperCase() === '.PDF') {
                            img.src = 'data:' + dataImage.mimeType + ';base64,' + dataImage.fileContent;
                            img.fileGuid = data.documents[i].fileGuid;
                            img.fileName = dataImage.fileName;
                            pdfListObj.push(img);
                        } else if (dataImage.fileExtension.toUpperCase() === '.XLXS' || dataImage.fileExtension.toUpperCase() === '.CSV' || dataImage.fileExtension.toUpperCase() === '.XLS') {
                            img.src = 'data:' + dataImage.mimeType + ';base64,' + dataImage.fileContent;
                            img.fileGuid = data.documents[i].fileGuid;
                            img.fileName = dataImage.fileName;
                            excelListObj.push(img);
                        } else if (dataImage.fileExtension.toUpperCase() === '.JPG' || dataImage.fileExtension.toUpperCase() === '.PNG' || dataImage.fileExtension.toUpperCase() === '.JPEG') {
                            img.src = 'data:' + dataImage.mimeType + ';base64,' + dataImage.fileContent;
                            img.fileGuid = data.documents[i].fileGuid;
                            imageListObj.push(img);
                        } else {
                            img.src = 'data:' + dataImage.mimeType + ';base64,' + dataImage.fileContent;
                            img.fileGuid = data.documents[i].fileGuid;
                            img.fileName = dataImage.fileName;
                            otherListObj.push(img);
                        }

                    }
                }
            }

            this.setState({
                loadPanelVisible: false,
                imageList: imageListObj,
                pdfList: pdfListObj,
                excelList: excelListObj,
                otherList: otherListObj
            });

        } catch (e) {
            console.log(e);
        }
    }

    handleFileInputChange = async (e) => {
        let images = e.target.files;
        var i;
        for (i = 0; i < e.target.files.length; i++) {
            this.state.targetFile.push(images[i]);
        }
        this.setState({
            documentSelectedFile: this.state.targetFile

        })
    }

    documentUpload = async () => {
        for (let i = 0; i < this.state.documentSelectedFile.length; i++) {
            //if (this.state.documentSelectedFile.size > 0) 
            {
                console.log(this.state.documentSelectedFile);
                const url = process.env.REACT_APP_API_BASE_URL + 'file/upload';
                const formData = new FormData();
                // console.log(this.state.documentSelectedFile);
                formData.append('body', this.state.documentSelectedFile[i]);
                const config = {
                    headers: {
                        'content-type': 'multipart/form-data',
                        'UserId': this.state.sessionUser.id,
                        'Authorization': 'Bearer ' + sessionStorage.getItem('session_token'),
                    },
                };
                this.setState({
                    loadPanelVisible: true
                });
                axios.post(url, formData, config).then((response) => {
                    var resp = response.data;
                    if (resp != null) {
                        this.setState({
                            fileGuid: resp.fileGuid
                        })
                        this.saveDocument(resp)
                    }
                });
                this.setState({
                    loadPanelVisible: false
                });
            }
        }
    }
    saveDocument = async (data) => {
        this.setState({ isSubmited: true })
        if (data !== null) {
            this.setState({
                fileGuid: data.fileGuid
            });
            try {
                var isSuccess = false;
                var resultMessage = '';
                //for (var i = 0; i < this.state.uniqueId.length; i++)
                {
                    var request = {};
                    request.FileGuid = data.fileGuid;
                    request.FileRecordType = parseInt(5);
                    request.UserId = Number(this.state.sessionUser.id);
                    request.DocumentType = parseInt(4);
                    request.Id = this.state.saleReturnDocumentOrderId;
                    const requestVehicleParams = getRequestParams('POST', request);
                    const responseVehicle = await fetch(process.env.REACT_APP_API_BASE_URL + 'file/save-documents', requestVehicleParams);
                    const dataVehicle = await responseVehicle.json();
                    isSuccess = dataVehicle.isSuccess;
                    resultMessage = dataVehicle.message;
                }
                this.setState({ isSubmited: false })
                if (isSuccess) {
                    Swal.fire({
                        icon: isSuccess ? 'success' : 'error',
                        title: isSuccess ? 'success' : 'Oops...',
                        text: this.state.resultMeassage,
                    }).then((result) => {
                        if (isSuccess) {
                            window.location.href = "/salereturn/list"
                            //  this.closeModel();
                            //this.getDocuments();
                        }
                    });
                } else {
                    this.onfailure('Failed to upload image.', resultMessage);
                }
            } catch (e) {
                //
            }

        }
    }
    resetFileInput = (e) => {
        var data = this.state.documentSelectedFile.filter(item => item.name === e.target.value);
        var _data = this.state.documentSelectedFile.filter(item => item.name !== data[0].name);
        //var data1 = this.state.documentSelectedFile.filter(item => item.name === data[0].name);
        this.setState({
            documentSelectedFile: _data,
            targetFile: _data
        }, () => 500)
        const arrLength = _data.length;
        console.log(arrLength - 1);
        if (_data.length === 0) {
            this.removeAll();
        }
        else {
            document.getElementById("file").value = null;
        }
    };
    removeAll = () => {
        document.getElementById("file").value = null;
    }
    //  handleFile = (e,data) => {
    //     if(data == false){
    //         e.target.value = null;
    //     }

    //     if(data == true){
    //         this.state.inputValue= null;
    //     }

    //  }


    render() {
        return (
            <Fragment>
                <div className="section-body">
                    <div className='card bg-filter'>
                        <div className='card-body'>
                            <div className="section-header">
                                <h6 className='m-0'>{this.state.salesreturnId > 0 ? "Sales Return" + "#" + this.state.rmaNo : "New Sales Return"} </h6>
                                <div className="section-header-buttons" >
                                    <button type="submit" className="btn btn-success" onClick={this.handleSalesReturnSubmit} style={{display:this.state.salesreturnId == 0 ? "block": "none"}} >
                                        {this.state.isSubmited && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                        {!this.state.isSubmited && <span title="Submit">Submit</span>}
                                    </button>&nbsp;&nbsp;
                                    {/* <button type="submit" className="btn btn-success">
                                    <span title="Save and Print"><i className="fas fa-print"></i> Save and Print</span>
                                </button>&nbsp;&nbsp; */}
                                    <button type="submit" className="btn btn-outline-secondary" onClick={this.handleCancel}>
                                        <span title="Cancel">Cancel</span>
                                    </button>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                    <div className='card'>
                    <div className='card-body'>
                            {/* <div className='sub-title-color font-weight-bold'>Personal Info</div> */}
                            <div className='row'>
                            <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <label htmlFor="">Customer <span className="text-danger">*</span></label>
                                        <SelectBox
                                            dataSource={this.state.accountList}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select customer"
                                            name="id"
                                            defaultValue={0}
                                            value={this.state.accountId}
                                            displayExpr='name'
                                            valueExpr='id'
                                            onSelectionChanged={this.handleAccountChange}
                                            showClearButton={true}
                                            disabled={this.state.salesreturnId > 0}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["accountId"].length > 0 && <span className="error invalid-feedback">{this.state.errors["accountId"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-3 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <label htmlFor="">Invoice# <span className="text-danger">*</span></label>
                                        <SelectBox
                                            dataSource={this.state.invoiceList}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select invoice number"
                                            name="invoiceNo"
                                            defaultValue={0}
                                            value={this.state.invoiceNo}
                                            displayExpr='invoiceNo'
                                            valueExpr='invoiceNo'
                                            onSelectionChanged={this.handleInvoiceChange}
                                            showClearButton={true}
                                           disabled={this.state.salesreturnId > 0}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["invoiceNo"].length > 0 && <span className="error invalid-feedback">{this.state.errors["invoiceNo"]}</span>}
                                    </div>
                                </div>
                                
                              
                                {/* {
                                    this.state.salesorderId !== 0 ?

                                        <div className="col-lg-2 col-md-3 col-sm-12 col-xs-12">
                                            <div className="form-group">
                                                <label htmlFor="">Item Status</label>
                                                <SelectBox
                                                    dataSource={this.state.itemReturnStatus}
                                                    className="basic-multi-select"
                                                    classNamePrefix="select"
                                                    placeholder="Select invoice status"
                                                    name="id"
                                                    defaultValue={0}
                                                    value={this.state.returnStatusId}
                                                    displayExpr='name'
                                                    valueExpr='id'
                                                    onSelectionChanged={this.handleStatusChanges}
                                                    showClearButton={true}
                                                    //disabled={this.state.salesreturnId > 0}
                                                    searchEnabled={true}
                                                />
                                            </div>
                                        </div> : null
                                } */}
                                <div className="col-lg-2 col-md-3 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <label htmlFor="">AWB# <span className="text-danger">*</span></label>
                                        <input type="text" className="form-control form-control-sm" name="awbNo" placeholder="Enter awb number" value={this.state.awbNo}
                                            disabled={this.state.salesreturnId > 0} title="AWB No" maxLength="20" onChange={this.handleInputChange} />
                                        {this.state.errors["awbNo"].length > 0 && <span className="error invalid-feedback">{this.state.errors["awbNo"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-3 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <label htmlFor="">Received Date</label>
                                        <DateBox type="date"
                                            className="replay-date-time"
                                            name="receivedDate"
                                            placeholder="dd/mm/yyyy"
                                            displayFormat="dd/MM/yyyy"
                                            showAnalogClock={true}
                                            useMaskBehavior={true}
                                            defaultValue={this.state.receivedDate}
                                            value={this.state.receivedDate}
                                            onValueChanged={this.handlereceivedDateChanged}
                                            width="100%"
                                            disabled={this.state.salesreturnId > 0}
                                            acceptCustomValue={false}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-3 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <label htmlFor="">Installer Name <span className="text-danger">*</span></label>
                                        <input type="text" className="form-control form-control-sm" name="installerName" placeholder="Enter installer name" disabled={this.state.salesreturnId > 0} value={this.state.installerName}
                                          title="Installer Name" maxLength="20" onChange={this.handleInputChange} />
                                        {this.state.errors["installerName"].length > 0 && <span className="error invalid-feedback">{this.state.errors["installerName"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <label htmlFor="">Comment <span className="text-danger">*</span></label>
                                        <input type="text" className="form-control form-control-sm" name="comments" placeholder="Enter comment" value={this.state.comments}
                                          disabled={this.state.salesreturnId > 0}   title="Comment" maxLength="150" onChange={this.handleInputChange} />
                                        {this.state.errors["comments"].length > 0 && <span className="error invalid-feedback">{this.state.errors["comments"]}</span>}
                                    </div>
                                </div>

                                <div className="col-lg-2 col-md-3 col-sm-12 col-xs-12" style={{ display: this.state.salesreturnId > 0 ? 'block' : 'none' }}>
                                    <div className="form-group">
                                        <label htmlFor="">RMA#</label>
                                        <input type="text" className="form-control form-control-sm" name="rmaNo" placeholder="Enter RMA No" disabled={this.state.salesreturnId > 0} value={this.state.rmaNo}
                                            title="RMA No" maxLength="20" onChange={this.handleInputChange} />

                                    </div>
                                </div>

                                <div className="col-lg-2 col-md-3 col-sm-12 col-xs-12" style={{ display: this.state.salesreturnId > 0 ? 'block' : 'none' }}>
                                    <br></br>
                                    <button className="btn btn-ouline-secondary" title="View Document(s)" onClick={() => { this.openDocumentModel() }} ><span className='fas fa-folder'></span>&nbsp;View Document(s)</button>
                                </div>
                            </div>
                            <div className='row mt-2'>
                                <div className='col-12'>
                                    {/* <div className='sub-title-color font-weight-bold'>Item Details</div> */}
                                    <DataGrid
                                        ref="grdSalesReturnAttributesRef"
                                        dataSource={this.state.itemDetails}
                                        keyExpr="id"
                                        allowColumnReordering={true}
                                        showBorders={true}
                                        onRowUpdating={this.onRowUpdating}
                                        onRowUpdated={this.onRowUpdated}
                                        onRowInserting={this.onRowInserting}
                                        onEditorPreparing={this.onEditorPreparing}>
                                        <Paging enabled={true} />
                                        <Editing mode="row"
                                            allowUpdating={this.state.salesreturnId === 0 ? true : false}
                                            allowDeleting={this.state.salesreturnId === 0 ? true : false}
                                            allowAdding={this.state.salesreturnId === 0 ? true : false}

                                            // allowUpdating={true}
                                            // allowDeleting={true}
                                            // allowAdding={true}
                                            editorOptions={true} />
                                     {/* {
                                            parseInt(this.state.invoiceTypeId) === parseInt(orderType.GoodsCapex) ?
                                            <Column dataField="stockItemId" caption="Item" >
                                            <Lookup dataSource={this.state.itemList} displayExpr="name" valueExpr="id" />
                                            <RequiredRule />
                                        </Column>
                                                : <Column dataField="stockItemId" caption="Item" >
                                                    <Lookup dataSource={this.state.serviceItemList} displayExpr="name" valueExpr="id" />
                                                    <RequiredRule />
                                                </Column>
                                        } */}
                                    
                                        <Column dataField="stockItemId" caption="Item" >
                                            <Lookup dataSource={this.state.itemList} displayExpr="name" valueExpr="id" />
                                            <RequiredRule />
                                        </Column>
                                        <Column dataField="serialNo" caption="Serial#">
                                            <PatternRule message={'Please enter valid serial No.'} pattern={alphanumericValidation} />
                                            <StringLengthRule message="Description can not be more than 150 characters" max={150} />
                                        </Column>
                                        <Column dataField="remarks" caption="Remark">
                                            <PatternRule message={'Please enter valid description.'} pattern={alphanumericValidation} />
                                            <StringLengthRule message="Description can not be more than 150 characters" max={150} />
                                        </Column>
                                        {/* <Column dataField="status" caption="Status">
                                            <StringLengthRule message="Status can not be more than 10 characters" max={10} />                                        </Column> */}
                                        <SortByGroupSummaryInfo summaryItem="count" />
                                        {this.state.errors["itemDetailsGrid"].length > 0 && <span className="error invalid-feedback">{this.state.errors["itemDetailsGrid"]}</span>}
                                    </DataGrid>
                                </div>
                            </div>
                            <div className='row mt-2' style={{ display: this.state.salesreturnId === 0 ? 'block' : 'none' }}>
                                <div className="col-12">
                                    <div className="form-group">
                                        <label htmlFor="">Attach File(s) to Sales Return</label>
                                        <input className="form-control" type="file" id="file" onClick={() => { this.removeAll() }} onChange={(e) => { this.handleFileInputChange(e, false) }} />
                                        {/* <label htmlFor="" className='small text-muted'>You can upload a maximum of 5 files, 5MB each</label> */}
                                        {this.state.documentSelectedFile.map((item, index) => {
                                            return (
                                                <div key={index}>
                                                    <label >{item.name} <button value={item.name} onClick={this.resetFileInput} class="btn btn-outline-secondary fas fa-trash text-danger"></button></label>
                                                </div>
                                            );
                                        })}

                                    </div>
                                </div>
                            </div>

                            {/* <p>
                                <input type="file" id="file" multiple />
                            </p>


                            <p id="fp"></p>

                            <p>
                                <input type="submit" value="Show Details"
                                    onClick= {this.FileDetails} />
                            </p>
                             */}
                        </div>
                    </div>
                </div>
                <Modal ariaHideApp={false}
                    isOpen={this.state.documentModelIsOpen}
                    style={customStyles}
                    className={"react-modal"}
                    onRequestClose={this.closeDocumentModel}
                    shouldCloseOnOverlayClick={false}
                    contentLabel="Example Modal">
                    <form method="post" style={this.state.isSubmited ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                        <div className="modal-header">
                            <h6 className="modal-title">Document</h6>
                        </div>
                        <div className="modal-body">
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <div className="box-body no-padding" style={{ maxHeight: 400 + 'px', overflowY: "scroll" }}>
                                    <div>
                                        <ul className="users-list clearfix">
                                            {this.state.imageList.length > 0 && this.state.imageList.map((item, j) =>
                                                <li style={{ position: "relative" }} key={j}>
                                                    <a href={item.src} download={item.fileName}><div className="framehover"><img src={item.src} width="150" alt="Blank" /></div></a>
                                                </li>
                                            )}
                                            {this.state.pdfList.length > 0 && this.state.pdfList.map((item, j) =>
                                                <li style={{ position: "relative" }} key={j}>
                                                    <a href={item.src} download={item.fileName}><div className="framehover"><img src="../../assets/img/pdf_icon.png" width="150" alt="Blank" /></div></a>
                                                </li>
                                            )}
                                            {this.state.excelList.length > 0 && this.state.excelList.map((item, j) =>
                                                <li style={{ position: "relative" }} key={j}>
                                                    <a href={item.src} download={item.fileName}><div className="framehover"><img src="../../assets/img/excel_icon.png" width="100" alt="Blank" /></div></a>
                                                </li>
                                            )}
                                            {this.state.otherList.length > 0 && this.state.otherList.map((item, j) =>
                                                <li style={{ position: "relative" }} key={j}>
                                                    <a href={item.src} download={item.fileName}><div className="framehover"><img src="../../assets/img/other_icon.png" width="100" alt="Blank" /></div></a>
                                                </li>
                                            )}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-outline-secondary" onClick={this.closeDocumentModel} title="Cancel">Cancel</button>
                        </div>
                    </form>
                </Modal>
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={position}
                    visible={this.state.loadPanelVisible}
                    showIndicator={true}
                    shading={true}
                    showPane={true}
                    hideOnOutsideClick={false}
                />
            </Fragment>
        )
    };
}
